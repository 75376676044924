import { useState, useCallback, useMemo } from 'react'
import { Session05Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion05Questions } from '../../../../../lib/data/session-05'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion05Questions[7]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment02 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const after = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">Unsatisfied</span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">Extremely</span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session05Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            <h4>Partnership and intimacy.</h4>
            <p>Your romantic relationships, partnerships or marriage can play a significant role in your heart health.</p>

            <div className="space-y-10">
              {ssHAQuestions.map((item, index) => {
                return (
                  <FormField
                    key={`ss05_${item.name}`}
                    after={after}
                    question={item}
                    value={formik.values[item.name]}
                    onChange={formik.handleChange}
                    onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                  >
                    {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                  </FormField>
                )
              })}
            </div>
          </div>

          {buttons}
        </form>
      </Session05Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
