import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'
import { FormValues, PramsNourish } from '../../models/my-heart'
import { useNourish } from '../use-nourish'

export function useNourishPage() {
  const [postDataResult, setPostDataResult] = useState<AdessoResult<{}>>()
  const [dataOptionsNourish, setDataOptionsNourish] = useState<AdessoResult<[]>>()
  const [stateCreate, setStateCreate] = useState<boolean>(false)
  const [editEnabled, setEditEnabled] = useState<boolean>(true)

  const auth = useAuth()
  const nourish = useNourish()

  const getOptionsOfNourishRx = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setDataOptionsNourish({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await nourish.getNourishOptions()

        if (res.success) {
          setDataOptionsNourish({
            data: res.data,
            isLoading: false,
            error: undefined,
          })
        } else {
          setDataOptionsNourish({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setDataOptionsNourish({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get nourish options error'),
      })
    }
  }, [])

  const getNourishRx = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setPostDataResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await nourish.getNourish()

        if (res.success) {
          const newData: FormValues = res?.data?.reduce((acc: any, currentItem: any, index: number) => {
            acc[currentItem?.title] = true

            if (index === 0) {
              acc.approvedByPhysician = currentItem.recommendation_option.approved_by_physician === 1
            }

            return acc
          }, {} as FormValues)

          const isEmpty = (obj: any) => {
            return Object.keys(obj).length === 0
          }

          if (!isEmpty(newData)) {
            setStateCreate(true)
          }

          setPostDataResult({
            isLoading: false,
            data: newData,
            error: undefined,
          })
        } else {
          setPostDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get nourish error'),
      })
    }
  }, [])

  const submitNourish = useCallback(async (params: PramsNourish, currentData: any, toggle?: boolean) => {
    if (toggle) {
      setEditEnabled(false)
    } else {
      setPostDataResult({
        isLoading: true,
        data: undefined,
        error: undefined,
      })
    }

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()

        const res = await nourish.submitNourish(params)

        if (res.success) {
          setPostDataResult({
            isLoading: false,
            data: currentData,
            error: undefined,
          })
          setStateCreate(true)
        } else {
          setPostDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Update my now error'),
      })
    } finally {
      if (toggle) {
        setEditEnabled(true)
      }
    }
  }, [])

  return { editEnabled, postDataResult, submitNourish, getNourishRx, getOptionsOfNourishRx, dataOptionsNourish, stateCreate }
}
