import { useMemo, useEffect, useCallback, useState } from 'react'

import { HeartChartTestingContent } from '../../components/HeartChartTesting'
import { useHeartChartTestingPage } from '../../hooks/pages/heart-chart-testing'
import { Quizess } from '../../models/question'

import { Loading } from '../../components/Common/Loading'

export const HeartChartTesting = () => {
  const { getDataResult, postDataResult, getDataHeartScore, submitHeartScore } = useHeartChartTestingPage()
  const [dataQuizess, setDataQuizess] = useState<Quizess[]>([])
  const [answerValue, setAnswerValue] = useState([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!getDataResult || (!getDataResult.isLoading && !getDataResult.data && !getDataResult.error)) {
      getDataHeartScore()
    }

    if (getDataResult?.data && getDataResult.data?.quizess) {
      setDataQuizess(getDataResult.data.quizess[0].screens)
      setAnswerValue(getDataResult.data.quizess[0].answers)
      setLoaded(true)
    }
  }, [getDataResult])

  const handleSubmit = useCallback((params: object) => {
    submitHeartScore(params)
  }, [])

  const HeartChartTesting = useMemo(() => {
    const isLoading = postDataResult?.isLoading || getDataResult?.isLoading || false
    const isSuccess = postDataResult?.data?.success || false
    const errorMessage = postDataResult?.error?.message || undefined

    return !loaded ? (
      <Loading />
    ) : (
      <HeartChartTestingContent answerValue={answerValue} quizessData={dataQuizess} isLoading={isLoading} isSuccess={isSuccess} errorMessage={errorMessage} onSubmit={handleSubmit} />
    )
  }, [loaded, dataQuizess, postDataResult, getDataResult, answerValue, handleSubmit])

  return <>{HeartChartTesting}</>
}
