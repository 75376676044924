import { mapDataModalProgress, mapDataHeartRickFactors } from '../maps/session'
import { mapQuestion, mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion04Questions: Question[] = [
  mapQuestionNotRequired('ss04__007', QuestionTypes.TextArea, 'What situations in your life are causing you the most emotional strain (stress, anxiety, depression)?'),
  mapQuestionNotRequired('ss04__008', QuestionTypes.TextArea, 'How do you usually manage your stress?'),
  mapQuestionNotRequired(
    'ss04__009',
    QuestionTypes.TextArea,
    "Now that you're aware, are there strategies that you realize that you need to become better at or make a normal part of your life in order to better manage stress?"
  ),
  mapQuestionNotRequired('ss04__001', QuestionTypes.Rate, 'How often do you feel stressed?'),
  mapQuestionNotRequired('ss04__002', QuestionTypes.Rate, 'How often do you feel anxious?'),
  mapQuestionNotRequired('ss04__003', QuestionTypes.Rate, 'How often do you feel depressed or hopeless?'),
  mapQuestionNotRequired('ss04__004', QuestionTypes.Rate, 'How often do you feel you have a sense of purpose in your life?'),
  mapQuestionNotRequired('ss04__005', QuestionTypes.CheckboxMulti, 'How do you manage stress? Do you…', undefined, [
    '<b>Fight:</b> Against the source of stress',
    '<b>Flight:</b> Run away from the problem',
    '<b>Freeze:</b> Become “flooded” with thoughts and emotions',
    '<b>Fawn:</b> Over-attend to or overcompensate for the stress',
  ]),
  mapQuestionNotRequired('ss04__006', QuestionTypes.Input, 'Describe your go-to-response?', '[enter your response]'),
  mapQuestionNotRequired('ss04__007', QuestionTypes.TextArea, 'Share more about what you can do below:', '[enter your response]'),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  const imgSrc = img ? (img.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${img}` : `/images/my-adresso-journey/session/${img}`) : undefined

  return { id, img: imgSrc, title, description, content }
}

const contentCoreConcepts01 = `
<p>You’ve just allowed yourself 1 minute to check-in with your inner self. It didn’t take long, but it’s a powerful step.</p>
<p>How do you feel? Are you surprised by any of your answers?</p>
<p>As you will learn in this journey, the most important step is one of simply pausing, being present, and tuning in to your inner state. We’ll teach you how, so even if you don’t feel great about the answers you just entered, you’re on your way to improving them!</p>
<p>Ready? Let’s dive in.</p>`

const contentCoreConcepts02 = `
<p>Let’s take a moment to learn about stress, how it relates to your body, and especially how it relates to your heart.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/04/img_001.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/04/img_001.png" alt="">
</picture>
`

const contentCoreConcepts03 = `
<p>Stress is sometimes good, if those stress hormones drive us to peak performance.</p>
<p>But when stress hormones are pumping through the body over time, problems can occur. In its worst form, stress is a killer. Norepinephrine, epinephrine, and cortisol can drive up blood pressure, stiffen arteries, increase inflammation, and decrease the immune system. Stress can affect your choices leading you to not sleep enough, drink too much, eat unhealthy foods, or smoke. It also can cause you to have symptoms simply from the stress like chest pain, palpitations, headaches, back pain, or diarrhea.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/04/img_002.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/04/img_002.png" alt="">
</picture>
`
const contentCoreConcepts04 = `
<p>Depression affects twice as many women as men. Young mothers, perimenopausal and menopausal women are the most at risk. Depressed women tend to isolate themselves, smoke or drink more, eat unhealthy foods, and give up exercise. This leaves long-term damage on your heart, too.</p>
<picture>
  <source srcset="/images/my-adresso-journey/session/04/img_003.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/04/img_003.png" alt="">
</picture>
<p>Depression can lead to sleep issues and an overall lack of well-being. Depression isn't something to ignore. It is something to take care of.</p>
`

const contentCoreConcepts05 = `
<p>An anxiety attack is not just in your head. It has real, measurable, physical effects in your body. Severe anxiety can lead to panic attacks, which have the SAME symptoms as heart attacks! (shortness of breath, rapid heart rate, chest pain, sweating, nausea, dizziness). These symptoms can lead to effects on your heart over time.</p>
<p>If you feel a panic attack coming on, you can start with your breathing. Take deep breaths to help turn your Parasympathetic Nervous System back on. This will tell your body “everything is OK”, and your breathing and heart rate should both return to normal. If they don’t, go to the hospital! Never take a chance when it comes to your heart.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/04/img_004.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/04/img_004.png" alt="">
</picture>
`

const contentCoreConcepts06 = `
<div>
  <p>Long-term activation of your body’s stress response system, along with prolonged exposure to cortisol and other stress hormones, may put you at risk for health problems such as:</p>
  <ul>
    <li>digestive problems</li>
    <li>anxiety</li>
    <li>headaches</li>
    <li>depression</li>
    <li>sleep problems</li>
    <li>weight gain</li>
    <li>decreased immune function</li>
    <li>memory and concentration issues</li>
  </ul>
</div>
<div>
  <p>Stress, anxiety and depression can also directly impact your heart with:</p>
  <ul>
    <li>high blood pressure</li>
    <li>heart disease and stroke</li>
    <li>irregular heart rate and rhythm</li>
    <li>increased blood pressure</li>
    <li>inflammation</li>
    <li>reduced blood flow to the heart</li>
  </ul>
</div>
<p>So now you can see why developing stress management strategies are so important to our overall state of wellbeing and health.</p>
`

const contentCoreConcepts07 = `
<p>Managing stress is about how you interact with your nervous system. Our nervous system is made up of two sides: our Sympathetic and Parasympathetic Nervous System.</p>
<p>First we'll learn how our sympathetic nervous systems reacts and then how we can engage the parasympathethic system to help.</p>
`

const contentCoreConcepts08 = `
<p>Managing stress is about how you interact with your nervous system. Our nervous system is made up of two sides: our Sympathetic and Parasympathetic Nervous System.</p>
<p>The Sympathetic Nervous System puts our body on high alert when it perceives a threat. It can increase your heart rate, speed up your breathing, and inhibit parts of your digestive system.</p>
<p>People respond to stress in different ways.</p>
`

const contentCoreConcepts09 = `
<p>Where your Sympathetic Nervous System sends your body on high alert, your Parasympathetic Nervous System has the ability to moderate, to calm us down and ease the stress. Our Parasympathetic Nervous System has a powerful way of keeping us healthy.</p>
<p>The more we engage our Parasympathetic Nervous System, the more “resiliency” we build. Resiliency is the ability to bounce back quickly when you engage with a challenging situation. It also develops “neuroplasticity” in our brain. The body and the brain learn the ability to creatively adapt to any situation!</p>
`

const contentCoreConcepts10 = `
<p>Here are some ways that you can try engaging your Parasympathetic Nervous System in times of anxiety, stress, and depression rather than remaining in the heightened state of anxiety, allowing your Parasympathetic Nervous System to run amok!</p>
<div>
  <div class="flex-col-2">
  <div>
    <picture>
      <source srcset="/images/my-adresso-journey/session/04/img_014.webp" type="image/webp">
      <img src="/images/my-adresso-journey/session/04/img_014.jpg" alt="">
    </picture>
    
    <p>Breathing</p>
  </div>

  <div>
    <picture>
      <source srcset="/images/my-adresso-journey/session/04/img_015.webp" type="image/webp">
      <img src="/images/my-adresso-journey/session/04/img_015.jpg" alt="">
    </picture>
    
    <p>Sleep</p>
  </div>

  <div>

    <picture>
      <source srcset="/images/my-adresso-journey/session/04/img_016.webp" type="image/webp">
      <img src="/images/my-adresso-journey/session/04/img_016.jpg" alt="">
    </picture>
    
    <p>Exercise</p>
  </div>

  <div>

    <picture>
      <source srcset="/images/my-adresso-journey/session/04/img_016.webp" type="image/webp">
      <img src="/images/my-adresso-journey/session/04/img_016.jpg" alt="">
    </picture>
    
    <p>Meditation</p>
  </div>
  </div>
  <p>Now, which tools would you like to use to engage your Parasympathetic Nervous System in stressful situations?</p>
</div>
`

const contentCoreConcepts11 = `
<p>Do you remember how we talked about that 1-minute pause at the start of this journey? Here you can put it to use again just to pause and moderate your breathing, an effective first step to activate your Parasympathetic Nervous System. Breathing helps moderate panic, which sends signals all over your body to be on high alert.</p>
<div>
  <h4>Box Breathing:</h4>
  <p>Here’s an activity you can use when you are stressed or anxious:</p>
  <ul style="list-style-type: decimal;">
    <li>Take a breath in for 4 seconds.</li>
    <li>Hold for 4 seconds.</li>
    <li>Let out for 6 seconds.</li>
    <li>Repeat twice.</li>
  <ul>
</div>
<div>
  <h4>Intermittent Nostril Breathing:</h4>
  <ul style="list-style-type: decimal;">
    <li>Use your right thumb to cover right nostril</li>
    <li>Inhale</li>
    <li>With your right pointer finger, cover left nostril, and release your right nostril.</li>
    <li>Exhale</li>
    <li>Inhale</li>
    <li>Cover your right nostril, and release your left nostril.</li>
    <li>Exhale</li>
    <li>Continue these steps for up to 5 minutes</li>
  <ul>
</div>
`

const contentCoreConcepts12 = `
<p>Meditation is a powerful practice that helps to clear our mind, become more focused, and allow for thoughts to pass without fixation and obsession. Meditation provides us with a break from stress and anxiety and allows us to become more grounded. It allows us to be less reactive and can decrease the stress response. Some research suggests that meditation physically changes the brain and could help increase the ability to process information, slow the cognitive effects of aging, reduce inflammation, support the immune system, reduce symptoms of menopause, control the brain’s response to pain, and improve sleep. Studies have shown it can improve depression and help battle addictions, like smoking and alcohol. Studies have also shown that they can help dilate the arteries, lower blood pressure and prevent heart disease.</p>
<div>
  <h4>Try a quick meditation yourself.</h4>
  <br/>
  <ul style="list-style: decimal;">
    <li>Find a quiet space to sit or at least a place where you can be uninterrupted for a short period of time.</li>
    <li>Sit up straight, relaxed but still.</li>
    <li>Fix your eyes softly, almost closed.</li>
    <li>Breathe in, breathe out.</li>
    <li>Settle your body. Clear your mind.</li>
    <li>Notice thoughts that pass by. Don’t judge them, just say “I notice that I’m thinking about this.” Let the thought pass.</li>
    <li>Breathe in, breath out.</li>
  <ul>
</div>
<p>Some types of meditation use a mantra. A mantra is a sound or a word that gets repeated over and over. You can even think of your own mantra that inspires you and makes you feel calm or powerful.</p>
`

const contentCoreConcepts13 = `
<p>Exercise, in some ways, is "the best medicine." Besides what we’ve already discussed in the movement session, exercise has the additional benefit of helping our minds, while improving our bodies! It is a useful tool for all sorts of heart related issues as well as emotional ones. Going for a walk around your neighborhood, doing stretches or just moving your body, all help with stress. Other types of physical activity, like yoga, dancing or your favorite sport, get your body moving, but also shift your mind as well. Find ways to move, even in small ways, throughout your day and notice the impact it has on your emotions.</p>
<p>On that note. let’s do some quick movement to release our emotions.</p>
<ul style="list-style: decimal;">
  <li>Lift right leg, hold it. Count to five. Lower it.</li>
  <li>Lift left leg, hold it. Count to five. Lower it.</li>
  <li>Cross your arm across your chest and stretch it.</li>
  <li>Cross your other arm across your chest and stretch it.</li>
  <li>Slowly roll your neck in a circle.</li>
  <li>Raise your head straight up to the ceiling and stretch your arms up above your head like a string is drawing you to the ceiling.</li>
  <li>Get up and sit down, and do that several times.</li>
  <li>How about a little victory dance and a big smile to wrap it all up! That was fun, right?!</li>
<ul>
`

const contentCoreConcepts14 = `
<p>Sleep is one of the most essential factors to managing stress. Unfortunately, it’s often also the most challenging thing to do when you are anxious. So it’s your job to figure out the tricks to sleep soundly. Use the tips we’ve provided below to get to a place of calm, so you can move more easily into a delightful, restorative night of sleep.</p>
<p>Getting ready for sleep should begin an hour before you get into bed. Start a regular routine of preparation. How about a “night cap.” Warm tea, heated almond milk and ginger, hot water and lemon to start the sleep ritual. Wash your face and brush your teeth. Choose clothing (or not!) that allows you to be comfortable. Make it sleepwear and not just your old torn sweatpants.</p>
<p>
To stop the list-making that often goes on right before bed, have a pen and paper ready at the side of your bed. Write down what’s on your mind, and then let it go.
</p><p>Light a candle and put on some soothing music. Darken the room and make sure the temperature is perfect. Leave all technology outside. I promise it will be there for you in the morning.
</p>
<p>The bedroom is only for sex and for sleep. Computers, work, televisions all belong outside of the place where you sleep. This is your sanctuary. Make it feel good. Add candles and pillows. Lavender scents.</p>
<p>Take some time for stretching, breathing, and meditation. Read a book if it helps. Then shut off all the lights. Breathe. Meditate. Breathe. Be grateful for the day. Breathe….
<br/>Are you feeling it yet?</p>
<p>Review the Sleep action at the end of the session in the Go Deeper library to learn more.</p>
`

const contentCoreConcepts15 = `
  <p>We’ve been dealing with the tangible, biological impact of stress. Now let’s take a flight up to the so-called 30,000-ft level and talk about something equally important, but not quite as obvious as a panic attack!</p>
  <p>We’re talking about purpose. We all have one.</p>
  <p>Let me say that again. We ALL have a purpose.</p>
  <p>Our purpose gets to the heart of who you are as a person. It’s how you define your reason for being. Our purpose can influence our stress levels and interplay with our heart health. Living with purpose and passion is associated to living a longer, healthier, and more vibrant life.</p>
  <p>Here’s what we do know. Lacking a sense of purpose can be a factor in depression, stress, and anxiety. Depression, stress and anxiety are increasingly linked to physical health problems.</p>
  <p>A recent study underscored the point. Researchers analyzed data from a survey of nearly 7,000 Americans over 50 that measured how strong their sense of life purpose was. People with a stronger life purpose were less likely to die of any cause, and even less likely to die of heart, circulatory and blood conditions.<sup>1</sup> I call it an essential part of “Living from the Heart.”</p>
  <p>So how do you define a sense of purpose? In the next session, Relate, we will dive deeper into this area, in our relationships, work and pleasure to help define a better understanding of purpose. For now, let’s recognize everything within our inner self contributes to our heath and wellbeing.</p>
  <ul class="notes">
    <li><a class="text-black hover:text-black" href="https://www.heart.org/en/news/2019/10/08/whats-your-sense-of-purpose-the-answer-may-affect-your-health" target="_blank">1. https://www.heart.org/en/news/2019/10/08/whats-your-sense-of-purpose-the-answer-may-affect-your-health</a></li>
  <ul>
`

const contentCoreConcepts16 = `
<p>Beyond all of these practices we’ve mentioned, most important it’s key to practice gratitude.</p>
<p>Gratitude focuses our attention on the good in our lives, the things we love and are thankful for. It helps us keep the bad things in perspective. It releases dopamine into our body that has tremendous physiological effects.</p>
<h4>Researchers that are studying the impact of gratitude are finding these common benefits:</h4>
<ul>
  <li>Associated with healthier behaviors</li>
  <li>Better quality of life</li>
  <li>Lower rates of developing depression and anxiety</li>
  <li>Beneficial outcomes</li>
  <li>Emotionally more resilient</li>
  <li>Improve well-being</li>
  <li>Improvement in diet, exercise, health compliance</li>
</ul>
<p>So take a moment today to write down 5 things you’re grateful for and encourage yourself to do this at least once a week.</p>
`

export const dataCoreConcepts = [
  mapCoreConcepts(1, undefined, 'Being present to how you feel.', '', contentCoreConcepts01),
  mapCoreConcepts(2, undefined, 'How stress, anxiety and depression affects your heart.', '', contentCoreConcepts02),
  mapCoreConcepts(3, undefined, 'Stress', '', contentCoreConcepts03),
  mapCoreConcepts(4, undefined, 'Depression', '', contentCoreConcepts04),
  mapCoreConcepts(5, undefined, 'Anxiety', '', contentCoreConcepts05),
  mapCoreConcepts(6, '04/img_005.webp', 'The toll of stress on your body and heart.', '', contentCoreConcepts06),
  mapCoreConcepts(7, '04/img_006.webp', 'Stress management strategies.', '', contentCoreConcepts07),
  mapCoreConcepts(8, '04/img_007.webp', 'How you manage stress.', '', contentCoreConcepts08),
  mapCoreConcepts(17, undefined, 'How your Parasympathetic Nervous System can be activated to help.', '', contentCoreConcepts09),
  mapCoreConcepts(9, 'ss_04.mp4', undefined, '', ''),
  mapCoreConcepts(10, undefined, 'Engaging your Parasympathetic Nervous System.', '', contentCoreConcepts10),
  mapCoreConcepts(11, '04/img_009.webp', 'Breathing: Guided breathing exercise.', '', contentCoreConcepts11),
  mapCoreConcepts(12, '04/img_010.webp', 'Meditation: Quick guided two-minute meditation.', '', contentCoreConcepts12),
  mapCoreConcepts(13, '04/img_011.webp', 'Exercise: Movements to reduce stress.', '', contentCoreConcepts13),
  mapCoreConcepts(14, '04/img_012.webp', 'Sleep', '', contentCoreConcepts14),
  mapCoreConcepts(15, '04/img_013.webp', 'Purpose', '', contentCoreConcepts15),
  mapCoreConcepts(16, undefined, 'A note on gratitude.', '', contentCoreConcepts16),
]

const coreConceptsMore = `<h4>coreConceptsMore</h4>`

export const dataCoreConceptsStops: CoreConceptsStops[] = [mapCoreConcepts(1, '04/img_015.png', 'The Breakfast Aisle', 'Start your day with proteins and complex carbs.', coreConceptsMore)]
