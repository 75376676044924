import React, { useRef, useEffect, useState, memo } from 'react'
import * as d3 from 'd3'
import { useLocales } from '../../../locales'

type DataPoint = {
  x: number
  y: number
}

type StatsDisplayProps = {
  stats: {
    label: string
    value: number
    color?: string
  }[]
}
type StatItemProps = {
  label: string
  value: number
  color?: string
}

type HeartRateVariabilityChartProps = {
  width: number
  height: number
  data: DataPoint[]
  color: string
}

type Props = {
  width: number
  height: number
  data: DataPoint[]
  color: string
  title: string
  setSelectedButtonIndexLineHeart: (index: number) => void
}
const HeartRateVariabilityChart: React.FC<HeartRateVariabilityChartProps> = ({ width, height, data, color }) => {
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const margin = { top: 20, right: 30, bottom: 40, left: 50 }
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    svg.selectAll('*').remove()

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.x) ?? 0])
      .range([0, innerWidth])

    const yScale = d3
      .scaleLinear()
      .domain([0, 160]) // Adjust if your y values need a different range
      .range([innerHeight, 0])

    // Use curveMonotoneX for smooth line but respecting x-axis monotonicity
    const lineGenerator = d3
      .line<DataPoint>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveBumpX)

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`)

    g.append('path').datum(data).attr('fill', 'none').attr('stroke', color).attr('stroke-width', 2).attr('d', lineGenerator)

    data.forEach((d) => {
      g.append('circle').attr('cx', xScale(d.x)).attr('cy', yScale(d.y)).attr('r', 3.5).attr('fill', color).attr('stroke', color).attr('stroke-width', 1)
    })

    const xAxis = d3.axisBottom(xScale).tickSize(4).tickPadding(10)
    const yAxis = d3.axisLeft(yScale).tickSize(-innerWidth).tickPadding(20)

    const xAxisGroup = g.append('g').attr('transform', `translate(0,${innerHeight})`).call(xAxis)

    const yAxisGroup = g.append('g').call(yAxis)

    xAxisGroup.select('.domain').remove()
    yAxisGroup.select('.domain').remove()
    yAxisGroup.selectAll('.tick line').attr('stroke-opacity', 0.1)

    xAxisGroup.selectAll('text').attr('fill', '#56566D').attr('font-family', 'Proxima Nova').attr('font-weight', '500').attr('font-size', '7.8px').attr('letter-spacing', '0.15px')

    yAxisGroup.selectAll('text').attr('fill', '#56566D').attr('font-family', 'Proxima Nova').attr('font-weight', '500').attr('font-size', '7.8px').attr('letter-spacing', '0.15px')
  }, [data, height, width, color])

  return <svg ref={svgRef} width={width} height={height}></svg>
}

const StatsDisplay: React.FC<StatsDisplayProps> = ({ stats }) => {
  const StatItem: React.FC<StatItemProps> = ({ label, value, color }) => {
    return (
      <div className={`font-['Inter'] text-[10px] font-[400] leading-[12px] ${color ? `text-[${color}]` : ''}`}>
        <span>{label} </span>
        <span>{Math.round(value)}</span>
      </div>
    )
  }

  return (
    <div className="bottom-0 left-0 flex w-full justify-around px-4">
      {stats.map((stat) => (
        <StatItem key={stat.label} label={stat.label} value={stat.value} color={stat.color} />
      ))}
    </div>
  )
}

export const LineChartHeart: React.FC<Props> = memo(({ data, color, width, height, title, setSelectedButtonIndexLineHeart }) => {
  const { t } = useLocales()
  const containerRef = useRef<HTMLDivElement>(null)

  const dataGroup = [t('heartChartMain.groupButton.day'), t('heartChartMain.groupButton.week'), t('heartChartMain.groupButton.month')]
  const min = d3.min(data, (d) => d.y) ?? 0
  const max = d3.max(data, (d) => d.y) ?? 0
  const avg = d3.mean(data, (d) => d.y) ?? 0
  const [selectedButton, setSelectedButton] = useState(0)

  const stats = [
    { label: 'MIN', value: min },
    { label: 'MAX', value: max, color: color },
    { label: 'AVG', value: avg, color: color },
  ]

  const [chartSize, setChartSize] = useState({ width: width, height: height })

  useEffect(() => {
    function handleResize() {
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : width
      const chartWidth = window.innerWidth < 768 ? width : containerWidth
      const chartHeight = window.innerWidth < 768 ? height : height + 100
      setChartSize({ width: chartWidth, height: chartHeight })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [width, height])

  const GroupButton = () => {
    const getButtonStyle = (isSelected: boolean) => ({
      backgroundColor: isSelected ? color : '#ffffff',
      color: isSelected ? '#ffffff' : '#000000',
      borderRadius: '9999px',
      padding: '8px 16px',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '21px',
      fontFamily: "'Proxima Nova', sans-serif",
    })

    return (
      <div className="mt-6 flex justify-around">
        {dataGroup.map((item, index) => {
          const isSelected = selectedButton === index
          return (
            <button
              key={index}
              style={getButtonStyle(isSelected)}
              onClick={() => {
                setSelectedButton(index)
                setSelectedButtonIndexLineHeart(index)
              }}
            >
              <p className='font-["Proxima Nova"] text-[15px] font-[500] leading-[21px]'>{item}</p>
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <div ref={containerRef} className="mt-3 w-full">
      <p className="font-['Proxima Nova'] justify-start text-[15px] font-[600] leading-[21px]">{title}</p>
      <span className="font-['Proxima Nova'] flex justify-center py-6 text-sm font-medium">Heart Rate Variability</span>
      <div className="flex w-full flex-col items-center justify-center">
        <HeartRateVariabilityChart data={data} width={chartSize.width} height={chartSize.height} color={color} />
      </div>
      <StatsDisplay stats={stats} />
      <GroupButton />
    </div>
  )
})
