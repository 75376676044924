import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'
import { getDeepers } from '../../lib/request/get-deepers'
import { getDeeperSlug } from '../../lib/request/get-deeper-slug'

import { mapDataDeepers, mapDataDeeperDetail } from '../../lib/maps/deepers'

import { Deeper, DeeperArticle } from '../../models/deepers'

type Response = {
  deepers?: Deeper[]
}

type ResponseDetail = {
  deeper?: DeeperArticle
}

export function useGoDeeperLibraryPage() {
  const [dataDeepers, setDataDeepers] = useState<AdessoResult<Response>>()

  const auth = useAuth()

  const getDataDeepers = useCallback(async () => {
    setDataDeepers({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getDeepers()

        if (res.data) {
          const deepers = mapDataDeepers(res.data)
          setDataDeepers({
            isLoading: false,
            data: { deepers },
            error: undefined,
          })
        } else {
          setDataDeepers({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataDeepers({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get Deepers Error'),
      })
    }
  }, [])

  return { dataDeepers, getDataDeepers }
}

export function useGoDeeperDetailPage() {
  const [getDataResult, setGetDataResult] = useState<AdessoResult<ResponseDetail>>()

  const auth = useAuth()

  const getDataDeeper = useCallback(async (slug: string) => {
    setGetDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getDeeperSlug(slug)

        if (res.data) {
          const deeper = mapDataDeeperDetail(res.data)
          setGetDataResult({
            isLoading: false,
            data: { deeper },
            error: undefined,
          })
        } else {
          setGetDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setGetDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get Deepers Error'),
      })
    }
  }, [])

  return { getDataResult, getDataDeeper }
}
