type Props = {
  className?: string
  children?: React.ReactNode
  bottom?: React.ReactNode
}

export const Container = ({ className, children, bottom }: Props) => {
  return (
    <div className={`relative flex h-full w-full flex-1 flex-col md:flex-none ${className} ${bottom ? 'space-y-9 pb-4' : ''}`}>
      <div className="flex-1 space-y-7">{children}</div>

      {bottom}
    </div>
  )
}
