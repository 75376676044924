import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts03 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">But what is exercise?</h4>
          <p>
            Exercise is movement that creates an energy demand of your body. Cardio is the best kind of exercise for your heart because it increases our heart rate for a sustained period of time.
            <br />
            <br />
            {`How do we measure increased heart rate? We look at how much oxygen is required or energy needed to perform an exercise. The stronger our heart is, the easier physical activity will be.`}
            <br />
            <br />
            <br />
            From a very basic perspective, we recommend:
          </p>

          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_003.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_003.jpg`} alt="" />
          </picture>
          <ul className="list-disc pl-8">
            <li>30 minutes of moderate intensity cardio activity five days a week (150 minutes a week) Or 75 minutes high intensity cardio per week.</li>
            <li className="mt-2">At least two days a week of strength training.</li>
          </ul>
          <p>Anything less than that is helpful but these are our goals.</p>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
