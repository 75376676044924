import { useEffect, useMemo, useState } from 'react'
import { useLocales } from '../../../locales'
import WrapperMyHealth from '../MyHealthWrapper'
import { useHeartScoreResultPage } from '../../../hooks/pages/heart-score-result'
import HeartScoreCustom from '../Form/HeartScoreCustom'
import useHeartScore from '../../../hooks/use-heart-score'
import { Loading } from '../../Common'
import { format, parse } from 'date-fns'

type Props = {
  prevScreen: () => void
}

const ListHeartScore = ({ prevScreen }: Props) => {
  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()
  const { dataHSResult, useGetDataHeartScore, dataHSHistory, useGetByDateHSHistory } = useHeartScore()

  const { t } = useLocales()
  const [expanded, setExpanded] = useState([1, 2, 3].map(() => false))

  const handleChange = async (index: number, date: Date) => {
    try {
      setExpanded((prev: boolean[]) => {
        const clar = prev.map((item, i) => (i === index ? item : false))
        clar[index] = !clar[index]
        return clar
      })
      if (!expanded[index]) {
        await useGetDataHeartScore({ date: date })
      }
    } catch (error) {
      return { message: `${error}` }
    }
  }

  useEffect(() => {
    if (dataHSHistory?.isLoading === undefined) {
      useGetByDateHSHistory()
    }
    if (dataHSHistory?.data?.heartScoreHistory) {
      setExpanded((dataHSHistory?.data?.heartScoreHistory).map(() => false))
    }
  }, [dataHSHistory?.data?.heartScoreHistory])

  useEffect(() => {
    if (resultHeartScore?.isLoading === undefined) {
      getResultHeartScore()
    }
  }, [resultHeartScore])

  const listHeartScore = useMemo(() => {
    return (
      <div className="space-y-4 pb-2">
        {dataHSHistory?.data?.heartScoreHistory.length > 0 ? (
          dataHSHistory?.data?.heartScoreHistory.map((item: any, index: number) => {
            const parsedDate = parse(item?.date, 'yyyy-MM-dd', new Date())
            const formattedDate = format(parsedDate, 'MMMM dd, yyyy')
            return (
              <>
                <div className="flex flex-col gap-4" onClick={() => handleChange(index, item?.date)}>
                  <div className="flex w-full cursor-pointer flex-row items-center justify-between rounded-[10px] border-[1px] border-solid border-[#D4D4D4] p-2 shadow-lg shadow-[#D4D4D4]">
                    <div className="text-[15px] font-semibold uppercase leading-5">{formattedDate ?? item?.date}</div>
                    <div className={`flex h-[30px] w-[30px] ${expanded[index] ? 'pt-3' : 'p-1'}`}>
                      <div className={`h-full w-full  ${expanded[index] ? "bg-[url('assets/images/icon/i-arrow_down.svg')]" : "bg-[url('assets/images/icon/i-arrow.svg')]"} bg-no-repeat`} />
                    </div>
                  </div>
                </div>
                {dataHSResult?.isLoading ? expanded[index] && <Loading /> : expanded[index] && <HeartScoreCustom resultHeartScore={dataHSResult} date={item?.date} />}
              </>
            )
          })
        ) : (
          <Loading />
        )}
      </div>
    )
  }, [expanded, resultHeartScore, dataHSHistory?.data?.heartScoreHistory, dataHSResult, dataHSResult?.data?.heartScoreResult, dataHSResult?.isLoading])

  return (
    <WrapperMyHealth title="myHeartScore" prevScreen={prevScreen}>
      <div className="m-2 flex flex-col justify-center gap-8">{listHeartScore}</div>
    </WrapperMyHealth>
  )
}

export default ListHeartScore
