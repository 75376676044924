import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'
import { GoalAnswers, GoalDetail } from '../../models/goal'
import { useHeartAction } from '../use-heart-action'
import { mapDataHeartJournal } from '../../lib/maps/heart-journal'

type GetGoalResponse = {
  goal?: GoalDetail
}

export function useHeartJournalActionPage() {
  const [dataResult, setDataResult] = useState<AdessoResult<GetGoalResponse>>()

  const auth = useAuth()

  const heartAction = useHeartAction()

  const getGoalData = useCallback(async (slug: string, date: string) => {
    setDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await heartAction.getGoalDetail(slug, date)
        if (res.data) {
          const newData = mapDataHeartJournal(res.data)
          setDataResult({
            isLoading: false,
            data: { goal: newData },
            error: undefined,
          })
        } else {
          setDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get HeartScore Error'),
      })
    }
  }, [])

  const submitGoalAnswers = useCallback(async (id: number, params: GoalAnswers) => {
    setDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await heartAction.submitAnswers(id, params)
        if (res.data) {
          setDataResult({
            isLoading: false,
            data: { goal: res.data },
            error: undefined,
          })
        } else {
          setDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post HeartScore Error'),
      })
    }
  }, [])

  return { dataResult, getGoalData, submitGoalAnswers }
}
