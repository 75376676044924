import { useState, useMemo, useCallback } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { WrapperCommon, FormInput, ErrorMessage, ButtonCommon, ButtonStyles } from '../../Common'
import { Bubble } from './Bubble'

export type YourNowOptions = {
  your_now: string
}

type Props = {
  myNow: string
  createMyNow: (data: string) => void
}

export const YourNowScreen = ({ myNow, createMyNow }: Props) => {
  const [isCheckError, setIsCheckError] = useState<boolean>(false)

  const defaultYourNowData: YourNowOptions = {
    your_now: myNow,
  }

  const formik = useFormik({
    initialValues: defaultYourNowData,
    validationSchema: Yup.object({
      your_now: Yup.string(),
    }),
    onSubmit: (values) => {
      createMyNow(values.your_now)
    },
  })

  const handleSubmit = useCallback((callback: () => void) => {
    setIsCheckError(true)
    callback()
  }, [])

  const onboardContent = useMemo(() => {
    return (
      <WrapperCommon className="min-h-[calc(100vh-74px)] bg-transparent py-5 text-white">
        <div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-white pt-20">
          <div className="h-full bg-[url('/backgrounds/bg-onboard.webp')] bg-cover bg-center bg-no-repeat"></div>
        </div>
        <form className="relative z-20 flex h-full w-full flex-col justify-between" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-7 text-ma-lg font-semibold">
            <p>
              This journey is all about you making the decisions you need to make for yourself. So let’s start by defining why you’re here.
              <br />
              <br />
              What is your Now? (Your reason for living a heart healthy life.)
            </p>

            <div className="space-y-4">
              <FormInput
                className="h-[88px] w-full rounded-ma bg-white p-4 text-[18px] leading-6 text-black outline-none"
                type="textarea"
                id="your_now"
                name="your_now"
                placeholder="Type YOUR NOW"
                value={formik.values.your_now}
                onChange={formik.handleChange}
              />
              {isCheckError && formik.errors.your_now && <ErrorMessage text={formik.errors.your_now} />}
            </div>

            <div className="flex flex-col">
              <Bubble>"I want to run a marathon."</Bubble>

              <Bubble isRight>
                "I want to blow out
                <br />
                100 candles."
              </Bubble>

              <Bubble>
                "I want to dance a tango
                <br />
                at my 50th anniversary."
              </Bubble>

              <Bubble isRight>
                "I want to look great and
                <br />
                fall in love again."
              </Bubble>
            </div>
          </div>

          <div className="flex justify-center pt-11">
            <ButtonCommon style={ButtonStyles.Dark} width="w-[147px]" height="h-[53px]" text="Next" onClick={() => handleSubmit(formik.handleSubmit)} />
          </div>
        </form>
      </WrapperCommon>
    )
  }, [isCheckError, formik, handleSubmit])

  return <>{onboardContent}</>
}
