import axios from 'axios'
import { AdessoError } from '../error'
import { setAmplitude, trackAmplitude } from '../utils/helper'

type GetMeResult = {
    success: boolean,
    data?: any,
    message?: string
}

export async function getMyBeat (): Promise<GetMeResult> {
    const { data, status } = await axios.get( '/my-beats' )

    if ( status !== 200 ) {
        throw new AdessoError( `Get my beat error - ${status} ${JSON.stringify( data )}` )
    }

    // Amplitude - start
    const beatLevel = data.data.score
    setAmplitude('beat_level', beatLevel)
    trackAmplitude('beat_level', { value: beatLevel })
    // Amplitude - end

    return data
}
