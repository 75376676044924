import { useCallback, useMemo, useState } from 'react'

import { ScreenCoreConceptsQuestion } from './CoreConceptsQuestion'
import { ScreenCoreConceptsQuestion01 } from './CoreConceptsQuestion01'
import { ScreenCoreConceptsQuestion02 } from './CoreConceptsQuestion02'
import { Session10Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { dataCoreConcepts, sesstion10Questions } from '../../../../../lib/data/session-10'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'
import { useReplacePage } from '../../replacePage'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? 4 : step.sub_step)
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataCoreConcepts[isButtonBack ? 4 : step.sub_step])

  const numScreens = dataCoreConcepts.length

  const replacePage = useReplacePage()

  const saveScreen = (screen: any) => {
    if (screen !== 1) {
      replacePage()
    }
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConcepts[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: number) => {
      params && onUpdate(params)
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    const srcImg = contentScreen.img?.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${contentScreen.img}` : `/images/my-adresso-journey/session/10/${contentScreen.img}`

    switch (screen) {
      case 0:
        const ssQuestion01 = [sesstion10Questions[13]]

        return (
          <ScreenCoreConceptsQuestion01
            answers={answers}
            contentScreen={contentScreen}
            questions={ssQuestion01}
            showModal={showModal}
            backScreen={() => handleBackScreen(screen)}
            onUpdate={(params: object) => handleUpdate(params, screen)}
          />
        )
      case 1:
        const ssQuestion02 = [sesstion10Questions[14]]

        return (
          <ScreenCoreConceptsQuestion02
            answers={answers}
            contentScreen={contentScreen}
            questions={ssQuestion02}
            showModal={showModal}
            backScreen={() => handleBackScreen(screen)}
            onUpdate={(params: object) => handleUpdate(params, screen)}
          />
        )
      case 3:
        const ssQuestion = [sesstion10Questions[12]]

        return (
          <ScreenCoreConceptsQuestion
            answers={answers}
            contentScreen={contentScreen}
            questions={ssQuestion}
            showModal={showModal}
            backScreen={() => handleBackScreen(screen)}
            onUpdate={(params: object) => handleUpdate(params, screen)}
          />
        )
      default:
        return (
          <Session10Wrapper buttons={buttons}>
            <div className="session-style">
              {contentScreen.title && <h4>{contentScreen.title}</h4>}

              {contentScreen.img ? (
                srcImg.includes('.mp4') ? (
                  <video className="block h-auto w-full" autoPlay muted controls>
                    <source src={srcImg} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={srcImg} alt="" />
                )
              ) : (
                ''
              )}

              <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
            </div>
          </Session10Wrapper>
        )
    }
  }, [screen, buttons])

  return <>{screenCoreConcepts}</>
}
