import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Wrapper, SectionCommon, Loading } from '../Common'
import { SessionItem } from './SessionItem'
import { Session, SessionStatus } from '../../models/session'
import { useLocales } from '../../locales'

type Props = {
  sessions?: Session[]
  isLoading?: boolean
}

export const MyAdessoJourneyContent = ({ sessions, isLoading }: Props) => {
  const { t } = useLocales()
  const myAdessoJourneyContent = useMemo(() => {
    if (isLoading) return <Loading />
    const numberSession = sessions?.length || 0
    const indexCurrentLesson = (sessions || []).findIndex((session) => session.sessionStatus === SessionStatus.Current)
    const percentProcess = indexCurrentLesson > 0 ? (indexCurrentLesson / numberSession) * 100 : ((numberSession - 1) / numberSession) * 100

    return (
      <div className="relative grid grid-cols-1 gap-2 md:grid-cols-4 custom834:grid-cols-1 custom1194:grid-cols-1">
        <div className="absolute left-[70px] top-[60px] z-[1] h-[calc(100%-121px)] w-[3px] bg-color-add-goal-secondary md:hidden custom834:block custom1194:block" />
        <div className={`absolute left-[70px] top-[60px] z-[2] w-[3px] bg-color-violet md:hidden custom834:block custom1194:block`} style={{ height: `${percentProcess}%` }} />

        {(sessions || []).map((session, index) => {
          const isLink = session.sessionStatus === SessionStatus.Current || session.sessionStatus === SessionStatus.Done
          return isLink ? (
            <Link key={`maj-session-${session.slug}-link`} to={`/my-adesso-journey/${session.slug}`}>
              <SessionItem session={session} />
            </Link>
          ) : (
            <Link
              key={`maj-session-${session.slug}-unlink`}
              to="/"
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              <SessionItem key={`maj-session-${session.slug}-unlink`} session={session} />
            </Link>
          )
        })}
      </div>
    )
  }, [isLoading, sessions])

  return (
    <Wrapper className="min-h-[calc(100vh-74px)] space-y-5 pt-0">
      <SectionCommon className="space-y-4" title={t('mainMenuAndSection.myAdessoJourney')}>
        {myAdessoJourneyContent}
      </SectionCommon>
    </Wrapper>
  )
}
