import { useCallback } from 'react'
import { AdessoError } from '../lib/error'
import { getDailyTrackingQuestions } from '../lib/request/get-daily-tranking-questions'
import { postUpdateDailyTrackingAnswer } from '../lib/request/post-update-daily-tracking-answer'
import { getDailyTrackingHistory, getCalendarHistory } from '../lib/request/get-daily-tracking-history'
import { getDailyTrackingDetail } from '../lib/request/get-daily-tracking-detail'
import { MODAL_TYPES, useGlobalModalContext } from './modals/global-modal'

export function useDailyTracking() {
  const { showGlobalModal } = useGlobalModalContext()

  const getDailyTracking = useCallback(async () => {
    try {
      return await getDailyTrackingQuestions()
    } catch (error) {
      throw new AdessoError('Get Daily Tracking Questions Failed')
    }
  }, [])

  const getDailyTrackingQuestionDetail = useCallback(async (dailyTrackingId: number) => {
    try {
      return await getDailyTrackingDetail(dailyTrackingId)
    } catch (error) {
      throw new AdessoError('Get Daily Tracking Detail Failed')
    }
  }, [])

  const getDailyTrackingHistoryData = useCallback(async (data_daily_tracking_history: any) => {
    try {
      return await getDailyTrackingHistory(data_daily_tracking_history)
    } catch (error) {
      throw new AdessoError('Get Daily Tracking History Failed')
    }
  }, [])

  const updateDailyTrackingAnswerQuestions = useCallback(async (daily_tracking_id: number, data_daily_tracking: any) => {
    try {
      const data = await postUpdateDailyTrackingAnswer(daily_tracking_id, data_daily_tracking)

      showGlobalModal(MODAL_TYPES.ADD_BEAT, data.data)

      return data
    } catch (error) {
      throw new AdessoError('Update daily tracking answer questions id Failed')
    }
  }, [])

  const getCalendarHistories = useCallback(async (startDate: string, endDate: string) => {
    try {
      return await getCalendarHistory(startDate, endDate)
    } catch (error) {
      throw new AdessoError('Update daily tracking answer questions id Failed')
    }
  }, [])

  return { getDailyTracking, getDailyTrackingQuestionDetail, getDailyTrackingHistoryData, updateDailyTrackingAnswerQuestions, getCalendarHistories }
}
