import { useCallback, useEffect, useState, useMemo } from 'react'
import { useMAJPage } from '../../hooks/pages/maj'
import { dataEndOfSessionTutorial } from '../../constants/introduction'
import { EndLearningJourney } from '../../components/MyTutorials/Screen/LearningJourney/EndLearningJourney'
import { MyAdessoJourneyIntroductionScreen } from '../../components/MyTutorials/Screen/LearningJourney/MyAdessoJourney'
import { useNavigate } from 'react-router-dom'

enum IntroductionScreen {
  MyAdessoJourney,
  Finish,
}

export const LearningJourneyTutorial = () => {
  const [screen, setScreen] = useState<IntroductionScreen>(IntroductionScreen.MyAdessoJourney)
  const navigate = useNavigate()
  const { dataSessions, getDataSessions } = useMAJPage()

  useEffect(() => {
    if (dataSessions?.isLoading === undefined) {
      getDataSessions()
    }
  }, [dataSessions?.isLoading])

  const handleClickNextScreen = useCallback((stepCurrent: IntroductionScreen, shouldLogout = false) => {
    setScreen(stepCurrent + 1)
  }, [])

  const handleClickCloseDemo = useCallback((stepCurrent: IntroductionScreen, shouldLogout = false) => {
    navigate('/my-tutorials')
  }, [])

  const majContent = useMemo(() => {
    const sessions = dataSessions?.data?.sessions || undefined
    const isLoading = dataSessions?.isLoading || undefined

    return <MyAdessoJourneyIntroductionScreen sessions={sessions} isLoading={isLoading} nextScreen={() => handleClickNextScreen(screen)} closeScreen={() => handleClickCloseDemo(screen)} />
  }, [screen, dataSessions, handleClickNextScreen])

  switch (screen) {
    case IntroductionScreen.MyAdessoJourney:
      return majContent
    case IntroductionScreen.Finish:
      return <EndLearningJourney text={dataEndOfSessionTutorial.text} linkPages={dataEndOfSessionTutorial.pages} />

    default:
      return <></>
  }
}
