import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts01 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Exercise is the best medicine. Get your move on!</h4>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_001.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_001.jpg`} alt="" />
          </picture>
          <p>
            Have you ever seen someone that looks 20 years younger than their actual age and wondered how that could be? Most of the time this is because of exercise.
            <br />
            <br />
            There’s a transformative power in exercise. It heals hearts, restores vitality, effects weight loss, improves your mood, and generally makes people dramatically healthier.
            <br />
            <br />
            An exhaustive analysis of 33 different trials involving exercise and death rates in more than 187,000 subjects found the higher cardiovasacular fitness the lower the death rate.{' '}
            <sup>1</sup>
            <br />
            <br />
            At the same time, 60% of American men and women over thirty-five are physically inactive. <sup>2</sup>
          </p>

          <ul className="text-[10px]">
            <li>
              <a className="text-black" href="https://pubmed.ncbi.nlm.nih.gov/19454641/" target="_blank">
                1. https://pubmed.ncbi.nlm.nih.gov/19454641/
              </a>
            </li>
            <li>
              <a className="text-black" href="https://www.cdc.gov/nccdphp/sgr/adults.htm" target="_blank">
                2. https://www.cdc.gov/nccdphp/sgr/adults.htm
              </a>
            </li>
          </ul>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
