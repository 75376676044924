// Import the necessary React and TailwindCSS components
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getDateFromDayOfWeek } from './dataUtils'

// Define a type for the props which will have an array of numbers (0-5)
type HeartRateProps = {
  values: number[]
  title: string
  text: string
  icon: string
  color: string
}

type DayValueProps = {
  values: number[]
  title: string
}

const DaysAndValues: React.FC<DayValueProps> = ({ values, title }) => {
  const navigate = useNavigate()

  const handleClick = (day: number) => {
    const date = getDateFromDayOfWeek(day)
    navigate(`/daily-tracking?label=${title}&date=${date}`)
  }

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const icons: { [key: number]: string } = {
    1: 'bg-heartIconWorriedHeart',
    2: 'bg-heartIconWorriedHeart',
    3: 'bg-heartIconNeutralHeart',
    4: 'bg-heartIconCheerfulHeart',
    5: 'bg-heartIconCheerfulHeart',
  }

  const getIconForValue = (value: number, index: number): JSX.Element => {
    return <div onClick={() => value && handleClick(index)} className={`h-[21px] w-[23px] ${value && 'cursor-pointer'} ${icons[value]} bg-center  bg-no-repeat  brightness-0 invert filter`} />
  }

  const rearrangedValues = [values[6], ...values.slice(0, 6)]

  return (
    <div className="my-2 grid w-full grid-cols-7 gap-8 md:gap-20">
      {days.map((day, index) => (
        <div key={index} className="font-['Proxima Nova'] flex flex-col items-center text-[11px] font-[500] leading-[10px]">
          <span className="mb-2">{day}</span>
          {getIconForValue(rearrangedValues[index], index)}
        </div>
      ))}
    </div>
  )
}

const HeartRateComponent: React.FC<HeartRateProps> = ({ values, title, text, icon, color }) => {
  const HeartIcon = () => <div className={`h-[21px] w-[25px] ${icon} bg-center  bg-no-repeat  brightness-0 invert filter`}></div>

  return (
    <>
      <div className="mt-3">
        <p className="font-['Proxima Nova']  text-[15px] font-[600] leading-[21px]">{title}</p>
        <div className="flex justify-center">
          <div className={`mt-3 flex h-[119px] w-full flex-col justify-around rounded-[15px]  px-4 py-2  text-white`} style={{ backgroundColor: color }}>
            <div className="h-[21px] w-[25px]">
              <HeartIcon />
            </div>
            <DaysAndValues values={values} title={title} />
            <div className="  self-start text-[11px] font-[500] leading-[10px]">{text}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeartRateComponent
