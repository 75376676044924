import { useCallback, useMemo, useState } from 'react'

import { ScreenCoreConceptsQuestion } from '../../session-08/screen/CoreConceptsQuestion'
import { Session08Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { dataCoreConcepts, dataCoreConceptsMores01, dataCoreConceptsMores02, sesstion08Questions } from '../../../../../lib/data/session-08'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers?: any
  isButtonBack: boolean
}

const ssQuestions = {
  1: [sesstion08Questions[8], sesstion08Questions[9], sesstion08Questions[10]],
  2: [sesstion08Questions[11]],
  3: [sesstion08Questions[12], sesstion08Questions[13]],
  4: [sesstion08Questions[14]],
  5: [sesstion08Questions[15], sesstion08Questions[16], sesstion08Questions[17]],
}

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? 14 : step.sub_step)
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataCoreConcepts[isButtonBack ? 14 : step.sub_step])
  const numScreens = dataCoreConcepts.length

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConcepts[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: number) => {
      params && onUpdate(params)
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const normalContent = useMemo(() => {
    if (contentScreen.id == 11) {
      return (
        <div className="space-y-6">
          <p>
            We’ve walked you through numbers that are very important screening numbers, as well as the CPET, which we find to be incredibly important screening tests. If your numbers are high or you
            have symptoms or you have a family history, your provider might want you to get other tests to help with your diagnosis.
          </p>
          <div className="flex flex-wrap justify-between">
            {dataCoreConceptsMores02.map((item, index) => (
              <div key={item.id} className="mb-5 w-[calc(50%-6px)] space-y-2">
                <img
                  className="cursor-pointer"
                  src={`/images/my-adresso-journey/session/08/${item.img}`}
                  alt=""
                  onClick={() => {
                    setContentScreen(dataCoreConcepts[index + 11] || '')
                    setScreen(index + 11)
                  }}
                />
                <div
                  className="w-max cursor-pointer text-[16px] font-bold leading-[19px]"
                  onClick={() => {
                    setContentScreen(dataCoreConcepts[index + 11] || '')
                    setScreen(index + 11)
                  }}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    } else if (contentScreen.id == 0) {
      return (
        <div>
          <p>
            First, let’s discuss the numbers you can typically get by talking to your doctor. Choose a risk factor from the menu. Learn about it and if you have the number from a recent doctor’s
            visit, you can enter it now or talk to your doctor and you can come back and fill these numbers out in your heart chart (link).
          </p>
          <div className="flex flex-wrap justify-between">
            {dataCoreConceptsMores01.map((item, index) => (
              <div key={item.id} className="mb-5 w-[calc(50%-6px)] space-y-2">
                <img
                  className="cursor-pointer"
                  src={`/images/my-adresso-journey/session/08/${item.img}`}
                  alt=""
                  onClick={() => {
                    setContentScreen(dataCoreConcepts[index + 1] || '')
                    setScreen(index + 1)
                  }}
                />
                <div
                  className="w-max cursor-pointer text-[16px] font-bold leading-[19px]"
                  onClick={() => {
                    setContentScreen(dataCoreConcepts[index + 1] || '')
                    setScreen(index + 1)
                  }}
                >
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    } else {
      return <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
    }
  }, [contentScreen])

  const screenCoreConcepts = useMemo(() => {
    const srcImg = contentScreen.img?.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${contentScreen.img}` : `/images/my-adresso-journey/session/08/${contentScreen.img}`

    switch (screen) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        const ssQuestion = ssQuestions[screen as keyof typeof ssQuestions]
        return (
          <ScreenCoreConceptsQuestion
            answers={answers}
            contentScreen={contentScreen}
            questions={ssQuestion}
            showModal={showModal}
            backScreen={() => handleBackScreen(screen)}
            onUpdate={(params: object) => handleUpdate(params, screen)}
          />
        )
      default:
        return (
          <Session08Wrapper buttons={buttons}>
            <div className="session-style">
              {contentScreen.title && <h4>{contentScreen.title}</h4>}

              {contentScreen.img ? (
                srcImg.includes('.mp4') ? (
                  <video className="block h-auto w-full" autoPlay muted controls>
                    <source src={srcImg} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={srcImg} alt="" />
                )
              ) : (
                ''
              )}

              {normalContent}
            </div>
          </Session08Wrapper>
        )
    }
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal])

  return <>{screenCoreConcepts}</>
}
