import { useLocation, useNavigate } from 'react-router-dom'

export { default as MyHealthPage } from './my-health'
export { default as HeartScoreMyHealthPage } from './heart-score'
export { default as LabTestResultsPage } from './lab-test-results'
export { default as MedicationsRxPage } from './medications-rx'
export { default as MovementRxPage } from './movement-rx'
export { default as NourishRxPage } from './nourish-rx'
export { default as SupplementsRxPage } from './supplements-rx'
export { default as LifeStyleRxPage } from './lifeStyle-rx'

export const cleanUrl = (url: string): string => {
  return url.replace(/\/+/g, '/')
}

export const useHandlePreviousUrl = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const previousUrl = searchParams.get('previousUrl')
  const tabGtcFromDT = Number(searchParams.get('tab-gtc-from-dt') as string)
  const tabDtFromGtc = Number(searchParams.get('tab-dt-from-gtc') as string)

  const url = `${previousUrl ? previousUrl : ''}` + `${tabGtcFromDT ? `/?tab-gtc-from-dt=${tabGtcFromDT}` : ''}` + `${tabDtFromGtc ? `/?tab-dt-from-gtc=${tabDtFromGtc}` : ''}`

  const navigate = useNavigate()

  const handlePreviousUrl = () => {
    navigate(`${url ? cleanUrl(url) : '/my-health'}`)
    localStorage.removeItem('previousUrl')
  }

  return handlePreviousUrl
}
