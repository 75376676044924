import axios from 'axios'
import { AdessoError } from "../error";

export async function postUpdateUserScreen ( params: any ): Promise<any> {
    const { data, status } = await axios.post( `/users/my-screen`, params )

    if ( status !== 200 ) {
        throw new AdessoError( `Request update user setting noti error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
