import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            In this session, we’re going to talk about the importance of movement to the overall health of your heart. We’ll discuss what it means to “get your move on” and to live a life that helps
            your body support your heart through an active lifestyle.
            <br />
            <br />
            Click the video below to begin.
          </p>

          <VimeoEmbed embedId="808486389" />
        </div>
      </Session02Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
