import { useMemo } from 'react'
import { useBoolean } from '../../../hooks/use-boolean'
import { Link } from 'react-router-dom'

type Props = {
  tracked?: number
  slug?: any
  refs?: any
}

export const TrackerAction = ({ refs, tracked, slug }: Props) => {
  const checked = useBoolean(!!tracked)
  const bg = checked.value === true ? "bg-[url('assets/images/icon/i-circle_checked.svg')]" : "bg-[url('assets/images/icon/i-circle_check.svg')]"

  const ticked = useMemo(() => {
    return (
      <div className="border-b-[1px] pb-4">
        <div className="space-y-4 text-black ">
          <div className="flex justify-between space-y-2 text-[#000]" ref={refs}>
            <h4 className="relative text-[16px] font-normal  text-black">Did you journal today?</h4>
            <div className="space-y-2">
              <div className="flex items-center justify-end pr-2">
                <div className="relative flex h-full flex-col items-center justify-center">
                  <div className="absolute h-[14px] w-[14px] bg-[url('assets/images/icon/i-tick.svg')] bg-no-repeat"></div>
                  <div className={`${bg} h-[36px] w-[36px] bg-no-repeat`}></div>
                </div>
              </div>
            </div>
          </div>
          {!tracked && (
            <div className="flex justify-end pr-2">
              <Link className="text-[14px] font-normal leading-[34px] text-[#950057] underline decoration-color-primary" to={`/heart-journal?slug=${slug}`}>
                Go to Heart Journal
              </Link>
            </div>
          )}
        </div>
      </div>
    )
  }, [tracked, checked])

  return <>{ticked}</>
}
