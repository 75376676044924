import { ChangeEvent } from 'react'

type Props = {
  id?: string
  type?: string
  name: string
  value?: string
  placeholder?: string
  label?: string
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const InputStyle = ({ id, type, name, value, placeholder, label, onChange }: Props) => {
  return (
    <div>
      {label && <label className=" text-gray-400">{label}</label>}
      <input
        className="mt-2 mb-2 w-full border-b border-gray-500 bg-transparent font-semibold placeholder-pink-500 outline-none  focus:border-gray-400"
        id={id}
        type={type ?? 'text'}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}
