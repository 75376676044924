import {useCallback, useEffect, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {LoginContent} from '../components/Login/'
import {LoginOptions} from '../models/login'
import {useLoginPage} from '../hooks/pages/login'
import {OAuthContent} from "../components/OAuth";
import {useOAuthPage} from "../hooks/pages/oauth";

export const OAuthPage = () => {
  const navigate = useNavigate()
  const {token} = useParams()

  const {dataResult, getToken} = useOAuthPage()

  useEffect(() => {
    if (token === undefined) {
      navigate('/login')
    } else {
      getToken(token)
    }
    if (dataResult?.data) navigate('/dashboard')

    if (!dataResult?.data?.success && dataResult?.isLoading === false){
      navigate('/login')
    }
  }, [dataResult?.data, navigate])

  const oauthContent = useMemo(() => {
    const isLoading = dataResult?.isLoading || false

    return <OAuthContent isLoading={isLoading}/>
  }, [dataResult])

  return <>{oauthContent}</>
}
