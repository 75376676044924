import { mapDataModalProgress, mapDataHeartRickFactors } from '../maps/session'
import { mapQuestion, mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion07Questions: Question[] = [
  mapQuestion('ss07__005', QuestionTypes.TextArea, 'What difficulties have you had navigating the medical system as a woman?', 'Describe what you want to change.'),
  mapQuestion('ss07__006', QuestionTypes.TextArea, 'What steps are you taking to ensure you prevent a serious heart emergency?', 'Describe what you want to change.'),
  mapQuestion(
    'ss07__007',
    QuestionTypes.TextArea,
    'After learning more about emergency symptoms, is there any action that you want to take to address your heart health or speak with a healthcare provider about?',
    'Describe what you want to change.'
  ),
  mapQuestion('ss07__008', QuestionTypes.TextArea, 'Are you happy with your healthcare provider or do you think that you might be able to find a better fit?', 'Describe what you want to change.'),
  mapQuestion('ss07__001', QuestionTypes.Radio, 'When was your last check-up/physical?', undefined, ['A few months ago', 'A year ago', 'A few years ago']),
  mapQuestion('ss07__002', QuestionTypes.Rate, 'How comfortable do you feel visiting your health provider’s office for routine check-ups or to address a health concern?'),
  mapQuestion('ss07__003', QuestionTypes.Rate, 'Rate yourself on how well you communicate your symptoms with your health provider.'),
  mapQuestion('ss07__004', QuestionTypes.Rate, 'Rate yourself on how well you advocate for your needs with your health provider.'),
]

export const sesstion10Questions: Question[] = [
  mapQuestionNotRequired('ss10__013', QuestionTypes.TextArea, 'What was the biggest change you saw in yourself and your perspective in this experience?', 'Describe what you want to change.'),
  mapQuestionNotRequired(
    'ss10__014',
    QuestionTypes.TextArea,
    'What would you like to focus on as you continue to move forward in your journey of maintaining a healthy heart?',
    'Describe what you want to change.'
  ),
  mapQuestionNotRequired('ss10__001', QuestionTypes.Rate, 'How confident are you that you’ve made significant changes that will improve your heart health through this experience?'),
  mapQuestionNotRequired('ss10__002', QuestionTypes.Rate, '<span class="text-black">Increasing Movement</span>'),
  mapQuestionNotRequired('ss10__003', QuestionTypes.Rate, '<span class="text-black">Improving Nourishment Habits</span>'),
  mapQuestionNotRequired('ss10__004', QuestionTypes.Rate, '<span class="text-black">Improving Sleep</span>'),
  mapQuestionNotRequired('ss10__005', QuestionTypes.Rate, '<span class="text-black">People, passion, and purpose</span>'),
  mapQuestionNotRequired('ss10__006', QuestionTypes.Rate, '<span class="text-black">Knowing Your Heart</span>'),
  mapQuestionNotRequired('ss07__007', QuestionTypes.Radio, 'When would you like for us to notify you that it’s time to retake your heart score?', undefined, [
    'I don’t want to retake it',
    'Four Months',
    'Six Months',
    // '>/= 500',
  ]),
  mapQuestionNotRequired('ss10__b001', QuestionTypes.Rate, 'To what degree did Adesso help you make progress on your heart health goals?'),
  mapQuestionNotRequired('ss10__b002', QuestionTypes.Rate, 'How likely are you to refer Adesso to a friend, family member or colleague? '),
  mapQuestionNotRequired('ss10__b003', QuestionTypes.TextArea, 'Please share any additional feedback that would make your Adesso experience even better!', 'Enter your feedback here.'),
  mapQuestionNotRequired('ss10__b004', QuestionTypes.Radio, 'Would you like to stay informed of upcoming events?', undefined, ['Yes', 'No']),
  mapQuestionNotRequired('ss10__mynow', QuestionTypes.TextArea, '', 'My Now'),
  mapQuestionNotRequired('ss10__b005', QuestionTypes.Radio, 'How often would you like for us to remind you to check in?', undefined, ['Daily', 'Weekly', 'Every Two Months', 'Every Month']),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  return { id, img, title, description, content }
}

const contentCoreConcepts01 = `
<p>Now that you’ve made it through this journey, let’s discuss a few ways you can continue on.</p>

<p>Remember the “Now” you established at the beginning of this process?</p>
`

const contentCoreConcepts02 = `
<p>Now that you know the areas that are key to sustaining a heart healthy lifestyle, you can continue to focus on your habits.</p>
<p>Here are the heart goals you’ve currently set for yourself.</p>
<p>
    Are there any goals you’d like to change? MyAdesso is here to support you in sustaining the energy you’ve brought to this learning journey.
    <br />
    Remember, this is marathon and not a sprint. We’ll continue providing you with reminders to check in on the areas that are a priority to you.
</p>
`

const contentCoreConcepts03 = `
<p>Beyond this initial journey, you can now begin focusing on the areas where you want to learn more and continue to improve.</p>
<p>Specify the content you’d like for MyAdesso to recommend to you and we will adapt future content based on your needs.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/10/img_001.webp" type="image/webp">
  <img src="images/my-adresso-journey/session/10/img_001.png" alt="">
</picture>
`
const contentCoreConcepts04 = `
<p>MyAdesso is building a community of women who are supporting one another towards happier, healthier heart-centered lives.</p>

<p>We are planning a full slate of ongoing activities like virtual cookalongs with our chefs, to close-circle groups for those continuing the MyAdesso wellness journey.</p>

<p>Some will be exclusive to the MyAdesso community, or some will be invited as a VIP to events open to the general public on Adesso.Health.</p>

<p>You can use your badges to redeem for events/content on Adesso.health.</p>


`

const contentComplete = `
<p>Beyond this initial journey, you can now begin focusing on the areas where you want to learn more and continue to improve.</p>
<p>Visit the categories you want to focus on in the Go Deeper sections below, use your daily tracker, and check in on your data and Rx’s to continue your journey.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/10/img_001.webp" type="image/webp">
  <img src="images/my-adresso-journey/session/10/img_001.png" alt="">
</picture>
`

export const dataCoreConcepts = [
  mapCoreConcepts(1, undefined, 'Revisiting Your Now', '', contentCoreConcepts01),
  mapCoreConcepts(2, undefined, 'Sustaining your habits', '', contentCoreConcepts02),
  mapCoreConcepts(3, undefined, 'Continue learning', '', contentCoreConcepts03),
  mapCoreConcepts(4, undefined, 'Accountability and Connection', '', contentCoreConcepts04),
  mapCoreConcepts(5, 'ss_10.mp4', '“Bravo! Let’s celebrate your success.”', '', ''),
]

export const dataComplete = mapCoreConcepts(3, undefined, 'Continue learning', '', contentComplete)

const coreConceptsMore = `<h4>coreConceptsMore</h4>`

export const dataCoreConceptsMores: CoreConceptsStops[] = [mapCoreConcepts(9, 'img_003.png', 'Work', '', coreConceptsMore)]
