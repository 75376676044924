import { useCallback, useMemo, useState } from 'react'

import { ScreenCoreConceptsMore } from './CoreConceptsMore'
import { Session09Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { dataCoreConceptsMores, CoreConceptsMores09 } from '../../../../../lib/data/session-09'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  isButtonBack: boolean
}

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, step, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? 4 : step.sub_step)
  const [contentScreen, setContentScreen] = useState<CoreConceptsMores09>(dataCoreConceptsMores[isButtonBack ? 4 : step.sub_step])

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConceptsMores[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConceptsMores[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <Session09Wrapper buttons={buttons}>
            <div className="session-style">
              <video className="block h-auto w-full" controls autoPlay muted>
                <source src="https://api.myadesso.health/uploads/animation/ss_09.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Session09Wrapper>
        )

      default:
        return <ScreenCoreConceptsMore showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
    }
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal])

  return <>{screenCoreConcepts}</>
}
