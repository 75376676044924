import { useMemo } from 'react'

type Props = {
  data: any
  showDetail: () => void
}

export const SelectedItem = ({ data, showDetail }: Props) => {
  const journalItem = useMemo(() => {
    const isRead = data.status === 1
    return (
      <button className={`flex w-full items-center space-x-2 rounded-[15px] px-4 py-2 ${isRead ? 'bg-ma-pink-500' : 'bg-ma-violet'}`} onClick={showDetail}>
        <div className={`h-8 w-8 rounded-full border-[1px] border-white ${isRead ? 'bg-white' : ''}`}>
          {isRead ? <div className="h-[30px] w-[30px] bg-[url('assets/images/icon/i-check__pink.svg')] bg-[length:16px_auto] bg-center bg-no-repeat" /> : <div className="h-[30px] w-[30px]"></div>}
        </div>
        <div className="text-left text-[15px] font-bold uppercase tracking-[0.15px] text-white" dangerouslySetInnerHTML={{ __html: data.description }} />
      </button>
    )
  }, [data])

  return <>{journalItem}</>
}
