import { ChangeEvent, useMemo } from 'react'

import { Question } from '../../../models/question'

type Props = {
  isInputSS?: boolean
  isDefault?: boolean
  isNumber?: boolean
  children?: React.ReactNode
  value?: string
  question: Question
  typeInput?: string
  min?: number
  max?: number
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const InputField = ({ isInputSS, isDefault, isNumber, value, question, typeInput, min, max, onChange }: Props) => {
  const className = isDefault ? 'bg-transparent px-2' : isInputSS ? 'bg-ma-pink-100 px-3' : 'px-3'
  const inputField = useMemo(() => {
    return (
      <input
        type={typeInput ?? 'text'}
        min={min}
        max={max}
        value={value}
        id={question.name}
        name={question.name}
        className={`h-[46px] w-full border-transparent placeholder:text-ma-gray-900/50 focus:border-transparent focus:outline-0 ${className}`}
        placeholder={question.placeholder}
        onChange={onChange}
      />
    )
  }, [value, isDefault, question])

  return <>{inputField}</>
}
