import { useEffect, useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'
import { useMovementRxPage } from '../../../../../hooks/use-movement-rx'
import { ButtonRx } from '../../ButtonRx'
import { useLocales } from '../../../../../locales'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
  values: object
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts08 = ({ values, buttons }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useLocales()

  const { movementRxResult, useGetMovementRx } = useMovementRxPage()
  const dataMovement = movementRxResult?.data?.movements

  useEffect(() => {
    useGetMovementRx()
  }, [])

  const lowContent = useMemo(() => {
    return (
      <>
        <p>
          This is a great place to start. Decide on something you enjoy that you can do that gets your heart rate to this target rate (plus or minus 10) for roughly 20-30 minutes, 4-5 times a week.
          Make it enjoyable, but something that pushes you a little bit each time, like walking faster or even mixing in a slight bit of jogging. Or a dance class! Be creative.
        </p>
        <p>You’ll want to slowly increase your heart rate to a moderate level over the course of this program.</p>
        <p>Next let’s choose some Target Movements that will help you get to your Target Heart Rate.</p>
      </>
    )
  }, [])

  const moderateContent = useMemo(() => {
    return (
      <>
        <p>
          You’re right in the sweet spot. As you exercise, try and maintain your heart rate (plus or minus 10) for 30 minutes, 5 times a week. And don’t be afraid to mix in different activities to
          keep it fun and fresh. Whatever works for you. You may gradually move to high intensity after a few months.
        </p>
        <p>Next let’s choose some Target Rhythms that will help you get to your Target Heart Rate.</p>
      </>
    )
  }, [])

  const highContent = useMemo(() => {
    return (
      <>
        <p>
          Fantastic! You’re really moving. As you exercise, try and maintain your heart rate (plus or minus 10) for 30 minutes, 5 times a week. And don’t be afraid to mix in different activities to
          keep it fun and fresh. Whatever works for you.
        </p>
        <p>Here are some things to keep in mind:</p>
        <ul className="list-disc space-y-6 pl-8 ">
          <li>High intensity workouts should be done on an interval basis.</li>
          <li>Weave in moderate intensity workouts for a balanced heart-healthy workout routine.</li>
        </ul>
        <p>Next let’s choose some Target Movements that will help you get to your Target Heart Rate.</p>
      </>
    )
  }, [])

  const screenCoreConcepts = useMemo(() => {
    // @ts-ignore
    const value = (values && values['ss02__028']) || undefined
    // @ts-ignore
    const age = (values && values['ss02__027']) || 0

    let valuePoint = 72
    let content = highContent

    if (value === 'I don’t exercise regularly') {
      valuePoint = 40
      content = lowContent
    } else if (value === 'I have an active lifestyle with sporadic exercise') {
      valuePoint = 50
      content = moderateContent
    }

    const calcAge = ((220 - age) * valuePoint) / 100

    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Your Target Heart Rate</h4>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_008.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_008.jpg`} alt="" />
          </picture>
          <p>Your target heart rate should be: {value && age ? (calcAge < 70 ? 70 : calcAge) : dataMovement?.target_heart_rate}</p>
          {content}
          <ButtonRx title={t('myAdessoJourney.session2.buttonRx')} handleClick={() => navigate(`/my-health/move-rx/?previousUrl=${location.pathname}`)} />
        </div>
      </Session02Wrapper>
    )
  }, [values, buttons, movementRxResult?.data?.movements, dataMovement])

  return <>{screenCoreConcepts}</>
}
