import { useState, useMemo } from 'react'
import { Wrapper, Container, TitlePage, FormInput, SubmitButtonCommon, ButtonStyles } from '../Common'
import { useFormik } from 'formik'
import { postAddCoupon } from '../../lib/request/post-add-coupon'
import { postAddAccessCode } from '../../lib/request/post-add-access-code'
import { useAuth } from '../../hooks/use-auth'

type props = {
  nextScreen: () => void
  lastScreen: () => void
}

export const Screen2 = ({ nextScreen, lastScreen }: props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const auth = useAuth()

  const formik = useFormik({
    initialValues: { coupon: '' },
    onSubmit: async (values) => {
      if (values.coupon == '') {
        nextScreen()
      } else {
        const res = await postAddCoupon(values)

        if (res.data.status === 'success') {
          nextScreen()
        } else {
          setErrorMessage(res.data.message)
        }
      }
    },
  })

  const formCode = useFormik({
    initialValues: { access_code: '' },
    onSubmit: async (values) => {
      const res = await postAddAccessCode(values)

      if (res.data.status === 'success') {
        auth.getMeResult().catch()
        lastScreen()
      } else {
        setErrorCode(res.data.message)
      }
    },
  })
  const confirmScreen = useMemo(() => {
    return (
      <Wrapper className=" bg-cover bg-center bg-no-repeat text-ma-md text-white">
        <div className="fixed bottom-0 left-0 right-0 top-20 z-10 bg-[url('/backgrounds/bg-register.webp')] bg-cover bg-center bg-no-repeat md:bg-[url('/backgrounds/bg-register-md.webp')]"></div>

        <Container className="z-20">
          <TitlePage title="Welcome to the MyAdesso Journey!" subTitle="Please enter you access code" />
          <div className="space-y-[21px]">
            <form className="w-full space-y-6 text-black" onSubmit={formCode.handleSubmit}>
              <div className="space-y-4">
                <FormInput type="text" name="access_code" placeholder="Access Code" value={formCode.values.access_code} onChange={formCode.handleChange} />
                {errorCode !== '' && <p className="text-rose-700">{errorCode}</p>}
              </div>
              <SubmitButtonCommon isLoading={isLoading} width="w-full" height="h-[53px]" text="SUBMIT" style={ButtonStyles.Dark} onClick={() => formCode.handleSubmit} />
            </form>
          </div>
          <div className="!mt-20"></div>
          <TitlePage title="No access code? No problem." subTitle="You can pay $199 to start your personalized MyAdesso Journey right now. Click here to enter your payment information and begin." />
          <div className="space-y-[21px]">
            <form className="w-full space-y-6 text-black" onSubmit={formik.handleSubmit}>
              <div className="space-y-4">
                <FormInput type="text" name="coupon" placeholder="Coupon" value={formik.values.coupon} onChange={formik.handleChange} />
                {errorMessage !== '' && <p className="text-rose-700">{errorMessage}</p>}
              </div>
              <SubmitButtonCommon isLoading={isLoading} width="w-[150px]" height="h-[53px]" text="NEXT" style={ButtonStyles.Light} onClick={() => formik.handleSubmit} />
            </form>
          </div>
        </Container>
      </Wrapper>
    )
  }, [formik, formCode])

  return <>{confirmScreen}</>
}
