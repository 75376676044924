import axios from 'axios'
import { GoalFromApiResponse } from '../../models/goal'
import { AdessoError } from '../error'

type UpdateDailyTrackingAnswerResponse = {
  success: boolean
  data: any | undefined
  message: string
}

export async function postUpdateDailyTrackingAnswer(daily_tracking_id: number, data_daily_tracking: any): Promise<UpdateDailyTrackingAnswerResponse> {
  const { data, status } = await axios.post(`goal-tracking/${daily_tracking_id}/answers`, data_daily_tracking)
  if (status !== 200) {
    console.info('postUpdateDailyTrackingAnswer', data)
    throw new AdessoError(`Request update daily tracking answer error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
