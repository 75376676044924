import { Goal } from '../models/goal'

export const IntroductionTabDT: Goal[] = [
  {
    id: 4,
    name: 'Commit To My Heart',
    slug: 'commit-to-my-heart',
    order: 1,
    textColor: '#ffffff',
    backgroundColor: '#ff3399',
    added_at: null,
    description: 'Take a moment to reflect on living from the heart.',
    completed: false,
    showProgress: true,
    title: 'COMMIT',
    goalTrackingId: 4,
  },
  {
    id: 2,
    name: 'Move My Heart',
    slug: 'move-my-heart',
    order: 2,
    textColor: '#ffffff',
    backgroundColor: '#ff567a',
    added_at: null,
    description: 'Log Your Movements',
    completed: false,
    showProgress: true,
    title: 'MOVE',
    goalTrackingId: 2,
  },
  {
    id: 1,
    name: 'Nourish My Heart',
    slug: 'nourish-my-heart',
    order: 3,
    textColor: '#ffffff',
    backgroundColor: '#f26e57',
    added_at: null,
    description: 'Track your new nourishment habits.',
    completed: false,
    showProgress: true,
    title: 'NOURISH',
    goalTrackingId: 3,
  },
  {
    id: 3,
    name: 'Refresh My Heart',
    description: 'Track and journal your emotions and mental health practices.',
    order: 4,
    created_at: '2022-12-28T03:55:01.000000Z',
    updated_at: '2024-04-16T21:47:44.000000Z',
    status: 1,
    deleted_at: null,
    textColor: '#ffffff',
    backgroundColor: '#b7005e',
    slug: 'refresh-my-heart',
    title: 'REFRESH',
    goalTrackingId: 7,
  },
  {
    id: 5,
    name: 'Relate For My Heart',
    description: 'Track and journal about your relationships.',
    order: 5,
    created_at: '2022-12-28T03:55:01.000000Z',
    updated_at: '2024-02-29T09:02:47.000000Z',
    status: 1,
    deleted_at: null,
    textColor: '#ffffff',
    backgroundColor: '#974a9a',
    slug: 'relate-for-my-heart',
    title: 'RELATE',
    goalTrackingId: 6,
  },
]

export const IntroductionTabDTDetail: any = [
  {
    id: 4,
    goal_id: 4,
    title: 'COMMIT',
    description: 'What changes do you want to make in your life?',
    sub_title: null,
    sub_description: null,
    text_color: '#ffffff',
    background_color: '#ff3399',
    goal_settings: [
      {
        id: 11,
        identifier: 'blood_pressure',
        goal_tracking_id: 4,
        type: 'both',
        title: 'Blood pressure',
        description: 'I want to monitor my blood pressure.',
        priority: 1,
        questions: [
          {
            id: 957,
            type: 'fill_blank',
            real_type: 'fill_blank',
            name: 'Your Blood Pressure Today',
            description: null,
            data_raw: {
              correct: null,
              options: [
                {
                  content: null,
                  is_correct: false,
                },
              ],
            },
            dependency: null,
            score: 1,
            order: 2,
            is_enable: null,
            sku: 'commit_002',
          },
        ],
      },
      {
        id: 12,
        identifier: 'medication',
        goal_tracking_id: 4,
        type: 'manually',
        title: 'Medication Tracking',
        description: 'I want to track my heart medications.',
        priority: 2,
        questions: [
          {
            id: 936,
            type: 'true_false',
            real_type: 'boolean',
            name: 'Did you take your medication today?',
            description: null,
            data_raw: {
              correct: '1',
              options: [
                [],
                {
                  score: 1,
                },
              ],
              main_content: null,
            },
            dependency: null,
            score: 1,
            order: 3,
            is_enable: null,
            sku: 'commit_003',
          },
        ],
      },
      {
        id: 13,
        identifier: 'smoking',
        goal_tracking_id: 4,
        type: 'manually',
        title: 'Smoking',
        description: 'I want to reduce my smoking habit.',
        priority: 3,
        questions: [
          {
            id: 937,
            type: 'fill_blank',
            real_type: 'IncreaseOrReduceButton',
            name: 'How many times did you smoke today?',
            description: null,
            data_raw: {
              correct: null,
              options: [
                {
                  content: null,
                  is_correct: false,
                },
              ],
              main_content: null,
            },
            dependency: null,
            score: 1,
            order: 4,
            is_enable: null,
            sku: 'commit_004',
          },
        ],
      },
      {
        id: 18,
        identifier: 'drinking',
        goal_tracking_id: 4,
        type: 'manually',
        title: 'Alcohol',
        description: 'I want to improve my drinking habits.',
        priority: 4,
        questions: [
          {
            id: 938,
            type: 'range',
            real_type: 'IncreaseOrReduceButton',
            name: 'How many times did you drink today?',
            description: null,
            data_raw: {
              correct: null,
              options: [
                {
                  content: null,
                  is_correct: false,
                },
              ],
              main_content: null,
            },
            dependency: null,
            score: 1,
            order: 5,
            is_enable: null,
            sku: 'commit_005',
          },
        ],
      },
      {
        id: 19,
        identifier: 'self_check_in',
        goal_tracking_id: 4,
        type: 'manually',
        title: 'Self-Check-ins',
        description: 'I want to check in with my health regularly.',
        priority: 5,
        questions: [
          {
            id: 939,
            type: 'range',
            real_type: 'rate',
            name: 'How are you feeling physically?',
            description: null,
            data_raw: {
              correct: null,
              options: [
                {
                  content: null,
                  is_correct: false,
                },
              ],
              main_content: null,
            },
            dependency: null,
            score: 1,
            order: 6,
            is_enable: null,
            sku: 'commit_006',
          },
          {
            id: 940,
            type: 'range',
            real_type: 'rate',
            name: 'How are you feeling emotionally?',
            description: null,
            data_raw: {
              correct: null,
              options: [
                {
                  content: null,
                  is_correct: false,
                },
              ],
              main_content: null,
            },
            dependency: null,
            score: 1,
            order: 7,
            is_enable: null,
            sku: 'commit_007',
          },
          {
            id: 941,
            type: 'range',
            real_type: 'rate',
            name: 'How stressed are you feeling today?',
            description: null,
            data_raw: {
              correct: null,
              options: [
                {
                  content: null,
                  is_correct: false,
                },
              ],
              main_content: null,
            },
            dependency: null,
            score: 1,
            order: 8,
            is_enable: null,
            sku: 'commit_008',
          },
        ],
      },
    ],
    questions: [
      {
        id: 936,
        type: 'true_false',
        real_type: 'boolean',
        name: 'Did you take your medication today?',
        description: null,
        data_raw: {
          correct: '1',
          options: [
            [],
            {
              score: 1,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 3,
        is_enable: 1,
        sku: 'commit_003',
      },
      {
        id: 937,
        type: 'fill_blank',
        real_type: 'IncreaseOrReduceButton',
        name: 'How many times did you smoke today?',
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 4,
        is_enable: 1,
        sku: 'commit_004',
      },
      {
        id: 938,
        type: 'range',
        real_type: 'IncreaseOrReduceButton',
        name: 'How many times did you drink today?',
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 5,
        is_enable: 1,
        sku: 'commit_005',
      },
      {
        id: 939,
        type: 'range',
        real_type: 'rate',
        name: 'How are you feeling physically?',
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 6,
        is_enable: 1,
        sku: 'commit_006',
      },
      {
        id: 940,
        type: 'range',
        real_type: 'rate',
        name: 'How are you feeling emotionally?',
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 7,
        is_enable: 1,
        sku: 'commit_007',
      },
      {
        id: 941,
        type: 'range',
        real_type: 'rate',
        name: 'How stressed are you feeling today?',
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 8,
        is_enable: 1,
        sku: 'commit_008',
      },
      {
        id: 957,
        type: 'fill_blank',
        real_type: 'fill_blank',
        name: 'Your Blood Pressure Today',
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
        },
        dependency: null,
        score: 1,
        order: 2,
        is_enable: 1,
        sku: 'commit_002',
      },
      {
        id: 958,
        type: 'range',
        real_type: 'rate',
        name: "How's your Heart Today?",
        description: null,
        data_raw: {
          correct: null,
          options: [
            {
              content: null,
              is_correct: false,
            },
          ],
          main_content: null,
        },
        dependency: null,
        score: 1,
        order: 1,
        is_enable: null,
        sku: 'commit_001',
      },
    ],
    heart_journal_action: 0,
  },
]
