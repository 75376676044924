import axios from 'axios'
import { AdessoError } from '../error'

type TMovementRxResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getMovementRx(): Promise<TMovementRxResponse> {
  const { data, status } = await axios.get(`/movements`)

  if (status !== 200) {
    console.info('getMovementRx', data)
    throw new AdessoError(`Request getMovementRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMovementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function getMovementRxById(id: number): Promise<TMovementRxResponse> {
  const { data, status } = await axios.get(`/movements/${id}`)

  if (status !== 200) {
    console.info('getMovementRxById', data)
    throw new AdessoError(`Request getMovementRxById error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMovementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function deleteMovementRx(id: number): Promise<TMovementRxResponse> {
  const { data, status } = await axios.delete(`/movements/${id}`)
  if (status !== 200) {
    console.info('deleteMovementRx', data)
    throw new AdessoError(`Request movements rx error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}

export async function postMovementManualRx(dataCreate: any): Promise<TMovementRxResponse> {
  const { data, status } = await axios.post(`/movements/manual`, dataCreate)

  if (status !== 200) {
    console.info('postMovementsManualRx', data)
    throw new AdessoError(`Request postMovementsManualRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMovementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function postMovementAutoRx(): Promise<TMovementRxResponse> {
  const { data, status } = await axios.post(`/movements/auto`)

  if (status !== 200) {
    console.info('postMovementsAutoRx', data)
    throw new AdessoError(`Request postMovementsAutoRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMovementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function putMovementRx(dataUpdate: any): Promise<TMovementRxResponse> {
  const { data, status } = await axios.put(`/movements`, dataUpdate)
  if (status !== 200) {
    console.info('putMovementRx', data)
    throw new AdessoError(`Request update movement Rx error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
