import { ChangeEvent, useMemo } from 'react'
import { FieldTypes } from '../../../models'
import { useGoalTrackingConfigPage } from '../../../hooks/pages/goal-tracking-config'
import { RadioFieldGoalTracking } from './RadioFieldGoalTracking'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../dataUtils'
import { useLocales } from '../../../locales'

type Props = {
  questions?: any
  value?: string
  id?: string
  backgroundColor?: string
  fieldType: FieldTypes
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onUpdateGoal: (data: any) => void
  isAdded?: boolean
  refs?: any
}
enum IdentifiersEnum {
  MEDICATION = 'medication',
  SUPPLEMENTS = 'supplements',
}

export const Questions = ({ id, isAdded, value, fieldType, questions, refs, backgroundColor, onChange, onUpdateGoal }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useLocales()

  const { goalSettingIdResult, updateGoalSettingId } = useGoalTrackingConfigPage()

  const is_enable = goalSettingIdResult ? goalSettingIdResult?.data?.goalSettingId?.is_enable : questions?.user_settings[0]?.is_enable

  let linkTo = ''
  if (questions.identifier === IdentifiersEnum.MEDICATION) {
    linkTo = `${IdentifiersEnum.MEDICATION}s`
  } else if (questions.identifier === IdentifiersEnum.SUPPLEMENTS) {
    linkTo = IdentifiersEnum.SUPPLEMENTS
  }
  const titleLink = capitalizeFirstLetter(linkTo)

  const navigateTo = (title: string) => {
    return `/my-health/${title}-rx/?previousUrl=${location.pathname}&tab-gtc-from-dt=${questions?.goal_tracking_id}`
  }
  const handleChangeIsEnable = async () => {
    const data = await updateGoalSettingId(questions?.id, {
      is_enable: !is_enable ?? true,
      type: is_enable ? 'none' : 'auto',
    })

    onUpdateGoal(data)
  }

  const question = useMemo(() => {
    const bg = is_enable ? "bg-[url('assets/images/icon/i-circle_checked.svg')]" : "bg-[url('assets/images/icon/i-circle_check.svg')]"
    const disabledClass = isAdded ? 'hover:cursor-pointer' : 'disabled'

    const objectMockup: any = {
      id: questions?.id,
      name: questions?.id,
      // type: 'radio',
      options: [
        {
          value: 0,
          label: 'I will enter it manually.',
        },
        {
          value: 1,
          label: 'I have a device to connect.',
        },
      ],
      link: 'Disconnect device',
    }

    return (
      <div key={questions?.id} className="space-y-2 border-b-[1px] py-3">
        <h3 className="text-[16px] font-bold">{''}</h3>
        <div className="flex justify-between">
          <div ref={refs}>
            <h4 className="font-medium uppercase" style={{ color: backgroundColor }}>
              {questions?.title}
            </h4>
            <p className="font-normal">{questions?.description}</p>
          </div>
          <div className={`relative flex h-full flex-col items-center justify-center ${disabledClass}`} onClick={() => handleChangeIsEnable()}>
            <div className="absolute h-[14px] w-[14px] bg-[url('assets/images/icon/i-tick.svg')] bg-no-repeat"></div>
            <div className={`${bg} h-[36px] w-[36px] bg-no-repeat`}></div>
          </div>
        </div>
        {questions?.type && questions?.type === 'both' && is_enable === true && (
          <div className="font-normal">
            {/* id={typeof id === 'string' ? id : undefined} value={typeof value === 'string' ? value : undefined} */}
            <RadioFieldGoalTracking question={questions} fieldType={fieldType} onUpdate={onUpdateGoal} />
          </div>
        )}
        {linkTo && (
          <div className="flex justify-end">
            <a
              href=""
              className="text-[14px] leading-none text-color-primary underline decoration-color-primary"
              onClick={(e) => {
                e.preventDefault()
                navigate(navigateTo(linkTo))
              }}
            >
              {t('GoalTrackingConfig.seeYour')} {titleLink} Rx
            </a>
          </div>
        )}
      </div>
    )
  }, [value, goalSettingIdResult])

  return question
}
