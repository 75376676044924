import { useState, useCallback, useMemo, useEffect } from 'react'
import { Session10Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { FormField, ErrorMessage } from '../../../../Common'

import { useForm } from '../../../../../hooks/use-form'
import { Question, CoreConceptsStops } from '../../../../../models'

import { useHeartJournalPage } from '../../../../../hooks/pages/heart-journal'
import { HeartJournalAllGoals } from '../../../../HeartJournal/AllGoals'
import { Goal } from '../../../../../models/goal'
import { ScreenHeartGoalTracking } from '../../layout/HeartGoalTracking'

type Props = {
  contentScreen: CoreConceptsStops
  questions: Question[]
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers?: any
}

export const ScreenCoreConceptsQuestion02 = ({ contentScreen, questions, showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)
  const { remainingGoalsResult, getRemainingGoals, addGoal } = useHeartJournalPage()

  async function addNewGoal(goal: Goal) {
    await addGoal(goal.id)
  }

  useEffect(() => {
    if (remainingGoalsResult?.data) return
    getRemainingGoals()
  }, [remainingGoalsResult?.data])

  useEffect(() => {
    setIsError(false)
  }, [contentScreen])

  const initForm = questions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const allGoalsContent = useMemo(() => {
    const goals = remainingGoalsResult?.data?.goals || undefined
    const isLoading = remainingGoalsResult?.isLoading !== false
    return <HeartJournalAllGoals noTitle={true} goals={goals} handleAddGoal={addNewGoal} isLoading={isLoading} />
  }, [remainingGoalsResult])

  const buttons = useMemo(() => {
    // return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => onUpdate(formik.values)} />
  }, [backScreen, checkSubmit, showModal, formik])

  const screenCoreConceptsQuestion = useMemo(() => {
    return (
      // <Session10Wrapper>
      //   <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
      //     <div className="session-style flex-1">
      //       {contentScreen.title && <h4>{contentScreen.title}</h4>}

      //       {contentScreen.img && <img src={contentScreen.img} alt="" />}

      //       <div className=" space-y-6">
      //         <p>Now that you know the areas that are key to sustaining a heart healthy lifestyle, you can continue to focus on your habits.</p>
      //         <p>Here are the heart goals you’ve currently set for yourself.</p>
      //         {allGoalsContent}
      //         <p> Are there any goals you’d like to change out? MyAdesso is here to support you in sustaining the energy you’ve brought to this learning journey. </p>
      //         <p> Remember, this is marathon and not a sprint. We’ll continue providing you with reminders to check in on the areas that are a priority to you.</p>
      //       </div>

      //       {questions.map((item) => {
      //         return (
      //           <FormField
      //             after={item.after || undefined}
      //             key={`ss10_${item.name}`}
      //             question={item}
      //             value={formik.values[item.name]}
      //             onChange={formik.handleChange}
      //             onChangeValue={(value: string) => handleChangeValue(value, item.name)}
      //           >
      //             {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
      //           </FormField>
      //         )
      //       })}
      //     </div>

      //     {buttons}
      //   </form>
      // </Session10Wrapper>
      <ScreenHeartGoalTracking sessionId={10} buttons={buttons} />
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenCoreConceptsQuestion}</>
}
