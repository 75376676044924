import { ChangeEvent, useMemo, useState, useCallback } from 'react'

import { RadioField } from './Radio'

import { InputField } from './Input'
import { CheckboxField } from './Checkbox'
import { RateField } from './Rate'
import { TextareaField } from './Textarea'
import { LabNumberInputForm } from './LabNumberInputForm'

import { Question, QuestionTypes, FieldTypes } from '../../../models/'

type Props = {
  isInputSS?: boolean
  before?: React.ReactNode
  after?: React.ReactNode
  children?: React.ReactNode
  value?: string | string[]
  question: Question
  fieldType?: FieldTypes
  currentPage?: number
  typeInput?: string
  min?: number
  max?: number
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChangeValue?: (value: string) => void
}

export const FormField = ({ isInputSS, before, after, children, value, question, fieldType = FieldTypes.Dark, currentPage, typeInput, min, max, onChange, onChangeValue }: Props) => {
  const isCollectLabNumberScreen = currentPage === 6
  const isHidden: boolean = [QuestionTypes.InputHidden, QuestionTypes.RadioHidden, QuestionTypes.RadioDropdown].includes(question.type)
  const isDarkType: boolean = fieldType === FieldTypes.Dark
  const hasParent: boolean = !!question.parent
  const isRadioParent: boolean = question.parent?.value === 'Yes' && question.parent?.parentName === 'hs_027'
  const [isShowField, setIsShowField] = isCollectLabNumberScreen ? useState<boolean>(true) : useState<boolean>(!isHidden)

  const handleShowInput = useCallback((statusCurrent: boolean) => {
    if (isHidden) {
      setIsShowField(!statusCurrent)
    }
  }, [])

  const input = useMemo(() => {
    switch (question.type) {
      case QuestionTypes.Radio:
      case QuestionTypes.RadioHidden:
      case QuestionTypes.RadioDropdown:
        return <RadioField question={question} value={typeof value === 'string' ? value : undefined} fieldType={fieldType} onChange={onChange} />

      case QuestionTypes.Input:
        if (isCollectLabNumberScreen) return <LabNumberInputForm isInputSS={isInputSS} question={question} value={typeof value === 'string' ? value : undefined} onChange={onChange} />
        return <InputField isInputSS={isInputSS} typeInput={typeInput} min={min} max={max} question={question} value={typeof value === 'string' ? value : undefined} onChange={onChange} />
      case QuestionTypes.InputHidden:
        return <InputField isInputSS={isInputSS} typeInput={typeInput} min={min} max={max} question={question} value={typeof value === 'string' ? value : undefined} onChange={onChange} />

      case QuestionTypes.Checkbox:
      case QuestionTypes.CheckboxMulti:
        return <CheckboxField question={question} value={value} fieldType={fieldType} onChange={onChange} />

      case QuestionTypes.Rate:
        return onChangeValue && <RateField before={before} after={after} question={question} value={typeof value === 'string' ? value : undefined} onChangeValue={onChangeValue} />

      case QuestionTypes.TextArea:
        return <TextareaField question={question} value={typeof value === 'string' ? value : undefined} onChange={onChange} />

      case QuestionTypes.FillBlank:
        return <InputField isInputSS={isInputSS} question={question} value={typeof value === 'string' ? value : undefined} onChange={onChange} />

      default:
        break
    }
  }, [isInputSS, before, after, fieldType, value, question])

  const inputField = useMemo(() => {
    const iconCheckbox = isShowField ? `bg-[url('assets/images/icon/i-checkbox__checked.svg')]` : `bg-[url('assets/images/icon/i-checkbox__uncheck.svg')]`
    const checkboxStyle = isHidden ? `cursor-pointer bg-[top_left_-3px] bg-no-repeat bg-[length:24px_auto] pl-6 pt-[2px] ${iconCheckbox}` : ''

    const iconArrowRotate = isShowField ? 'before:rotate-90' : ''
    const dropdownStyle =
      !isCollectLabNumberScreen && isHidden
        ? `cursor-pointer rounded border border-[#D4D4D4] pl-3 leading-[30px] shadow-[0px_8px_15px_rgba(0,0,0,0.2)] before:absolute before:top-0 before:right-0 before:h-[30px] before:w-[30px] before:bg-[url('assets/images/icon/i-arrow.svg')] before:bg-[length:auto_17px] before:bg-center before:bg-no-repeat before:transition-transform ${iconArrowRotate}`
        : ''

    const iconStyle = question.type === QuestionTypes.RadioDropdown ? dropdownStyle : checkboxStyle
    const colorStyle = isDarkType ? (!isCollectLabNumberScreen ? 'text-[#EB2781]' : '') : 'text-white'

    return (
      <>
        {question.question ? (
          isCollectLabNumberScreen ? (
            question.type === QuestionTypes.Input ? (
              <div className="flex items-center justify-between border-b-[1px]  border-[#D9D9D9] pb-2 font-semibold">
                <h4 className={`relative text-ma-md font-semibold ${colorStyle}`}>{question.question}</h4>
                {input}
              </div>
            ) : (
              <div className="mt-[-16px] border-b-[1px] pb-3">
                <h4 className={`relative mb-[5px] self-center text-ma-md font-semibold ${colorStyle}`}>{question.question}</h4>
                {input}
              </div>
            )
          ) : (
            <>
              <h4 className={`relative text-ma-md font-semibold ${iconStyle} ${colorStyle}`} onClick={() => handleShowInput(isShowField)} dangerouslySetInnerHTML={{ __html: question.question }}></h4>
              {/* {question.name === 'email_frequency' ? null : isShowField ? input : null} */}
              {isShowField ? input : null}
            </>
          )
        ) : isShowField ? (
          input
        ) : null}
      </>
    )
  }, [isDarkType, isHidden, isShowField, question, input, handleShowInput])

  const formField = useMemo(() => {
    const hasTitle = !!question.question
    return (
      <div
        className={`space-y-4 ${isDarkType ? 'text-black' : 'text-white'} ${hasTitle ? 'pt-3' : ''} ${
          hasParent ? `${isRadioParent ? '!mb-[60px] !mt-[-355px] md:!mb-[50px] md:!mt-[-295px]' : ''} bg-ma-pink-100 p-4` : ''
        }`}
      >
        {inputField}
        {children}
      </div>
    )
  }, [hasParent, inputField, question, children])

  return <>{formField}</>
}
