import React from 'react'
import MedicationRx from '../../components/MyHealth/MedicationRx'
import { MedicationRxProvider } from '../../context/medicationRxContext'
import { useHandlePreviousUrl } from '.'

interface IProps {}

const MedicationsRxPage = (props: IProps) => {
  const handlePreviousUrl = useHandlePreviousUrl()

  return (
    <MedicationRxProvider>
      <MedicationRx prevScreen={handlePreviousUrl} />
    </MedicationRxProvider>
  )
}

export default MedicationsRxPage
