import React from 'react'
import NutritionRx from '../../components/MyHealth/Nutrition'
import { useHandlePreviousUrl } from '.'

type Props = {}

const NourishRxPage = (props: Props) => {
  const handlePreviousUrl = useHandlePreviousUrl()

  return <NutritionRx prevScreen={handlePreviousUrl} />
}

export default NourishRxPage
