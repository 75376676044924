import { NavLink, useNavigate } from 'react-router-dom'
import { Goal } from '../../models/goal'

type Props = {
  goal: Goal
  clickable?: boolean
  isProgressing: boolean
}
export const GoalElement = ({ goal, isProgressing, clickable = true }: Props) => {
  const navigate = useNavigate()
  const style = {
    color: goal.textColor,
    backgroundColor: goal.backgroundColor,
  }

  function completedCircle() {
    return (
      <div className="avatar placeholder">
        <div className="w-[31px] rounded-full border border-white">
          <svg className="h-auto w-4" color={goal.backgroundColor} viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.919 0.217597C15.4854 -0.43084 16.3864 0.514797 15.82 1.16372C12.6793 4.89222 9.51187 8.5667 6.3463 12.2677C6.19184 12.4568 6.03737 12.6189 5.88291 12.8081C5.65305 13.0782 5.21357 13.0493 4.98187 12.8081C3.38576 11.2159 1.78964 9.5929 0.193519 7.99883C-0.398589 7.40443 0.502445 6.43178 1.09455 7.05319C2.53621 8.48516 3.95212 9.91712 5.39378 11.3761C8.58601 7.64759 11.7525 3.9461 14.919 0.217597Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    )
  }

  function inCompletedCircle() {
    return (
      <div className="avatar placeholder">
        <div className="w-[31px] rounded-full border border-white"></div>
      </div>
    )
  }

  let circle
  if (goal.showProgress && goal.completed) {
    circle = completedCircle()
  } else if (goal.showProgress && !goal.completed) {
    circle = inCompletedCircle()
  }

  return (
    <div
      className={(clickable ? 'cursor-pointer ' : '') + 'flex h-full min-h-goal w-full items-center justify-start space-x-2 rounded-[15px] px-4 py-[9px]'}
      style={style}
      data-id={goal.id}
      onClick={() => {
        if (clickable && !isProgressing) {
          navigate(`${goal.path}` + goal.slug)
        }
      }}
    >
      {circle}
      <span className="text-[15px] font-medium uppercase leading-none">{goal.name}</span>
    </div>
  )
}
