import axios from 'axios'
import { JourneyAnswer } from '../../models';
import { AdessoError } from "../error";

type AddJourneyAnswerResponse = {
    success: boolean
    data: JourneyAnswer[] | undefined
    message: string
}

export async function postAddJourneyAnswer ( journeyId: number, answer: any ): Promise<AddJourneyAnswerResponse> {
    const { data, status } = await axios.post( "/journeys/update-answer", { journey_id: journeyId, answer: answer } )

    if ( status !== 200 ) {
        throw new AdessoError( `Request login error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
