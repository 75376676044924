export enum SessionScreen {
  BigIdeasVideo,
  HeartAssessment,
  CoreConcepts,
  HeartToHeart,
  HeartReflection,
  HeartAction,
  HeartComplete,
}

export enum SessionStatus {
  Done,
  Lock,
  Current,
}

export type Session = {
  slug: string
  imgSrc: string
  sessionStatus: SessionStatus
  title: string
  time: number
  point: number
  bonus?: string
  imgSrcWebp?: string
}

export enum ProgressScreenStatus {
  Done,
  Current,
  Lock,
}

export type ProgressScreenType = {
  status: ProgressScreenStatus
  title: string
  time: number
  id: number
}

export enum HeartRickFactorsStatus {
  Unread,
  Read,
}

export type HeartRickFactorsType = {
  id: number
  title: string
  description: string
  status: HeartRickFactorsStatus
}

export type CoreConceptsStops = {
  id: number
  title?: string
  img?: string
  description: string
  content: string
  status?: any
  imgWebp?: string
}
