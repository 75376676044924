import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts06 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Create your Daily Moves.</h4>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_006.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_006.jpg`} alt="" />
          </picture>
          <p>
            {`Here are some ideas to help us get out of our chairs, from behind our desks, and move our body to the rhythm of life! Think about the Daily Moves you want to experiment with this week.`}
          </p>
          <ul className="list-disc space-y-6 pl-8 ">
            <li>
              <b>Multi-task while you’re walking</b> — Listen to a podcast, music, or call a friend.
            </li>
            <li>
              <b>Bike or walk to work or the store</b> — Instead of driving, take the stairs instead of the elevator. If you work from home. Try to take 2-3 short walks a day.
            </li>
            <li>
              <b>Get a standing desk</b> and standing mat that encourages you to move around.
            </li>
            <li>
              <b>Do chores</b> — cleaning, sweeping floors, washing your car. Anything that moves your full body.
            </li>
            <li>
              <b>Move while watching TV</b> — you can stand, jog in place, stretch, even dance.{' '}
            </li>
            <li>
              <b>Lift objects as weights</b> — Grab two jugs of water, a set of books or even furniture and use them as weights.
            </li>
            <li>
              <b>For moms</b> — grab your baby or kids, they love to move and dance. Or use your child as your weight set!
            </li>
          </ul>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
