export enum TitleType {
  COMMIT = 'COMMIT',
  REFRESH = 'REFRESH',
  RELATE = 'RELATE',
  OTHER = 'OTHER',
}

export const customTitle = (title?: string): string => {
  switch (title) {
    case TitleType.COMMIT:
    case TitleType.REFRESH:
      return 'LIFESTYLE'
    case TitleType.RELATE:
      return ''
    default:
      return title || ''
  }
}
export function capitalizeFirstLetter(title?: string) {
  if (!title) return title
  title = title.toLowerCase()
  return title.charAt(0).toUpperCase() + title.slice(1)
}
