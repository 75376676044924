import { useMemo, useEffect, useCallback, useState } from 'react'

import { HeartScoreContent } from '../../components/HeartScore'
import { useHeartScorePage } from '../../hooks/pages/heart-score'

import { Quizess } from '../../models/question'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { IUser } from '../../models/user'
import { paramsOfSymptoms, ABOUT_SELF_SCREEN } from './constants'

import { setAmplitude } from '../../lib/utils/helper'

export const HeartScorePage = () => {
  const { getItemLS } = useLocalStorage()
  const { getDataResult, postDataResult, getDataHeartScore, submitHeartScore, submitHeartScoreStep } = useHeartScorePage()

  const [dataQuizess, setDataQuizess] = useState<Quizess[]>([])
  const [step, setStep] = useState({})

  useEffect(() => {
    if (!getDataResult || (!getDataResult.isLoading && !getDataResult.data && !getDataResult.error)) {
      getDataHeartScore()
    }

    if (getDataResult?.data && getDataResult.data?.quizess) {
      setDataQuizess(getDataResult.data.quizess)
    }

    if (getDataResult?.data && getDataResult.data?.step) {
      setStep(getDataResult.data.step)
    }
  }, [getDataResult])

  const handleSubmit = useCallback(async (params: any): Promise<void> => {
    if (params.step) {
      params.step.page === paramsOfSymptoms.currentPageOfSymptoms && params.step.screen === paramsOfSymptoms.stepPrevOfSymptoms ? await submitHeartScoreStep(params) : submitHeartScoreStep(params)

      // Update amplitude
      if (params.step.page == ABOUT_SELF_SCREEN && params.step.screen == ABOUT_SELF_SCREEN) {
        if ('hs_001' in params.answers) {
          setAmplitude('age_range', params.answers.hs_001)
        }
        if ('hs_002' in params.answers) {
          setAmplitude('marital_status', params.answers.hs_002)
        }
        if ('hs_004' in params.answers) {
          setAmplitude('gender', params.answers.hs_004)
        }
        if ('hs_005' in params.answers) {
          setAmplitude('ethnicity', params.answers.hs_005)
        }
      }
    } else {
      const user = getItemLS('user') as IUser

      const valueSubmitHeartScore = {
        ...params,
        heart_score_status: user?.heart_score_status,
      }
      await submitHeartScore(valueSubmitHeartScore)
    }
  }, [])

  const heartScoreContent = useMemo(() => {
    const isLoading = postDataResult?.isLoading || getDataResult?.isLoading || false
    const isSuccess = postDataResult?.data?.success || false
    const errorMessage = postDataResult?.error?.message || undefined

    return <HeartScoreContent step={step} quizessData={dataQuizess} isLoading={isLoading} isSuccess={isSuccess} errorMessage={errorMessage} onSubmit={handleSubmit} />
  }, [dataQuizess, step, postDataResult, getDataResult, handleSubmit])

  return <>{heartScoreContent}</>
}
