import axios from 'axios'
import { GoalFromApiResponse } from "../../models/goal";
import { AdessoError } from "../error";

type AddGoalResponse = {
    success: boolean
    data: GoalFromApiResponse[] | undefined
    message: string
}

export async function postAddGoal ( id?: number, slug?: string ): Promise<AddGoalResponse> {
    let idToPost = '' + id
    if ( slug !== undefined ) {
        idToPost = slug
    }
    const { data, status } = await axios.post( "/goals/add-goal", { goal_id: idToPost } )
    if ( status !== 200 ) {
        throw new AdessoError( `Request login error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
