export const RiskLegend = () => {
  return (
    <section className="mt-3 flex items-center justify-between ">
      <div className="flex items-end">
        <span className="mr-2 h-[18px] w-[18px] rounded-full bg-[#974A9A]" />
        <p className="font-['Proxima Nova'] text-xs font-semibold text-[#974A9A]">LOW RISK</p>
      </div>

      <div className="flex items-end">
        <span className="mr-2 h-[18px] w-[18px] rounded-full bg-[#F26E57]" />
        <p className="font-['Proxima Nova'] text-xs font-semibold text-[#F26E57]">MEDIUM RISK</p>
      </div>

      <div className="flex items-end">
        <span className="mr-2 h-[18px] w-[18px] rounded-full bg-[#FF3399]" />
        <p className="font-['Proxima Nova'] text-xs font-semibold text-[#FF3399]">HIGH RISK</p>
      </div>
    </section>
  )
}
