import React from 'react'
import MyTutorialsContent from '../../components/MyTutorials'

type Props = {}

const MyTutorials = (props: Props) => {
  return <MyTutorialsContent />
}

export default MyTutorials
