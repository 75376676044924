import { addDays, startOfWeek } from 'date-fns'

import heartStart from '../../../assets/images/iconHeart/heartStart.svg'
import heartAlive1 from '../../../assets/images/iconHeart/heartAlive1.svg'
import heartAlive2 from '../../../assets/images/iconHeart/heartAlive2.svg'
import heartStrive1 from '../../../assets/images/iconHeart/heartStrive1.svg'
import heartStrive2 from '../../../assets/images/iconHeart/heartStrive2.svg'
import heartThrive1 from '../../../assets/images/iconHeart/heartThrive1.svg'
import heartThrive2 from '../../../assets/images/iconHeart/heartThrive2.svg'
import heartMaximize1 from '../../../assets/images/iconHeart/heartMaximize1.svg'
import heartMaximize2 from '../../../assets/images/iconHeart/heartMaximize2.svg'

enum BadgeType {
  HeartStart = 'HEART_START',
  HeartAlive1 = 'HEART_ALIVE_1',
  HeartAlive2 = 'HEART_ALIVE_2',
  HeartStrive1 = 'HEART_STRIVE_1',
  HeartStrive2 = 'HEART_STRIVE_2',
  HeartThrive1 = 'HEART_THRIVE_1',
  HeartThrive2 = 'HEART_THRIVE_2',
  HeartMaximize1 = 'HEART_MAXIMIZE_1',
  HeartMaximize2 = 'HEART_MAXIMIZE_2',
  OTHER = 'OTHER',
}

export const getBadgedImage = (badge: string, needSplit?: boolean) => {
  if (needSplit) {
    const splitDesBadge = badge.split(':')
    badge = splitDesBadge[0].trim()
  }

  const formattedBadge = badge.toUpperCase().replace(/\s+/g, '_')

  switch (formattedBadge) {
    case BadgeType.HeartStart:
      return heartStart
    case BadgeType.HeartAlive1:
      return heartAlive1
    case BadgeType.HeartAlive2:
      return heartAlive2
    case BadgeType.HeartStrive1:
      return heartStrive1
    case BadgeType.HeartStrive2:
      return heartStrive2
    case BadgeType.HeartThrive1:
      return heartThrive1
    case BadgeType.HeartThrive2:
      return heartThrive2
    case BadgeType.HeartMaximize1:
      return heartMaximize1
    case BadgeType.HeartMaximize2:
      return heartMaximize2
    default:
      return heartStart
  }
}
export enum SubscribedEnum {
  STEPS = 'STEPS',
  BLOOD_PRESSURE = 'BLOOD PRESSURE',
  SMOKING = 'SMOKING',
  DRINKING = 'DRINKING',
  SLEEP = 'SLEEP',
  STRESS_TRACKING = 'STRESS TRACKING',
  WEIGHT = 'WEIGHT',
}

export function getDateFromDayOfWeek(dayOfWeek: number, referenceDate = new Date()) {
  const startOfCurrentWeek = startOfWeek(referenceDate)

  const targetDate = addDays(startOfCurrentWeek, dayOfWeek)

  return targetDate
}
