import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts04 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <video className="block h-auto w-full" controls autoPlay muted>
            <source src="https://api.myadesso.health/uploads/animation/ss_02.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
