import { useState, useCallback, useMemo } from 'react'
import { Session10Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion10Questions } from '../../../../../lib/data/session-10'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion10Questions[2], sesstion10Questions[3], sesstion10Questions[4], sesstion10Questions[5], sesstion10Questions[6], sesstion10Questions[7], sesstion10Questions[8]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment01 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const after1 = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">Not</span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">Very</span>
    </div>
  )

  const after2 = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">
        No <br />
        Progress
      </span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">
        Major <br />
        Progress
      </span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const text01 = <h4 className="text-ma-pink">Consider your Adesso Heart Score. Which of the areas below have you made the most progress on?</h4>
  const text02 = (
    <p>
      If you’ve had a physical and know your numbers, and have taken steps to improve your numbers, we recommend you retake the Heart Score at some point in the near future. Changes can be seen as
      early as 2 months, but sustainable change most likely will happen between four and six months if you’re actively working on lifestyle intervention.
    </p>
  )

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session10Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style">
            <div className="space-y-10">
              {ssHAQuestions.map((item, index) => {
                const text = index !== 1 && index !== 6 ? undefined : <div key={`ss10_text_${index}_${item.name}`}>{index === 1 ? text01 : text02}</div>

                const after = index === 0 ? after1 : after2
                return (
                  <>
                    {text}
                    <FormField
                      key={`ss10_${index}_${item.name}`}
                      after={after}
                      question={item}
                      value={formik.values[item.name]}
                      onChange={formik.handleChange}
                      onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                    >
                      {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                    </FormField>
                  </>
                )
              })}
            </div>
          </div>

          {buttons}
        </form>
      </Session10Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
