import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
    data?: any
    message?: string
}

export async function getJourneysCurrentStep ( sessionIndex: any = '' ): Promise<Response> {
    const { data, status } = await axios.get( '/journeys/current-step?session_index=' + sessionIndex )

    if ( status !== 200 ) {
        console.info( 'putJourneysCurrentStep', data )
        throw new AdessoError( `Request putJourneysCurrentStep error - ${status} ${JSON.stringify( data )}` )
    }

    const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

    return dataReturn;
}
