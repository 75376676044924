import { useCallback, useMemo, useState } from 'react'

import { ScreenCoreConceptsQuestion } from './CoreConceptsQuestion'
import { ScreenCoreConceptsMore } from './CoreConceptsMore'
import { Session05Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { dataCoreConcepts, sesstion05Questions } from '../../../../../lib/data/session-05'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

const ssCC00Questions = [
  sesstion05Questions[8],
  {
    ...sesstion05Questions[9],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Unsatisfied</span>
        <span className="w-[40%] text-center">Moderate</span>
        <span className="w-[30%] text-right"> Very Satisfied</span>
      </div>
    ),
  },
]
const ssCC01Questions = [
  {
    ...sesstion05Questions[10],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Unhappy</span>
        <span className="w-[40%] text-center">Moderate</span>
        <span className="w-[30%] text-right">Very Happy</span>
      </div>
    ),
  },
  {
    ...sesstion05Questions[11],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Unhappy</span>
        <span className="w-[40%] text-center">Moderate</span>
        <span className="w-[30%] text-right">Very Happy</span>
      </div>
    ),
  },
  {
    ...sesstion05Questions[12],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Disagree</span>
        <span className="w-[40%] text-center">Moderate</span>
        <span className="w-[30%] text-right">Agree</span>
      </div>
    ),
  },
]
const ssCC02Questions = [
  {
    ...sesstion05Questions[13],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Disagree</span>
        <span className="w-[40%] text-center">Moderate</span>
        <span className="w-[30%] text-right">Agree</span>
      </div>
    ),
  },
  sesstion05Questions[14],
]
const ssCC03Questions = [
  {
    ...sesstion05Questions[15],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Disagree</span>
        <span className="w-[40%] text-center">Moderate</span>
        <span className="w-[30%] text-right">Agree</span>
      </div>
    ),
  },
  sesstion05Questions[16],
]

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? 4 : step.sub_step)
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataCoreConcepts[isButtonBack ? 4 : step.sub_step])

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const numScreens = dataCoreConcepts.length

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConcepts[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: number) => {
      params && onUpdate(params)
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    if (screen < numScreens) {
      switch (screen) {
        case 0:
          return (
            <ScreenCoreConceptsQuestion
              answers={answers}
              contentScreen={contentScreen}
              questions={ssCC00Questions}
              showModal={showModal}
              backScreen={() => handleBackScreen(screen)}
              onUpdate={(params: object) => handleUpdate(params, screen)}
            />
          )
        case 1:
          return (
            <ScreenCoreConceptsQuestion
              answers={answers}
              contentScreen={contentScreen}
              questions={ssCC01Questions}
              showModal={showModal}
              backScreen={() => handleBackScreen(screen)}
              onUpdate={(params: object) => handleUpdate(params, screen)}
            />
          )
        case 2:
          return (
            <ScreenCoreConceptsQuestion
              answers={answers}
              contentScreen={contentScreen}
              questions={ssCC02Questions}
              showModal={showModal}
              backScreen={() => handleBackScreen(screen)}
              onUpdate={(params: object) => handleUpdate(params, screen)}
            />
          )
        case 3:
          return (
            <ScreenCoreConceptsQuestion
              answers={answers}
              contentScreen={contentScreen}
              questions={ssCC03Questions}
              showModal={showModal}
              backScreen={() => handleBackScreen(screen)}
              onUpdate={(params: object) => handleUpdate(params, screen)}
            />
          )
        default:
          const srcImg = contentScreen.img?.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${contentScreen.img}` : `/images/my-adresso-journey/session/05/${contentScreen.img}`

          return (
            <Session05Wrapper buttons={buttons}>
              <div className="session-style">
                {contentScreen.title && <h4>{contentScreen.title}</h4>}

                {contentScreen.img ? (
                  srcImg.includes('.mp4') ? (
                    <video className="block h-auto w-full" autoPlay muted controls>
                      <source src={srcImg} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img src={srcImg} alt="" />
                  )
                ) : (
                  ''
                )}

                <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
              </div>
            </Session05Wrapper>
          )
      }
    }

    return <ScreenCoreConceptsMore showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal])

  return <>{screenCoreConcepts}</>
}
