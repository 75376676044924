import { useMemo, useEffect } from 'react'
import { SettingContent } from '../../components/Setting/'
import { useSettingPage } from '../../hooks/pages/setting'

export const Setting = () => {
  const { getSettingResult, getSetting } = useSettingPage()

  useEffect(() => {
    getSetting()
  }, [])

  const Setting = useMemo(() => {
    return getSettingResult?.isLoading ? '' : <SettingContent dataSetting={getSettingResult?.data} />
  }, [getSettingResult])

  return <>{Setting}</>
}
