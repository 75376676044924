import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { DayData, GroupData } from '../../../models/heartChart'
import { IconLoading } from '../../Common'
import { useNavigate } from 'react-router-dom'
import { getDateFromDayOfWeek } from './dataUtils'

interface CircularChartProps {
  data: GroupData[]
  size: number
}

interface CommentatorsChartProps {
  data: GroupData[]
}

type Props = {
  data: GroupData[]
  size: number
  isLoading?: boolean
}

const CircularChart: React.FC<CircularChartProps> = ({ data, size }) => {
  const ref = useRef<SVGSVGElement>(null)
  const navigate = useNavigate()

  const handleClick = (day: DayData, group: GroupData) => {
    const date = getDateFromDayOfWeek(day.day)
    navigate(`/daily-tracking?label=${group.label}&date=${date}`)
  }

  useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current)
      const width = size
      const height = size
      const radius = width / 2
      const spaceBetweenGroups = 0.03
      const groupWidth = (2 * Math.PI) / data.length - spaceBetweenGroups
      const isSingleGroup = data.length === 1
      const fullCircle = 2 * Math.PI

      svg.selectAll('*').remove()

      const groups = svg
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)

      const arcGenerator = d3.arc().cornerRadius(10)

      data.forEach((group, groupIndex) => {
        const startAngle = isSingleGroup ? 0 : groupIndex * (groupWidth + spaceBetweenGroups) - Math.PI / 2
        const endAngle = isSingleGroup ? fullCircle : startAngle + groupWidth

        group.days.forEach((day, index) => {
          const spaceBetweenArcs = 2 // Space between arcs
          const arcWidth = 18 // The width of each arc
          const innerRadius = radius - (group.days.length + 1 - index) * arcWidth + index * spaceBetweenArcs
          const outerRadius = innerRadius + arcWidth - spaceBetweenArcs

          const path = groups
            .append('path')
            .attr(
              'd',
              arcGenerator({
                startAngle: startAngle,
                endAngle: endAngle,
                innerRadius: innerRadius,
                outerRadius: outerRadius,
              })
            )

            .attr('fill', day.value !== null ? group.color : '#ddd')
            .attr('fill-opacity', day.value === 1 ? 1 : day.value === 0 ? 0.2 : 1)
            .attr('stroke', 'white')
            .attr('stroke-width', '2')

          const isWithinLastTwoDays = (today: number, day: number) => {
            let difference = today - day
            if (difference < 0) {
              difference += 7
            }

            //today 1 equal Monday, day equal Saturday of last week
            if (today === 1 && day === 6) {
              return false
            }

            return difference < 3
          }

          const today = new Date().getDay()
          const canClick = (day.value !== null && day.value !== 0) || isWithinLastTwoDays(today, day.day)

          if (canClick) {
            path.on('click', () => handleClick(day, group))

            path.on('mouseover', function () {
              d3.select(this).style('cursor', 'pointer').transition().duration(200).attr('fill-opacity', 0.7).attr('transform', 'scale(0.99)')
            })

            path.on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(200)
                .attr('fill-opacity', day.value === 1 ? 1 : day.value === 0 ? 0.2 : 1)
                .attr('transform', 'scale(1)')
            })
          }
        })
      })
    }
  }, [data, size])

  return <svg ref={ref} width={size} height={size}></svg>
}

const Commentators: React.FC<CommentatorsChartProps> = ({ data }) => {
  return (
    <section className="mt-10 flex w-full justify-around">
      {data.map((group) => (
        <div key={group.label} className="flex items-center ">
          <span className={`mr-1 h-[12px]  w-[12px] rounded-full `} style={{ backgroundColor: group.color }} />
          <p className="font-['Roboto'] text-[12px] font-[400] ">{group.label}</p>
        </div>
      ))}
    </section>
  )
}

export const PieChart: React.FC<Props> = ({ data, size, isLoading }) => {
  const [chartSize, setChartSize] = useState(size)

  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth < 768 ? size : size + 70
      setChartSize(newSize)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <IconLoading />
      </div>
    )
  }

  return (
    <div>
      <div className="flex w-full flex-col items-center justify-center">
        <CircularChart data={data} size={chartSize} />
        <Commentators data={data} />
      </div>
    </div>
  )
}
