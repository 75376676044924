import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
}

export async function getHeartChartResult(date?: { date: string }): Promise<Response> {
  const { data, status } = await axios.get(`/heart-chart-result${date ? `?date=${date.date}` : ''}`)

  if (status !== 200) {
    throw new AdessoError(`Request getHeartChartResult error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartHistory(): Promise<Response> {
  const { data, status } = await axios.get('/heart-chart-history') || {}

  if (status !== 200) {
    throw new AdessoError(`Request get heart chart history error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
