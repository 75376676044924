import React from 'react'
import { Loading } from '../Common'
import { MyAdessoMyHealthStep } from '../../constants/introduction'

type Props = {
  nameButton?: string
  bgButton?: string
  nextScreen?: () => void
  prevScreen?: () => void
  submit?: () => void
  addNew?: boolean
  className?: string
  buttonSubmit?: boolean
  disabled?: boolean
  type?: string | any
  width?: string
  stateCreate?: boolean
  isLoading?: boolean
  refs?: React.Ref<any>[]
}

export function ButtonMyHealth({ refs = [], isLoading = false, type, className, nameButton, disabled = false, bgButton, prevScreen, nextScreen, submit, addNew = false, buttonSubmit = false }: Props) {
  return (
    <>
      {submit && (
        <button
          type="submit"
          disabled={disabled}
          className={`flex max-w-[250px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] ${bgButton} p-4 px-10 uppercase text-[#FFF] ${className}`}
          onClick={isLoading ? () => {} : submit}
        >
          <h3 className="font-proxima-nova text-[15px] font-medium"> {isLoading ? <Loading /> : nameButton}</h3>
        </button>
      )}

      {prevScreen && (
        <button
          disabled={disabled}
          className={`flex max-w-[250px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] ${bgButton} p-4 px-10 uppercase text-[#FFF] ${className}`}
          onClick={prevScreen}
        >
          <h3 className="font-proxima-nova text-[15px] font-medium">{nameButton}</h3>
        </button>
      )}

      {nextScreen && (
        <div
          ref={refs[MyAdessoMyHealthStep.SeePastTestResult]}
          className={`flex max-w-[250px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] ${bgButton} p-4 px-10 uppercase text-[#FFF] ${className}`}
          onClick={nextScreen}
        >
          <h3 className="font-proxima-nova text-[15px] font-medium">{nameButton}</h3>
        </div>
      )}
    </>
  )
}

export function ButtonMyHealthV2({ nameButton = 'Button', bgButton = 'bg-blue-500', prevScreen, nextScreen, submit, addNew = false, width = 'auto', stateCreate = false }: Props) {
  const maxWidth = addNew ? 'max-w-[160px]' : 'max-w-[347px]'
  const baseStyle = `flex ${maxWidth} cursor-pointer items-center justify-center self-center 
                     whitespace-nowrap rounded-[50px] ${bgButton} p-4 px-10 uppercase text-[#FFF] w-[${width}]`
  const content = <h3 className="px-10 font-proxima-nova text-[15px] font-medium">{nameButton}</h3>
  if (addNew) {
    return (
      <div className={baseStyle} onClick={submit ?? prevScreen}>
        {content}
      </div>
    )
  } else if (!stateCreate) {
    return (
      <button className={baseStyle} onClick={nextScreen} disabled={stateCreate}>
        {content}
      </button>
    )
  }
  return null
}
