import { useCallback, useState } from 'react'

import { SettingUser, SettingNotification, AdessoResult } from '../../models'

import { useSetting } from '../use-setting'
import { GetUserSettingResponse } from '../../lib/request/get-user-setting'
import { UpdateNotiResponse } from '../../lib/request/post-update-setting-noti'
import { UpdateSettingResponse } from '../../lib/request/post-update-setting-user'

export function useSettingPage() {
  const [postUserResult, setPostUserResult] = useState<AdessoResult<UpdateSettingResponse>>()
  const [postNotiResult, setPostNotiResult] = useState<AdessoResult<UpdateNotiResponse>>()
  const [getSettingResult, setGetSettingResult] = useState<AdessoResult<GetUserSettingResponse>>({
    isLoading: true,
    data: undefined,
    error: undefined,
  })
  const setting = useSetting()

  const getSetting = useCallback(async () => {
    setGetSettingResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      const response = await setting.getSetting()

      setGetSettingResult({
        isLoading: false,
        data: response,
        error: undefined,
      })
    } catch (error) {
      setGetSettingResult({
        isLoading: false,
        data: undefined,
        error: setting.throwError(error, 'Get Setting Error'),
      })
    }
  }, [])

  const postSettingUser = useCallback(async (params: SettingUser) => {
    setPostUserResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      const response = await setting.updateUserSetting(params)
      setPostUserResult({
        isLoading: false,
        data: response,
        error: undefined,
      })
    } catch (error) {
      setPostUserResult({
        isLoading: false,
        data: undefined,
        error: setting.throwError(error, 'Post Setting User Error'),
      })
    }
  }, [])

  const postSettingNoti = useCallback(async (params: SettingNotification) => {
    setPostNotiResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      const response = await setting.updateNotiSetting(params)
      setPostNotiResult({
        isLoading: false,
        data: response,
        error: undefined,
      })
    } catch (error) {
      setPostNotiResult({
        isLoading: false,
        data: undefined,
        error: setting.throwError(error, 'Post Setting Noti Error'),
      })
    }
  }, [])

  return { postUserResult, postNotiResult, getSettingResult, getSetting, postSettingUser, postSettingNoti }
}
