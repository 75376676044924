import { useMemo } from 'react'
import { Session06Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session06Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            <b>Welcome!</b>
            <br />
            In this session we’re going to talk about how the heart works. We’ll also review more heart throbs, but this time we’ll get more specific about the risk factors that are going on inside
            your body, that you may not be aware of.
            <br /> <br />
            Play the video below to learn about some of these big ideas:
          </p>

          <VimeoEmbed embedId="808489197" />
        </div>
      </Session06Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
