import { useMemo } from 'react'
import { Session07Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

const postCard = `
  Hi, it's Dr. Suzanne, and I want to discuss something common for all of us when we think about testing and our hearts... fear. I see it with my patients all the time, we talk about it openly. Coming to a doctor can be scary. One of the best compliments I ever received happened on a film set with one of my patients. We were filming a pilot about real patient experiences in a fun and fashionable way. And my patient said to me, I like coming in, we have fun, and I just happened to get my blood pressure checked. That made my day. It's that time again, to make that all-powerful mindset shift. We take the fear out of it and put an important word in its place, power. Remember, I'll say it again, it's because now you know exactly what I mean when I say 80% of the time, heart disease is preventable. It's only preventable if you have the power of knowledge. The more we know, the more ability we have to prevent something serious from happening. 
  <br/>It's that time again, to make that all-powerful mindset shift. We take the fear out of it and put an important word in its place, power. Because remember, and I'll say it again because now you know exactly what I mean when I say 80% of the time, heart disease is preventable. But it's only preventable if you have the power of knowledge. The more we know, the more ability we have to prevent something serious from happening. Even if you've already had issues with your heart, or you're not sure what's going on right now. When we have the right knowledge, we can take the steps to save our life and bring us back to health. Ultimately, our goal with testing is never to get sick. And with the power of information and data, we can make even better decisions to ensure we stay healthy and have a happier life. 80% of the time, we can do something to prevent you from ever getting heart disease, we can do. This is the time to find a partner in health care. You won't laugh and have fun getting your blood pressure checked as my patients had. But you will form a relationship of trust that will keep you healthier. I encourage you to click to make this category one of your goals. You can use the tools we have provided to empower yourself. Set a goal to get a checkup if necessary, and learn more about your heart health. This may have been a big one for you. But know you are empowered, and we are going to continue the journey by giving you the best information you need to stay healthy. It comes down to testing, understanding your risk factors, and taking steps to get these risks under control. You can do it and join me for the next session.
`

export const ScreenHeartToHeart = ({ buttons }: Props) => {
  const screenHeartToHeart = useMemo(() => {
    return (
      <Session07Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>Let’s discuss how to advocate for yourself.</p>

          <audio controls className="w-full">
            <source src="https://api.myadesso.health/uploads/journeys/episode-07.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div className="h-full max-h-[calc(100vh-490px)] w-full flex-1 overflow-y-scroll">
            <p className=" text-[18px] leading-[22px]" dangerouslySetInnerHTML={{ __html: postCard }} />
          </div>
        </div>
      </Session07Wrapper>
    )
  }, [buttons])

  return <>{screenHeartToHeart}</>
}
