import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MyAdessoDailyTrackingStep } from '../../../../constants/introduction'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { useLocales } from '../../../../locales'
import { ButtonCommon, ButtonStyles } from '../../../Common'
import { DailyTrackingContent } from '../../../DailyTracking'
import { ArrowType, BoxText } from '../../../Introduction/BoxText'

interface Props {
  nextScreen: () => void
  closeScreen: () => void
}

const MyAdessoDailyTrackingIntroductionScreen = ({ nextScreen, closeScreen }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const { t } = useLocales()
  const [step, setStep] = useState<MyAdessoDailyTrackingStep>(MyAdessoDailyTrackingStep.WelcomeTour)
  const boxRef = useRef<HTMLDivElement>(null)
  const refs = Object.values(MyAdessoDailyTrackingStep).reduce((acc: any, step, index) => {
    if (!isNaN(Number(step))) {
      acc[step] = useRef<HTMLElement>(null)
    }

    return acc
  }, {})

  const [currentRef, setCurrentRef] = useState<any>(refs[MyAdessoDailyTrackingStep.WelcomeTour])

  const positionStep = {
    WelcomeTour: 'top',
    AdjustYourGoals: '',
    SeeYourLifestyleRx: '',
    GoToHeartJournal: '',
    Submit: '',
  }
  const { setShowFeedback } = useHeaderContext()

  const handleNextStep = useCallback((stepCurrent: MyAdessoDailyTrackingStep) => {
    setStep(stepCurrent + 1)
  }, [])

  useEffect(() => {
    setShowFeedback(false)
  }, [])

  useEffect(() => {
    if (step === MyAdessoDailyTrackingStep.WelcomeTour) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        boxRef.current.style.top = '190px'
      }
      return
    }

    if (!currentRef?.current) {
      // Center vertical popup
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        const boxTop = Math.max(0, window.innerHeight / 2 - (boxRef.current.children[0]?.clientHeight + 51) / 2) - 80
        boxRef.current.style.top = boxTop + 'px'
      }
      return
    }

    currentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    const scrollHeight = currentRef.current.scrollHeight
    const scrollY = window.scrollY
    const { bottom } = currentRef?.current.getBoundingClientRect()

    if (boxRef?.current) {
      switch (step) {
        case MyAdessoDailyTrackingStep.AdjustYourGoals:
        case MyAdessoDailyTrackingStep.SeeYourLifestyleRx:
          boxRef.current.style.top = Math.round(bottom + scrollY - scrollHeight / 2.5 - 40) + 'px'
          break

        case MyAdessoDailyTrackingStep.GoToHeartJournal:
        case MyAdessoDailyTrackingStep.Submit:
          boxRef.current.style.top = Math.round(bottom + scrollY - scrollHeight / 2.5 - 330) + 'px'
          break

        default:
          boxRef.current.style.top = Math.round(bottom + scrollY - 80) + 'px'
          break
      }
    }
  }, [step])

  const handleButtonNext = () => {
    handleNextStep(step)
    const stepCurrent = step
    const nextStep = stepCurrent + 1

    if (stepCurrent === MyAdessoDailyTrackingStep.Submit) {
      nextScreen()
    }

    setCurrentRef(refs[nextStep])
  }

  const contentMyAdessoMyHealthStep = useMemo(() => {
    switch (step) {
      case MyAdessoDailyTrackingStep.WelcomeTour:
        return <BoxText className={`${positionStep.WelcomeTour}`} title={t('introduction.myAdessoDailyTracking.title.welcomeTour')} text={t('introduction.myAdessoDailyTracking.text.welcomeTour')} />
      case MyAdessoDailyTrackingStep.AdjustYourGoals:
        return <BoxText arrow={ArrowType.Right} className={`${positionStep.AdjustYourGoals}`} text={t('introduction.myAdessoDailyTracking.text.adjustYourGoals')} />
      case MyAdessoDailyTrackingStep.SeeYourLifestyleRx:
        return <BoxText arrow={ArrowType.Right} className={`${positionStep.SeeYourLifestyleRx}`} text={t('introduction.myAdessoDailyTracking.text.seeYourLifestyleRx')} />
      case MyAdessoDailyTrackingStep.GoToHeartJournal:
        return (
          <BoxText arrow={ArrowType.BottomRight} arrowClass="!top-[95%] !right-5" className={`${positionStep.GoToHeartJournal}`} text={t('introduction.myAdessoDailyTracking.text.goToHeartJournal')} />
        )
      case MyAdessoDailyTrackingStep.Submit:
        return <BoxText arrow={ArrowType.BottomCenter} arrowClass="!-bottom-[60%]" className={`${positionStep.Submit}`} text={t('introduction.myAdessoDailyTracking.text.submit')} />

      default:
        return <></>
    }
  }, [step])

  const buttonNext = useMemo(() => {
    const marginBottom = step === MyAdessoDailyTrackingStep.WelcomeTour ? 'scroll-mb-0' : 'scroll-mb-80'
    return (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        scrollMarginBottom={marginBottom}
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        onClick={() => handleButtonNext()}
      />
    )
  }, [step, handleNextStep])

  const buttonClose = useMemo(() => {
    return (
      <button onClick={closeScreen} className="fixed right-5 top-7 z-50 flex cursor-pointer  items-center justify-center bg-transparent text-white">
        <p className="pr-2 font-['Proxima_Nova'] text-[14px] font-[600] leading-[17px] tracking-[0.15px]">CLOSE DEMO</p>
        <div className=" h-[29px] w-[30px] bg-[url('assets/images/icon/i-button-close.svg')] bg-no-repeat" />
      </button>
    )
  }, [step])

  const introMemo = useMemo(() => {
    switch (step) {
      case MyAdessoDailyTrackingStep.WelcomeTour:
      case MyAdessoDailyTrackingStep.AdjustYourGoals:
      case MyAdessoDailyTrackingStep.SeeYourLifestyleRx:
      case MyAdessoDailyTrackingStep.GoToHeartJournal:
      case MyAdessoDailyTrackingStep.Submit:
        return <DailyTrackingContent refs={refs} isIntroduction />
    }
  }, [step, currentRef])

  const buttonNextTop = [MyAdessoDailyTrackingStep.GoToHeartJournal, MyAdessoDailyTrackingStep.Submit]

  return (
    <div className="relative h-full w-full pb-40">
      {introMemo}

      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] bg-color-overlay bg-opacity-50"></div>
      {buttonClose}
      <div ref={boxRef} className="absolute left-0 top-[-74px] z-50 flex h-[calc(100%+74px)] w-full flex-col items-center space-y-4 px-4 font-['Proxima_Nova']">
        {buttonNextTop.includes(step) ? (
          <>
            {buttonNext}
            {contentMyAdessoMyHealthStep}
          </>
        ) : (
          <>
            {contentMyAdessoMyHealthStep}
            {buttonNext}
          </>
        )}
      </div>
    </div>
  )
}

export default MyAdessoDailyTrackingIntroductionScreen
