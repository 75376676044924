import { useMemo, useState } from 'react'
import { Goal } from '../../models/goal'
import { GoalElement } from './Goal'
import { IconLoading } from '../Common'
import { useLocales } from '../../locales'

export enum ActionType {
  Add,
  Remove,
}

type Props = {
  handleButtonClick: Function
  goal: Goal
  actionType: ActionType
  onClick?: () => void
}

export const GoalTrackConfigMyGoalsItem = ({ handleButtonClick, goal, actionType, onClick }: Props) => {
  const [isProgressing, setIsProgressing] = useState(false)
  const { t } = useLocales()
  const isDefaultGoal = actionType !== ActionType.Add && goal.title === 'COMMIT'
  function handleButtonClicked() {
    if (isDefaultGoal) {
      return
    }
    setIsProgressing(true)
    handleButtonClick(goal)
    setTimeout(() => {
      setIsProgressing(false)
    }, 60000)
  }

  const actionButton = useMemo(() => {
    const isAddAction = actionType === ActionType.Add
    const icon = isAddAction ? `bg-[url('assets/images/icon/i-add.svg')]` : `bg-[url('assets/images/icon/i-remove.svg')]`
    const textButton = isAddAction ? t('common.add') : t('common.remove')
    const colorButton = isAddAction ? 'text-color-text-primary' : 'text-color-text-secondary'
    if (isProgressing) {
      return (
        <div className={`${isAddAction ? 'ml-4 text-color-text-primary' : 'ml-[27.5px]'} flex h-[29px] w-24 items-center justify-end pl-9`}>
          <IconLoading customStyle={{ width: '29px', height: '29px' }}></IconLoading>
        </div>
      )
    }
    return (
      <div
        onClick={handleButtonClicked}
        className={`ml-4 flex h-[29px] w-24 items-center justify-end bg-[length:auto_29px] bg-[center_right_1px] bg-no-repeat pr-9 ${!isDefaultGoal ? icon + ' cursor-pointer' : ''}`}
      >
        <span className={`${isDefaultGoal ? 'invisible' : ''} text-[14px] font-semibold leading-[17px] tracking-[0.15px] ${colorButton}`}>{textButton}</span>
      </div>
    )
  }, [actionType, isProgressing])

  return (
    <div className="flex items-center justify-between" data-id={goal.id}>
      <GoalElement goal={goal} clickable={actionType == ActionType.Remove} isProgressing={isProgressing} onClick={onClick} />
      {actionButton}
    </div>
  )
}
