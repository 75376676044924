import React, { useEffect, useState } from 'react'

import * as d3 from 'd3'

import { useLocales } from '../../../locales'
import { dataEarnBeats } from './fakeData'
import { getBadgedImage } from './dataUtils'
import { Link } from 'react-router-dom'

type GroupData = {
  label: string
  beats: number
  totalBeats: number
  color: string
}

type DataEarnBeast = {
  title: string
  description: string
  link: string
}
type EarnBeatsChartProps = {
  data: DataEarnBeast[]
  title: string
}

interface CommentatorsChartProps {
  data: GroupData[]
}

export type dataMyBeats = {
  img: string
  des: string
  score: number
  bgColor: string
  leftPoint: number
  nextLevel: string
}
interface Props {
  data: GroupData[]
  size: number
  dataMyBeats?: dataMyBeats
}

const RadialProgressGroup: React.FC<{ groupData: GroupData[]; size: number; dataMyBeats?: dataMyBeats }> = ({ groupData, size, dataMyBeats }) => {
  const { t } = useLocales()
  const ref = React.useRef(null)

  let desBadge = ''
  let imgBadge = ''

  if (dataMyBeats?.des) {
    const splitDesBadge = dataMyBeats?.des.split(':')
    desBadge = splitDesBadge[0].trim().toUpperCase()
    imgBadge = getBadgedImage(desBadge)
  }
  React.useEffect(() => {
    const svgCenterX = size / 2
    const svgCenterY = size / 2
    const scaleRatio = size / 300
    const thickness = 10 * scaleRatio
    const arcSpacing = 6 * scaleRatio
    const centralGapWidth = 70 * scaleRatio
    const iconWidth = 100 * scaleRatio
    const iconHeight = 100 * scaleRatio

    const svg = d3
      .select(ref.current)
      .attr('width', size)
      .attr('height', size)
      .append('g')
      .attr('transform', `translate(${size / 2}, ${size / 2})`)

    const numArcs = groupData.length

    groupData.forEach((data, index) => {
      const innerRadius = centralGapWidth + (numArcs - 1 - index) * (thickness + arcSpacing)
      const outerRadius = innerRadius + thickness

      const backgroundArcGenerator = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(2 * Math.PI)

      const backgroundPathData = backgroundArcGenerator({
        startAngle: 0,
        endAngle: 2 * Math.PI,
        innerRadius: innerRadius,
        outerRadius: outerRadius,
      })

      svg.append('path').attr('d', backgroundPathData).style('fill', '#ddd')

      if (data.beats > 0) {
        const foregroundArcGenerator = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(0)
          .endAngle((data.beats / data.totalBeats) * 2 * Math.PI)
          .cornerRadius(20)

        const foregroundPathData = foregroundArcGenerator({
          startAngle: 0,
          endAngle: (data.beats / data.totalBeats) * 2 * Math.PI,
          innerRadius: innerRadius,
          outerRadius: outerRadius,
        })

        svg.append('path').attr('d', foregroundPathData).style('fill', data.color)
      }
    })

    d3.select(ref.current)
      .append('image')
      .attr('href', imgBadge)
      .attr('width', iconWidth)
      .attr('height', iconHeight)
      .attr('x', svgCenterX - iconWidth / 2)
      .attr('y', svgCenterY - iconHeight / 2)
  }, [groupData])
  return (
    <div className="">
      <svg ref={ref}></svg>
      <div className="mt-3 flex flex-col items-center justify-center">
        <span className="font-['Proxima Nova']  flex text-[36px] font-[600] leading-[32px]">
          <p className="pr-2">{t('heartChartMain.beatsProgress.myBeats')}:</p>
          {dataMyBeats?.score}
        </span>

        <p className="font-['Proxima Nova']  my-2 flex text-[24px] font-[600] leading-[32px] text-[#FF3399]">
          {desBadge}
          <span className="mx-1"> </span>
          {t(`heartChartMain.beatsProgress.level`)}
        </p>

        {(dataMyBeats?.leftPoint || 0) > 0 && (
          <p className="font-['Proxima Nova']  my-2 flex text-[14px] font-[600] leading-[10px]">
            {dataMyBeats?.leftPoint}
            <span className="mx-1">{t(`heartChartMain.beatsProgress.needUnlock`)}</span>
            {dataMyBeats?.nextLevel.toUpperCase()}
          </p>
        )}
      </div>
    </div>
  )
}

export const ChartBeastProgress: React.FC<Props> = ({ data, size, dataMyBeats }) => {
  const [chartSize, setChartSize] = useState(size)
  const { t } = useLocales()
  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth < 768 ? size : size + 100
      setChartSize(newSize)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const Commentators: React.FC<CommentatorsChartProps> = ({ data }) => {
    return (
      <section className=" w-full flex-col justify-around">
        {data.map((group) => (
          <div key={group.label} className="font-['Proxima Nova'] mb-3 flex items-center text-[16px] font-[400] leading-[16px]">
            <span className={`mr-1 h-[21px]  w-[21px] rounded-full `} style={{ backgroundColor: group.color }} />
            <p className=" ">{group.label}:</p>
            <span className="ml-2 flex">
              {group.beats}
              <p className="mx-1">{t('heartChartMain.beatsProgress.beatsOf')}</p>
              {group.totalBeats}
              <p className="ml-1">{t('heartChartMain.beatsProgress.total')}</p>
            </span>
          </div>
        ))}
      </section>
    )
  }

  const EarnBeats: React.FC<EarnBeatsChartProps> = ({ data, title }) => {
    return (
      <div className="mt-8">
        <p className='font-["Proxima Nova"] mb-3  text-[15px] font-[600] leading-[21px]'>{title}:</p>
        <section className=" w-full flex-col justify-around">
          {data.map((earnInfo) => (
            <div className="mb-3">
              <Link to={earnInfo.link} className='font-["Proxima Nova"] cursor-pointer text-[16px] font-[400] leading-[21px] text-[#950057] underline'>
                {earnInfo.title}
              </Link>
              <p className='font-["Proxima Nova"] mb-3  text-[16px] font-[600] leading-[16px]'>+ {earnInfo.description}</p>
            </div>
          ))}
        </section>
      </div>
    )
  }

  return (
    <div className="mt-3">
      <div className="flex w-full justify-center">
        <RadialProgressGroup groupData={data} size={chartSize} dataMyBeats={dataMyBeats} />
      </div>
      <p className="my-8 text-center">{t('heartChartMain.beatsProgress.achieveBeats')}</p>
      <Commentators data={data} />
      <EarnBeats data={dataEarnBeats} title={t('heartChartMain.beatsProgress.earnMoreBeats')} />
    </div>
  )
}
