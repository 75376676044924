import { useCallback, useState } from 'react'

import { AdessoResult, LoginOptions } from '../../models'

import { useAuth } from '../use-auth'

type Result = {
  screen: any
}

export function useLoginPage() {
  const [postDataResult, setPostDataResult] = useState<AdessoResult<Result>>()
  const auth = useAuth()

  const login = useCallback(async (params: LoginOptions) => {
    setPostDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      await auth.checkAuth()
      const result = await auth.login(params)
      setPostDataResult({
        isLoading: false,
        data: result,
        error: undefined,
      })
    } catch (error) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Login Error'),
      })
    }
  }, [])

  return { postDataResult, login }
}
