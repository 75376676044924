import {useMemo} from 'react'
import {IconLoading, TitlePage, WrapperCommon} from "../Common";
import {LoadingSize} from "../../models/loading";

type Props = {
  isLoading: boolean
}

export const OAuthContent = ({isLoading}: Props) => {
  const loadingElement = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Large}/>
        </div>
      )
    } else {
      return (<div></div>)
    }
  }, [isLoading])

  return (
    <WrapperCommon className="min-h-[calc(100vh-74px)] space-y-5 bg-ma-80 py-10 text-white">
      <TitlePage title="Welcome to the MyAdesso Journey!" subTitle="Please wait. Login in..."/>
      {loadingElement}
    </WrapperCommon>
  )
}
