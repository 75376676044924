import { useState, useCallback, useMemo, useEffect } from 'react'

import { Session03Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { SelectedItem } from './SelectedItem'
import { dataCoreConceptsStops02 } from '../../../../../lib/data/session-03'

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
}

export const ScreenCoreConceptsStop = ({ showModal, nextScreen, backScreen }: Props) => {
  const [dataList, setDataList] = useState(dataCoreConceptsStops02)
  const [dataShow, setDataShow] = useState(dataCoreConceptsStops02[0])
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)
  const [isDisableNext, setIsDisableNext] = useState(true)

  useEffect(() => {
    let newCount = 0
    const newData = dataList.map((item) => {
      if (item.status === 1) newCount++
      return item
    })
    setDataList(newData)
    setIsDisableNext(newCount < 3)
  }, [])

  const handleBackScreen = useCallback(
    (statusShowDetail: boolean) => {
      if (statusShowDetail) {
        window.scrollTo(0, 0)
        setIsShowDetail(false)
      } else backScreen()
    },
    [backScreen]
  )

  const handleShowDetail = useCallback(
    (id: number) => {
      const dataDetail = dataList.find((item) => item.id === id)

      if (dataDetail) {
        window.scrollTo(0, 0)
        setDataShow(dataDetail)
        setIsShowDetail(true)

        let newCount: number = 0
        const newData = dataList.map((item) => {
          if (item.id === id) item.status = 1
          if (item.status === 1) newCount++
          return item
        })
        setDataList(newData)
        setIsDisableNext(newCount < 3)
      }
    },
    [backScreen]
  )

  const buttons = useMemo(() => {
    return (
      <SessionButtons
        isDisableNext={isDisableNext}
        styleButton={'dark'}
        showModal={isShowDetail ? undefined : showModal}
        backScreen={() => handleBackScreen(isShowDetail)}
        nextScreen={isShowDetail ? undefined : nextScreen}
      />
    )
  }, [dataShow, isShowDetail, handleBackScreen, nextScreen, showModal, isDisableNext])

  const screenList = useMemo(() => {
    return (
      <Session03Wrapper buttons={buttons}>
        <div className="session-style">
          <h4>Making changes toward nourishment.</h4>

          <picture className="">
            <source srcSet={`/images/my-adresso-journey/session/03/img_004.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/03/img_004.jpg`} alt="" />
          </picture>

          <p>{`We’ve already discussed one of the biggest changes in your approach to eating, which is to view the process of eating for how it nourishes you and supports your body.  Let’s talk about some of the other ways in which you may need to adopt new mindsets and habits to support a more nourishing lifestyle. Click on some of the topics below to explore what can help you as you begin to shift your habits towards nourishing yourself.`}</p>

          <div className="space-y-3">
            {dataList.map((item) => (
              <SelectedItem key={`HeartRickFactors-${item.id}`} data={item} showDetail={() => handleShowDetail(item.id)} />
            ))}
          </div>
        </div>
      </Session03Wrapper>
    )
  }, [buttons, handleShowDetail])

  const screenDetail = useMemo(() => {
    return (
      <Session03Wrapper buttons={buttons}>
        <div className="session-style">
          <h4>{`${dataShow.title}`}</h4>

          <picture className="">
            <source srcSet={`/images/my-adresso-journey/session/03/${dataShow.imgWebp}`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/03/${dataShow.img}`} alt="" />
          </picture>

          <div className="space-y-6" dangerouslySetInnerHTML={{ __html: dataShow.content }} />
        </div>
      </Session03Wrapper>
    )
  }, [dataShow, buttons])

  const screenCoreConceptsStop = useMemo(() => {
    return isShowDetail ? screenDetail : screenList
  }, [isShowDetail, screenList, screenDetail])

  return <>{screenCoreConceptsStop}</>
}
