import { useState, useEffect, useMemo, useCallback } from 'react'
import { ModalProgress, SessionButtons, LayoutHeartAction, LayoutBeatsReviced } from '../layout'
import { ScreenBigIdeasVideo, ScreenHeartAssessment, ScreenCoreConcepts, ScreenHeartToHeart, ScreenHeartReflection, ScreenVideo, ScreenFeedback } from './screen'

import { SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../../../models'
import { dummyProgressDataSS01 } from '../../../../lib/data/session-01'
import { dataComplete } from '../../../../lib/data/session-10'

import { putJourneysCurrentStep } from '../../../../lib/request/put-journeys-current-step'
import { ScreenHeartGoalTracking } from '../layout/HeartGoalTracking'
import { useBoolean } from '../../../../hooks/use-boolean'
import { ButtonCommon, ButtonStyles, Wrapper } from '../../../Common'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocales } from '../../../../locales'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  isLoading?: boolean
  isSuccess?: boolean
  onSubmit: (params: object) => void
  backTop: () => void
  step: Step
  answers: any
}

export const Session10 = ({ isLoading, isSuccess, onSubmit, backTop, step, answers }: Props) => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const { t } = useLocales()

  const { setShowHeader, setIsHeaderAbsolute } = useHeaderContext()

  const stepEndScreen = 7

  const [values, setValues] = useState<object>({})
  const [screen, setScreen] = useState(step.step)
  const [isShowProgressModal, setIsShowProgressModal] = useState<boolean>(false)
  const [progressData, setProgressData] = useState<ProgressScreenType[]>(dummyProgressDataSS01)

  const isButtonBack = useBoolean()

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: screen, step: 0 })
  }

  useEffect(() => {
    if (screen >= 6) {
      setIsHeaderAbsolute(screen === 6)
      setShowHeader(true)
    } else {
      setIsHeaderAbsolute(false)
      setShowHeader(false)
    }

    const newProgress = dummyProgressDataSS01.map((item, index) => {
      if (screen >= index) item.status = ProgressScreenStatus.Done
      return item
    })

    setProgressData(newProgress)
  }, [screen])

  useEffect(() => {
    if (isSuccess) saveScreen(SessionScreen.HeartAction)
  }, [isSuccess])

  useEffect(() => {
    if (step.step === stepEndScreen) {
      saveScreen(stepEndScreen - 1)
    }
  }, [])

  const handleGoToScreen = useCallback((screenID: SessionScreen) => {
    isButtonBack.onFalse()
    saveScreen(screenID)
  }, [])

  const handleToggleProgressModal = useCallback((isShow: boolean) => {
    setIsShowProgressModal(isShow)
  }, [])

  const handleBackScreen = useCallback((stepCurrent: SessionScreen) => {
    if (stepCurrent === SessionScreen.BigIdeasVideo) {
      backTop()
    } else {
      isButtonBack.onTrue()
      window.scrollTo(0, 0)
      saveScreen(stepCurrent - 1)
    }
  }, [])

  const handleNextScreen = useCallback((stepCurrent: any) => {
    if (stepCurrent < 7) {
      isButtonBack.onFalse()
      window.scrollTo(0, 0)
      saveScreen(stepCurrent + 1)
    }
  }, [])

  const handleNext2Screen = useCallback((stepCurrent: any) => {
    if (stepCurrent < 5) {
      isButtonBack.onFalse()
      window.scrollTo(0, 0)
      saveScreen(stepCurrent + 2)
    }
  }, [])

  const handleUpdateParams = useCallback((oldValues: object, params: object) => {
    setValues({ ...oldValues, ...params })
    onSubmit({ ...oldValues, ...params, goNext: false })
  }, [])

  const handleSubmit = useCallback((oldValues: object, params: object) => {
    setValues({ ...oldValues, ...params })
    onSubmit({ ...oldValues, ...params, goNext: true })
  }, [])

  const buttons = useMemo(() => {
    return (
      <SessionButtons
        styleButton={'dark'}
        isLoading={isLoading}
        showModal={() => handleToggleProgressModal(true)}
        backScreen={() => handleBackScreen(screen)}
        nextScreen={() => handleNextScreen(screen)}
      />
    )
  }, [isLoading, screen, handleBackScreen, handleNextScreen, handleToggleProgressModal])

  const congratulationContent = useMemo(() => {
    return (
      <div className="space-y-6 text-center text-white">
        <h1 className="w-full font-butler text-[35px] leading-[39px]">You did it! You’ve completed Adesso’s intro heart journey</h1>
      </div>
    )
  }, [])

  const CompleteScreen = ({}) => {
    const dataCoreConcept = dataComplete
    const contentWithCenteredImages = dataCoreConcept.content.replace(/<img /g, '<img style="display: block; margin-left: auto; margin-right: auto; margin-top: 1.5rem" ')

    return (
      <Wrapper className="min-h-[calc(100vh-74px)] flex-col space-y-5 pt-0 text-center">
        <div className='font-["Butler"] text-4xl font-normal'>{t('myAdessoJourney.session10.whatNext')}</div>
        <div className="flex-col space-y-6" dangerouslySetInnerHTML={{ __html: contentWithCenteredImages }} />
        <div className="flex flex-col items-center space-y-4 pt-11">
          <ButtonCommon style={ButtonStyles.Session} width="w-[250px]" height="h-[50px]" onClick={() => navigate(`/my-adesso-journey/go-deeper/${slug}`)}>
            <span className="font-['Proxima Nova' text-base] font-medium text-white">{t('myAdessoJourney.button.goDeeper')}</span>
          </ButtonCommon>
          <ButtonCommon style={ButtonStyles.Session} width="w-[250px]" height="h-[50px]" onClick={() => navigate(`/heart-chart`)}>
            <span className="font-['Proxima Nova' text-base] font-medium text-white">{t('myAdessoJourney.button.heartChart')}</span>
          </ButtonCommon>
          <ButtonCommon style={ButtonStyles.Session} width="w-[250px]" height="h-[50px]" onClick={() => navigate(`/my-health`)}>
            <span className="font-['Proxima Nova' text-base] font-medium text-white">{t('myAdessoJourney.button.myHealth')}</span>
          </ButtonCommon>
        </div>
      </Wrapper>
    )
  }

  const session01 = useMemo(() => {
    switch (screen) {
      case 0:
        return <ScreenBigIdeasVideo isLoading={isLoading} buttons={buttons} />
      case 1:
        return (
          <ScreenHeartAssessment
            answers={answers}
            step={step}
            showModal={() => handleToggleProgressModal(true)}
            backScreen={() => handleBackScreen(screen)}
            nextScreen={() => handleNextScreen(screen)}
            onUpdate={(params: object) => handleUpdateParams(values, params)}
          />
        )
      case 2:
        return (
          <ScreenCoreConcepts
            isButtonBack={isButtonBack.value}
            answers={answers}
            step={step}
            showModal={() => handleToggleProgressModal(true)}
            backScreen={() => handleBackScreen(screen)}
            nextScreen={() => handleNextScreen(screen)}
            onUpdate={(params: object) => handleUpdateParams(values, params)}
          />
        )
      case 3:
        return <ScreenHeartToHeart buttons={buttons} />
      // case 4:
      //   return (
      //     <ScreenHeartReflection
      //       answers={answers}
      //       isLoading={isLoading}
      //       showModal={() => handleToggleProgressModal(true)}
      //       backScreen={() => handleBackScreen(screen)}
      //       onSubmit={(params: object) => handleSubmit(values, params)}
      //     />
      //   )

      // case 5:
      // const takeActionHint = 'Create a plan. Identify the areas you’d like to explore in the coming weeks:'
      // const takeActions = [
      //   'Improve your exercise plan',
      //   'Improve your diet',
      //   'Reduce alcohol',
      //   'Reduce caffeine',
      //   'Quit smoking',
      //   'Get more sleep',
      //   'Focus more on your relationships.',
      //   'Discover your purpose',
      //   'Find a new hobby or passion',
      //   'Address your stress, depression or anxiety with your primary care provider or a therapist',
      //   'Explore your hormonal issues with your primary care provider or gynecologist. Find out ways to deal with the hormonal changes that happen with perimenopause or menopause.',
      //   'Learn how to get healthy after pregnancy.',
      //   'Learn how to manage your heart risk after having a complication during pregnancy like gestational diabetes or preeclampsia.',
      // ]

      // return (
      //   <LayoutHeartAction
      //     takeActionHint={takeActionHint}
      //     takeActions={takeActions}
      //     sessionId={10}
      //     slogan="Create a plan. Identify the areas you’d like to explore in the coming weeks:"
      //     goHeartAction={() => handleNextScreen(screen)}
      //   />
      // )

      // case 5:
      //   return <ScreenHeartGoalTracking buttons={buttons} sessionId={10} />

      case 4:
        return <ScreenVideo isLoading={isLoading} nextScreen={() => handleNextScreen(screen)} next2Screen={() => handleNext2Screen(screen)} />

      case 5:
        return (
          <ScreenFeedback
            answers={answers}
            isLoading={isLoading}
            showModal={() => handleToggleProgressModal(true)}
            backScreen={() => handleBackScreen(screen)}
            nextScreen={() => handleNextScreen(screen)}
            onUpdate={(params: object) => handleUpdateParams(values, params)}
          />
        )
      case 6:
        return <LayoutBeatsReviced nextScreen={() => handleNextScreen(screen)} textContent={congratulationContent} isNextLevel showModal={() => handleToggleProgressModal(true)} journeyId={10} />

      case 7:
        return <CompleteScreen />

      default:
        return <></>
    }
  }, [isButtonBack.value, isLoading, values, buttons, screen, handleBackScreen, handleNextScreen, handleUpdateParams, handleSubmit, handleToggleProgressModal])

  const modalProgress = useMemo(() => {
    return <ModalProgress progress={progressData} isShowModal={isShowProgressModal} closeModal={() => handleToggleProgressModal(false)} goToScreen={handleGoToScreen} />
  }, [progressData, isShowProgressModal, handleToggleProgressModal, handleGoToScreen])

  return (
    <>
      {session01}
      {modalProgress}
    </>
  )
}
