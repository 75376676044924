import { toNumber } from 'lodash'
import { HeartChartBeats } from '../../models/heart-chart-beats'

export function mapDataChartBeats(data: any[]): HeartChartBeats[] {
  const initData: HeartChartBeats[] = [
    { label: 'COMMIT', beats: 0, totalBeats: 500, color: '#ff3399' },
    { label: 'MOVE', beats: 0, totalBeats: 400, color: '#ff567a' },
    { label: 'NOURISH', beats: 0, totalBeats: 400, color: '#f26e57' },
    { label: 'RELATE', beats: 0, totalBeats: 400, color: '#974a9a' },
    { label: 'REFRESH', beats: 0, totalBeats: 400, color: '#b7005e' },
  ]

  return initData.map((initialItem) => {
    const newDataItem = data.find((item) => item.title === initialItem.label)
    if (newDataItem) {
      return {
        label: newDataItem.title,
        beats: toNumber(newDataItem.score),
        totalBeats: newDataItem.max_score,
        color: newDataItem.color,
      }
    }
    return initialItem
  })
}
