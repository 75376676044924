import { useMemo } from 'react'
import { Session, SessionStatus } from '../../models/session'

type Props = {
  session: Session
}

export const SessionItem = ({ session }: Props) => {
  const sessionItem = useMemo(() => {
    const isCurrent = session.sessionStatus === SessionStatus.Current
    const isLock = session.sessionStatus === SessionStatus.Lock
    const isDone = session.sessionStatus === SessionStatus.Done

    return (
      <>
        <div className="flex w-[141px] justify-center">
          <div className={`relative flex items-center justify-center overflow-hidden rounded-full bg-white `}>
            <picture className={`block h-auto w-full max-w-[105px]`}>
              <source srcSet={`${session.imgSrcWebp}`} type="image/webp" />
              <img src={`${session.imgSrc}`} alt="Description" />
            </picture>
            {isDone && <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/icon/i-check-2.svg')] bg-[length:auto_23px] bg-center bg-no-repeat" />}
            {isLock && <div className="absolute left-0 top-0 h-full w-full bg-[url('assets/images/icon/i-lock.svg')] bg-[length:auto_36px] bg-center bg-no-repeat" />}
          </div>
        </div>

        <div className="w-[calc(100%-157px)] space-y-2 font-['Proxima_Nova'] md:mt-4 md:w-full">
          <div className="text-ma-base-semibold text-black">{session.title}</div>
          <div className="bg-[url('assets/images/icon/i-play.svg')] bg-[length:auto_19px] bg-[center_left] bg-no-repeat pl-6 pt-[1px] text-ma-sm text-ma-gray">{session.time} min</div>
          <div className="text-ma-sm font-medium tracking-tight text-ma-pink">
            <p>
              {session.point} Heart Beats {!!session.bonus && session.bonus}
            </p>
            {!!session.bonus && <p>Journey Bonus Beats</p>}
          </div>
        </div>
      </>
    )
  }, [session])

  return <div className="relative z-10 flex w-full items-center justify-between pb-4 md:grid md:grid-cols-1 custom834:grid-cols-2 custom1194:grid-cols-2">{sessionItem}</div>
}
