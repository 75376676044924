import { useState, useCallback, useMemo } from 'react'

import { ScreenCoreConceptsMoreItem } from './CoreConceptsMoreItem'
import { Session09Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { dataCoreConceptsMores, CoreConceptsMores09 } from '../../../../../lib/data/session-09'

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
}

export const ScreenCoreConceptsMore = ({ showModal, nextScreen, backScreen }: Props) => {
  const [dataShow, setDataShow] = useState<CoreConceptsMores09>(dataCoreConceptsMores[0])
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)

  const handleBackScreen = useCallback(
    (statusShowDetail: boolean) => {
      if (statusShowDetail) {
        window.scrollTo(0, 0)
        setIsShowDetail(false)
      } else backScreen()
    },
    [backScreen]
  )

  const handleShowDetail = useCallback(
    (id: number) => {
      const dataDetail = dataCoreConceptsMores.find((item) => item.id === id)

      if (dataDetail) {
        window.scrollTo(0, 0)
        setDataShow(dataDetail)
        setIsShowDetail(true)
      }
    },
    [backScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={isShowDetail ? undefined : showModal} backScreen={() => handleBackScreen(isShowDetail)} nextScreen={isShowDetail ? undefined : nextScreen} />
  }, [dataShow, isShowDetail, handleBackScreen, nextScreen, showModal])

  const screenList = useMemo(() => {
    return (
      <Session09Wrapper buttons={buttons}>
        <div className="session-style">
          <h4>Primary numbers</h4>
          <p>{`Now that we better understand and appreciate what our hormones are doing throughout our life, let’s explore some specific hormonal stages which can affect your heart health. Click on the icons below that are relevant to you to learn more.`}</p>

          <div className="flex flex-wrap justify-between">
            {dataCoreConceptsMores.map((item) => (
              <div key={item.id} className="mb-5 w-[calc(50%-6px)] cursor-pointer space-y-2" onClick={() => handleShowDetail(item.id)}>
                <img src={`/images/my-adresso-journey/session/09/${item.img}`} alt="" />
              </div>
            ))}
          </div>
        </div>
      </Session09Wrapper>
    )
  }, [buttons, handleShowDetail])

  const screenDetail = useMemo(() => {
    return <ScreenCoreConceptsMoreItem dataList={dataShow.data} showModal={showModal} backScreen={() => handleBackScreen(isShowDetail)} />
  }, [dataShow, isShowDetail, handleBackScreen, showModal])

  const screenCoreConceptsStop = useMemo(() => {
    return isShowDetail ? screenDetail : screenList
  }, [isShowDetail, screenList, screenDetail])

  return <>{screenCoreConceptsStop}</>
}
