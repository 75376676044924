import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'
import { useLocalStorage } from '../use-local-storage'

import { AdessoResult } from '../../models'
import { getHeartChartLatestQuestions, getHeartChartTestingQuestions } from '../../lib/request/get-heartchart-testing'

import { mapDataHeartScoreQuestions } from '../../lib/maps/heart-score-questions'

import { HeartChartLatest, Quizess } from '../../models/question'
import { postHeartChart } from '../../lib/request'

type Result = {
  success: boolean
}

type Response = {
  quizess?: Quizess[]
}

export function useHeartChartTestingPage() {
  const [postDataResult, setPostDataResult] = useState<AdessoResult<Result>>()
  const [getDataResult, setGetDataResult] = useState<AdessoResult<Response>>()
  const [getLatestDataResult, setGetLatestDataResult] = useState<AdessoResult<HeartChartLatest[]>>()

  const auth = useAuth()

  const getLeastDataHeartScore = useCallback(async () => {
    setGetLatestDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartLatestQuestions()

        if (res.data) {
          setGetLatestDataResult({
            isLoading: false,
            data: res.data,
            error: undefined,
          })
        } else {
          setGetLatestDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setGetLatestDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get HeartScore Error'),
      })
    }
  }, [])

  const getDataHeartScore = useCallback(async () => {
    setGetDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartTestingQuestions()

        if (res.data) {
          const quizess = mapDataHeartScoreQuestions(res.data)
          setGetDataResult({
            isLoading: false,
            data: { quizess },
            error: undefined,
          })
        } else {
          setGetDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setGetDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get HeartScore Error'),
      })
    }
  }, [])

  const submitHeartScore = useCallback(async (params: object) => {
    setPostDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await postHeartChart(params)

        if (res.data) {
          const { setItemLS } = useLocalStorage()
          setItemLS('resultHeartScore', res.data)

          setPostDataResult({
            isLoading: false,
            data: { success: true },
            error: undefined,
          })
        } else {
          setPostDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post HeartScore Error'),
      })
    }
  }, [])

  return { getLeastDataHeartScore, getLatestDataResult, getDataResult, postDataResult, getDataHeartScore, submitHeartScore }
}
