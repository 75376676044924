import { useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { SubscriptionContent } from '../components/Subscription/'
import { useSubscriptionPage } from '../hooks/pages/subscription'
import { SubscriptionOptions } from '../models/subscription'

export const SubscriptionPage = () => {
  const navigate = useNavigate()
  const { postDataResult, submitSubscription } = useSubscriptionPage()

  useEffect(() => {
    if (postDataResult?.data && postDataResult?.data.success) {
      navigate('/heart-score/')
    }
  }, [postDataResult?.data])

  const handleSubscription = useCallback((params: SubscriptionOptions): void => {
    submitSubscription(params)
  }, [])

  const subscriptionContent = useMemo(() => {
    const isLoading = postDataResult?.isLoading || false
    const errorMessage = postDataResult?.error?.message || undefined

    return <SubscriptionContent isLoading={isLoading} errorMessage={errorMessage} onSubscription={handleSubscription} />
  }, [postDataResult, handleSubscription])

  return <>{subscriptionContent}</>
}
