import axios from 'axios'
import { getCsrfCookie } from './get-csrf-cookie'
import { Goal, GoalFromApiResponse } from '../../models/goal'
import { mapGoal } from '../maps/goal'

type MyGoalsResponse = {
  success: boolean
  data: Goal[]
  message?: string
}

type GetMyGoalsResponse = {
  message: string
  data: GoalFromApiResponse[]
}

export async function getMyGoals(showProgress: boolean = false): Promise<MyGoalsResponse> {
  let dataReturn: MyGoalsResponse = {
    success: true,
    data: [],
    message: 'Success!',
  }
  try {
    const response = await axios.get<GetMyGoalsResponse>('/goals/my-goals')
    const mappedData = response.data.data?.map((goal) => {
      return mapGoal(goal, showProgress)
    })
    dataReturn.success = true
    dataReturn.data = mappedData
  } catch (error) {
    console.info('/goals/my-goals error', error)
    dataReturn.success = false
  }

  return dataReturn
}

export async function getAllGoals(showProgress: boolean = false): Promise<MyGoalsResponse> {
  let dataReturn: MyGoalsResponse = {
    success: true,
    data: [],
    message: 'Success!',
  }
  try {
    const response = await axios.get<GetMyGoalsResponse>('/goals/all')
    const mappedData = response.data.data?.map((goal) => {
      return mapGoal(goal, showProgress)
    })
    dataReturn.success = true
    dataReturn.data = mappedData
  } catch (error) {
    console.info('/goals/all', error)
    dataReturn.success = false
  }

  return dataReturn
}
