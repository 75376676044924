import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { LoginContent } from '../components/Login/'
import { ICustomLocationState, LoginOptions } from '../models/login'
import { useLoginPage } from '../hooks/pages/login'

export const LoginPage = () => {
  const navigate = useNavigate()
  const { postDataResult, login } = useLoginPage()

  const location = useLocation()
  const state = location.state as ICustomLocationState

  useEffect(() => {
    if (postDataResult?.data) {
      if (postDataResult.data?.screen?.screen == 'welcome') {
        navigate(state?.path || '/welcome')
      } else if (postDataResult.data?.screen?.screen == 'heart-score') {
        navigate(state?.path || '/heart-score')
      } else {
        navigate(state?.path || '/dashboard')
      }
    }
  }, [postDataResult?.data, navigate])

  const handlePostLogin = useCallback(
    async (params: LoginOptions) => {
      await login(params)
    },
    [login]
  )

  const loginForm = useMemo(() => {
    const isLoading = postDataResult?.isLoading || false
    const error = !!postDataResult?.error
    const errorMessage = postDataResult?.error?.message || undefined

    return <LoginContent isLoading={isLoading} isError={error} errorMessage={errorMessage} onLogin={handlePostLogin} />
  }, [postDataResult, handlePostLogin])

  return <>{loginForm}</>
}
