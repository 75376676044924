import React, { useCallback, useState } from 'react'
import { AdessoError } from '../lib/error'
import { deleteMovementRx, getMovementRx, getMovementRxById, postMovementAutoRx, postMovementManualRx, putMovementRx } from '../lib/request'
import { AdessoResult } from '../models'
import { useAuth } from './use-auth'

type TMovementResponse = {
  movements?: any
}

export function useMovementRxPage() {
  const [movementRxResult, setMovementRxResult] = useState<AdessoResult<TMovementResponse>>()
  const [movementRxResultById, setMovementRxResultById] = useState<AdessoResult<TMovementResponse>>()

  const auth = useAuth()

  const useGetMovementRx = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMovementRxResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getMovementRx()
        if (res.success) {
          setMovementRxResult({
            data: { movements: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setMovementRxResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setMovementRxResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Movement Rx error'),
      })
      throw new AdessoError('Get Movements Rx Failed')
    }
  }, [])

  const useGetByIdMovementRx = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMovementRxResultById({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getMovementRxById(id)
        if (res.success) {
          setMovementRxResultById({
            data: { movements: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setMovementRxResultById({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setMovementRxResultById({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Movement Rx By Id error'),
      })
      throw new AdessoError('Get Movements Rx By Id Failed')
    }
  }, [])

  const usePostMovementManualRx = useCallback(async (dataCreate: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await postMovementManualRx(dataCreate)
      }
    } catch (error) {
      throw new AdessoError('Create Movements Manual Rx Failed')
    }
  }, [])

  const usePostMovementAutoRx = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await postMovementAutoRx()
      }
    } catch (error) {
      throw new AdessoError('Create Movements Auto Rx Failed')
    }
  }, [])

  const usePutMovementRx = useCallback(async (dataUpdate: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await putMovementRx(dataUpdate)
      }
    } catch (error) {
      throw new AdessoError('Update Movements Rx Failed')
    }
  }, [])

  const useDeleteMovementRx = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await deleteMovementRx(id)
      }
    } catch (error) {
      throw new AdessoError('Delete Movements Rx Failed')
    }
  }, [])

  return { movementRxResult, movementRxResultById, useGetMovementRx, useGetByIdMovementRx, usePostMovementManualRx, usePostMovementAutoRx, usePutMovementRx, useDeleteMovementRx }
}
