import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EndMyHealth } from '../../components/MyTutorials/Screen/MyHealth/EndMyHealth'
import MyAdessoMyHealthIntroductionScreen from '../../components/MyTutorials/Screen/MyHealth/MyAdessoMyHealth'
import { dataEndOfMyHealthTutorial } from '../../constants/introduction'

type Props = {}

enum IntroductionScreen {
  MyHealth,
  Finish,
}

const MyHealthTutorial = (props: Props) => {
  const [screen, setScreen] = useState<IntroductionScreen>(IntroductionScreen.MyHealth)
  const navigate = useNavigate()

  const handleClickNextScreen = useCallback((stepCurrent: IntroductionScreen, shouldLogout = false) => {
    setScreen(stepCurrent + 1)
  }, [])

  const handleClickCloseDemo = useCallback(() => {
    navigate('/my-tutorials')
  }, [])

  const myHealthContent = useMemo(() => {
    return <MyAdessoMyHealthIntroductionScreen nextScreen={() => handleClickNextScreen(screen)} closeScreen={() => handleClickCloseDemo()} />
  }, [screen, handleClickNextScreen])

  switch (screen) {
    case IntroductionScreen.MyHealth:
      return myHealthContent
    case IntroductionScreen.Finish:
      return <EndMyHealth text={dataEndOfMyHealthTutorial.text} linkPages={dataEndOfMyHealthTutorial.pages} />

    default:
      return <></>
  }
}

export default MyHealthTutorial
