import React, { ChangeEvent, useMemo } from 'react'

type Props = {
  fieldName?: string
  type?: string
  placeholder?: string
  classNameContainer?: string
  classNameLabel?: string
  classNameInput?: string
  classNameErrorMessage?: string
  errorMessage?: string
  idInput?: string
  nameInput?: string
  value?: string | number
  disabled?: boolean
  min?: number
  max?: number
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const InputField = ({
  min,
  max,
  idInput,
  nameInput,
  type,
  value,
  fieldName,
  placeholder,
  classNameContainer,
  classNameLabel,
  classNameInput,
  errorMessage,
  classNameErrorMessage,
  onChange,
}: Props) => {
  const input = useMemo(() => {
    return (
      <div className={`${classNameContainer}`}>
        <label htmlFor={fieldName} className={`mb-2 block text-[16px] font-semibold text-[#000] dark:text-white ${classNameLabel}`}>
          {fieldName}
        </label>
        <input
          min={min}
          max={max}
          type={type ?? 'text'}
          id={idInput}
          name={nameInput}
          value={value}
          className={`block w-full border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900 shadow-inner shadow-stone-600 focus:border-gray-400 focus:outline-0 focus:ring-pink-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-pink-500 dark:focus:ring-pink-500 ${classNameInput}`}
          placeholder={placeholder}
          onChange={onChange}
          // required
        />
      </div>
    )
  }, [value])
  return input
}

export default InputField
