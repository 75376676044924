import { ButtonStyles, WrapperCommon, ButtonCommon } from '../../../Common'
import { useLocales } from '../../../../locales'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Page {
  title: string
  link: string
}
type Props = {
  text: string
  linkPages?: Page[]
}
type DTPagesProps = {
  pages: Page[]
}

export const EndHeartChart = ({ text, linkPages }: Props) => {
  const { t } = useLocales()
  const navigate = useNavigate()
  const { setShowHeader, setShowFeedback } = useHeaderContext()

  useEffect(() => {
    setShowHeader(false)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 100)
  }, [])

  const features = ['line1', 'line2', 'line3']

  const MyAdessoMobileApp = () => {
    return (
      <div className="space-y-5">
        <div className=" mx-2 w-full font-[700] leading-5 tracking-[0.15px]">
          <p className="my-6 text-[20px] ">{t('introduction.myAdessoHeartChart.finish.myAdessoMobileApp.title')}</p>
          <p className="mb-6 text-[16px]">{t('introduction.myAdessoHeartChart.finish.myAdessoMobileApp.text')}</p>
          <ul className="flex list-disc flex-col gap-6 pl-6">
            {features.map((feature, index) => (
              <li key={index}>{t(`introduction.myAdessoHeartChart.finish.myAdessoMobileApp.${feature}`)}</li>
            ))}
          </ul>
        </div>
        <ButtonCommon
          className="px-11 uppercase"
          scrollMarginTop="scroll-mt-0"
          scrollMarginBottom="scroll-mt-0"
          style={ButtonStyles.EndOfOnboarding}
          width="w-[320px]"
          height="h-[50px]"
          text={t('introduction.myAdessoHeartChart.finish.myAdessoMobileApp.textButton')}
          onClick={() => {
            setShowHeader(true)
            setShowFeedback(true)
            window.open('https://apps.apple.com/us/app/myadesso/id6497954262', '_blank')
          }}
        />
      </div>
    )
  }

  const YourHealth = () => {
    return (
      <>
        <div className=" mx-2 w-full font-[700] leading-5 tracking-[0.15px]">
          <p className="my-6 text-[20px] ">{t('introduction.myAdessoHeartChart.finish.yourHealth.title')}</p>
          <p className="mb-6 text-[16px]">{t('introduction.myAdessoHeartChart.finish.yourHealth.text')}</p>
        </div>
        <ButtonCommon
          className="uppercase"
          scrollMarginTop="scroll-mt-0"
          scrollMarginBottom="scroll-mt-0"
          style={ButtonStyles.EndOfOnboarding}
          width="w-[320px]"
          height="h-[51px]"
          text={t('introduction.myAdessoHeartChart.finish.yourHealth.textButton')}
          onClick={() => {
            setShowHeader(true)
            setShowFeedback(true)
            navigate('/my-health')
          }}
        />
      </>
    )
  }

  const DailyTracking = () => {
    return (
      <>
        <div className=" mx-2 w-full font-[700] leading-5 tracking-[0.15px]">
          <p className="my-6 text-[20px] ">{t('introduction.myAdessoHeartChart.finish.dailyTracking.title')}</p>
          <p className="mb-6 text-[16px]">{t('introduction.myAdessoHeartChart.finish.dailyTracking.text')}</p>
        </div>
        <ButtonCommon
          className="uppercase"
          scrollMarginTop="scroll-mt-0"
          scrollMarginBottom="scroll-mt-0"
          style={ButtonStyles.EndOfOnboarding}
          width="w-[320px]"
          height="h-[51px]"
          text={t('introduction.myAdessoHeartChart.finish.dailyTracking.textButton')}
          onClick={() => {
            setShowHeader(true)
            setShowFeedback(true)
            navigate('/daily-tracking')
          }}
        />
      </>
    )
  }

  const MyTutorials: React.FC<DTPagesProps> = ({ pages }) => {
    return (
      <>
        <div className="my-6 w-full pl-2 text-[18px] font-[500] leading-5 tracking-[0.15px]">
          <p className="my-6 text-[20px] font-[700] leading-5 tracking-[0.15px]">{t('introduction.myAdessoHeartChart.finish.myTutorials.title')}</p>
          <p className="mb-6">{t('introduction.myAdessoHeartChart.finish.myTutorials.text')}</p>
          <p className="mb-6">{t('introduction.myAdessoHeartChart.finish.myTutorials.line')}</p>
          <ul className="mb-6 flex flex-col gap-6">
            {pages.map((page, index) => (
              <li
                key={index}
                className={`w-max cursor-pointer underline`}
                onClick={() => {
                  navigate(page.link)
                  setShowHeader(true)
                  setShowFeedback(true)
                }}
              >
                {t(page.title)}
              </li>
            ))}
          </ul>
        </div>
        <ButtonCommon
          className="uppercase"
          scrollMarginTop="scroll-mt-0"
          scrollMarginBottom="scroll-mt-0"
          style={ButtonStyles.EndOfOnboarding}
          width="w-[237px]"
          height="h-[51px]"
          text={t('introduction.myAdessoHeartChart.finish.myTutorials.textButton')}
          onClick={() => {
            setShowHeader(true)
            navigate('/my-tutorials')
          }}
        />
      </>
    )
  }

  return (
    <WrapperCommon className="mt-14 min-h-[calc(100vh-74px)] bg-transparent text-white">
      <div className="fixed bottom-0 left-0 right-0 top-0 z-10 bg-white ">
        <div className="h-full bg-[url('assets/images/bg-end-of-onboarding-session.png')] bg-cover bg-center bg-no-repeat"></div>
      </div>
      <div className="relative z-20 flex h-full w-full flex-col justify-between font-['Proxima_Nova']">
        <div className="w-full text-center ">
          <p className="mb-2 text-[35px] font-[400] leading-10">{t('introduction.myAdessoHeartChart.finish.title')}</p>
          <p className="text-[22px] font-[500] leading-7">{t(text) || ''}</p>
        </div>

        <MyAdessoMobileApp />

        <YourHealth />

        <DailyTracking />

        <MyTutorials pages={linkPages || []} />
      </div>
    </WrapperCommon>
  )
}
