import axios from 'axios'

class DeeperServices {
  getDeepers() {
    return axios.get(import.meta.env.VITE_APP_HOST_URL + '/sanctum/csrf-cookie').then(function () {
      return axios
        .get('/deeper-topics')
        .then((response) => {
          return response.data
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            return error.response.data
          } else if (error.request || error.message) {
            return error.message
          } else {
            //
          }
        })
    })
  }

  getDeeperArticlesSession(slug: string) {
    return axios.get(import.meta.env.VITE_APP_HOST_URL + '/sanctum/csrf-cookie').then(function () {
      return axios
        .get(`/deeper-session/${slug}`)
        .then((response) => {
          return response.data
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            return error.response.data
          } else if (error.request || error.message) {
            return error.message
          } else {
            //
          }
        })
    })
  }
}

export default new DeeperServices()
