import { useState, useCallback, useMemo } from 'react'
import { ScreenHeartAssessment01 } from './HeartAssessment01'
import { SessionButtons } from '../../layout'
import { Session07Wrapper } from '../Wrapper'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

enum Screen {
  HeartAssessment01,
  HeartAssessment02,
  HeartAssessment03,
}

export const ScreenHeartAssessment = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.HeartAssessment03 : step.sub_step)

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 1, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.HeartAssessment01) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.HeartAssessment03) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: Screen) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenHeartAssessment = useMemo(() => {
    switch (screen) {
      case 0:
        return <ScreenHeartAssessment01 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />
      case 1:
        return (
          <Session07Wrapper buttons={buttons}>
            <div className="session-style">
              <video className="block h-auto w-full" controls autoPlay muted>
                <source src="https://api.myadesso.health/uploads/animation/ss_07.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Session07Wrapper>
        )
      case 2:
        return (
          <Session07Wrapper buttons={buttons}>
            <div className="session-style">
              <h4>The importance of getting a physical and check-ups.</h4>

              <picture className={``}>
                <source srcSet={`/images/my-adresso-journey/session/07/img_001.webp`} type="image/webp" />
                <img src={`/images/my-adresso-journey/session/07/img_001.png`} alt="Description" />
              </picture>
              <p>{`If you aren’t meeting with your primary care provider regularly, you should! This is an important relationship. You have to feel like your provider is a partner in your health care, someone you can have open communication and dialogue with about your health. Your provider can help you create a wellness plan to improve any conditions or symptoms you are experiencing.`}</p>
              <h4 className="text-ma-pink">What to expect from your provider in your next visit:</h4>
              <ul>
                <li>Asking about changes or concerns with your health</li>
                <li>Asking about your feelings like whether you are facing stress, anxiety, depression</li>
                <li>Checking in about any medications and supplements you are taking</li>
                <li>Asking about your diet, exercise and sleep</li>
                <li>Weighing you</li>
                <li>Checking your blood pressure and heart rate</li>
                <li>A head to toe check-up, including things like listening to your heart and lungs, looking in your eyes, feeling your thyroid, and examining for any abnormal signs</li>
                <li>Taking blood tests (this may vary depending on the practitioner). We will give you a list of blood tests we recommend for women to get at least once a year</li>
                <li>Maybe an EKG (electrocardiogram) to assess your heart</li>
              </ul>
              <p>Make sure to explore the checklist for your physical in the deep dives at the end of this session.</p>
            </div>
          </Session07Wrapper>
        )
    }
  }, [screen, buttons, handleBackScreen, handleNextScreen, showModal, handleUpdate])

  return <>{screenHeartAssessment}</>
}
