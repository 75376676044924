import axios from 'axios'
import { AdessoError } from '../error'

type TSupplementRxResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getSupplementRx(): Promise<TSupplementRxResponse> {
  const { data, status } = await axios.get(`/supplements`)

  if (status !== 200) {
    console.info('getSupplementRx', data)
    throw new AdessoError(`Request getSupplementRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TSupplementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function getSupplementRxById(id: number): Promise<TSupplementRxResponse> {
  const { data, status } = await axios.get(`/supplements/${id}`)

  if (status !== 200) {
    console.info('getSupplementRxById', data)
    throw new AdessoError(`Request getSupplementRxById error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TSupplementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function deleteSupplementRx(id: number): Promise<TSupplementRxResponse> {
  const { data, status } = await axios.delete(`/supplements/${id}`)
  if (status !== 200) {
    console.info('deleteSupplementRx', data)
    throw new AdessoError(`Request supplement rx error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}

export async function putSupplementRx(id: number, dataUpdate: any): Promise<TSupplementRxResponse> {
  const { data, status } = await axios.put(`/supplements/${id}`, dataUpdate)
  if (status !== 200) {
    console.info('putSupplementRx', data)
    throw new AdessoError(`Request update supplement Rx error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}

export async function postSupplementRx(dataCreate: any): Promise<TSupplementRxResponse> {
  const { data, status } = await axios.post(`/supplements`, dataCreate)

  if (status !== 200) {
    console.info('postSupplementsRx', data)
    throw new AdessoError(`Request postSupplementsRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TSupplementRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
