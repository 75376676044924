import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { DashboardSection } from '../Common/DashboardSection'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { useLocales } from '../../locales';

type Props = {
  isIntroduction?: boolean
  myNowContent?: string
  handleUpdateMyNow?: Function
  jumpTo: any
  [_: string]: any
}

export const MyNow = ({ myNowContent, isIntroduction, handleUpdateMyNow, jumpTo }: Props) => {
  const myNowLocalStorage = localStorage.getItem('myNow') || ''
  const { setItemLS } = useLocalStorage()
  const [myNow, setMyNow] = useState<string>(myNowLocalStorage)
  const [newMyNow, setNewMyNow] = useState<string>('')
  const [isShowModalEdit, setIsShowModalEdit] = useState<boolean>(false)
  const {t} = useLocales()

  useEffect(() => {
    if (myNowContent) {
      setMyNow(myNowContent)
    }
  }, [myNowContent])

  const handleChangeMyNow = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewMyNow(event.target.value)
  }, [])

  const handleUpdateMyNowAction = useCallback(() => {
    setMyNow(newMyNow)
    setItemLS('myNow', newMyNow)
    setIsShowModalEdit(false)
    if (handleUpdateMyNow) {
      handleUpdateMyNow(newMyNow)
    }
  }, [newMyNow])

  const handleOpenModalEditMyNow = useCallback(() => {
    setNewMyNow(myNow)
    setIsShowModalEdit(true)
  }, [myNow])

  const modalEditMyNow = useMemo(() => {
    return (
      <div className="modal" id="edit-my-now">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Edit My Now</h3>
          <textarea className="textarea textarea-bordered mt-10 w-full rounded-2xl p-4 text-lg placeholder:text-lg placeholder:text-black" value={newMyNow} onChange={handleChangeMyNow} />
          <div className="modal-action">
            <a
              href="#"
              onClick={handleUpdateMyNowAction}
              className="flex h-quiz-button items-center justify-center rounded-full bg-color-text-primary px-10 text-[15px] font-medium uppercase text-white"
            >
              Update
            </a>
          </div>
        </div>
      </div>
    )
  }, [newMyNow])

  return (
    <>
      <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myNow')}>
        <div
          className={`flex aspect-[3/1] w-full items-center justify-center rounded bg-dashboard-my-now bg-cover bg-center bg-no-repeat px-8 text-[22px] font-bold leading-[1.1818] tracking-[0.15px] text-white md:px-20 lg:px-40 ${
            isIntroduction ? 'py-5 text-left' : 'py-10 text-center'
          }`}
        >
          {myNow}
        </div>

        <div className="flex justify-end">
          <a href="#edit-my-now" className="text-[14px] leading-none text-color-primary underline decoration-color-primary" onClick={handleOpenModalEditMyNow}>
          {t('extraText.editMyNow')}
          </a>
        </div>
      </DashboardSection>

      {!isIntroduction && isShowModalEdit && modalEditMyNow}
    </>
  )
}
