import React, { useEffect, useMemo, useState } from 'react'
import InputField from '../Form/InputField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ButtonMyHealth } from '../Button'
import WrapperMyHealth from '../MyHealthWrapper'
import Toggle from '../Form/Toggle'
import { useContextMovementRx } from '../../../context/movementRxContext'
import { useMovementRxPage } from '../../../hooks/use-movement-rx'
import { format, parse } from 'date-fns'
import { useAlert } from '../../../context/AlertContext'
import { useBoolean } from '../../../hooks/use-boolean'
import { ErrorMessage } from '../../Common'

type Props = {
  prevScreen?: any
  nextScreen?: any
}

const defaultAddMovementData: any = {
  target_heart_rate: '',
  times_per_week: '',
  minutes: '',
  activity: '',
  approved_by_physician: false,
}

const listActivity: any = {
  'High-intensity walking': false,
  Running: false,
  Biking: false,
  Hiking: false,
  Yoga: false,
  Pilates: false,
  Swimming: false,
  Dancing: false,
  Aerobics: false,
  'Lifting Weights': false,
}

const AddMovement = ({ prevScreen, nextScreen }: Props) => {
  const isOpenSelectActivity = useBoolean()

  const [dataActivity, setDataActivity] = useState<any>(listActivity)

  const { addDataMovementRx, addDataMovement } = useContextMovementRx()

  const { usePostMovementManualRx, usePutMovementRx } = useMovementRxPage()

  const [alarmFor, setAlarmFor] = useState<string>('')
  const [isCheckError, setIsCheckError] = useState<boolean>(false)
  const { setAlertError } = useAlert()
  const isLoadingSubmit = useBoolean()

  const formik = useFormik({
    initialValues: defaultAddMovementData,
    validationSchema: Yup.object({
      target_heart_rate: Yup.string().required('Field is required'),
      times_per_week: Yup.string().required('Field is required'),
      minutes: Yup.string().required('Field is required'),
    }),
    onSubmit: async (values) => {
      const addMovementRx = { ...values, is_alarm: false }
      const editMovementRx = values
      const dataSubmit = addDataMovementRx?.alarm ? editMovementRx : addMovementRx
      delete dataSubmit?.alarm_id
      try {
        isLoadingSubmit.onTrue()
        if (addDataMovementRx?.id) {
          await usePutMovementRx(dataSubmit)
        } else {
          await usePostMovementManualRx(dataSubmit)
        }
      } catch (error) {
        isLoadingSubmit.onFalse()
        setAlertError(`${error}`)
        return { message: 'Error' }
      } finally {
        isLoadingSubmit.onFalse()
        addDataMovement(defaultAddMovementData)
        prevScreen()
      }
    },
  })

  const handleUpdateHiddenField = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  const handleSubmitForm = () => {
    setIsCheckError(true)
    formik.handleSubmit
  }

  const handleChangeActivity = (item: any) => {
    setDataActivity((prev: any) => {
      const clar = { ...prev }
      clar[item] = !clar[item]
      return clar
    })
  }

  useEffect(() => {
    if (dataActivity) {
      const selectedActivities = Object.entries(dataActivity)
        .filter(([activity, isSelected]) => isSelected)
        .map(([activity]) => activity)

      const result = selectedActivities.join(', ')

      formik.setFieldValue('activity', result)
    }
  }, [dataActivity])

  useEffect(() => {
    formik.setValues(addDataMovementRx)
  }, [addDataMovementRx])

  useEffect(() => {
    if (formik.values?.alarm?.alarm_at) {
      const parsedTime = parse(formik.values?.alarm?.alarm_at, 'HH:mm:ss', new Date())
      const formattedTime = format(parsedTime, 'h:mm aa')
      setAlarmFor(formattedTime)
    }
  }, [formik.values?.alarm?.alarm_at])

  useEffect(() => {
    const listActivityFormat: any = {
      'High-intensity walking': false,
      Running: false,
      Biking: false,
      Hiking: false,
      Yoga: false,
      Pilates: false,
      Swimming: false,
      Dancing: false,
      Aerobics: false,
      'Lifting Weights': false,
    }
    if (addDataMovementRx?.activity) {
      const activities = addDataMovementRx?.activity.split(',').map((act: any) => act.trim())
      activities.forEach((act: any) => {
        if (listActivityFormat.hasOwnProperty(act)) {
          listActivityFormat[act] = true
        }
        setDataActivity(listActivityFormat)
      })
    }
  }, [])

  const formMovement = useMemo(() => {
    return (
      <div className="space-y-5">
        <h5 className="text-center text-[16px] font-medium uppercase">{addDataMovementRx?.id ? 'Edit My exercise' : 'Add My exercise'}</h5>
        <InputField
          min={70}
          max={160}
          type="number"
          idInput="target_heart_rate"
          nameInput="target_heart_rate"
          fieldName="Target Heart Rate"
          placeholder=""
          value={formik.values.target_heart_rate}
          onChange={formik.handleChange}
        />
        {isCheckError && formik.errors.target_heart_rate && <ErrorMessage text={formik.errors.target_heart_rate} />}

        <InputField idInput="times_per_week" nameInput="times_per_week" fieldName="Times/Week" placeholder="" value={formik.values.times_per_week} onChange={formik.handleChange} />
        {isCheckError && formik.errors.times_per_week && <ErrorMessage text={formik.errors.times_per_week} />}

        <InputField idInput="minutes" nameInput="minutes" fieldName="Minutes" placeholder="" value={formik.values.minutes} onChange={formik.handleChange} />
        {isCheckError && formik.errors.minutes && <ErrorMessage text={formik.errors.minutes} />}

        <div className="relative">
          <div className="relative cursor-pointer" onClick={isOpenSelectActivity.onToggle}>
            <InputField classNameContainer={`cursor-pointer`} idInput="activity" nameInput="activity" fieldName="Activity" placeholder="" value={formik.values.activity} />
            <div className="  absolute right-1 top-11 h-[30px] w-[30px] bg-gray-50 bg-[url('assets/images/icon/i-arrow_select_down.svg')] bg-no-repeat" />
          </div>

          {isOpenSelectActivity.value && (
            <div id="dropdown" className="absolute z-20 my-2 h-48 w-full divide-y divide-gray-100 overflow-auto rounded-lg bg-white shadow dark:bg-gray-700">
              <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                {Object.keys(listActivity).map((item, index) => (
                  <li
                    key={item}
                    id={`${item}_${index}`}
                    onChange={() => handleChangeActivity(item)}
                    className="mx-4 my-2 block cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <input type="checkbox" id={item} value="" className="peer hidden" required checked={dataActivity[item]} onChange={() => {}} />
                    <label
                      htmlFor={item}
                      className="inline-flex w-full cursor-pointer items-center justify-start px-3 text-[#000] hover:bg-gray-50 hover:text-gray-600 peer-checked:border-gray-400 peer-checked:bg-gray-400 peer-checked:text-[#FFF] dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:peer-checked:text-gray-300"
                    >
                      <div className="">
                        <div className="py-2 text-[14px] font-semibold">{item}</div>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <Toggle idInput="approved_by_physician" nameInput="approved_by_physician" toggleName="Approved By Physician" checked={formik.values.approved_by_physician} onChange={handleUpdateHiddenField} />
        {addDataMovementRx?.alarm && (
          <div>
            <Toggle idInput="is_alarm" nameInput="is_alarm" toggleName={`Alarm Set For ${alarmFor}`} checked={formik.values.is_alarm} onChange={handleUpdateHiddenField} />
          </div>
        )}
      </div>
    )
  }, [screen, formik, addDataMovementRx, alarmFor])

  const button = useMemo(() => {
    return (
      <div className="flex flex-col justify-center gap-3">
        <div className="w-full">
          <ButtonMyHealth
            nameButton={addDataMovementRx?.alarm ? 'EDIT ALARM' : 'SET ALARM'}
            bgButton="bg-[#F26C59]"
            className={'w-full max-w-full'}
            nextScreen={() => {
              try {
                addDataMovement(formik.values)
              } finally {
                nextScreen()
              }
            }}
          />
        </div>
        <div className="flex gap-3">
          <ButtonMyHealth
            nameButton="CANCEL"
            bgButton="bg-color-text-secondary"
            prevScreen={() => {
              prevScreen()
            }}
          />
          <ButtonMyHealth
            className={`${isLoadingSubmit.value ? 'cursor-wait px-[50px] opacity-25' : ''}`}
            isLoading={isLoadingSubmit.value}
            disabled={isLoadingSubmit.value}
            type="submit"
            nameButton="SUBMIT"
            bgButton="bg-color-primary"
            submit={handleSubmitForm}
          />
        </div>
      </div>
    )
  }, [formik, addDataMovementRx, isLoadingSubmit.value])

  const addMovement = useMemo(() => {
    return (
      <WrapperMyHealth title="moveRx" prevScreen={prevScreen} button={button} boxShadow={true} onSubmit={formik.handleSubmit}>
        {formMovement}
      </WrapperMyHealth>
    )
  }, [formik, screen, addDataMovementRx])

  return <>{addMovement}</>
}

export default AddMovement
