import React, { useEffect, useMemo, useRef } from 'react'

import './styles.css'

interface Item {
  value: string
  label: string
}

interface WheelPickerProps {
  alarmAt?: string
  dateItems: Item[]
  dateValue: string
  onDateChange: (value: string) => void
  hourItems: Item[]
  hourValue: string
  onHourChange: (value: string) => void
  minuteItems: Item[]
  minuteValue: string
  onMinuteChange: (value: string) => void
  ampmItems: Item[]
  ampmValue: string
  onAmpmChange: (value: string) => void
  containerHeight?: number
  itemHeight?: number
}

const WheelPicker: React.FC<WheelPickerProps> = ({
  alarmAt,
  dateItems,
  dateValue,
  onDateChange,
  hourItems,
  hourValue,
  onHourChange,
  minuteItems,
  minuteValue,
  onMinuteChange,
  ampmItems,
  ampmValue,
  onAmpmChange,
  containerHeight = 210,
  itemHeight = 32,
}) => {
  if (alarmAt) {
    ;[hourValue, minuteValue, ampmValue] = alarmAt?.split(':')
  }

  const hourItemsContRef = useRef<HTMLUListElement>(null)
  const dateItemsContRef = useRef<HTMLUListElement>(null)
  const minuteItemsContRef = useRef<HTMLUListElement>(null)
  const ampmItemsContRef = useRef<HTMLUListElement>(null)
  const isScrolling = useRef<number | null>(null)
  const dateRefs = useRef<HTMLLIElement[]>([])
  const hourRefs = useRef<HTMLLIElement[]>([])
  const minuteRefs = useRef<HTMLLIElement[]>([])
  const ampmRefs = useRef<HTMLLIElement[]>([])
  const dateItemsMap = useMemo(() => new Map<string, number>(dateItems.map((item, index) => [item.value, index])), [dateItems])
  const currentDateValue = useRef<number>(dateItemsMap.get(dateValue) ?? 0)
  const hourItemsMap = useMemo(() => new Map<string, number>(hourItems.map((item, index) => [item.value, index])), [hourItems])
  const currentHourValue = useRef<number>(hourItemsMap.get(hourValue) ?? 0)
  const minuteItemsMap = useMemo(() => new Map<string, number>(minuteItems.map((item, index) => [item.value, index])), [minuteItems])
  const currentMinuteValue = useRef<number>(minuteItemsMap.get(minuteValue) ?? 0)
  const ampmItemsMap = useMemo(() => new Map<string, number>(ampmItems.map((item, index) => [item.value, index])), [ampmItems])
  const currentAmpmValue = useRef<number>(ampmItemsMap.get(ampmValue) ?? 0)

  const visibleItemsCount = Math.floor(containerHeight / itemHeight)
  const offset = Math.round((visibleItemsCount + 1) / 2) + 1
  const maxScrollOffset = (containerHeight - itemHeight) / 2

  // function rerenderDateElements(
  //   selectedElement: number,
  //   scrollTop: number,
  //   firstItemIndex: number = Math.max(selectedElement - offset, 0),
  //   lastItemIndex: number = Math.min(selectedElement + offset, dateItems.length)
  // ) {
  //   if (dateRefs.current) {
  //     dateRefs.current.slice(firstItemIndex, lastItemIndex).forEach((item, index) => {
  //       const realIndex = index + firstItemIndex
  //       const scrollOffset = Math.min(Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2), maxScrollOffset)
  //       const sin = scrollOffset / maxScrollOffset
  //       const cos = Math.sqrt(1 - sin ** 2)
  //       const [div] = item.getElementsByTagName('div')
  //       if (div) {
  //         div.style.transform = `rotateX(${Math.asin(sin)}rad) scale(${cos})`
  //         div.style.transformOrigin = 'right'
  //       }
  //     })
  //   }
  // }

  function rerenderHourElements(
    selectedElement: number,
    scrollTop: number,
    firstItemIndex: number = Math.max(selectedElement - offset, 0),
    lastItemIndex: number = Math.min(selectedElement + offset, hourItems.length)
  ) {
    if (hourRefs.current) {
      hourRefs.current.slice(firstItemIndex, lastItemIndex).forEach((item, index) => {
        const realIndex = index + firstItemIndex
        const scrollOffset = Math.min(Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2), maxScrollOffset)
        const sin = scrollOffset / maxScrollOffset
        const cos = Math.sqrt(1 - sin ** 2)
        const [div] = item.getElementsByTagName('div')
        if (div) {
          div.style.transform = `rotateX(${Math.asin(sin)}rad) scale(${cos})`
          div.style.transformOrigin = 'center'
        }
      })
    }
  }

  function rerenderMinuteElements(
    selectedElement: number,
    scrollTop: number,
    firstItemIndex: number = Math.max(selectedElement - offset, 0),
    lastItemIndex: number = Math.min(selectedElement + offset, minuteItems.length)
  ) {
    if (minuteRefs.current) {
      minuteRefs.current.slice(firstItemIndex, lastItemIndex).forEach((item, index) => {
        const realIndex = index + firstItemIndex
        const scrollOffset = Math.min(Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2), maxScrollOffset)
        const sin = scrollOffset / maxScrollOffset
        const cos = Math.sqrt(1 - sin ** 2)
        const [div] = item.getElementsByTagName('div')
        if (div) {
          div.style.transform = `rotateX(${Math.asin(sin)}rad) scale(${cos})`
          div.style.transformOrigin = 'left'
        }
      })
    }
  }

  function rerenderAmpmElements(
    selectedElement: number,
    scrollTop: number,
    firstItemIndex: number = Math.max(selectedElement - offset, 0),
    lastItemIndex: number = Math.min(selectedElement + offset, ampmItems.length)
  ) {
    if (ampmRefs.current) {
      ampmRefs.current.slice(firstItemIndex, lastItemIndex).forEach((item, index) => {
        const realIndex = index + firstItemIndex
        const scrollOffset = Math.min(Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2), maxScrollOffset)
        const sin = scrollOffset / maxScrollOffset
        const cos = Math.sqrt(1 - sin ** 2)
        const [div] = item.getElementsByTagName('div')
        if (div) {
          div.style.transform = `rotateX(${Math.asin(sin)}rad) scale(${cos})`
          div.style.transformOrigin = 'left'
        }
      })
    }
  }

  useEffect(() => {
    let isAnimating = false

    function handleHourScroll(event: Event) {
      if (!isAnimating && hourItemsContRef.current) {
        isAnimating = true

        requestAnimationFrame(() => {
          const target = event.target as HTMLUListElement
          const scrollTop = Math.max(target.scrollTop, 0)
          const selectedElement = Math.min(Math.max(Math.floor(scrollTop / itemHeight), 0), hourItems.length - 1)
          window.clearTimeout(isScrolling.current ?? undefined)
          rerenderHourElements(selectedElement, scrollTop)

          currentHourValue.current = selectedElement
          isScrolling.current = window.setTimeout(() => {
            // Use onHourChange instead of handleHourChange
            onHourChange(hourItems[selectedElement]?.value)
          }, 20)

          isAnimating = false
        })
      }
    }

    hourItemsContRef.current?.addEventListener('scroll', handleHourScroll)
    // Check if currentHourValue.current is defined before using it
    if (currentHourValue.current !== undefined) {
      hourRefs.current[currentHourValue.current]?.scrollIntoView({
        block: 'center',
      })
      rerenderHourElements(
        currentHourValue.current,
        hourItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
        0,
        hourItems.length
      )
    }

    return () => {
      hourItemsContRef.current?.removeEventListener('scroll', handleHourScroll)
    }
  }, [hourItemsContRef.current, hourItems, onHourChange]) // Include dependencies

  // useEffect(() => {
  //   let isAnimating = false

  //   function handleDateScroll(event: Event) {
  //     if (!isAnimating && dateItemsContRef.current) {
  //       isAnimating = true

  //       requestAnimationFrame(() => {
  //         const target = event.target as HTMLUListElement
  //         const scrollTop = Math.max(target.scrollTop, 0)
  //         const selectedElement = Math.min(Math.max(Math.floor(scrollTop / itemHeight), 0), dateItems.length - 1)
  //         window.clearTimeout(isScrolling.current ?? undefined)
  //         rerenderDateElements(selectedElement, scrollTop)

  //         currentDateValue.current = selectedElement
  //         isScrolling.current = window.setTimeout(() => {
  //           // Use onDateChange instead of handleDateChange
  //           onDateChange(dateItems[selectedElement]?.value)
  //         }, 20)

  //         isAnimating = false
  //       })
  //     }
  //   }

  //   dateItemsContRef.current?.addEventListener('scroll', handleDateScroll)
  //   // Check if currentDateValue.current is defined before using it
  //   if (currentDateValue.current !== undefined) {
  //     dateRefs.current[currentDateValue.current]?.scrollIntoView({
  //       block: 'center',
  //     })
  //     rerenderDateElements(
  //       currentDateValue.current,
  //       dateItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
  //       0,
  //       dateItems.length
  //     )
  //   }

  //   return () => {
  //     dateItemsContRef.current?.removeEventListener('scroll', handleDateScroll)
  //   }
  // }, [dateItemsContRef.current, dateItems, onDateChange]) // Include dependencies

  useEffect(() => {
    let isAnimating = false

    function handleMinuteScroll(event: Event) {
      if (!isAnimating && minuteItemsContRef.current) {
        isAnimating = true

        requestAnimationFrame(() => {
          const target = event.target as HTMLUListElement
          const scrollTop = Math.max(target.scrollTop, 0)
          const selectedElement = Math.min(Math.max(Math.floor(scrollTop / itemHeight), 0), minuteItems.length - 1)
          window.clearTimeout(isScrolling.current ?? undefined)
          rerenderMinuteElements(selectedElement, scrollTop)

          currentMinuteValue.current = selectedElement
          isScrolling.current = window.setTimeout(() => {
            // Use onMinuteChange instead of handleMinuteChange
            onMinuteChange(minuteItems[selectedElement]?.value)
          }, 20)

          isAnimating = false
        })
      }
    }

    minuteItemsContRef.current?.addEventListener('scroll', handleMinuteScroll)
    // Check if currentMinuteValue.current is defined before using it
    if (currentMinuteValue.current !== undefined) {
      minuteRefs.current[currentMinuteValue.current]?.scrollIntoView({
        block: 'center',
      })
      rerenderMinuteElements(
        currentMinuteValue.current,
        minuteItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
        0,
        minuteItems.length
      )
    }

    return () => {
      minuteItemsContRef.current?.removeEventListener('scroll', handleMinuteScroll)
    }
  }, [minuteItemsContRef.current, minuteItems, onMinuteChange]) // Include dependencies

  useEffect(() => {
    let isAnimating = false

    function handleAmpmScroll(event: Event) {
      if (!isAnimating && ampmItemsContRef.current) {
        isAnimating = true

        requestAnimationFrame(() => {
          const target = event.target as HTMLUListElement
          const scrollTop = Math.max(target.scrollTop, 0)
          const selectedElement = Math.min(Math.max(Math.floor(scrollTop / itemHeight), 0), ampmItems.length - 1)
          window.clearTimeout(isScrolling.current ?? undefined)
          rerenderAmpmElements(selectedElement, scrollTop)

          currentAmpmValue.current = selectedElement
          isScrolling.current = window.setTimeout(() => {
            // Use onAmpmChange instead of handleAmpmChange
            onAmpmChange(ampmItems[selectedElement]?.value)
          }, 20)

          isAnimating = false
        })
      }
    }

    ampmItemsContRef.current?.addEventListener('scroll', handleAmpmScroll)
    // Check if currentAmpmValue.current is defined before using it
    if (currentAmpmValue.current !== undefined) {
      ampmRefs.current[currentAmpmValue.current]?.scrollIntoView({
        block: 'center',
      })
      rerenderAmpmElements(
        currentAmpmValue.current,
        ampmItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
        0,
        ampmItems.length
      )
    }

    return () => {
      ampmItemsContRef.current?.removeEventListener('scroll', handleAmpmScroll)
    }
  }, [ampmItemsContRef.current, ampmItems, onAmpmChange]) // Include dependencies

  // useEffect(() => {
  //   const index = dateItemsMap.get(dateValue)
  //   if (index !== undefined && index !== currentDateValue.current) {
  //     currentDateValue.current = index
  //         dateRefs.current[index]?.scrollIntoView({
  //           block: 'center',
  //           behavior: 'smooth',
  //         }),
  //     rerenderDateElements(
  //       currentDateValue.current,
  //       dateItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
  //       0,
  //       dateItems.length
  //     )
  //   }
  // }, [dateValue, dateItemsMap, dateItemsContRef.current])

  useEffect(() => {
    const index = hourItemsMap.get(hourValue)
    if (index !== undefined && index !== currentHourValue.current) {
      currentHourValue.current = index
      hourRefs.current[index]?.scrollIntoView({
        block: 'center',
        // behavior: 'smooth',
      }),
        rerenderHourElements(
          currentHourValue.current,
          hourItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
          0,
          hourItems.length
        )
    }
  }, [hourValue, hourItemsMap, hourItemsContRef.current])

  useEffect(() => {
    const index = minuteItemsMap.get(minuteValue)
    if (index !== undefined && index !== currentMinuteValue.current) {
      currentMinuteValue.current = index
      minuteRefs.current[index]?.scrollIntoView({
        block: 'center',
        // behavior: 'smooth',
      }),
        rerenderMinuteElements(
          currentMinuteValue.current,
          minuteItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
          0,
          minuteItems.length
        )
    }
  }, [minuteValue, minuteItemsMap, minuteItemsContRef.current])

  useEffect(() => {
    const index = ampmItemsMap.get(ampmValue)
    if (index !== undefined && index !== currentAmpmValue.current) {
      currentAmpmValue.current = index
      ampmRefs.current[index]?.scrollIntoView({
        block: 'center',
        // behavior: 'smooth',
      }),
        rerenderAmpmElements(
          currentAmpmValue.current,
          ampmItemsContRef.current?.scrollTop ?? 0, // Ensure scrollTop is not undefined
          0,
          ampmItems.length
        )
    }
  }, [ampmValue, ampmItemsMap, ampmItemsContRef.current])

  return (
    <div
      className="container"
      style={{
        height: `${containerHeight}px`,
      }}
    >
      {/* <ul className="items" ref={dateItemsContRef}>
        {dateItems.map((item, index) => (
          <li
            className="item"
            key={item.value}
            ref={(node) => (dateRefs.current[index] = node as HTMLLIElement)}
            style={{
              height: `${itemHeight}px`,
              lineHeight: `${itemHeight}px`,
            }}
          >
            <div>{item.label}</div>
          </li>
        ))}
      </ul> */}
      <ul className="items" ref={hourItemsContRef}>
        {hourItems.map((item, index) => (
          <li
            className="item"
            key={item.value}
            ref={(node) => (hourRefs.current[index] = node as HTMLLIElement)}
            style={{
              height: `${itemHeight}px`,
              lineHeight: `${itemHeight}px`,
            }}
          >
            <div>{item.label}</div>
          </li>
        ))}
      </ul>
      <ul className="items" ref={minuteItemsContRef}>
        {minuteItems.map((item, index) => (
          <li
            className="item"
            key={item.value}
            ref={(node) => (minuteRefs.current[index] = node as HTMLLIElement)}
            style={{
              height: `${itemHeight}px`,
              lineHeight: `${itemHeight}px`,
            }}
          >
            <div>{item.label}</div>
          </li>
        ))}
      </ul>
      <ul className="items" ref={ampmItemsContRef}>
        {ampmItems.map((item, index) => (
          <li
            className="item"
            key={item.value}
            ref={(node) => (ampmRefs.current[index] = node as HTMLLIElement)}
            style={{
              height: `${itemHeight}px`,
              lineHeight: `${itemHeight}px`,
            }}
          >
            <div>{item.label}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WheelPicker
