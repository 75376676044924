import { useLocales } from '../../locales'
import { DashboardSection } from '../Common/DashboardSection'
import React, { useState, useEffect, useRef } from 'react'

interface CarouselItemProps {
  reminder: string
  index: number
  isActive: boolean
}

const CarouselItem: React.FC<CarouselItemProps> = ({ reminder, index, isActive }) => (
  <div data-carousel-item={isActive ? 'active' : null} className={`duration-700 ease-in-out ${isActive ? 'block' : 'hidden'} h-full w-full space-y-1 rounded-[15px] bg-[#F9F7F7] p-3 leading-5`}>
    <div>
      <div className="flex justify-between">
        <h4 className="text-[18px] font-semibold"> {index} November 1 | 9:15 AM</h4>
        <div className="h-[18px] w-[4px] cursor-pointer bg-[url('assets/images/icon/i-dots_vertical.svg')] bg-[length:auto] bg-no-repeat" />
      </div>
      <p className="text-[14px] font-normal">Wednesday</p>
    </div>

    <p className="text-[14px] font-normal">Dr. Lorianna Fletcher (Pallai), MD | Cardiologist</p>

    <span className="text-[13px] font-normal text-[#666666]">
      French Hospital Medical Center, 1941 Johnson Ave, San Luis Obispo, CA, 93401.
      <strong className="font-normal text-[#EF2E7D]">Get directions</strong>
    </span>

    <div className="flex space-x-2 font-normal">
      <p className="text-[14px]">Reminder</p>

      <label className="relative inline-flex cursor-pointer items-center">
        <input type="checkbox" value="" defaultChecked={true} className="peer sr-only" />
        <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#FF3399] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
      </label>
    </div>
  </div>
)

export const MyReminders = ({ jumpTo }: any) => {
  const { t } = useLocales()

  const carouselItems = ['1', '2', '3', '4']

  const [currentIndex, setCurrentIndex] = useState(0)
  const touchStartX = useRef<number | null>(null)
  const hasSwiped = useRef(false)

  const handleTouchStart = (event: React.TouchEvent) => {
    touchStartX.current = event.touches[0].clientX
    hasSwiped.current = false
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    if (touchStartX.current !== null && !hasSwiped.current) {
      const touchMoveX = event.touches[0].clientX
      const deltaX = touchMoveX - touchStartX.current

      if (deltaX > 50) {
        handlePrevClick()
        hasSwiped.current = true
      } else if (deltaX < -50) {
        handleNextClick()
        hasSwiped.current = true
      }
    }
  }

  const handleTouchEnd = () => {
    touchStartX.current = null
  }

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1))
  }

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1))
  }

  const handleOptionClick = (index: number) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePrevClick()
      } else if (event.key === 'ArrowRight') {
        handleNextClick()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextClick()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [currentIndex])

  return (
    <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myReminders')}>
      <div id="indicators-carousel" className="relative w-full" data-carousel="static">
        <div
          className="relative overflow-hidden rounded-lg "
          // className="relative w-full"
          id="indicators-carousel"
          data-carousel="static"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {carouselItems.map((reminder, index) => (
            <CarouselItem key={index} reminder={reminder} index={index} isActive={index === currentIndex} />
          ))}
        </div>

        {/* <button type="button" onClick={handlePrevClick} className="start-0 group absolute top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none" data-carousel-prev>
          <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70">
            <svg className="h-4 w-4 text-white rtl:rotate-180 dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button type="button" onClick={handleNextClick} className="end-0 group absolute top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none" data-carousel-next>
          <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70">
            <svg className="h-4 w-4 text-white rtl:rotate-180 dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button> */}
      </div>

      <div className="-translate-x-1/5 bottom-5 left-1/2 z-30 flex justify-center space-x-3 rtl:space-x-reverse">
        {carouselItems.map((_, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(index)}
            type="button"
            className={`h-3 w-3 rounded-full ${index === currentIndex ? 'bg-[#FF3399]' : 'bg-[#D9D9D9]'}`}
            aria-label={`Slide ${index + 1}`}
            data-carousel-slide-to={index}
          ></button>
        ))}
      </div>

      <div className="h-full w-full rounded-[15px] bg-[#F8DFE9] p-3">
        <div className="flex justify-between">
          <div className="text-[18px] font-semibold leading-5 text-[#D42128]">
            <h4 className="uppercase">Missed Appointment</h4>
            <h4>October 31 | 6:15 AM</h4>
          </div>
          <div className="h-[18px] w-[4px] cursor-pointer bg-[url('assets/images/icon/i-dots_vertical.svg')] bg-[length:auto] bg-no-repeat" />
        </div>
        <p className="text-[14px] font-normal text-[#D42128]">Wednesday</p>
        <p className="text-[14px] font-normal leading-[10px]">Dr. Michael Steel, MD | GP</p>
        <p className="text-[12px] text-[#666666]">Telehealth link: hdjhsjchdsjchjcsjhcj</p>
        <div className="flex space-x-2">
          <p className="text-[14px]">Reminder</p>

          <label className="relative inline-flex cursor-pointer items-center">
            <input type="checkbox" value="" className="peer sr-only" />
            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#FF3399] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
          </label>
        </div>
      </div>

      <div className="flex justify-end">
        <a href="#edit-my-now" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
          {t('extraText.edit/changeAppointments')}
        </a>
      </div>

      <div className="flex h-full w-full items-center justify-center space-x-2 rounded-[50px] bg-[#FF3399] px-5 py-[5px]">
        <div className="h-[38px] w-[48px] cursor-pointer bg-[url('assets/images/icon/i-remind.svg')] bg-[length:auto] bg-no-repeat" />
        <p className="text-center text-[12px] uppercase leading-none text-color-nav">YOUR BLOODWORK IS DUE IN 30 DAYS. SCHEDULE NOW.</p>
      </div>
    </DashboardSection>
  )
}
