import { useCallback, useMemo, useState } from 'react'

import { ScreenCoreConceptsQuestion } from './CoreConceptsQuestion'
import { Session04Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { dataCoreConcepts, sesstion04Questions } from '../../../../../lib/data/session-04'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
  answers?: any
}

const ssCC07Questions = [sesstion04Questions[7], sesstion04Questions[8]]
const ssCC10Questions = [sesstion04Questions[9]]

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers?: any
  isButtonBack: boolean
}

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? 16 : step.sub_step)
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataCoreConcepts[isButtonBack ? 16 : step.sub_step])

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const numScreens = dataCoreConcepts.length

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConcepts[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: number) => {
      params && onUpdate(params)
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    if (screen < numScreens) {
      switch (screen) {
        case 7:
        case 10:
          return (
            <ScreenCoreConceptsQuestion
              contentScreen={contentScreen}
              answers={answers}
              questions={screen === 7 ? ssCC07Questions : ssCC10Questions}
              showModal={showModal}
              backScreen={() => handleBackScreen(screen)}
              onUpdate={(params: object) => handleUpdate(params, screen)}
            />
          )
        default:
          return (
            <Session04Wrapper buttons={buttons}>
              <div className="session-style">
                {contentScreen.title && <h4>{contentScreen.title}</h4>}

                {contentScreen.img?.includes('.mp4') ? (
                  <video className="block h-auto w-full" autoPlay muted controls>
                    <source src={contentScreen.img} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={contentScreen.img} alt="" />
                )}

                <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
              </div>
            </Session04Wrapper>
          )
      }
    }
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal])

  return <>{screenCoreConcepts}</>
}
