import { useCallback, useMemo } from 'react'

import { ProgressScreenType, ProgressScreenStatus } from '../../../../models/session'

type Props = {
  progressItem: ProgressScreenType
  toScreen: () => void
}

export const ProgressItem = ({ progressItem, toScreen }: Props) => {
  const isDone = progressItem.status === ProgressScreenStatus.Done

  const handleToScreen = useCallback(() => {
    if (!isDone) return

    toScreen()
  }, [isDone])

  const iconStatus = useMemo(() => {
    const isLock = progressItem.status === ProgressScreenStatus.Lock

    return isLock ? (
      <div className="relative z-[4] h-[42px] w-[42px] rounded-full bg-ma-gray-300" onClick={handleToScreen} />
    ) : (
      <div className="relative z-[4] h-[42px] w-[42px] rounded-full bg-ma" onClick={handleToScreen}>
        <div className="h-full w-full bg-[url('assets/images/icon/i-check__white.svg')] bg-[length:auto_17px] bg-[left_10px_center] bg-no-repeat" />
      </div>
    )
  }, [progressItem.status])

  return (
    <div
      className={`relative z-[4] flex h-[129px] space-x-4 before:absolute before:left-[21px] before:top-0 before:z-[2] before:h-full before:w-[1px] last:h-auto last:before:hidden ${
        isDone ? 'before:bg-ma' : ''
      }`}
    >
      {iconStatus}

      <div className="flex-1">
        <div className="text-ma-lg font-semibold leading-[30px] tracking-[0.21px]" onClick={handleToScreen}>
          {progressItem.title}
        </div>
        <div className="text-ma-md font-light leading-none" onClick={handleToScreen}>
          {progressItem.time} min
        </div>
      </div>
    </div>
  )
}
