import { useState, useCallback, useMemo } from 'react'
import { ScreenHeartAssessment01 } from './HeartAssessment01'
import { ScreenHeartAssessment02 } from './HeartAssessment02'
import { SessionButtons } from '../../layout'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  step: Step
  isButtonBack: boolean
}

enum Screen {
  HeartAssessment01,
  HeartAssessment02,
}

export const ScreenHeartAssessment = ({ showModal, nextScreen, backScreen, step, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.HeartAssessment02 : step.sub_step)

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 1, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.HeartAssessment01) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.HeartAssessment02) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenHeartAssessment = useMemo(() => {
    switch (screen) {
      case 0:
        return <ScreenHeartAssessment01 buttons={buttons} />
      case 1:
        return <ScreenHeartAssessment02 buttons={buttons} />
    }
  }, [screen, buttons])

  return <>{screenHeartAssessment}</>
}
