import { useState, useCallback, useMemo, useEffect } from 'react'
import { Session08Wrapper } from '../../session-08/Wrapper'
import { SessionButtons } from '../../layout'

import { FormField, ErrorMessage } from '../../../../Common'

import { useForm } from '../../../../../hooks/use-form'
import { Question, CoreConceptsStops } from '../../../../../models'

type Props = {
  contentScreen: CoreConceptsStops
  questions: Question[]
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers?: any
}

export const ScreenCoreConceptsQuestion = ({ contentScreen, questions, showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    setIsError(false)
  }, [contentScreen])

  const initForm = questions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const tableGuide = useMemo(() => {
    switch (contentScreen.id) {
      case 1:
        return (
          <div className="mt-8">
            <div className="grid h-14 w-full  grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[14px] font-semibold leading-[23.15px]">
              <div className="flex h-full items-center justify-center px-2 text-start"></div>
              <div className="flex h-full items-center justify-start px-2 text-start">Cholesterol</div>
              <div className="flex h-full items-center justify-start px-2 text-start">HDL</div>
              <div className="flex h-full items-center justify-start px-2 text-start">LDL</div>
            </div>
            <div className="mt-1 grid h-14 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#ED2580]">Desirable (Less than 200 overall)</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Lower than 100</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Lower than 150</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Higher than 60</div>
            </div>
            <div className="mt-1 grid h-14 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#974A9A]">Moderate (200 -239 overall)</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">100 - 159</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">150 - 199</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">50 - 59</div>
            </div>
            <div className="mt-1 grid h-14 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#F26E57]">High (240 and higher)</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">Higher than 240</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">Higher than 200</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">Lower than 50</div>
            </div>
          </div>
        )
      case 2:
        return (
          <div className="mt-8">
            <div className="grid h-14 w-full grid-cols-custom-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[12px]">
              <div className="flex h-full items-center justify-start px-2 text-start">Blood Pressure Category</div>
              <div className="flex h-full items-center justify-start px-2 text-start">Systolic mm Hg (upper number)</div>
              <div className="flex h-full items-center justify-start px-2 text-start"></div>
              <div className="flex h-full items-center justify-start px-2 text-start">Diastolic mm Hg (lower number)</div>
            </div>
            <div className="mt-1 grid h-14 w-full grid-cols-custom-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#ED2580]">Normal</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Less than 120</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">and</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Less than 80</div>
            </div>
            <div className="mt-1 grid h-14 w-full grid-cols-custom-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#974A9A]">Elevated</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">120-129</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">and</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">Less than 80</div>
            </div>
            <div className="mt-1 grid h-[87px] w-full grid-cols-custom-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#F26E57]">High Blood Pressure (Hypertension) Stage 1</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">130 - 139</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">or</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">80 - 89</div>
            </div>
            <div className="mt-1 grid h-[87px] w-full grid-cols-custom-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#B72E5F]">High Blood Pressure (Hypertension) Stage 2</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#B72E5F]">140 or higher</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#B72E5F]">or</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#B72E5F]">90 or higher</div>
            </div>
            <div className="mt-1 grid h-[87px] w-full grid-cols-custom-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-start px-2 text-start font-semibold text-[#6D4372]">Hypertensive Crisis (consult your doctor immediately)</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#6D4372]">Higher than 180</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#6D4372]">and/or</div>
              <div className="flex h-full items-center justify-start px-2 text-start font-medium text-[#6D4372]">Higher than 120</div>
            </div>
          </div>
        )

      case 3:
        return (
          <div>
            <div className="mt-8">
              <div className="grid h-10 w-full  grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[12px]">
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-center font-semibold">Fasting Glucose</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#ED2580]">{`<100`}</div>
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start font-medium  text-[#ED2580]">Normal</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#974A9A]">{`> 100`}</div>
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">High</div>
              </div>
            </div>
            <div className="mt-8">
              <div className="grid h-10 w-full  grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] font-semibold leading-[17px]">
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-center font-semibold">HbA1C</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#ED2580]">{`<5.7`}</div>
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Normal</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#F26E57]">{`5.7 - 6.4`}</div>
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">Prediabetic</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-4 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#974A9A]">{`> 6.4`}</div>
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start text-[12.19px] font-medium text-[#974A9A]">Diabetic</div>
              </div>
            </div>
          </div>
        )

      case 4:
        return (
          <div className="mt-8">
            <div className="grid h-10 w-full  grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[12px]">
              <div className="col-span-3 flex h-full items-center justify-start px-2 text-center">C-Reactive Protein</div>
            </div>
            <div className="mt-1 grid h-10 w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#ED2580]">{`<1mg/dL`}</div>
              <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Normal</div>
            </div>
            <div className="mt-1 grid h-[102px] w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#F26E57]">{`> 1.0 - 10 mg/dL`}</div>
              <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">
                Moderate elevation- Indicates body-wide inflammation. An hs-CRP result above 3.0 mg/L puts you at high risk for a heart attack or stroke.
              </div>
            </div>
            <div className="mt-1 grid h-[52px] w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
              <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#974A9A]">{`> 6.4`}</div>
              <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">High elevation - Major risk of a heart attack or stroke.</div>
            </div>
          </div>
        )
      case 5:
        return (
          <div>
            <div className="mt-8">
              <div className="grid h-10 w-full  grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[12px]">
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start">Apoliprotein B</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#ED2580]">{`<100 mg/dL`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Normal</div>
              </div>
              <div className="mt-1 grid h-14 w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#F26E57]">{`>100 mg/dL`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">High elevation - Major risk of a heart attack or stroke.</div>
              </div>
            </div>
            <div className="mt-8">
              <div className="grid h-10 w-full  grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[12px]">
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start ">Lp(a)</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#ED2580]">{`<30 mg/dL`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">Normal</div>
              </div>
              <div className="mt-1 grid h-14 w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#F26E57]">{`>30mg/dL - 49mg/dL`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">Moderate elevation</div>
              </div>
              <div className="mt-1 grid h-10 w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#974A9A]">{`>50mg/dL`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#974A9A]">High elevation</div>
              </div>
            </div>
            <div className="mt-8">
              <div className="grid h-10 w-full  grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[12px]">
                <div className="col-span-3 flex h-full items-center justify-start px-2 text-start ">ApoE</div>
              </div>
              <div className="mt-1 grid h-[100px] w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#ED2580]">{`E3/4 or 4/4`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#ED2580]">
                  The body cannot package the fats well, causing them to build up in the lining of the artery, leading to coronary artery disease or Alzheimer’s.
                </div>
              </div>
              <div className="mt-1 grid h-[71px] w-full grid-cols-3 divide-x divide-dotted divide-gray-500 rounded-[14px] bg-[#F0F0F0] py-1 text-[12.19px] leading-[14.62px]">
                <div className="flex h-full items-center justify-center px-2 text-center font-semibold text-[#F26E57]">{`E2/3`}</div>
                <div className="col-span-2 flex h-full items-center justify-start px-2 text-start font-medium text-[#F26E57]">
                  A 2/3 type has difficulty processing sugars leading to obesity and diabetes.
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return <div className="mt-8"></div>
    }
  }, [contentScreen])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenCoreConceptsQuestion = useMemo(() => {
    return (
      <Session08Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            {contentScreen.title && <h4>{contentScreen.title}</h4>}

            {contentScreen.img && <img src={contentScreen.img} alt="" />}

            <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />

            {questions.map((item) => {
              return (
                <FormField
                  after={item.after || undefined}
                  key={`ss05_${item.name}`}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {tableGuide}
          {buttons}
        </form>
      </Session08Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenCoreConceptsQuestion}</>
}
