import { ChangeEvent, useMemo, useState, useCallback, useEffect } from 'react'

import { RadioField } from './Radio'

import { InputField } from './Input'
import { CheckboxField } from './Checkbox'
import { RateField } from './Rate'

import { Question, QuestionTypes, FieldTypes } from '../../../models/'
import { CheckboxMultiField } from './CheckboxMulti'
import { TableQuestion } from './TableQuestion'

type Props = {
  editable: boolean
  backgroundColor?: string
  textColor?: string
  children?: React.ReactNode
  value?: string | string[]
  question: Question
  fieldType?: FieldTypes
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onUpdateHiddenField: Function
}

export const HeartActionField = ({ editable, children, value, backgroundColor, textColor, question, fieldType = FieldTypes.Dark, onChange, onUpdateHiddenField }: Props) => {
  let isHidden: boolean = [QuestionTypes.InputHidden, QuestionTypes.RadioHidden, QuestionTypes.RadioDropdown].includes(question.type)
  const isDarkType: boolean = fieldType === FieldTypes.Dark
  const hasParent: boolean = !!question.parent
  const [isShowField, setIsShowField] = useState<boolean>(!isHidden)
  const handleShowInput = useCallback((statusCurrent: boolean) => {
    if (isHidden && editable) {
      setIsShowField(!statusCurrent)
    }
  }, [])

  const input = useMemo(() => {
    switch (question.type) {
      case QuestionTypes.Radio:
      case QuestionTypes.RadioHidden:
      case QuestionTypes.RadioDropdown:
        return (
          <RadioField
            question={question}
            disabled={!editable}
            backgroundColor={backgroundColor}
            textColor={textColor}
            value={typeof value === 'string' ? value : undefined}
            fieldType={fieldType}
            onChange={onChange}
          />
        )

      case QuestionTypes.Input:
      case QuestionTypes.InputHidden:
        if (question.value) {
          setIsShowField(true)
        }
        return <InputField question={question} inputType={question.inputType} disabled={!editable} backgroundColor={backgroundColor + '33'} textColor={textColor} value={value} onChange={onChange} />

      case QuestionTypes.Checkbox:
        return <CheckboxField question={question} disabled={!editable} backgroundColor={backgroundColor} textColor={textColor} value={value} fieldType={fieldType} onChange={onChange} />
      case QuestionTypes.CheckboxMulti:
        return <CheckboxMultiField question={question} disabled={!editable} backgroundColor={backgroundColor} textColor={textColor} value={value} fieldType={fieldType} onChange={onChange} />

      case QuestionTypes.Rate:
        return (
          <RateField
            question={question}
            disabled={!editable}
            backgroundColor={backgroundColor}
            textColor={textColor}
            value={typeof value === 'string' ? parseInt(value) : undefined}
            onChange={onUpdateHiddenField}
          />
        )
      case QuestionTypes.TableQuestion:
        return <TableQuestion question={question} disabled={!editable} backgroundColor={backgroundColor} textColor={textColor} value={value} fieldType={fieldType} onChange={onChange} />
        return

      default:
        break
    }
  }, [fieldType, value, question])

  const inputField = useMemo(() => {
    const iconCheckbox = isShowField ? `bg-[url('assets/images/icon/i-checkbox__checked.svg')]` : `bg-[url('assets/images/icon/i-checkbox__uncheck.svg')]`
    const checkboxStyle = isHidden ? `cursor-pointer bg-[top_left_-3px] bg-no-repeat bg-[length:24px_auto] pl-6 pt-[2px] ${iconCheckbox}` : ''

    const iconArrowRotate = isShowField ? 'before:rotate-90' : ''
    const dropdownStyle = isHidden
      ? `cursor-pointer rounded border border-[#D4D4D4] pl-3 leading-[30px] shadow-[0px_8px_15px_rgba(0,0,0,0.2)] before:absolute before:top-0 before:right-0 before:h-[30px] before:w-[30px] before:bg-[url('assets/images/icon/i-arrow.svg')] before:bg-[length:auto_17px] before:bg-center before:bg-no-repeat before:transition-transform ${iconArrowRotate}`
      : ''

    const iconStyle = question.type === QuestionTypes.RadioDropdown ? dropdownStyle : checkboxStyle
    const colorStyle = isDarkType ? (question.parent ? 'text-black' : 'text-ma-pink-300') : 'text-white'
    return (
      <>
        {question.question && !question.hideLabel && (
          <h4
            className={`relative text-ma-md font-semibold ${iconStyle} ${colorStyle}`}
            style={{
              color: backgroundColor ?? 'text-ma-pink-300',
            }}
            onClick={() => handleShowInput(isShowField)}
          >
            {question.question}
          </h4>
        )}

        {isShowField ? input : null}
      </>
    )
  }, [isDarkType, isHidden, isShowField, question, input, handleShowInput])

  const formField = useMemo(() => {
    const hasTitle = !!question.question
    return (
      <div className={`space-y-4 ${isDarkType ? 'text-white' : 'text-black'} ${hasTitle ? 'pt-3' : ''}`}>
        {inputField}
        {children}
      </div>
    )
  }, [hasParent, inputField, question, children])

  return <>{formField}</>
}
