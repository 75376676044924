import { useCallback } from 'react'
import { getUserSetting } from '../lib/request/get-user-setting'
import { postUpdateSettingNoti } from '../lib/request/post-update-setting-noti'
import { postUpdateSettingUser } from '../lib/request/post-update-setting-user'
import { AdessoError } from '../lib/error'
import { SettingUser, SettingNotification } from '../../src/models'
import { AxiosError } from 'axios'

export function useSetting() {
  const getSetting = useCallback(async () => {
    try {
      return await getUserSetting()
    } catch (error) {
      throw new AdessoError('Get setting Failed')
    }
  }, [])

  const updateUserSetting = useCallback(async (params: SettingUser) => {
    try {
      return await postUpdateSettingUser(params)
    } catch (error) {
      throw new AdessoError('Update setting user Failed')
    }
  }, [])

  const updateNotiSetting = useCallback(async (params: SettingNotification) => {
    try {
      return await postUpdateSettingNoti(params)
    } catch (error) {
      throw new AdessoError('Update setting not Failed')
    }
  }, [])

  const throwError = useCallback((error: AdessoError | AxiosError | any, errorMessage: string = ''): AdessoError => {
    if (error instanceof AdessoError) return error

    if (!(error instanceof AxiosError)) {
      return new AdessoError(errorMessage)
    }

    const data = error?.response && error.response.data
    return new AdessoError(`${data.message} ${data.data}`)
  }, [])

  return { getSetting, updateUserSetting, updateNotiSetting, throwError }
}
