import { useMemo } from 'react'
import { Session10Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

const postCard = `
  Hello, it's Dr. Suzanne. Guess what? This is the last step in your My Adesso initial learning journey. Congrats! Congratulations on making it this far. You know, it's really unbelievable reaching this goal. Think of all the women out there who never went on this journey. The people that are out there struggling with symptoms day to day, without the knowledge that they can do things today to improve their condition, live a life of vitality, and overall wellness. We created this experience because it is time we change the women and heart disease story. Women have been misdiagnosed, undertreated, and dismissed when it comes to their hearts, and when it comes to preventing heart disease. This is all about changing lives one woman at a time, and we are grateful that you are now part of this mission with us. The truth of Adesso is what it stands for. We talk about your now. Well, now is the time for a lot of things. This whole journey we've been talking about mindset shifts. This may be the biggest one of all, you, yes, you now can have the life changing knowledge and practices that can bring health to your heart and vibrancy to your life. Your life is changing and improving mostly because of your commitment. And this is beautiful and inspiring to us. So now you are the expert my friend.
`

export const ScreenHeartToHeart = ({ buttons }: Props) => {
  const screenHeartToHeart = useMemo(() => {
    return (
      <Session10Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>Let’s take a moment to celebrate your success.</p>

          <audio controls className="w-full">
            <source src="https://api.myadesso.health/uploads/journeys/episode-10.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div className="h-full max-h-[calc(100vh-490px)] w-full flex-1 overflow-y-scroll">
            <p className=" text-[18px] leading-[22px]" dangerouslySetInnerHTML={{ __html: postCard }} />
          </div>
        </div>
      </Session10Wrapper>
    )
  }, [buttons])

  return <>{screenHeartToHeart}</>
}
