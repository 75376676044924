import { Question, QuestionTypes } from '../../models/question'
import { mapQuestion } from './question'

const frequencyValues = ['Daily', 'Weekly Digest', 'Monthly Digest']

export const subscriptionQuestions: Question[] = [
  // mapQuestion('text_frequency', QuestionTypes.RadioHidden, 'TEXT', undefined, undefined, false),
  // mapQuestion('email_frequency', QuestionTypes.RadioHidden, 'EMAIL', undefined, undefined, false),
  mapQuestion('text_frequency', QuestionTypes.CheckboxMulti, undefined, undefined, ['text'], false),
  mapQuestion('email_frequency', QuestionTypes.CheckboxMulti, undefined, undefined, ['email'], false),
]
