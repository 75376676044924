import { mapDataHeartRickFactors, mapDataModalProgress } from '../maps/session'
import { mapQuestion, mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { HeartRickFactorsType, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

const heartRickFactors01 = `
<div>
  <h4>Basic Factors</h4>
  <p>Your cholesterol, blood pressure and blood sugar all have tremendous impacts on your heart.</p>
</div>

<div>
  <h4>Cholesterol</h4>
  <p>Bad Cholesterol (LDL) is a number we want to keep LOW. It creates tears in the lining of your heart that increase plaque and the chance of heart disease. Good Cholesterol (HDL) keeps your heart protected and safe. Triglycerides are fat found in your blood. They’re important to keep an eye on too, because they harden the arteries of the heart, increasing the chances of a stroke, heart attack and other heart diseases.</p>
</div>

<div>
  <h4>Blood Pressure</h4>
  <p>65 Million Americans have high blood pressure and they call it the silent killer because 30% of those people don’t even know they have it! We watch the systolic number (high number) because it tells us the pressure against the arteries when the blood is pumping. We watch the diastolic number (lower number) because it tells us how the heart is pumping when the heart is at rest. If the heart is having to work harder to pump blood to your body, that might mean that the lining of the heart is getting stiff. And this leads to heart disease.</p>
</div>

<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_003.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_003.png" alt="">
</picture>

<div>
  <h4>Blood Sugar</h4>
  <p> Imagine the inside of a watch. Now imagine pouring honey all over it.  Blood sugar influences how efficiently the heart is able to pump blood to your body. High blood sugar leads to diabetes and many other heart health complications. </p>
</div>

<div>
  <h4>What to do:</h4>
  <ul>
    <li>Get tested for these numbers!</li>
    <li>If you’re numbers are not within a healthy range, discuss your options with your doctor.</li>
    <li>This learning journey and future sessions are designed to help improve all three of these risk factors through steps and changes that you can make.</li>
  </ul>
</div>`

const heartRickFactors02 = `
<h4>Family History</h4>
<p>Family history can play a major factor in your heart health. Look at your family. Is there a history of heart disease?</p>
<p>Heart disease can be genetic, passed down between generations. When you learn and pay attention to this history, you know how to best care for your own heart. Does your family have a history of heart attack, stroke, irregular heart beats, aneuryms or sudden cardiac death?</p>


<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_004.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_004.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>If any of your family members died early, find out why. </li>
  <li>Draw a family genealogy and map how they died. Ask other family members if you don’t know.</li>
  <li>Remember, stay positive even when dealing with something like genetics, which is out of your control. Information can motivate you to change what is within your control and when necessary get medical advice.</li>
  <li>Keep on the Journey! Later lessons will help you know where you are and what you can do despite genetic concerns.</li>
</ul>`

const heartRickFactors03 = `
<h4>Diet</h4>
<p>Diet plays a major role in your heart health and you will learn the ins and outs of this in the Nourish Section coming up soon...</p>
<p>Cholesterol is an important factor in diet because good cholesterol (HDL) helps your heart and bad cholesterol (LDL) damages it.</p>
<p>Eating a lot of processed food high in saturated fats, will surely increase your risk. Eating healthy fresh fruits and vegetables can lower your risk.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_005.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_005.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>When is the best time to eat better… Adesso! Start today by replacing one sugary treat with a healthy one, like change your morning specialty drink to something simple and sugar free. </li>
  <li>Learn more about the Mediterranean Diet, which is high in fruits and vegetables, whole grains, nuts, olive oil, and lots of other heart healthy foods.</li>
  <li>Make an intentional plan to reduce salt, which can spike blood pressure. The vast majority of salt comes from processed foods, so replace one processed meal with one fresh one. It’s a start you can do… Now!</li>
</ul>`

const heartRickFactors04 = `
<h4>Exercise</h4>
<p>How much you move and exercise can greatly contribute to your heart risk. Someone who is physically in shape has a heart that doesn’t have to work as hard to perform an activity. When they run, their heart rate may go up, but slowly and to the optimum level to deliver oxygen for the amount of work that the body is performing.</p>
<p>Someone who is sedentary may have a heart that has to labor dramatically just to get up the stairs. A study looked at over 200,000 people for three years and the result was clear: The more you sit, and the less you exercise, the more you are at risk.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_006.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_006.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>Use (or buy) a step tracker and start getting 10,000 steps every day. </li>
  <li>Stand up every hour for at least 2 minutes. It’s actually funny when you jump in a meeting and do jumping jacks! Smiles help your heart, too.</li>
  <li>Set a new workout plan and commit to it and you’ll do this much more in the Move Session coming soon. </li>
</ul>`

const heartRickFactors05 = `
<h4>Weight</h4>
<p>As women, many factors can influence weight gain from sedentary lifestyles to pregnancy, menopause, and other hormonal effects.  </p>
<p>Your weight usually has a direct connection to your cholesterol levels, which have a major effect on your heart. </p>
<p>We are not talking about weight as being the ideal that we see in magazines and movies. It is not how you look. It is how you feel and the impact that weight can have on your heart health.</p>
<p>A study found that women who sustained loss of 10 percent of their body weight for two years reduced their cholesterol, sugars, and blood pressure, which have a major effect on your heart.</p>


<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_007.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_007.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>Breakout (or buy) your scale. Weigh yourself every day. Remember, information is power! </li>
  <li>Take 10 seconds before eating anything sugary. Pause. Ask yourself if you can do without it. Celebrate the times you say yes and dance a little.</li>
  <li>Use the journaling feature at the end of this session to track your meals and journal about your positive changes.</li>
</ul>`

const heartRickFactors06 = `
<h4>Sleep</h4>
<p>Our parents were right! Bedtimes are needed (and maybe naps, too!). Your heart risk goes way up if you get less than seven hours of sleep or more than nine hours of sleep per night.</p>
<p>Sleep is very important for rejuvenation. It also helps decrease stress and anxiety levels, which have major effects on your heart’s health. </p>
<p>Sleep helps repair and replenish your nervous system. Without that you are likely to suffer from an excess of stress hormones.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_008.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_008.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>Try to get 7 or 8 hours of sleep tonight. It will replenish your heart and do you a world of good. Journal to track how much you sleep.</li>
  <li>At the end of this journey, you can Go Deeper with our micro-session on Sleep. You’ll learn more about the importance of sleep and practices that will help you better your sleep habits.</li>
  <li>Meditate for 10 minutes before bed to calm your stress and improve your sleep.</li>
</ul>`

const heartRickFactors07 = `
<h4>Drinking</h4>
<p>OK, we’ve all read (happily for many probably) that a little bit of alcohol can be good for your heart. Most things in moderation are. The problem is a “little bit” is often far less than we consume.</p>
<p>Too much alcohol can cause heart palpitations, raise blood pressure, and damage your liver as well as your heart.</p>
<p>Especially when consumed at night, it metabolizes as sugar and can lead to rapid weight gain.</p>
<p>It’s ok to have a small glass of wine or 2 ounces of hard liquor,  but if you can’t keep drinking to a limit, it’s better to not drink at all.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_009.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_009.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>Determine what a healthy drinking habit looks like for you, whether it’s drinking in moderation or not drinking at all. Journal about it to ensure you are staying on track</li>
  <li>Learn more about drinking and the research about its interaction with the heart in the “Alcohol” microsession in our Go Deeper library at the end of this section. </li>
  <li>Try a 30-day “fast” and give up drinking. Journal about how you feel, how your skin looks, how your weight is impacted. It could become a lifestyle or help you drink less when you are finished.</li>
</ul>`

const heartRickFactors08 = `
<h4>Smoking</h4>
<p>We all know that smoking is bad for your health, but most people don’t consider its impact on your heart. </p>
<p>Cigarettes have a very direct impact on the lining of the arteries (the endothelium). When you smoke, you are flooding your body with chemicals that eventually cause cuts and tears in the lining of the heart. </p>
<p>The chemicals in cigarette smoke can also cause plaque ruptures, which can lead to a sudden heart attack. </p>
<p>People who smoke have lower levels of HDL (the good cholesterol). Even if you just smoke one to three cigarettes a day, you are at greater risk for a heart attack, stroke, cancer, and other heart diseases.</p>
<p>And if you smoke a pack a day and are on birth control pills your risk goes up thirty-fold. </p>
<p>The minute you stop smoking is the minute your body starts fixing itself. There’s no world in which smoking is good for your heart.</p>


<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_010.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_010.png" alt="">
</picture>
<h4>What to do:</h4>
<ul>
  <li>Eliminate “recreational” smoking, like when at a party. </li>
  <li>Set a goal to stop smoking and take critical steps (buy the patch, start a program, etc.).</li>
  <li>Share your determination to stop smoking loud and proud–announce on social media and tell people so you’ll feel accountable.</li>
  <li>Learn more about the effect of smoking on the heart in our “Smoking” micro-session in the Go Deeper Library.</li>
</ul>`

const heartRickFactors09 = `
<h4>Stress</h4>
<p>Stress affects everything and it definitely affects the heart. Nothing in your body works well when you are stressed.</p>
<p>Stress releases high levels of stress hormones that cause inflammation in the body. This leads to high blood pressure, obesity, and many other risk factors.</p>
<p>Stress is pervasive, but is also one you can take steps to improve. You’ll learn much more in the Refresh Session coming up soon, but, you know our motto by now, there is so much you can do Adesso! Now!</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/01/img_011.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/01/img_011.png" alt="">
</picture>

<h4>What to do:</h4>
<ul>
  <li>Put at least 5 minutes a day into your calendar next week for meditation.</li>
  <li>Schedule time unplugged or away from all work actions. Leave your phone behind and get outside.</li>
  <li>If stress is really getting to you, talk to a friend or a counselor to get support.</li>
</ul>`

export const dummyDataHeartRickFactors: HeartRickFactorsType[] = [
  mapDataHeartRickFactors(1, 'Basic Factors', heartRickFactors01),
  mapDataHeartRickFactors(2, 'Family History', heartRickFactors02),
  mapDataHeartRickFactors(3, 'Diet', heartRickFactors03),
  mapDataHeartRickFactors(4, 'Exercise', heartRickFactors04),
  mapDataHeartRickFactors(5, 'Weight', heartRickFactors05),
  mapDataHeartRickFactors(6, 'Sleep', heartRickFactors06),
  mapDataHeartRickFactors(7, 'Drinking', heartRickFactors07),
  mapDataHeartRickFactors(8, 'Smoking', heartRickFactors08),
  mapDataHeartRickFactors(9, 'Stress', heartRickFactors09),
]

export const dummyProgressDataSS01: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion01Questions: Question[] = [
  mapQuestionNotRequired('ss01__001', QuestionTypes.TextArea, 'What created the need to take this important journey towards heart wellness?'),
  mapQuestionNotRequired('ss01__002', QuestionTypes.TextArea, 'How are you feeling? What emotions are surfacing for you and why?'),
]
