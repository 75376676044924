import { useEffect, useMemo, useState } from 'react'
import { useDailyTrackingQuestionsPage } from '../../../hooks/pages/daily-tracking-questions'
import { useForm } from '../../../hooks/use-form'
import { Question } from '../../../models'
import { ButtonStyles, ErrorMessage, SubmitButtonCommon } from '../../Common'
import { DailyTrackingField } from '../Form/Field'
import { TrackerAction } from './TrackerAction'
import { format, isSameDay, constructFrom, differenceInDays, parseISO } from 'date-fns'
import { useLocales } from '../../../locales'
import { MyAdessoDailyTrackingStep } from '../../../constants/introduction'
import { Link, useLocation } from 'react-router-dom'
import { toDate } from '../../../lib/utils/helper'

type Props = {
  id: number
  className?: string
  backgroundColor?: string
  textColor?: string
  editable: boolean
  completed?: boolean
  questions: Question[]
  hideAfterSubmit: boolean
  afterSubmit: Function
  goalMemoId: any
  date: any
  answer?: any
  trackerAtionStatus?: number
  slug?: string
  refs?: React.Ref<any>[]
}

interface IHyperlink {
  [key: string]: {
    name: string
    link: string
  }
}

const DATA_HYPER_LINKS: IHyperlink = {
  commit_001: {
    name: 'Lifestyle',
    link: 'lifestyle',
  },
  commit_003: {
    name: 'Medications',
    link: 'medications',
  },
  move_001: {
    name: 'Move',
    link: 'move',
  },
  nourish_001: {
    name: 'Nourish',
    link: 'nourish',
  },
  nourish_006: {
    name: 'Supplements',
    link: 'supplements',
  },
  refresh_001: {
    name: 'Lifestyle',
    link: 'lifestyle',
  },
}

export const FormQuestions = ({ refs = [], answer, trackerAtionStatus, slug, id, date, className, editable, backgroundColor, textColor, completed, questions, afterSubmit, goalMemoId }: Props) => {
  const { initUseFormik } = useForm()
  const { t } = useLocales()
  const location = useLocation()

  const [isError, setIsError] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [textButton, setTextButton] = useState<string>()

  useEffect(() => {
    if (date) {
      const currentDate = new Date()
      const selectedDateParsed = parseISO(date)

      const differenceInDaysResult = -differenceInDays(selectedDateParsed, currentDate)
      setIsVisible(differenceInDaysResult <= 2)
    }
  }, [date])

  useEffect(() => {
    setTextButton(editable ? t('common.submit') : t('common.edit'))
  }, [editable])

  const formInit = useMemo(() => {
    return answer
      ?.map((item: any) => {
        const { id, ...rest } = item
        return {
          answer_history_id: id,
          ...rest,
          value: item.answer,
        }
      })
      ?.map((answer: any) => {
        const question = questions.find((q) => q.id === answer.question_id)
        if (question) {
          return { ...question, ...answer }
        }
        return null
      })
      .filter(Boolean)
  }, [answer, questions])

  const formik = initUseFormik(formInit, () => {
    handleSubmitRequest(formik.values)
  })

  const { UpdateDailyTrackingAnswerResult, updateDailyTrackingAnswer } = useDailyTrackingQuestionsPage()

  function handleSubmitRequest(answers: any) {
    const checkDefault: any = {
      rate: '3',
      fill_blank: '0',
      boolean: 'false',
      IncreaseOrReduceButton: '0',
      ticked: 'false',
    }

    const answerKeys = Object.keys(answers)
    const answerValues = Object.values(answers)

    const resultQuestions = questions.map((item) => {
      const itemAnswerIndex = answerKeys.findIndex((f) => f.toLowerCase() === item.name?.toLowerCase())
      const itemAnswerValue = (answer !== -1 ? answerValues[itemAnswerIndex] : '') as string

      return {
        question_id: item.id,
        answer: !itemAnswerValue ? (item?.real_type ? checkDefault[item?.real_type] : '1') : itemAnswerValue.toString(),
      }
    })
    const dataQuestions = {
      questions: resultQuestions,
      date: format(toDate(date), 'yyyy-M-d'),
      isEdit: isSubmitted ? true : !editable,
    }

    setIsSubmitting(true)
    setIsLoading(true)

    updateDailyTrackingAnswer(id, dataQuestions).then((res) => {
      setIsSubmitted(true)
      afterSubmit()
      setIsLoading(false)
      setTextButton(t('common.edit'))
    })
  }

  function handleSubmit() {
    setIsError(true)
  }

  function handleUpdateHiddenField(name: string, value: string) {
    formik.setFieldValue(name, value)
  }

  const formGoalQuestions = useMemo(() => {
    if (questions.length) {
      const classes = 'flex h-full w-full flex-1 flex-col space-y-6 ' + (className || '')
      return (
        <form className={classes} onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-4">
            {questions.map((question: any, index) => {
              return (
                <DailyTrackingField
                  onUpdateHiddenField={handleUpdateHiddenField}
                  key={question.name}
                  editable={editable}
                  question={question}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                  answer={answer}
                  value={formik.values[question.name]}
                  onChange={formik.handleChange}
                  isVisible={isVisible}
                >
                  {isError && formik.errors[question.name] && <ErrorMessage text={formik.errors[question.name]} />}

                  {DATA_HYPER_LINKS[question?.sku] && (
                    <div ref={question?.sku === 'commit_001' ? refs[MyAdessoDailyTrackingStep.SeeYourLifestyleRx] : null} className="flex justify-end">
                      <Link
                        to={`/my-health/${DATA_HYPER_LINKS[question?.sku]?.link}-rx/?previousUrl=${location.pathname}&tab-dt-from-gtc=${id}`}
                        className="text-[14px] capitalize leading-none text-color-primary underline decoration-color-primary"
                      >
                        {t(`dailyTracking.seeYour${DATA_HYPER_LINKS[question?.sku]?.name}Rx`)}
                      </Link>
                    </div>
                  )}
                </DailyTrackingField>
              )
            })}

            {isError && errorMsg && <ErrorMessage text={errorMsg} />}
          </div>
          <TrackerAction refs={refs[MyAdessoDailyTrackingStep.GoToHeartJournal]} tracked={trackerAtionStatus} slug={slug} />
          <div ref={refs[MyAdessoDailyTrackingStep.Submit]} className="pt-3">
            {isVisible && <SubmitButtonCommon style={ButtonStyles.Dark} width="w-[160px]" height="h-[50px]" text={textButton} isLoading={isLoading} onClick={handleSubmit} />}
          </div>
        </form>
      )
    } else {
      return <div className="w-full">No question</div>
    }
  }, [formik, isError, errorMsg, answer, isVisible, isSubmitted, isLoading, editable, textButton, formInit])

  return <>{formGoalQuestions}</>
}
