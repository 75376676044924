interface Props {
  [key: string]: string
}

export const textHeartRate: Props = {
  commit: 'heartChartMain.text.heartToday',
  move: 'heartChartMain.text.dailyMoves',
  nourish: 'heartChartMain.text.nutritionHabits',
  refresh: 'heartChartMain.text.emotionsAndSelfCare',
  relate: 'heartChartMain.text.relationships',
}

export const iconHeartRate: Props = {
  commit: 'bg-heartIconRate',
  move: 'bg-moveIconRate',
  nourish: 'bg-nourishIconRate',
  refresh: 'bg-refreshIconRate',
  relate: 'bg-relateIconRate',
}
