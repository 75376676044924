import { useCallback, useState } from 'react'
import { AdessoResult } from '../../models'
import { useAuth } from '../use-auth'
import { useDailyTracking } from '../use-daily-tracking'

type goalDailyTrackingQuestionsResponse = {
  dailyTrackingQuestions?: any
}

type dailyTrackingQuestionDetailResponse = {
  dailyTrackingQuestionDetail?: any
}

type dailyTrackingHistoryResponse = {
  dailyTrackingHistory?: any
}

type dailyTrackingUpdateAnswerResponse = {
  dailyTrackingUpdateAnswerId?: any
}

export function useDailyTrackingQuestionsPage() {
  const [dailyTrackingQuestionsResult, setDailyTrackingQuestionsResult] = useState<AdessoResult<goalDailyTrackingQuestionsResponse>>()
  const [dailyTrackingQuestionDetailResult, setDailyTrackingQuestionDetailResult] = useState<AdessoResult<dailyTrackingQuestionDetailResponse>>()
  const [dailyTrackingHistoryResult, setDailyTrackingHistoryResult] = useState<AdessoResult<dailyTrackingHistoryResponse>>()
  const [UpdateDailyTrackingAnswerResult, setUpdateDailyTrackingAnswerQuestionsResult] = useState<AdessoResult<dailyTrackingUpdateAnswerResponse>>()

  const auth = useAuth()
  const dailyTracking = useDailyTracking()

  const getDailyTrackingQuestions = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setDailyTrackingQuestionsResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await dailyTracking.getDailyTracking()
        if (res.success) {
          setDailyTrackingQuestionsResult({
            data: { dailyTrackingQuestions: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setDailyTrackingQuestionsResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setDailyTrackingQuestionsResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Daily Tracking Questions error'),
      })
    }
  }, [])

  const getDailyTrackingQuestionDetail = useCallback(async (dailyTrackingId: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setDailyTrackingQuestionDetailResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await dailyTracking.getDailyTrackingQuestionDetail(dailyTrackingId)
        if (res.success) {
          setDailyTrackingQuestionDetailResult({
            data: { dailyTrackingQuestionDetail: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setDailyTrackingQuestionDetailResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setDailyTrackingQuestionDetailResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Daily Tracking Detail error'),
      })
    }
  }, [])

  const getDailyTrackingHistory = useCallback(async (data_daily_tracking_history: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setDailyTrackingHistoryResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await dailyTracking.getDailyTrackingHistoryData(data_daily_tracking_history)
        if (res.success) {
          setDailyTrackingHistoryResult({
            data: { dailyTrackingHistory: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setDailyTrackingHistoryResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setDailyTrackingHistoryResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Daily Tracking History error'),
      })
    }
  }, [])

  const updateDailyTrackingAnswer = useCallback(async (goal_setting_id: number, data_goal_setting: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setUpdateDailyTrackingAnswerQuestionsResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await dailyTracking.updateDailyTrackingAnswerQuestions(goal_setting_id, data_goal_setting)
        if (res.success) {
          setUpdateDailyTrackingAnswerQuestionsResult({
            data: { dailyTrackingUpdateAnswerId: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setUpdateDailyTrackingAnswerQuestionsResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setUpdateDailyTrackingAnswerQuestionsResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Daily Tracking Update Answer Questions error'),
      })
    }
  }, [])

  return {
    dailyTrackingQuestionsResult,
    getDailyTrackingQuestions,
    dailyTrackingQuestionDetailResult,
    getDailyTrackingQuestionDetail,
    dailyTrackingHistoryResult,
    getDailyTrackingHistory,
    UpdateDailyTrackingAnswerResult,
    updateDailyTrackingAnswer,
  }
}
