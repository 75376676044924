import { Link } from 'react-router-dom'
import { Logo } from './Logo'
import { useLocales } from '../../locales'

type Props = {
  className?: string
  isLogoWhite?: boolean
  goDeeper?: boolean
}

export const CommonHeader = ({ className = '', isLogoWhite = false, goDeeper = false }: Props) => {
  const { t } = useLocales()

  return (
    <header className={`!fixed top-0 right-0 left-0 flex w-full p-5 md:text-center ${className}`}>
      <div className="w-full">
        <Link to="/" className="block md:text-center">
          <Logo className="inline-block h-10 w-[145px] hover:opacity-80" isLogoWhite={isLogoWhite} />
        </Link>
        {goDeeper && <h2 className="relative pt-[30px] font-butler text-[33px] font-normal leading-[34px] text-white md:text-center">{t('mainMenuAndSection.goDeeper')}</h2>}
      </div>
    </header>
  )
}
