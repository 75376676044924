import { useState, MutableRefObject, useRef } from 'react'
import { ButtonCommon, ButtonStyles } from '../Common/Button'
import { useLocales } from '../../locales'

type Props = {
  message?: string
  show: boolean
  closeNotification?: any
  handleRedirect?: () => void
}

export const IntroPopup = ({ message, show = false, closeNotification, handleRedirect }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const { t } = useLocales()

  const [isOpen, setOpen] = useState(show)
  const [text, setText] = useState(message)

  const handleClose = () => {
    setOpen(false)
    if (handleRedirect) {
      handleRedirect()
    }
    if (closeNotification) {
      closeNotification(false)
    }
  }

  return isOpen ? (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] flex items-center justify-center">
      <div className="absolute h-full w-full bg-color-overlay bg-opacity-50"></div>
      <div className="relative mx-2 w-[380px]">
        <div className="rounded-[15px] bg-white p-4 text-center text-[17px] font-medium leading-[21px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
          <p>{text}</p>
        </div>

        <div className="mt-4">
          <ButtonCommon
            contentRef={contentRef}
            style={ButtonStyles.Introduction}
            width="w-[167px]"
            height="h-[51px]"
            text={t('common.buttonConfirm')}
            onClick={() => {
              handleClose()
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
