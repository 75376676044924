import { useCallback, useMemo, useState } from 'react'

import { Session07Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { dataCoreConcepts, dataCC01 } from '../../../../../lib/data/session-07'

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
}

export const ScreenCoreConcepts01 = ({ showModal, nextScreen, backScreen }: Props) => {
  const [screen, setScreen] = useState(0)
  const [answers, setAnswers] = useState([] as any)
  const [contentScreen, setContentScreen] = useState(dataCoreConcepts[0])
  const numScreens = dataCoreConcepts.length

  const saveScreen = (screen: any) => {
    setScreen(screen)
  }

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConcepts[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleBackTop = useCallback(() => {
    window.scrollTo(0, 0)
    saveScreen(0)
    setContentScreen(dataCoreConcepts[0] || '')
  }, [])

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
        if (!answers.includes(stepCurrent)) {
          setAnswers([...answers, stepCurrent])
        }
      } else nextScreen()
    },
    [nextScreen, answers]
  )

  const showOption = useMemo(() => {
    return (
      <>
        {dataCC01.map((item) => {
          return (
            <div key={item.id} onClick={() => !answers.includes(item.id) && handleNextScreen(item.id)} className="flex items-center gap-4 rounded-[15px] border border-[#ED2580] p-4">
              {answers.includes(item.id) && <img className="!h-4 !w-4" src="/images/icons/ico_x.png" alt="" />}
              <div className="grow text-center text-[#ED2580]">{item.text}</div>
            </div>
          )
        })}
        {answers.length >= 4 && screen != 5 && (
          <div onClick={() => handleNextScreen(4)} className="flex items-center gap-4 rounded-[15px] border border-[#ED2580] p-4">
            <div className="grow text-center text-[#ED2580]">All of the above</div>
          </div>
        )}
        {screen == 5 && (
          <div className="flex items-center gap-4 rounded-[15px] border border-[#ED2580] bg-[#ED2580] p-4">
            <img className="!h-4 !w-4" src="/images/icons/ico_v.png" alt="" />
            <div className="grow text-center text-white">All of the above</div>
          </div>
        )}
      </>
    )
  }, [screen, answers, handleNextScreen])

  const buttons = useMemo(() => {
    switch (screen) {
      case 0:
        return <SessionButtons styleButton={'dark'} backScreen={() => handleBackScreen(screen)} />
      case 5:
        return <SessionButtons styleButton={'dark'} nextScreen={() => handleNextScreen(screen)} />
      default:
        return <SessionButtons styleButton={'dark'} backScreen={handleBackTop} />
    }
  }, [screen, handleBackScreen, handleNextScreen])

  const screenCoreConcepts = useMemo(() => {
    if (screen < numScreens) {
      const srcImg = contentScreen.img?.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${contentScreen.img}` : `/images/my-adresso-journey/session/07/${contentScreen.img}`

      return (
        <Session07Wrapper buttons={buttons}>
          <div className="session-style">
            {contentScreen.title && <h4>{contentScreen.title}</h4>}

            {contentScreen.img ? (
              srcImg.includes('.mp4') ? (
                <video className="block h-auto w-full" autoPlay muted controls>
                  <source src={srcImg} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={srcImg} alt="" />
              )
            ) : (
              ''
            )}

            <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />

            {(screen == 0 || screen == 5) && showOption}
          </div>
        </Session07Wrapper>
      )
    }
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal])

  return <>{screenCoreConcepts}</>
}
