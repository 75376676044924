import { useState, useCallback, useMemo } from 'react'
import { Session05Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion05Questions } from '../../../../../lib/data/session-05'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion05Questions[5], sesstion05Questions[6]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment01 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const after1 = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">
        Not
        <br />
        stressful
      </span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">Extremely</span>
    </div>
  )

  const after2 = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">Disagree</span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">Agree</span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session05Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            <h4>Let’s focus on a few areas of your life to find out how you are doing related to people, pleasure, and purpose.</h4>
            <div>
              <h4 className="text-ma-pink">Work</h4>
              <p>
                {`First up is work. You spend most of your waking life at work, so how it affects you matters a great deal to your health and heart. In some cases your work itself may be stressful. In other cases, the work may not be difficult but the culture or your relationships may be a source of stress. For others, work can be a source of pride, joy, and even purpose. Let’s get to know your work.`}
              </p>
            </div>
            <p>Let’s get to know your work.</p>
          </div>

          <div className="space-y-10">
            {ssHAQuestions.map((item, index) => {
              const after = index > 0 ? after2 : after1
              return (
                <FormField
                  key={`ss05_${item.name}`}
                  after={after}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session05Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
