import { GoalDetail } from '../../models/goal'

export function mapDataHeartJournal(data: GoalDetail): GoalDetail {
  let updatedData: GoalDetail = { ...data }

  updatedData.questions = updatedData.questions.map((question) => {
    return {
      ...question,
      is_required: true,
    }
  })

  return updatedData
}
