import { NavLink, useNavigate } from 'react-router-dom'
import { useLocales } from '../../locales'
import { Goal } from '../../models/goal'
import { DashboardSection } from '../Common/DashboardSection'
import { GoalElement } from '../HeartJournal/Goal'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { getBadgedImage } from '../HeartChart/HeartChartMain/dataUtils'

const IntroductionGoals: Goal[] = [
  {
    id: 1,
    name: 'My daily tracking',
    slug: '',
    order: 1,
    showProgress: true,
    completed: false,
    textColor: '#ffffff',
    backgroundColor: '#974A9A',
    path: '/daily-tracking',
  },
  {
    id: 2,
    name: 'My heart journal',
    slug: '',
    order: 2,
    showProgress: true,
    completed: true,
    textColor: '#ffffff',
    backgroundColor: '#974A9A',
    path: '/heart-journal',
  },
]

export const MyHeartActions = ({ jumpTo }: any) => {
  const navigate = useNavigate()
  const { t } = useLocales()

  const [dataMybeat, setDataMybeat] = useState<any>(null)
  const [imageBadge, setImageBadge] = useState<any>(null)
  const { getItemLS } = useLocalStorage()

  const userLocalStorage: any = getItemLS('user')

  useEffect(() => {
    const getDataMybeat = async () => {
      try {
        const result = await axios.get('/my-beats')

        setDataMybeat(result?.data?.data)
        setImageBadge(getBadgedImage(result?.data?.data?.des, true))
      } catch (error) {
        console.log(error)
      }
    }
    getDataMybeat()
  }, [])

  const myHeartActions = (
    <div className="flex space-x-2">
      <div className="flex w-[calc(50%)] flex-col items-center justify-center space-y-2 rounded-[15px] bg-[#EFD4F3] py-3">
        <div className="relative flex h-[45%] w-full  items-center justify-center bg-[length:auto_100%] bg-center bg-no-repeat text-center text-[18px] font-medium leading-none text-white">
          <div
            onClick={() => navigate('/heart-chart/beats-progress')}
            className={`mb-1 h-[65px] w-[65px]  cursor-pointer bg-[length:auto_100%] bg-center bg-no-repeat`}
            style={{
              backgroundImage: `url(${imageBadge})`,
              backgroundSize: 'auto 100%',
            }}
          />
        </div>
        <p className="font-['Proxima Nova'] text-center text-[12px] font-semibold uppercase leading-[15px] text-[#974A9A]">{dataMybeat?.des}</p>
        <p className="font-['Proxima Nova'] w-[68%] text-center text-[9px] font-semibold uppercase leading-[10px] text-[#974A9A]">
          {dataMybeat?.leftPoint} {t('heartChartMain.beatsProgress.needUnlock')} {dataMybeat?.nextLevel}
        </p>
      </div>
      <div className="flex w-[calc(50%)] flex-col space-y-2 ">
        {IntroductionGoals &&
          IntroductionGoals.length > 0 &&
          IntroductionGoals.map((goal, i) => {
            if (i > 2) {
              return <></>
            } else {
              if (goal.id === 1) {
                goal.completed = userLocalStorage?.status_daily_tracking
              } else if (goal.id === 2) {
                goal.completed = userLocalStorage?.status_heart_journal
              }

              return <GoalElement isProgressing={false} key={goal.id} goal={goal} clickable={true} />
            }
          })}
      </div>
    </div>
  )

  return (
    <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myHeartActions')}>
      {myHeartActions}
      <div className="flex justify-end">
        <NavLink to="/goal-tracking-configuration" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
          {t('extraText.view/editYourGoals')}
        </NavLink>
      </div>
    </DashboardSection>
  )
}
