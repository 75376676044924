export enum QuestionTypes {
  Input = 'input',
  InputHidden = 'input-hidden',
  Checkbox = 'checkbox',
  CheckboxMulti = 'checkbox-multi',
  Rate = 'rate',
  Radio = 'radio',
  RadioHidden = 'radio-hidden',
  RadioDropdown = 'radio-dropdown',
  MBI = 'Caculate MBI',
  TableQuestion = 'table-question',
  Label = 'label',
  TextArea = 'textarea',
  IncreaseOrReduceButton = 'IncreaseOrReduceButton',
  Boolean = 'boolean',
  FillBlank = 'fill_blank',
  Ticked = 'ticked',
}

export enum InputTypes {
  Number = 'number',
  Text = 'text',
  Email = 'email',
  PhoneNumber = 'tel',
  URL = 'url',
}

export type OptionValue = string | number

export type QuestionOption<T extends OptionValue> = {
  value: T
  label: string
  is_correct?: boolean
  score?: number
}

export type Parent<T extends OptionValue> = {
  value: T
  parentName: string
}

export type Question = {
  id?: number
  name: string
  type: QuestionTypes
  real_type?: QuestionTypes | any
  hideLabel?: boolean
  inputType?: InputTypes
  question?: string
  content_type?: string
  placeholder?: string
  value?: string
  hidden?: boolean
  isRequired?: boolean
  options?: QuestionOption<OptionValue>[]
  parent?: Parent<OptionValue>
  fields?: QuestionTableField[]
  tableOptions?: QuestionTableOptions
  after?: any
  link?: string
  check?: boolean
  name_input?: any
  user_settings?: any
  order?: number
  is_enable?: number
  answer?: string
}

export type ScreenQuizess = {
  isRequired?: boolean
  title?: string
  subTitle?: string
  more?: string
  screen_type?: string
  content_data?: {
    footer: string
    header: string
    content: string
    contentHighRisk?: []
  }
  questions: Question[]
}

export type IntroductionQuizess = {
  id?: number
  title: string
  description: string
  background?: string
}

export type Quizess = {
  introduction: any
  screens: any
  answers?: any
}

export type HeartChartLatest = {
  answer?: string
  date: Date
  id: number
  name: string
  question_id: number
}

type QuestionTableField = {
  key: string
  label: string
  type: string
}

type QuestionTableOptions = {
  column: number
  row: number
}
