import { useMemo } from 'react'

import { HeartRickFactorsStatus, HeartRickFactorsType } from '../../../../models'

type Props = {
  data: HeartRickFactorsType
  showDetail: () => void
}

export const HeartRiskFactorsItem = ({ data, showDetail }: Props) => {
  const journalItem = useMemo(() => {
    const isRead = data.status === HeartRickFactorsStatus.Read
    return (
      <button className={`flex w-full items-center space-x-2 rounded-[15px] px-4 py-2 ${isRead ? 'bg-ma-pink-500' : 'bg-ma-violet'}`} onClick={showDetail}>
        <div className={`h-8 w-8 rounded-full border-[1px] border-white ${isRead ? 'bg-white' : ''}`}>
          {isRead && <div className="h-full w-full bg-[url('assets/images/icon/i-check__pink.svg')] bg-[length:16px_auto] bg-center bg-no-repeat" />}
        </div>
        <div className="text-[15px] font-bold uppercase tracking-[0.15px] text-white">{data.title}</div>
      </button>
    )
  }, [data])

  return <>{journalItem}</>
}
