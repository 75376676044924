import React, { useEffect, useState } from 'react'

type Alert = {
  id?: number
  message: string
  onClose: () => void
}

type Props = {
  data: Alert[]
  title: string
}

const AlertItem: React.FC<Alert> = ({ message, onClose }) => {
  return (
    <div className="my-2 flex h-[50px] w-full items-center justify-between rounded-full bg-[#FF3399] p-4">
      <div className="flex items-center justify-center">
        <span className="h-[30px] w-[30px] flex-shrink-0 bg-i-alert-heart bg-contain bg-center bg-no-repeat"></span>
      </div>
      <span className="mx-2 text-center text-[11px] font-medium leading-[13px] text-white">{message}</span>
      <button onClick={onClose} className="h-[24px] w-[24px] flex-shrink-0 bg-transparent bg-closeAlert bg-contain bg-center bg-no-repeat"></button>
    </div>
  )
}

export const Alerts: React.FC<Props> = ({ data, title }) => {
  const [alerts, setAlerts] = useState<Alert[]>([])

  const removeAlert = (id: number) => {
    setAlerts(alerts.filter((alert) => alert.id !== id))
  }

  useEffect(() => {
    setAlerts(data)
  }, [data])

  if (alerts.length === 0) {
    return (
      <div className="my-3 ">
        <p className="font-['Proxima Nova'] text-[15px] font-[600] leading-[21px]">{title}</p>
        <p className="text-center">No alerts to display.</p>
      </div>
    )
  }

  return (
    <div className="mt-3">
      <p className="font-['Proxima Nova']  text-[15px] font-[600] leading-[21px]">{title}</p>
      <div className="flex flex-col items-center">
        {alerts.map((alert) => (
          <AlertItem key={alert.id} message={alert.message} onClose={() => alert.id !== undefined && removeAlert(alert.id)} />
        ))}
      </div>
    </div>
  )
}
