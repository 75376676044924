export enum ResultStatus {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  'N/A' = 'n/a',
}

export type DetailResultData = {
  category: string
  score: number
  scoreRaw: number
  status: ResultStatus
}

export type HeartScoreResult = {
  totalScore: number
  totalRawScore: number
  totalStatus: ResultStatus
  content: string
  details: DetailResultData[]
}
