import React, { useState, createContext, useContext } from 'react'
import { LevelUp } from './level-up'
import { AddBeat } from './add-beat'

export const MODAL_TYPES = {
  LEVEL_UP: 'LEVEL_UP',
  ADD_BEAT: 'ADD_BEAT',
}

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.LEVEL_UP]: LevelUp,
  [MODAL_TYPES.ADD_BEAT]: AddBeat,
}

type GlobalModalContext = {
  showGlobalModal: (modalType: string, modalProps?: any) => void
  hideGlobalModal: () => void
  store: any
}

const initalState: GlobalModalContext = {
  showGlobalModal: () => {},
  hideGlobalModal: () => {},
  store: {},
}

type Props = {
  children: any
}

const GlobalModalContext = createContext(initalState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

export const GlobalModal = ({ children }: Props) => {
  const [store, setStore] = useState({
    modalType: '',
    modalProps: {},
  })
  const { modalType, modalProps } = store

  const showGlobalModal = (modalType: any, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    })
  }

  const hideGlobalModal = () => {
    setStore({
      ...store,
      modalType: '',
    })
  }

  const renderComponent = () => {
    const ModalComponent = modalType ? MODAL_COMPONENTS[modalType] : null
    if (!modalType || !ModalComponent) {
      return null
    }
    return <ModalComponent id="global-modal" {...modalProps} />
  }

  return (
    <GlobalModalContext.Provider value={{ store, showGlobalModal, hideGlobalModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  )
}
