import axios from 'axios'
import { AdessoError } from "../error";
import { SettingUser } from "../../models";

export type UpdateSettingResponse = {
    success: boolean
    data: SettingUser | undefined
    message: string
}

export async function postUpdateSettingUser ( params: SettingUser ): Promise<UpdateSettingResponse> {
    const { data, status } = await axios.post( `/users/setting`, params )

    if ( status !== 200 ) {
        console.info( 'postUpdateMyNow', data )
        throw new AdessoError( `Request update user setting error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
