import { NavLink } from 'react-router-dom'
import { useHeaderContext } from '../../hooks/modals/global-set-header'
import { useEffect } from 'react'

type Props = {
  link: string
  name: string
  children?: React.ReactNode
}

export const FooterItem = ({ link, name, children }: Props) => {
  const { setShowHeader, setShowFeedback } = useHeaderContext()

  return (
    // <NavLink to={link} className={`flex w-full flex-col items-center space-y-[2px]`}>
    <NavLink
      onClick={() => {
        setShowHeader(true)
        setShowFeedback(true)
      }}
      to={link}
      className={({ isActive }) => (isActive ? 'bg-white/20' : 'bg-white/0') + ' flex h-full w-full flex-col items-center justify-center space-y-[2px] px-[1px] transition-colors hover:bg-white/20'}
    >
      <div className="flex h-6 w-full items-center justify-center">{children}</div>
      <span className="font-roboto text-ma-xs font-normal tracking-ma-wide text-white">{name}</span>
    </NavLink>
  )
}
