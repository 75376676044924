import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MyAdessoDailyTrackingIntroductionScreen from '../../components/MyTutorials/Screen/DailyTracking/DailyTracking'
import { EndDailyTracking } from '../../components/MyTutorials/Screen/DailyTracking/EndDailyTracking'
import MyAdessoGTCIntroductionScreen from '../../components/MyTutorials/Screen/GoalTrackingConfiguration/GoalTrackingConfiguration'
import { dataEndOfDailyTrackingTutorial } from '../../constants/introduction'

type Props = {}

enum IntroductionScreen {
  goalTrackingConfiguration,
  dailyTracking,
  finish,
}

const GTCAndDTTutorial = (props: Props) => {
  const [screen, setScreen] = useState<IntroductionScreen>(IntroductionScreen.goalTrackingConfiguration)
  const navigate = useNavigate()

  const handleClickNextScreen = useCallback((stepCurrent: IntroductionScreen, shouldLogout = false) => {
    setScreen(stepCurrent + 1)
  }, [])

  const handleClickCloseDemo = useCallback(() => {
    navigate('/my-tutorials')
  }, [])

  const goalTrackingConfigurationContent = useMemo(() => {
    return <MyAdessoGTCIntroductionScreen nextScreen={() => handleClickNextScreen(screen)} closeScreen={() => handleClickCloseDemo()} />
  }, [screen, handleClickNextScreen])

  const dailyTrackingContent = useMemo(() => {
    return <MyAdessoDailyTrackingIntroductionScreen nextScreen={() => handleClickNextScreen(screen)} closeScreen={() => handleClickCloseDemo()} />
  }, [screen, handleClickNextScreen])

  switch (screen) {
    case IntroductionScreen.goalTrackingConfiguration:
      return goalTrackingConfigurationContent

    case IntroductionScreen.dailyTracking:
      return dailyTrackingContent

    case IntroductionScreen.finish:
      return <EndDailyTracking text={dataEndOfDailyTrackingTutorial.text} linkPages={dataEndOfDailyTrackingTutorial.pages} />

    default:
      return <></>
  }
}

export default GTCAndDTTutorial
