export const fetchDataSimulator = async (buttonIndex: any) => {
  const mockData = [
    { name: 'systolic', values: [120, 130, 110, 115, 125, 130, 120], color: '#FF3399' }, // Week
    { name: 'diastolic', values: [80, 85, 75, 90, 85, 80, 82], color: '#974A9A' }, // Month
    { name: 'pulse', values: [70, 65, 75, 70, 68, 72, 74], color: '#F26E57' }, // 6 Months
  ]

  return mockData[buttonIndex % mockData.length]
}
export const heartRateValues = [1, 2, 3, 4, 5, 0, 1]

export const generateFakeDataHeartLine = () => {
  let data = []
  for (let i = 0; i < 8; i++) {
    const x = Math.random() * 80
    const y = Math.random() * 160
    data.push({ x: x, y: y })
  }

  data.sort((a, b) => a.x - b.x)

  return data
}

export const alertsFakeData = [
  { id: 1, message: 'YOUR BLOOD PRESSURE IS ABOVE NORMAL LIMIT!' },
  { id: 2, message: 'WE DETECTED ONE WEEK WITH NO EXERCISE IN TARGET HEART RATE ZONE' },
]

export const generateRandomDataForBarChart = () => {
  const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
  return daysOfWeek.map((day) => ({
    day,
    value: Math.floor(Math.random() * 10) + 1,
  }))
}

export const chartPieData = [
  {
    label: 'Move',
    color: '#F26E57',
    days: [
      { day: 1, value: 1 },
      { day: 2, value: 0 },
      { day: 3, value: 1 },
      { day: 4, value: null },
      { day: 5, value: null },
      { day: 6, value: null },
      { day: 7, value: null },
    ],
  },
  {
    label: 'Nourish',
    color: '#F26E57',
    days: [
      { day: 1, value: 1 },
      { day: 2, value: 0 },
      { day: 3, value: 1 },
      { day: 4, value: null },
      { day: 5, value: null },
      { day: 6, value: null },
      { day: 7, value: null },
    ],
  },
  {
    label: 'Relate',
    color: '#974A9A',
    days: [
      { day: 1, value: 1 },
      { day: 2, value: 0 },
      { day: 3, value: 1 },
      { day: 4, value: null },
      { day: 5, value: null },
      { day: 6, value: null },
      { day: 7, value: null },
    ],
  },
]

export const fakeGroupDataChartBeast = [
  { label: 'COMMIT', beats: 130, totalBeats: 500, color: '#FF3399' },
  { label: 'MOVE', beats: 30, totalBeats: 400, color: '#FF567A' },
  { label: 'NOURISH', beats: 100, totalBeats: 400, color: '#F26E57' },
  { label: 'RELATE', beats: 20, totalBeats: 400, color: '#B7005E' },
  { label: 'REFRESH', beats: 10, totalBeats: 400, color: '#974A9A' },
]

export const dataEarnBeats = [
  // { title: 'Complete another tutorial', description: '20 beats/tutorial', link: '/my-tutorials' },
  { title: 'Complete Learning Journey Session (Commit category includes Sessions 6 - 10)', description: '10 beats/session', link: '/my-adesso-journey' },
  { title: 'Complete a “Go Deeper” Session', description: '1 beats/session', link: '/go-deeper' },
  { title: 'Track your Daily Goal', description: '2 beat/day/category', link: '/daily-tracking' },
  { title: 'Heart Journal entree', description: '1 beat/day/category', link: '/heart-journal' },
  { title: 'Achieving a daily tracking 7-day streak', description: '4 beats', link: '/daily-tracking' },
  { title: 'Other special activities (Exercise, weight, sleep, purpose, medication tracking)', description: '1 beats/day/category', link: '/daily-tracking' },
  { title: 'Completing the full Adesso Learning Journey', description: '10 beats', link: '/my-adesso-journey' },
]
