import axios from 'axios'
import { AdessoError } from "../error";
import { SettingNotification } from "../../models";

export type UpdateNotiResponse = {
    success: boolean
    data: SettingNotification | undefined
    message: string
}

export async function postUpdateSettingNoti ( params: SettingNotification ): Promise<UpdateNotiResponse> {
    const { data, status } = await axios.post( `/users/setting-noti`, params )

    if ( status !== 200 ) {
        console.info( 'postUpdateMyNow', data )
        throw new AdessoError( `Request update user setting noti error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
