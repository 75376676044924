import { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconLoading, Wrapper, ButtonCommon, ButtonStyles } from '../Common'
import { HeartScoreResult } from '../../models/heartscore-result'
import MyActionServices from '../../services/MyActionServices'

type Props = {
  isLoading?: boolean
  data?: HeartScoreResult
}

export const HeartScoreResultContent = ({ isLoading, data }: Props) => {
  const navigate = useNavigate()

  useEffect(() => {
    MyActionServices.addMyAction('finish_heart_score').then((response) => {
      // do something here
    })
  }, [])

  const resultContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading />
        </div>
      )
    }

    if (!data) return <>{`Empty Data!`}</>

    const totalScore =
      data.totalScore && data.totalStatus ? (
        <div className={`flex w-full items-center space-x-[6px] font-semibold uppercase text-color-${data.totalStatus}-risk`}>
          <div className={`flex h-16 w-16 items-center justify-center rounded-full border-2 text-[25px] leading-[30px] border-color-${data.totalStatus}-risk`}>{data.totalScore}</div>

          <div className="leading-[19px text-[16px]">{data.totalStatus} RISK</div>
        </div>
      ) : null

    const tableScore = data.details
      ? data.details.map((detail, index) => {
          return (
            <div key={`resultDetail-${index}`} className="flex items-center border-t border-color-gray py-3">
              <div className="flex-1 font-semibold leading-[22px] text-color-overlay">{detail.category}</div>
              <div className={`w-20 text-right text-[12px] font-semibold leading-[15px] text-color-${detail.status}-risk`}>
                {`${detail.status} ${detail.status === 'n/a' ? '' : 'RISK'}`.toUpperCase()}
              </div>
            </div>
          )
        })
      : null

    return (
      <>
        <div className="space-y-5">
          {totalScore}
          <p className="font-bold">Here were your scores for each section:</p>
          <div className="border-b border-color-gray">{tableScore}</div>

          {/* <div className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-low-risk bg-color-low-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-low-risk">LOW RISK</div>
            </div>

            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-medium-risk bg-color-medium-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-medium-risk">MEDIUM RISK</div>
            </div>

            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-high-risk bg-color-high-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-high-risk">HIGH RISK</div>
            </div>
          </div> */}
        </div>
        <p dangerouslySetInnerHTML={{ __html: data.content }} />
        <p className="text-[16px] leading-[21px] text-color-high-risk">
          Want to continue your Adesso Journey? Take a tour of the MyAdesso Platform. Or feel free log out and you can always come back.
        </p>
      </>
    )
  }, [data, isLoading])

  return (
    <Wrapper className="pt-0">
      <div className="flex min-h-full flex-col justify-between space-y-5 pb-3">
        <div className="text-[17x] space-y-5 leading-[21px] text-black">
          <h3 className="font-['Butler'] text-[24px]">Your Adesso Heart score is</h3>

          {resultContent}
        </div>

        <div className="flex justify-center space-x-4">
          <div>
            <ButtonCommon style={ButtonStyles.Dark} width="w-[112px]" height="h-[50px]" text="Logout" onClick={() => navigate('/logout?scr=welcome')} />
          </div>
          <div>
            <ButtonCommon style={ButtonStyles.Dark} width="w-[112px]" height="h-[50px]" text="Continue" onClick={() => navigate('/welcome')} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
