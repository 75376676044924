import React, { useCallback, useState } from 'react'
import { AdessoError } from '../lib/error'
import { deleteMedicationRx, getMedicationRx, getMedicationRxById, postMedicationRx, putMedicationRx } from '../lib/request'
import { AdessoResult } from '../models'
import { useAuth } from './use-auth'

type TMedicationResponse = {
  medications?: any
}

export function useMedicationRxPage() {
  const [medicationRxResult, setMedicationRxResult] = useState<AdessoResult<TMedicationResponse>>()
  const [medicationRxResultById, setMedicationRxResultById] = useState<AdessoResult<TMedicationResponse>>()

  const auth = useAuth()

  const useGetMedicationRx = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMedicationRxResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getMedicationRx()
        if (res.success) {
          setMedicationRxResult({
            data: { medications: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setMedicationRxResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setMedicationRxResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Medication Rx error'),
      })
      throw new AdessoError('Get Medications Rx Failed')
    }
  }, [])

  const useGetByIdMedicationRx = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMedicationRxResultById({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getMedicationRxById(id)
        if (res.success) {
          setMedicationRxResultById({
            data: { medications: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setMedicationRxResultById({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setMedicationRxResultById({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Medication Rx By Id error'),
      })
      throw new AdessoError('Get Medications Rx By Id Failed')
    }
  }, [])

  const usePostMedicationRx = useCallback(async (dataCreate: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await postMedicationRx(dataCreate)
      }
    } catch (error) {
      throw new AdessoError('Create Medications Rx Failed')
    }
  }, [])

  const usePutMedicationRx = useCallback(async (id: number, dataUpdate: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await putMedicationRx(id, dataUpdate)
      }
    } catch (error) {
      throw new AdessoError('Update Medications Rx Failed')
    }
  }, [])

  const useDeleteMedicationRx = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await deleteMedicationRx(id)
      }
    } catch (error) {
      throw new AdessoError('Delete Medications Rx Failed')
    }
  }, [])

  return { medicationRxResult, medicationRxResultById, useGetMedicationRx, useGetByIdMedicationRx, usePostMedicationRx, usePutMedicationRx, useDeleteMedicationRx }
}
