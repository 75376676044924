import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from '../../../hooks/use-boolean'
import { mapGoalQuestions } from '../../../lib/maps/goal'
import { useLocales } from '../../../locales'
import { GoalDetail } from '../../../models/goal'
import { ButtonStyles, SubmitButtonCommon } from '../../Common'
import { Checkbox } from './Checkbox'
import { FormQuestions } from './FormQuestions'
import { HearJournalArticle } from '../../../models/deepers'

type Props = {
  goal: GoalDetail
  trackingId?: number | any
  anwsers: IAnwserHistory[]
}

interface IAnwserHistory {
  id: number
  goal_id: number
  question_id: number
  date: string
  answer: string | any
}

export const FormQuestionsContent = ({ goal, trackingId, anwsers }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>()
  const { t } = useLocales()
  const navigate = useNavigate()
  const checked = useBoolean(!!goal?.daily_action)

  const handleAfterSubmitForm = useCallback(() => {
    setIsSubmitted(true)
  }, [])

  useEffect(() => {
    setIsSubmitted(goal?.completed)
  }, [goal])

  const formGoalQuestions = useMemo(() => {
    let mappedQuestions = mapGoalQuestions(goal.questions || []).filter((f) => f.real_type === 'input')
    if (anwsers?.length) {
      mappedQuestions.forEach((q) => {
        const anwser = anwsers.find((a) => {
          return q.id === a.question_id
        })
        if (anwser) {
          q.value = anwser?.answer
        }
        return q
      })
    }
    return (
      <FormQuestions
        id={goal.id}
        goalMemoId={goal.goal_memo_id}
        backgroundColor={goal.backgroundColor}
        textColor={goal.textColor}
        className="pb-9"
        editable={!goal.completed}
        completed={goal.completed}
        questions={mappedQuestions}
        key={goal.id}
        hideAfterSubmit={false}
        afterSubmit={handleAfterSubmitForm}
      />
    )
  }, [goal, anwsers])

  const goDeeper = useMemo(() => {
    return (
      <div className="pb-5">
        <h4 className="relative text-ma-md font-bold text-ma-pink-300" style={{ color: goal?.backgroundColor }}>
          Go Deeper (earn extra point)
        </h4>
        <div className="mt-3 grid grid-cols-1">
          {goal?.articles &&
            goal.articles.map((article: HearJournalArticle, index) => {
              return <Checkbox slug={article.slug} key={article.id} id={article.id} label={article.title} checked={article.is_finish ? true : false} onChange={(e) => {}} />
            })}
        </div>
      </div>
    )
  }, [goal])

  const bg = checked.value === true ? "bg-[url('assets/images/icon/i-circle_checked.svg')]" : "bg-[url('assets/images/icon/i-circle_check.svg')]"

  const dailyTracker = useMemo(() => {
    return (
      <div className="space-y-3">
        <div className="flex items-center justify-between pr-2">
          <h4 className="relative text-ma-md font-bold text-ma-pink-300" style={{ color: goal?.backgroundColor }}>
            Did you track your daily actions?
          </h4>
          <div
            className="relative flex h-full flex-col items-center justify-center hover:cursor-pointer"
            onClick={(e) => {
              if (!goal.daily_action) {
                checked.onToggle()
              }
            }}
          >
            <div className="absolute h-[14px] w-[14px] bg-[url('assets/images/icon/i-tick.svg')] bg-no-repeat"></div>
            <div className={`${bg} h-[36px] w-[36px] bg-no-repeat`}></div>
          </div>
        </div>
        {checked.value === true && !goal.daily_action && (
          <SubmitButtonCommon style={ButtonStyles.Dark} width="w-[335px]" height="h-[50px]" text="GO TO YOUR DAILY TRACKER" onClick={() => navigate(`/daily-tracking?trackingId=${trackingId}`)} />
        )}
      </div>
    )
  }, [goal, checked])

  return (
    <div>
      {formGoalQuestions}
      {goDeeper}
      {dailyTracker}
    </div>
  )
}
