import { useState, useCallback, useMemo } from 'react'

import { Session05Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { dataCoreConceptsMores } from '../../../../../lib/data/session-05'

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
}

export const ScreenCoreConceptsMore = ({ showModal, nextScreen, backScreen }: Props) => {
  const [dataShow, setDataShow] = useState<CoreConceptsStops>(dataCoreConceptsMores[0])
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)

  const handleBackScreen = useCallback(
    (statusShowDetail: boolean) => {
      if (statusShowDetail) {
        window.scrollTo(0, 0)
        setIsShowDetail(false)
      } else backScreen()
    },
    [backScreen]
  )

  const handleShowDetail = useCallback(
    (id: number) => {
      const dataDetail = dataCoreConceptsMores.find((item) => item.id === id)

      if (dataDetail) {
        window.scrollTo(0, 0)
        setDataShow(dataDetail)
        setIsShowDetail(true)
      }
    },
    [backScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={isShowDetail ? undefined : showModal} backScreen={() => handleBackScreen(isShowDetail)} nextScreen={isShowDetail ? undefined : nextScreen} />
  }, [dataShow, isShowDetail, handleBackScreen, nextScreen, showModal])

  const screenList = useMemo(() => {
    return (
      <Session05Wrapper buttons={buttons}>
        <div className="session-style">
          <h4>Meaningful connections.</h4>
          <p>Finding healthy methods for relating with the world and the people in it can bring a multitude of benefits to your heart and health.</p>
          <p>Next let’s learn about how these areas interact with your heart and explore steps you can take to improve these areas in your life.</p>
          <p>
            Click on the icons below in the relationships that are meaningful for you. If you find the tips useful, be sure to click this section of goals at the end of the session and use your Heart
            Journal to take action as described here.{' '}
          </p>

          <div className="flex flex-wrap justify-between">
            {dataCoreConceptsMores.map((item) => (
              <div key={item.id} className="mb-5 w-[calc(50%-6px)] space-y-2" onClick={() => handleShowDetail(item.id)}>
                <img className="cursor-pointer" src={`/images/my-adresso-journey/session/05/${item.img}`} alt="" />
                <div className="w-max cursor-pointer text-[16px] font-bold leading-[19px]">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </Session05Wrapper>
    )
  }, [buttons, handleShowDetail])

  const screenDetail = useMemo(() => {
    return (
      <Session05Wrapper buttons={buttons}>
        <div className="session-style">
          <h4>{dataShow.title}</h4>

          <img src={`/images/my-adresso-journey/session/05/${dataShow.img}`} alt="" />

          <div className="space-y-6" dangerouslySetInnerHTML={{ __html: dataShow.content }} />
        </div>
      </Session05Wrapper>
    )
  }, [dataShow, buttons])

  const screenCoreConceptsStop = useMemo(() => {
    return isShowDetail ? screenDetail : screenList
  }, [isShowDetail, screenList, screenDetail])

  return <>{screenCoreConceptsStop}</>
}
