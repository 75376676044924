import { useCallback, useEffect, useMemo } from 'react'
import { useBoolean } from '../../../hooks/use-boolean'
import { Question } from '../../../models'
import { toBool } from '../../../helpers/StringHelper'

type Props = {
  question?: Question
  value?: any
  onChange?: any
}

export const Ticked = ({ question, value, onChange }: Props) => {
  const checked = useBoolean(value)

  useEffect(() => {
    checked.setValue(toBool(value))
  }, [question, value])

  const handleChangeTicked = useCallback(
    (value: boolean) => {
      onChange(question?.name, value)
    },
    [onChange, question]
  )
  const bg = checked.value === true ? "bg-[url('assets/images/icon/i-circle_checked.svg')]" : "bg-[url('assets/images/icon/i-circle_check.svg')]"
  const ticked = useMemo(() => {
    return (
      <div className="flex items-center justify-end pr-2">
        <div
          className="relative flex h-full flex-col items-center justify-center hover:cursor-pointer"
          onClick={() => {
            checked.onToggle()
            handleChangeTicked(!checked?.value)
          }}
        >
          <div className="absolute h-[14px] w-[14px] bg-[url('assets/images/icon/i-tick.svg')] bg-no-repeat"></div>
          <div className={`${bg} h-[36px] w-[36px] bg-no-repeat`}></div>
        </div>
      </div>
    )
  }, [checked, handleChangeTicked, bg])

  return <>{ticked}</>
}
