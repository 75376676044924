import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
}

export async function getHeartChartTestingQuestions(): Promise<Response> {
  const { data, status } = await axios.get('/heart-chart-testing-questions')

  if (status !== 200) {
    throw new AdessoError(`Request getHeartChartTestingQuestions error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartLatestQuestions(): Promise<Response> {
  const { data, status } = await axios.get('/heart-chart-latest')

  if (status !== 200) {
    throw new AdessoError(`Request getHeartChartLatestQuestions error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
