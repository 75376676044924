import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts09 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Create your Target Movements</h4>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_009.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_009.jpg`} alt="" />
          </picture>
          <p>There are so many Target Movements you can choose to help you reach your target heart rate. Think about the ones you'd like to use this week:</p>

          <ul className="list-disc pl-8">
            <li>High-intensity walking</li>
            <li>Hiking</li>
            <li>Running</li>
            <li>Cycling</li>
            <li>Aerobics or yoga class</li>
            <li>Dancing</li>
            <li>Weight lifting</li>
            <li>Swimming or surfing</li>
            <li>Skating, blading or skiing</li>
          </ul>

          <p>
            {`If you can’t go outside, play indoors! There are an endless variety of in-home fitness solutions to choose from. You can go with expensive tech-enabled companies  or go the budget-friendly route with jump ropes and weight sets online. Or even try a dancing focused video game.`}
          </p>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
