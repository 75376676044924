import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult, SubscriptionOptions } from '../../models'
import { postSubscription } from '../../lib/request/post-subscription'

type Result = {
  success: boolean
}

export function useSubscriptionPage() {
  const [postDataResult, setPostDataResult] = useState<AdessoResult<Result>>()
  const auth = useAuth()

  const submitSubscription = useCallback(async (params: SubscriptionOptions) => {
    setPostDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await postSubscription(params)

        if (res.data) {
          setPostDataResult({
            isLoading: false,
            data: { success: true },
            error: undefined,
          })
        }

        if (res.message) {
          setPostDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post Subscription Error'),
      })
    }
  }, [])

  return { postDataResult, submitSubscription }
}
