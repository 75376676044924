import { useMemo, useCallback, useState, useEffect } from 'react'

import { ButtonStyles, FormField, ErrorMessage } from '../../Common'
import { BmiField } from '../../Common/form/Bmi'
import { RateField } from '../../Common/form/Rate'

import { HeartScoreButtons } from './Buttons'

import { useForm, FormikOptions } from '../../../hooks/use-form'
import { ScreenQuizess, QuestionTypes } from '../../../models/question'

type Props = {
  currentStep: number
  values: FormikOptions<any>
  quizess: ScreenQuizess
  isLoading?: boolean
  backScreen: (data?: object) => void
  nextScreen: (data?: object, notMove?: boolean) => void
}

export const QuizzesLayout = ({ currentStep, values, quizess, isLoading, backScreen, nextScreen }: Props) => {
  const { initUseFormik } = useForm()
  const [valuesField, setValuesField] = useState<FormikOptions<any>>(values)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  useEffect(() => {
    setValuesField(values)
  }, [values])

  const { title, subTitle, questions, more } = quizess

  let formik = initUseFormik(
    questions,
    () => {
      nextScreen(formik.values, currentStep === 1 ? true : false)
    },
    valuesField
  )

  const handleBackScreen = useCallback(() => {
    backScreen(formik.values)
  }, [backScreen, formik.values])

  const handleSubmitForm = useCallback(() => {
    setIsError(true)
  }, [])

  const handleChange = useCallback(
    (value: any, name: string) => {
      formik.values[name] = value
    },
    [formik]
  )

  const formQuizzes = useMemo(() => {
    return (
      <form className="flex h-full w-full flex-1 flex-col space-y-9 pb-4" onSubmit={formik.handleSubmit}>
        <div className="flex-1 space-y-4">
          {title ? <h3 className="text-ma-h3 font-bold tracking-[0.21px]">{title}</h3> : undefined}

          {subTitle ? <p dangerouslySetInnerHTML={{ __html: subTitle }} /> : undefined}

          {questions.map((item) => {
            if (item.parent) {
              if (item.hidden) return undefined

              const parentValue = item.parent.value
              const formikParentValue = formik.values[item.parent.parentName]

              if (typeof formikParentValue === 'object') {
                // @ts-ignore
                if (!formikParentValue.includes(parentValue)) return undefined
              } else {
                if (formikParentValue !== parentValue) return undefined
              }
            }

            if (item.type === QuestionTypes.MBI) {
              return (
                <BmiField key={`${item.name}-${item.id}`} question={item} values={formik.values[item.name]} onChangeValue={(value: any) => handleChange(value, item.name)}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </BmiField>
              )
            }

            return (
              <FormField key={`${item.name}-${item.id}`} question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
              </FormField>
            )
          })}

          {isError && errorMsg && <ErrorMessage text={errorMsg} />}

          {more ? <p className="pt-3" dangerouslySetInnerHTML={{ __html: more }} /> : undefined}
        </div>

        <HeartScoreButtons style={ButtonStyles.Dark} backScreen={currentStep == 0 ? false : handleBackScreen} isSubmit isLoading={isLoading} nextScreen={() => handleSubmitForm()} />
      </form>
    )
  }, [formik, currentStep, isError, errorMsg, isLoading, handleBackScreen, handleChange, handleSubmitForm])

  return <>{formQuizzes}</>
}
