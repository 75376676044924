import { useMemo, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, ButtonStyles } from '../../Common'
import { HeartScoreButtons, IntroductionLayout } from '../layout'

type Props = {
  isLoading?: boolean
  nextScreen: () => void
}

enum Screen {
  MediaRecords,
  Introduction,
}

export const IntroductionScreen = ({ isLoading, nextScreen }: Props) => {
  const [screen, setScreen] = useState<Screen>(Screen.MediaRecords)
  const navigate = useNavigate()

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.Introduction) {
        window.scrollTo(0, 0)
        setScreen(stepCurrent + 1)
      } else {
        nextScreen()
      }
    },
    [nextScreen]
  )

  const handleBackScreen = useCallback(() => {
    navigate('/subscription')
  }, [])

  const buttons = useMemo(() => {
    const isMediaRecordsScreen = screen === Screen.MediaRecords
    return (
      <div className="flex items-center justify-center gap-4">
        <HeartScoreButtons style={isMediaRecordsScreen ? ButtonStyles.Dark : ButtonStyles.Light} isSubmit={!isMediaRecordsScreen} isLoading={isLoading} backScreen={() => handleBackScreen()} />
        <HeartScoreButtons style={isMediaRecordsScreen ? ButtonStyles.Dark : ButtonStyles.Light} isSubmit={!isMediaRecordsScreen} isLoading={isLoading} nextScreen={() => handleNextScreen(screen)} />
      </div>
    )
  }, [isLoading, screen, handleNextScreen])

  const title = useMemo(() => {
    return screen === Screen.MediaRecords ? 'Medical Records' : ''
  }, [screen])

  const content = useMemo(() => {
    return screen === Screen.MediaRecords ? (
      <>
        {`If you have taken a physical and have those records, please access them now. We will ask you about those results to more accurately gauge your heart score. If you don’t have them, don’t worry. We will still help you get an accurate heart score and you can add in the medical records later. Or if you’re in the Adesso Clinical Program, your results will be added to your profile after you begin.`}
      </>
    ) : (
      <>
        {`When you take care of yourself and focus on self-care, you address those parts of your life that you can control. The more you empower yourself the more you can live with energy, vitality, and overall health.`}
        <br />
        <br />
        MyAdesso will help you create healthy lifestyle changes that positively impact every area of your life while preventing heart disease. Heart-care is the best form of self-care.
        <br />
        <br />
        Heart disease takes decades to develop, so the sooner we figure out if you are at risk, the sooner we can do something about it.
        <br />
        <br />
        <strong>Heart-care is the new selfcare!</strong>
      </>
    )
  }, [screen])

  const heartScoreIntro = useMemo(() => {
    return (
      <Wrapper className={`text-ma-md ${screen === Screen.MediaRecords ? '' : ' text-white'}`}>
        <div className="fixed top-0 bottom-0 left-0 right-0 z-10 bg-white pt-20">
          <div className={`h-full ${screen === Screen.MediaRecords ? '' : "bg-[url('/backgrounds/heart-score/bg-00.webp')] bg-cover bg-center bg-no-repeat "}`}></div>
        </div>
        {!isLoading && (
          <IntroductionLayout className="z-20" title={title} buttons={buttons}>
            {content}
          </IntroductionLayout>
        )}
      </Wrapper>
    )
  }, [isLoading, buttons])

  return <>{heartScoreIntro}</>
}
