import { useCallback } from 'react'
import { AdessoError } from '../lib/error'
import { getGoalTracking } from '../lib/request/get-goal-tracking'
import { getGoalTrackingDetail } from '../lib/request/get-goal-tracking-detail'
import { postUpdateGoalSettingId } from '../lib/request/post-update-goal-tracking'

export function useGoalTracking() {
  const getGoalTrackingConfig = useCallback(async () => {
    try {
      return await getGoalTracking()
    } catch (error) {
      throw new AdessoError('Get Goal Tracking Failed')
    }
  }, [])

  const getGoalTrackingConfigDetail = useCallback(async (goalTrackingId: number) => {
    try {
      return await getGoalTrackingDetail(goalTrackingId)
    } catch (error) {
      throw new AdessoError('Get Goal Tracking Detail Failed')
    }
  }, [])

  const updateGoalSettingId = useCallback(async (goal_setting_id: number, data_goal_setting: any) => {
    try {
      return await postUpdateGoalSettingId(goal_setting_id, data_goal_setting)
    } catch (error) {
      throw new AdessoError('Update goal setting id Failed')
    }
  }, [])

  return { getGoalTrackingConfig, getGoalTrackingConfigDetail, updateGoalSettingId }
}
