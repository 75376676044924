import { useCallback, useEffect, useMemo } from 'react'
import { OnboardContent } from '../components/Onboard/'

import { useOnboardPage } from '../hooks/pages/onboard'
import { MyNow } from '../models/my-now'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../hooks/use-local-storage'

export const Onboard = () => {
  const { postDataResult, saveMyNow } = useOnboardPage()
  const navigate = useNavigate()
  const { getItemLS, setItemLS } = useLocalStorage()

  const onboarded = getItemLS('onboarded')

  useEffect(() => {
    if (onboarded && onboarded === 'yes') {
      navigate('/explanation')
    } else if (!onboarded) {
      setItemLS('onboarded', 'no')
    } else {
      navigate('/welcome')
    }
  }, [])

  const handleSaveMyNow = useCallback(
    (myNow: string) => {
      saveMyNow(myNow)
    },
    [saveMyNow]
  )

  const onboardContent = useMemo(() => {
    const isSuccess = postDataResult?.data?.content !== undefined
    const isLoading = postDataResult?.isLoading || false

    return <OnboardContent isSaveSuccess={isSuccess} isLoading={isLoading} saveMyNow={handleSaveMyNow} />
  }, [postDataResult, handleSaveMyNow])

  return <>{onboardContent}</>
}
