import { useCallback, useMemo, useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { ButtonCommon, ButtonStyles, IconLoading } from '../../Common'
import { SessionWrapper } from '../SessionWrapper'
import DeeperServices from '../../../services/DeeperServices'
import { LoadingSize } from '../../../models/loading'

export const MAJourneyGoDeeperContent = () => {
  const { slug } = useParams()

  const navigate = useNavigate()

  const handleNavigate = useCallback((nextPage: string) => {
    navigate(nextPage)
  }, [])

  const [data, setData] = useState<
    Array<{
      id: number
      order: number
      slug: string
      name: string
      title: string
    }>
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState('00')
  const [sessionTitle, setSessionTitle] = useState('')

  useEffect(() => {
    getActions()
  }, [])

  const getActions = () => {
    setIsLoading(true)
    if (!!slug) {
      DeeperServices.getDeeperArticlesSession(slug).then((response) => {
        setSessionId(response.data.id)
        setSessionTitle(response.data.title)
        setData(response.data.go_deepers || [])
        setIsLoading(false)
      })
    } else {
      DeeperServices.getDeepers().then((response) => {
        setData(response.data.go_deepers || [])
        setIsLoading(false)
      })
    }
  }

  const boxButton = useMemo(() => {
    const currentSession = slug?.slice(-2)
    const nextSession = currentSession == '10' ? '' : '/session-' + ('00' + (Number(currentSession) + 1)).slice(-2)
    return (
      <div className="flex flex-col justify-center space-y-4 pt-11">
        <ButtonCommon style={ButtonStyles.Dark} width="w-[250px]" height="h-[50px]" text="GO DEEPER LIBRARY" onClick={() => handleNavigate('/go-deeper')} />
        <ButtonCommon style={ButtonStyles.Dark} width="w-[250px]" height="h-[50px]" text="NEXT SESSION" onClick={() => handleNavigate('/my-adesso-journey' + nextSession)} />
      </div>
    )
  }, [])

  const prebuildTailwindClass = useMemo(() => {
    return (
      <div className="">
        <div className="bg-[url('/images/my-adresso-journey/session/01/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/02/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/03/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/04/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/05/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/06/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/07/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/08/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/09/bg.webp')]"></div>
        <div className="bg-[url('/images/my-adresso-journey/session/10/bg.webp')]"></div>
        <div className="bg-[url('/images/go-deeper/demo_heart.webp')]"></div>
      </div>
    )
  }, [])

  const maJourneyGoDeeperContent = useMemo(() => {
    const bg = isLoading ? '' : sessionId == '00' ? "bg-[url('/images/go-deeper/demo_heart.webp')]" : `bg-[url('/images/my-adresso-journey/session/${sessionId}/bg.png')]`
    return (
      <SessionWrapper className={`min-h-[calc(100vh-56px)] ${bg}`} title={sessionTitle || 'Go Deepers'}>
        <div className="flex h-full flex-col py-9 text-[18px] leading-[22px]">
          <div className="flex-1 space-y-5">
            <div className="space-y-4">
              <h2 className="w-full text-[27px] font-bold leading-[30px]">Go Deeper</h2>
              <p>You can also gain extra beats by diving in further and completing the following actions:</p>
            </div>
            {isLoading ? (
              <div className="flex w-full justify-center">
                <IconLoading size={LoadingSize.Normal} />
              </div>
            ) : (
              <ul className="list-disc space-y-4 pl-10">
                {data.map((item) => {
                  return (
                    <li key={item.id}>
                      <Link to={`/go-deeper/${item.slug}`} className="text-md flex w-full flex-col justify-center gap-0 p-0 align-middle text-black underline underline-offset-2 active:bg-transparent">
                        {item.name || item.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>

          {boxButton}
        </div>
      </SessionWrapper>
    )
  }, [isLoading, data, sessionId, sessionTitle])

  return <>{maJourneyGoDeeperContent}</>
}
