import axios from 'axios'
import { AdessoError } from "../error";

export async function deleteRemoveScreen (): Promise<any> {
    const { data, status } = await axios.delete( 'users/screen' )
    if ( status !== 200 ) {
        console.info( 'deleteRemoveGoal', data )
        throw new AdessoError( `Request login error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
