import { ReactElement } from 'react'
import { checkAuth } from '../../lib/request/helper'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { IUser } from '../../models/user'
import { IntroPopup } from '../Common/IntroPopup'
import { useLocales } from '../../locales'
import { checkHadFirstHeartScore } from '../../lib/utils/helper'

interface IAuthenticationGuardProps {
  // Allow any component to be rendered
  component: ReactElement<any>
  [_: string]: any
}

export function AuthMiddleware({ component, ...rest }: IAuthenticationGuardProps): ReactElement<any> {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useLocales()
  const { getItemLS } = useLocalStorage()
  const token = !!getItemLS('token')
  const user = getItemLS('user') as IUser

  if (user) {
    user.screen = checkHadFirstHeartScore(user) ? [] : user.screen
  }

  if (!token || !user) {
    return <Navigate to="/login" replace state={{ path: location.pathname }} />
  }

  if (!user.access_code_id && !user.payment_status && location.pathname !== '/register') {
    return <Navigate to={'/register'} replace />
  }

  if (location.pathname.includes('my-tutorials/') || ((location.pathname.includes('heart-score') || location.pathname.includes('explanation')) && !user.introduced)) {
    return component
  }

  if (user.screen?.screen != undefined && `/${user.screen?.screen}` !== location.pathname) {
    return <Navigate to={'/' + user.screen?.screen} replace />
  }

  if (!user.had_first_heart_score && location.pathname !== '/heart-score') {
    return <IntroPopup handleRedirect={() => navigate('/heart-score')} message={t('extraText.firstFinishYourHeartScore')} show />
  }

  if (!user.introduced && location.pathname !== '/introduction' && location.search !== '?scr=welcome') {
    return <Navigate to="/introduction" replace />
  }

  return component
}

export function LoginMiddleware({ component }: any) {
  let auth = checkAuth()
  return auth ? <Navigate to="/dashboard" replace /> : component
}
