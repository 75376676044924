import { useCallback, useEffect, useState } from 'react'
import { Question } from '../../../models'
import { toNumber } from 'lodash'

type Props = {
  value?: any
  question: Question
  // onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: any
  answer?: any
  disabled: boolean
}

export const IncreaseOrReduceButtonField = ({ answer, value, question, onChange, disabled }: Props) => {
  const answerId = answer?.filter((item: any) => item?.question_id === question?.id)
  const [count, setCount] = useState(value ?? 0)

  useEffect(() => {
    setCount(value ?? 0)
  }, [value])

  const handleChangeButton = useCallback((count: number) => {
    onChange(question?.name, count)
  }, [])

  return disabled ? (
    <>
      <span className="self-center pr-5 font-bold">{count}</span>
    </>
  ) : (
    <div className="flex h-[36px] w-[108px] justify-between rounded-full bg-[#FFD7E8B2]">
      <div
        className={`flex h-[36px] w-[36px] items-center justify-center rounded-[50%] bg-[url('assets/images/icon/i-ellipse.svg')] bg-[length:auto] bg-no-repeat text-center hover:cursor-pointer hover:bg-slate-200`}
        onClick={() => {
          setCount(Math.max(count - 1, 0))
          handleChangeButton(Math.max(count - 1, 0))
        }}
      >
        <div className={`h-[1.5px] w-[15px] bg-[url('assets/images/icon/i-minus.svg')] bg-[length:auto] bg-no-repeat text-center`} />
      </div>
      <span className="self-center">{count}</span>
      <div
        className={`flex h-[36px] w-[36px] items-center justify-center rounded-[50%] bg-[url('assets/images/icon/i-ellipse.svg')] bg-[length:auto] bg-no-repeat hover:cursor-pointer hover:bg-slate-200`}
        onClick={(e) => {
          setCount(toNumber(count) + 1)
          handleChangeButton(toNumber(count) + 1)
        }}
      >
        <div className={`h-[15px] w-[15px] bg-[url('assets/images/icon/i-plus.svg')] bg-[length:auto] bg-no-repeat text-center`} />
      </div>
    </div>
  )
}
