import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Wrapper, Logo, IconLoading } from '../../../Common'

type Props = {
  title?: string
  titleHeight?: string
  isLoading?: boolean
  hiddenHeader?: boolean
  isLogoWhite?: boolean
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
  buttons?: React.ReactNode
  pcPannel?: string
  bgExpand?: boolean
}

export const SessionWrapper = ({ isLoading, hiddenHeader, title, titleHeight = 'h-[101px]', className, children, style, buttons, isLogoWhite = true, pcPannel = '', bgExpand = false }: Props) => {
  const head = useMemo(() => {
    return !hiddenHeader ? (
      <header className="relative z-20 w-full max-md:!mt-0">
        <div className="w-full">
          <Link to="/">
            <Logo className="block h-[34px] w-[145px] hover:opacity-80 md:mx-auto" isLogoWhite={isLogoWhite} />
          </Link>
        </div>

        {title && <h1 className={`flex w-full items-center font-butler text-[33px] leading-[35px] text-white md:justify-center ${titleHeight}`} dangerouslySetInnerHTML={{ __html: title }} />}
      </header>
    ) : undefined
  }, [hiddenHeader, title, isLogoWhite])

  const content = useMemo(() => {
    return isLoading ? (
      <div className="flex justify-center">
        <IconLoading />
      </div>
    ) : (
      children
    )
  }, [isLoading, children])

  return (
    <Wrapper className={`min-h-[100vh-58px] space-y-6 bg-cover bg-[center_top] bg-no-repeat ${className}`} style={bgExpand ? {} : style}>
      {bgExpand && <div className={`fixed top-0 left-0 right-0 bottom-0 z-10 bg-cover bg-[center_top] bg-no-repeat`} style={style}></div>}
      {!bgExpand && <div className="absolute top-0 left-0 right-0 z-10 hidden h-[200px] bg-slate-300 bg-cover bg-[center_top] bg-no-repeat md:block" style={{ backgroundImage: pcPannel }}></div>}

      {head}
      <div className="!mt-0 hidden h-10 md:block"></div>
      <div className="relative z-20 mx-auto flex w-full max-w-[689px] flex-1 flex-col">
        <div className="flex h-full w-full flex-1 flex-col">{content}</div>
        {buttons}
      </div>
    </Wrapper>
  )
}
