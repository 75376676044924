import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { cleanUrl } from '../../../pages/MyHealth'

export const useReplacePage = () => {
  const location = useLocation()
  const currentPath = location.pathname
  const navigate = useNavigate()

  const replacePage = () => {
    navigate(cleanUrl(currentPath), { replace: true })
  }

  return replacePage
}
