import { format, isAfter, isSameMonth, startOfMonth, subMonths } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocales } from '../../../locales'

import { IconLoading } from '../../Common'
import { iconHeartRate, textHeartRate } from './constants'

import { useHeartChartPage } from '../../../hooks/pages/heart-chart'
import { useHeartChartBarPage } from '../../../hooks/pages/heart-chart-bar'
import { useHeartChartLinePage } from '../../../hooks/pages/heart-chart-line'
import { convertTypeBarChart, convertTypeButton } from '../../../lib/maps/heart-chart-bar'
import { convertTypeBloodPressure } from '../../../lib/maps/heart-chart-line'
import { alertsFakeData, generateFakeDataHeartLine } from './fakeData'

import { CalanderScreen } from '../../../constants'
import {
  dataBarDrinkingIntroduction,
  dataBarSmokingIntroduction,
  dataBarStepsIntroduction,
  dataBloodPressureIntroduction,
  dataPieIntroduction,
  dataRateHeartsIntroduction,
} from '../../../constants/dataIntroductionHeartChart'
import { MyAdessoHeartChartStep } from '../../../constants/introduction'
import { useDailyTracking } from '../../../hooks/use-daily-tracking'
import { CustomCalendar } from '../../Calendar'
import { Alerts } from './Alerts'
import { ContentBarChart, typeBarChart } from './BarChart'
import { SubscribedEnum } from './dataUtils'
import HeartRateComponent from './HeartRate'
import { ContentLineChart } from './LineChart'
import { LineChartHeart } from './LineChartHeart'
import { PieChart } from './PieChart'
import { HeartChartWrapper } from './Wrap'

const DATE_FORMAT = 'yyyy-MM-dd'

type ComponentMapType = {
  [key: string]: React.ReactNode
}

interface DataRateHeart {
  subscribed: { [key in SubscribedEnum]?: any }[]
}

type Props = {
  isIntroduction?: boolean
  refs?: React.Ref<any>[]
}

export default function HeartChartMain({ isIntroduction = false, refs = [] }: Props) {
  const { t } = useLocales()
  const navigate = useNavigate()

  const {
    dataBarDrinkingResult,
    getDataHeartChartBarDrinking,
    dataBarSmokingResult,
    getDataHeartChartBarSmoking,
    dataBarStepsResult,
    getDataHeartChartBarSteps,
    dataBarSleepResult,
    getDataHeartChartBarSleep,
  } = useHeartChartBarPage()
  const { getDataPieResult, getDataHeartChartPie, dataRateGoalResult, getDataHeartChartRateGoal, dataStressTrackingResult, getDataHeartChartStressTracking } = useHeartChartPage()
  const { dataBloodPressureResult, getDataHeartChartBloodPressure, dataWeightResult, getDataHeartChartWeight } = useHeartChartLinePage()
  const { getCalendarHistories } = useDailyTracking()

  const [selectedButtonBloodPressure, setSelectedButtonBloodPressure] = useState(0)
  const [selectedButtonWeight, setSelectedButtonWeight] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)

  const [heartLineRateData, setHeartLineRateData] = useState<any[]>([])
  const [alertsData, setAlertsData] = useState<any[]>([])
  const [selectedButtonIndexLineHeart, setSelectedButtonIndexLineHeart] = useState(0)
  const [selectedButtonIndexBarChartDrinking, setSelectedButtonIndexBarChartDrinking] = useState(0)
  const [selectedButtonIndexBarChartSmoking, setSelectedButtonIndexBarChartSmoking] = useState(0)
  const [selectedButtonIndexBarChartSteps, setSelectedButtonIndexBarChartSteps] = useState(0)
  const [selectedButtonIndexBarChartSleep, setSelectedButtonIndexBarChartSleep] = useState(0)

  const [calendarDate, setCalendarDate] = useState(new Date())
  const [trackedDate, setTrackedDate] = useState<String[]>([])
  const [month, setMonth] = useState(new Date())
  const [dataHistoryCalendar, setDataHistoryCalendar] = useState<any[]>([])
  const [startCalendarDate, setStartCalendarDate] = useState(new Date())
  const [loadingCalendar, setLoadingCalendar] = useState(false)

  const dataRateHearts = isIntroduction ? dataRateHeartsIntroduction : dataRateGoalResult?.data

  const subscribedComponents = useMemo(() => {
    let objectSubscribed: Record<string, any> = {}

    dataRateHearts?.forEach((dataRateHeart) => {
      dataRateHeart.subscribed.forEach((item) => {
        objectSubscribed[item] = true
      })
    })

    return objectSubscribed
  }, [dataRateHearts])

  useEffect(() => {
    if (isIntroduction) return
    getDataHeartChartPie()
    getDataHeartChartRateGoal()
  }, [])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents[SubscribedEnum.STRESS_TRACKING]) {
      getDataHeartChartStressTracking()
    }
  }, [subscribedComponents])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents && subscribedComponents[SubscribedEnum.BLOOD_PRESSURE]) {
      const typeSelect = selectedButtonBloodPressure
      getDataHeartChartBloodPressure(convertTypeBloodPressure[typeSelect])
    }
  }, [selectedButtonBloodPressure, subscribedComponents])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents && subscribedComponents[SubscribedEnum.WEIGHT]) {
      const typeSelect = selectedButtonWeight
      getDataHeartChartWeight(convertTypeBloodPressure[typeSelect])
    }
  }, [selectedButtonWeight, subscribedComponents])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents && subscribedComponents[SubscribedEnum.DRINKING]) {
      const typeSelect = selectedButtonIndexBarChartDrinking
      getDataHeartChartBarDrinking(convertTypeBarChart[typeSelect], convertTypeButton[typeSelect])
    }
  }, [selectedButtonIndexBarChartDrinking, subscribedComponents])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents && subscribedComponents[SubscribedEnum.SLEEP]) {
      const typeSelect = selectedButtonIndexBarChartSleep
      getDataHeartChartBarSleep(convertTypeBarChart[typeSelect], convertTypeButton[typeSelect])
    }
  }, [selectedButtonIndexBarChartSleep, subscribedComponents])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents && subscribedComponents[SubscribedEnum.SMOKING]) {
      const typeSelect = selectedButtonIndexBarChartSmoking
      getDataHeartChartBarSmoking(convertTypeBarChart[typeSelect], convertTypeButton[typeSelect])
    }
  }, [selectedButtonIndexBarChartSmoking, subscribedComponents])

  useEffect(() => {
    if (isIntroduction) return
    if (subscribedComponents && subscribedComponents[SubscribedEnum.STEPS]) {
      const typeSelect = selectedButtonIndexBarChartSteps
      getDataHeartChartBarSteps(convertTypeBarChart[typeSelect], convertTypeButton[typeSelect])
    }
  }, [selectedButtonIndexBarChartSteps, subscribedComponents])

  useEffect(() => {
    setHeartLineRateData(generateFakeDataHeartLine())
  }, [selectedButtonIndexLineHeart])

  useEffect(() => {
    setAlertsData(alertsFakeData)
  }, [])

  useEffect(() => {
    if (isIntroduction) return
    if (isAfter(month, startCalendarDate) && dataHistoryCalendar.length) {
      return
    }

    const fetchCalendar = async () => {
      try {
        const newStartDate = subMonths(startOfMonth(startCalendarDate), 3)
        setLoadingCalendar(true)

        const data = await getCalendarHistories(format(newStartDate, DATE_FORMAT), format(new Date(), DATE_FORMAT)).catch()

        setLoadingCalendar(false)
        setStartCalendarDate(newStartDate)
        setDataHistoryCalendar(dataHistoryCalendar.concat(data?.data))
      } catch (error) {}
    }

    fetchCalendar()
  }, [month])

  useEffect(() => {
    if (isIntroduction) return
    if (!dataHistoryCalendar.length) {
      return
    }
    const archived = dataHistoryCalendar
      .filter((m: any) => {
        return isSameMonth(month, new Date(m.date))
      })
      .map((d: any) => d.date)
    setTrackedDate([...archived])
  }, [dataHistoryCalendar, month, calendarDate])

  const BloodPressure = useMemo(() => {
    const isLoading = dataBloodPressureResult?.isLoading

    return (
      <div ref={refs[MyAdessoHeartChartStep.HeartDimensionsTracking] ?? null}>
        <ContentLineChart
          isLoading={isIntroduction ? false : isLoading}
          selectedButtonIndex={selectedButtonBloodPressure}
          data={isIntroduction ? dataBloodPressureIntroduction : dataBloodPressureResult?.data || []}
          colorButton={'#FF3399'}
          setSelectedButtonIndex={setSelectedButtonBloodPressure}
          title={t('heartChartMain.title.blood')}
          width={360}
          height={290}
        />
      </div>
    )
  }, [dataBloodPressureResult, selectedButtonBloodPressure])

  const Weight = useMemo(() => {
    const isLoading = dataWeightResult?.isLoading

    return (
      <ContentLineChart
        isLoading={isIntroduction ? false : isLoading}
        selectedButtonIndex={selectedButtonWeight}
        data={isIntroduction ? [] : dataWeightResult?.data || []}
        colorButton={'#F26E57'}
        setSelectedButtonIndex={setSelectedButtonWeight}
        title={t('heartChartMain.title.weight')}
        width={360}
        height={290}
      />
    )
  }, [dataWeightResult, selectedButtonWeight])

  const barChartSmoking = useMemo(() => {
    return (
      <ContentBarChart
        width={353}
        height={170}
        data={isIntroduction ? dataBarSmokingIntroduction : dataBarSmokingResult?.data || []}
        color={'#FF3399'}
        title={t('heartChartMain.title.smoking')}
        setSelectedButtonIndexBarChart={setSelectedButtonIndexBarChartSmoking}
        type={typeBarChart.normal}
        selectedButtonIndex={selectedButtonIndexBarChartSmoking}
        isLoading={isIntroduction ? false : dataBarSmokingResult?.isLoading}
      />
    )
  }, [dataBarSmokingResult?.data])

  const barChartDrinking = useMemo(() => {
    return (
      <ContentBarChart
        width={353}
        height={170}
        data={isIntroduction ? dataBarDrinkingIntroduction : dataBarDrinkingResult?.data || []}
        color={'#FF3399'}
        title={t('heartChartMain.title.drinking')}
        setSelectedButtonIndexBarChart={setSelectedButtonIndexBarChartDrinking}
        type={typeBarChart.normal}
        selectedButtonIndex={selectedButtonIndexBarChartDrinking}
        isLoading={isIntroduction ? false : dataBarDrinkingResult?.isLoading}
      />
    )
  }, [dataBarDrinkingResult?.data])

  const barChartSteps = useMemo(() => {
    return (
      <ContentBarChart
        width={353}
        height={170}
        data={isIntroduction ? dataBarStepsIntroduction : dataBarStepsResult?.data || []}
        color={'#FF567A'}
        title={t('heartChartMain.title.steps')}
        setSelectedButtonIndexBarChart={setSelectedButtonIndexBarChartSteps}
        type={typeBarChart.special}
        selectedButtonIndex={selectedButtonIndexBarChartSteps}
        isLoading={isIntroduction ? false : dataBarStepsResult?.isLoading}
      />
    )
  }, [dataBarStepsResult?.data])

  const barChartSleep = useMemo(() => {
    return (
      <ContentBarChart
        width={353}
        height={170}
        data={isIntroduction ? [] : dataBarSleepResult?.data || []}
        color={'#B7005E'}
        title={t('heartChartMain.title.sleep')}
        setSelectedButtonIndexBarChart={setSelectedButtonIndexBarChartSleep}
        type={typeBarChart.normal}
        selectedButtonIndex={selectedButtonIndexBarChartSleep}
        isLoading={isIntroduction ? false : dataBarSleepResult?.isLoading}
      />
    )
  }, [dataBarSleepResult?.data])

  const stressTracking = useMemo(() => {
    return (
      <HeartRateComponent
        values={isIntroduction ? [] : dataStressTrackingResult?.data?.data || []}
        title={t('heartChartMain.title.stressTracking')}
        text={t('heartChartMain.text.stressTracking')}
        icon={'bg-stressTrackingIcon'}
        color={'#B7005E'}
      />
    )
  }, [dataStressTrackingResult?.data?.data])

  const RateHeart = useMemo(() => {
    const isLoading = dataRateGoalResult?.isLoading
    const componentMap: ComponentMapType = {
      steps: barChartSteps,
      blood: BloodPressure,
      smoking: barChartSmoking,
      drinking: barChartDrinking,
      sleep: barChartSleep,
      stressTracking: stressTracking,
      weight: Weight,
    }

    if (isIntroduction ? false : isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading />
        </div>
      )
    }

    return (
      <section className="mt-3 ">
        {dataRateHearts?.map((rateHeart) => {
          const { label, data, color, subscribed } = rateHeart
          const subscribedSet = new Set(subscribed)

          return (
            <div key={label} className="mb-7 grid grid-cols-1 gap-3">
              <HeartRateComponent values={data} title={label.toUpperCase()} text={t(textHeartRate[label])} icon={iconHeartRate[label]} color={color} />
              {Array.from(subscribedSet).map((subscribedItem) => {
                const componentKey = Object.keys(componentMap).find((key) => {
                  return t(`heartChartMain.title.${key}`).toUpperCase() === subscribedItem
                })
                return componentKey ? componentMap[componentKey] : null
              })}
            </div>
          )
        })}
      </section>
    )
  }, [dataRateHearts, barChartSteps, BloodPressure, barChartSmoking, barChartDrinking, barChartSleep, stressTracking, Weight])

  const onChangeMonth = async (start: Date, end: Date) => {
    setMonth(start)
    setCalendarDate(start)
  }

  const onSelectedDate = (date: string) => {
    let url = `/daily-tracking?date=${date}`
    navigate(url)
  }

  const calendar = useMemo(() => {
    if (isIntroduction ? false : loadingCalendar) {
      return (
        <div className="flex justify-center">
          <IconLoading />
        </div>
      )
    }

    return (
      <>
        <CustomCalendar date={calendarDate} trackedDate={trackedDate} month={month} screen={CalanderScreen.DTC} onChangeMonth={onChangeMonth} onSelectedDate={onSelectedDate} />
      </>
    )
  }, [calendarDate, month, trackedDate, loadingCalendar])

  const triangleStyle = {
    width: 0,
    height: 0,
    borderLeft: '24px solid transparent',
    borderRight: '24px solid transparent',
    cursor: 'pointer',
    marginRight: '5px',
    borderBottom: '37px solid white',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  }

  return (
    <HeartChartWrapper className="md:px-20">
      <h4 className="mb-3 font-['Butler'] text-[24px] font-[400] leading-[34px]">{t('mainMenuAndSection.heartChart')}</h4>

      <div className="mb-2 flex items-center">
        <p className="font-['Proxima Nova'] pr-2 text-[15px] font-[600] leading-[21px]">{t('mainMenuAndSection.yourWeekAtAGlanceTitle')}</p>

        <div
          className="relative flex h-[29px] w-[29px] cursor-pointer items-center justify-center rounded-full bg-[#FF3399] text-[20px] font-[500] text-white"
          onClick={() => setShowTooltip(!showTooltip)}
        >
          i
        </div>
      </div>

      {showTooltip && (
        <div className="  absolute w-[346px] flex-col ">
          <div className=" relative left-[53%] mb-[-1px] transform" style={triangleStyle}></div>

          <div className="absolute  z-10  rounded-[25px]  bg-white p-2 text-[17px] font-[500] leading-[21px] text-black shadow-2xl">
            <div className="flex">
              <p className="py-3 pl-3">{t('heartChartMain.toolTip')}</p>

              <div className="w-[10%]">
                <button onClick={() => setShowTooltip(false)} className="h-[29px] w-[29px] rounded-full bg-[#BFBFBE]  bg-i-close bg-contain bg-center bg-no-repeat" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div ref={refs[MyAdessoHeartChartStep.Chart] ?? null}>
        <PieChart size={350} data={isIntroduction ? dataPieIntroduction : getDataPieResult?.data || []} isLoading={isIntroduction ? false : getDataPieResult?.isLoading === true} />
      </div>

      <p
        className="font-['Proxima Nova'] my-3 cursor-pointer text-[14px] font-[400] leading-[34px] text-[#950057] underline "
        onClick={() => {
          navigate('/heart-chart/beats-progress')
        }}
      >
        {t('heartChartMain.myProgressPageOverview')}
      </p>

      <div className={`${isIntroduction ? 'block' : 'hidden'}`}>
        {/* <div ref={refs[MyAdessoHeartChartStep.YourHeart] ?? null}> */}
        <LineChartHeart
          width={350}
          height={200}
          data={heartLineRateData}
          color={'#FF3399'}
          title={t('heartChartMain.title.yourHeart')}
          setSelectedButtonIndexLineHeart={setSelectedButtonIndexLineHeart}
        />
        {/* </div> */}
        {/* <div ref={refs[MyAdessoHeartChartStep.Alerts] ?? null}> */}
        <Alerts data={alertsData} title={t('heartChartMain.title.alerts')} />
        {/* </div> */}
      </div>

      {RateHeart}
      <div className="flex h-[261px] justify-center ">
        <div className="w-full">{calendar}</div>
      </div>
    </HeartChartWrapper>
  )
}
