import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'
import { useLocalStorage } from '../use-local-storage'

import { AdessoResult } from '../../models'
import { postHeartScore } from '../../lib/request/post-heartscore'
import { getCheckHeartSym, getHeartScoreQuestions } from '../../lib/request/get-heartscore-questions'

import { mapDataHeartScoreQuestions } from '../../lib/maps/heart-score-questions'

import { Quizess } from '../../models/question'
import { postHeartScoreStep } from '../../lib/request/post-heartscore-step'

type Result = {
  success: boolean
}

type Response = {
  quizess?: Quizess[]
  step?: any
}

export function useHeartScorePage() {
  const [postDataResult, setPostDataResult] = useState<AdessoResult<Result>>()
  const [getDataResult, setGetDataResult] = useState<AdessoResult<Response>>()
  const [getDataCheckHeartSymptoms, setGetDataCheckHeartSymptoms] = useState<AdessoResult<boolean>>()

  const auth = useAuth()

  const getCheckHeartSymptoms = useCallback(async () => {
    setGetDataCheckHeartSymptoms({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getCheckHeartSym()

        if (res.data) {
          setGetDataCheckHeartSymptoms({
            isLoading: false,
            data: res.data.isHeartSymptoms,
            error: undefined,
          })
        } else {
          setGetDataCheckHeartSymptoms({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setGetDataCheckHeartSymptoms({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get HeartScore Error'),
      })
    }
  }, [])

  const getDataHeartScore = useCallback(async () => {
    setGetDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartScoreQuestions()
        if (res.data) {
          const quizess = mapDataHeartScoreQuestions(res.data.quizess)
          const step = res.data.step
          setGetDataResult({
            isLoading: false,
            data: { quizess, step },
            error: undefined,
          })
        } else {
          setGetDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setGetDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get HeartScore Error'),
      })
    }
  }, [])

  const submitHeartScore = useCallback(async (params: object) => {
    setPostDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await postHeartScore(params)

        if (res.data) {
          const { setItemLS } = useLocalStorage()
          setItemLS('resultHeartScore', res.data)
          auth.updateUserLocal({ screen: [] })
          setPostDataResult({
            isLoading: false,
            data: { success: true },
            error: undefined,
          })
          auth.getMeResult()
        } else {
          setPostDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post HeartScore Error'),
      })
    }
  }, [])

  const submitHeartScoreStep = useCallback(async (params: any) => {
    try {
      auth.setTokenApi()
      await postHeartScoreStep(params)
    } catch (error) {
      // error go here
    }
  }, [])

  return { getDataCheckHeartSymptoms, getCheckHeartSymptoms, getDataResult, postDataResult, getDataHeartScore, submitHeartScore, submitHeartScoreStep }
}
