import { useState, useMemo, useCallback, useEffect, MutableRefObject, useRef } from 'react'
import { MyAdessoJourneyContent } from '../../../MyAdessoJourney'
import { ButtonCommon, ButtonStyles } from '../../../Common/Button'
import { BoxText, ArrowType } from '../../../Introduction/BoxText'

import { Session } from '../../../../models/session'
import { useLocales } from '../../../../locales'
import { MAJSession } from '../../../../pages/MyAdessoJourney/Session'
import { GoalTrackingConfigurationContent } from '../../../GoalTrackingConfiguration'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { MyAdessoJourneyStep } from '../../../../constants/introduction'

type Props = {
  sessions?: Session[]
  isLoading?: boolean
  nextScreen: any
  closeScreen: any
}

export const MyAdessoJourneyIntroductionScreen = ({ sessions, isLoading, nextScreen, closeScreen }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const { t } = useLocales()
  const [step, setStep] = useState<MyAdessoJourneyStep>(MyAdessoJourneyStep.WelcomeTour)
  const [stepSession, setStepSession] = useState(1)
  const boxRef = useRef<HTMLDivElement>(null)
  const refs = Object.values(MyAdessoJourneyStep).reduce((acc: any, step, index) => {
    if (!isNaN(Number(step))) {
      acc[step] = useRef<HTMLElement>(null)
    }

    return acc
  }, {})

  const [currentRef, setCurrentRef] = useState<any>(refs[MyAdessoJourneyStep.WelcomeTour])
  const positionStep = {
    WelcomeTour: 'top',
    Session1: '',
    IntroductionButton: '',
    Session2: '',
    Session3: '',
    IntroductionForm: '',
    GoalTrackingConfig: '',
  }
  const { setShowFeedback } = useHeaderContext()

  const handleNextStep = useCallback((stepCurent: MyAdessoJourneyStep) => {
    setStep(stepCurent + 1)
  }, [])

  useEffect(() => {
    setShowFeedback(false)
  }, [])

  useEffect(() => {
    // 80 header height
    // 47 Arrow height -10 (bottom)
    // 51 Next Button Height

    if (step === MyAdessoJourneyStep.WelcomeTour) {
      window.scrollTo({
        behavior: 'smooth',
        top: 50,
      })
      if (boxRef?.current?.style) {
        boxRef.current.style.top = '100px'
      }
      return
    }
    if (step === MyAdessoJourneyStep.IntroductionButton) {
      if (boxRef?.current?.style) {
        boxRef.current.style.bottom = '280px'
        boxRef.current.style.top = 'unset'
        boxRef.current.style.height = 'unset'
        window.scrollTo(0, document.body.scrollHeight)
      }
      return
    }

    if (!currentRef?.current) {
      // Center vertical popup
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        const boxTop = Math.max(0, window.innerHeight / 2 - (boxRef.current.children[0]?.clientHeight + 51) / 2) - 80
        boxRef.current.style.top = boxTop + 'px'
      }
      return
    }

    currentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    const scrollHeight = currentRef.current.scrollHeight
    const scrollY = window.scrollY
    const { bottom } = currentRef?.current.getBoundingClientRect()

    if (boxRef?.current) {
      switch (step) {
        case MyAdessoJourneyStep.Session1:
        case MyAdessoJourneyStep.Session2:
        case MyAdessoJourneyStep.Session1:
          boxRef.current.style.top = Math.round(bottom - 80) + 'px'
          break

        case MyAdessoJourneyStep.IntroductionForm:
          boxRef.current.style.top = Math.round(bottom - 80) + 'px'
          break

        case MyAdessoJourneyStep.GoalTrackingConfig:
          boxRef.current.style.top = Math.round(bottom - 80) + 'px'
          break

        default:
          boxRef.current.style.top = Math.round(bottom + scrollY - 80) + 'px'
          break
      }
    }
  }, [step])

  const handleButtonNext = () => {
    handleNextStep(step)
    const stepCurrent = step
    const nextStep = stepCurrent + 1

    switch (nextStep) {
      case MyAdessoJourneyStep.IntroductionButton:
      case MyAdessoJourneyStep.Session1:
        setStepSession(1)
        break
      case MyAdessoJourneyStep.Session2:
        setStepSession(2)
        break
      case MyAdessoJourneyStep.Session3:
        setStepSession(3)
        break
      case MyAdessoJourneyStep.IntroductionForm:
        setStepSession(4)
        break
      default:
        break
    }
    setCurrentRef(refs[nextStep])
  }

  const BoxTextWelcomeTour = (className: any) => {
    const welcomeTourLine1 = t('introduction.myAdessoJourney.text.WelcomeTour.line1')
    const welcomeTourLine2 = t('introduction.myAdessoJourney.text.WelcomeTour.line2')
    const welcomeTourLine3 = t('introduction.myAdessoJourney.text.WelcomeTour.line3')
    const lines = [welcomeTourLine1, welcomeTourLine2, welcomeTourLine3]

    return (
      <div className={`relative max-w-[855px] rounded-[15px] bg-white p-5 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] ${className}`}>
        <p className="mb-4 text-[20px] font-bold leading-[21px]">{t('introduction.myAdessoJourney.title.WelcomeTour')}</p>
        <div className="text-[17px] font-medium leading-[21px]">
          {lines.map((line, index) => (
            <p key={index}>
              {line}
              {index !== lines.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </p>
          ))}
        </div>
      </div>
    )
  }

  const contentMyAdessoJourneyStep = useMemo(() => {
    switch (step) {
      case MyAdessoJourneyStep.WelcomeTour:
        return (
          <div className="relative">
            <BoxTextWelcomeTour className={`${positionStep.WelcomeTour}`} />
          </div>
        )

      case MyAdessoJourneyStep.Session1:
        return <BoxText className={`${positionStep.Session1}`} text={t('introduction.myAdessoJourney.text.Session1')} />

      case MyAdessoJourneyStep.IntroductionButton:
        return <BoxText arrow={ArrowType.BottomCenters} arrowClass=" !top-[95%]" className="" text={t('introduction.myAdessoJourney.text.IntroductionButton')} />

      case MyAdessoJourneyStep.Session2:
        return <BoxText className={`${positionStep.Session2}`} text={t('introduction.myAdessoJourney.text.Session2')} />
      case MyAdessoJourneyStep.Session3:
        return <BoxText className={`${positionStep.Session3}`} text={t('introduction.myAdessoJourney.text.Session3')} />
      case MyAdessoJourneyStep.IntroductionForm:
        return <BoxText className={`${positionStep.IntroductionForm}`} text={t('introduction.myAdessoJourney.text.IntroductionForm')} />
      case MyAdessoJourneyStep.GoalTrackingConfig:
        return <BoxText className={`${positionStep.GoalTrackingConfig} !max-w-[670px]`} text={t('introduction.myAdessoJourney.text.GoalTrackingConfig')} />
      default:
        return <></>
    }
  }, [step])

  const buttonNext = useMemo(() => {
    const marginBottom = step === MyAdessoJourneyStep.WelcomeTour ? 'scroll-mb-0' : 'scroll-mb-80'
    return step === MyAdessoJourneyStep.GoalTrackingConfig ? (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        scrollMarginTop="scroll-mt-0"
        scrollMarginBottom="scroll-mt-0"
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        onClick={nextScreen}
      />
    ) : (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        scrollMarginBottom={marginBottom}
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        className={`${step === MyAdessoJourneyStep.IntroductionButton ? 'mt-[480px]' : ''}`}
        onClick={() => handleButtonNext()}
      />
    )
  }, [step, handleNextStep, nextScreen])

  const buttonClose = useMemo(() => {
    return (
      <button onClick={closeScreen} className="fixed right-5 top-7 z-50 flex cursor-pointer  items-center justify-center bg-transparent text-white">
        <p className="pr-2 font-['Proxima_Nova'] text-[14px] font-[600] leading-[17px] tracking-[0.15px]">CLOSE DEMO</p>
        <div className=" h-[29px] w-[30px] bg-[url('assets/images/icon/i-button-close.svg')] bg-no-repeat" />
      </button>
    )
  }, [step])

  const introMemo = useMemo(() => {
    let content

    if (step !== MyAdessoJourneyStep.WelcomeTour && step !== MyAdessoJourneyStep.GoalTrackingConfig) {
      content = <MAJSession introduction sessionIntroduction={stepSession} />
    } else if (step === MyAdessoJourneyStep.WelcomeTour) {
      content = <MyAdessoJourneyContent sessions={sessions} isLoading={isLoading} />
    } else if (step === MyAdessoJourneyStep.GoalTrackingConfig) {
      content = <GoalTrackingConfigurationContent jumpTo={refs[MyAdessoJourneyStep.GoalTrackingConfig]} isIntroduction />
    }

    return <div>{content}</div>
  }, [step, currentRef, sessions, isLoading, stepSession])

  return (
    <div className="relative h-full w-full pb-40">
      {introMemo}

      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] bg-color-overlay bg-opacity-50"></div>
      {buttonClose}
      <div ref={boxRef} className="absolute left-0 top-[-74px] z-50 flex h-[calc(100%+74px)] w-full flex-col items-center space-y-4 px-4 font-['Proxima_Nova']">
        {step === MyAdessoJourneyStep.IntroductionButton ? (
          <>
            {buttonNext}
            {contentMyAdessoJourneyStep}
          </>
        ) : (
          <>
            {contentMyAdessoJourneyStep}

            {buttonNext}
          </>
        )}
      </div>
    </div>
  )
}
