import { useMemo, ChangeEvent } from 'react'
import { Question, FieldTypes } from '../../../models/'

type Props = {
  value?: string
  question: Question
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  fieldType: FieldTypes
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const RadioField = ({ question, disabled, value, fieldType, onChange }: Props) => {
  const isDarkType: boolean = fieldType === FieldTypes.Dark

  const radioList = useMemo(() => {
    return question.options
      ? question.options.map((option, index) => {
          const idRadio = `${question.name}-${index}`
          const background = isDarkType ? `bg-[url('assets/images/icon/i-radio__uncheck.svg')]` : `bg-[url('assets/images/icon/i-radio__uncheck__white.svg')]`
          return (
            <div key={idRadio}>
              <input disabled={disabled} id={idRadio} type="radio" name={question.name} className="peer hidden" value={option.label} onChange={onChange} checked={value === option.label} />

              <label
                htmlFor={idRadio}
                className={`flex cursor-pointer bg-[length:21px_auto] bg-[top_1px_left] bg-no-repeat pt-[1px] pl-7 text-ma-md peer-checked:bg-[url('assets/images/icon/i-radio__checked.svg')] ${background}`}
              >
                {option.label}
              </label>
            </div>
          )
        })
      : null
  }, [isDarkType, value, question])

  return <div className="space-y-3">{radioList}</div>
}
