import React, { useCallback, useState } from 'react'
import { AdessoError } from '../lib/error'
import { deleteSupplementRx, getSupplementRx, getSupplementRxById, postSupplementRx, putSupplementRx } from '../lib/request'
import { AdessoResult } from '../models'
import { useAuth } from './use-auth'

type TSupplementRxResponse = {
  supplements?: any
}

export function useSupplementRxPage() {
  const [supplementRxResult, setSupplementRxResult] = useState<AdessoResult<TSupplementRxResponse>>()
  const [supplementRxResultById, setSupplementRxResultById] = useState<AdessoResult<TSupplementRxResponse>>()

  const auth = useAuth()

  const useGetSupplementRx = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setSupplementRxResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getSupplementRx()
        if (res.success) {
          setSupplementRxResult({
            data: { supplements: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setSupplementRxResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setSupplementRxResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Supplement Rx error'),
      })
      throw new AdessoError('Get Supplements Rx Failed')
    }
  }, [])

  const useGetByIdSupplementRx = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setSupplementRxResultById({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getSupplementRxById(id)
        if (res.success) {
          setSupplementRxResultById({
            data: { supplements: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setSupplementRxResultById({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setSupplementRxResultById({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Supplement Rx By Id error'),
      })
      throw new AdessoError('Get Supplements Rx By Id Failed')
    }
  }, [])

  const usePostSupplementRx = useCallback(async (dataCreate: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await postSupplementRx(dataCreate)
      }
    } catch (error) {
      throw new AdessoError('Create Supplements Rx Failed')
    }
  }, [])

  const usePutSupplementRx = useCallback(async (id: number, dataUpdate: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await putSupplementRx(id, dataUpdate)
      }
    } catch (error) {
      throw new AdessoError('Update Supplements Rx Failed')
    }
  }, [])

  const useDeleteSupplementRx = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        await deleteSupplementRx(id)
      }
    } catch (error) {
      throw new AdessoError('Delete Supplements Rx Failed')
    }
  }, [])

  return { supplementRxResult, supplementRxResultById, useGetSupplementRx, useGetByIdSupplementRx, usePostSupplementRx, usePutSupplementRx, useDeleteSupplementRx }
}
