import { useState, useCallback, useMemo } from 'react'
import { ScreenHeartAssessment02 } from './HeartAssessment02'
import { SessionButtons } from '../../layout'
import { Session08Wrapper } from '../Wrapper'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

enum Screen {
  HeartAssessment01,
  HeartAssessment02,
  HeartAssessment03,
}

export const ScreenHeartAssessment = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.HeartAssessment03 : step.sub_step)

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 1, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.HeartAssessment01) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.HeartAssessment03) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: Screen) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenHeartAssessment = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <Session08Wrapper buttons={buttons}>
            <div className="session-style">
              <h4>Manage your risk factors by knowing your numbers.</h4>
              <p>We’ve learned about the risk factors that effect your heart and began to understand what goes on when we don’t address those risk factors.</p>
              <p>In this session, we’ll focus on the numbers we should be tracking that will help us determine whether we are still at risk for heart disease.</p>
            </div>
          </Session08Wrapper>
        )
      case 1:
        return <ScreenHeartAssessment02 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />

      case 2:
        return (
          <Session08Wrapper buttons={buttons}>
            <div className="session-style">
              <h4>Know Your Numbers</h4>

              <picture className={``}>
                <source srcSet={`/images/my-adresso-journey/session/08/img_001.webp`} type="image/webp" />
                <img src={`/images/my-adresso-journey/session/08/img_001.png`} alt="Description" />
              </picture>
              <p>
                {`One of the first steps to taking ownership of your heart health journey is to know your numbers, which means to understand and monitor the test results that matter most to your heart’s well-being.`}
              </p>
              <p>Think of health numbers like you would your bank statements. If you’re trying to have more money, you have to pay attention to the numbers.</p>
              <p>Likewise, if you want to be healthier, there are numbers you’ll want to better understand and track.</p>
              <p>
                {`Looking at your bank transactions can be really hard, so can looking at your heart numbers. We have to move past our initial feelings of shame, fear, and anxiety to do what’s best for ourselves.`}
              </p>
              <p>Let’s begin by understanding what numbers to look at and what they mean.</p>
            </div>
          </Session08Wrapper>
        )
    }
  }, [screen, buttons, handleBackScreen, handleNextScreen, showModal, handleUpdate])

  return <>{screenHeartAssessment}</>
}
