import axios from 'axios'

import { AdessoError } from '../../lib/error'
import { PramsLifeStyle } from '../../models'

type RequestResponse = {
  success: boolean
  data: PramsLifeStyle | undefined
  message: string
}

export async function postSubmitLifeStyle(params: PramsLifeStyle): Promise<RequestResponse> {
  const { data, status } = await axios.post('/lifestyles', params)

  if (status !== 200) {
    throw new AdessoError(`Request submit LifeStyle error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
