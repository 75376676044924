import { useState, useMemo, useCallback } from 'react'
import { Session09Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion09Questions } from '../../../../../lib/data/session-09'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHRQuestions = [sesstion09Questions[0]]

type Props = {
  isLoading?: boolean
  showModal: () => void
  backScreen: () => void
  onSubmit: (params: object) => void
  answers: any
}

export const ScreenHeartReflection = ({ showModal, backScreen, onSubmit, isLoading, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHRQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onSubmit(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons isLoading={isLoading} styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [isLoading, backScreen, checkSubmit, showModal])

  const screenHeartReflection = useMemo(() => {
    return (
      <Session09Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6">
            <h4 className="text-[18px] font-bold leading-[23px]">Take a moment to reflect.</h4>
            {ssHRQuestions.map((item) => {
              return (
                <FormField key={`ss01_${item.name}`} question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session09Wrapper>
    )
  }, [formik, buttons, isError])

  return <>{screenHeartReflection}</>
}
