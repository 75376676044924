import { format, parse } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useContextMedicationRx } from '../../../context/medicationRxContext'
import { ButtonMyHealth } from '../Button'
import WrapperMyHealth from '../MyHealthWrapper'
import IOSPicker from '../WheelPicker'

type Props = {
  prevScreen?: any
  nextScreen?: () => void
}

const day: any = {
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
  sun: false,
}

const SetAlarm = ({ nextScreen, prevScreen }: Props) => {
  const { addDataMedicationRx, addDataMedication } = useContextMedicationRx()

  const [alarm, setAlarm] = useState<any>(day)
  const [selectedTime, setSelectedTime] = useState<string>('')

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(event.target.value)
  }

  const handleCheckbox = (item: any) => {
    setAlarm((prev: any) => {
      const clar = { ...prev }
      clar[item] = !clar[item]
      return clar
    })
  }

  const handleSaveAlarm = () => {
    const hasIsAlarm = Object.values(alarm).some((value) => value === true)

    const parsedTime = parse(selectedTime, 'HH:mm', new Date())
    const formattedTime = format(parsedTime, 'HH:mm:ss')

    addDataMedication({ ...addDataMedicationRx, is_alarm: hasIsAlarm, alarm: { ...alarm, alarm_at: formattedTime } })
    prevScreen()
  }

  useEffect(() => {
    if (addDataMedicationRx?.alarm) {
      delete addDataMedicationRx?.alarm?.id
      const { alarm_at, ...daysData } = addDataMedicationRx?.alarm

      const daysObj = Object.keys(daysData).reduce((acc: any, key) => {
        acc[key] = daysData[key]
        return acc
      }, {})
      const parsedTime = parse(alarm_at, 'HH:mm:ss', new Date())
      const formattedTime = format(parsedTime, 'HH:mm')
      setSelectedTime(formattedTime)
      setAlarm(daysObj)
    }
  }, [])

  const setYourAlarm = useMemo(() => {
    return (
      <div className="">
        <h5 className="text-center text-[16px] font-medium uppercase">Set Your Alarm</h5>
        {/* <form> */}
        <div className="flex flex-col">
          <div>
            <div className="block sm:hidden">
              <IOSPicker dataSetAlarm={addDataMedicationRx?.alarm?.alarm_at} setAlarm={(alarm: any) => setSelectedTime(alarm)} />
            </div>
            <form className="mx-auto hidden max-w-[8rem] sm:block">
              <label htmlFor="time" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"></label>
              <div className="relative hidden sm:block">
                <input
                  type="time"
                  id="time"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  // min="09:00"
                  // max="18:00"
                  value={selectedTime}
                  onChange={handleTimeChange}
                  // required
                />
              </div>
            </form>
          </div>
          <div className="">
            <h6 className="text-[16px] font-semibold">Days</h6>
            <div className="mt-2">
              <ul className="flex flex-row justify-between gap-1">
                {Object.keys(day).map((item, index) => (
                  <li className="" id={`${item}_${index}`} onChange={() => handleCheckbox(item)}>
                    <input type="checkbox" id={item} value="" className="peer hidden" required checked={alarm[item]} onChange={() => {}} />
                    <label
                      htmlFor={item}
                      className="inline-flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[100%] border-[1px] border-[#000] bg-[#FFF] p-3 text-[#000] hover:bg-gray-50 hover:text-gray-600 peer-checked:border-color-bg-primary peer-checked:bg-color-bg-primary peer-checked:text-[#FFF] dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:peer-checked:text-gray-300"
                    >
                      <div className="">
                        <div className="text-[11px] font-medium uppercase">{item}</div>
                      </div>
                    </label>
                  </li>
                ))}

                {/* {day.map((item, index: number) => {
                    return (
                      <li className="" id={`${item?.label}_${index}`} onChange={() => handleCheckbox(item, index)}>
                        <input type="checkbox" id={item?.label} value="" className="peer hidden" required checked={alarm?.[index]?.value} />
                        <label
                          htmlFor={item?.label}
                          className="inline-flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[100%] border-[1px] border-[#000] bg-white p-3 text-[#000] hover:bg-gray-50 hover:text-gray-600 peer-checked:border-color-bg-primary peer-checked:bg-color-bg-primary peer-checked:text-[#FFF] dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:peer-checked:text-gray-300"
                        >
                          <div className="">
                            <div className="text-[11px] font-medium uppercase">{item?.label}</div>
                          </div>
                        </label>
                      </li>
                    )
                  })} */}
              </ul>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
    )
  }, [alarm, selectedTime, addDataMedicationRx, addDataMedicationRx?.alarm?.alarm_at])

  const button = useMemo(() => {
    const hasIsAlarm = Object.values(alarm).some((value) => value === true)
    const alarmTime = selectedTime ? true : false
    const checkSave = hasIsAlarm && alarmTime

    return (
      <div className="flex flex-row gap-3">
        <ButtonMyHealth nameButton="Cancel" bgButton="bg-color-text-secondary" prevScreen={prevScreen} />
        <ButtonMyHealth nameButton="Save" bgButton="bg-color-primary" className={`px-[49px] ${!checkSave ? 'cursor-not-allowed opacity-25' : ''}`} prevScreen={handleSaveAlarm} disabled={!checkSave} />
      </div>
    )
  }, [selectedTime, alarm, addDataMedicationRx])

  return (
    <WrapperMyHealth title="medicationRx" prevScreen={prevScreen} button={button} boxShadow={true}>
      {setYourAlarm}
    </WrapperMyHealth>
  )
}

export default SetAlarm
