import { useMemo } from 'react'

type Props = {
  title: string
  handleClick: () => void
  className?: string
}

export const ButtonRx = ({ handleClick, title, className }: Props) => {
  const buttonRx = useMemo(() => {
    return (
      <button className={`flex h-[51px] w-full items-center justify-center rounded-[25px] bg-[#950057] ${className}`} onClick={handleClick}>
        <p className='font-["Proxima Nova"] text-base font-medium uppercase leading-5 text-white'>{title}</p>
      </button>
    )
  }, [title])

  return <>{buttonRx}</>
}
