export type TDataMyHeart = {
  [_: string]: string
}

export const dataMyHeart = [
  {
    statisticsIcon: 'i-blood_pressure',
    statistics: '160',
    unit: '90',
    nameStatistics: 'blood pressure',
    progressIcon: 'i-arrow_reduce',
  },
  {
    statisticsIcon: 'i-weight',
    statistics: '194',
    unit: 'LB',
    nameStatistics: 'weight',
    progressIcon: 'i-arrow_increase',
  },
  {
    statisticsIcon: 'i-sleep',
    statistics: '7.5',
    unit: '',
    nameStatistics: 'House of sleep',
    progressIcon: 'i-arrow_increase',
  },
]
