import {
  add,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  parse,
  parseISO,
  startOfMonth,
  startOfToday,
  startOfWeek,
  subMonths,
} from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useBoolean } from '../../hooks/use-boolean'
import { CalanderScreen } from '../../constants'

interface Props {
  [_: string]: any
  styles?: any
  onSelectedDate?: any
  togglePopover?: boolean
  date?: Date
  month?: Date
  trackedDate?: String[]
  screen?: number
  onClosed?: () => void
}

const DATE_FORMAT = 'yyyy-MM-dd'

export const CustomCalendar = ({ trackedDate, onSelectedDate, togglePopover, month, date = new Date(), onChangeMonth, onClosed, screen }: Props) => {
  const today = startOfToday()
  const selectedDate = date ? date : month ? startOfMonth(month) : today
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7']
  const [currMonth, setCurrMonth] = useState(format(selectedDate || today, 'MMM-yyyy'))

  useEffect(() => {
    setCurrMonth(format(selectedDate || today, 'MMM-yyyy'))
  }, [selectedDate])

  let firstDayOfMonth = parse(currMonth, 'MMM-yyyy', new Date())
  const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 })
  const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 })

  const isPopoverOpen = useBoolean()
  const isArrowRight = useBoolean(true)
  const isArrowLeft = useBoolean(true)

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  })

  const getPrevMonth = (event: React.MouseEvent) => {
    event.preventDefault()
    if (!isPopoverOpen.value && screen !== CalanderScreen.DTC) {
      isPopoverOpen.onToggle()
      return
    }
    const oneYearOneMonthAgo = subMonths(today, 13)
    const dayCurrentMonth = new Date(firstDayOfPrevMonth)
    const before = isBefore(oneYearOneMonthAgo, dayCurrentMonth)
    if (before) {
      setCurrMonth(format(firstDayOfPrevMonth, 'MMM-yyyy'))
    }
    onChangeMonth(firstDayOfPrevMonth, endOfMonth(firstDayOfPrevMonth))
  }

  const getNextMonth = (event: React.MouseEvent) => {
    event.preventDefault()
    if (!isPopoverOpen.value && screen !== CalanderScreen.DTC) {
      isPopoverOpen.onToggle()
      return
    }
    const dayCurrentMonth = new Date(today)
    const dateChoice = new Date(firstDayOfNextMonth)
    const after = isAfter(dayCurrentMonth, dateChoice)
    if (after) {
      setCurrMonth(format(firstDayOfNextMonth, 'MMM-yyyy'))
    }
    onChangeMonth(firstDayOfNextMonth, endOfMonth(firstDayOfNextMonth))
  }

  const handleClickDate = (day: any) => {
    onSelectedDate(format(day, DATE_FORMAT))

    isPopoverOpen.onToggle()
  }

  const calendarHeader = useMemo(() => {
    const textCurrentMonth = screen === CalanderScreen.DTC ? format(new Date(selectedDate), 'MMMM, yyyy') : format(new Date(selectedDate), 'MMMM dd, yyyy') //`${monthNames[monthIndex]} ${dateChange}, ${thisYear}`
    return (
      <div className="relative z-20 flex items-center space-x-1 pb-1">
        <div
          onClick={getPrevMonth}
          className={`h-[24px] w-[24px] rounded-[50%]  bg-[length:auto] bg-no-repeat ${
            isArrowLeft.value ? `bg-[url('assets/images/icon/i-arrow_left.svg')] hover:cursor-pointer hover:bg-slate-200` : ''
          }`}
        />

        <h4 className="text-[13px] font-semibold uppercase leading-[17px] tracking-[0.15px] hover:cursor-pointer" onClick={() => isPopoverOpen.onToggle()}>
          {textCurrentMonth}
        </h4>

        <div
          onClick={getNextMonth}
          className={`h-[24px] w-[24px] rounded-[50%]  bg-[length:auto] bg-no-repeat ${
            isArrowRight.value ? `bg-[url('assets/images/icon/i-arrow_right.svg')] hover:cursor-pointer hover:bg-slate-200` : ''
          } `}
        />
      </div>
    )
  }, [currMonth, isArrowRight.value, isArrowLeft.value, isPopoverOpen.value, date])

  const calendarData = useMemo(() => {
    const className = screen !== CalanderScreen.DTC ? 'absolute -left-5 -right-5 z-20 shadow-lg border bg-white' : ''
    const headerClass = screen !== CalanderScreen.DTC ? 'bg-[white] ' : 'bg-[#D9D9D9]'

    const conditionChoseDate = (date: Date) => {
      if (screen === CalanderScreen.DT || screen === CalanderScreen.DTC) {
        const currentDate = new Date()

        const differenceInDaysResult = -differenceInDays(date, currentDate)
        return differenceInDaysResult <= 2
      }
      return false
    }

    return (
      <div className="relative z-20 w-full">
        <div className={`max-w-[684px] table-auto border-collapse ${className}`}>
          {!togglePopover && <div className="py-2">{calendarHeader}</div>}
          <div className="">
            <div className={`flex`}>
              {days.map((day, idx) => {
                return (
                  <div
                    key={idx}
                    className={`${headerClass} w-[calc(100%/7)] text-center text-[14px] font-semibold leading-7 tracking-[0.15px] first-letter:uppercase first:rounded-[5px_0_0_5px] last:rounded-[0_5px_5px_0]`}
                  >
                    {day}
                  </div>
                )
              })}
            </div>
            <div className="grid grid-cols-7 place-items-center py-4">
              {daysInMonth.map((day, idx) => {
                const hover = 'transform transition hover:scale-95 hover:duration-100 hover:ease-in-out'
                const backgroundToday = `bg-[url('assets/images/icon/i-circle.svg')] bg-[length:auto_33px] text-white rounded-full ${hover} cursor-pointer`
                const bgHeartHightLight = `bg-[url('assets/images/icon/i-heart-2.svg')] bg-[length:auto_27px] text-white ${hover} cursor-pointer`
                let bg = ''

                let clickable = true

                if (isSameDay(day, today)) {
                  bg = backgroundToday
                } else if (isAfter(day, today)) {
                  bg += ' cursor-default'
                  clickable = false
                } else {
                  if (trackedDate?.includes(format(day, DATE_FORMAT)) && isSameMonth(selectedDate, day)) {
                    bg = bgHeartHightLight
                  } else {
                    bg += 'text-[#ED2580] cursor-default'
                    clickable = conditionChoseDate(day)
                  }
                }

                if (!isSameMonth(selectedDate, day)) {
                  bg += ' text-[#989899] cursor-default'
                  clickable = false
                }

                return (
                  <div key={idx} className={colStartClasses[getDay(day)]}>
                    <p
                      className={`flex h-8 w-8 items-center justify-center
                      bg-center bg-no-repeat text-center text-[14px] font-semibold leading-[17px] ${bg} `}
                      onClick={() => {
                        if (clickable) {
                          handleClickDate(day)
                        }
                      }}
                    >
                      {format(day, 'd')}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }, [currMonth, isArrowRight.value, isArrowLeft.value, trackedDate, date])
  return (
    <>
      {screen === CalanderScreen.DTC && <h3 className="font-['Butler'] text-[24px] leading-[1.41667]">My Data</h3>}
      {isPopoverOpen.value && screen !== CalanderScreen.DTC && (
        <div
          className="fixed bottom-0 left-0 right-0 top-0 z-10"
          onClick={() => {
            if (onClosed) onClosed()
            isPopoverOpen.onToggle()
          }}
        ></div>
      )}
      {togglePopover && calendarHeader}
      {togglePopover ? isPopoverOpen.value && calendarData : calendarData}
    </>
  )
}
