import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGoalTrackingConfigPage } from '../../hooks/pages/goal-tracking-config'
import { useHeartJournalPage } from '../../hooks/pages/heart-journal'
import { useLocales } from '../../locales'
import { Goal } from '../../models/goal'
import { GoalTrackConfigAllGoals } from './AllGoals'
import { GoalTrackingConfiguration } from './Form/GoalTrackingConfiguration'
import { Questions } from './Form/Questions'
import { GoalTrackConfigMyData } from './MyData'
import { GoalTrackConfigMyGoals } from './MyGoals'
import { GoalTrackConfigSection } from './Section'
import { GoalTrackConfigWrapper } from './Wrapper'
import { goalTrackingConfig, goal_settings, user_settings } from '../../models/goalTrackingConfig'
import { CustomCalendar } from '../Calendar'
import { CalanderScreen } from '../../constants'
import { endOfMonth, format, isAfter, isSameDay, isSameMonth, startOfMonth, subMonths } from 'date-fns'
import { useDailyTracking } from '../../hooks/use-daily-tracking'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Loading } from '../Common'
import { useAlert } from '../../context/AlertContext'
import { capitalizeFirstLetter, customTitle, TitleType } from './dataUtils'
import { IntroductionMyGoals, IntroductionRemainingGoals, IntroductionTabGTC, IntroSelectedTabData } from '../../constants/dataIntroductionGTC'
import { MyAdessoGTCStep } from '../../constants/introduction'
import { DashboardSection } from '../Common/DashboardSection'

type Props = {
  tabIntroduction?: number
  isIntroduction?: boolean
  goalTrackingConfig?: string | number | any
  jumpTo?: any
  refs?: React.Ref<any>[]
}

const DATE_FORMAT = 'yyyy-MM-dd'

export const GoalTrackingConfigurationContent = ({ isIntroduction, jumpTo, refs = [], tabIntroduction, goalTrackingConfig }: Props) => {
  const location = useLocation()
  const { slug } = useParams()

  const validSessions = ['session-06', 'session-07', 'session-08', 'session-09', 'session-10']
  const searchParams = new URLSearchParams(location.search)
  const tabGtcFromRx = Number(searchParams.get('tab-gtc-from-rx') as string)
  const tabGtcFromDt = Number(searchParams.get('tab-gtc-from-dt') as string)

  const specScroll: Record<number, number> = {
    4: window.innerWidth < 768 ? 400 : 200,
    3: window.innerWidth < 768 ? 600 : 420,
    2: window.innerWidth < 768 ? 600 : 420,
  }

  tabGtcFromRx &&
    window.scrollTo({
      behavior: 'smooth',
      top: specScroll[tabGtcFromRx] ?? 0,
    })

  const [valuesFieldAnswer, setValuesFieldAnswer] = useState('')

  const handleChangeAnswer = (event: any) => {
    setValuesFieldAnswer(event.target.value)
  }

  const isValidSession = (slug: string) => {
    return validSessions.includes(slug)
  }

  const { t } = useLocales()
  const navigate = useNavigate()
  const { setAlertError } = useAlert()
  const tabsRef = useRef<HTMLDivElement>(null)
  const { getCalendarHistories } = useDailyTracking()
  const { myGoalsResult, remainingGoalsResult, getMyGoals, getRemainingGoals, addGoal, removeGoal, myHeartJournalsResult, getMyHeartJournals } = useHeartJournalPage()
  const { goalTrackingConfigResult, getGoalTrackingConfig } = useGoalTrackingConfigPage()
  const [tabGTC, setTabGTC] = useState(0)
  const [selectedTabData, setSelectedTab] = useState<goalTrackingConfig>()

  const [month, setMonth] = useState(new Date())
  const [trackedDate, setTrackedDate] = useState<String[]>([])
  const [dataHistoryCalendar, setDataHistoryCalendar] = useState<any[]>([])
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [startCalendarDate, setStartCalendarDate] = useState(new Date())

  const goalTrackingConfigList = goalTrackingConfigResult?.data?.goalTrackingConfig

  const handleNavigate = () => {
    if (myGoalsResult?.data?.goals) {
      const myGoal = myGoalsResult?.data?.goals
      const goal = myGoal.filter((item) => item.goalTrackingId === selectedTabData?.id) || 4
      if (goal.length > 0 && tabGTC !== 0) {
        navigate(`/daily-tracking/?tab-dt-from-gtc=${selectedTabData?.id || 4}`)
      } else {
        navigate(`/daily-tracking`)
      }
    } else {
      setAlertError(`API My Goal Error`)
    }
  }

  const getAllGoals = (): Goal[] => {
    if (!remainingGoalsResult?.data?.goals) {
      return myGoalsResult?.data?.goals || []
    }

    return myGoalsResult?.data?.goals?.concat(remainingGoalsResult?.data?.goals) || []
  }
  const handleGoalTrackingConfig = useCallback(
    (id: number, index?: number) => {
      setTabGTC(id)
    },
    [tabGTC, goalTrackingConfigList]
  )

  // useEffect(() => {
  //   if ((tabGtcFromRx || tabGtcFromDt) && goalTrackingConfigList) {
  //     setTabGTC(tabGtcFromRx || tabGtcFromDt)
  //     setSelectedTab(goalTrackingConfigList?.find((f: any) => f.id === ((tabGtcFromRx || tabGtcFromDt) ?? 4)))
  //   }
  // }, [goalTrackingConfigList, tabGtcFromRx, tabGtcFromDt])

  useEffect(() => {
    if (!tabGTC && tabGTC !== 0) return
    if (goalTrackingConfigList) {
      setSelectedTab(goalTrackingConfigList?.find((f: any) => f.id === tabGTC))
      const activeTab = tabsRef?.current?.querySelector(`.tabId-${tabGTC}`)
      if (activeTab) {
        activeTab.scrollIntoView({
          behavior: 'smooth',
          block: isValidSession(slug ?? 'false') ? 'nearest' : 'center',
          inline: 'center',
        })
      }
    }
  }, [tabGTC, goalTrackingConfigList])

  const seletedTabFromSlug = async (slug: string) => {
    if (!slug || !myGoalsResult?.data?.goals?.length) {
      return
    }
    const allGoals = getAllGoals()
    const goal = allGoals.find((f: any) => f.slug === slug)
    if (goal?.goalTrackingId) {
      handleGoalTrackingConfig(goal?.goalTrackingId)
      return
    }
    const tracking = goalTrackingConfigList?.find((f: any) => f.goal_id === goal?.id)
    if (tracking) handleGoalTrackingConfig(tracking.id)
  }

  useEffect(() => {
    if (goalTrackingConfigList) {
      setSelectedTab(goalTrackingConfigList[0])
    }
  }, [goalTrackingConfigList])

  useEffect(() => {
    if (myGoalsResult?.data || isIntroduction) return
    getMyGoals(true)
  }, [myGoalsResult?.data])

  useEffect(() => {
    if (remainingGoalsResult?.data || isIntroduction) return
    getRemainingGoals()
  }, [remainingGoalsResult?.data])

  useEffect(() => {
    if (myHeartJournalsResult?.data || isIntroduction) return
    getMyHeartJournals()
  }, [myHeartJournalsResult?.data])

  useEffect(() => {
    if (isIntroduction) {
      setTabGTC(tabIntroduction ?? IntroSelectedTabData.id)
      setSelectedTab(IntroSelectedTabData)
      return
    }
    const fetchResult = async () => {
      await getGoalTrackingConfig()
    }
    fetchResult()
  }, [tabIntroduction])

  async function addNewGoal(goal: Goal) {
    await addGoal(goal.id)
    seletedTabFromSlug(goal.slug)
  }

  async function removeCurrentGoal(goal: Goal) {
    await removeGoal(goal.id)
  }

  const getEnableGoalStatus = () => {
    if (isIntroduction) {
      return true
    }
    return !!myGoalsResult?.data?.goals?.find((f) => f.id === selectedTabData?.goal_id)
  }
  useEffect(() => {
    if (goalTrackingConfigList && (tabGtcFromRx || tabGtcFromDt || goalTrackingConfig)) {
      setTabGTC((tabGtcFromRx || tabGtcFromDt || goalTrackingConfig.id) ?? 4)
      setSelectedTab(goalTrackingConfigList?.find((f: any) => f.id === ((tabGtcFromRx || tabGtcFromDt || goalTrackingConfig.id) ?? 4)))
    }
  }, [goalTrackingConfigList, tabGtcFromRx, tabGtcFromDt, goalTrackingConfig])

  const getTitle = () => {
    return tabGTC === 0 || selectedTabData?.title?.toLowerCase() === 'commit' ? t('extraText.goalTrackingConfiguration') : t('extraText.dtcTitleCommon')
  }

  const getTitleSession6_10 = () => {
    return (
      <div className="flex flex-col gap-[20px] text-[16px] leading-[19.49px]">
        {slug === 'session-10' && <h4 className="text-[18px] font-[700] leading-[23px]">Sustaining your habits</h4>}
        <p className="font-normal">{t('extraText.nowThatSS6_9')}</p>
        {slug === 'session-10' ? <p className="font-normal">{t('extraText.prioritizeSS10')}</p> : <p className="font-semibold text-color-bg-primary">{t('extraText.prioritizeSS6_9')}</p>}
      </div>
    )
  }

  const onUpdateGoalSetting = (item: goal_settings, data: user_settings) => {
    goalTrackingConfigList.forEach((m: any) => {
      if (m.id === item.goal_tracking_id) {
        const seti = m.goal_settings.find((f: goal_settings) => f.id === item?.id) as goal_settings
        seti.user_settings = [data]
      }
    })
  }

  useEffect(() => {
    if (isAfter(month, startCalendarDate) && dataHistoryCalendar.length) {
      return
    }

    const fetchCalendar = async () => {
      try {
        const newStartDate = subMonths(startOfMonth(startCalendarDate), 3)
        const data = await getCalendarHistories(format(newStartDate, DATE_FORMAT), format(new Date(), DATE_FORMAT)).catch()
        setStartCalendarDate(newStartDate)
        setDataHistoryCalendar(dataHistoryCalendar.concat(data?.data))
      } catch (error) {}
    }
    fetchCalendar()
  }, [month])

  useEffect(() => {
    if (!dataHistoryCalendar.length || !selectedTabData) {
      return
    }
    const archived = dataHistoryCalendar
      .filter((m: any) => {
        return !tabGTC ? isSameMonth(month, new Date(m.date)) : isSameMonth(month, new Date(m.date)) && m.goal_tracking_id?.includes(selectedTabData?.id)
      })
      .map((d: any) => d.date)
    setTrackedDate([...archived])
  }, [tabGTC, dataHistoryCalendar, selectedTabData, month, calendarDate])

  const onChangeMonth = async (start: string, end: string) => {
    setMonth(new Date(start))
    setCalendarDate(new Date(start))
  }
  const onSelectedDate = (date: string) => {
    let url = `/daily-tracking?date=${date}`
    const tracked = dataHistoryCalendar.find((m: any) => isSameDay(new Date(date), new Date(m.date)))

    if (tracked?.goal_tracking_id?.length) {
      const id = getIndexFromGoals(tracked.goal_tracking_id)
      url += `&trackingId=${id}`
    }
    navigate(url)
  }

  const getIndexFromGoals = (trackingIds: number[]) => {
    const allGoals = getAllGoals()
    let id = null
    for (const goal of allGoals) {
      const index = trackingIds.findIndex((f) => f === goal.goalTrackingId)
      if (index !== -1) {
        id = trackingIds[index]
        break
      }
    }

    return id || trackingIds[0]
  }

  const goalTrackConfigList = useMemo(() => {
    return (
      <div className="no-scrollbar flex space-x-3 overflow-auto whitespace-nowrap" ref={tabsRef}>
        <button
          onClick={() => setTabGTC(0)}
          className={`tabId-0 rounded-full p-[5px] px-[10px] uppercase hover:cursor-pointer`}
          style={
            tabGTC === 0
              ? {
                  backgroundColor: '#D3548E',
                  color: '#FFFFFF',
                }
              : {
                  backgroundColor: '#FFF',
                }
          }
        >
          OverView
        </button>
        {(isIntroduction ? IntroductionTabGTC : goalTrackingConfigList)?.map((item: any, index: number) => {
          return (
            <button
              key={index}
              ref={item.id === 4 ? refs[MyAdessoGTCStep.TabCommit] : null}
              onClick={() => handleGoalTrackingConfig(item?.id, index)}
              className={`rounded-full p-[5px] px-[10px] uppercase hover:cursor-pointer tabId-${item.id}`}
              style={
                tabGTC === item?.id
                  ? {
                      backgroundColor: item?.background_color || item.backgroundColor,
                      color: item?.text_color || item.textColor,
                    }
                  : {
                      backgroundColor: '#FFF',
                    }
              }
            >
              {item?.title}
            </button>
          )
        })}
      </div>
    )
  }, [tabGTC, goalTrackingConfigList])

  const myGoalsContent = useMemo(() => {
    if (isIntroduction) {
      return <GoalTrackConfigMyGoals goals={IntroductionMyGoals} handleRemoveGoal={() => {}} />
    }
    const goals = myGoalsResult?.data?.goals || undefined
    const isLoading = myGoalsResult?.isLoading !== false
    return <GoalTrackConfigMyGoals goals={goals} handleRemoveGoal={removeCurrentGoal} onClick={(slug) => seletedTabFromSlug(slug)} isLoading={isLoading} />
  }, [myGoalsResult])

  const allGoalsContent = useMemo(() => {
    if (isIntroduction) {
      return <GoalTrackConfigAllGoals goals={IntroductionRemainingGoals} handleAddGoal={addNewGoal} />
    }
    const goals = remainingGoalsResult?.data?.goals || undefined
    const isLoading = remainingGoalsResult?.isLoading !== false
    return goals?.length ? <GoalTrackConfigAllGoals goals={goals} handleAddGoal={addNewGoal} onClick={(slug) => seletedTabFromSlug(slug)} isLoading={isLoading} /> : <></>
  }, [remainingGoalsResult])

  const myGoalTrackConfigDataContent = useMemo(() => {
    if (isIntroduction) {
      return <GoalTrackConfigMyData isIntroduction={true} />
    }
    return <></>
    // TODO: refactor calendar
    // const myHeartJournals = myHeartJournalsResult?.data?.myHeartJournals || undefined
    // return <GoalTrackConfigMyData myHeartJournals={myHeartJournals} />
  }, [myHeartJournalsResult])

  const overView = useMemo(() => {
    return (
      <>
        {myGoalsContent}
        {allGoalsContent}
      </>
    )
  }, [myGoalsResult, remainingGoalsResult])
  const screen = useMemo(() => {
    const isCommit = selectedTabData?.title === TitleType.COMMIT

    const questions = {
      id: selectedTabData?.id || 0,
      // id: 1,
      title: customTitle(selectedTabData?.title)?.toLowerCase() || '',
      name: `${isCommit ? '' : t('common.add')} ${selectedTabData?.title || ''} ${t('common.goal')}`,
      // name: 'Goal',
      notification: '',
      goalId: selectedTabData?.goal_id,
      type: 'radio',
      isRequired: false,
      hidden: false,
      link: `${t('GoalTrackingConfig.seeYour')} ${capitalizeFirstLetter(customTitle(selectedTabData?.title))} Rx`,
      options: [
        // {
        //   value: 0,
        //   label: 'Daily',
        // },
        // {
        //   value: 1,
        //   label: 'Weekly',
        // },
      ],
    }
    switch (tabGTC) {
      case 0:
        return overView
      default:
        return (
          <>
            <GoalTrackingConfiguration
              jumpTo={jumpTo}
              refs={refs}
              handleAddRemoveGoal={(isAdded: boolean) => (isAdded ? removeGoal(selectedTabData?.goal_id) : addGoal(selectedTabData?.goal_id))}
              backgroundColor={selectedTabData?.background_color}
              goalTracking={isIntroduction ? IntroductionMyGoals[0] : selectedTabData}
              questions={questions}
              fieldType={0}
              value={valuesFieldAnswer}
              isAdded={getEnableGoalStatus()}
              onChange={(event: any) => handleChangeAnswer(event)}
              isIntroduction={isIntroduction}
            />
            <h3 className="text-[16px] font-bold">{selectedTabData?.description}</h3>
            {selectedTabData?.goal_settings?.map((item: any, index: number) => {
              const objectMockup: any = {
                name: item?.id,
                options: [
                  {
                    value: 'manually',
                    label: 'I will enter it manually.',
                  },
                  {
                    value: 'auto',
                    label: 'I have a device to connect.',
                  },
                ],
                link: '',
                fieldType: 0,
                value: '',
              }

              const mergedObject = item?.type && item?.type === 'both' ? Object.assign({}, item, objectMockup) : item
              return (
                <Questions
                  refs={index === 0 ? refs[MyAdessoGTCStep.BloodPressure] : null}
                  backgroundColor={selectedTabData?.background_color}
                  key={item?.id}
                  questions={mergedObject}
                  fieldType={mergedObject?.fieldType}
                  isAdded={getEnableGoalStatus()}
                  onUpdateGoal={(data) => onUpdateGoalSetting(item, data)}
                />
              )
            })}
          </>
        )
    }
  }, [tabGTC, valuesFieldAnswer, selectedTabData, myGoalsResult, remainingGoalsResult])
  const calendar = useMemo(() => {
    return (
      <>
        <CustomCalendar togglePopover={false} date={calendarDate} month={month} trackedDate={trackedDate} screen={CalanderScreen.DTC} onSelectedDate={onSelectedDate} onChangeMonth={onChangeMonth} />
      </>
    )
  }, [month, calendarDate, trackedDate])

  return (
    <GoalTrackConfigWrapper className="mx-auto max-w-[684px] space-y-4">
      {isValidSession(slug ?? 'false') ? (
        getTitleSession6_10()
      ) : (
        <GoalTrackConfigSection
          className="space-y-4"
          title={t('mainMenuAndSection.settingMyselfUpForSuccess')}
          track={
            <div ref={refs[MyAdessoGTCStep.TrackYourGoals]} onClick={handleNavigate} className="flex cursor-pointer items-center gap-2 whitespace-nowrap">
              <span className="inline-block text-[12px] text-[#FF3399]">Track Your Goals</span>
              <div className="h-[35px] w-[35px] bg-[url('assets/images/icon/i-pen.svg')]"></div>
            </div>
          }
        >
          <p className="text-[17px] font-medium leading-[21px]">{getTitle()}</p>
        </GoalTrackConfigSection>
      )}

      {goalTrackingConfigResult?.isLoading ? (
        <Loading />
      ) : (
        <>
          {goalTrackConfigList}
          {screen}
          {!tabGTC && calendar}
        </>
      )}
    </GoalTrackConfigWrapper>
  )
}
