import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Wrapper, Container, TitlePage, ButtonCommon } from '../Common'
import { postSendConfirmEmail } from '../../lib/request/post-send-confirm-email'

export const Screen4 = () => {
  const navigate = useNavigate()

  const handleConfim = useCallback(() => {
    postSendConfirmEmail()
    navigate('/subscription')
  }, [])

  const buttons = useMemo(() => {
    return <ButtonCommon width="w-[147px]" height="h-[53px]" text="Confirm" onClick={handleConfim} />
  }, [])

  const confirmScreen = useMemo(() => {
    return (
      <Wrapper className="z-20 bg-transparent text-ma-md text-white">
        <div className="fixed top-20 left-0 right-0 bottom-0 z-10 bg-[url('/backgrounds/bg-register.webp')] bg-cover bg-center bg-no-repeat md:bg-[url('/backgrounds/bg-register-md.webp')]"></div>
        <Container className="z-20 mx-auto max-w-[686px]" bottom={buttons}>
          <div className="space-y-[21px]">
            <TitlePage title="Welcome to MyAdesso." />

            <p className="text-ma-lg font-semibold">
              This is the first step in your health journey. We're going to ask you a series of questions related to your lifestyle, heart health, and risk factors. This process will take about 10
              minutes.
              <br />
              <br />
              As you may know, heart disease is the #1 killer of women and 70 percent don’t know they are sick. By arriving here now, you will not be one of those. In the next few minutes you’ll have
              a clear understanding of your health and what risks may be present. More importantly, we’re giving you all the tools you need to reduce or even eradicate those risks. Remember, 80
              percent of heart disease is preventable!
              <br />
              <br />
              Answer each question as honestly and realistically as you can. There's no need to feel insecure or critical as you answer. You are where you are right now, and we're in this together to
              make progress towards a better future for ourselves and those we love.
              <br />
              <br />
              Ready? Here we go...
            </p>
          </div>
        </Container>
      </Wrapper>
    )
  }, [])

  return <>{confirmScreen}</>
}
