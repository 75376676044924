import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'
import { useLocalStorage } from '../use-local-storage'

import { AdessoResult, MyNow } from '../../models'
import { postUpdateMyNow } from '../../lib/request/post-update-my-now'

type Result = {
  content: string
}

export function useOnboardPage() {
  const { setItemLS } = useLocalStorage()
  const [postDataResult, setPostDataResult] = useState<AdessoResult<Result>>()
  const auth = useAuth()

  const saveMyNow = useCallback(async (myNow: string) => {
    setPostDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await postUpdateMyNow(myNow)
        if (res.data) {
          setItemLS('myNow', res.data.content)
          setPostDataResult({
            isLoading: false,
            data: { content: res.data.content },
            error: undefined,
          })
        } else if (res.message) {
          setPostDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post MyNow Error'),
      })
    }
  }, [])

  return { postDataResult, saveMyNow }
}
