import { useMemo, ChangeEvent, useCallback, useState, useEffect } from 'react'

import { Question } from '../../../models/'

type Props = {
  before?: React.ReactNode
  after?: React.ReactNode
  children?: React.ReactNode
  isRateTop?: boolean
  value?: string
  question: Question
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeValue: (value: string) => void
}

export const RateField = ({ value, before, after, children, isRateTop = false, onChangeValue }: Props) => {
  const [ratePoint, setRatePoint] = useState<number>(+`${value}` || 3)

  useEffect(() => {
    if (!value) {
      setRatePoint(3)
      onChangeValue(`3`)
    }
  }, [value])

  const handleChangeInput = useCallback((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target

    if (!isNaN(+value)) {
      setRatePoint(+value)
      onChangeValue(value)
    }
  }, [])

  const numberRate = useMemo(() => {
    return (
      <div className="flex h-full w-full justify-between font-medium leading-none">
        <span className="w-[12.5%] text-left">1</span>
        <span className="w-1/4 text-center">2</span>
        <span className="w-1/4 text-center">3</span>
        <span className="w-1/4 text-center">4</span>
        <span className="w-[12.5%] text-right">5</span>
      </div>
    )
  }, [])

  const rateContent = useMemo(() => {
    return (
      <div className="relative z-[1] w-full">
        {before}
        {after ? numberRate : children}

        <div className="-mx-4 my-2 h-10 w-[calc(100%+32px)]">
          <input type="range" value={ratePoint} className="input-range" min="1" max="5" step=".01" onChange={handleChangeInput} />
        </div>

        {before ? numberRate : children}
        {after}
      </div>
    )
  }, [before, after, ratePoint, handleChangeInput])

  return <>{rateContent}</>
}
