import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
    data?: {
        content: string
    }
    message?: string
}

export async function postHeartChart ( params: object ): Promise<Response> {
    const { data, status } = await axios.post( '/heart-chart-testing-questions', params )

    if ( status !== 200 ) {
        throw new AdessoError( `Request postHeartChart error - ${status} ${JSON.stringify( data )}` )
    }

    const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

    return dataReturn
}
