import { DashboardSection } from '../Common/DashboardSection'
import { useLocales } from '../../locales'
import { useState } from 'react'
import { ButtonCommon, ButtonStyles, ButtonType } from '../Common/Button'
import { useNavigate } from 'react-router-dom'

export const MyTutorials = ({ jumpTo }: any) => {
  const { t } = useLocales()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  return (
    <DashboardSection jumpTo={jumpTo} className="space-y-2" title={t('mainMenuAndSection.myTutorials')}>
      <p className="text-[14px] font-normal">See product tour videos to get full advantage of MyAdesso features.</p>
      {/* <div className="flex justify-end">
        <NavLink to="/heart-journal" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
          {t('extraText.watchTutorials')}
        </NavLink>
      </div> */}
      <div className="flex w-full justify-center">
        <button className="flex h-[50px] w-[180px] cursor-pointer items-center justify-center rounded-[50px] bg-[#950057]">
          <h4 onClick={() => navigate('/my-tutorials')} className="uppercase text-white">
            Watch Tutorials
          </h4>
        </button>
      </div>
      {/* {isOpen && (
        <div className={`fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-60`}>
          <div className={`relative top-24 mx-auto w-[95%] max-w-[500px] rounded-md p-4`}>
            <div className="relative top-[14px] w-full rounded-[15px] bg-white px-6 py-[18px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
              <p className="text-[17px] font-medium leading-[21px]">{t('extraText.newFeature')}</p>
            </div>
            <div className="relative top-[14px] mt-4 w-full rounded-[15px]">
              <ButtonCommon type={ButtonType.Button} style={ButtonStyles.Introduction} width="w-[167px]" height="h-[51px]" text="Got it!" onClick={togglePopup} />
            </div>
          </div>
        </div>
      )} */}
    </DashboardSection>
  )
}
