import React from 'react'
import LabTestResults from '../../components/MyHealth/LabTestResults'
import { useNavigate } from 'react-router-dom'

type Props = {}

const LabTestResultsPage = (props: Props) => {
  const navigate = useNavigate()

  return <LabTestResults prevScreen={() => navigate('/my-health')} />
}

export default LabTestResultsPage
