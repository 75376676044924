import { useMemo } from 'react'
import { Session08Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session08Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            In this session we’re going to continue our conversation about heart risk factors, but this time we will connect the numbers you need to know as you continue on your heart journey.
            <br />
            <br />
            Play the video below to learn about some of these big ideas:
          </p>

          <VimeoEmbed embedId="808490801" />
        </div>
      </Session08Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
