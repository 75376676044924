import { Link } from 'react-router-dom'

import { IconLoading } from '../Common'
import { DashboardSection } from '../Common/DashboardSection'

import { Session } from '../../models/session'
import { LoadingSize } from '../../models/loading'
import { useMemo } from 'react'
import { useLocales } from '../../locales'

type Props = {
  nextSession?: Session
  isLoading?: boolean
  jumpTo?: any
}

export const MyNextSession = ({ isLoading, nextSession, jumpTo }: Props) => {
  const { t } = useLocales()
  const DashboardSectionContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Normal} />
        </div>
      )
    }
    const slug = (nextSession && nextSession?.slug) || ''
    const keyvisual = slug !== '' ? `/images/my-adresso-journey/${slug.replace('-', '/')}/bg.webp` : undefined

    return (
      <Link to={`/my-adesso-journey/${slug}`} className="relative block w-full rounded-[5px]  text-white hover:text-white">
        <div className="w-full overflow-hidden rounded-[5px]">{keyvisual && <img src={keyvisual} className="block h-auto w-full" />}</div>
        <div className="absolute inset-0 flex h-full w-full flex-col items-center justify-center py-6">
          <h4 className="text-center text-[26px] font-bold leading-[35px]">{nextSession?.title}</h4>

          <div className="flex items-center justify-center space-x-2">
            <div className="flex h-6 w-6 items-center justify-center rounded-full border border-white">
              <svg className="h-2.5 w-2.5" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.789967 1.51732C0.780497 0.721259 1.65979 0.23386 2.32987 0.663737L9.39736 5.19777C10.0344 5.60643 10.0041 6.54717 9.3422 6.91408L2.37458 10.7763C1.71266 11.1432 0.898848 10.6703 0.889846 9.91357L0.789967 1.51732Z"
                  fill="white"
                />
              </svg>
            </div>
            <span className="text-[14px] font-semibold">{nextSession?.time ? `${nextSession.time} mins` : undefined}</span>
          </div>
        </div>
      </Link>
    )
  }, [isLoading, nextSession])

  return (
    <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myNextSession')}>
      {DashboardSectionContent}
    </DashboardSection>
  )
}
