import { Outlet } from 'react-router-dom'

import { CommonFooter } from '../Common/Footer'
import { CommonHeader } from '../Common/Header'
import { useLocales } from '../../locales'

type Props = {
  hasbgcolor?: boolean
  headerAbsolute?: boolean
}

export const GodeeperLayoutWrapper = ({ hasbgcolor = false, headerAbsolute = false }: Props) => {
  const feedbackUrl = import.meta.env.VITE_FEEDBACK_URL
  const { t } = useLocales()
  return (
    <div className={`w-full ${hasbgcolor ? 'bg-gradient-to-b from-[rgba(237,37,128,0.8)] to-[rgba(242,110,87,0.8)]' : ''}`}>
      <div className="mx-auto h-full max-h-max min-h-screen w-full min-w-[375px] max-w-[895px] bg-white font-proxima-nova">
        <a href={feedbackUrl} className="fixed top-5 right-5 z-50 cursor-pointer rounded-full bg-[#D42128] bg-opacity-80 px-4 py-2 font-semibold text-white hover:text-pink-200">
          {t('common.feedback')}
        </a>

        <CommonHeader
          className={`${
            headerAbsolute ? 'h-[156px]' : 'relative z-40 bg-white'
          } bg-slate-300 bg-cover bg-[center_top] bg-no-repeat md:bg-[url('/images/go-deeper/godeeper-pc.webp')] max-md:bg-[url('/images/go-deeper/demo_heart.webp')]`}
          isLogoWhite={headerAbsolute}
          goDeeper
        />

        <div className="mt-[156px] h-full min-h-screen w-full bg-white pb-[58px] text-black">
          <Outlet />
        </div>

        <CommonFooter />
      </div>
    </div>
  )
}
