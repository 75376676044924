import { useState, useCallback, useMemo } from 'react'
import { ScreenHeartAssessment01 } from './HeartAssessment01'
import { ScreenHeartAssessment02 } from './HeartAssessment02'
import { SessionButtons } from '../../layout'
import { Session05Wrapper } from '../Wrapper'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

enum Screen {
  HeartAssessment00,
  HeartAssessment01,
  HeartAssessment02,
}

export const ScreenHeartAssessment = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.HeartAssessment02 : step.sub_step)

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 1, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.HeartAssessment00) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.HeartAssessment02) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: Screen) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenHeartAssessment = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <Session05Wrapper buttons={buttons}>
            <div className="session-style">
              <h4>The healthy pleasure of pleasure.</h4>
              <picture className="">
                <source srcSet={`/images/my-adresso-journey/session/05/img_001.webp`} type="image/webp" />
                <img src={`/images/my-adresso-journey/session/05/img_001.jpg`} alt="" />
              </picture>
              <p>
                We are social beings. For the vast majority of us, who we relate to defines us. We are parents, spouses, children, friends, lovers and co-workers. Who we are in the context of these
                concentric circles of community plays a prominent, daily role in our lives.
              </p>
              <p>Of course, we know all of that, but do you know that the pleasure you derive from these relationships also plays a role in our heart health?</p>
              <p>Pleasure increases your heart health! And you’re going to learn much more about this now.</p>
              <p>Let’s take a moment to dive into those things that bring joy, pleasure, connection and purpose.</p>
            </div>
          </Session05Wrapper>
        )

      case 1:
        return <ScreenHeartAssessment01 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />
      case 2:
        return <ScreenHeartAssessment02 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />
    }
  }, [screen, buttons, handleBackScreen, handleNextScreen, showModal, handleUpdate])

  return <>{screenHeartAssessment}</>
}
