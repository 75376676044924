import React, { useMemo, useState } from 'react'
import { IconLoading } from '../../Common'
import { LoadingSize } from '../../../models/loading'
import {} from '../../../hooks/pages/heart-chart-testing'
import { useBoolean } from '../../../hooks/use-boolean'
import { startOfToday, format } from 'date-fns'
import { BasicCalendar } from '../../Calendar'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useLocales } from '../../../locales'
import { DashboardSection } from '../../Common/DashboardSection'

interface Quiz {
  name: string
  question: string
  type: string
  content_type?: string
  id?: string
  options: Option[]
}

interface Option {
  value: string
  label: string
}

type Props = {
  prevScreen?: () => void
  submit?: (values: any) => void
  dataQuizess?: any[]
  isLoading?: boolean
}

type PropsButton = {
  nameButton?: string
  bgButton?: string
  nextScreen?: () => void
  prevScreen?: () => void
  submit?: (values: any) => void
}

const NewLabTest = ({ prevScreen, submit, dataQuizess, isLoading }: Props) => {
  const { t } = useLocales()
  const today = startOfToday()
  const isPopoverOpen = useBoolean()
  const [selectDate, setSelectDate] = useState(() => format(today, 'MMMM dd, yyyy'))
  const [selectedOption, setSelectedOption] = useState('')

  const initialValues = dataQuizess?.reduce((acc: { [key: string]: string }, currentItem: Quiz) => {
    if (currentItem.content_type === 'lab_test_date') {
      acc[currentItem.name] = selectDate
    } else if (currentItem.name === 'hs_055') {
      acc['hs_055_sys'] = ''
      acc['hs_055_dia'] = ''
    } else {
      acc[currentItem.name] = ''
    }
    return acc
  }, {})

  const button = useMemo(() => {
    const ButtonContent = ({ nameButton, bgButton, prevScreen, submit }: PropsButton) => {
      return (
        <button
          className={`flex max-w-[160px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] ${bgButton} p-4 px-10 uppercase text-[#FFF] `}
          onClick={submit ? undefined : prevScreen}
          type={submit ? 'submit' : 'button'}
        >
          <h3 className="px-10 font-proxima-nova text-[15px] font-medium">{nameButton}</h3>
        </button>
      )
    }

    return (
      <div className="flex justify-around gap-3">
        <ButtonContent nameButton="Cancel" bgButton="bg-color-text-secondary" prevScreen={prevScreen} />
        <ButtonContent nameButton="Submit" bgButton="bg-color-primary" submit={submit} />
      </div>
    )
  }, [])

  const formQuizzes = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }

    const renderInput = (item: Quiz, setFieldValue: any) => {
      switch (item.type) {
        case 'input':
          if (item.name === 'hs_055') {
            return (
              <div className="font-['Proxima Nova'] flex items-center justify-between border-b-[1px] pb-3 text-[16px] font-[600] leading-[24px] text-[#333333]">
                <p>{item.question}</p>
                <div className="flex items-center gap-1 ">
                  <p className="pr-1">SYS</p>
                  <Field name="hs_055_sys" className="h-[32px] w-[46px] border-[1px] border-[#BFBFBE] text-center" />
                  <p className="pr-1">DIA</p>
                  <Field name="hs_055_dia" className="h-[32px] w-[46px] border-[1px] border-[#BFBFBE] text-center" />
                </div>
              </div>
            )
          }

          if (item.content_type === 'lab_test_date') {
            return (
              <>
                <div className="z-auto flex items-center justify-between border-b-[1px] pb-3" key={`${item.name}-${item.id}`}>
                  <h4 className="font-semibold uppercase">{item.question}</h4>
                  <div className="relative items-center text-center">
                    <div
                      className='absolute right-1 top-1 h-[24px] w-[24px] cursor-pointer bg-[#FFF] bg-[url("assets/images/icon/i-calendar.svg")] bg-no-repeat p-1'
                      onClick={() => isPopoverOpen.onToggle()}
                    ></div>
                    <Field type="text" value={selectDate} id="lab_test_date" name={item.name} className={`h-[35px] w-[228px] border-[1px] border-[#BFBFBE] pl-3 font-semibold uppercase`} />
                  </div>
                </div>
                {isPopoverOpen.value && <BasicCalendar selectedDate={(date: Date) => handleChangeDate(date, setFieldValue)} togglePopover={false} />}
              </>
            )
          }
          return (
            <div className="flex items-center justify-between border-b-[1px] pb-3">
              <p className='font-["Proxima Nova"] text-[16px] font-[600] leading-[24px] text-[#333333]'>{item.question}</p>
              <Field name={item.name} className="h-[32px] w-[66px] border-[1px] border-[#BFBFBE] text-center" />
            </div>
          )
        case 'radio-dropdown':
          return (
            <div className="font-['Proxima Nova'] ml-3 border-b-[1px] pb-3 text-[17px]">
              <div className="mb-2 font-[600]">{item.question}</div>
              {item.options.map((option: Option, index: number) => (
                <label key={index} className="mb-2 flex cursor-pointer">
                  <Field
                    type="radio"
                    name={item.name}
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={() => handleChange(option.value, setFieldValue, option.label)}
                    className="form-radio h-[21px] w-[21px] text-pink-600"
                    style={{
                      accentColor: selectedOption === option.value ? '#EB2781' : 'initial',
                    }}
                  />
                  <span className="ml-3 font-[400]">{option.label}</span>
                </label>
              ))}
            </div>
          )
        default:
          return null
      }
    }

    const onsubmit = (values: any, actions: FormikHelpers<any>) => {
      const formattedDateData = format(values.lab_test_date, 'yyyy-MM-dd')

      values.lab_test_date = formattedDateData

      if (!values.hs_055_sys && !values.hs_055_dia) {
        values.hs_055 = ''
      } else {
        values.hs_055 = `${values.hs_055_sys}/${values.hs_055_dia}`
      }

      delete values.hs_055_sys
      delete values.hs_055_dia

      submit && submit?.(values)
      actions.setSubmitting(false)
    }

    const handleChange = (value: any, setFieldValue: any, optionValue: string) => {
      setSelectedOption(value)
      setFieldValue('hs_061', optionValue)
    }

    const handleChangeDate = (selectedDate: Date, setFieldValue: any) => {
      const formattedDate = format(selectedDate, 'MMMM dd, yyyy')
      setSelectDate(formattedDate)
      setFieldValue('lab_test_date', formattedDate)
    }

    return (
      <Formik initialValues={initialValues} onSubmit={onsubmit}>
        {({ errors, setFieldValue }) => (
          <Form className="z-20 mx-auto flex h-full w-full max-w-[684px] flex-1 flex-col space-y-9 pb-4">
            <div className="flex-1 space-y-4">
              {dataQuizess?.map((item, index) => (
                <div key={index}>{renderInput(item, setFieldValue)}</div>
              ))}
              <div className="">{button}</div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }, [selectDate, dataQuizess, isPopoverOpen])

  return (
    <DashboardSection className="space-y-4 pb-2" title={t(`mainMenuAndSection.newLabTestResults`)}>
      <div className={` space-y-4 pb-2`}>{formQuizzes}</div>
    </DashboardSection>
  )
}

export default NewLabTest
