import { useMemo, useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Loading } from '../../components/Common/Loading'
import { MAJSessionContent } from '../../components/MyAdessoJourney/session'

import { useHeartScoreResultPage } from '../../hooks/pages/heart-score-result'
import { useMAJPage } from '../../hooks/pages/maj'

import { SessionStatus, Session } from '../../models/session'

import { getJourneysCurrentStep } from '../../lib/request/get-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  introduction?: boolean
  sessionIntroduction?: number
}

type SessionType = {
  1: { session_id: string; step: number; sub_step: number }
  2: { session_id: string; step: number; sub_step: number }
  3: { session_id: string; step: number; sub_step: number }
  4: { session_id: string; step: number; sub_step: number }
}

const dataStepSessionsIntroduction = {
  1: { session_id: '01', step: 0, sub_step: 0 },
  2: { session_id: '02', step: 1, sub_step: 0 },
  3: { session_id: '03', step: 2, sub_step: 7 },
  4: { session_id: '01', step: 4, sub_step: 0 },
}

export const MAJSession = ({ introduction = false, sessionIntroduction }: Props) => {
  const navigate = useNavigate()
  const [idSession, setIdSession] = useState<number>(0)
  const [step, setStep] = useState<Step>({ session_id: -1, step: 0, sub_step: 0 })
  const { resultHeartScore, checkResultHeartScore } = useHeartScoreResultPage()
  const { dataSessions, getDataSessions, dataResult, postDataSessions, getSessionDetail } = useMAJPage()

  const { slug } = useParams()

  function backTopMAJ() {
    navigate('/my-adesso-journey')
  }

  useEffect(() => {
    if (introduction && sessionIntroduction) {
      const sessionIndexIntroduction = sessionIntroduction || 1

      const isSession = !!sessionIndexIntroduction && sessionIndexIntroduction > 0 && sessionIndexIntroduction < 11
      isSession ? setIdSession(sessionIndexIntroduction) : backTopMAJ()
      getSessionDetail(sessionIndexIntroduction)

      const sessionIndex: keyof SessionType = sessionIntroduction as keyof SessionType
      const sessionData = dataStepSessionsIntroduction?.[sessionIndex]

      setStep(sessionData)
    }
  }, [sessionIntroduction])

  useEffect(() => {
    if (slug) {
      const sessionIndex = +slug.split('session-')[1]
      const isSession = !!sessionIndex && sessionIndex > 0 && sessionIndex < 11
      isSession ? setIdSession(sessionIndex) : backTopMAJ()

      const fetchData = async () => {
        // todo get session info: current step, answer
        getSessionDetail(sessionIndex)
        const data = await getJourneysCurrentStep(sessionIndex)

        if (data.data) {
          setStep(data.data)
        }
      }

      if (isSession) {
        fetchData()
      }
    }
  }, [slug])

  useEffect(() => {
    if (idSession === 1) {
      if (resultHeartScore?.isLoading === undefined) {
        checkResultHeartScore()
      }
    } else {
      if (dataSessions?.isLoading === undefined) {
        getDataSessions()
      }
    }
  }, [idSession, resultHeartScore?.isLoading, dataSessions?.isLoading])

  useEffect(() => {
    if (slug && dataSessions?.data?.sessions) {
      const { sessions } = dataSessions.data
      const isSessionLog = sessions.find((session) => session.slug === slug && session.sessionStatus === SessionStatus.Lock)
      if (isSessionLog) backTopMAJ()
    }
  }, [slug, dataSessions])

  const handleSubmit = useCallback(
    (params: object) => {
      if (idSession != 0) {
        postDataSessions(params, idSession)
      }
    },
    [postDataSessions, idSession]
  )

  const majSessionContent = useMemo(() => {
    const isLoading = dataSessions?.isLoading || resultHeartScore?.isLoading || dataResult?.isLoading || undefined
    const isSuccess = dataResult?.status || undefined

    if (dataSessions?.isLoading || step.session_id == -1) return <Loading />
    return (
      <MAJSessionContent
        answers={dataResult?.data}
        step={step}
        isSubmitSuccess={isSuccess}
        idSession={idSession}
        isLoading={isLoading}
        backTop={() => backTopMAJ()}
        onSubmit={(params: object) => handleSubmit(params)}
      />
    )
  }, [dataSessions, idSession, step, resultHeartScore, dataResult, handleSubmit])

  return <>{majSessionContent}</>
}
