import { useCallback, useState } from 'react'
import { AdessoResult } from '../../models'
import { useAuth } from '../use-auth'
import { useGoalTracking } from '../use-goal-tracking'

type goalTrackingConfigResponse = {
  goalTrackingConfig?: any
}

type goalTrackingConfigDetailResponse = {
  goalTrackingConfigDetail?: any
}

type goalSettingIdResponse = {
  goalSettingId?: any
}

export function useGoalTrackingConfigPage() {
  const [goalTrackingConfigResult, setGoalTrackingConfigResult] = useState<AdessoResult<goalTrackingConfigResponse>>()
  const [goalTrackingConfigDetailResult, setGoalTrackingConfigDetailResult] = useState<AdessoResult<goalTrackingConfigDetailResponse>>()
  const [goalSettingIdResult, setGoalSettingIdResultResult] = useState<AdessoResult<goalSettingIdResponse>>()

  const auth = useAuth()
  const goalTrackingConfig = useGoalTracking()

  const getGoalTrackingConfig = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setGoalTrackingConfigResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await goalTrackingConfig.getGoalTrackingConfig()
        if (res.success) {
          setGoalTrackingConfigResult({
            data: { goalTrackingConfig: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setGoalTrackingConfigResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setGoalTrackingConfigResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Goal Tracking Config error'),
      })
    }
  }, [])

  const getGoalTrackingConfigDetail = useCallback(async (goalTrackingId: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setGoalTrackingConfigDetailResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await goalTrackingConfig.getGoalTrackingConfigDetail(goalTrackingId)
        if (res.success) {
          setGoalTrackingConfigDetailResult({
            data: { goalTrackingConfigDetail: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setGoalTrackingConfigDetailResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setGoalTrackingConfigDetailResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Goal Tracking Config error'),
      })
    }
  }, [])

  const updateGoalSettingId = useCallback(async (goal_setting_id: number, data_goal_setting: any) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setGoalSettingIdResultResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await goalTrackingConfig.updateGoalSettingId(goal_setting_id, data_goal_setting)
        if (res.success) {
          setGoalSettingIdResultResult({
            data: { goalSettingId: res.data },
            isLoading: false,
            error: undefined,
          })
          return res.data
        } else {
          setGoalSettingIdResultResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setGoalSettingIdResultResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Goal Setting Id error'),
      })
    }
  }, [])

  return {
    goalTrackingConfigResult,
    getGoalTrackingConfig,
    goalTrackingConfigDetailResult,
    getGoalTrackingConfigDetail,
    goalSettingIdResult,
    updateGoalSettingId,
  }
}
