import { SessionScreen, ProgressScreenStatus, ProgressScreenType, HeartRickFactorsStatus, HeartRickFactorsType, Session, SessionStatus } from '../../models/session'
import { Question, QuestionTypes } from '../../models/question'
import { mapQuestion } from './question'

export function mapDataSessions(data: any[]): Session[] | undefined {
  if (data.length > 0) {
    return data.map((session) => {
      const slug = session.slug || ''
      const imgSrc = session.imgSrc || ''
      const title = session.title || ''
      const time = session.time || 0
      const point = session.point || 0
      const bonus = session.bonus || 0

      const sessionStatus = session.sessionStatus || SessionStatus.Done

      return mapDataSessionList(slug, imgSrc, title, time, point, bonus, sessionStatus)
    })
  }

  return undefined
}

export function mapDataModalProgress(id: SessionScreen, title: string, time: number, status: ProgressScreenStatus = ProgressScreenStatus.Lock): ProgressScreenType {
  return { id, title, time, status: status }
}

export function mapDataHeartRickFactors(id: number, title: string, description: string = '', status: HeartRickFactorsStatus = HeartRickFactorsStatus.Unread): HeartRickFactorsType {
  return { id, title, description, status }
}

const mapDataSessionList = (
  slug: string,
  imgSrc: string,
  title: string,
  time: number,
  point: number,
  bonus?: string,
  sessionStatus: SessionStatus = SessionStatus.Lock,
  imgSrcWebp?: string
): Session => {
  return { slug, imgSrc, title, time, point, bonus, sessionStatus, imgSrcWebp }
}

export const dummySessonList: Session[] = [
  mapDataSessionList('session-01', '/images/my-adresso-journey/session-01.png', 'Living From Your Heart', 8, 10, undefined, SessionStatus.Current, '/images/my-adresso-journey/session-01.webp'),
  mapDataSessionList('session-02', '/images/my-adresso-journey/session-02.png', 'Get Your Move On: Exercise', 12, 10, undefined, SessionStatus.Lock, '/images/my-adresso-journey/session-02.webp'),
  mapDataSessionList('session-03', '/images/my-adresso-journey/session-03.png', 'Nourish: Food and Your Heart', 13, 10, undefined, SessionStatus.Lock, '/images/my-adresso-journey/session-03.webp'),
  mapDataSessionList(
    'session-04',
    '/images/my-adresso-journey/session-04.png',
    'Refresh: Stress, Anxiety, Depression, and Purpose',
    11,
    10,
    undefined,
    SessionStatus.Lock,
    '/images/my-adresso-journey/session-04.webp'
  ),
  mapDataSessionList(
    'session-05',
    '/images/my-adresso-journey/session-05.png',
    'Relate: People, Pleasure, and Purpose',
    15,
    10,
    // '+25 Half',
    undefined,
    SessionStatus.Lock,
    '/images/my-adresso-journey/session-05.webp'
  ),
  mapDataSessionList('session-06', '/images/my-adresso-journey/session-06.png', 'Know Your Heart', 8, 10, undefined, SessionStatus.Lock, '/images/my-adresso-journey/session-06.webp'),
  mapDataSessionList(
    'session-07',
    '/images/my-adresso-journey/session-07.png',
    'Advocate: Your Provider, Your Medical Care, Your Heart, Your Life',
    8,
    10,
    undefined,
    SessionStatus.Lock,
    '/images/my-adresso-journey/session-07.webp'
  ),
  mapDataSessionList('session-08', '/images/my-adresso-journey/session-08.png', 'Know Your Numbers', 8, 10, undefined, SessionStatus.Lock, '/images/my-adresso-journey/session-08.webp'),
  mapDataSessionList('session-09', '/images/my-adresso-journey/session-09.png', 'Balance: Hormones and Your Heart', 8, 10, undefined, SessionStatus.Lock, '/images/my-adresso-journey/session-09.webp'),
  mapDataSessionList(
    'session-10',
    '/images/my-adresso-journey/session-10.png',
    'Next Steps, Success, Pass It On',
    8,
    20,
    // '+50 Completed',
    undefined,
    SessionStatus.Lock,
    '/images/my-adresso-journey/session-10.webp'
  ),
]

export const sesstion01Questions: Question[] = [
  mapQuestion('ss01__001', QuestionTypes.TextArea, 'What created the need to take this important journey towards heart wellness?'),
  mapQuestion('ss01__002', QuestionTypes.TextArea, 'How are you feeling? What emotions are surfacing for you and why?'),
]

export const sesstion02Questions: Question[] = [
  mapQuestion('ss02__027', QuestionTypes.TextArea, 'What kind of movement brings you joy and why?'),
  mapQuestion('ss02__028', QuestionTypes.TextArea, 'How do you think you’d feel if you did that exercise this week?'),
  mapQuestion('ss02__029', QuestionTypes.TextArea, 'What things can you do that will allow you to think about your Daily Movements and Target Movements as a source of joy rather than a chore?'),
]

export const sesstion03Questions: Question[] = [
  mapQuestion('ss03__016', QuestionTypes.TextArea, 'When do you feel most tempted towards unhealthy eating habits and what is your dietary achille’s heel?', 'Describe what you want to change.'),
  mapQuestion('ss03__017', QuestionTypes.TextArea, 'What do you think you might be seeking underneath your craving for that food experience?', 'Describe what you want to change.'),
]

export const sesstion04Questions: Question[] = [
  mapQuestion('ss04__007', QuestionTypes.TextArea, 'What situations in your life are causing you the most emotional strain (stress, anxiety, depression)?'),
  mapQuestion('ss04__008', QuestionTypes.TextArea, 'How do you usually manage your stress?'),
  mapQuestion(
    'ss04__009',
    QuestionTypes.TextArea,
    "Now that you're aware, are there strategies that you realize that you need to become better at or make a normal part of your life in order to better manage stress?"
  ),
]

export const sesstion05Questions: Question[] = [
  mapQuestion('ss05__013', QuestionTypes.TextArea, 'Take a moment to reflect on your heart journey and listen to your heart.', 'Enter your reflection.'),
  mapQuestion('ss05__014', QuestionTypes.TextArea, 'Write down three things that you like about yourself:', 'Enter your reflection.'),
  mapQuestion('ss05__015', QuestionTypes.TextArea, 'Write down three things you want to do to bring greater pleasure in your life.', 'Enter your reflection.'),
  mapQuestion('ss05__016', QuestionTypes.TextArea, 'Describe what gives you hope:', 'Enter your reflection.'),
  mapQuestion('ss05__017', QuestionTypes.TextArea, 'In two sentences or less, describe your purpose in life as it stands right now.', 'Enter your reflection.'),
]

export const sesstion06Questions: Question[] = [
  mapQuestion('ss06__005', QuestionTypes.TextArea, 'What habits in your life may eventually lead to heart disease?', 'Describe what you want to change.'),
  mapQuestion('ss06__006', QuestionTypes.TextArea, 'What steps do you want to take to live healthier?', 'Describe what you want to change.'),
]

export const sesstion07Questions: Question[] = [
  mapQuestion('ss07__005', QuestionTypes.TextArea, 'What difficulties have you had navigating the medical system as a woman?', 'Describe what you want to change.'),
  mapQuestion('ss07__006', QuestionTypes.TextArea, 'What steps are you taking to ensure you prevent a serious heart emergency?', 'Describe what you want to change.'),
  mapQuestion(
    'ss07__007',
    QuestionTypes.TextArea,
    'After learning more about emergency symptoms, is there any action that you want to take to address your heart health or speak with a healthcare provider about?',
    'Describe what you want to change.'
  ),
  mapQuestion('ss07__008', QuestionTypes.TextArea, 'Are you happy with your healthcare provider or do you think that you might be able to find a better fit?', 'Describe what you want to change.'),
]

export const sesstion08Questions: Question[] = [
  mapQuestion('ss08__015', QuestionTypes.TextArea, 'Given what you’ve read today, which of the following areas do you think might be an area to focus on for you?'),
  mapQuestion('ss08__016', QuestionTypes.TextArea, 'Why did you choose that area?'),
  mapQuestion('ss08__017', QuestionTypes.TextArea, 'Based on what you learned, which next steps would you like to take to improve these numbers?'),
  mapQuestion('ss08__018', QuestionTypes.TextArea, 'What steps do you want to take right now to help move yourself forward?'),
]

export const sesstion09Questions: Question[] = [mapQuestion('ss09__003', QuestionTypes.TextArea, 'How do you experience your hormones affecting you?', 'Describe what you want to change.')]

export const sesstion10Questions: Question[] = [
  mapQuestion('ss10__013', QuestionTypes.TextArea, 'What was the biggest change you saw in yourself and your perspective in this experience?', 'Describe what you want to change.'),
  mapQuestion(
    'ss10__014',
    QuestionTypes.TextArea,
    'What would you like to focus on as you continue to move forward in your journey of maintaining a healthy heart?',
    'Describe what you want to change.'
  ),
]
