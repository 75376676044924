import axios from 'axios'
import { AdessoError } from '../error'

type GetDailyTrackingHistoryResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getDailyTrackingHistory(data_daily_tracking_history: any): Promise<GetDailyTrackingHistoryResponse> {
  const params = {
    date: data_daily_tracking_history?.date,
    goal_tracking_id: data_daily_tracking_history?.goal_tracking_id,
  }
  const { data, status } = await axios.get(`/goal-tracking/history`, { params })

  if (status !== 200) {
    console.info('getDailyTrackingHistory', data)
    throw new AdessoError(`Request getDailyTrackingHistory error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDailyTrackingHistoryResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function getCalendarHistory(startDate: string, endDate: string): Promise<GetDailyTrackingHistoryResponse> {
  const params = {
    start_date: startDate,
    end_date: endDate,
  }
  const { data, status } = await axios.get(`/goal-tracking/calendar`, { params })

  if (status !== 200) {
    console.info('getDailyTrackingHistory', data)
    throw new AdessoError(`Request getDailyTrackingHistory error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDailyTrackingHistoryResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
