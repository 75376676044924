import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'
import { useLocalStorage } from '../use-local-storage'

import { JourneyAnswer, AdessoResult, Session, SessionStatus } from '../../models'
import { dummySessonList } from '../../lib/maps/session'
import { getJourneysCurrentStep } from '../../lib/request/get-journeys-current-step'
import { postAddJourneyAnswer } from '../../lib/request/post-add-journey-answer'
import { getJourneyDetail } from '../../lib/request/get-journey-detail'

type Response = {
  sessions?: Session[]
  nextSession?: Session
}

type PostResponse = {
  success?: boolean
}

function updateSessions(slugSession: string, currentSessions: Session[]): Session[] {
  const indexSession = currentSessions.findIndex((session) => session.slug === slugSession)

  if (indexSession > -1) {
    const sessions = currentSessions.map((session, key) => {
      if (key <= indexSession) {
        session.sessionStatus = SessionStatus.Done
      } else if (key === indexSession + 1) {
        session.sessionStatus = SessionStatus.Current
      } else {
        session.sessionStatus = SessionStatus.Lock
      }

      return session
    })

    return sessions
  }

  return currentSessions
}

export function useMAJPage() {
  const [dataSessions, setDataSessions] = useState<AdessoResult<Response>>()
  const [dataNextSession, setDataNextSession] = useState<Session>(dummySessonList[0])
  const [dataResult, setDataResult] = useState<AdessoResult<JourneyAnswer[]>>({
    isLoading: true,
    data: undefined,
    error: undefined,
    status: false,
  })

  const auth = useAuth()
  const { setItemLS, getItemLS } = useLocalStorage()

  const getDataSessions = useCallback(async () => {
    setDataSessions({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      const isAuth = await auth.checkAuth()
      if (isAuth) {
        auth.setTokenApi()
        const res = await getJourneysCurrentStep()

        if (res.data) {
          let sessions = dummySessonList
          const { session_id, step, memo } = res.data

          const sessionSlug = 'session-' + ('00' + session_id).slice(-2)
          sessions.forEach((v, i) => {
            if (memo?.memo[v.slug]?.is_open == 1) {
              if (memo?.memo[v.slug]?.is_finish == 1) {
                sessions[i].sessionStatus = SessionStatus.Done
              } else {
                sessions[i].sessionStatus = SessionStatus.Current
              }
            }
            // calculate next session
            if (v.slug == sessionSlug) {
              if (step == 6) {
                setDataNextSession(sessions[i + 1])
              } else {
                setDataNextSession(v)
              }
            }
          })

          setDataSessions({
            isLoading: false,
            data: { sessions },
            error: undefined,
          })
        } else {
          setDataSessions({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataSessions({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get Sessions Error'),
      })
    }
  }, [])

  const getSessionDetail = useCallback(async (idSession: any) => {
    setDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
      status: false,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getJourneyDetail(idSession)

        if (res.data) {
          setDataResult({
            isLoading: false,
            data: res.data,
            status: false, //avoid goto next step
            error: undefined,
          })
        } else {
          setDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
            status: false,
          })
        }
      }
    } catch (error) {
      setDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post Sessions Result Error'),
        status: false,
      })
    }
  }, [])

  const postDataSessions = useCallback(async (params: any, idSession: any) => {
    setDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
      status: false,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await postAddJourneyAnswer(idSession, params)

        if (res.data) {
          const status = res.success ? params['goNext'] : false

          setDataResult({
            isLoading: false,
            data: res.data,
            status: status,
            error: undefined,
          })
        } else {
          setDataResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
            status: false,
          })
        }
      }
    } catch (error) {
      setDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Post Sessions Result Error'),
        status: false,
      })
    }
  }, [])

  return { dataSessions, getDataSessions, dataResult, postDataSessions, dataNextSession, getSessionDetail }
}
