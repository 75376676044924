import { useEffect, useMemo } from 'react'

import { Session01 } from './session-01'
import { Session02 } from './session-02'
import { Session03 } from './session-03'
import { Session04 } from './session-04'
import { Session05 } from './session-05'
import { Session06 } from './session-06'
import { Session07 } from './session-07'
import { Session08 } from './session-08'
import { Session09 } from './session-09'
import { Session10 } from './session-10'
import { useGoalTrackingConfigPage } from '../../../hooks/pages/goal-tracking-config'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  idSession: number
  step: Step
  isLoading?: boolean
  isSubmitSuccess?: boolean
  onSubmit: (params: object) => void
  backTop: () => void
  answers: any
}

export const MAJSessionContent = ({ answers, idSession, step, isLoading, isSubmitSuccess, onSubmit, backTop }: Props) => {
  const { goalTrackingConfigResult, getGoalTrackingConfig } = useGoalTrackingConfigPage()
  const goalTrackingConfigList = goalTrackingConfigResult?.data?.goalTrackingConfig

  useEffect(() => {
    const fetchResult = async () => {
      await getGoalTrackingConfig()
    }
    fetchResult()
  }, [])
  const majSessionContent = useMemo(() => {
    const goalTrackingConfigCommit = goalTrackingConfigList ? goalTrackingConfigList.find((item: any) => item?.title.toLowerCase() === 'commit') : []
    const goalTrackingConfigMove = goalTrackingConfigList ? goalTrackingConfigList.find((item: any) => item?.title.toLowerCase() === 'move') : []
    const goalTrackingConfigNourish = goalTrackingConfigList ? goalTrackingConfigList.find((item: any) => item?.title.toLowerCase() === 'nourish') : []
    const goalTrackingConfigRefresh = goalTrackingConfigList ? goalTrackingConfigList.find((item: any) => item?.title.toLowerCase() === 'refresh') : []
    const goalTrackingConfigRelate = goalTrackingConfigList ? goalTrackingConfigList.find((item: any) => item?.title.toLowerCase() === 'relate') : []

    switch (idSession) {
      case 1:
        return <Session01 goalTrackingConfigCommit={goalTrackingConfigCommit} answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 2:
        return <Session02 goalTrackingConfigMove={goalTrackingConfigMove} answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 3:
        return <Session03 goalTrackingConfigNourish={goalTrackingConfigNourish} answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 4:
        return <Session04 goalTrackingConfigRefresh={goalTrackingConfigRefresh} answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 5:
        return <Session05 goalTrackingConfigRelate={goalTrackingConfigRelate} answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 6:
        return <Session06 answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 7:
        return <Session07 answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 8:
        return <Session08 answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 9:
        return <Session09 answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
      case 10:
        return <Session10 answers={answers} step={step} isLoading={isLoading} onSubmit={onSubmit} backTop={backTop} isSuccess={isSubmitSuccess} />
    }
  }, [isSubmitSuccess, idSession, isLoading, goalTrackingConfigResult])
  return <>{majSessionContent}</>
}
