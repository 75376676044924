import * as amplitude from '@amplitude/analytics-browser'
import { BaseEvent, EventOptions } from '@amplitude/analytics-types'
import { useLocalStorage } from '../../hooks/use-local-storage'

export function toDate(date: string): Date {
  // Validate the date string
  const regex = /^(?:\d{4})-(?:0?[1-9]|1[0-2])-(?:0?[1-9]|[12][0-9]|3[01])$/
  if (!regex.test(date)) {
    throw new Error("Invalid date format. Use 'yyyy-MM-dd' or 'yyyy-M-d'.")
  }
  return new Date(date + 'T00:00:00')
}

export const checkHadFirstHeartScore = (user: any) => {
  return user.had_first_heart_score
}

export const setAmplitudeUserId = (userId: string) => {
  amplitude.setUserId(userId)
}

export const setAmplitude = (key: string, value: any) => {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set(key, value); 
  amplitude.identify(identifyEvent);
}

export const trackAmplitude = (event: string | BaseEvent, eventProperties?: Record<string, any> | undefined, eventOptions?: EventOptions | undefined) => {
  amplitude.track(event, eventProperties, eventOptions);
}

export const setChangeAmplitude = (key: string, value: any) => {
  const { getItemLS, setItemLS } = useLocalStorage()
  const amplitudeLS: any = getItemLS('amplitudeUser') || {}

  if (amplitudeLS[key] != value) {
    amplitudeLS[key] = value
    setItemLS('amplitudeUser', amplitudeLS)
    setAmplitude(key, value)
  }
}

export const trackChangeAmplitude = (event: string, eventProperties: Record<string, any>, eventOptions?: EventOptions | undefined) => {
  const { getItemLS, setItemLS } = useLocalStorage()
  const amplitudeLS: any = getItemLS('amplitudeEvent') || {}

  if (!amplitudeLS[event]) {
    amplitudeLS[event] = {}
  }
  const eventValueLS = amplitudeLS[event]

  let updateFound = false
  for (const key in eventProperties) {
    if (eventValueLS[key] != eventProperties[key]) {
      eventValueLS[key] = eventProperties[key]
      updateFound = true
    }
  }

  if (updateFound) {
    setItemLS('amplitudeEvent', amplitudeLS)
    trackAmplitude(event, eventProperties)
  }
}

