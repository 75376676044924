import { useMemo } from 'react'

import { ForgotPasswordContent } from '../components/ForgotPassword'

export const ForgotPasswordPage = () => {
  const forgotPasswordContent = useMemo(() => {
    return <ForgotPasswordContent />
  }, [])

  return <>{forgotPasswordContent}</>
}
