import { useMemo, useEffect } from 'react'
import { HeartScoreResultContent } from '../../components/HeartScore/Result'
import { useHeartScoreResultPage } from '../../hooks/pages/heart-score-result'

import { HeartScoreResult, ResultStatus } from '../../models/heartscore-result'

const arrStatus: ResultStatus[] = [ResultStatus.HIGH, ResultStatus.MEDIUM, ResultStatus.LOW, ResultStatus['N/A']]

const randomStatus = (): ResultStatus => {
  const random = Math.floor(Math.random() * 3)
  return arrStatus[random]
}

const dummyDataHeartScoreResultLow: HeartScoreResult = {
  totalScore: 45,
  totalRawScore: 582,
  totalStatus: ResultStatus.LOW,
  content: `
    This puts you in the <b class='text-color-low-risk'>low-risk</b> category. We encourage you to embrace your health and well-being by starting the MyAdesso Learning Journey. Stay vital and fabulous by empowering yourself with how to continue living the most heart-healthy life you can! Congratulations. See you there!
  `,
  details: [
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Tell Us About Yourself:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Lifestyle Choices:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Your Feelings And Mental Health:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Food Insecurity:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Job Satisfaction:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Past Medical History:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Heart History:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'The Women Stuff:' },
    { score: 5, scoreRaw: 15, status: randomStatus(), category: 'Your Family History:' },
  ],
}

const dummyDataHeartScoreResultMedium: HeartScoreResult = {
  totalScore: 70,
  totalRawScore: 774,
  totalStatus: ResultStatus.MEDIUM,
  content: `
    This puts you in the <b class='text-color-medium-risk'>moderate-risk</b> category.<br/><br/>
    We are so pleased that you have started your MyAdesso heart health journey. You will soon learn more about your specific risks and how you can make changes to improve your heart health. We will help you reach your goals and achieve ultimate wellness. 
    <br/><br/>You are now ready to create your own heartcare routine. With each session you'll be able to personalize your plan that's right for you, including what to discuss during your next physical exam. If you're enrolled in the Adesso Clincial Program, discuss these results with your physician. Welcome to Adesso. The time for heart health is Now! 
  `,
  details: [
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Tell Us About Yourself:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Lifestyle Choices:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Your Feelings And Mental Health:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Food Insecurity:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Job Satisfaction:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Past Medical History:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Heart History:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'The Women Stuff:' },
    { score: 50, scoreRaw: 478, status: randomStatus(), category: 'Your Family History:' },
  ],
}

const dummyDataHeartScoreResultHigh: HeartScoreResult = {
  totalScore: 80,
  totalRawScore: 865,
  totalStatus: ResultStatus.HIGH,
  content: `
    This puts you in the <b class='text-color-high-risk'>high-risk</b> category. Don\'t worry...we want to make sure that you and your heart are okay!
    <br/>
    <br/><b>Call your doctor's office/human resources department/employee benefits to find a cardiologist on your plan.</b>
    <br/>
    <br/>Once you are evaluated, you can join our clinical program to have testing done to create your own personalized diet, exercise, and stress management program. This will be created specifically for YOU, as soon and we have the ok from the cardiologist.
    <br/>
    <br/>Bring your assessment with you so your doctor can see our concerns.
    <br/>In the meantime, you have become part of the MyAdesso Learning Journey to help you empower yourself with information to address the issues that you can change.
  `,
  details: [
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Tell Us About Yourself:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Lifestyle Choices:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Your Feelings And Mental Health:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Food Insecurity:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Job Satisfaction:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Past Medical History:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Heart History:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'The Women Stuff:' },
    { score: 80, scoreRaw: 865, status: randomStatus(), category: 'Your Family History:' },
  ],
}

const arrDummyData: HeartScoreResult[] = [dummyDataHeartScoreResultHigh, dummyDataHeartScoreResultMedium, dummyDataHeartScoreResultLow]

export const HeartScoreResultPage = () => {
  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()

  useEffect(() => {
    if (resultHeartScore?.data) return
    getResultHeartScore()
  }, [resultHeartScore?.data])

  const heartScoreResultContent = useMemo(() => {
    const isLoading = resultHeartScore?.isLoading || false
    const dataResultHeartScore = resultHeartScore?.data?.results || arrDummyData[Math.floor(Math.random() * 3)] || undefined

    return <HeartScoreResultContent data={dataResultHeartScore} isLoading={isLoading} />
  }, [resultHeartScore])

  return <>{heartScoreResultContent}</>
}
