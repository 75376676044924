import { useMemo } from 'react'

type Props = {
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
}

export const Wrapper = ({ className, children, style }: Props) => {
  const wrapper = useMemo(() => {
    return (
      <div className={`h-full w-full bg-white p-5 font-proxima-nova ${className}`} style={style}>
        {children}
      </div>
    )
  }, [children])

  return <>{wrapper}</>
}
