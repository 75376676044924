import { useCallback } from 'react'
import { AdessoError } from '../lib/error'
import { PramsLifeStyle } from '../models'
import { postSubmitLifeStyle } from '../lib/request/post-submit-lifeStyle'
import { getLifeStyleRx, getOptionsOfLifeStyleRx } from '../lib/request/get-lifeStyle'

export function useLifeStyle() {
  const getLifeStyle = useCallback(async () => {
    try {
      return await getLifeStyleRx()
    } catch (error) {
      throw new AdessoError('Get LifeStyle Failed')
    }
  }, [])

  const getLifeStyleOptions = useCallback(async () => {
    try {
      return await getOptionsOfLifeStyleRx()
    } catch (error) {
      throw new AdessoError('Get LifeStyle Options Failed')
    }
  }, [])

  const submitLifeStyle = useCallback(async (prams: PramsLifeStyle) => {
    try {
      return await postSubmitLifeStyle(prams)
    } catch (error) {
      throw new AdessoError('submit LifeStyle Failed')
    }
  }, [])

  return { getLifeStyle, getLifeStyleOptions, submitLifeStyle }
}
