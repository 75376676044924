import React, { useEffect, useMemo, useState } from 'react'
import WrapperMyHealth from '../MyHealthWrapper'
import { useLocales } from '../../../locales'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// import './dots.css'
import { Link, useNavigate } from 'react-router-dom'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { FormValues } from '../../../models'
import { IconLoading } from '../../Common'
import { LoadingSize } from '../../../models/loading'
import { Deeper } from '../../../models/deepers'
import { ButtonMyHealthV2 } from '../Button'
import { useDebounceCallback } from '../../../hooks/useDebounceCallback'

type Props = {
  prevScreen?: () => void
  nextScreen?: (edit?: boolean) => void
  postDataResult?: FormValues
  isLoading?: boolean
  isLoadingDeepers?: boolean
  isLoadingToggle?: boolean
  deepers?: Deeper[]
  stateCreate?: boolean
  submit?: (params: FormValues, toggle?: boolean) => void
}

type PropsContentButton = {
  title: string
  buttonName: string
  onClick?: () => void
}

const Results = ({ prevScreen, nextScreen = () => {}, postDataResult, isLoading, deepers, isLoadingDeepers, stateCreate, isLoadingToggle, submit }: Props) => {
  const { t } = useLocales()
  const navigate = useNavigate()

  const [stateApproved, setStateApproved] = useState(postDataResult?.approvedByPhysician ?? false)

  useEffect(() => {
    setStateApproved(postDataResult?.approvedByPhysician || false)
  }, [postDataResult, isLoading])

  const isObjectEmpty = (): boolean => {
    const obj = (Object.entries(postDataResult || {}) as [keyof FormValues, boolean][]).filter(([key, value]) => {
      return value === true
    })

    return obj.length === 0
  }

  const button = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }

    return (
      <ButtonMyHealthV2 nameButton={t('mainMenuAndSection.button.createLifeStyle')} bgButton="bg-color-bg-primary" nextScreen={() => nextScreen(false)} width={'400px'} stateCreate={stateCreate} />
    )
  }, [stateCreate, isLoading])

  const itemsCarousel = useMemo(() => {
    if (isLoadingDeepers) {
      return (
        <div className="flex w-full justify-center">
          <IconLoading size={LoadingSize.Normal} />
        </div>
      )
    }
    return (
      deepers &&
      deepers.length > 0 &&
      deepers?.map((item, index) => {
        return (
          <div key={`${item.slug}-${index}`} className="carousel-item w-1/2 flex-col">
            <SwiperSlide>
              <div className="mb-2 aspect-video min-w-[170px] rounded-md bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url("${item.thumbnail}")` }}></div>
              <div className="ml-[4px]">
                <Link to={`/go-deeper/${item.slug}`} className="font-medium capitalize leading-[18px] tracking-[0.07em] text-black sm:text-[14px] md:text-[24px]">
                  {item.title}
                </Link>
              </div>
            </SwiperSlide>
          </div>
        )
      })
    )
  }, [deepers])

  const LifeStyleForAHealthy = () => {
    return (
      <div className="my-6">
        <p className='font-["Proxima Nova"] text-[15px] font-[600] leading-[21px]'>{t('mainMenuAndSection.lifeStyleRx.lifeStyleForAHealthyHeart')}</p>
        <Swiper
          slidesPerView={2}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {itemsCarousel}
        </Swiper>
        <div className="flex justify-end">
          <Link to="/go-deeper" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
            {t('extraText.exploreGoDeepers')}
          </Link>
        </div>
      </div>
    )
  }

  const ContentButton: React.FC<PropsContentButton> = React.memo(({ title, buttonName, onClick }) => {
    const buttonText = useMemo(() => t(buttonName), [buttonName])
    const buttonTitle = useMemo(() => t(title), [title])

    return (
      <div className="my-6 flex flex-col items-center justify-center">
        <p className='font-["Proxima Nova"] mb-3 text-center text-[14px] font-[500] leading-[20px]'>{buttonTitle}</p>
        <div
          onClick={onClick}
          className="flex w-[347px] max-w-[347px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] bg-[#974A9A] p-4 px-10 uppercase text-[#FFF]"
        >
          <h3 className="font-proxima-nova text-[15px] font-medium">{buttonText}</h3>
        </div>
      </div>
    )
  })

  const toggleApproved = useDebounceCallback(() => {
    if (!postDataResult || typeof postDataResult !== 'object') {
      return
    }

    const newApprovalStatus = !stateApproved
    setStateApproved(newApprovalStatus)
    const newPostDataResult = {
      ...postDataResult,
      approvedByPhysician: newApprovalStatus,
    }

    if (submit) {
      submit(newPostDataResult, true)
    }
  }, 300)

  const MyRecommendation = useMemo(() => {
    return (
      <div>
        <p className='font-["Proxima Nova"] text-[15px] font-[600] leading-[21px]'>{t('mainMenuAndSection.myRecommendation')}</p>

        <div className=" my-3  w-full flex-col items-stretch justify-between  rounded-[15px] bg-[#F9F7F7] p-4 shadow-md">
          <ul className="list-none space-y-2">
            {(Object.entries(postDataResult || {}) as [keyof FormValues, boolean][])
              .filter(([key, value]) => {
                return key !== 'approvedByPhysician' && value === true
              })
              .map(([key, value]) => (
                <li key={key} className="flex items-center">
                  <div className="flex h-[31px] w-[31px] items-center justify-center rounded-full  border border-[#FF3399] ">
                    <div className=" h-[11px] w-[14px] bg-i-check bg-contain bg-center bg-no-repeat" />
                  </div>
                  <span className="font-['Proxima Nova'] ml-2  text-[16px] font-[600] leading-[21px]">{key}</span>
                </li>
              ))}
          </ul>
          <div className="mt-4 flex items-center justify-between">
            <button
              onClick={() => nextScreen(true)}
              className={`flex items-center justify-center bg-transparent ${
                isLoadingToggle ? 'cursor-not-allowed text-gray-500 opacity-50' : 'cursor-pointer text-[#FF3399]'
              } transition-opacity duration-300 ease-in-out`}
              disabled={isLoadingToggle}
            >
              <div className="h-[20px] w-[20px] bg-i-pensil" />
              <p className="font-['Proxima Nova'] ${ isLoadingToggle ? 'text-gray-500' :'text-[#FF3399]' } ml-1 text-[14px] font-[400] leading-[14px]">{t('mainMenuAndSection.button.edit')}</p>
            </button>

            <div className="flex items-center">
              <span className={`font-['Proxima Nova'] ml-1 mr-2 cursor-pointer  text-[12px] font-[400] leading-[14px] ${postDataResult?.approvedByPhysician ? 'text-[#000000]' : 'text-[#FF3399]'}`}>
                {t('mainMenuAndSection.approvedByPhysician')}
              </span>
              <button
                type="button"
                onClick={toggleApproved}
                className={`flex h-[14px] w-[34px] items-center rounded-full p-1 transition-colors duration-300 ${postDataResult?.approvedByPhysician ? 'bg-[#FF339959]' : 'bg-gray-300'}`}
              >
                <span
                  className={`block h-5 w-5 transform rounded-full  shadow transition-transform duration-300 ${
                    postDataResult?.approvedByPhysician ? 'translate-x-2 bg-[#FF3399]' : 'translate-x-[-2px] bg-white'
                  }`}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }, [postDataResult, stateApproved, isLoadingToggle])

  const handleClick = () => {
    navigate('/goal-tracking-configuration?tab-gtc-from-rx=4')
  }

  return (
    <>
      <WrapperMyHealth title="lifeStyle" prevScreen={prevScreen} button={button}>
        <div>
          {isLoading ? (
            <div className="flex justify-center">
              <IconLoading size={LoadingSize.Medium} />
            </div>
          ) : (
            ''
          )}
          {!isObjectEmpty() && MyRecommendation}

          <p className='font-["Proxima Nova"] text-[15px] font-[600] leading-[21px]'>{t('mainMenuAndSection.drSuzanne')}</p>

          <p className='font-["Proxima Nova"] my-3 text-[14px] font-[500] leading-[20px]'>{t('mainMenuAndSection.lifeStyleRx.recommends')}</p>
        </div>
      </WrapperMyHealth>

      <LifeStyleForAHealthy />

      <ContentButton title={'mainMenuAndSection.doYouWantLifestyle'} buttonName={'mainMenuAndSection.button.goToCommit'} onClick={handleClick} />

      <div className="hidden">
        <ContentButton title={'mainMenuAndSection.doYouOwnLifestyle'} buttonName={'mainMenuAndSection.button.connect'} />
      </div>
    </>
  )
}

export default Results
