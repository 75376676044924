type Props = {
  title?: string
  track?: React.ReactNode
  className?: string
  children?: React.ReactNode
}

export const GoalTrackConfigSection = ({ title, track, className, children }: Props) => {
  return (
    <section className={className}>
      <div className="flex w-full justify-between gap-[10px]">
        {title && <h3 className="w-[60%] font-['Butler'] text-[24px] leading-[1.41667]">{title}</h3>}
        {track}
      </div>
      {children}
    </section>
  )
}
