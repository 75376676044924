import { useState, useCallback, useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion02Questions } from '../../../../../lib/data/session-02'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

import { setAmplitude } from '../../../../../lib/utils/helper'

const ssHAQuestions = [sesstion02Questions[29], sesstion02Questions[30]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers?: any
}

export const ScreenCoreConcepts07 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    // onUpdate(formik.values)
    onUpdate({ ...formik.values, ss02__027: String(formik.values.ss02__027) })
    setAmplitude('age', formik.values.ss02__027)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    const hasIsRequire = Object.values(formik?.values).some((value) => value === '')

    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} isDisableNext={hasIsRequire} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal, formik.values])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session02Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6 text-[18px] leading-[22px]">
            <h4 className="font-bold">Calculate your Target Heart Rate.</h4>
            <picture className="block h-auto w-full">
              <source srcSet={`/images/my-adresso-journey/session/02/img_007.webp`} type="image/webp" />
              <img src={`/images/my-adresso-journey/session/02/img_007.jpg`} alt="" />
            </picture>
            <p>
              Wondering how you calculate your Target Heart Rate?
              <br />
              <br />
              Enter the following information to calculate your target heart rate based on your current level of fitness:
            </p>

            {ssHAQuestions.map((item) => {
              return item.name === 'ss02__027' ? (
                <FormField key={`${item.name}`} typeInput="number" min={1} max={90} isInputSS question={item} value={String(formik.values[item.name])} onChange={formik.handleChange}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              ) : (
                <FormField key={`${item.name}`} isInputSS question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session02Wrapper>
    )
  }, [formik, buttons, isError])

  return <>{screenHeartAssessment02}</>
}
