import { useMemo } from 'react'
import { HeartJournalSection } from '../HeartJournal/Section'
import { mapGoalQuestions } from '../../lib/maps/goal'
import { GoalQuestionsForm } from '../HeartActions/GoalQuestionsForm'
import { HeartJournal } from '../../models/heart-journal'
import { IconLoading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { HeartJournalAction } from '../HeartJournal/Action'

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

type Props = {
  date: string
  isLoading: boolean
  heartJournal?: HeartJournal
}

export const HeartJournalArchiveContent = ({ date, isLoading, heartJournal }: Props) => {
  let epoch = Date.parse(date)
  let dateValue = new Date(epoch)
  let formattedDate = monthNames[dateValue.getMonth()] + ' ' + dateValue.getDate() + ', ' + dateValue.getFullYear()

  const allGoalsData = useMemo(() => {
    if (!heartJournal) {
      return (
        <div className="flex items-center justify-center py-24">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }

    if (!heartJournal.goals.length) {
      return <div>No Data</div>
    }

    return (
      <div className="grid grid-cols-1 gap-y-5">
        {heartJournal.goals.length &&
          heartJournal.goals.map((goal, index) => {
            let mappedQuestions = mapGoalQuestions(goal.questions)
            const headerStyles = { backgroundColor: goal.backgroundColor ? goal.backgroundColor : '#FF3399' }
            const descriptionStyles = { color: goal.backgroundColor || '' }
            return (
              <div key={goal.id}>
                {goal.description && (
                  <>
                    <div style={headerStyles} className="flex w-full items-center justify-start gap-2 rounded-2xl p-4">
                      <span className="text-[15px] font-bold uppercase leading-[17px] text-white">{goal.name}</span>
                    </div>
                    <h6 style={descriptionStyles} className="mt-1 text-[18px] font-bold text-color-text-primary">
                      {goal.description}
                    </h6>
                  </>
                )}
                {!goal.description && (
                  <div style={headerStyles} className="mb-3 flex w-full items-center justify-start gap-2 rounded-2xl p-4">
                    <span className="text-[15px] font-bold uppercase leading-[17px] text-white">{goal.name}</span>
                  </div>
                )}
                <GoalQuestionsForm
                  key={goal.id}
                  goalMemoId={null}
                  backgroundColor={goal.backgroundColor}
                  textColor={goal.textColor}
                  className="mt-2 pb-4"
                  id={goal.id}
                  editable={false}
                  completed={true}
                  questions={mappedQuestions}
                  hideAfterSubmit={true}
                  afterSubmit={() => {}}
                />
              </div>
            )
          })}
      </div>
    )
  }, [heartJournal])

  return (
    <>
      <HeartJournalSection className="w-full px-5 pb-20" title="Heart Journal">
        <HeartJournalAction date={date}>{allGoalsData}</HeartJournalAction>
      </HeartJournalSection>
    </>
  )
}
