import { useMemo } from 'react'

import { ButtonCommon, ButtonStyles, SubmitButtonCommon } from '../../Common'

type Props = {
  style?: ButtonStyles
  isDisable?: boolean
  isSubmit?: boolean
  isLoading?: boolean
  showModal?: () => void
  nextScreen?: () => void
  backScreen?: any
}

export const HeartScoreButtons = ({ style, isDisable = false, isSubmit = false, isLoading = false, showModal, backScreen, nextScreen }: Props) => {
  const heartScoreButtons = useMemo(() => {
    return (
      <div className={`flex ${showModal ? 'justify-between' : 'justify-center space-x-2'}`}>
        {backScreen && (
          <div>
            <ButtonCommon style={style} width="w-28" height="h-[50px]" text="Back" onClick={backScreen} />
          </div>
        )}
        {showModal && (
          <div>
            <ButtonCommon style={style} width="w-28" height="h-[50px]" text="Show Modal" onClick={showModal} />
          </div>
        )}
        {nextScreen && (
          <div>
            {isSubmit ? (
              <SubmitButtonCommon disabled={isDisable} style={style} width="w-28" height="h-[50px]" text="Next" isLoading={isLoading} onClick={nextScreen} />
            ) : (
              <ButtonCommon disabled={isDisable} style={style} width="w-28" height="h-[50px]" text="Next" onClick={nextScreen} />
            )}
          </div>
        )}
      </div>
    )
  }, [style, isSubmit, isLoading, isDisable, showModal, backScreen, nextScreen])

  return <>{heartScoreButtons}</>
}
