import React from 'react'
import HeartScore from '../../components/MyHealth/HeartScore'
import { useNavigate } from 'react-router-dom'

interface IProps {}

const HeartScoreMyHealthPage = ({}: IProps) => {
  const navigate = useNavigate()

  return <HeartScore prevScreen={() => navigate('/my-health')} />
}

export default HeartScoreMyHealthPage
