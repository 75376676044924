import { useNavigate } from 'react-router-dom'
import { Logo } from '../../components/Partials/Logo'
import { ButtonCommon, ButtonStyles } from '../../components/Common/Button'

export const Landing = () => {
  const navigate = useNavigate()

  return (
    <div className="flex h-full min-h-screen flex-col items-center font-sans">
      <div className="flex h-full w-full flex-1 justify-center bg-white text-black">
        <div className="flex w-full min-w-[375px] justify-center">
          <div className="flex h-full w-full max-w-lg flex-col items-center">
            <Logo className="mt-28 block h-[34px] w-[145px] hover:opacity-80" />
            <div className="mt-40 flex justify-center">
              <ButtonCommon style={ButtonStyles.Session} width="w-[300px]" height="h-[50px]" text="LOG IN" onClick={() => navigate('/login')} />
            </div>
            <div className="mt-2">
              <ButtonCommon style={ButtonStyles.Session} width="w-[300px]" height="h-[50px]" text="SIGN UP" onClick={() => navigate('/register')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
