import { useMemo } from 'react'
import { Session01Wrapper } from './Wrapper'

type Props = {
  content: string
  buttons?: React.ReactNode
  children?: React.ReactNode
}

export const CoreConceptsWrapper = ({ buttons, children, content }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <div className="session-style" dangerouslySetInnerHTML={{ __html: content }} />

          {children}
        </div>
      </Session01Wrapper>
    )
  }, [buttons, content, children])

  return <>{screenCoreConcepts}</>
}
