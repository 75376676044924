import axios from 'axios'

import { AdessoError } from '../error'

export async function putJourneysCurrentStep(params: object): Promise<Boolean> {
  const { data, status } = await axios.put('/journeys/update-current-step', params)

  console.info('Data =>', data)
  console.info('Status =>', status)

  if (status !== 200) {
    console.info('putJourneysCurrentStep', data)
    throw new AdessoError(`Request putJourneysCurrentStep error - ${status} ${JSON.stringify(data)}`)
  }

  return true;
}
