import axios from 'axios'

import { AdessoError } from '../../lib/error'
import { ChangePasswordFields } from '../../models/login'

type RequestResponse = {
    status: boolean
    errorMessage?: object
}

export async function postChangePassword ( params: ChangePasswordFields ): Promise<RequestResponse> {
    const { data, status } = await axios.post( '/change-password', params )

    if ( status !== 200 ) {
        console.info( 'postLogin', data )
        throw new AdessoError( `Request login error - ${status} ${JSON.stringify( data )}` )
    }

    let dataReturn: RequestResponse
    if ( data.message === 'Success!' ) {
        dataReturn = {
            status: true
        }
    } else {
        dataReturn = {
            status: false,
            errorMessage: data.message,
        }
    }

    return dataReturn
}
