import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { WrapperCommon, ButtonCommon, ButtonStyles } from '../../Common'

type Props = {
  isNextLevel?: boolean
}

export const MAJourneyRevicedContent = ({ isNextLevel = false }: Props) => {
  const { slug } = useParams()

  const navigate = useNavigate()

  const handleNavigate = useCallback((nextPage: string) => {
    navigate(nextPage)
  }, [])

  const screenDefault = useMemo(() => {
    return (
      <div className="space-y-6 text-center text-white">
        <h1 className="w-full font-butler text-[35px] leading-[39px]">
          Congratulations!
          <br />
          You did it!
        </h1>

        <p className="w-full text-[20px] font-semibold leading-[24px]">
          You completed this session and earned 10 heart beats.
          <br />
          <br />
          To advance to your next Heart Level, keep logging your actions, reviewing Go Deeper activities and advancing through your heart journey to gain more heart beats.
        </p>
      </div>
    )
  }, [])

  const screenNextLevel = useMemo(() => {
    return (
      <div className="space-y-6 text-center text-white">
        <h1 className="w-full font-butler text-[35px] leading-[39px]">
          Congratulations!
          <br />
          You did it!
        </h1>

        <p className="w-full text-[20px] font-semibold leading-[24px]">You’ve achieved the Heart Basics Level!</p>

        <div className="mx-auto flex h-[218px] w-[218px] items-center justify-center rounded-full bg-ma-gray-300 p-4 text-[16px] font-bold leading-[22px] text-black">
          Placeholder for
          <br />
          badge
        </div>

        <p className="w-full text-[20px] font-semibold leading-[24px]">
          To unlock your next level, work to complete your actions and keep advancing through your heart journey.
          <br />
          <br />
          Next Up: Getting to Know Your Heart
        </p>
      </div>
    )
  }, [])

  const boxButton = useMemo(() => {
    return (
      <div className="flex flex-col justify-center space-y-4 pt-11">
        <ButtonCommon style={ButtonStyles.Light} width="w-[250px]" height="h-[50px]" onClick={() => handleNavigate(`/my-adesso-journey/go-deeper/${slug}`)}>
          <span className="text-ma-pink">GO DEEPER LIBRARY</span>
        </ButtonCommon>

        <ButtonCommon style={ButtonStyles.Light} width="w-[250px]" height="h-[50px]" onClick={() => handleNavigate('/my-adesso-journey')}>
          <span className="text-ma-pink">NEXT SESSION</span>
        </ButtonCommon>

        <ButtonCommon style={ButtonStyles.Light} width="w-[250px]" height="h-[50px]" onClick={() => handleNavigate('/dashboard')}>
          <span className="text-ma-pink">
            RETURN TO
            <br />
            DASHBOARD
          </span>
        </ButtonCommon>
      </div>
    )
  }, [])

  const maJourneyRevicedContent = useMemo(() => {
    return (
      <WrapperCommon
        className={`min-h-[calc(100vh-56px)] bg-cover bg-center bg-no-repeat ${
          isNextLevel ? `bg-[url('/backgrounds/bg-maj__reviced_screen-02.webp')]` : `bg-[url('/backgrounds/bg-maj__reviced_screen-01.webp')]`
        }`}
      >
        <div className="flex flex-col space-y-6 py-9">
          {isNextLevel ? screenNextLevel : screenDefault}
          {boxButton}
        </div>
      </WrapperCommon>
    )
  }, [isNextLevel])

  return <>{maJourneyRevicedContent}</>
}
