import axios from 'axios'

import {AdessoError} from '../../lib/error'
import {LoginOptions} from '../../models/login'
import {AdessoResult} from "../../models";

type UpdateIntroductionResult = {
  success: boolean,
  data?: object
}

export async function postUpdateIntroduction(): Promise<UpdateIntroductionResult> {
  const {data, status} = await axios.post('/introductions')

  if (status !== 200) {
    console.info('postUpdateIntroduction', data)
    throw new AdessoError(`Update introduction error - ${status} ${JSON.stringify(data)}`)
  }

  let dataReturn: UpdateIntroductionResult
  if (data.message === 'Success!') {
    dataReturn = {
      success: true,
      data: data.data,
    }
  } else {
    dataReturn = {
      success: false,
      data: undefined
    }
  }

  return dataReturn
}
