import axios from 'axios'
import { GoalFromApiResponse } from '../../models/goal'
import { AdessoError } from '../error'
import { MyNow } from '../../models'

type UpdateGoalSettingIdResponse = {
  success: boolean
  data: any | undefined
  message: string
}

export async function postUpdateGoalSettingId(goal_setting_id: number, data_goal_setting: any): Promise<UpdateGoalSettingIdResponse> {
  const { data, status } = await axios.post(`/goal-setting/${goal_setting_id}`, data_goal_setting)
  if (status !== 200) {
    console.info('postUpdateGoalSettingId', data)
    throw new AdessoError(`Request update goal setting id error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
