import {useCallback, useState} from 'react'
import {AdessoResult, MyNow} from "../../models"
import {useAuth} from "../use-auth"
import {useMyNow} from "../use-my-now"
import {useLocalStorage} from "../use-local-storage";

type MyNowResponse = {
  myNow?: MyNow
}


export function useMyNowPage() {
  const {setItemLS} = useLocalStorage()
  const [myNowResult, setMyNowResult] = useState<AdessoResult<MyNowResponse>>()

  const auth = useAuth()
  const myNow = useMyNow()

  const getMyNow = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMyNowResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await myNow.getMyNow()
        if (res.success) {
          setMyNowResult({
            data: {myNow: res.data},
            isLoading: false,
            error: undefined
          })
          if (res.data?.content){
            setItemLS('myNow', res.data.content)
          }
        } else {
          setMyNowResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message)
          })
        }
      }
    } catch (e) {
      setMyNowResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Heart Journal error')
      })
    }
  }, [])

  const updateMyNow = useCallback(async (content: string) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMyNowResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await myNow.updateMyNow(content)
        if (res.success) {
          setMyNowResult({
            data: {myNow: res.data},
            isLoading: false,
            error: undefined
          })
        } else {
          setMyNowResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message)
          })
        }
      }
    } catch (e) {
      setMyNowResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Update my now error')
      })
    }
  }, [])

  return {
    myNowResult,
    getMyNow,
    updateMyNow
  }
}
