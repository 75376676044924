import { useMemo } from 'react'

import { ButtonCommon, ButtonStyles, SubmitButtonCommon } from '../../../Common'
import { useLocales } from '../../../../locales';

type Props = {
  styleButton?: 'dark' | 'light'
  isSubmit?: boolean
  isLoading?: boolean
  isDisableNext?: boolean
  showModal?: () => void
  nextScreen?: () => void
  backScreen?: () => void
}

export const SessionButtons = ({ styleButton, isSubmit = false, isLoading = false, isDisableNext = false, showModal, backScreen, nextScreen }: Props) => {
  const style = styleButton === 'dark' ? ButtonStyles.Dark : ButtonStyles.Light
  const {t} = useLocales()

  const sessionButtons = useMemo(() => {
    return (
      <div className={`flex justify-center space-x-2 pt-6`}>
        {backScreen && (
          <div>
            <ButtonCommon style={style} width="w-28" height="h-[50px]" text={t('common.back')} onClick={backScreen} />
          </div>
        )}
        {showModal && (
          <div>
            <ButtonCommon style={style} width="w-28" height="h-[50px]" onClick={showModal}>
              <div className="h-full w-full bg-[url('assets/images/icon/i-menu.svg')] bg-[length:26px_auto] bg-center bg-no-repeat" />
            </ButtonCommon>
          </div>
        )}
        {nextScreen && (
          <div>
            <SubmitButtonCommon disabled={isDisableNext} style={style} width="w-28" height="h-[50px]" text={t('common.next')} isLoading={isLoading} onClick={nextScreen} />
          </div>
        )}
      </div>
    )
  }, [style, isSubmit, isLoading, isDisableNext, showModal, backScreen, nextScreen])

  return <>{sessionButtons}</>
}
