import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DashboardContent } from '../../components/Dashboard/'
import { useGoDeeperLibraryPage } from '../../hooks/pages/go-deeper'
import { useHeartJournalPage } from '../../hooks/pages/heart-journal'
import { useMyNowPage } from '../../hooks/pages/my-now'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { useMAJPage } from '../../hooks/pages/maj'

export const Dashboard = () => {
  const { getItemLS } = useLocalStorage()
  const [score, setScore] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const { myNowResult, getMyNow, updateMyNow } = useMyNowPage()
  const { dataDeepers, getDataDeepers } = useGoDeeperLibraryPage()
  const { myGoalsResult, getMyGoals } = useHeartJournalPage()
  const { dataSessions, getDataSessions, dataNextSession } = useMAJPage()
  const navigate = useNavigate()

  const userLocalStorage: any = getItemLS('user')
  const doneIntroduction = getItemLS('doneIntroduction')

  useEffect(() => {
    if (userLocalStorage && !userLocalStorage.introduced && doneIntroduction && doneIntroduction !== 'yes') {
      navigate('/introduction')
    }
    setScore(userLocalStorage?.heart_basic)
    setIsLoading(false)
  }, [userLocalStorage])

  useEffect(() => {
    if (myNowResult?.data) return
    getMyNow()
  }, [myNowResult?.data])

  useEffect(() => {
    if (dataDeepers?.data) return
    getDataDeepers()
  }, [dataDeepers?.data])

  useEffect(() => {
    if (myGoalsResult?.data) return
    getMyGoals(true)
  }, [myGoalsResult?.data])

  useEffect(() => {
    if (dataSessions?.data) return
    getDataSessions()
  }, [dataSessions?.data])

  const dashboard = useMemo(() => {
    if (isLoading) {
      return <></>
    }
    const myNow = myNowResult?.data?.myNow

    const deeperIsLoading = dataDeepers?.isLoading || false
    const deepers = dataDeepers?.data?.deepers || []

    const myGoalsIsLoading = !myGoalsResult?.data
    const myGoals = myGoalsResult?.data?.goals || []

    const nextSessionIsLoading = dataSessions?.isLoading
    const nextSession = dataNextSession

    return (
      (
        <DashboardContent
          score={score}
          myNow={myNow?.content ?? ''}
          handleUpdateMyNow={updateMyNow}
          deepers={deepers}
          isLoading={deeperIsLoading}
          goals={myGoals}
          myGoalsIsLoading={myGoalsIsLoading}
          nextSession={nextSession}
          nextSessionIsLoading={nextSessionIsLoading}
        />
      ) || undefined
    )
  }, [dataDeepers, myGoalsResult, dataSessions, isLoading])

  return <>{dashboard}</>
}
