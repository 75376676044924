import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Wrapper, ButtonCommon, ButtonStyles } from '../../Common'

import { HeartScoreButtons, IntroductionLayout } from '../layout'

import { useLocalStorage } from '../../../hooks/use-local-storage'

type Props = {
  backScreen: () => void
}

export const FinishedScreen = ({ backScreen }: Props) => {
  const navigate = useNavigate()

  useEffect(() => {
    const { deleteItemLS } = useLocalStorage()
    deleteItemLS('amplitude_heart_score_survey_questions')
  }, [])

  const buttons = useMemo(() => {
    return <ButtonCommon style={ButtonStyles.Light} width="w-[150px]" height="h-[50px]" text="See My Results" onClick={() => navigate('/heart-score/result')} />
    // return <HeartScoreButtons backScreen={backScreen} nextScreen={() => navigate('/heart-score/result')} />
  }, [backScreen])

  return (
    <Wrapper className="bg-transparent text-ma-md text-white">
      <div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-white pt-20">
        <div className="h-full bg-heartscore-finished bg-cover bg-center bg-no-repeat"></div>
      </div>
      <IntroductionLayout className="z-20" buttons={buttons}>
        <div className="space-y-7 py-7">
          <h2 className="text-center text-[30px] leading-[34px]">
            That’s it!
            <br /> You’ve finished.
          </h2>

          <p className="pb-7 text-center">
            We have already emailed you your results. Please check spam. You will want to keep these for future reference.
            <br />
            <br />
            If you have any problems contact{' '}
            <a className="font-semibold text-white underline hover:text-black" href="mailto:info@hearttech.health">
              info@hearttech.health
            </a>
            .
            <br />
            <br />
          </p>

          <div className="mx-auto flex  h-[50px] w-[248px] items-center rounded-[25px] bg-ma-pink-500 bg-[url('assets/images/icon/i-visit_your_heart.svg')] bg-[length:auto_23px] bg-[left_15px_center] bg-no-repeat pl-14 pr-2 text-[10px] uppercase leading-[11px] tracking-[0.15px] text-white hover:text-white">
            Visit your Heart Chart at any time to review your past heart score, testing and lab results
          </div>
        </div>
      </IntroductionLayout>
    </Wrapper>
  )
}
