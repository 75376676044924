import { useMemo, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IconLoading } from '../Common/'
import { DashboardSection } from '../Common/DashboardSection'

import { Deeper } from '../../models/deepers'
import { LoadingSize } from '../../models/loading'
import { useLocales } from '../../locales'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './dots.css'

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

const IntroductionCarouselItems = [
  {
    id: 'what_to_eat_to_lower_your_cholesterol',
    title: 'What to eat to lower your cholesterol',
    thumbnail: 'images/deeper/1.webp',
    link: '#',
  },
  {
    id: 'juicing_for_a_healthy_heart',
    title: 'Juicing for a healthy heart',
    thumbnail: 'images/deeper/2.webp',
    link: '#',
  },
  {
    id: 'what_to_eat_to_lower_your_cholesterol_2',
    title: 'What to eat to lower your cholesterol',
    thumbnail: 'images/deeper/1.webp',
    link: '#',
  },
  {
    id: 'juicing_for_a_healthy_heart_2',
    title: 'Juicing for a healthy heart',
    thumbnail: 'images/deeper/2.webp',
    link: '#',
  },
]

type Props = {
  isLoading?: boolean
  isIntroduction: boolean | undefined
  deepers?: Deeper[]
  jumpTo: React.Ref<any>
}

export const GoDeeper = ({ isLoading, isIntroduction, deepers, jumpTo }: Props) => {
  const navigate = useNavigate()
  const { t } = useLocales()

  const carouselContainer = useRef<HTMLDivElement>(null)
  const coverCarousel = useRef<HTMLDivElement>(null)

  const itemsCarousel = useMemo(() => {
    if (isIntroduction) {
      return IntroductionCarouselItems.map((item, index) => {
        return (
          <div key={`${item.id}-${index}`} className="carousel-item w-1/2 flex-col">
            <SwiperSlide>
              <div className="mb-2 aspect-video min-w-[170px] cursor-pointer rounded-md bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url("${item.thumbnail}")` }}></div>
              <div className="ml-[4px]">
                <Link to={`/go-deeper/${item.id}`} className="font-medium capitalize leading-[18px] tracking-[0.07em] text-black sm:text-[14px] md:text-[24px]">
                  {item.title}
                </Link>
              </div>
            </SwiperSlide>
          </div>
        )
      })
    } else if (isLoading) {
      return (
        <div className="flex w-full justify-center">
          <IconLoading size={LoadingSize.Normal} />
        </div>
      )
    }
    return (
      deepers &&
      deepers.length > 0 &&
      deepers.map((item, index) => {
        return (
          <div key={`${item.slug}-${index}`} className="carousel-item w-1/2 flex-col">
            <SwiperSlide>
              <div
                className="mb-2 aspect-video min-w-[170px] cursor-pointer rounded-md bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url("${item.thumbnail}")` }}
                onClick={() => navigate(`/go-deeper/${item.slug}`)}
              ></div>
              <div className="ml-[4px]">
                <Link to={`/go-deeper/${item.slug}`} className="font-medium capitalize leading-[18px] tracking-[0.07em] text-black sm:text-[14px] md:text-[24px]">
                  {item.title}
                </Link>
              </div>
            </SwiperSlide>
          </div>
        )
      })
    )
  }, [deepers])

  const handleScroll = (type: string) => {
    const coverContainer = coverCarousel?.current
    const container = carouselContainer.current

    const step = coverContainer ? coverContainer.offsetWidth / 2 : 0

    if (type === 'left') {
      container &&
        container.scrollTo({
          top: 0,
          left: container.scrollLeft + step,
          behavior: 'smooth',
        })
    } else {
      container &&
        container.scrollTo({
          top: 0,
          left: container.scrollLeft - step,
          behavior: 'smooth',
        })
    }
  }

  return (
    <DashboardSection jumpTo={jumpTo} className={`space-y-4 ${isIntroduction ? 'mb-40' : ''}`} title={t('mainMenuAndSection.goDeeper')}>
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {itemsCarousel}
      </Swiper>
      <div className="flex justify-end">
        <Link to="/go-deeper" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
          {t('extraText.goDeeperLibrary')}
        </Link>
      </div>
    </DashboardSection>
  )
}
