import axios from 'axios'
import { AdessoError } from '../error'

type GetDailyTrackingDetailResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getDailyTrackingDetail(dailyTrackingId: number): Promise<GetDailyTrackingDetailResponse> {
  const { data, status } = await axios.get(`/goal-tracking/${dailyTrackingId}/questions`)

  if (status !== 200) {
    console.info('getDailyTrackingDetail', data)
    throw new AdessoError(`Request getDailyTrackingDetail error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDailyTrackingDetailResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
