import logoDefaultPath from '../../assets/images/logo.png'
import logoWhitePath from '../../assets/images/logo-white.svg'

type Props = {
  className: string
  isLogoWhite?: boolean
}

export const Logo = ({ className, isLogoWhite = false }: Props) => {
  return <img src={isLogoWhite ? logoWhitePath : logoDefaultPath} className={`${className} `} alt="My Adesso Logo" />
}
