import { format, isAfter, isSameDay, isSameMonth, startOfMonth, subMonths, isValid, parseISO, differenceInDays } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDailyTrackingQuestionsPage } from '../../hooks/pages/daily-tracking-questions'
import { useLocales } from '../../locales'
import { FormQuestionsContent } from './Question/FormQuestionsContent'
import { DailyTrackingSection } from './Section'
import { DailyTrackingWrapper } from './Wrapper'
import { Link, useLocation } from 'react-router-dom'
import { useHeartJournal } from '../../hooks/use-heart-journal'
import { Goal } from '../../models/goal'
import { Loading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { CustomCalendar } from '../Calendar/index'
import { CalanderScreen } from '../../constants'
import { useDailyTracking } from '../../hooks/use-daily-tracking'
import { IntroductionTabDT, IntroductionTabDTDetail } from '../../constants/dataIntroductionDailyTracking'
import { MyAdessoDailyTrackingStep } from '../../constants/introduction'
import { toDate } from '../../lib/utils/helper'

const DATE_FORMAT = 'yyyy-MM-dd'

interface IDailyTracking {
  isIntroduction?: boolean
  refs?: React.Ref<any>[]
}

export const DailyTrackingContent = ({ isIntroduction = false, refs = [] }: IDailyTracking) => {
  const { myGoals, allGoals } = useHeartJournal()
  const { dailyTrackingQuestionDetailResult, getDailyTrackingQuestionDetail, dailyTrackingHistoryResult, getDailyTrackingHistory } = useDailyTrackingQuestionsPage()

  const { getCalendarHistories } = useDailyTracking()
  const tabDailyTrackingDetail = dailyTrackingQuestionDetailResult?.data?.dailyTrackingQuestionDetail

  const dailyTrackingHistory = dailyTrackingHistoryResult?.data?.dailyTrackingHistory

  const { t } = useLocales()
  const location = useLocation()
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([])

  const searchQuery = new URLSearchParams(location.search)
  const trackingId = Number(searchQuery.get('trackingId') as string)
  const label = searchQuery.get('label') as string
  const dateStrParam = searchQuery.get('date') as string
  const dateParam = dateStrParam && isValid(new Date(dateStrParam)) ? new Date(dateStrParam) : undefined

  const tabDtFromGtc = Number(searchQuery.get('tab-dt-from-gtc') as string)
  const [tabDT, setTabDT] = useState<number>(tabDtFromGtc || trackingId || 4)
  const [goals, setGoals] = useState<Goal[]>([])
  const [myGoalIds, setGoalId] = useState<number[]>([])
  const [allGoal, setAllGoals] = useState<Goal[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [date, setDate] = useState(dateParam || new Date())
  const [month, setMonth] = useState(dateParam || new Date())
  const [trackedDate, setTrackedDate] = useState<String[]>([])
  const [dataHistoryCalendar, setDataHistoryCalendar] = useState<String[]>([])
  const [calendarDate, setCalendarDate] = useState(dateParam || new Date())
  const [startCalendarDate, setStartCalendarDate] = useState(new Date())
  const [stateSubmit, setStateSubmit] = useState(false)
  const [loadingCalendar, setLoadingCalendar] = useState(false)

  useEffect(() => {
    const selectedTab = tabRefs.current[goals.findIndex((item: Goal) => item?.goalTrackingId === tabDT)]
    if (selectedTab) {
      selectedTab.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
    }
  }, [tabDT, goals])

  useEffect(() => {
    if (isIntroduction) return
    const fetchAllGoals = async () => {
      const goals = await allGoals()

      setAllGoals(goals?.data)
    }
    fetchAllGoals()
  }, [])

  useEffect(() => {
    if (isIntroduction) return
    const fetchGoals = async () => {
      const goals = await myGoals()
      let trackingIdFromLabel
      if (label) {
        const getCurrentGoalTrackingId = () => {
          const currentGoal = goals.data.find((f) => {
            return f.title === label
          })
          return currentGoal?.goalTrackingId || null
        }
        trackingIdFromLabel = getCurrentGoalTrackingId()
      }
      const defaultGoal = tabDtFromGtc || trackingId || trackingIdFromLabel || goals?.data[0]?.goalTrackingId || 4
      // setGoals(goals?.data)
      setGoalId(goals?.data.map((goal: Goal) => goal.id))
      setTabDT(defaultGoal)
      return goals.data
    }

    fetchGoals()
  }, [])

  const handleTabDailyTracking = useCallback(
    (id: number | any) => {
      setTabDT(id)
    },
    [dailyTrackingQuestionDetailResult, date]
  )

  useEffect(() => {
    if (isIntroduction) return
    if (!tabDT) {
      setLoading(false)
      return
    }
    setLoading(true)
    const fetchDetail = async () => {
      try {
        await Promise.all([
          getDailyTrackingQuestionDetail(tabDT),
          getDailyTrackingHistory({
            date: format(date, 'yyyy-M-d'),
            goal_tracking_id: tabDT,
          }),
        ])
      } catch (error) {}

      setLoading(false)
    }
    fetchDetail()
    setStateSubmit(false)
  }, [tabDT, date])

  useEffect(() => {
    if (!tabDT) {
      return
    }
    handleRefreshGoals(format(date, DATE_FORMAT))
  }, [date, dataHistoryCalendar, allGoal, myGoalIds])

  useEffect(() => {
    if (isAfter(month, startCalendarDate) && dataHistoryCalendar.length && !stateSubmit) {
      return
    }
    if (isIntroduction) return
    const fetchCalendar = async () => {
      try {
        setLoadingCalendar(true)

        const newStartDate = subMonths(startOfMonth(startCalendarDate), 3)
        const data = await getCalendarHistories(format(newStartDate, DATE_FORMAT), format(new Date(), DATE_FORMAT)).catch()
        setStartCalendarDate(newStartDate)
        setDataHistoryCalendar(dataHistoryCalendar.concat(data?.data))

        setLoadingCalendar(false)
      } catch (error) {}
    }

    fetchCalendar()
  }, [month, stateSubmit])

  useEffect(() => {
    if (!dataHistoryCalendar.length) {
      return
    }
    const archived = dataHistoryCalendar
      .filter((m: any) => {
        return isSameMonth(month, new Date(m.date)) && m.goal_tracking_id.includes(tabDT)
      })
      .map((d: any) => d.date)
    setTrackedDate([...archived])
  }, [tabDT, dataHistoryCalendar, month, calendarDate])

  const prevTwoDays = (date: string) => {
    const currentDate = new Date()
    const selectedDateParsed = parseISO(date)

    const differenceInDaysResult = -differenceInDays(selectedDateParsed, currentDate)
    return differenceInDaysResult <= 2
  }

  const handleRefreshGoals = (date: string) => {
    const today = format(new Date(), DATE_FORMAT)
    if (isSameDay(date, today) || prevTwoDays(date)) {
      setGoals(allGoal.filter((g: any) => myGoalIds.includes(g.id)))
    } else {
      const goalTrackingIds = dataHistoryCalendar
        .map((f: any) => {
          return f.date === date ? f.goal_tracking_id : []
        })
        .flatMap((m) => m)
      const newGoals = allGoal.filter((g: any) => goalTrackingIds.includes(g.goalTrackingId))
      setGoals(newGoals)
    }
  }

  const onCloseCalendar = async () => {
    setMonth(new Date(date))
    setCalendarDate(new Date(date))
  }
  const onChangeMonth = async (start: Date, end: Date) => {
    setMonth(start)
    setCalendarDate(start)
  }

  const onSelectedDate = (date: string) => {
    handleRefreshGoals(date)
    setDate(toDate(date))
    setCalendarDate(toDate(date))
  }

  const myDailyTrackingList = useMemo(() => {
    return (
      <div className="no-scrollbar flex space-x-3 overflow-auto whitespace-nowrap">
        {(isIntroduction ? IntroductionTabDT : goals).map((item: Goal, index: number) => {
          return (
            <button
              key={index}
              ref={(el) => (tabRefs.current[index] = el)}
              onClick={() => handleTabDailyTracking(item?.goalTrackingId)}
              className="rounded-full p-[5px] px-[10px] uppercase hover:cursor-pointer"
              style={
                tabDT === item?.goalTrackingId
                  ? {
                      backgroundColor: item?.backgroundColor,
                      color: item?.textColor,
                    }
                  : {
                      backgroundColor: '#FFF',
                    }
              }
            >
              {item?.title}
            </button>
          )
        })}
      </div>
    )
  }, [tabDT, date, goals])

  const questionsPage = useMemo(() => {
    const goal = (isIntroduction ? IntroductionTabDT : goals).find((f) => f.goalTrackingId === tabDT)
    if (tabDailyTrackingDetail && goal && dailyTrackingHistory) {
      return <FormQuestionsContent setStateSubmit={setStateSubmit} goal={goal} goalTracking={tabDailyTrackingDetail[0]} history={dailyTrackingHistory} date={format(date, DATE_FORMAT)} />
    }
    if (isIntroduction && goal) {
      return <FormQuestionsContent refs={refs} goal={goal} goalTracking={IntroductionTabDTDetail[0]} history={dailyTrackingHistory} date={format(date, DATE_FORMAT)} />
    }
  }, [tabDT, dailyTrackingQuestionDetailResult?.data, date, goals, tabDailyTrackingDetail, dailyTrackingHistory])

  const calendar = useMemo(() => {
    if (loadingCalendar) {
      return (
        <div className="flex justify-center">
          <Loading size={LoadingSize.Small} isFull={true} />
        </div>
      )
    }

    return (
      <>
        <CustomCalendar
          togglePopover={true}
          date={calendarDate}
          month={month}
          trackedDate={trackedDate}
          screen={CalanderScreen.DT}
          onSelectedDate={onSelectedDate}
          onChangeMonth={onChangeMonth}
          onClosed={onCloseCalendar}
        />
      </>
    )
  }, [calendarDate, trackedDate, loadingCalendar])

  return (
    <DailyTrackingWrapper className="mx-auto max-w-[684px] space-y-4 pb-12">
      <DailyTrackingSection
        className="mx-auto max-w-[684px] space-y-4 pb-12"
        title={t('mainMenuAndSection.myDailyTracking')}
        adJust={
          <Link ref={refs[MyAdessoDailyTrackingStep.AdjustYourGoals]} to={`/goal-tracking-configuration/?tab-gtc-from-dt=${tabDT}`} className="flex items-center gap-2">
            <span className="inline-block text-[12px] text-[#FF3399]">Adjust Your Goals</span>
            <div className="h-[35px] w-[35px] bg-[url('assets/images/icon/i-gear.svg')]"></div>
          </Link>
        }
      >
        {(loading || dailyTrackingHistoryResult?.isLoading || dailyTrackingQuestionDetailResult?.isLoading) && (
          <div className="flex justify-center">
            <Loading size={LoadingSize.Small} isFull={true} />
          </div>
        )}
        {calendar}
        {myDailyTrackingList}
        {questionsPage}
      </DailyTrackingSection>
    </DailyTrackingWrapper>
  )
}
