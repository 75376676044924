import { useGlobalModalContext } from './global-modal'

import heartStart from '../../assets/images/iconHeart/heartStart.svg'
import heartAlive1 from '../../assets/images/iconHeart/heartAlive1.svg'
import heartAlive2 from '../../assets/images/iconHeart/heartAlive2.svg'
import heartStrive1 from '../../assets/images/iconHeart/heartStrive1.svg'
import heartStrive2 from '../../assets/images/iconHeart/heartStrive2.svg'
import heartThrive1 from '../../assets/images/iconHeart/heartThrive1.svg'
import heartThrive2 from '../../assets/images/iconHeart/heartThrive2.svg'
import heartMaximize1 from '../../assets/images/iconHeart/heartMaximize1.svg'
import heartMaximize2 from '../../assets/images/iconHeart/heartMaximize2.svg'
import { useLocales } from '../../locales'

const badgeIcons: { [key: string]: string } = {
  heartStart,
  heartAlive1,
  heartAlive2,
  heartStrive1,
  heartStrive2,
  heartThrive1,
  heartThrive2,
  heartMaximize1,
  heartMaximize2,
}
const getBadgeInfo = (totalBeats: number): any => {
  const badges = [
    { threshold: 0, icon: 'heartStart' },
    { threshold: 200, icon: 'heartAlive1' },
    { threshold: 300, icon: 'heartAlive2' },
    { threshold: 400, icon: 'heartStrive1' },
    { threshold: 500, icon: 'heartStrive2' },
    { threshold: 600, icon: 'heartThrive1' },
    { threshold: 700, icon: 'heartThrive2' },
    { threshold: 800, icon: 'heartMaximize1' },
    { threshold: 900, icon: 'heartMaximize2' },
    { threshold: Infinity, icon: null },
  ]

  let currentBadgeIndex = badges.findIndex((badge, index) => {
    const nextBadge = badges[index + 1]
    return totalBeats >= badge.threshold && totalBeats < nextBadge.threshold
  })

  if (currentBadgeIndex === -1) {
    currentBadgeIndex = badges.length - 2
  }

  const currentBadge = badges[currentBadgeIndex]

  return {
    currentBadge: currentBadge.icon,
    currentBadgeIconPath: currentBadge.icon ? badgeIcons[currentBadge.icon] : null,
  }
}

export const LevelUp = () => {
  const { t } = useLocales()

  const { hideGlobalModal, store } = useGlobalModalContext()
  const { modalProps } = store
  const beat = modalProps
  const infoBadge = getBadgeInfo(beat)
  const handleModalToggle = () => {
    hideGlobalModal()
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-30 flex justify-center bg-[rgba(0,0,0,0.8)] md:py-4">
      <div className="h-full w-[375px] bg-cover bg-[center_top] bg-no-repeat md:rounded-md" style={{ backgroundImage: 'url(/backgrounds/bg_up_level.webp)' }}>
        <div className="space-y-6 pt-8 text-center text-white">
          <h1 className="w-full space-y-6 font-butler text-[35px] leading-[39px]">
            Congratulations!
            <br />
            You did it!
          </h1>
          <p className="text-[24px] font-bold">You've reached</p>
          <p className="text-[40px] font-bold">{beat} BEATS</p>
          <img className="inline h-56 w-56" src={infoBadge.currentBadgeIconPath} alt="heart score" />
          <p className="text-[24px] font-bold">You’ve achieved the</p>
          <p className="text-[24px] font-bold">
            {t(`heartChartMain.beatsProgress.${infoBadge.currentBadge}`)}
            <span className=""> </span>
            {t(`heartChartMain.beatsProgress.level`)}
          </p>
          <p className="text-[20px]">To unlock your next level, work to complete your actions and keep advancing through your heart journey.</p>
          <span onClick={handleModalToggle} className=" inline-block rounded-[30px] bg-white px-10 py-3 text-[#EB2781]">
            OK
          </span>
        </div>
      </div>
    </div>
  )
}
