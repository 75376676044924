import { useState, useCallback, useMemo } from 'react'
import { Session09Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion09Questions } from '../../../../../lib/data/session-09'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion09Questions[1], sesstion09Questions[2]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment01 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session09Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            <h4>How hormones affect the heart</h4>
            <p>Let’s start by doing a quick self-check on your hormones.</p>

            {ssHAQuestions.map((item, index) => {
              return (
                <FormField
                  key={`ss09_${index}_${item.name}`}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session09Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
