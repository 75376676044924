import { ChangeEvent } from 'react'
import { NavLink } from 'react-router-dom'

interface CheckboxProps {
  id: number
  label: string
  slug: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: React.FC<CheckboxProps> = ({ id, label, checked, slug, onChange }) => (
  <div className="mb-1 flex items-center">
    <input id={id.toString()} type="checkbox" value="" checked={checked} onChange={onChange} className="peer hidden" />
    <label
      htmlFor={id.toString()}
      className="mx-0 block cursor-pointer bg-[url('assets/images/icon/i-checkbox__uncheck.svg')] bg-[length:24px_auto] bg-[top_left] bg-no-repeat pl-7 text-[16px] text-ma-md font-semibold leading-[19px] text-ma-pink-300 peer-checked:bg-[url('assets/images/icon/i-checkbox__checked.svg')]"
    >
      <NavLink key={id} to={'/go-deeper/' + slug} className="link text-base text-black">
        {label}
      </NavLink>
    </label>
  </div>
)
