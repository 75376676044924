import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: {
    content: string
  }
  message?: string
}

export async function postHeartScoreStep(params: object): Promise<Response> {
  const { data, status } = await axios.post('/heart-score-step', params)

  if (status !== 200) {
    console.info('postHeartScore', data)
    throw new AdessoError(`Request postHeartScore error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
