import {useMemo, ChangeEvent} from 'react'

import {Question, FieldTypes, QuestionTypes} from '../../../models'

type Props = {
    value?: string | string[]
    disabled: boolean
    backgroundColor?: string
    textColor?: string
    fieldType: FieldTypes
    question: Question
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxMultiField = ({value, disabled, question, onChange}: Props) => {
    const options = useMemo(() => {
        return question.options
            ? question.options.map((option, index) => {
                const idCheckbox = `${question.name}-${index}`
                const isChecked = !!value && (value === option.label || value?.includes(option.label))
                return (
                    <div key={`checkbox-${index}`}>
                        <input id={idCheckbox} disabled={disabled} type="checkbox" name={question.name}
                               className="peer hidden"
                               value={option.label} onChange={onChange} checked={isChecked}/>

                        <label
                            htmlFor={idCheckbox}
                            className="block text-ma-md text-ma-pink-300 font-semibold cursor-pointer bg-[url('assets/images/icon/i-checkbox__uncheck.svg')] bg-[length:24px_auto] bg-[top_left] bg-no-repeat pl-7 pt-1 text-[16px] leading-[19px] peer-checked:bg-[url('assets/images/icon/i-checkbox__checked.svg')]"
                        >
                            <span>{option.label}</span>
                        </label>
                    </div>
                )
            })
            : null
    }, [value, question])

    return <div className="space-y-3">{options}</div>
}
