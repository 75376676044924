import { format, subMonths, startOfWeek, endOfWeek, subWeeks } from 'date-fns'
import { BarData } from '../../models/heartChart'

type ScaleOptionKeys = 'week' | 'month' | 'sixMonths'

type NumericDictionary = {
  [index: number]: string
}

const convertWeekDay: { [key: string]: string } = {
  7: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
}

const convertMonths: { [key: string]: number } = {
  '01': 0,
  '02': 1,
  '03': 2,
  '04': 3,
  '05': 4,
  '06': 5,
}

const getFiveDayIntervals = () => {
  let intervals = []
  const today = new Date()
  // const today = new Date(2024, 3, 27)
  let currentWeekStart = startOfWeek(today, { weekStartsOn: 0 })
  let currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 0 })

  for (let i = 0; i < 5; i++) {
    const formattedInterval = `${format(currentWeekStart, 'MMM-dd')} to ${format(currentWeekEnd, 'MMM-dd')}`
    intervals.push(formattedInterval)

    currentWeekEnd = subWeeks(currentWeekStart, 1)
    currentWeekStart = startOfWeek(currentWeekEnd, { weekStartsOn: 0 })
    currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 0 })
  }

  return intervals.reverse()
}

function formatMonthInterval(interval: string): string {
  const parts = interval.split(' to ')
  const start = parts[0].split('-')
  const end = parts[1].split('-')

  if (start[0] === end[0]) {
    return `${start[0]} ${start[1]}-${end[1]}`
  } else {
    return `${start[0]} ${start[1]}-${end[0]} ${end[1]}`
  }
}

const scaleOptions: Record<ScaleOptionKeys, string[]> = {
  week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  month: getFiveDayIntervals(),
  sixMonths: Array.from({ length: 6 }, (_, i) => format(subMonths(new Date(), i), 'MMM yyyy')).reverse(),
}

export function mapDataChartBar(data: any[], typeDate?: string): BarData[] {
  const selectedScaleOption: ScaleOptionKeys = typeDate as ScaleOptionKeys
  const scaleOptionSelected = scaleOptions[selectedScaleOption || 'week']

  const checkHaveDate = (date: string, index: number) => {
    if (typeDate === 'week') {
      return data.find((dateData) => convertWeekDay[dateData.date] === date)
    } else if (typeDate === 'month') {
      return data.find((dateData) => dateData.week === date)
    } else if (typeDate === 'sixMonths') {
      return data.find((dateData) => convertMonths[dateData.month] === index)
    }
  }

  const mappedData: BarData[] = scaleOptionSelected.map((date, index) => {
    const dateData = checkHaveDate(date, index)
    const value = dateData ? dateData?.statistics || dateData?.hours : 0

    if (typeDate === 'month') {
      date = formatMonthInterval(date)
    }

    return {
      day: date,
      value: value,
    }
  })

  return mappedData
}

export const convertTypeBarChart: NumericDictionary = {
  1: 'month',
  2: '6-months',
}

export const convertTypeButton: NumericDictionary = {
  0: 'week',
  1: 'month',
  2: 'sixMonths',
}
