import { useCallback, useMemo, useState } from 'react'

import { ScreenCoreConceptsStop } from './CoreConceptsStop'
import { ScreenCoreConceptsStop01 } from './CoreConceptsStop01'
import { Session03Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'
import { dataCoreConcepts, dataCoreConceptsStops01, dataCoreConceptsStops02 } from '../../../../../lib/data/session-03'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'
import { ButtonRx } from '../../ButtonRx'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocales } from '../../../../../locales'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers?: any
  isButtonBack: boolean
}

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const { t } = useLocales()
  const location = useLocation()
  const navigate = useNavigate()

  const [screen, setScreen] = useState(isButtonBack ? 14 : step.sub_step)
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataCoreConcepts[isButtonBack ? 14 : step.sub_step])

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const numScreens = dataCoreConcepts.length

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataCoreConcepts[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataCoreConcepts[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: number) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    const srcImg = contentScreen.img?.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${contentScreen.img}` : `/images/my-adresso-journey/session/03/${contentScreen.imgWebp}`
    switch (screen) {
      case 0:
        return (
          <ScreenCoreConceptsStop01
            answers={answers}
            content={contentScreen}
            showModal={showModal}
            backScreen={() => handleBackScreen(screen)}
            onUpdate={(params: object) => handleUpdate(params, screen)}
          />
        )
      case 3:
        return <ScreenCoreConceptsStop showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
      case 6:
        return (
          <Session03Wrapper buttons={buttons}>
            <div className="session-style">
              <h4 className="text-[18px] font-bold leading-[22px]">Create a roadmap through the supermarket.</h4>

              <p>
                {`It’s time to take a trip to the supermarket. There are certain principles we can live by that will help us make decisions about what foods to purchase and eat and which foods to avoid as we journey towards a life of nourishment.`}
              </p>

              <div className="h-[821px] space-y-2 bg-[url(/images/my-adresso-journey/session/03/img_014.webp)] bg-[length:139px_auto] bg-[top_left] bg-no-repeat pl-[150px]">
                {dataCoreConceptsStops01.map((item) => (
                  <div key={item.id} className="flex h-[109px] w-full flex-col justify-center">
                    <div className="text-[16px] font-bold leading-[19px]">Stop {item.id} :</div>
                    <div className="text-[14px] font-light leading-[17px]">{item.title}</div>
                    <div className="text-[13px] leading-[16px]">{item.description}</div>
                  </div>
                ))}
              </div>
            </div>
          </Session03Wrapper>
        )

      default:
        return (
          <Session03Wrapper buttons={buttons}>
            <div className="session-style">
              <h4>
                {contentScreen.id > 14 ? `Stop ${contentScreen.id - 14}:` : ''} {contentScreen.title}
              </h4>

              {srcImg.includes('.mp4') ? (
                <video className="block h-auto w-full" autoPlay muted controls>
                  <source src={srcImg} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={srcImg} alt="" />
              )}

              <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
              {contentScreen.id === 13 && <ButtonRx title={t('myAdessoJourney.session3.buttonRx')} handleClick={() => navigate(`/my-health/nourish-rx/?previousUrl=${location.pathname}`)} />}
            </div>
          </Session03Wrapper>
        )
    }
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal])

  return <>{screenCoreConcepts}</>
}
