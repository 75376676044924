import { useCallback, useEffect, useMemo, useState } from 'react'
import { useBoolean } from '../../../hooks/use-boolean'
import { Question } from '../../../models'
import { toBool } from '../../../helpers/StringHelper'

type Props = {
  valueDefault?: any
  question: Question
  // onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: any
  inputType?: string
  isArchived?: boolean
  disabled: boolean
}

export const ToggleField = ({ valueDefault, question, onChange, inputType, isArchived = false, disabled }: Props) => {
  const check = useBoolean()
  const handleChangeToggle = useCallback((value: boolean) => {
    onChange(question?.name, value.toString() ?? false)
  }, [])

  useEffect(() => {
    check.setValue(toBool(valueDefault))
  }, [question, valueDefault])

  const boolean = useMemo(() => {
    return disabled ? (
      <span className="pr-5 font-bold uppercase">{check.value ? 'Yes' : 'No'}</span>
    ) : (
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          value=""
          disabled={disabled}
          className="peer sr-only"
          checked={check.value}
          onChange={(e) => {
            check.onToggle()
            handleChangeToggle(e.target.checked)
          }}
        />
        <div
          className={`${
            check.value ? 'after:bg-[#FF3399] peer-focus:ring-pink-300' : 'after:bg-[#7F8080] peer-focus:ring-gray-300'
          } after:top-0.1 after:start-[2px] peer flex h-[36px] w-[108px] items-center rounded-full bg-[#F0F0F0] after:absolute after:h-[36px] after:w-[36px] after:rounded-full after:border after:border-gray-200 after:transition-all after:content-[''] peer-checked:bg-[#FFD7E8] peer-checked:after:translate-x-[72px] peer-checked:after:border-[#FFD7E8] peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800`}
        >
          <span className={`${check.value ? 'left-[20%] ' : 'right-[20%]'} absolute text-[16px] uppercase`}>{check.value ? 'Yes' : 'No'}</span>
        </div>
      </label>
    )
  }, [check.value, disabled])

  return boolean
}
