import { useCallback, useMemo, useState, useEffect } from 'react'
import { Wrapper, Container, TitlePage, FormInput, SubmitButtonCommon, ButtonCommon, ButtonStyles, ErrorMessage } from '../Common'
import { useRegisterPage } from '../../hooks/pages/register'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useLocales } from '../../locales'
import { PrivacyPolicy } from '../PrivacyPolicy'
import { useBoolean } from '../../hooks/use-boolean'
import { CLOSE_EYE_ICON, OPEN_EYE_ICON } from '../../constants'

const defaultRegisterData = {
  name: '',
  email: '',
  password: '',
  confirm_password: '',
  phone_number: '',
  company_name: '',
  agree_term: [],
}

type props = {
  nextScreen: () => void
}

export const Screen1 = ({ nextScreen }: props) => {
  const { postDataResult, register } = useRegisterPage()
  const [isCheckError, setIsCheckError] = useState<boolean>(false)
  const [showTerm, setShowTerm] = useState<boolean>(false)
  const { t } = useLocales()
  const [passwordEye, confirmPasswordEye] = [useBoolean(), useBoolean()]

  useEffect(() => {
    if (postDataResult?.data) {
      nextScreen()
    }
  }, [postDataResult?.data])

  const handlePostRegister = useCallback(
    async (params: any) => {
      await register(params)
    },
    [register]
  )

  const handleOpen = () => setShowTerm(true)

  const handleClose = () => setShowTerm(false)

  const formik = useFormik({
    initialValues: defaultRegisterData,
    validationSchema: Yup.object({
      name: Yup.string().min(3, 'Field is too Short! Minimum: 3 characters').max(50, 'Field is too Long! Maximum: 50 characters').required('Field is required'),
      password: Yup.string().min(6, 'Field is too Short! Minimum: 6 characters').required('Field is required'),
      confirm_password: Yup.string().test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      }),
      email: Yup.string().email('Invalid email').required('Field is required'),
      phone_number: Yup.string().min(9, 'Invalid Phone Number!').max(12, 'Invalid Phone Number!'),
      company_name: Yup.string().min(3, 'Field is too Short! Minimum: 3 characters').max(50, 'Field is too Long! Maximum: 50 characters'),
      agree_term: Yup.array().of(Yup.string()).min(1, 'You should agree the term'),
    }),
    onSubmit: (values) => {
      handlePostRegister(values)
    },
  })

  const formRegister = useMemo(() => {
    const isLoading = postDataResult?.isLoading || false
    const isError = !!postDataResult?.error || false
    const errorMessage = postDataResult?.error?.message || undefined

    const iconEyePassword = passwordEye.value ? `${OPEN_EYE_ICON}` : `${CLOSE_EYE_ICON}`

    const iconEyeConfirmPassword = confirmPasswordEye.value ? `${OPEN_EYE_ICON}` : `${CLOSE_EYE_ICON}`

    return (
      <Wrapper className="relative min-h-[calc(100vh-74px)] space-y-5 bg-ma-80 py-10 text-white">
        {!showTerm ? (
          <>
            <TitlePage title={t('welcome')} subTitle={t('auth.createAnAccount')} />

            <form className="w-full space-y-6 text-black" onSubmit={formik.handleSubmit}>
              <div className="space-y-4">
                <FormInput type="text" id="name" name="name" placeholder={t('common.name') + ' *'} value={formik.values.name} onChange={formik.handleChange} />
                {isCheckError && formik.errors.name && <ErrorMessage text={formik.errors.name} />}
                <FormInput id="email" type="email" name="email" placeholder={t('common.email') + ' *'} value={formik.values.email} onChange={formik.handleChange} />
                {isCheckError && formik.errors.email && <ErrorMessage text={formik.errors.email} />}
                <FormInput type="text" id="phone_number" name="phone_number" placeholder={t('auth.phoneNumber')} value={formik.values.phone_number} onChange={formik.handleChange} />
                {isCheckError && formik.errors.phone_number && <ErrorMessage text={formik.errors.phone_number} />}
                <FormInput type="text" id="company_name" name="company_name" placeholder={t('auth.companyName')} value={formik.values.company_name} onChange={formik.handleChange} />
                {isCheckError && formik.errors.company_name && <ErrorMessage text={formik.errors.company_name} />}
                <div className="relative">
                  <FormInput
                    id="password"
                    type={passwordEye.value ? 'text' : 'password'}
                    name="password"
                    placeholder={t('common.password') + ' *'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <div className={iconEyePassword} onClick={passwordEye.onToggle} />
                </div>
                {isCheckError && formik.errors.password && <ErrorMessage text={formik.errors.password} />}
                <div className="relative">
                  <FormInput
                    id="confirm_password"
                    type={confirmPasswordEye.value ? 'text' : 'password'}
                    name="confirm_password"
                    placeholder={t('auth.confirmPassword') + ' *'}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                  />
                  <div className={iconEyeConfirmPassword} onClick={confirmPasswordEye.onToggle} />
                </div>
                {isCheckError && formik.errors.confirm_password && <ErrorMessage text={formik.errors.confirm_password} />}
                <input type="checkbox" value="1" name="agree_term" onChange={formik.handleChange} />{' '}
                <span className="text-[16px] font-[600] text-white">
                  {t('termsOfService.agreeThe')}
                  <span className="underline hover:cursor-pointer" onClick={handleOpen}>
                    {t('termsOfService.privacyPolicy')}
                  </span>
                </span>
                {isError && errorMessage && <ErrorMessage text={errorMessage} isHtml={true} />}
              </div>

              <SubmitButtonCommon
                isLoading={isLoading}
                disabled={formik.values.agree_term.length === 0}
                width="w-full"
                height="h-[53px]"
                text={t('auth.createAccount')}
                style={ButtonStyles.Dark}
                onClick={() => {
                  setIsCheckError(true)
                  formik.handleSubmit
                }}
              />
              <div className="space-y-4">
                <a href="login" className="text-[16px] font-[600] text-white underline hover:text-white">
                  {t('auth.alreadyGotAnAccount')} {t('auth.login')}
                </a>
              </div>
            </form>
          </>
        ) : (
          <PrivacyPolicy children={<ButtonCommon className="px-8 py-4" text="Close" onClick={handleClose} />} />
        )}
      </Wrapper>
    )
  }, [formik, postDataResult, showTerm])

  return <>{formRegister}</>
}
