import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Wrapper, IconLoading } from '../Common'
import { Deeper, GoDeeperTopics, GoDeeperTypes } from '../../models/deepers'

type Props = {
  isLoading: boolean
  deepers: Deeper[]
}

const dataTopicGoDeeper: string[] = [
  GoDeeperTopics.COMMIT_TO_MY_HEART,
  GoDeeperTopics.RELATE_FOR_MY_HEART,
  GoDeeperTopics.MOVE_MY_HEART,
  GoDeeperTopics.KNOW_MY_NUMBERS,
  GoDeeperTopics.NOURISH_MY_HEART,
  GoDeeperTopics.ADVOCATE_FOR_MY_HEART,
  GoDeeperTopics.REFESH_MY_HEART,
  GoDeeperTopics.MANAGE_MY_HORMONES,
]

export const GoDeeperLibraryContent = ({ isLoading, deepers }: Props) => {
  const [deeperLibrary, getDeeperLibrary] = useState<Deeper[]>(deepers)
  const [searchTopics, getSearchTopics] = useState<string[]>([])
  const [searchKeyword, getSearchKeyword] = useState<string>('')

  useEffect(() => {
    if (searchTopics) {
      let newDeeperLibrary = deepers
      if (searchTopics.length > 0) {
        newDeeperLibrary = newDeeperLibrary.filter((deeper) => searchTopics.includes(deeper.topic))
      }

      if (searchKeyword) {
        newDeeperLibrary = newDeeperLibrary.filter(
          (deeper) => deeper.title.toUpperCase().indexOf(searchKeyword.toUpperCase()) > -1 || deeper.description.toUpperCase().indexOf(searchKeyword.toUpperCase()) > -1
        )
      }

      getDeeperLibrary(newDeeperLibrary)
    }
  }, [deepers, searchTopics, searchKeyword])

  const handleSearchTopic = useCallback(
    (currentSearchTopics: string[], topic: string) => {
      if (deepers.length > 0) {
        const isRemoveItem = currentSearchTopics.includes(topic)

        if (isRemoveItem) {
          const newSearchTopics = currentSearchTopics.filter((topicItem) => topicItem !== topic)
          getSearchTopics(newSearchTopics)
        } else {
          getSearchTopics([...currentSearchTopics, topic])
        }
      }
    },
    [deepers, getSearchTopics]
  )

  const goDeeperTopics = useMemo(() => {
    return dataTopicGoDeeper.map((item, index) => {
      const bgImage = `bg-[url('assets/images/icon/i-heart_0${index + 1}.svg')]`
      const isActive = searchTopics.length < 1 || searchTopics.includes(item)
      return (
        <div
          key={index}
          className={`mb-3 flex h-6 w-full cursor-pointer items-center bg-[length:auto_21px] bg-[left_center] bg-no-repeat pl-8 text-[11px] text-ma-red-500 ${bgImage} ${isActive ? '' : 'opacity-50'}`}
          onClick={() => handleSearchTopic(searchTopics, item)}
        >
          {item.toUpperCase()}
        </div>
      )
    })
  }, [searchTopics, handleSearchTopic])

  const boxSearch = useMemo(() => {
    return (
      <form action="">
        <input
          value={searchKeyword}
          disabled={isLoading}
          type="text"
          className="flex h-10 w-full items-center rounded-[25px] border border-black bg-[url(assets/images/icon/i-search.svg)] bg-[length:auto_24px] bg-[left_8px_center] bg-no-repeat pl-10 pr-6 text-ma-md placeholder:text-ma-gray-900/50 focus:border-black focus:outline-0"
          placeholder="Enter search keyword"
          onChange={(e) => getSearchKeyword(e.target.value)}
        />
      </form>
    )
  }, [isLoading, searchKeyword, getSearchKeyword])

  const itemsGoDeeper = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading />
        </div>
      )
    }

    if (!deeperLibrary || deeperLibrary.length < 1) {
      return <strong>{`No articles were found.`}</strong>
    }

    return deeperLibrary.map((item, index) => {
      const isVideo = item.type === GoDeeperTypes.VIDEO
      const iconHeart = `bg-[url('assets/images/icon/i-heart_0${dataTopicGoDeeper.indexOf(item.topic) + 1}.svg')]`

      return (
        <Link to={`/go-deeper/${item.slug}`} key={`${index}-${item.slug}`} className="block text-black hover:text-black">
          <div className="relative mb-4 aspect-video w-full overflow-hidden bg-[length:100%_auto] bg-center bg-no-repeat p-3">
            <img className=" absolute top-0 left-0 right-0" src={item.thumbnail} />
            <div className={`relative z-20 h-11 w-11 rounded-full bg-white bg-[length:auto_21px] bg-center bg-no-repeat shadow-[0_4px_4px_#00000025] ${iconHeart}`} />
            {isVideo ? <div className="absolute inset-0 z-10 h-full w-full bg-[url(assets/images/icon/i-video.svg)] bg-[length:auto_67px] bg-center bg-no-repeat" /> : undefined}
          </div>

          <h3 className="font-butler text-[24px] leading-[34px] tracking-[0.18px]">{item.title}</h3>
          <div className="truncate-2lines" dangerouslySetInnerHTML={{ __html: item.description }} />
        </Link>
      )
    })
  }, [isLoading, deeperLibrary])

  return (
    <Wrapper className="min-h-[calc(100vh-74px)] space-y-5 pt-0">
      <h2 className="font-butler text-[24px] leading-[34px]">Go Deeper</h2>

      <div className="grid grid-cols-2 md:grid-cols-4">
        <span className="hidden bg-[url('assets/images/icon/i-heart_01.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_02.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_03.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_04.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_05.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_06.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_07.svg')]" />
        <span className="hidden bg-[url('assets/images/icon/i-heart_08.svg')]" />

        {goDeeperTopics}
      </div>

      {boxSearch}

      <div className="grid grid-cols-1 gap-4 pt-3 md:grid-cols-3">{itemsGoDeeper}</div>
    </Wrapper>
  )
}
