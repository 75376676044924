import { useCallback, useState } from 'react'

import { AdessoResult, RegisterOptions } from '../../models'

import { useAuth } from '../use-auth'

type Result = {
  success: boolean
}

export function useRegisterPage() {
  const [postDataResult, setPostDataResult] = useState<AdessoResult<Result>>()
  const auth = useAuth()

  const register = useCallback(async (params: RegisterOptions) => {
    setPostDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      await auth.checkAuth()
      const success = await auth.register(params)
      setPostDataResult({
        isLoading: false,
        data: { success },
        error: undefined,
      })
    } catch (error) {
      setPostDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Register Error'),
      })
    }
  }, [])

  return { postDataResult, register }
}
