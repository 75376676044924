import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { DashboardSection } from '../Common/DashboardSection'
import { getMyBeat } from '../../lib/request'
import { useLocales } from '../../locales';


type Props = {
  showLink: Boolean
}

export const MyBeats = ({ showLink }: Props) => {
  const [img, setImg] = useState('')
  const [description, setDescription] = useState('')
  const [bgColor, setBgColor] = useState('#EFD4F3')
  const [nextLevel, setNextLevel] = useState('')
  const [leftPoint, setLeftPoint] = useState(0)
  const {t} = useLocales()

  const getInfo = async () => {
    const data = await getMyBeat()

    if (data.success) {
      setImg(data.data.img)
      setDescription(data.data.des)
      setBgColor(data.data.bgColor)
      setNextLevel(data.data.nextLevel)
      setLeftPoint(data.data.leftPoint)
    }
  }

  useEffect(function () {
    getInfo()
  }, [])

  return (
    <DashboardSection className="space-y-4" title={t('mainMenuAndSection.myBeats')}>
      <div className="flex justify-between">
        <div className="flex aspect-[3/1] w-[calc(50%-6px)] flex-col items-center space-y-2 rounded-[15px] py-4" style={{ backgroundColor: bgColor }}>
          <div
            className="flex h-5/6 w-full items-center justify-center bg-[length:auto_100%] bg-center bg-no-repeat text-center text-[20px] font-medium leading-none text-white"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <h4 className="text-center text-[12px] font-medium uppercase leading-none text-color-text-primary">{description}</h4>
        </div>

        {nextLevel != '' && (
          <div className="flex aspect-[3/1] w-[calc(50%-6px)] flex-col items-center space-y-2 rounded-[15px] bg-color-violet py-4">
            <div className="h-4/6 w-full bg-[url('assets/images/icon/i-lock.svg')] bg-[length:auto_100%] bg-center bg-no-repeat" />
            <h4 className="text-center text-[12px] font-medium uppercase leading-[1.25] text-white">
              {leftPoint} {t('extraText.pointsMoreTo')} <br /> {t('common.unlock')} {nextLevel}
            </h4>
          </div>
        )}
      </div>

      {showLink && (
        <div className="flex justify-end">
          <NavLink to="/heart-chart" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
          {t('extraText.seeMyHeartChart')}
          </NavLink>
        </div>
      )}
    </DashboardSection>
  )
}
