import axios from 'axios'
import { GoalFromApiResponse } from "../../models/goal";
import { AdessoError } from "../error";

type RemoveGoalResponse = {
    success: boolean
    data: GoalFromApiResponse[] | undefined,
    message: string
}

export async function deleteRemoveGoal ( id: number ): Promise<RemoveGoalResponse> {
    const { data, status } = await axios.delete( `/goals/${id}` )
    if ( status !== 200 ) {
        console.info( 'deleteRemoveGoal', data )
        throw new AdessoError( `Request login error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
