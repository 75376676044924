import { useCallback, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { WrapperCommon, TitlePage, FormInput, SubmitButtonCommon, ButtonStyles, ErrorMessage } from '../Common'

import { LoginOptions } from '../../models/login'
import { useLocales } from '../../locales'
import { useBoolean } from '../../hooks/use-boolean'
import { CLOSE_EYE_ICON, OPEN_EYE_ICON } from '../../constants'

const defaultLoginData: LoginOptions = {
  email: '',
  password: '',
}

type Props = {
  isLoading: boolean
  isError?: boolean
  errorMessage?: string
  onLogin: (params: LoginOptions) => void
}

export const LoginContent = ({ isError, errorMessage, isLoading, onLogin }: Props) => {
  const { t } = useLocales()
  const passwordEye = useBoolean()

  const [isCheckError, setIsCheckError] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: defaultLoginData,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, `${t('validator.minLengthRequired', { length: 6, field: 'password' })}`)
        .required(`${t('validator.requiredField', { field: 'password' })}`),
      email: Yup.string()
        .email(`${t('validator.invalidField', { field: 'email' })}`)
        .required(`${t('validator.requiredField', { field: 'email' })}`),
    }),
    onSubmit: (values) => {
      onLogin(values)
    },
  })

  const handleSubmit = useCallback((callback: () => void) => {
    setIsCheckError(true)
    callback()
  }, [])

  const formLogin = useMemo(() => {
    const iconEyePassword = passwordEye.value ? `${OPEN_EYE_ICON}` : `${CLOSE_EYE_ICON}`

    return (
      <form className="w-full space-y-6 text-black" onSubmit={formik.handleSubmit}>
        <div className="space-y-4">
          <FormInput id="email" type="email" name="email" placeholder={t('common.email')} value={formik.values.email} onChange={formik.handleChange} />
          {isCheckError && formik.errors.email && <ErrorMessage text={formik.errors.email} />}

          <div className="relative">
            <FormInput id="password" type={passwordEye.value ? 'text' : 'password'} name="password" placeholder={t('common.password')} value={formik.values.password} onChange={formik.handleChange} />
            <div className={iconEyePassword} onClick={passwordEye.onToggle} />
          </div>

          {isCheckError && formik.errors.password && <ErrorMessage text={formik.errors.password} />}

          {isError && errorMessage && <ErrorMessage text={errorMessage} />}
        </div>
        <div className="space-y-4">
          <a href="forgot-password" className="text-[16px] font-[600] text-white underline hover:text-white">
            {t('auth.forgotPassword')} {t('auth.clickHereToReset')}
          </a>
        </div>

        <SubmitButtonCommon isLoading={isLoading} width="w-full" height="h-[53px]" text={t('common.login')} style={ButtonStyles.Dark} onClick={() => handleSubmit(formik.handleSubmit)} />

        <div className="space-y-4">
          <a href="register" className="text-[16px] font-[600] text-white underline hover:text-white">
            {t('auth.doNotHaveAnAccount')} {t('auth.signup')}.
          </a>
        </div>
      </form>
    )
  }, [isLoading, formik, isError, errorMessage])

  return (
    <WrapperCommon className="min-h-[calc(100vh-74px)] space-y-5 bg-ma-80 py-10 text-white">
      <TitlePage title={t('welcome')} subTitle={t('auth.loginToYourAccount')} />
      {formLogin}
    </WrapperCommon>
  )
}
