import axios from 'axios'
import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
}

export async function getHeartChartBeats(): Promise<Response> {
  const { data, status } = await axios.get(`/my-beats/categorizations`)

  if (status !== 200) {
    throw new AdessoError(`Request getHeartChartBeats error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
