import { useCallback } from 'react'
import { AxiosError } from 'axios'
import { AdessoError } from '../lib/error'
import { GoalAnswers } from '../models/goal'
import { getGoal } from '../lib/request/get-goal'
import { submitAnswers as submitAnswersRequest } from '../lib/request/post-submit-answers'
import { useGlobalModalContext, MODAL_TYPES } from './modals/global-modal'
import { getMyBeat } from '../lib/request'

export function useHeartAction() {
  const { showGlobalModal } = useGlobalModalContext()

  const getGoalDetail = useCallback(async (slug: string, date: string) => {
    try {
      return await getGoal(slug, date)
    } catch (error) {
      throw throwError(error, 'Get Goal Detail Failed')
    }
  }, [])

  const submitAnswers = useCallback(async (id: number, params: GoalAnswers, goal_memo_id?: any, date?: string) => {
    try {
      const data = await submitAnswersRequest(id, params, goal_memo_id, date)

      showGlobalModal(MODAL_TYPES.ADD_BEAT, data.data)

      return data
    } catch (error) {
      throw throwError(error, 'Submit Goal Answers Failed')
    }
  }, [])

  const throwError = useCallback((error: AdessoError | AxiosError | any, errorMessage: string = ''): AdessoError => {
    if (error instanceof AdessoError) return error

    if (!(error instanceof AxiosError)) {
      return new AdessoError(errorMessage)
    }

    const data = error?.response && error.response.data
    return new AdessoError(`${data.message} ${data.data}`)
  }, [])

  return { getGoalDetail, submitAnswers }
}
