import React from 'react'
import { DashboardSection } from '../Common/DashboardSection'
import { useLocales } from '../../locales'

type Props = {
  prevScreen?: () => void
  title?: string
  children?: React.ReactNode
  button?: React.ReactNode
  boxShadow?: boolean
  onSubmit?: () => void
}

const WrapperMyHealth = ({ prevScreen, title, children, button, boxShadow, onSubmit }: Props) => {
  const { t } = useLocales()

  return (
    <div className="space-y-3">
      {prevScreen && (
        <div className="flex w-[60px] cursor-pointer flex-row items-center gap-2 uppercase" onClick={prevScreen}>
          <div className="h-[29px] w-[15px] bg-[url('assets/images/icon/i-arrow_back.svg')] bg-no-repeat"></div>
          <div className="">
            <h6 className="text-[12px] font-medium leading-3">Back</h6>
          </div>
        </div>
      )}
      <DashboardSection className="space-y-4 pb-2" title={t(`mainMenuAndSection.${title}`)}>
        <form onSubmit={onSubmit}>
          <div className={`${boxShadow ? 'w-full rounded-[10px] bg-[#F9F8F8] p-2 shadow-lg' : ''} space-y-4 pb-2`}>
            {children}
            <div className="flex flex-col items-center justify-center gap-4">{button}</div>
          </div>
        </form>
      </DashboardSection>
    </div>
  )
}

export default WrapperMyHealth
