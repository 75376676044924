import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useContextSupplementRx } from '../../../context/supplementRxContext'
import { useSupplementRxPage } from '../../../hooks/use-supplement-rx'
import { ButtonMyHealth } from '../Button'
import InputField from '../Form/InputField'
import Toggle from '../Form/Toggle'
import WrapperMyHealth from '../MyHealthWrapper'
import { ErrorMessage, Loading } from '../../Common'
import { format, parse } from 'date-fns'
import { useAlert } from '../../../context/AlertContext'
import { useBoolean } from '../../../hooks/use-boolean'

type TAddSupplementRxProps = {
  prevScreen?: any
  nextScreen?: any
}

const defaultAddSupplementData: any = {
  prescription_name: '',
  how_to_take: '',
  single_dose: '',
  times_per_day: '',
  approved_by_physician: false,
}

const AddSupplement = ({ prevScreen, nextScreen }: TAddSupplementRxProps) => {
  const { addDataSupplementRx, addDataSupplement } = useContextSupplementRx()
  const { usePostSupplementRx, usePutSupplementRx } = useSupplementRxPage()
  const [alarmFor, setAlarmFor] = useState<string>('')
  const [isCheckError, setIsCheckError] = useState<boolean>(false)
  const { setAlertError } = useAlert()
  const isLoadingSubmit = useBoolean()

  const formik = useFormik({
    initialValues: defaultAddSupplementData,
    validationSchema: Yup.object({
      prescription_name: Yup.string().required('Field is required'),
      how_to_take: Yup.string().required('Field is required'),
    }),
    onSubmit: async (values) => {
      const addSupplementRx = { ...values, is_alarm: false }
      const editSupplementRx = values
      const dataSubmit = addDataSupplementRx?.alarm ? editSupplementRx : addSupplementRx
      delete dataSubmit.alarm_id
      try {
        dataSubmit?.single_dose === '' && delete dataSubmit?.single_dose
        dataSubmit?.times_per_day === '' && delete dataSubmit?.times_per_day
        isLoadingSubmit.onTrue()
        if (addDataSupplementRx?.id) {
          await usePutSupplementRx(addDataSupplementRx?.id, dataSubmit)
        } else {
          await usePostSupplementRx(dataSubmit)
        }
      } catch (error) {
        isLoadingSubmit.onFalse()
        setAlertError(`${error}`)
        return { message: 'Error' }
      } finally {
        isLoadingSubmit.onFalse()
        addDataSupplement(defaultAddSupplementData)
        prevScreen()
      }
    },
  })

  const [screen, setScreen] = useState(-1)

  const handleSubmitForm = () => {
    setIsCheckError(true)
    formik.handleSubmit
  }

  const handleUpdateHiddenField = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = () => {
    setScreen((prev) => prev + 1)
  }

  useEffect(() => {
    if (addDataSupplementRx) {
      formik.setValues(addDataSupplementRx)
    }
  }, [addDataSupplementRx])

  useEffect(() => {
    if (formik.values?.alarm?.alarm_at) {
      const parsedTime = parse(formik.values?.alarm?.alarm_at, 'HH:mm:ss', new Date())
      const formattedTime = format(parsedTime, 'h:mm aa')
      setAlarmFor(formattedTime)
    }
  }, [formik.values?.alarm?.alarm_at])

  const formSupplement = useMemo(() => {
    return (
      <div>
        <h5 className="text-center text-[16px] font-medium uppercase">{addDataSupplementRx?.alarm ? 'SET YOUR SUPPLEMENT' : 'ADD SUPPLEMENT'} </h5>

        <div className="flex flex-col gap-4">
          <InputField
            idInput="prescription_name"
            nameInput="prescription_name"
            fieldName="Supplement Name"
            placeholder="Enter Name"
            value={formik.values.prescription_name}
            onChange={formik.handleChange}
          />
          {isCheckError && formik.errors.prescription_name && <ErrorMessage text={formik.errors.prescription_name} />}

          <InputField idInput="how_to_take" nameInput="how_to_take" fieldName="How To Take" placeholder="Instructions to use" value={formik.values.how_to_take} onChange={formik.handleChange} />
          {isCheckError && formik.errors.how_to_take && <ErrorMessage text={formik.errors.how_to_take} />}

          <div className="flex flex-row justify-between gap-4">
            <div className="flex flex-col gap-3">
              <InputField
                idInput="single_dose"
                nameInput="single_dose"
                fieldName="Single Dose"
                type="number"
                placeholder="0"
                min={0}
                classNameContainer="flex flex-row items-center gap-4 whitespace-nowrap"
                value={formik.values.single_dose}
                onChange={formik.handleChange}
              />
            </div>

            <div className="flex flex-col gap-3">
              <InputField
                idInput="times_per_day"
                nameInput="times_per_day"
                fieldName="Times Per Day"
                type="number"
                placeholder="0"
                min={0}
                classNameContainer="flex flex-row items-center gap-4 whitespace-nowrap"
                value={formik.values.times_per_day}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div>
            <Toggle
              idInput="approved_by_physician"
              nameInput="approved_by_physician"
              toggleName="Approved By Physician"
              checked={formik.values.approved_by_physician}
              onChange={handleUpdateHiddenField}
            />
          </div>
          {addDataSupplementRx?.alarm && (
            <div>
              <Toggle idInput="is_alarm" nameInput="is_alarm" toggleName={`Alarm Set For ${alarmFor}`} checked={formik.values.is_alarm} onChange={handleUpdateHiddenField} />
            </div>
          )}
        </div>
      </div>
    )
  }, [formik, addDataSupplementRx, alarmFor])

  const button = useMemo(() => {
    return (
      <div className="flex flex-col justify-center gap-3">
        <div className="w-full">
          <ButtonMyHealth
            nameButton={addDataSupplementRx?.alarm ? 'EDIT ALARM' : 'SET ALARM'}
            bgButton="bg-[#F26C59]"
            className={'w-full max-w-full'}
            nextScreen={() => {
              addDataSupplement(formik.values)
              nextScreen()
            }}
          />
        </div>
        <div className="flex gap-3">
          <ButtonMyHealth
            nameButton="CANCEL"
            bgButton="bg-color-text-secondary"
            prevScreen={() => {
              try {
                addDataSupplement({
                  prescription_name: '',
                  how_to_take: '',
                  single_dose: '',
                  times_per_day: '',
                  approved_by_physician: false,
                })
              } finally {
                prevScreen()
              }
            }}
          />
          <ButtonMyHealth
            className={`${isLoadingSubmit.value ? 'cursor-wait px-[50px] opacity-25' : ''}`}
            isLoading={isLoadingSubmit.value}
            disabled={isLoadingSubmit.value}
            type="submit"
            nameButton="SUBMIT"
            bgButton="bg-color-primary"
            submit={handleSubmitForm}
          />
        </div>
      </div>
    )
  }, [formik, addDataSupplementRx, isLoadingSubmit.value])

  const addSupplement = useMemo(() => {
    return (
      <WrapperMyHealth title="supplementsRx" prevScreen={prevScreen} button={button} boxShadow={true} onSubmit={formik.handleSubmit}>
        {formSupplement}
      </WrapperMyHealth>
    )
  }, [screen, formik, addDataSupplementRx])

  return addDataSupplementRx ? addSupplement : <Loading />
}

export default AddSupplement
