import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonCommon, ButtonStyles, ErrorMessage, FormField } from '../../Common'
import { BmiField } from '../../Common/form/Bmi'
import { HeartScoreButtons } from './Buttons'
import { useBoolean } from '../../../hooks/use-boolean'
import { FormikOptions, useForm } from '../../../hooks/use-form'
import { HeartJournalMininum } from '../../../models/heart-journal'
import { QuestionTypes, ScreenQuizess } from '../../../models/question'
import { BasicCalendar } from '../../Calendar'
import { format, startOfToday } from 'date-fns'
import { useLocales } from '../../../locales'
import { ScreenTypes } from '../screen/Quizzes'

type Props = {
  currentPage: number
  currentScreen: number
  values: FormikOptions<any>
  quizess: ScreenQuizess
  isLoading?: boolean
  backScreen: () => void
  nextScreen: (data?: object, screen_type?: boolean) => void
  isIntroduction?: boolean
  myHeartJournals?: HeartJournalMininum[]
}

export const QuizzesLayout = ({ isIntroduction, myHeartJournals, currentPage, currentScreen, values, quizess, isLoading, backScreen, nextScreen }: Props) => {
  const { initUseFormik } = useForm()
  const { t } = useLocales()

  const [valuesField, setValuesField] = useState<FormikOptions<any>>(values)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [stateSubmit, setStateSubmit] = useState<boolean>(false)
  const isCollectLabNumberScreen = currentPage === 6
  const [currentTarget, setCurrentTarget] = useState({})
  const [currentValues, setCurrentValues] = useState({})
  const isPopoverOpen = useBoolean()

  const today = startOfToday()
  const [selectDate, setSelectDate] = useState(() => format(values.lab_test_date || today, 'MMMM d, yyyy'))

  const { title, subTitle, questions, more, isRequired, content_data } = quizess
  const listHighRisk: [] = content_data?.contentHighRisk || []
  const messageErrors = t('error.errorHeartScore')

  const newQuestion: any = {
    id: 0,
    name: 'lab_test_date',
    type: 'input',
    question: 'Test Date',
    isRequired: false,
    hidden: false,
    content_type: 'lab_test_date',
  }

  const questionsFormat = [newQuestion, ...questions]

  const formik = initUseFormik(
    isCollectLabNumberScreen ? questionsFormat : questions,
    () => {
      nextScreen(formik.values)
    },
    valuesField,
    messageErrors,
    currentValues
  )

  const handleSubmitForm = () => {
    const hasCheckboxMulti = questions.some((q) => q.type === QuestionTypes.CheckboxMulti)
    if (hasCheckboxMulti && isRequired) {
      const isValid = questions.some((question) => {
        return question.type === QuestionTypes.CheckboxMulti && formik.values[question.name]?.length > 0
      })
      if (isValid) {
        setIsError(false)
      } else {
        setIsError(true)
        setErrorMsg('Please select at least one option.')
        return
      }
    } else {
      setIsError(false)
    }
    setStateSubmit(true)
    formik.handleSubmit()
  }

  const handleChange = useCallback(
    (value: any, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const handleChangeDate = (selectedDate: any) => {
    setSelectDate(selectedDate)
  }

  const getNoneField = () => {
    return questions.find((q) => q?.options?.find((o) => o?.label?.toLowerCase() === 'none'))
  }

  const handleChangeMiddleware = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>, item: any) => {
      if (item.type !== QuestionTypes.CheckboxMulti) {
        return formik.handleChange(event)
      }

      const noneField = getNoneField()
      if (!noneField) {
        return formik.handleChange(event)
      }

      setCurrentTarget({
        target: event.target,
        item: item,
        noneField: noneField,
      })
      formik.handleChange(event)
    },
    [formik]
  )

  useEffect(() => {
    // Handle logic when select NONE or outside NONE checkbox

    const { item, target, noneField } = currentTarget as any
    if (item && target && target.value) {
      const isChecked = target['checked']
      const tagsValue = (formik.values[noneField.name] || []) as string[] | any
      if (item.name !== noneField.name) {
        if (isChecked) {
          const value = tagsValue?.filter((f: string) => f.toLowerCase() !== 'none')

          formik.setFieldValue(noneField.name, value)
        }
        return
      }

      if (target.value.toLowerCase() !== 'none') {
        if (isChecked) {
          const value = [...new Set([...[target.value], ...tagsValue?.filter((f: string) => f.toLowerCase() !== 'none')])]
          formik.setFieldValue(noneField.name, value)
        }
        return
      }
      questions.forEach((question) => {
        if (question.type === QuestionTypes.CheckboxMulti) {
          formik.setFieldValue(question.name, [])
        } else {
          formik.setFieldValue(question.name, '')
        }
      })
      if (isChecked) {
        formik.setFieldValue(noneField.name, [target.value])
      }
    }
  }, [currentTarget])

  useEffect(() => {
    setIsError(false)
    setStateSubmit(false)
  }, [currentScreen])

  useEffect(() => {
    const date = format(selectDate, 'yyyy-MM-dd')
    isCollectLabNumberScreen && (values.lab_test_date = date)
    setValuesField(values)
  }, [values, selectDate])

  useEffect(() => {
    setCurrentValues(formik.values)
    setIsError(false)
  }, [formik.values])

  const formQuizzes = useMemo(() => {
    return (
      <form className="z-20 mx-auto flex h-full w-full max-w-[684px] flex-1 flex-col space-y-9 pb-4" onSubmit={(e) => e.preventDefault()}>
        <div className="flex-1 space-y-4">
          {title ? <h3 className="text-ma-h3 font-bold tracking-[0.21px]">{title}</h3> : undefined}

          {subTitle ? <p className={`${content_data ? 'text-ma-h3 font-bold tracking-[0.21px]' : ''}`} dangerouslySetInnerHTML={{ __html: subTitle }} /> : undefined}

          {content_data ? (
            <div className="flex-col space-y-5">
              <header className="text-ma-h3 font-bold tracking-[0.21px]">{content_data.header}</header>
              <ul className="list-disc space-y-2 pl-8 text-ma-h3	font-bold tracking-[0.21px] text-[#FF3399]">
                {listHighRisk &&
                  listHighRisk.map((item, index) => {
                    return <li key={index}>{item}</li>
                  })}
              </ul>
              <footer className="text-ma-h3 font-normal tracking-[0.21px]"> {content_data.footer}</footer>
            </div>
          ) : undefined}

          {(isCollectLabNumberScreen ? questionsFormat : questions).map((item) => {
            if (item.parent) {
              if (item.hidden) return undefined

              const parentValue = item.parent.value
              const formikParentValue = formik.values[item.parent.parentName]

              if (typeof formikParentValue === 'object') {
                // @ts-ignore
                if (!formikParentValue.includes(parentValue)) return undefined
              } else {
                if (formikParentValue !== parentValue) return undefined
              }
            }

            if (item.type === QuestionTypes.MBI) {
              return (
                <BmiField
                  errors={formik.errors[item.name]}
                  stateSubmit={stateSubmit}
                  key={`${item.name}-${item.id}`}
                  question={item}
                  values={formik.values[item.name] || {}}
                  onChangeValue={(value: any) => handleChange(value, item.name)}
                ></BmiField>
              )
            }

            if (item.content_type === 'lab_test_date') {
              return (
                <>
                  <div className="flex items-center justify-between border-b-[1px] pb-3" key={`${item.name}-${item.id}`}>
                    <h4 className="font-semibold uppercase">{item.question}</h4>
                    <div className="relative items-center text-center">
                      <div
                        className='absolute right-1 top-1 h-[24px] w-[24px] cursor-pointer bg-[#FFF] bg-[url("assets/images/icon/i-calendar.svg")] bg-no-repeat p-1'
                        onClick={() => isPopoverOpen.onToggle()}
                      ></div>
                      <input
                        type="text"
                        value={selectDate}
                        id="lab_test_date"
                        name="lab_test_date"
                        className={`h-[35px] w-[228px] border-[1px] border-[#BFBFBE] pl-3 font-semibold uppercase`}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  {isPopoverOpen.value && <BasicCalendar selectedDate={handleChangeDate} togglePopover={false} />}
                </>
              )
            }

            return (
              <FormField key={`${item.name}-${item.id}`} question={item} value={formik.values[item.name]} currentPage={currentPage} onChange={(e) => handleChangeMiddleware(e, item)}>
                {stateSubmit && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
              </FormField>
            )
          })}

          {isError && errorMsg && <ErrorMessage text={errorMsg} />}

          {more ? <p className="pt-3" dangerouslySetInnerHTML={{ __html: more }} /> : undefined}
        </div>

        {quizess.content_data && quizess.screen_type == ScreenTypes.Yes && <HeartScoreButtons isLoading={isLoading} contactProvider={() => {}} />}

        {!quizess.content_data && quizess.screen_type == ScreenTypes.Yes ? (
          <HeartScoreButtons
            isSymptoms={true}
            style={ButtonStyles.Light}
            isSubmit
            isLoading={isLoading}
            nextScreen={(screen_type) => {
              nextScreen(undefined, screen_type)
            }}
          />
        ) : (
          <HeartScoreButtons style={ButtonStyles.Dark} backScreen={backScreen} isSubmit isLoading={isLoading} nextScreen={() => handleSubmitForm()} />
        )}
      </form>
    )
  }, [selectDate, formik, currentPage, isError, errorMsg, isLoading, backScreen, handleChange, handleSubmitForm, stateSubmit])

  return <>{formQuizzes}</>
}
