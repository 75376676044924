import { HeartJournalSection } from './Section'
import { ActionType, HeartJournalMyGoalsItem } from './MyGoalsItem'
import React, { useMemo } from 'react'
import { Goal } from '../../models/goal'
import { IconLoading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { useLocales } from '../../locales';

const BackgroundColor = '#989899'
const TextColor = '#ffffff'

type Props = {
  isLoading?: boolean
  handleAddGoal: Function
  goals?: Goal[]
  noTitle?: boolean
}
export const HeartJournalAllGoals = ({ handleAddGoal, goals, isLoading, noTitle = false }: Props) => {
  const {t} = useLocales()
  const allGoalsContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }
    if (!goals || !goals.length) {
      return <div>{t('mainMenuAndSection.noGoal')}</div>
    }
    return goals.map((goal: Goal, index) => {
      goal.backgroundColor = BackgroundColor
      goal.textColor = TextColor
      return <HeartJournalMyGoalsItem handleButtonClick={handleAddGoal} key={goal.id || index} goal={goal} actionType={ActionType.Add} />
    })
  }, [goals])
  return (
    <HeartJournalSection className="space-y-2" title={noTitle ? '' : t('mainMenuAndSection.allGoals')}>
      {allGoalsContent}
    </HeartJournalSection>
  )
}
