import React, { useMemo } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useLocales } from '../../../locales'
import { FormValues } from '../../../models/my-heart'
import { IconLoading } from '../../Common'
import { LoadingSize } from '../../../models/loading'

type Props = {
  prevScreen?: () => void
  submit?: (params: FormValues) => void
  postDataResult?: FormValues
  dataOptions: any
  isLoading?: boolean
  edit?: boolean
}

type PropsButton = {
  nameButton?: string
  bgButton?: string
  nextScreen?: () => void
  prevScreen?: () => void
  submit?: (params: FormValues) => void
}

const CreateMyLifeStyle = ({ prevScreen, submit, postDataResult, dataOptions, isLoading = false, edit }: Props) => {
  const { t } = useLocales()

  const initialValues: FormValues = dataOptions?.reduce((acc: any, currentItem: any) => {
    acc[currentItem?.title] = false
    return acc
  }, {} as FormValues)

  const formInitialValues = useMemo(() => {
    if (postDataResult && postDataResult) {
      return postDataResult
    }
    return initialValues
  }, [postDataResult, dataOptions])

  const button = useMemo(() => {
    const ButtonContent = ({ nameButton, bgButton, prevScreen, submit }: PropsButton) => {
      return (
        <button
          className={`flex max-w-[160px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] ${bgButton} p-4 px-10 uppercase text-[#FFF] `}
          onClick={submit ? undefined : prevScreen}
          type={submit ? 'submit' : 'button'}
        >
          <h3 className="px-10 font-proxima-nova text-[15px] font-medium">{nameButton}</h3>
        </button>
      )
    }

    return (
      <div className="flex justify-around gap-3">
        <ButtonContent nameButton="Cancel" bgButton="bg-color-text-secondary" prevScreen={prevScreen} />
        <ButtonContent nameButton="Submit" bgButton="bg-color-primary" submit={submit} />
      </div>
    )
  }, [])

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const { approvedByPhysician, ...otherValues } = values

    if (!Object.values(otherValues).some(Boolean)) {
      actions.setFieldError('_error', 'At least one option must be selected aside from approval by physician')
      actions.setSubmitting(false)
      return
    }

    submit?.(values)
    actions.setSubmitting(false)
  }

  const ContentCreateMyLifeStyle = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }
    return (
      <div className="  space-y-4  pb-2">
        <h3 className="font-['Butler'] text-[24px] leading-[1.41667]">{t('mainMenuAndSection.lifeStyle')}</h3>

        <section className="mx-[-1.10rem] mt-0 flex  h-screen justify-center rounded-[15px] bg-[#F9F8F8] p-4 shadow ">
          <Formik initialValues={formInitialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div className="font-['Proxima Nova'] mb-4 text-center text-[16px] font-[500] leading-[17px]">
                  {edit ? t('mainMenuAndSection.lifeStyleRx.editMyRecommendation') : t('mainMenuAndSection.lifeStyleRx.createMyLifeStyleRx')}
                </div>

                <div className="my-3">
                  {(Object?.keys(initialValues) as (keyof FormValues)[])
                    .filter((key) => key !== 'approvedByPhysician')
                    .map((key) => (
                      <label key={key} className="mb-4 flex cursor-pointer items-center ">
                        <Field name={key} type="checkbox" className="hidden" />
                        <div className={`${values[key] ? 'bg-i-checkbox-check' : 'bg-i-checkbox-uncheck'} flex h-[20px] w-[20px] items-center justify-center bg-contain bg-center bg-no-repeat `}></div>
                        <span className="font-['Proxima Nova'] ml-2  text-[16px] font-[600] leading-[21px]">{key}</span>
                      </label>
                    ))}
                </div>

                {errors['_error'] && <div className="text-red-500">{errors['_error']}</div>}

                <div className="mb-4 flex items-center">
                  <label htmlFor="approvedByPhysicianToggle" className="flex cursor-pointer items-center">
                    <Field type="checkbox" id="approvedByPhysicianToggle" name="approvedByPhysician" className="sr-only" />
                    <div
                      className={`${
                        values.approvedByPhysician ? 'bg-[#FF3399]' : 'bg-gray-200'
                      } relative inline-flex h-[31px] w-[51px] items-center rounded-full transition-colors duration-200 ease-in-out`}
                    >
                      <span
                        className={`transform transition-all duration-200 ease-in-out ${
                          values.approvedByPhysician ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-[27px] w-[27px] rounded-full bg-white`}
                      ></span>
                    </div>
                    <span className="font-['Proxima Nova'] ml-2  text-[16px] font-[600] leading-[34px]">{t('mainMenuAndSection.approvedByPhysician')}</span>
                  </label>
                </div>

                <div className="">{button}</div>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    )
  }, [dataOptions, isLoading])

  return (
    <div className="space-y-3">
      <div className="flex w-[60px] cursor-pointer flex-row items-center gap-2 uppercase" onClick={prevScreen}>
        <div className="h-[29px] w-[15px] bg-[url('assets/images/icon/i-arrow_back.svg')] bg-no-repeat"></div>
        <div className="">
          <h6 className="text-[12px] font-medium leading-3">{t('mainMenuAndSection.button.back')}</h6>
        </div>
      </div>
      {ContentCreateMyLifeStyle}
    </div>
  )
}

export default CreateMyLifeStyle
