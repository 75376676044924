import { useState, useCallback, useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion02Questions } from '../../../../../lib/data/session-02'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'
import { InputField } from '../../../../Common/form/Input'

const ssHAQuestions = [sesstion02Questions[3], sesstion02Questions[4], sesstion02Questions[5], sesstion02Questions[6]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment01 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const beforeQ01 = (
    <div className="flex w-full justify-between">
      <span className="w-[33.33%] text-left">Very Difficult</span>
      <span className="w-[33.33%] text-center">Moderate</span>
      <span className="w-[33.33%] text-right">Very Easy</span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const screenHeartAssessment01 = useMemo(() => {
    const errorMessage = isError && (formik.errors[ssHAQuestions[1].name] || formik.errors[ssHAQuestions[2].name] || formik.errors[ssHAQuestions[3].name])
    return (
      <Session02Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6">
            <h4 className="text-[18px] font-bold leading-[23px]">Let’s get real!</h4>
            <p>
              {`So, time to be honest here. We love to talk about exercise far more than many of us love to do it. So let’s start by asking ourselves some questions about the state of movement in our life. It’s time to get real.`}
            </p>

            <FormField
              key={`ss02_${ssHAQuestions[0].name}`}
              before={beforeQ01}
              question={ssHAQuestions[0]}
              value={formik.values[ssHAQuestions[0].name]}
              onChange={formik.handleChange}
              onChangeValue={(value: string) => handleChangeValue(value, ssHAQuestions[0].name)}
            >
              {isError && formik.errors[ssHAQuestions[0].name] && <ErrorMessage text={formik.errors[ssHAQuestions[0].name]} />}
            </FormField>

            <h4 className="relative text-ma-md font-semibold text-ma-pink-300">What percentage of the day are you:</h4>

            <div className="space-y-2">
              <div className="flex h-14 w-full divide-x divide-dotted divide-black rounded-[14px] bg-[#F0F0F0] py-1 text-[12px] font-semibold leading-[17px]">
                <div className="flex h-full w-1/3 items-center justify-center px-5">Sitting</div>
                <div className="flex h-full w-1/3 items-center justify-center px-5">Standing</div>
                <div className="flex h-full flex-1 items-center justify-center px-5">
                  Walking <br className="md:hidden" />
                  or Moving
                </div>
              </div>

              <div className="flex h-14 w-full divide-x divide-dotted divide-black rounded-[14px] bg-[#F0F0F0] py-1 text-[16px] font-semibold">
                <div className="flex h-full w-1/3 items-center px-5 text-[#ED2580]">
                  <span>%</span>
                  <InputField isDefault question={ssHAQuestions[1]} value={formik.values[ssHAQuestions[1].name]} onChange={formik.handleChange} />
                  <></>
                </div>

                <div className="flex h-full w-1/3 items-center px-5 text-[#F26E57]">
                  <span>%</span>
                  <InputField isDefault question={ssHAQuestions[2]} value={formik.values[ssHAQuestions[2].name]} onChange={formik.handleChange} />
                  <></>
                </div>

                <div className="flex h-full flex-1 items-center px-5 text-[#950057]">
                  <span>%</span>
                  <InputField isDefault question={ssHAQuestions[3]} value={formik.values[ssHAQuestions[3].name]} onChange={formik.handleChange} />
                  <></>
                </div>
              </div>

              {errorMessage && <ErrorMessage text={errorMessage} />}
            </div>
          </div>

          {buttons}
        </form>
      </Session02Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment01}</>
}
