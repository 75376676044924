import axios from 'axios'
import { AdessoError } from '../error'

type GetGoalTrackingResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getGoalTracking(): Promise<GetGoalTrackingResponse> {
  const { data, status } = await axios.get(`/goal-tracking`)

  if (status !== 200) {
    console.info('getGoalTracking', data)
    throw new AdessoError(`Request getGoalTracking error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetGoalTrackingResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
