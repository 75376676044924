import { MutableRefObject } from 'react'

export enum ButtonStyles {
  Dark = 'dark',
  Light = 'light',
  Introduction = 'introduction',
  Session = 'session',
  Setting = 'setting',
  EndOfOnboarding = 'endOfOnboarding',
  Cancel = 'cancel',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
}

type Props = {
  children?: React.ReactNode
  width?: string
  height?: string
  text?: string
  type?: ButtonType
  style?: ButtonStyles
  disabled?: boolean
  isRoudedDefault?: boolean
  onClick: () => void
  contentRef?: MutableRefObject<any>
  scrollMarginBottom?: string
  scrollMarginTop?: string
  className?: string
}

export const ButtonCommon = ({
  children,
  width = 'w-auto',
  height = 'h-auto',
  scrollMarginTop = 'scroll-mt-0',
  scrollMarginBottom = 'scroll-mb-72',
  text,
  disabled,
  style = ButtonStyles.Light,
  type = ButtonType.Button,
  isRoudedDefault = false,
  className,
  onClick,
  contentRef,
}: Props) => {
  const classNameDefault = 'block border-none uppercase text-[15px] font-semibold leading-[21px] tracking-[0.15px] text-center flex justify-center items-center'
  const rounded = isRoudedDefault ? 'rounded-ma' : 'rounded-full'
  let classNameStyle = ''
  switch (style) {
    case ButtonStyles.Light:
      classNameStyle = 'mx-auto text-color-primary hover:text-white bg-color-secondary-gray hover:bg-color-primary'
      break
    case ButtonStyles.Dark:
      classNameStyle = 'mx-auto text-white hover:text-color-primary bg-color-primary hover:bg-color-secondary-gray'
      break
    case ButtonStyles.Setting:
      classNameStyle = 'text-white bg-color-primary hover:bg-opacity-60'
      break
    case ButtonStyles.Introduction:
      classNameStyle = `mx-auto text-white active:text-color-text-primary bg-color-text-primary active:bg-color-secondary-gray ${scrollMarginTop} ${scrollMarginBottom}`
      break
    case ButtonStyles.Session:
      classNameStyle = 'text-white bg-color-primary transition-opacity hover:bg-opacity-60'
      break

    case ButtonStyles.EndOfOnboarding:
      classNameStyle = `mx-auto text-[#FF3399] hover:text-color-text-primary bg-white hover:bg-color-secondary-gray ${scrollMarginTop} ${scrollMarginBottom}`
      break
    case ButtonStyles.Cancel:
      classNameStyle = `mx-auto text-white hover:text-color-text-primary bg-color-cancel hover:bg-color-secondary-gray ${scrollMarginTop} ${scrollMarginBottom}`
      break
    default:
      break
  }

  return (
    <button ref={contentRef} type={type} disabled={disabled} className={`${width} ${height} ${className} ${classNameDefault} ${rounded} ${classNameStyle}`} onClick={onClick}>
      {text || children}
    </button>
  )
}
