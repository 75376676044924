import axios from 'axios'

import { Results } from '../../models/result-type'

export async function postLogout(screen = ''): Promise<Results<any>> {
  let dataReturn: Results<any> = {
    error: false,
    success: false,
    data: undefined,
    errorMessage: undefined,
  }

  await axios
    .post('/logout', { screen })
    .then((response) => {
      dataReturn.error = false
      dataReturn.success = true
      dataReturn.data = response.data
      return dataReturn
    })
    .catch((error) => {
      const errorData = (error.response && error.response.data) || error.request || error.message || undefined

      dataReturn.error = true
      dataReturn.success = false
      dataReturn.errorMessage = errorData?.message || errorData

      return dataReturn
    })

  return dataReturn
}
