import React, { useEffect, useMemo, useState } from 'react'
import { format, startOfToday } from 'date-fns'

import { useHeartChartTestingPage } from '../../../hooks/pages/heart-chart-testing'

import { IconLoading } from '../../Common'
import { ResponsiveWrapper } from '../../Common/ResponsiveWrapper'
import { LoadingSize } from '../../../models/loading'

import Results from './Results'
import NewLabTest from './NewLabTest'
import ListHeartScore from './ListHeartScore'

type Props = {
  prevScreen?: () => void
  // nextScreen?: () => void
  refs?: React.Ref<any>[]
  isIntroduction?: boolean
}

const introductionsLTR = [
  {
    title: 'Blood Pressure',
    answer: '168/88',
    score: 16,
    result: 'Medium',
  },
  {
    title: 'Cholesterol',
    answer: '120',
    score: -8,
    result: 'Low',
  },
  {
    title: 'LDL',
    answer: null,
    score: 0,
    result: 'Medium',
  },
  {
    title: 'HDL',
    answer: '49',
    score: 32,
    result: 'High',
  },
  {
    title: 'Triglycerides',
    answer: null,
    score: 0,
    result: 'Medium',
  },
  {
    title: 'Lp(a)',
    answer: null,
    score: 0,
    result: 'Medium',
  },
  {
    title: 'ApoE',
    answer: null,
    score: 0,
    result: 'High',
  },
  {
    title: 'Hemoglobin A1C',
    answer: null,
    score: 0,
    result: 'Medium',
  },
  {
    title: 'ApolipoproteinB',
    answer: null,
    score: 0,
    result: 'Medium',
  },
  {
    title: 'Calcium Score',
    answer: null,
    score: -4,
    result: 'Low',
  },
]

const LabTestResults = ({ prevScreen, refs = [], isIntroduction }: Props) => {
  const [screen, setScreen] = useState(0)

  const today = startOfToday()

  const { getDataHeartScore, getDataResult, submitHeartScore, postDataResult, getLeastDataHeartScore, getLatestDataResult } = useHeartChartTestingPage()
  const [dataQuizess, setDataQuizess] = useState<any[]>([])
  const [answer, setAnswer] = useState<any[]>([])
  const [date, setDate] = useState('')

  useEffect(() => {
    getLeastDataHeartScore()
  }, [postDataResult])

  useEffect(() => {
    if (getLatestDataResult) {
      const dataLatest = getLatestDataResult.data || []
      const dateLatest = dataLatest[0]?.date || today
      const dateLatesFormat = format(dateLatest, 'MMMM dd, yyyy')

      setDate(dateLatesFormat)
      setAnswer(getLatestDataResult.data || [])
    }
  }, [getLatestDataResult])

  useEffect(() => {
    if (isIntroduction) {
      setDate('2024-01-01')
      setAnswer(introductionsLTR)
    }
  }, [isIntroduction])

  useEffect(() => {
    if (isIntroduction) return
    if (!getDataResult || (!getDataResult.isLoading && !getDataResult.data && !getDataResult.error)) {
      getDataHeartScore()
    }
    if (getDataResult?.data && getDataResult.data?.quizess) {
      const newQuestion: any = {
        id: 0,
        name: 'lab_test_date',
        type: 'input',
        question: 'Test Date',
        isRequired: false,
        hidden: false,
        content_type: 'lab_test_date',
      }
      const questions = getDataResult.data.quizess[0].screens[0].questions
      const questionsFormat = [newQuestion, ...questions]

      setDataQuizess(questionsFormat)
    }
  }, [getDataResult])

  const handlePrevScreen = () => {
    setScreen(0)
  }

  const handleNextScreen1 = () => {
    setScreen(1)
  }

  const handleNextScreen2 = () => {
    setScreen(2)
  }

  const handleSubmit = (values: any): void => {
    submitHeartScore(values)
    handlePrevScreen()
  }
  const labTestResults = useMemo(() => {
    if (getDataResult?.isLoading || postDataResult?.isLoading || getLatestDataResult?.isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Small} />
        </div>
      )
    }

    switch (screen) {
      case 0:
        return <Results refs={refs} prevScreen={prevScreen} nextScreen1={handleNextScreen1} nextScreen2={handleNextScreen2} answers={answer} date={date || undefined} />
      case 1:
        return <NewLabTest prevScreen={handlePrevScreen} submit={handleSubmit} dataQuizess={dataQuizess} isLoading={getDataResult?.isLoading || getLatestDataResult?.isLoading} />
      case 2:
        return <ListHeartScore prevScreen={handlePrevScreen} />
    }
  }, [screen, getDataResult, dataQuizess, answer, getLatestDataResult])

  return <ResponsiveWrapper>{labTestResults}</ResponsiveWrapper>
}

export default LabTestResults
