import { useCallback, useMemo, useState } from 'react'
import { Question } from '../../../models/question'

type Props = {
  children?: React.ReactNode
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  value?: any
  question: Question
  // onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: any
  inputType?: string
  isArchived?: boolean
}

export const FillBlankField = ({ value, disabled, question, onChange, inputType = 'text', backgroundColor, textColor, isArchived = false }: Props) => {
  const ObjectInput: any = {
    'Your Blood Pressure Today': [
      { name: 'SYS', unit: '', value: '' },
      { name: 'DIA', unit: '', value: '' },
      { name: 'PUL', unit: '', value: '' },
    ],
    'Target Heart Rate move duration today': [{ name: '', unit: 'min', value: '' }],
    'How many steps did you take today?': [{ name: '', unit: '', value: '' }],
    'Your Weight': [{ name: '', unit: 'lb', value: '' }],
    'Hours Slept Last Night': [
      { name: '', unit: 'h', value: '' },
      { name: '', unit: 'min', value: '' },
    ],
  }

  let arr: any = value ? value?.split('/') : []
  const ObjectInputDefault = value
    ? question?.name
      ? ObjectInput[question?.name]?.map((obj: any, index: any) => {
          if (arr[index] !== undefined) {
            obj.value = arr[index]
          }
          return obj
        })
      : []
    : question?.name
    ? ObjectInput[question?.name]
    : []

  const [valueInput, setValueInput] = useState(ObjectInputDefault)

  const handleInputChange = useCallback(
    (index: any, newValue: any) => {
      const indexFind = valueInput.findIndex((_: any, i: any) => i === index) // tìm vị trí của object cần thay thế

      if (indexFind !== -1) {
        valueInput.splice(indexFind, 1, { ...valueInput[index], value: newValue }) // thay thế object tại vị trí đó bằng object mới
        setValueInput(valueInput)

        const inputFormat = valueInput.map((item_input: any, index_input: number) => item_input?.value)

        const result = inputFormat.join('/')

        onChange(question?.name, result)
      }
    },
    [valueInput]
  )

  const inputField = useMemo(() => {
    return ObjectInputDefault?.map((item: any, index: number) => {
      return (
        <div key={index} className={`flex items-center gap-2 ${isArchived ? 'pr-3' : ''}`}>
          <h4 className="text-[16px] font-normal uppercase">{item?.name}</h4>

          <input
            // disabled={disabled}
            // type={inputType}
            disabled={disabled}
            id={`${item.name}-${index}`}
            name={`${item.name}-${index}`}
            onChange={(e) => handleInputChange(index, e.target.value)}
            value={item?.value}
            className={`h-[40px] ${!item?.unit && !item?.name ? 'w-[94px]' : 'w-[66px]'} border border-[#BFBFBE] p-1 focus:border-gray-300`}
            // placeholder={question.placeholder}
          />

          <h4 className="text-[16px] font-normal">{item?.unit}</h4>
        </div>
      )
    })
  }, [value, question, disabled])

  return <div className="flex justify-between">{inputField}</div>
}
