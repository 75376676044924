import { useCallback } from 'react'
import { AxiosError } from 'axios'
import { AdessoError } from '../lib/error'
import { getAllGoals, getMyGoals } from '../lib/request/get-my-goals'
import { getRemainingGoals } from '../lib/request/get-remaining-goals'
import { postAddGoal } from '../lib/request/post-add-goal'
import { deleteRemoveGoal } from '../lib/request/delete-remove-goal'
import { getHeartJournal } from '../lib/request/get-heart-journal-detail'
import { getMyHeartJournals } from '../lib/request/get-my-heart-journals'

export function useHeartJournal() {
  const heartJournal = useCallback(async (date: string) => {
    try {
      return await getHeartJournal(date)
    } catch (error) {
      throw throwError(error, 'Get heart journal Failed')
    }
  }, [])

  const myHeartJournals = useCallback(async () => {
    try {
      return await getMyHeartJournals()
    } catch (error) {
      throw throwError(error, 'Get my heart journals Failed')
    }
  }, [])

  const myGoals = useCallback(async (showProgress: boolean = false) => {
    try {
      return await getMyGoals(showProgress)
    } catch (error) {
      throw throwError(error, 'Get my Goals Failed')
    }
  }, [])

  const allGoals = useCallback(async (showProgress: boolean = false) => {
    try {
      return await getAllGoals(showProgress)
    } catch (error) {
      throw throwError(error, 'Get All Goals Failed')
    }
  }, [])

  
  const remainingGoals = useCallback(async () => {
    try {
      return await getRemainingGoals()
    } catch (error) {
      throw throwError(error, 'Get remaining Goals Failed')
    }
  }, [])

  const addGoal = useCallback(async (id?: number, slug?: any) => {
    try {
      return await postAddGoal(id, slug)
    } catch (error) {
      throw throwError(error, 'Add goal Failed')
    }
  }, [])

  const removeGoal = useCallback(async (id: number) => {
    try {
      return await deleteRemoveGoal(id)
    } catch (error) {
      throw throwError(error, 'Remove goal Failed')
    }
  }, [])

  const throwError = useCallback((error: AdessoError | AxiosError | any, errorMessage: string = ''): AdessoError => {
    if (error instanceof AdessoError) return error

    if (!(error instanceof AxiosError)) {
      return new AdessoError(errorMessage)
    }

    const data = error?.response && error.response.data
    return new AdessoError(`${data.message} ${data.data}`)
  }, [])

  return { heartJournal, myHeartJournals, myGoals, remainingGoals, addGoal, removeGoal, allGoals }
}
