import React from 'react'
import MovementRx from '../../components/MyHealth/Movement'
import { MovementRxProvider } from '../../context/movementRxContext'
import { useHandlePreviousUrl } from '.'

type Props = {}

const MovementRxPage = (props: Props) => {
  const handlePreviousUrl = useHandlePreviousUrl()

  return (
    <MovementRxProvider>
      <MovementRx prevScreen={handlePreviousUrl} />
    </MovementRxProvider>
  )
}

export default MovementRxPage
