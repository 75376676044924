import React, { useMemo } from 'react'
import { IconLoading } from '../../Common'

type Props = {
  resultHeartScore: any
  date?: string
}

const HeartScoreCustom = ({ resultHeartScore, date }: Props) => {
  const tableScore = useMemo(() => {
    const isLoading = resultHeartScore?.isLoading || false
    const data = resultHeartScore?.data?.heartScoreResult || undefined

    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading />
        </div>
      )
    }

    if (!data) return <>{`Empty Data!`}</>

    const totalScore =
      data.totalScore && data.totalStatus ? (
        <div className={`flex w-full items-center space-x-[6px] font-semibold uppercase text-color-${data.totalStatus}-risk`}>
          <div className={`flex h-16 w-16 items-center justify-center rounded-full border-2 text-[25px] leading-[30px] border-color-${data.totalStatus}-risk`}>{data.totalScore}</div>

          <div className="leading-[19px text-[16px]">{data.totalStatus} RISK</div>
        </div>
      ) : null

    const tableScore = data.details
      ? data?.details.map((detail: any) => {
          return (
            <div key={`resultDetail-${detail.category}`} className="flex items-center justify-between gap-3 border-t border-color-gray py-3">
              <div className="flex-1 font-semibold leading-[22px] text-color-overlay">{detail.category}</div>
              <div className={`font-semibold text-color-${detail.status}-risk whitespace-nowrap text-right uppercase`}>{`${detail.status}  ${detail.status === 'n/a' ? '' : 'Risk'}`}</div>
            </div>
          )
        })
      : null

    return (
      <>
        <p className="text-[16px] capitalize"> Your Adesso Whole Heart score:</p>
        {totalScore}
        {/* <h6 className="font-proxima-nova text-[15px] font-semibold uppercase leading-5">Test date: {date ?? ''}</h6> */}
        <p className="font-bold">Here were your scores for each section:</p>
        <div className="border-b border-color-gray">{tableScore}</div>
      </>
    )
  }, [resultHeartScore])

  const heartScoreLatest = useMemo(() => {
    return (
      <div>
        <div className="space-y-5 pb-2">
          {tableScore}

          {/* <div className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-low-risk bg-color-low-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-low-risk">LOW RISK</div>
            </div>

            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-medium-risk bg-color-medium-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-medium-risk">MEDIUM RISK</div>
            </div>

            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-high-risk bg-color-high-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-high-risk">HIGH RISK</div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }, [resultHeartScore])

  return heartScoreLatest
}

export default HeartScoreCustom
