import React, { useEffect, useMemo, useState } from 'react'
import WrapperMyHealth from '../MyHealthWrapper'
import { ButtonMyHealth } from '../Button'
import { Link } from 'react-router-dom'
import { useLocales } from '../../../locales'
import AddMovement from './AddMovement'
import SetAlarm from './SetAlarm'
import { useMovementRxPage } from '../../../hooks/use-movement-rx'
import { useContextMovementRx } from '../../../context/movementRxContext'
import { Loading } from '../../Common'
import CalculateYourTargetHeartRate from './CalculateYourTargetHeartRate'
import AddToMyMoveGoal from './AddToMyMoveGoal'
import Toggle from '../Form/Toggle'
import { useBoolean } from '../../../hooks/use-boolean'
import { ResponsiveWrapper } from '../../Common/ResponsiveWrapper'

type Props = {
  prevScreen?: () => void
  nextScreen?: () => void
}

const MovementRx = ({ prevScreen, nextScreen }: Props) => {
  const { movementRxResult, movementRxResultById, useGetMovementRx, useGetByIdMovementRx, usePutMovementRx } = useMovementRxPage()

  const { addDataMovementRx, addDataMovement } = useContextMovementRx()

  const dataMovementId = movementRxResultById?.data?.movements

  const listMovement = movementRxResult?.data?.movements

  const { t } = useLocales()

  const [screen, setScreen] = useState(-1)

  const [dataMovement, setDataMovement] = useState<any>({})

  const [idMovement, setIdMovement] = useState<number | string>()

  const isLoadingClickEdit = useBoolean()

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = () => {
    setScreen((prev) => prev + 1)
  }

  const handleApprovedByPhysician = (e: any) => {
    // e.preventDefault()
    setDataMovement((prev: any) => {
      const clar = { ...prev }
      return { ...clar, approved_by_physician: !clar?.approved_by_physician }
    })

    delete dataMovement.alarm_id
    usePutMovementRx({ ...dataMovement, approved_by_physician: !dataMovement.approved_by_physician })
  }

  const handleMovementById = async (id: number) => {
    try {
      isLoadingClickEdit.onTrue()
      // setIdMovement(id)
      addDataMovement(dataMovement)
    } finally {
      isLoadingClickEdit.onFalse()
      handleNextScreen()
    }
  }

  useEffect(() => {
    setDataMovement(listMovement)
  }, [movementRxResult?.data, screen])

  useEffect(() => {
    if (screen === -1) {
      useGetMovementRx()
    }
  }, [screen])

  const makeYourOwnExerciseRxToday = useMemo(() => {
    return dataMovement ? (
      <>
        <h5 className="font-proxima-nova text-[15px] font-semibold uppercase leading-5">MY EXERCISE RX</h5>
        <div>
          <div className="w-full space-y-4 rounded-[20px] border-solid border-[#D4D4D4] bg-[#F9F7F7] p-4 shadow-lg shadow-gray-400">
            <div className="flex flex-row items-center justify-around text-center">
              <div>
                <h4 className="text-[18px] font-semibold">THR</h4>
                <h4 className="text-[18px] font-semibold">{dataMovement?.target_heart_rate}</h4>
              </div>
              <div>
                <h4 className="text-[18px] font-semibold">TIMES/WEEK</h4>
                <h4 className="text-[18px] font-semibold">{dataMovement?.times_per_week}</h4>
              </div>
              <div>
                <h4 className="text-[18px] font-semibold">MINUTES</h4>
                <h4 className="text-[18px] font-semibold">{dataMovement?.minutes}</h4>
              </div>
            </div>
            <div className="flex flex-col justify-center text-center">
              <h4 className="text-[18px] font-semibold">ACTIVITY</h4>
              <h4 className="text-[18px] font-semibold">{dataMovement?.activity}</h4>
            </div>
            <div className="flex w-[60px] cursor-pointer flex-row items-center gap-2 text-[#EE2B7E]" onClick={() => handleMovementById(dataMovement?.id)}>
              <div className='h-[20px] w-[20px] bg-[url("assets/images/icon/i-edit.svg")] bg-no-repeat'></div>
              <h6>Edit</h6>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start gap-4">
          <Toggle
            idInput="approved_by_physician"
            nameInput="approved_by_physician"
            toggleName="Approved By Physician"
            checked={dataMovement?.approved_by_physician}
            onChange={(e: any) => handleApprovedByPhysician(e)}
          />
        </div>
      </>
    ) : (
      <div>
        <h5 className="font-proxima-nova text-[15px] font-semibold uppercase">{t('Rx.title.makeYourOwn')}</h5>
        <div className="flex flex-col items-center justify-center gap-4">
          <p className="font-proxima-nova text-[14px] font-medium">{t('extraText.makeYourOwnExerciseRxToday')}</p>
        </div>
      </div>
    )
  }, [movementRxResult, movementRxResult?.data, movementRxResult?.isLoading, dataMovement, screen, idMovement, addDataMovementRx])

  const referenceTargetHeartRateZoneChart = useMemo(() => {
    return (
      <div className="space-y-5">
        <h5 className="font-proxima-nova text-[15px] font-semibold uppercase">{t('Rx.title.reference')}</h5>
        <div className="relative overflow-x-auto">
          <table className="w-full border text-center  text-sm rtl:text-right">
            <thead className="w-full text-[10px] text-xs font-semibold">
              <tr>
                <th scope="col" className="w-[33.33%] truncate break-words border border-[#000] px-3 py-2">
                  Zones /Intensity
                </th>
                <th scope="col" className="w-[33.33%] truncate break-words border border-[#000] px-3 py-2">
                  Cycling
                </th>
                <th scope="col" className="w-[33.33%] truncate break-words border border-[#000] px-3 py-2">
                  Walking /Jogging / <br />
                  Treadmill
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white text-[16px] font-semibold text-[#974A9A] dark:border-gray-700 dark:bg-gray-800">
                <td className="border border-[#000] px-3 py-2">Zone 1</td>
                <td className="border border-[#000] px-3 py-2">{`< 99`}</td>
                <td className="border border-[#000] px-3 py-2">{`< 104`}</td>
              </tr>
              <tr className="bg-white text-[16px] font-semibold text-[#F26C59] dark:border-gray-700 dark:bg-gray-800">
                <td className="border border-[#000] px-3 py-2">Zone 2</td>
                <td className="border border-[#000] px-3 py-2">99 - 177</td>
                <td className="border border-[#000] px-3 py-2">104 - 124</td>
              </tr>
              <tr className="bg-white text-[16px] font-semibold text-[#FF3399] dark:bg-gray-800">
                <td className="border border-[#000] px-3 py-2">Zone 3</td>
                <td className="border border-[#000] px-3 py-2">118 - 136</td>
                <td className="border border-[#000] px-3 py-2">124 - 146</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end text-right">
          <Link to="/go-deeper" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
            {t('extraText.exerciseRx')}
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center space-y-5 text-[14px] font-medium">
          <p className="text-center"> {t('Rx.text.targetExercise')}</p>
          <ButtonMyHealth
            nameButton={t('Rx.button.addMoveGoal')}
            bgButton="bg-color-violet"
            className="max-w-full px-[82px]"
            nextScreen={() => {
              // handleNextScreen()
              setScreen(3)
            }}
          />
        </div>

        <div className="hidden">
          <div className="flex  flex-col items-center justify-center space-y-5 text-[14px] font-medium">
            <p className="text-center"> {t('Rx.text.trackingHeart')}</p>
            <ButtonMyHealth
              nameButton={t('Rx.button.connectTracking')}
              bgButton="bg-color-violet"
              className="max-w-full px-[62px]"
              nextScreen={() => {
                // handleNextScreen()
              }}
            />
          </div>
        </div>
      </div>
    )
  }, [screen])

  const buttonMakeYourOwnExerciseRxToday = useMemo(() => {
    return movementRxResult?.isLoading ? (
      <Loading />
    ) : (
      <>
        <ButtonMyHealth
          nameButton="CALCULATE YOUR TARGET HEART RATE"
          bgButton="bg-color-violet"
          className="max-w-full"
          nextScreen={() => {
            setScreen(2)
          }}
        />
        {!dataMovement && (
          <ButtonMyHealth
            className="max-w-full px-[82px]"
            nameButton="CREATE MY movement RX"
            bgButton="bg-color-bg-primary"
            nextScreen={() => {
              try {
                addDataMovement({
                  target_heart_rate: '',
                  times_per_week: '',
                  minutes: '',
                  activity: '',
                  approved_by_physician: false,
                })
              } finally {
                handleNextScreen()
              }
            }}
          />
        )}
      </>
    )
  }, [screen, dataMovement, movementRxResult?.isLoading])

  const screenMovementRx = useMemo(() => {
    switch (screen) {
      case 0:
        return movementRxResult?.isLoading ? <Loading /> : <AddMovement prevScreen={handlePrevScreen} nextScreen={handleNextScreen} />
      case 1:
        return <SetAlarm prevScreen={handlePrevScreen} />
      case 2:
        return <CalculateYourTargetHeartRate prevScreen={() => setScreen(-1)} nextScreen={handleNextScreen} />
      case 3:
        return <AddToMyMoveGoal />
      default:
        return (
          <>
            <WrapperMyHealth title="moveRx" prevScreen={prevScreen} button={buttonMakeYourOwnExerciseRxToday}>
              {makeYourOwnExerciseRxToday}
            </WrapperMyHealth>
            {referenceTargetHeartRateZoneChart}
          </>
        )
    }
  }, [addDataMovementRx, screen, movementRxResult?.data, movementRxResult?.isLoading, dataMovement, movementRxResultById?.data, dataMovementId, idMovement])

  return <ResponsiveWrapper>{movementRxResult?.isLoading ? <Loading /> : isLoadingClickEdit.value ? <Loading /> : screenMovementRx}</ResponsiveWrapper>
}

export default MovementRx
