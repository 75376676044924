type Props = {
  totalPage: number
  currentPage: number
}

export const ProgressBar = ({ totalPage, currentPage }: Props) => {
  const listPage =
    totalPage > 0
      ? [...Array(totalPage)].map((page, index) => (
          <li
            key={`page-${index}`}
            className={`flex h-7 w-7 items-center justify-center rounded-full text-[20px] font-medium leading-none text-ma-pink-300 ${currentPage >= index ? 'bg-white' : 'bg-[#BFBFBE]'}`}
          >
            {index + 1}
          </li>
        ))
      : undefined

  return (
    <div className="relative h-7 w-full">
      <div className="absolute left-0 top-1/2 z-10 flex h-1 w-full -translate-y-1/2 justify-start bg-[#BFBFBE]">
        <div className="h-full bg-white" style={{ width: `calc(${(currentPage / (totalPage - 1)) * 100}%)` }} />
      </div>
      <ul className="relative z-50 flex w-full justify-between">{listPage}</ul>
    </div>
  )
}
