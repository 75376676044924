import axios from 'axios'
import { GoalAnswers, GoalDetail } from '../../models/goal'
import { AdessoError } from '../error'

type SubmitGoalAnswerResponse = {
  success: boolean
  data: GoalDetail
  message: string
}

export async function submitAnswers(goal_id: number, params: GoalAnswers, goal_memo_id?: number, date?: string): Promise<SubmitGoalAnswerResponse> {
  const { data, status } = await axios.post(`/goals/answers`, { answers: params, goal_id, goal_memo_id, date })

  if (status !== 200) {
    throw new AdessoError(`Request submit answer error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
