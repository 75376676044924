import { mapDataModalProgress } from '../maps/session'
import { mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion02Questions: Question[] = [
  mapQuestionNotRequired('ss02__029', QuestionTypes.TextArea, 'What kind of movement brings you joy and why?'),
  mapQuestionNotRequired('ss02__030', QuestionTypes.TextArea, 'What is one shift you can make in your schedule this week to allow at least 30 minutes to do this movement you love?'),
  mapQuestionNotRequired(
    'ss02__031',
    QuestionTypes.TextArea,
    'Is there one Target Movement that jumped out at you during this session? If so, what was it and write down what day this week you’ll do it at least 1 time. Make a commitment to move!'
  ),
  mapQuestionNotRequired('ss02__001', QuestionTypes.Rate, 'To what degree is it difficult for you to motivate yourself to exercise?'),
  mapQuestionNotRequired('ss02__002', QuestionTypes.Input),
  mapQuestionNotRequired('ss02__003', QuestionTypes.Input),
  mapQuestionNotRequired('ss02__004', QuestionTypes.Input),
  mapQuestionNotRequired('ss02__005', QuestionTypes.Input, `Standing while <br />working`),
  mapQuestionNotRequired('ss02__006', QuestionTypes.Input, `Walking to work`),
  mapQuestionNotRequired('ss02__007', QuestionTypes.Input, `Biking to work`),
  mapQuestionNotRequired('ss02__008', QuestionTypes.Input, `Walk or pace during<br />a phone call`),
  mapQuestionNotRequired('ss02__009', QuestionTypes.Input, `Cleaning or other <br />movement in your home`),
  mapQuestionNotRequired('ss02__010', QuestionTypes.Input, `Standing while <br />watching TV`),
  mapQuestionNotRequired('ss02__011', QuestionTypes.Input, `Stretching`),
  mapQuestionNotRequired('ss02__012', QuestionTypes.Input, `Standing up at least 1 an hour`),
  mapQuestionNotRequired('ss02__013', QuestionTypes.Input, `High-intensity walking `),
  mapQuestionNotRequired('ss02__014', QuestionTypes.Input, `Running`),
  mapQuestionNotRequired('ss02__015', QuestionTypes.Input, `Biking`),
  mapQuestionNotRequired('ss02__016', QuestionTypes.Input, `Hiking`),
  mapQuestionNotRequired('ss02__017', QuestionTypes.Input, `Cycling (indoor)`),
  mapQuestionNotRequired('ss02__018', QuestionTypes.Input, `Cycling (outdoor)`),
  mapQuestionNotRequired('ss02__019', QuestionTypes.Input, `Yoga`),
  mapQuestionNotRequired('ss02__020', QuestionTypes.Input, `Pilates`),
  mapQuestionNotRequired('ss02__021', QuestionTypes.Input, `Swimming`),
  mapQuestionNotRequired('ss02__022', QuestionTypes.Input, `Dancing`),
  mapQuestionNotRequired('ss02__023', QuestionTypes.Input, `Aerobics`),
  mapQuestionNotRequired('ss02__024', QuestionTypes.Input, `Lifting Weights`),
  mapQuestionNotRequired('ss02__025', QuestionTypes.Input, `What is your favorite Target Movement in your life right now?`),
  mapQuestionNotRequired('ss02__026', QuestionTypes.Input, `What is your favorite Daily Movement in your life right now?`),
  mapQuestionNotRequired('ss02__027', QuestionTypes.Input, undefined, '[enter your age]', undefined, true),
  mapQuestionNotRequired(
    'ss02__028',
    QuestionTypes.Radio,
    undefined,
    undefined,
    ['I don’t exercise regularly', 'I have an active lifestyle with sporadic exercise', 'I have a regular workout routine'],
    true
  ),
]
