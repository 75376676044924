import React, { useEffect, useMemo } from 'react'
import { ButtonCommon, ButtonStyles, ButtonType, SectionCommon, Wrapper, WrapperCommon } from '../Common'
import { useLocales } from '../../locales'
import { DATA_MY_TUTORIALS } from '../../constants/dataMockupMyTutorials'
import { useNavigate } from 'react-router-dom'
import { IntroPopup } from '../Common/IntroPopup'
import { useBoolean } from '../../hooks/use-boolean'
import { useHeaderContext } from '../../hooks/modals/global-set-header'

type Props = {}

const checkLink = ['/my-tutorials/learning-journey', '/my-tutorials/my-health', '/my-tutorials/goal-and-daily-tracking', '/my-tutorials/heart-chart']

const MyTutorialsContent = (props: Props) => {
  const { t } = useLocales()
  const navigate = useNavigate()
  const { setShowFeedback } = useHeaderContext()

  const isNotification = useBoolean()

  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  })

  useEffect(() => {
    setShowFeedback(false)
  }, [])

  const contentMyTutorials = useMemo(() => {
    return (
      <div className="space-y-8">
        <div className="space-y-8">
          <p>Want to keep learning about how to use MyAdesso to reach a heart healthy life ?</p>
          <p>Visit these sections of the App to learn more:</p>
        </div>
        <div className="flex flex-col gap-3">
          {DATA_MY_TUTORIALS.map((item) => {
            return (
              <div
                onClick={() => {
                  if (checkLink.includes(item.link)) {
                    navigate(`${item.link}`)
                  } else {
                    isNotification.onToggle()
                  }
                }}
                key={item.id}
                className="flex h-[64px] w-full cursor-pointer flex-row items-center justify-between rounded-[15px] bg-[#FFF] text-center leading-[10px]"
              >
                <h6 className="px-4 text-[18px] font-medium leading-[10px] text-[#000]">{item.title}</h6>
                <p className="px-3 text-[14px] font-normal leading-[10px] text-color-bg-primary">{item.numberOfMinutes} min</p>
              </div>
            )
          })}
          {isNotification.value && (
            <div className={`fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-60`}>
              <div className={`relative top-24 mx-auto w-[95%] max-w-[500px] rounded-md p-4`}>
                <div className="relative top-[14px] w-full rounded-[15px] bg-white px-6 py-[18px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
                  <p className="text-[17px] font-medium leading-[21px] !text-[#000]">{t('extraText.newFeature')}</p>
                </div>
                <div className="relative top-[14px] mt-4 w-full rounded-[15px]">
                  <ButtonCommon type={ButtonType.Button} style={ButtonStyles.Introduction} width="w-[167px]" height="h-[51px]" text="Got it!" onClick={isNotification.onToggle} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }, [isNotification.value])

  return (
    <WrapperCommon className="min-h-[calc(110vh-74px)] space-y-5 pt-0">
      <div className="fixed top-0 bottom-0 left-0 right-0 z-10 bg-white ">
        <div className="h-full bg-my-tutorials bg-cover bg-center bg-no-repeat"></div>
      </div>
      <SectionCommon className="relative z-10 space-y-4 text-[#FFF]" title={t('mainMenuAndSection.myTutorials')}>
        {contentMyTutorials}
      </SectionCommon>
    </WrapperCommon>
  )
}

export default MyTutorialsContent
