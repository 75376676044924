import { useState, useCallback, useMemo } from 'react'
import { ScreenHeartAssessment01 } from './HeartAssessment01'
import { SessionButtons } from '../../layout'
import { Session06Wrapper } from '../Wrapper'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

enum Screen {
  HeartAssessment00,
  HeartAssessment01,
}

export const ScreenHeartAssessment = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.HeartAssessment01 : step.sub_step)

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 1, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.HeartAssessment00) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.HeartAssessment01) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: Screen) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenHeartAssessment = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <Session06Wrapper buttons={buttons}>
            <div className="session-style">
              <h4>The anatomy of your heart</h4>
              <p>{`We've just explored all the aspects of our lifestyle and wellbeing that can impact our heart health. Now, we are going to learn about the heart.`}</p>
              <p>{`Nobody, especially women, want to even think that heart disease is a concern for them. Women often want to believe it’s a “man’s disease” as much as men! But as we’ve learned so far, heart health is the key to both overall health and wellbeing… a healthy heart means a vital life.`}</p>
              <p>Heart issues can be scary to many people, because we can't see it, feel it or understand it. And, we know bad things can happen if things go wrong.</p>
              <p>So, let's learn about the heart and take away some of the mystery...</p>
            </div>
          </Session06Wrapper>
        )

      case 1:
        return <ScreenHeartAssessment01 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />
    }
  }, [screen, buttons, handleBackScreen, handleNextScreen, showModal, handleUpdate])

  return <>{screenHeartAssessment}</>
}
