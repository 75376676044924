import {useCallback, useEffect, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {HeartJournalActionsContent} from '../../components/HeartActions'
import {useHeartJournalActionPage} from "../../hooks/pages/heart-journal-action";
import { format } from 'date-fns'

export const HeartJournalAction = () => {
  const navigate = useNavigate()
  const DATE_FORMAT = 'yyyy-MM-dd'
  const {dataResult, getGoalData} = useHeartJournalActionPage()
  const {slug} = useParams()
  if (slug === undefined) {
    navigate('/heart-journal')
  }

  useEffect(() => {
    if (dataResult) return
    if (slug) {
      const date = format(new Date(), DATE_FORMAT)
      getGoalData(slug, date)
    }
  }, [dataResult])

  const heartJournalDetailPage = useMemo(() => {
    const goal = dataResult?.data?.goal || undefined
    return <HeartJournalActionsContent goal={goal}/>
  }, [dataResult])

  return <>{heartJournalDetailPage}</>
}
