import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: {
    content: string
  }
  message?: string
}

export async function postHeartScore(params: object): Promise<Response> {
  const { data, status } = await axios.post('/heart-score', params)

  console.info('Data =>', data)
  console.info('Status =>', status)

  if (status !== 200) {
    console.info('postHeartScore', data)
    throw new AdessoError(`Request postHeartScore error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = status === 200 ? { data: data.data } : { message: data.message }

  return dataReturn
}
