import { ChangeEvent, useMemo, useCallback, useRef } from 'react'

import { Question } from '../../../models/question'

type Props = {
  isInputSS?: boolean
  isDefault?: boolean
  isNumber?: boolean
  children?: React.ReactNode
  value?: string
  question: Question
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeValue?: (value: string) => void
}

export const LabNumberInputForm = ({ isInputSS, isDefault, isNumber, value = undefined, question, onChange }: Props) => {
  const className = isDefault ? 'bg-transparent px-3' : isInputSS ? 'bg-ma-pink-100 px-3' : 'px-3'

  const inputRef = useRef<HTMLInputElement[]>([])
  const inputBloodRef = useRef<HTMLInputElement>(null)

  const bloodPressure = [
    {
      key: 'SYS',
      value: value ? value?.split('/')[0] : undefined,
    },
    {
      key: 'DIA',
      value: value ? value?.split('/')[1] : undefined,
    },
  ]

  const addToRefs = (el: HTMLInputElement) => {
    if (el && !inputRef.current.includes(el)) {
      inputRef.current.push(el)
    }
  }

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target

      if (bloodPressure.map((m) => m.key).includes(name)) {
        const val = `${inputRef.current[0].value}/${inputRef.current[1].value}`
        if (inputBloodRef && inputBloodRef.current) {
          inputBloodRef.current.value = val

          // Call onChange with inputBloodRef.current value
          const syntheticEvent = {
            target: inputBloodRef.current,
          } as ChangeEvent<HTMLInputElement> | any
          if (onChange) onChange(syntheticEvent)
        }
      }
    },
    [value]
  )

  const fillBlank = useMemo(() => {
    return (
      <div className="">
        {question.question === 'Blood Pressure' ? (
          <div className="flex space-x-4">
            <input
              ref={inputBloodRef}
              hidden
              type="text"
              value={question.value}
              id={question.name}
              name={question.name}
              className={`h-[32px] w-[66px] border-2 border-[#BFBFBE]`}
              placeholder={question.placeholder}
              onChange={onChange}
            />
            {bloodPressure.map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                <h4 className="font-semibold">{item.key}</h4>
                <input
                  ref={addToRefs}
                  type="text"
                  value={item.value}
                  id={item.key}
                  name={item.key}
                  className={`h-[32px] w-[46px] border-[1px] border-[#BFBFBE] py-1 px-1 text-center`}
                  placeholder={question.placeholder}
                  onChange={handleChangeInput}
                />
              </div>
            ))}
          </div>
        ) : (
          <input
            type="text"
            value={value ? value : undefined}
            id={question.name}
            name={question.name}
            className={`h-[32px] w-[66px] border-[1px] border-[#BFBFBE] py-1 text-center ${className}`}
            placeholder={question.placeholder}
            onChange={onChange}
          />
        )}
      </div>
    )
  }, [value, isDefault, question])

  return <>{fillBlank}</>
}
