import React, { useMemo, useState } from 'react'
import { Session02Wrapper } from '../../MyAdessoJourney/session/session-02/Wrapper'
import { ButtonMyHealth } from '../Button'
import { useMovementRxPage } from '../../../hooks/use-movement-rx'
import { useAlert } from '../../../context/AlertContext'
import { useBoolean } from '../../../hooks/use-boolean'
import { Link } from 'react-router-dom'
import { Logo } from '../../Common'

type TYourTargetHeartProps = {
  prevScreen?: () => void
  prevHome?: any
  dataQuestion?: {
    ss02__027: number
    ss02__028: string
  }
}

const YourTargetHeartRate = ({ prevScreen, prevHome, dataQuestion }: TYourTargetHeartProps) => {
  const { usePostMovementAutoRx } = useMovementRxPage()
  const { setAlertError } = useAlert()
  const isLoading = useBoolean()

  const [screen, setScreen] = useState(-1)

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = () => {
    setScreen((prev) => prev + 1)
  }

  const handleNextSubmit = async () => {
    try {
      isLoading.onTrue()
      await usePostMovementAutoRx()
    } catch (error) {
      isLoading.onFalse()
      setAlertError(`${error}`)
    } finally {
      isLoading.onFalse()
      prevHome()
    }
  }

  const lowContent = useMemo(() => {
    return (
      <>
        <p>
          This is a great place to start. Decide on something you enjoy that you can do that gets your heart rate to this target rate (plus or minus 10) for roughly 20-30 minutes, 4-5 times a week.
          Make it enjoyable, but something that pushes you a little bit each time, like walking faster or even mixing in a slight bit of jogging. Or a dance class! Be creative.
        </p>
        <p>You’ll want to slowly increase your heart rate to a moderate level over the course of this program.</p>
        <p>Next let’s choose some Target Movements that will help you get to your Target Heart Rate.</p>
      </>
    )
  }, [])

  const moderateContent = useMemo(() => {
    return (
      <>
        <p>
          You’re right in the sweet spot. As you exercise, try and maintain your heart rate (plus or minus 10) for 30 minutes, 5 times a week. And don’t be afraid to mix in different activities to
          keep it fun and fresh. Whatever works for you. You may gradually move to high intensity after a few months.
        </p>
        <p>Next let’s choose some Target Rhythms that will help you get to your Target Heart Rate.</p>
      </>
    )
  }, [])

  const highContent = useMemo(() => {
    return (
      <>
        <p>
          Fantastic! You’re really moving. As you exercise, try and maintain your heart rate (plus or minus 10) for 30 minutes, 5 times a week. And don’t be afraid to mix in different activities to
          keep it fun and fresh. Whatever works for you.
        </p>
        <p>Here are some things to keep in mind:</p>
        <ul className="list-disc space-y-6 pl-8 ">
          <li>High intensity workouts should be done on an interval basis.</li>
          <li>Weave in moderate intensity workouts for a balanced heart-healthy workout routine.</li>
        </ul>
        <p>Next let’s choose some Target Movements that will help you get to your Target Heart Rate.</p>
      </>
    )
  }, [])

  const screenCoreConcepts = useMemo(() => {
    // @ts-ignore
    // const value = (values && values['ss02__028']) || undefined
    const value = (dataQuestion && dataQuestion?.ss02__028) || undefined
    // @ts-ignore
    // const age = (values && values['ss02__027']) || 0
    const age = (dataQuestion && dataQuestion?.ss02__027) || 0

    let valuePoint = 72
    let content = highContent

    if (value === 'I don’t exercise regularly') {
      valuePoint = 40
      content = lowContent
    } else if (value === 'I have an active lifestyle with sporadic exercise') {
      valuePoint = 50
      content = moderateContent
    }

    const calcAge = ((220 - age) * valuePoint) / 100

    return (
      <div className="flex flex-col gap-4">
        <div
          className="absolute left-0 right-0 top-0 z-10 h-[250px] bg-slate-300 bg-cover bg-[center_top] bg-no-repeat"
          style={{ backgroundImage: 'url(/images/my-adresso-journey/session/ss2_pc.png)' }}
        />
        <header className="relative z-20 w-full p-4">
          <div className="w-full">
            <Link to="/">
              <Logo className="block h-[34px] w-[145px] hover:opacity-80 md:mx-auto" isLogoWhite={true} />
            </Link>
          </div>
          <h1 className={`flex h-[101px] w-full items-center font-butler text-[33px] leading-[35px] text-white md:justify-center`} dangerouslySetInnerHTML={{ __html: 'Get Your Move On: Exercise' }} />
        </header>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Your Target Heart Rate</h4>
          <img className="block h-auto w-full" src="/images/my-adresso-journey/session/02/img_008.jpg" alt="" />
          <p>Your target heart rate should be: {calcAge < 70 ? 70 : calcAge}</p>
          {content}
        </div>
        <div className="flex flex-row justify-center gap-4">
          <ButtonMyHealth nameButton="Back" prevScreen={prevScreen} bgButton="bg-color-primary" />
          <ButtonMyHealth
            nameButton="Next"
            nextScreen={handleNextSubmit}
            bgButton="bg-color-primary"
            className={`${isLoading.value ? 'cursor-wait px-[50px] opacity-25' : ''}`}
            isLoading={isLoading.value}
          />
        </div>
      </div>
    )
  }, [dataQuestion])

  return <>{screenCoreConcepts}</>
}

export default YourTargetHeartRate
