import { stressTracking } from '../../models/heartChart'

type Dates = {
  date: string
  statistics: string
}

export function mapDataChartStressTracking(data: Dates[]): number[] {
  const initialData = Array(7).fill(0)

  let newData = [...initialData]

  data.forEach((dateInfo: Dates) => {
    const dateIndex = parseInt(dateInfo.date) - 1
    const rate = Math.round(parseFloat(dateInfo.statistics))
    newData[dateIndex] = rate
  })

  return newData
}
