import axios from 'axios'

import { AdessoError } from '../../lib/error'
import { PramsNourish } from '../../models'

type RequestResponse = {
  success: boolean
  data: PramsNourish | undefined
  message: string
}

export async function postSubmitNourish(params: PramsNourish): Promise<RequestResponse> {
  const { data, status } = await axios.post('/nourishs', params)

  if (status !== 200) {
    throw new AdessoError(`Request submit nourish error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
