import React, { useCallback, useState } from 'react'
import { useAuth } from './use-auth'
import { AdessoResult } from '../models'
import { getHeartScoreHistory, getHeartScoreResult } from '../lib/request/heart-score'
import { mapDataHeartScoreQuestions } from '../lib/maps/heart-score-questions'
import { AdessoError } from '../lib/error'
import { mapDataHeartScoreResult } from '../lib/maps/heart-score-result'

const useHeartScore = () => {
  const [dataHSResult, setDataHSResult] = useState<AdessoResult<any>>()
  const [dataHSHistory, setDataHSHistory] = useState<AdessoResult<any>>()

  const auth = useAuth()

  const useGetDataHeartScore = useCallback(async (params: object) => {
    setDataHSResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartScoreResult(params)

        if (res.data) {
          const results = mapDataHeartScoreResult(res.data)
          setDataHSResult({
            isLoading: false,
            data: { heartScoreResult: results },
            error: undefined,
          })
        } else {
          setDataHSResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataHSResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get Heart Score Error'),
      })
    }
  }, [])

  const useGetByDateHSHistory = useCallback(async (params?: object) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setDataHSHistory({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await getHeartScoreHistory(params)
        if (res?.data) {
          setDataHSHistory({
            data: { heartScoreHistory: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setDataHSHistory({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataHSHistory({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Heart Score History error'),
      })
      throw new AdessoError('Get Heart Score History Failed')
    }
  }, [])

  return { dataHSResult, useGetDataHeartScore, dataHSHistory, useGetByDateHSHistory }
}

export default useHeartScore
