import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts10 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Use modern tools to move you.</h4>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_010.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_010.jpg`} alt="" />
          </picture>
          <p>
            While you are exercising, you will want to keep track of your target heart rate.
            <br />
            <br />
            {`Remember how we said modern technology often makes us stop movement? There are some nice exceptions, and a quick search online will help you identify the best options out there for you in terms of price and features.`}
            <br />
            <br />
            {`Keep an eye on your fitness tracker until you reach your target heart rate. When you get there, maintain your exercise at that level for 30 minutes. If you go above your heart rate zone, slow down. If you go below it, speed up.`}{' '}
          </p>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
