import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { Question } from '../../../models/question'

type Props = {
  children?: React.ReactNode
  value?: string
  question: Question
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

export const TextareaField = ({ value, question, onChange }: Props) => {
  const inputField = useMemo(() => {
    return (
      <textarea
        value={value}
        id={question.name}
        name={question.name}
        placeholder={question.placeholder}
        className="h-[120px] w-full border-transparent bg-[#FFE1EE] p-3 placeholder:text-ma-gray-900/50 focus:border-transparent focus:outline-0"
        onChange={onChange}
      />
    )
  }, [value, question, onChange])

  return <>{inputField}</>
}
