import { mapDataModalProgress } from '../maps/session'
import { mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion08Questions: Question[] = [
  mapQuestionNotRequired('ss08__015', QuestionTypes.CheckboxMulti, 'Given what you’ve read today, which of the following areas do you think might be an area to focus on for you?', undefined, [
    'High LDL cholesterol',
    'High HDL cholesterol',
    'High blood pressure',
    'High blood sugar',
    'Inflammation',
  ]),
  mapQuestionNotRequired('ss08__016', QuestionTypes.TextArea, 'Why did you choose that area?'),
  mapQuestionNotRequired('ss08__017', QuestionTypes.TextArea, 'Based on what you learned, which next steps would you like to take to improve these numbers?'),
  mapQuestionNotRequired('ss08__018', QuestionTypes.TextArea, 'What steps do you want to take right now to help move yourself forward?'),
  mapQuestionNotRequired('ss08__001', QuestionTypes.Rate, 'Do you know what your cholesterol numbers are and what they mean?'),
  mapQuestionNotRequired('ss08__002', QuestionTypes.Rate, 'Do you know what your blood pressure is and what it means to your heart health?'),
  mapQuestionNotRequired('ss08__003', QuestionTypes.Rate, 'Do you know what blood sugar is and what it means to your heart health?'),
  mapQuestionNotRequired('ss08__004', QuestionTypes.Rate, 'Do you know what inflammation is and what it means for your heart health?'),

  mapQuestionNotRequired('ss08__b001', QuestionTypes.Radio, 'LDL', undefined, ['<100- 0', '101-130', '>130', '> 160']),
  mapQuestionNotRequired('ss08__b002', QuestionTypes.Radio, 'HDL', undefined, ['<50', '51-60', '>61']),
  mapQuestionNotRequired('ss08__b003', QuestionTypes.Radio, 'Triglycerides', undefined, ['<150', '151-19', '200-499', '>/= 500']),
  mapQuestionNotRequired('ss08__b004', QuestionTypes.Radio, 'Blood Pressure', undefined, ['<120/80', '>130/80 - 139/89', '>140/90 - 159/99', '>160/100']),
  mapQuestionNotRequired('ss08__b005', QuestionTypes.Radio, 'Fasting Glucose', undefined, ['< 100', '> 100']),
  mapQuestionNotRequired('ss08__b006', QuestionTypes.Radio, 'Hemoglobin A1C', undefined, ['< 5.7', '5.7 - 6.4', '> 6.5']),
  mapQuestionNotRequired('ss08__b007', QuestionTypes.Radio, 'C-Reactive Protein:', undefined, ['< 1mg/L', '1 - 3mg/L', '> 3mg/L']),
  mapQuestionNotRequired('ss08__b008', QuestionTypes.Radio, 'Apoliprotein B', undefined, ['< 100 mg/dL', '> 100 mg/dL']),
  mapQuestionNotRequired('ss08__b009', QuestionTypes.Radio, 'Lp(a)', undefined, ['< 75 nmol/L', '75 - 125 nmol/L', '> 125 nmol/L']),
  mapQuestionNotRequired('ss08__b010', QuestionTypes.Radio, 'ApoE', undefined, ['E2/3', 'E3/3', 'E3/4', 'E4/4']),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  return { id, img, title, description, content }
}

const contentCoreConcepts03 = `
<p>
  Now that you know your numbers...
  <br/>If your numbers are high or you have symptoms or you have a family history, your doctor might want to get more tests. Next we’ll talk about those tests. In addition to that, the lifestyle changes we have learned in earlier sessions will help reduce the risk of heart disease and help you get on a path towards a healthier life.
</p>
<h4>Remember:</h4>
<ul>
  <li>Institute daily and focused movement into your life.</li>
  <li>Create habits that nourish your heart through a healthy diet.</li>
  <li>Get adequate sleep.</li>
  <li>Reduce stress, anxiety, and depression in your life and refresh yourself using the various techniques like sleep, exercise, meditation, intentional breathing, etc.</li>
  <li>Relate with others and the parts of your life that bring passion and purpose.</li>
</ul>
`

const contentCoreConcepts04 = `
<p>The cardiopulmonary exercise test is not widely used by cardiologists. We believe this will soon change, as the CPET (as this test is more commonly known) has been proven to recognize risk earlier and more accurately than many other tests. It can also give you a personalized exercise program based upon your unique testing results.</p>
<p>The American Heart Association has stated that cardiorespiratory fitness is a “potentially stronger predictor of mortality than established risk factors…” Up until now, cardiorespiratory fitness has not been part of predicting who is at risk of heart disease.</p>
<p>Cardiorespiratory fitness is best determined by finding the maximum amount of oxygen delivered to the muscles per minute, known as VO2 max, which is discovered during a CPET. The CPET is also the most sensitive way to accurately know your fitness level. This same study showed that the “strength of exercise capacity in predicting risk of mortality was even greater among women than men…”</p>
<p>For all these reasons, this powerful test is the gold standard for Adesso, which is why everyone who enters the Adesso Clinical Program* will take CPET every four months along with the aforementioned blood tests.</p>
<p>Let me see your CPET and I will know your heart and so much else about your health so very, very well!</p>
<p>Let’s learn a bit more about this wonderful testing tool.</p>
<p><i>*The Adesso Clinical Program is offered only in specific areas with trained providers. Contact us to learn more about when the program may be coming to your area or offered by your insurance or health care provider.</i></p>
`

const contentCoreConcepts05 = `
<p class="text-ma-pink"><b>What happens during a CPET?</b></p>
<p>When you have a CEPT, you are put on a stationary bike or on a treadmill.</p>
<p>Leads are attached to the front of your body so your heart rhythm can be monitored. It can also determine if your heart might be struggling during exercise. Blood pressure is also checked throughout the testing.</p>
<p>You wear a breathing apparatus that makes you look a little bit like Darth Vadar! This mask monitors your oxygen consumption. Your muscles need oxygen to exercise, and your heart is the only way it can get delivered. The CPET can see it all—how well the heart is doing its job, and if the arteries are working efficiently. It also gives insight into your lung function, too.</p>
<p>Some critical numbers are VO2 max, anaerobic threshold (to help determine your exercise prescription) and peak O2-pulse (the ml of blood delivered per beat). These major markers can help us determine how well the heart and the arteries are functioning.</p>
<p>You will be a sweaty mess when it is over, so just be prepared with sneakers, comfortable clothing and ditch the lipstick.</p>
<p>You’ll exercise for about 15 minutes, the test will last for about 40 minutes as the intensity slowly increases.</p>
`

const contentCoreConcepts06 = `
<p>Cholesterol is a waxy substance that is made in your liver and travels through your bloodstream. This important part of our nerve cells also works in the formation of our sex hormones. Too much of this substance can be bad for us.</p>
<p>Cholesterol becomes a problem when the levels are too high, especially the LDL, Apolipoprotein B and the triglycerides. When cholesterol is too high it can go inside the lining of the arteries (the endothelium) and cause plaque.</p>
<p class="text-ma-pink mt-5"><b>Know your numbers:</b></p>
<p class="!mt-2">With cholesterol, we track:</p>
<ul style="margin-top: 0">
<li>LDL (the bad kind of cholesterol, that we want to LOWER)</li>
<li>HDL (the good kind of cholesterol, that we want HIGHER)</li>
<li>Triglycerides: a type of fat that comes from the food you eat. We want lower.</li>
</ul>
<p class="text-ma-pink mt-5"><b>Track your numbers:</b></p>
<p class="!mt-2">Do you know your cholesterol numbers? Take a look back at your last blood work from a recent physical.</p>
<p>Don’t worry if you don’t know these numbers. You can learn more about them now and ask specifically about them during your next check-up or physical.</p>
`

const contentCoreConcepts07 = `
<p>Blood pressure is the pressure of circulating blood against the walls of the blood vessels when your heart pumps. Blood pressure goes up when the force to pump the blood is more difficult than it should be or it takes more effort to fill the heart with blood when it is in its relaxed state.</p>
<p>If your blood pressure is high, this can tell us whether the lining of your heart (the endothelium) is getting stiff, just like if someone is plugging the end of a garden hose to generate more pressure.</p>
<p>Blood pressure increases with age: half of women 60+ have high blood pressure! Which side you’re on when you hit 60 is up to you.</p>
<p>Blood pressure is typically taken when your provider wraps your arm with a cuff.</p>
<p>There is the top number, the systolic, and the bottom number, the diastolic.</p>
<p class="text-ma-pink !mt-5"><b>Know your numbers:</b></p>
<ul class="!mt-2">
<li><b>Systolic</b> is the pressure against your arteries when your heart is pumping. This is often associated with stress, poor diet and all those lifestyle choices that aren’t good for us. This is what leads to a stroke or heart attack.</li>
<li><b>Diastolic</b> is the blood pressure against your arteries when the heart is at rest. When this is elevated, it often is associated with lack of exercise or obesity. It increases your risk, too.</li>
</ul>
<p class="text-ma-pink !mt-5"><b>Track your numbers:</b></p>
<p class="!mt-2">What was your blood pressure the last time you visited the doctor? (You may have to look back on your medical records or if you have a smart watch, you can take it from there). Many pharmacies have a place for you to check your blood pressure.</p>
<p>It’s best to take the average over several readings, so average the last three measures you took.</p>
`

const contentCoreConcepts08 = `
<p>Your blood carries sugar in it that comes from the foods you eat. This sugar, or glucose, is the main source of the body’s energy. The body moderates blood sugar and transitions it into energy. As sugar goes up in the body, insulin is released to bring it down. If there is too much sugar, or the body doesn’t respond to the sugar because it is “resistant” to the effects of insulin, the sugar levels will go up. This is how Type 2 diabetes develops.</p>
<p>Sugar is one of the most damaging things that we can eat, as it leads to inflammation throughout the body damaging blood vessels, nerves, kidneys and eyes.</p>
<p>Blood sugar levels are measured through a blood test.</p>

<p class="text-ma-pink !mt-5"><b>Know your numbers:</b></p>
<ul class="!mt-2">
<li><b>Fasting Glucose</b> - This measures the level of sugar in your blood. The goal is less than 100.</li>
<li><b>HbA1C</b> - This measures the percentage of your red blood cells (hemoglobin) that have sugar attached to them, as they are carried through the bloodstream, and assesses the sugar levels over a 3-month period of time.</li>
</ul>
<p class="text-ma-pink !mt-5"><b>Track your numbers:</b></p>
<p class="!mt-2">Take a look back at your last blood work from a recent physical. If you don’t have them, schedule a doctor’s appointment and come back here or enter your blood sugar levels (Glucose and HbA1C) in your heart chart.</p>
`

const contentCoreConcepts09 = `
<p>When the lining of your heart wears down it begins to tear. Cholesterol can seep into the arteries more easily through these tears, causing inflammation that can compromise the artery’s flexibility and strength. This is how plaque starts to form.</p>
<p>Inflammation is triggered in our bodies through many different ways. Inflammation develops with stress, poor diets, lack of sleep, and sugar. It is also associated with auto-immune diseases. That’s why inflammation is associated with heart disease.</p>

<p class="text-ma-pink !mt-5"><b>Know your numbers:</b></p>
<ul class="!mt-2">
<li><b>C-reactive Protein</b> is a protein found in blood plasma, whose circulating concentrations rise in response to inflammation.</li>
</ul>
<p class="text-ma-pink !mt-5"><b>Track your numbers:</b></p>
<p class="!mt-2">To record your inflammation level you will have to order a special test called a “high sensitivity C-reactive protein” that’s not normally a part of the annual checkup, so you probably won’t be able to enter anything right now. Talk to your doctor about getting this test conducted.</p>
`

const contentCoreConcepts10 = `
<p>Beyond the typical measures that your provider will use to assess your heart in a physical or check-up, there are a number of advanced markers that can be incredibly helpful in knowing your risks that may lead to heart disease.</p>
<p class="text-ma-pink !mt-5"><b>Know your numbers:</b></p>
<ul class="!mt-2">
<li><b>Apolipoprotein B</b>: A blood test that can tell you your risk of heart disease. It is part of the cholesterol panel. Its job is to carry the cholesterol particles that are most likely to lead to plaque formation. So, the more apolipoprotein you have, the more dangerous cholesterol you have. Some say that this is a more sensitive and accurate marker of cardiovascular risk. This is especially helpful in people with the metabolic syndrome or diabetes.</li>
<li><b>Lp(a)</b>: Discovered in 1963, Lp(a) has been called a “novel risk factor,” as we better understand the true impact this genetic marker can have on the development of heart disease. Lp(a) is a marker of risk we inherit from our parents, which can increase clotting that can lead to heart attack and strokes. The higher the Lp(a) number is, the greater your risk. Elevated Lp(a) is more prevalent in African American women and men. Moderately elevated levels are above 30 mg/dL and high levels are above 50 mg/dL.</li>
<li><b>ApoE</b>: This marker is a protein that is involved in the metabolism of fats. ApoE combines with fats to remove them from the bloodstream. There are 3 major types- 2,3,4. With the genetic version 3/4 or 4/4, the body can not package the fats well, causing them to build up in the lining of the artery, leading to coronary artery disease or Alzheimer’s. A 2/3 type has been shown to be protective against Alzheimer's and coronary artery disease. Eating healthy fats and a diet low in sugar and carbohydrates is most beneficial. Knowing your ApoE is important to understand your risk and know how best to eat for your genetic type.</li>
</ul>
<p class="text-ma-pink !mt-5"><b>Track your numbers:</b></p>
<p class="!mt-2">Take a look back at your last blood work from a recent physical. If you don’t have them, schedule a doctor’s appointment and come back here or enter the numbers in your Heart Chart.</p>
`

const coreConceptsMore = `<h4>coreConceptsMore</h4>`
const coreConceptsMore201 = `
<p>Cholesterol is a waxy substance that is made in your liver and travels through your bloodstream. This important part of our nerve cells also works in the formation of our sex hormones. oo much of this substance can be bad for us.</p>
<p>Cholesterol becomes a problem when the levels are too high, especially of the LDL, Apolipoprotein B and the triglycerides. When cholesterol is too high it can go inside the lining of the arteries (the endothelium) and cause plaque.</p>
<h4>Know your numbers:</h4>
<div>
  <p>With cholesterol, we track:</p>
  <ul>
    <li>LDL (the bad kind of cholesterol, that we want to LOWER)<li>
    <li>HDL (the good kind of cholesterol, we want that number to go HIGHER)<li>
    <li>Triglycerides - these are another kind of fat that can cause problems with your arteries.<li>
  </ul>
</div>

<h4>Track your numbers:</h4>
<p>Do you know your cholesterol numbers? Take a look back at your last blood work from a recent physical.</p>
<p>Don’t worry if you don’t know these numbers. You can learn more about them now and ask specifically about them during your next check-up or physical.</p>
`

const coreConceptsMore202 = `
<p>Blood pressure is the pressure of circulating blood against the walls of the blood vessels when your heart pumps. Blood pressure goes up when the force to pump the blood is more difficult than it should be or it takes more effort to fill the heart with blood when it is in its relaxed state.</p>
<p>If your blood pressure is high, this can tell us whether the lining of your heart (the endothelium) is getting stiff, just like if someone is plugging the end of a garden hose to generate more pressure.</p>
<p>Blood pressure increases with age: half of women 60+ have high blood pressure! Which side you’re on when you hit 60 is up to you.</p>
<p>Blood pressure is typically taken when your provider wraps your arm with a cuff.</p>
<p>There is the top number, the systolic, and the bottom number, the diastolic.</p>
<h4>Know your numbers:</h4>
<ul>
  <li><b>Systolic</b> is the pressure against your arteries when your heart is pumping.This is often associated with stress, poor diet and all those lifestyle choices that aren’t good for us. This is what leads to a stroke or heart attack.</li>
  <li><b>Diastolic</b> is the blood pressure against your arteries when the heart is at rest. When this is elevated, it often is associated with lack of exercise or obesity. It increases your risk, too.</li>
</ul>

<h4>Track your numbers:</h4>
<p>What was your blood pressure the last time you visited the doctor? (You may have to look back on your medical records or if you have a smart watch, you can take it from there). Many pharmacies have a place for you to check your blood pressure. If you can’t find it, you can always add it to your heart chart (link) later.</p>
<p>It’s best to take the average over several readings, so average the last three measures you took.</p>
`

const coreConceptsMore203 = `
<p>Your blood carries sugar in it that comes from the foods you eat. This sugar, or glucose, is the main source of the body’s energy. The body moderates blood sugar and transitions it into energy. As sugar goes up in the body, insulin is released to bring it down. If there is too much sugar, or the body doesn’t respond to the sugar because it is “resistant” to the effects of insulin, the sugar levels will go up. This is how Type 2 diabetes develops.
<br/>vessels. Sugar is one of the most damaging things that we can eat, as it leads to inflammation throughout the body damaging blood vessels, nerves, kidneys and eyes.</p>

<p>Blood sugar levels are measured through a blood test..</p>
<h4>Know your numbers:</h4>
<ul>
  <li><b>Fasting Glucose</b> - This measures the level of sugar in your blood. The goal is less than 100.</li>
  <li><b>HbA1C</b> - This measures the percentage of your red blood cells (hemoglobin) that have sugar attached to them, as they are carried through the bloodstream, and assesses the sugar levels over a 3-month period of time.</li>
</ul>

<h4>Track your numbers:</h4>
<p>Take a look back at your last blood work from a recent physical. If you don’t have them, schedule a doctor’s appointment and come back here or enter your blood sugar levels (Glucose and HbA1C) in your heart chart.</p>
`

const coreConceptsMore204 = `
<p>When the lining of your heart wears down it begins to tear. Cholesterol can seep into the heart more easily through these tears, causing inflammation that can compromise the artery’s flexibility and strength. This is how plaque starts to form.</p>

<p>Inflammation is triggered in our bodies through many different ways. Inflammation develops with stress, poor diets, lack of sleep, and sugar. It is also associated with auto-immune diseases. That’s why inflammation is associated with heart disease.</p>
<h4>Know your numbers:</h4>
<ul>
  <li><b>FC-reactive Protein</b>  is a protein found in blood plasma, whose circulating concentrations rise in response to inflammation.</li>
</ul>

<h4>Track your numbers:</h4>
<p>To record your inflammation level you will have to order a special test called a “high sensitivity C-reactive protein” that’s not normally a part of the annual checkup, so you probably won’t be able to enter anything right now. Talk to your doctor about getting this test conducted.</p>
`

const coreConceptsMore205 = `
<p>Beyond the typical measures that your provider will use to assess your heart in a physical or check-up, there are a number of advanced markers that can be incredibly helpful in knowing your risks that may lead to heart complications.</p>

<p>These tests may or may not be covered under your insurance, so discuss them with your health care provider and find out if they can be covered.</p>
<h4>Know your numbers:</h4>
<ul class="space-y-6">
  <li><b>Apolipoprotein B:</b> There are many different types of “bad” cholesterol besides the LDL. Each type of these bad cholesterol is carried by the Apolipoprotein B protein. This test is a more sensitive test of your risk of heart disease based on the evaluation of your true cholesterol numbers that are dangerous for the development of plaque and heart disease risk. A goal level is less than 100 mg/dL.</li>

  <li><b>Lp(a):</b> Discovered in 1963, Lp(a) has been called a “novel risk factor,” as we better understand the true impact this genetic marker can have on the development of heart disease. Lp(a) is a marker of risk we inherit from our parents, which can increase clotting that can lead to heart attack and strokes. The higher th e Lp(a) number is, the greater your risk. Elevated Lp(a) is more prevalent in African American women and men. Moderately elevated levels are above 30 mg/dL and high levels are above 50 mg/dL.</li>

  <li><b>ApoE:</b> This marker is a protein that is involved in the metabolism of fats. ApoE combines with fats to remove them from the bloodstream. There are 3 major types- 2,3,4. With the genetic version 3/4 or 4/4, the body can not package the fats well, causing them to build up in the lining of the artery, leading to coronary artery disease or Alzheimer’s. A 2/3 type has difficulty processing sugars leading to obesity and diabetes. Knowing your Apo E is important to understand your risk and know how best to eat for your genetic type.</li>
</ul>

<h4>Track your numbers:</h4>
<p>Take a look back at your last blood work from a recent physical. If you don’t have them, schedule a doctor’s appointment and come back here or enter the numbers in your Heart Chart.</p>
`

export const dataCoreConceptsMores01: CoreConceptsStops[] = [
  mapCoreConcepts(201, 'ss08_01.webp', 'Cholesterol Panel', '', coreConceptsMore201),
  mapCoreConcepts(202, 'img_004.webp', 'Blood Pressure', '', coreConceptsMore202),
  mapCoreConcepts(203, 'img_005.webp', 'HbA1C (blood sugar)', '', coreConceptsMore203),
  mapCoreConcepts(204, 'img_006.webp', 'Inflammation - CRP', '', coreConceptsMore204),
  mapCoreConcepts(205, 'img_007.webp', 'Lp(a)', '', coreConceptsMore205),
]

const coreConceptsMore301 = `
<h4 class="text-ma-pink">What does it tell us?</h4>
<p>An EKG tells us the electrical activity of the heart. It can also tell if there has been damage to the heart muscle (heart attack) or if there is a problem with blood flow to the heart (ischemia). It can also tell us about the structure of the atria and the ventricles, and can give us clues as to the electrolyte levels in the blood.</p>


<picture>
  <source srcset="/images/my-adresso-journey/session/08/img_011.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/08/img_011.jpg" alt="">
</picture>
<h4 class="text-ma-pink">How does it work?</h4>
<p>A doctor will attach small electrode patches or “leads” across your chest, arms, and legs.
<br/>The leads measure the electrical conduction of your heart and then the machine interprets them in graphic form.</p>
<p>It is totally painless and just takes minutes to perform. You just need to lay still while the EKG is being performed.</p>
`

const coreConceptsMore302 = `
<h4 class="text-ma-pink">What does it tell us?</h4>
<p>An echocardiogram is an ultrasound of the heart, just like what doctors use to examine pregnancy, except it is on your chest, so we can see your heart.</p>
<p>The ultrasound, known as "echo," can see your heart valves, the size of the chambers of the heart, the function of the heart, the size of the heart muscle and the lining of the heart. It’s one of the best ways for us to see what’s going on there!</p>

<h4 class="text-ma-pink">How does it work?</h4>
<p>You’ll lie on your left side and get hooked up to the machine with the same kind of leads as your EKG.</p>
<p>The doctor puts lubricant on a probe and will place it on your chest. You might be asked to take shallow or deep breaths, roll more or less to one side or the other, and you also will have to lie flat for some of the pictures.</p>
<p>The point is to see the heart as clearly as possible.</p>
<p>It can take between 20 minutes to 45 minutes, depending on how easy it is to see your heart and if there are issues to be evaluated.</p>
`

const coreConceptsMore303 = `
<h4 class="text-ma-pink">What does it tell us?</h4>
<p>A Carotid doppler is an ultrasound that looks specifically at the carotid arteries, which are the main arteries that deliver blood to the brain.</p>
<p>The test looks for plaque in the carotid arteries, which can lead to a stroke. It also can measure the thickness of the carotid arteries, which may be the first sign of the development of plaque before it happens. It’s a good tool to let us know how aggressive we need to be with our preventive strategies!</p>

<h4 class="text-ma-pink">How does it work?</h4>
<p>Just like an echocardiogram, a probe with lubricant is used. This time the probe is placed on the neck, where the carotid arteries are found. There is one on the left and one on the right. The test usually takes between 15-20 minutes.</p>
`

const coreConceptsMore304 = `
<h4 class="text-ma-pink">What does it tell us?</h4>
<p>This test is useful if you have a family history of heart disease or your cholesterol is elevated. This is a screening test when you have risk factors for heart disease, and you are trying to determine if you need aggressive intervention.</p>

<h4 class="text-ma-pink">How does it work?</h4>
<p>The coronary artery calcium score is a CT scan of the heart. There is no dye and no contrast.</p>
<p>You lie on a table and go under a big tube, which is the camera. You are asked to hold your breath and in 15 seconds the picture is taken. It takes longer to fill out the paperwork than it does to actually have the test!</p>
<p>The Coronary Artery Calcium score (known as CAC) can see calcium in the arteries. Calcium is associated with plaque in the arteries. This is a different kind of calcium than the calcium found in your bones or in milk or cheese. This calcium occurs at the end stage of the development of plaque as a hardened cap to seal off the damage to the artery that has occurred. Plaque begins with microtears in the lining of the artery. Inside those tears, cholesterol and inflammatory cells will enter, trying to fight off the damage done. At first this plaque is soft and goopy, but as the plaque matures, calcium comes to seal it off. The body tries to protect you like this. When we see calcium, we know there is mature old plaque there. We can just assume that there is probably soft plaque there that we can’t see. When there is an elevated calcium score, we know that prevention is critical, and sometimes this is the reason to start cholesterol-lowering medication.</p>
`

const contentCoreConceptsNoContent = ''

export const dataCoreConceptsMores02: CoreConceptsStops[] = [
  mapCoreConcepts(301, 'img_010.webp', 'EKGs', '', coreConceptsMore301),
  mapCoreConcepts(302, 'img_012.webp', 'Echocardiogram', '', coreConceptsMore302),
  mapCoreConcepts(303, 'img_013.webp', 'Carotid doppler', '', coreConceptsMore303),
  mapCoreConcepts(304, 'img_014.webp', 'Coronary artery calcium score', '', coreConceptsMore304),
]

export const dataCoreConcepts = [
  mapCoreConcepts(0, undefined, 'Primary Numbers', '', contentCoreConceptsNoContent),

  mapCoreConcepts(1, '/images/my-adresso-journey/session/08/ss08_01.webp', 'Cholesterol Panel', '', contentCoreConcepts06),
  mapCoreConcepts(2, '/images/my-adresso-journey/session/08/ss08_02.webp', 'Blood Pressure', '', contentCoreConcepts07),
  mapCoreConcepts(3, '/images/my-adresso-journey/session/08/ss08_03.webp', 'HbA1C (blood sugar)', '', contentCoreConcepts08),
  mapCoreConcepts(4, '/images/my-adresso-journey/session/08/ss08_04.webp', 'Inflammation in the Arteries', '', contentCoreConcepts09),
  mapCoreConcepts(5, '/images/my-adresso-journey/session/08/ss08_05.webp', 'Advanced markers ', '', contentCoreConcepts10),

  mapCoreConcepts(7, 'ss08_05.webp', 'What You Can Do', '', contentCoreConcepts03),
  mapCoreConcepts(8, 'img_008.webp', 'CPET - The Adesso Gold Standard', '', contentCoreConcepts04),
  mapCoreConcepts(9, 'img_008.webp', 'CPET - The Adesso Gold Standard', '', contentCoreConcepts05),

  mapCoreConcepts(10, 'ss_08.mp4', '', '', ''),

  mapCoreConcepts(11, 'img_009.webp', 'A few more numbers', '', contentCoreConceptsNoContent),

  mapCoreConcepts(301, 'img_010.webp', 'EKGs', '', coreConceptsMore301),
  mapCoreConcepts(302, 'img_012.webp', 'Echocardiogram', '', coreConceptsMore302),
  mapCoreConcepts(303, 'img_013.webp', 'Carotid doppler', '', coreConceptsMore303),
  mapCoreConcepts(304, 'img_014.webp', 'Coronary artery calcium score', '', coreConceptsMore304),
]
