import { useState, useCallback, useMemo, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Session01Wrapper } from '../Wrapper'
import { CoreConceptsWrapper } from '../CoreConceptsWrapper'
import { HeartRiskFactorsItem } from '../HeartRiskFactorsItem'
import { SessionButtons } from '../../layout'

import { HeartRickFactorsStatus, HeartRickFactorsType } from '../../../../../models'
import { dummyDataHeartRickFactors } from '../../../../../lib/data/session-01'
import { ButtonRx } from '../../ButtonRx'
import { useLocales } from '../../../../../locales'

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
}

export const ScreenCoreConcepts02 = ({ showModal, nextScreen, backScreen }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useLocales()

  const [dataShow, setDataShow] = useState<HeartRickFactorsType>(dummyDataHeartRickFactors[0])
  const [isDisableNext, setIsDisableNext] = useState<boolean>(true)
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)
  const [dataHeartRickFactors, setdataHeartRickFactors] = useState<HeartRickFactorsType[]>(dummyDataHeartRickFactors)

  useEffect(() => {
    let newCount: number = 0
    const newData: HeartRickFactorsType[] = dataHeartRickFactors.map((item) => {
      if (item.status === HeartRickFactorsStatus.Read) newCount++
      return item
    })
    setdataHeartRickFactors(newData)
    setIsDisableNext(newCount < 3)
  }, [])

  const handleBackScreen = useCallback(
    (statusShowDetail: boolean) => {
      if (statusShowDetail) {
        window.scrollTo(0, 0)
        setIsShowDetail(false)
      } else backScreen()
    },
    [backScreen]
  )

  const handleShowDetail = useCallback(
    (id: number) => {
      const dataDetail = dummyDataHeartRickFactors.find((item) => item.id === id)

      if (dataDetail) {
        window.scrollTo(0, 0)
        setDataShow(dataDetail)
        setIsShowDetail(true)

        let newCount: number = 0
        const newData: HeartRickFactorsType[] = dataHeartRickFactors.map((item) => {
          if (item.id === id) item.status = HeartRickFactorsStatus.Read
          if (item.status === HeartRickFactorsStatus.Read) newCount++
          return item
        })
        setdataHeartRickFactors(newData)
        setIsDisableNext(newCount < 3)
      }
    },
    [backScreen]
  )

  const buttons = useMemo(() => {
    return (
      <SessionButtons
        isDisableNext={isDisableNext}
        styleButton={'dark'}
        showModal={isShowDetail ? undefined : showModal}
        backScreen={() => handleBackScreen(isShowDetail)}
        nextScreen={isShowDetail ? undefined : nextScreen}
      />
    )
  }, [dataShow, isShowDetail, isDisableNext, handleBackScreen, nextScreen, showModal])

  const screenList = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <h4 className="text-[18px] font-bold leading-[22px]">{t('myAdessoJourney.session1.title')}</h4>
          <p>{t('myAdessoJourney.session1.description')} </p>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/01/img_002.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/01/img_002.jpg`} alt="Description" />
          </picture>
          <div className="space-y-3">
            {dataHeartRickFactors.map((item, index) => (
              <HeartRiskFactorsItem key={`HeartRickFactors-${item.id}`} data={item} showDetail={() => handleShowDetail(item.id)} />
            ))}
          </div>
          <ButtonRx title={t('myAdessoJourney.session1.buttonRx')} handleClick={() => navigate(`/my-health/lifestyle-rx/?previousUrl=${location.pathname}`)} />
          <p>
            {t('myAdessoJourney.session1.descriptionVisit')}
            <Link to="/go-deeper" className="text-[#B7005E] underline">
              {t('myAdessoJourney.session1.visitDeeper')}
            </Link>
          </p>
        </div>
      </Session01Wrapper>
    )
  }, [dataHeartRickFactors, buttons, handleShowDetail])

  const screenDetail = useMemo(() => {
    return <CoreConceptsWrapper buttons={buttons} content={dataShow.description} />
  }, [dataShow, buttons])

  const screenCoreConcepts02 = useMemo(() => {
    return <>{isShowDetail ? screenDetail : screenList}</>
  }, [isShowDetail, screenList, screenDetail])

  return <>{screenCoreConcepts02}</>
}
