import { mapDataModalProgress } from '../maps/session'
import { mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion06Questions: Question[] = [
  mapQuestionNotRequired('ss06__005', QuestionTypes.TextArea, 'What habits in your life may eventually lead to heart disease?', 'Describe what you want to change.'),
  mapQuestionNotRequired('ss06__006', QuestionTypes.TextArea, 'What steps do you want to take to live healthier?', 'Describe what you want to change.'),
  mapQuestionNotRequired('ss06__001', QuestionTypes.Rate, 'I feel like I know _____ about how my heart works.'),
  mapQuestionNotRequired('ss06__002', QuestionTypes.Rate, 'I feel like I know _____ about what can go wrong in my heart that can cause heart problems.'),
  mapQuestionNotRequired('ss06__003', QuestionTypes.Rate, 'I know _____ about what I can do to reduce risk factors that lead to heart disease.'),
  mapQuestionNotRequired('ss06__004', QuestionTypes.CheckboxMulti, 'What are you doing to live a heart-healthy life?', undefined, [
    'Moving everyday',
    'Nourishing my body with healthy foods',
    'Taking time to refresh to reduce stress',
    'Focusing on relating to the world around me by living with purpose and passion, and fostering my relationships',
    'Getting more sleep',
    'Quitting smoking and creating healthy alcohol habits',
  ]),
  mapQuestionNotRequired('ss06__b001', QuestionTypes.TextArea, '', 'Enter your resting heart rate here.'),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  return { id, img, title, description, content }
}

const contentCoreConcepts01 = `
<p>In the last several sessions we discussed very important areas of your heart health: moving, nourishing, refreshing, and relating. These are the foundations of our wellbeing.</p>

<p>Why?</p>

<p>Because every aspect of our lives connects right to the center of our lives: our loving, working, beating heart.</p>

<p>Everything we talked about can lead to the development of plaque or prevent the development of plaque. We can prevent atherosclerosis, which is the development of plaque in the arteries, 80% of the time. This is the most common form of heart disease that leads to heart attacks and strokes.</p>

<p>And what people often don’t know is that plaque in the arteries is very, very different in women than it is in men—and it’s harder to detect. We’ll get into this more later, but this often leads to women not knowing about their potential disease until it’s a real threat.</p>

<p>Simply by shifting our lifestyle choices, we can take control and prevent heart disease in our lives.</p>

<p>Now, let’s explore the heart and how it works so that this invisible thing isn't scary to you anymore.</p>`

const contentCoreConcepts03 = `
<p>First, let’s talk about how the heart works. The heart's job is to get oxygen to the body through the blood in the arteries. It is a muscle that pumps blood throughout the body and to the lungs to get oxygen. It is controlled by the autonomic nervous system, and you don't even have to think about it.</p>
<p>The valves control the blood moving in the right directions through the heart.</p>
<p>Un-oxygenated blood goes from the right side of the heart to the lungs and then gets sent back to the left side, where oxygenated blood gets pumped out of the heart through the arteries to the entire body.</p>
<h4 class="text-ma-pink">You can think of the heart in terms of these four key functions:</h4>
<ul>
  <li>Arteries: Carry oxygen-filled blood to all the cells of the body.</li>
  <li>Muscle: Pumps the blood to, through, and from the heart.</li>
  <li>Electrical system: Controls the heart rhythm.</li>
  <li>Valves: Regulate the flow of blood through the chambers of the heart.</li>
</ul>
<p>Let’s take a moment to explore these functions and what happens when they aren’t working correctly.</p>
`

const contentCoreConcepts04 = `
<ul class="space-y-6">
  <li>Arteries carry blood out from the heart to other areas of the body.</li>
  <li>Too much cholesterol, high sugars, elevated blood pressure, smoking, lack of sleep, poor diet, and a sedentary lifestyle can negatively damage the lining of the arteries (the endothelium).</li>
  <li>Coronary artery disease develops when the arteries become stiff. Once they become stiff, tears in the lining of the artery can occur from high blood pressure, elevated sugars or high cholesterol, and all the other lifestyle issues, like stress, poor diet, lack of sleep and no exercise.</li>
  <li>Plaque is a buildup of cholesterol and inflammatory cells that form inside those microtears in the lining of the artery.</li>
  <li>A heart attack happens when the plaque ruptures and blocks the flow of blood to the heart. If you experience chest pain, shortness of breath, jaw pain, back pain, nausea and vomiting, fatigue, and even flu-like symptoms, talk to your health care provider immediately. These could be symptoms that occur when the heart is not getting enough oxygen.</li>
</ul>
`

const contentCoreConcepts05 = `
<ul class="space-y-6">
  <li>The heart muscle pumps blood to and through your heart to the other parts of the body.</li>
  <li>When the heart is weak or becomes stiff it can cause a range of problems. The heart muscle can also become inflamed.</li>
  <li>With high blood pressure and lack of exercise, the heart muscle can also become stiff and have difficulty filling.</li>
  <li>If you experience chest pain, chest pressure, shortness of breath, nausea, vomiting, radiating pain from chest to back, swelling of your ankles, problems laying flat, difficulty with exertion or exercise, talk to your health care provider.</li>
</ul>
`

const contentCoreConcepts06 = `
<ul class="space-y-6">
  <li>The electrical system sends a signal to your heart to tell it to beat.</li>
  <li>When the beat is irregular, too slow or not synchronized, the heart cannot function well.</li>
  <li>This can require getting a pacemaker so that the two are in sync.</li>
  <li>Sometimes, these irregular rhythms can lead to a stroke, so it is important to know if there is a problem.</li>
  <li>Other rhythms can be a sign that the heart is not getting enough oxygen, which can be dangerous.</li>
  <li>If you feel palpitations, dizziness, lightheadedness, feeling like passing out, shortness of breath, and an inability to exercise, talk to your primary care provider.</li>
</ul>
`

const contentCoreConcepts07 = `
<ul class="space-y-6">
  <li>The heart valves are the doors that direct your blood in the right directions through the heart. They also prevent backflow.</li>
  <li>Heart disorders occur if valves are loose or leaking or too tight and can’t open correctly.</li>
  <li>When there are problems with the valves, your health care provider might hear a “murmur” through the stethoscope.</li>
  <li>See your healthcare provider if you’re experiencing palpitations, shortness of breath, inability to exercise or signs of retaining water such as swelling of the ankles.</li>
</ul>
`

const contentCoreConcepts08 = `
<p>Your resting heart rate is important because it can tell us a lot about the health of your heart. Hearts tend to get more pliable with exercise. A healthy heart doesn’t have to work as hard to supply the body with oxygenated blood. Hearts that are more stiff or sick have to beat more. A resting heart rate less than 72 has been shown to reduce the risk of heart disease.</p>
<div>
  <h4 class="text-ma-pink">What is your resting heart rate?</h4>
  <p>You can figure this out by counting the number of times your heart beats in a minute, or use a pulse oximeter or smart watch to tell you the number. Do this when you’ve been sitting down for 20 minutes.</p>
</div>
`

export const dataCoreConcepts = [
  mapCoreConcepts(1, 'img_001.webp', 'Getting to the heart of the matter.', '', contentCoreConcepts01),
  mapCoreConcepts(2, 'ss_06.mp4', undefined, '', ''),
  mapCoreConcepts(3, 'img_003.webp', 'How does your heart work?', '', contentCoreConcepts03),
  mapCoreConcepts(4, 'img_004.webp', 'Arteries.', '', contentCoreConcepts04),
  mapCoreConcepts(5, 'img_005.webp', 'Heart muscle.', '', contentCoreConcepts05),
  mapCoreConcepts(6, 'img_006.webp', 'The electrical system.', '', contentCoreConcepts06),
  mapCoreConcepts(7, 'img_007.webp', 'Heart valves.', '', contentCoreConcepts07),
  mapCoreConcepts(8, 'img_008.webp', 'Your resting heart rate.', '', contentCoreConcepts08),
]

const coreConceptsMore = `<h4>coreConceptsMore</h4>`

export const dataCoreConceptsMores: CoreConceptsStops[] = [mapCoreConcepts(9, 'img_003.webp', 'Work', '', coreConceptsMore)]
