import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts02 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6 text-[18px] leading-[22px]">
          <h4 className="font-bold">Born to move.</h4>
          <picture className="block h-auto w-full">
            <source srcSet={`/images/my-adresso-journey/session/02/img_002.webp`} type="image/webp" />
            <img src={`/images/my-adresso-journey/session/02/img_002.jpg`} alt="" />
          </picture>
          <p>
            We are genetically designed to move. Humanity’s great superpower over other animals is to walk for long distances. Early hunters couldn’t race down their prey… they could walk and follow
            them to exhaustion, according to author Christopher McDougall, <i>Born to Run</i>.
            <br />
            <br />
            So why aren’t we still moving? Modern society makes life "easier" in many ways that limit our natural movement.
            <br />
            <br />
            Which means exercise is more important than ever. Exercise helps you stay heart healthy in the following ways:
          </p>

          <ul className="list-disc pl-8">
            <li>It lowers blood pressure</li>
            <li>Reduces cholesterol</li>
            <li>Lowers your body weight</li>
            <li>Improves your mood</li>
          </ul>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
