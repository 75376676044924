import { Outlet } from 'react-router-dom'

import { CommonHeader } from '../Common/Header'
import { CommonFooter } from '../Common/Footer'
import { useLocales } from '../../locales'
import { useHeaderContext } from '../../hooks/modals/global-set-header'
import { useEffect, useState } from 'react'
import CustomAlert from '../Alert'

type Props = {
  noHeader?: boolean
  headerAbsolute?: boolean
  noFooter?: boolean
  hasbgcolor?: boolean
}

export const LayoutWrapper = ({ noHeader = false, noFooter = false, headerAbsolute = false, hasbgcolor = false }: Props) => {
  const feedbackUrl = import.meta.env.VITE_FEEDBACK_URL
  const { t } = useLocales()
  const { showHeader, showFeedback } = useHeaderContext()
  const [isHeaderVisible, setHeaderVisible] = useState(!noHeader)
  const [isFeedbackVisible, setFeedbackVisible] = useState(true)

  useEffect(() => {
    setHeaderVisible(showHeader)
  }, [showHeader])

  useEffect(() => {
    setFeedbackVisible(showFeedback)
  }, [showFeedback])

  return (
    <div className={`w-full ${hasbgcolor ? 'bg-gradient-to-b from-[rgba(237,37,128,0.8)] to-[rgba(242,110,87,0.8)]' : ''}`}>
      <div className="relative mx-auto h-full max-h-max min-h-screen w-full min-w-[375px] max-w-[895px] bg-white font-proxima-nova">
        {isFeedbackVisible && (
          <a href={feedbackUrl} className="fixed right-5 top-5 z-50 cursor-pointer rounded-full bg-[#D42128] bg-opacity-80 px-4 py-2 font-semibold text-white hover:text-pink-200">
            {t('common.feedback')}
          </a>
        )}
        <div className="fixed right-5 top-5 z-50">
          <CustomAlert />
        </div>

        {isHeaderVisible && <CommonHeader className={headerAbsolute ? 'z-40' : 'relative z-40 bg-white'} isLogoWhite={headerAbsolute} />}

        <div className={`mt-[80px] flex h-full w-full flex-col bg-white px-1 text-black ${noHeader ? 'min-h-screen' : 'min-h-[calc(100vh-74px)]'} ${noFooter ? '' : 'pb-[58px]'}`}>
          <Outlet />
        </div>

        {!noFooter && <CommonFooter />}
      </div>
    </div>
  )
}
