import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Question } from '../../../models/question'

type Props = {
  question: Question
  value?: number
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  onChange: Function
}

export const RateField = ({ question, disabled, value, onChange }: Props) => {
  const [inputValue, setInputValue] = useState<number>((value || 3) * 10)

  // Cập nhật state khi prop value thay đổi
  useEffect(() => {
    setInputValue((value || 3) * 10)
  }, [value])

  const handleChangeRate = useCallback(
    (e: any) => {
      const newValue = e.target.value
      setInputValue(newValue)
      onChange(question?.name, String(newValue / 10) ?? 3)
    },
    [onChange, question?.name]
  )

  const rateContent = useMemo(() => {
    return (
      <div className="relative w-full pb-[18px]">
        <div className="flex w-full cursor-pointer justify-between px-5 text-[16px] font-medium leading-[22px]">
          <span className="w-[12.5%] text-left">1</span>
          <span className="w-1/4 text-center">2</span>
          <span className="w-1/4 text-center">3</span>
          <span className="w-1/4 text-center">4</span>
          <span className="w-[12.5%] text-right">5</span>
        </div>
        <input type="range" min="10" max="50" name={question.name} disabled={disabled} value={inputValue} onChange={handleChangeRate} />
      </div>
    )
  }, [inputValue, question.name, disabled, handleChangeRate])

  return <div className="space-y-4">{rateContent}</div>
}
