import axios from 'axios'
import {GoalFromApiResponse} from "../../models/goal";
import {AdessoError} from "../error";
import {MyNow} from "../../models";

type UpdateMyNowResponse = {
  success: boolean
  data: MyNow | undefined
  message: string
}

export async function postUpdateMyNow(myNow: string): Promise<UpdateMyNowResponse> {
  const {data, status} = await axios.post(`/my-now`, {'content': myNow})
  if (status !== 200) {
    console.info('postUpdateMyNow', data)
    throw new AdessoError(`Request update my now error - ${status} ${JSON.stringify(data)}`)
  }

  return data;
}
