import { useMemo } from 'react'
import { SessionWrapper } from '../layout'

type Props = {
  title?: string
  isLoading?: boolean
  hiddenHeader?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
  buttons?: React.ReactNode
}

export const Session04Wrapper = ({ hiddenHeader, isLoading, title, children, style, buttons }: Props) => {
  const sessionWrapper = useMemo(() => {
    return (
      <SessionWrapper
        buttons={buttons}
        isLoading={isLoading}
        hiddenHeader={hiddenHeader}
        title={title || `Refresh: Stress, <br/>Anxiety, Depression, and Purpose`}
        titleHeight="h-[125px]"
        className={hiddenHeader ? '' : 'bg-[length:100%_179px]'}
        style={style || { backgroundImage: `url(/images/my-adresso-journey/session/04/bg.webp)` }}
        pcPannel="url(/images/my-adresso-journey/session/ss4_pc.webp)"
      >
        {children}
      </SessionWrapper>
    )
  }, [isLoading, hiddenHeader, title, children, style, buttons])

  return <>{sessionWrapper}</>
}
