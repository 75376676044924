import { useMemo, useState } from 'react'
import { useForm } from '../../../hooks/use-form'
import { useHeartAction } from '../../../hooks/use-heart-action'
import { FieldTypes, Question } from '../../../models'
import { ButtonStyles, ErrorMessage, SubmitButtonCommon } from '../../Common'
import { HeartActionField } from '../../HeartActions/Form/Field'
import { format } from 'date-fns'

type Props = {
  id: number
  className?: string
  backgroundColor?: string
  textColor?: string
  editable: boolean
  completed?: boolean
  questions: Question[]
  hideAfterSubmit: boolean
  afterSubmit: Function
  goalMemoId: any
}

export const FormQuestions = ({ id, className, editable, backgroundColor, textColor, completed, questions, afterSubmit, goalMemoId }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const formik = initUseFormik(questions, () => {
    handleSubmitRequest(formik.values)
  })
  const heartAction = useHeartAction()
  function handleSubmitRequest(answers: any) {
    setIsSubmitting(true)
    // TODO: get data from props
    const date = format(new Date(), 'yyyy-MM-dd')
    heartAction.submitAnswers(id, answers, goalMemoId, date).then((res) => {
      setIsSubmitted(true)
      afterSubmit()
    })
  }

  function handleSubmit() {
    setIsError(true)
  }

  function handleUpdateHiddenField(name: string, value: string) {
    formik.setFieldValue(name, value)
  }

  const formGoalQuestions = useMemo(() => {
    if (questions.length) {
      const classes = 'flex h-full w-full flex-1 flex-col space-y-6 ' + (className || '')
      return (
        <form className={classes} onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-4">
            {questions.map((question: any, index) => {
              return (
                <HeartActionField
                  onUpdateHiddenField={handleUpdateHiddenField}
                  key={question.name}
                  fieldType={FieldTypes.Light}
                  editable={editable}
                  question={question}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                  value={formik.values[question.name]}
                  onChange={formik.handleChange}
                >
                  {isError && formik.errors[question.name] && <ErrorMessage text={formik.errors[question.name]} />}
                </HeartActionField>
              )
            })}

            {isError && errorMsg && <ErrorMessage text={errorMsg} />}
          </div>
          {editable && !isSubmitted && !completed && (
            <div className="pt-3">
              <SubmitButtonCommon style={ButtonStyles.Dark} width="w-[160px]" height="h-[50px]" text="SAVE" isLoading={isSubmitting} onClick={handleSubmit} />
            </div>
          )}
        </form>
      )
    } else {
      return <div className="w-full">No question</div>
    }
  }, [formik, isError, errorMsg])

  return <>{formGoalQuestions}</>
}
