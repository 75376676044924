import {useCallback} from 'react'
import {getMyNow as getMyNowRequest} from "../lib/request/get-my-now";
import {postUpdateMyNow} from "../lib/request/post-update-my-now";
import {AdessoError} from "../lib/error";

export function useMyNow() {

  const getMyNow = useCallback(async () => {
    try {
      return await getMyNowRequest();
    } catch (error) {
      throw new AdessoError('Get my now Failed')
    }
  }, [])

  const updateMyNow = useCallback(async (myNow: string) => {
    try {
      return await postUpdateMyNow(myNow)
    } catch (error) {
      throw new AdessoError('Update my now Failed')
    }
  }, []);

  return {getMyNow, updateMyNow}
}
