import { useMemo } from 'react'
import { Session04Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

const postCard = `
  Hey ladies, Dr. Suzanne here. You know, every time I think about this topic lately, I think back to the height of the pandemic. I was seeing patients and I could see how sick they were. And like so many women, I knew how hard it had been on all of us. Suddenly, we were our kids' teachers, and we were working, finding no space, no time for ourselves. It was so stressful. And you know what, ladies, it was not just in your head; the impact was on your heart. We're changing all of this up. I am right here with you. I have had my own self-care routine for a long time. But I've had to check in and pay attention. And I still have to, just like I'm asking you to do now. I want to share something with you. I met with a life coach recently about a particularly stressful situation I had. And she reminded me that I was probably feeling less connected to myself, and even a little depressed, but it was normal. But just that check-in did more to get me back on track than anything I do routinely. So that's what I'm recommending for you, check-in. Let's make a shift to be sure you're taking care of yourself.
  <br/>The one shift you can make right now; put yourself in your schedule. We need to make time for ourselves and do it without feeling anxious, stressed, and like we are spinning. Let's just calm-down, and manage our lives. Try and relax our brains. This has to happen every day for us to become in charge of our lives. Maybe through this journey, you liked something that clicked with you... Was it meditation, breathing, mindfulness, gratitude? Put it into action. Click to make this a goal. And take that one-minute pause a day to journal about what you're doing to calm your brain and help manage your stress. By adding one routine to your life, you begin to experience it, feel it, and welcome its healing benefits. Do you have a watch that monitors your heart rate? Check it when you're stressed. Then do your one self-care routine and check it again. Even just breathe. And just like that. You will see how you're taking care of your heart as you watch your heart rate slow down.
  <br/>Click this goal in the next screen, apply this plan, track it, and then celebrate it. We are here with you.
`

export const ScreenHeartToHeart = ({ buttons }: Props) => {
  const screenHeartToHeart = useMemo(() => {
    return (
      <Session04Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>We’ve spent some time talking about how the heart operates physically, but now let’s talk more about how your emotional heart connects with your physical heart.</p>

          <audio controls className="w-full">
            <source src="https://api.myadesso.health/uploads/journeys/episode-04.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div className="h-full max-h-[calc(100vh-490px)] w-full flex-1 overflow-y-scroll">
            <p className=" text-[18px] leading-[22px]" dangerouslySetInnerHTML={{ __html: postCard }} />
          </div>
        </div>
      </Session04Wrapper>
    )
  }, [buttons])

  return <>{screenHeartToHeart}</>
}
