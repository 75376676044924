import React, { useCallback } from 'react'
import { useBoolean } from '../../../hooks/use-boolean'

type Props = {
  toggleName?: string
  checked?: boolean | any
  idInput?: string
  nameInput?: string
  onChange?: any
}

const Toggle = ({ idInput, nameInput, toggleName, checked, onChange }: Props) => {
  const check = useBoolean()
  const handleChangeToggle = useCallback((value: boolean) => {
    onChange(nameInput, value ?? false)
  }, [])

  return (
    <label className={`me-5 inline-flex cursor-pointer items-center gap-1`}>
      <input
        id={idInput}
        name={nameInput}
        type="checkbox"
        value={checked}
        className={`peer sr-only`}
        checked={checked ?? check.value}
        onChange={(e) => {
          check.onToggle()
          handleChangeToggle(e.target.checked)
        }}
      />
      <div
        className={`peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-color-checkbox peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-pink-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-pink-800`}
      ></div>
      <span className={`ms-3 text-[16px] font-semibold text-[#000] dark:text-gray-300`}>{toggleName}</span>
    </label>
  )
}

export default Toggle
