import { ChangeEvent, useMemo } from 'react'

import { Question } from '../../../models/question'

type Props = {
  children?: React.ReactNode
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  value?: string | string[]
  question: Question
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  inputType?: string
}

export const InputField = ({ value, disabled, question, onChange, inputType = 'text', backgroundColor, textColor }: Props) => {
  const inputField = useMemo(() => {
    const isTextarea = inputType === 'textarea'
    return isTextarea ? (
      <textarea
        disabled={disabled}
        name={question.name}
        value={value}
        rows={9}
        className="textarea w-full leading-4 font-normal rounded-none border-none border-transparent bg-color-pink-textarea text-ma-md placeholder:text-ma-gray-900/50 focus:border-transparent focus:outline-0 disabled:bg-color-pink-textarea"
        style={{
          backgroundColor: backgroundColor ?? 'bg-color-pink-textarea',
        }}
        placeholder={question.placeholder}
        onChange={onChange}
      ></textarea>
    ) : (
      <input
        disabled={disabled}
        type={inputType}
        name={question.name}
        onChange={onChange}
        value={value}
        className="input h-[46px] w-full rounded-none border-none border-transparent bg-color-pink-textarea text-ma-md placeholder:text-ma-gray-900/50 focus:border-transparent focus:outline-0 disabled:bg-color-pink-textarea"
        placeholder={question.placeholder}
      />
    )
  }, [value, question])

  return <>{inputField}</>
}
