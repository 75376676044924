import { useEffect, useMemo, useState } from 'react'
import { HeartJournalWrapper } from '../HeartJournal/Wrapper'
import { HeartChartSection } from './Section'
import { useNavigate } from 'react-router-dom'
import { getHeartChartResult } from '../../lib/request'
import { useHeartScoreResultPage } from '../../hooks/pages/heart-score-result'

export const HeartChartResultContent = () => {
  const [totalHeatChartResult, setTotalHeartChartResult] = useState(0)
  const [riskLevel, setRiskLevel] = useState('')
  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()

  const [answers, setAnswers] = useState([])
  const navigate = useNavigate()

  const getResult = async () => {
    const res = await getHeartChartResult()

    setAnswers(res.data.answers)
    setTotalHeartChartResult(res.data.totalScore)
    setRiskLevel(res?.data?.risk_level)
  }

  useEffect(() => {
    getResult()
  }, [])

  useEffect(() => {
    if (resultHeartScore?.data) return
    getResultHeartScore()
  }, [resultHeartScore?.data])

  const handleTest = () => {
    navigate('/heart-chart/testing')
  }

  const totalChartResult = useMemo(() => {
    const dataResultHeartScore = resultHeartScore?.data?.results

    return (
      <HeartChartSection className="space-y-4 pt-10" title="Adesso Heart Score">
        <div className="flex justify-between">
          <div
            className={`border-color-${dataResultHeartScore?.totalStatus}-risk text-color-${dataResultHeartScore?.totalStatus}-risk mr-4 flex h-[130px] w-[130px] items-center justify-center rounded-full border-[10px] font-['Lato'] text-[44px] leading-none`}
          >
            {totalHeatChartResult}
          </div>
          <div className="w-[calc(100%-146px)]">
            <h3 className={`text-[24px] text-color-${dataResultHeartScore?.totalStatus}-risk`}>{riskLevel} Risk</h3>
            <p className="pt-1 pb-4 text-[13px] leading-[16px]">This score is calculated from your responses to your most recent Adesso Heart Score Questionaire</p>
            <p className="text-[13px] leading-[16px] text-color-primary underline">Take The Heart Score Again</p>
          </div>
        </div>
      </HeartChartSection>
    )
  }, [resultHeartScore, riskLevel, totalHeatChartResult])

  return (
    <HeartJournalWrapper className="mx-auto max-w-[700px]">
      <h3 className="font-['Butler'] text-[24px] leading-[1.41667]">Your Heart Chart</h3>
      {totalChartResult}
      <h4 className="mt-8 text-[15px] font-semibold uppercase leading-[21px]">Your Tests</h4>
      <div className="flex items-center pt-5 pb-1">
        <div className="flex-1 grow text-[12px] font-semibold leading-[22px] text-color-overlay"></div>
        <div className={`w-20 grow text-right text-[12px] font-semibold leading-[15px] text-[#DA3D7F] underline`}>This Month</div>
      </div>

      {answers &&
        answers.map((detail: any, index) => {
          return (
            <div key={`resultDetail-${index}`} className="flex items-center border-t border-color-gray py-3">
              <div className="flex-1 grow text-[12px] font-semibold leading-[22px] text-color-overlay">{detail.title}</div>
              <div className={`w-20 grow text-right text-[12px] font-semibold leading-[15px]`}>{detail.answer}</div>
            </div>
          )
        })}

      <HeartChartSection className="space-y-4 pt-8" title="">
        <div className="w-80 rounded-[15px] bg-[#EB2781] p-2 md:mx-auto" onClick={handleTest}>
          <div className="bg-[url('assets/images/icon/i-lab.webp')] bg-[length:auto_49px] bg-[center_left] bg-no-repeat py-2 pl-14 text-[15px] font-semibold uppercase leading-[17px] tracking-[0.15em] text-white">
            Update Your Testing and Lab Numbers
          </div>
        </div>
      </HeartChartSection>
    </HeartJournalWrapper>
  )
}
