import { HeartChartLine } from '../../models/heart-chart-line'

interface NumericDictionary {
  [index: number]: string
}

const getIndex = (item: any, type?: string): number => {
  if (type === 'month') {
    return parseInt(item.date) - 1
  } else if (type === '6-months') {
    return parseInt(item.month) - 1
  }
  return parseInt(item.date) === 7 ? 0 : parseInt(item.date)
}

export function mapDataChartLineBloodPressure(data: any[], type?: string): HeartChartLine[] {
  const arraySize = type ? (type === 'month' ? 31 : 6) : 7

  let results: HeartChartLine[] = []
  let systolicIndex: number | undefined
  let diastolicIndex: number | undefined
  let pulseIndex: number | undefined

  if (data.some((item) => item.sys != null)) {
    systolicIndex = results.push({ name: 'Systolic Pressure', values: Array(arraySize).fill(null), color: '#FF3399' }) - 1
  }

  if (data.some((item) => item.dia != null)) {
    diastolicIndex = results.push({ name: 'Diastolic Pressure', values: Array(arraySize).fill(null), color: '#974A9A' }) - 1
  }

  if (data.some((item) => item.pul != null)) {
    pulseIndex = results.push({ name: 'Pulse Pressure', values: Array(arraySize).fill(null), color: '#F26E57' }) - 1
  }

  data.forEach((item) => {
    const index = getIndex(item, type)
    if (item.sys != null && systolicIndex !== undefined) {
      results[systolicIndex].values[index] = item.sys
    }
    if (item.dia != null && diastolicIndex !== undefined) {
      results[diastolicIndex].values[index] = item.dia
    }
    if (item.pul != null && pulseIndex !== undefined) {
      results[pulseIndex].values[index] = item.pul
    }
  })
  return results
}

export function mapDataChartLineWeight(data: any[], type?: string): HeartChartLine[] {
  const arraySize = type === 'month' ? 31 : type === '6-months' ? 6 : 7

  let results: HeartChartLine[] = []
  let weightSeriesIndex: number | undefined
  let targetWeightSeriesIndex: number | undefined

  if (data.some((item) => item.weight != null)) {
    weightSeriesIndex = results.push({ name: 'Your Weight', values: Array(arraySize).fill(null), color: '#F26E57' }) - 1
  }

  if (data.some((item) => item.targetWeight != null)) {
    targetWeightSeriesIndex = results.push({ name: 'Your Target Weight', values: Array(arraySize).fill(null), color: '#974A9A' }) - 1
  }

  data.forEach((item) => {
    const index = getIndex(item, type)
    if (item.weight != null && weightSeriesIndex !== undefined) {
      results[weightSeriesIndex].values[index] = item.weight
    }
    if (item.targetWeight != null && targetWeightSeriesIndex !== undefined) {
      results[targetWeightSeriesIndex].values[index] = item.targetWeight
    }
  })

  return results
}

export const convertTypeBloodPressure: NumericDictionary = {
  1: 'month',
  2: '6-months',
}

export const convertTypeButton: NumericDictionary = {
  0: 'week',
  1: 'month',
  2: 'sixMonths',
}
