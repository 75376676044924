import { useEffect, useMemo, useState } from 'react'
import { format, addDays, startOfDay, isToday, getDaysInMonth, parse } from 'date-fns'
import './styles.css'
import WheelPicker from './WheelPicker'
import { useContextMedicationRx } from '../../../context/medicationRxContext'
import { useContextSupplementRx } from '../../../context/supplementRxContext'

interface Item {
  value: string
  label: string
}

const hourItems: Item[] = Array.from({ length: 12 }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString(),
}))

const minuteItems: Item[] = Array.from({ length: 60 }, (_, index) => ({
  value: `${(index * 1).toString().padStart(2, '0')}`,
  label: `${(index * 1).toString().padStart(2, '0')}`,
}))

const ampmItems: Item[] = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
]

const currentDate = new Date()
const currentDaysInMonth = getDaysInMonth(currentDate)
const dateItems: Item[] = Array.from({ length: currentDaysInMonth * 2 }, (_, i) => {
  const date = addDays(startOfDay(currentDate), -currentDaysInMonth + i)
  return {
    value: format(date, 'yyyy-MM-dd'),
    label: isToday(date) ? 'Today' : format(date, 'EEE dd MMM'),
  }
})

interface IIOSPickerProps {
  setAlarm?: any
  dataSetAlarm?: string
}

export default function IOSPicker({ dataSetAlarm, setAlarm }: IIOSPickerProps) {
  const [date, setDate] = useState<string>(dateItems[currentDaysInMonth].value)
  const [hour, setHour] = useState<string>(hourItems[0].value)
  const [minute, setMinute] = useState<string>(minuteItems[0].value)
  const [ampm, setAmpm] = useState<string>(ampmItems[0].value)
  const [formattedTime, setFormattedTime] = useState<string>('')

  useEffect(() => {
    if (formattedTime) {
      const [hourCustom, minuteCustom, ampmCustom] = formattedTime.split(':')
      // const timeObject = {
      //   hour: hourCustom,
      //   minute: minuteCustom,
      //   ampm: ampmCustom,
      // }
      if (hourCustom) {
        setHour(hourCustom)
      }
      if (minuteCustom) {
        setMinute(minuteCustom)
      }
      if (ampmCustom) {
        setAmpm(ampmCustom)
      }
    }
  }, [formattedTime])

  useEffect(() => {
    if (dataSetAlarm) {
      const alarmSelectFormat = parse(dataSetAlarm, 'HH:mm:ss', new Date())
      setFormattedTime(format(alarmSelectFormat, 'h:mm:aa'))
    }
  }, [dataSetAlarm])

  useEffect(() => {
    const alarm = `${hour}:${minute} ${ampm}`
    const parsedTime = parse(alarm, 'h:mm aa', new Date())
    const alarmFormat = format(parsedTime, 'HH:mm')

    if (alarmFormat) {
      setAlarm(alarmFormat)
    }
  }, [date, hour, minute, ampm])

  const wheelPick = useMemo(() => {
    return (
      <div className="App">
        {/* <span style={{ textAlign: 'center', width: '100%' }}>
          {date} {hour}:{minute} {ampm}
        </span> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WheelPicker
            alarmAt={formattedTime}
            dateItems={dateItems}
            dateValue={date}
            onDateChange={setDate}
            hourItems={hourItems}
            hourValue={hour}
            onHourChange={setHour}
            minuteItems={minuteItems}
            minuteValue={minute}
            onMinuteChange={setMinute}
            ampmItems={ampmItems}
            ampmValue={ampm}
            onAmpmChange={setAmpm}
          />
        </div>
      </div>
    )
  }, [hour, minute, ampm, formattedTime])

  return wheelPick
}
