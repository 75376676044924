import { useMemo } from 'react'
import { Session03Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session03Wrapper buttons={buttons}>
        <div className="space-y-6">
          <h4 className="text-[18px] font-bold leading-[22px]">Welcome!</h4>
          <p>
            In this session we’re going to talk about what it means to nourish ourselves. This takes a broader mindset than just “eating or dieting.” And it extends to all areas of our lives.
            <br />
            <br />
            Watch the video below to begin.
          </p>

          <VimeoEmbed embedId="808487745" />
        </div>
      </Session03Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
