import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'

import { GroupData, stressTracking } from '../../models/heartChart'
import { mapDataChartPie } from '../../lib/maps/heartChart'
import { getHeartChartPie, getHeartChartRateGoal, getHeartChartStressTracking } from '../../lib/request/get-heartChart'
import { rateGoal } from '../../models/heart-chart-rateGoal'
import { mapDataChartRateGoal } from '../../lib/maps/heart-chart-rateGoal'
import { mapDataChartStressTracking } from '../../lib/maps/heart-chart-stressTracking'

type Result = {
  success: boolean
}

export function useHeartChartPage() {
  const [getDataPieResult, setGetDataPieResult] = useState<AdessoResult<GroupData[]>>()
  const [dataRateGoalResult, setDataRateGoalResult] = useState<AdessoResult<rateGoal[]>>()
  const [dataStressTrackingResult, setDataStressTrackingResult] = useState<AdessoResult<stressTracking>>()

  const auth = useAuth()

  const getDataHeartChartPie = useCallback(async () => {
    setGetDataPieResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartPie()
        if (res.data) {
          const dataChartPie = mapDataChartPie(res.data)
          setGetDataPieResult({
            isLoading: false,
            data: dataChartPie,
            error: undefined,
          })
        } else {
          setGetDataPieResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setGetDataPieResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart pie Error'),
      })
    }
  }, [])

  const getDataHeartChartRateGoal = useCallback(async () => {
    setDataRateGoalResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartRateGoal()
        if (res.data) {
          const dataChartRateGoal = mapDataChartRateGoal(res.data)
          setDataRateGoalResult({
            isLoading: false,
            data: dataChartRateGoal,
            error: undefined,
          })
        } else {
          setDataRateGoalResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataRateGoalResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart rate goal Error'),
      })
    }
  }, [])

  const getDataHeartChartStressTracking = useCallback(async () => {
    setDataStressTrackingResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartStressTracking()
        if (res.data) {
          const dataChartStressTracking = mapDataChartStressTracking(res.data)
          setDataStressTrackingResult({
            isLoading: false,
            data: { data: dataChartStressTracking },
            error: undefined,
          })
        } else {
          setDataStressTrackingResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataStressTrackingResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart rate goal Error'),
      })
    }
  }, [])

  return { getDataPieResult, getDataHeartChartPie, dataRateGoalResult, getDataHeartChartRateGoal, dataStressTrackingResult, getDataHeartChartStressTracking }
}
