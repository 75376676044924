import { useMemo } from 'react'
import { Session01Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenCoreConcepts01 = ({ buttons }: Props) => {
  const screenCoreConcepts = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            Seeing your Heart Score probably stirred up some emotions. Your heart is beating for you every day, but you only think about it if something feels off. So our Heart Score makes us connect
            directly to those precious, lovely heart beats.
            <br />
            <br />
            If your Heart Score was low, you were probably pretty happy. So you’ll be learning more about how to keep it that way.
            <br />
            <br />
            But if your Heart Score was moderate or high, it might have caused your heart to feel like it skipped a beat! It can be scary.
            <br />
            <br />
            But don’t worry, 80 percent of all heart disease is preventable and you’re here… you have your Now! And you’re doing the work to ensure your score goes down and your health and vitality
            go up!
            <br />
            <br />
            So let’s take a deeper look at your score and the risk factors that need your attention. You want to think about how these factors impact your heart. You’ll also learn basic steps you can
            take right now.
          </p>
        </div>
      </Session01Wrapper>
    )
  }, [buttons])

  return <>{screenCoreConcepts}</>
}
