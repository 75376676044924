import { useMemo, ChangeEvent } from 'react'

import { Question, FieldTypes, QuestionTypes } from '../../../models'

type Props = {
  value?: string | string[]
  fieldType: FieldTypes
  question: Question
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxField = ({ value, question, onChange }: Props) => {
  const isMulti = question.type === QuestionTypes.CheckboxMulti

  const isColorSubscription = question.name === 'text_frequency' || question.name === 'email_frequency'

  const options = useMemo(() => {
    return question.options
      ? question.options.map((option, index) => {
          const idCheckbox = `${question.name}-${index}`
          const isChecked = !!value && (value === option.label || value?.includes(option.label))
          return (
            <div key={`checkbox-${index}`}>
              <input id={idCheckbox} type="checkbox" name={question.name} className="peer hidden" value={option.label} onChange={onChange} checked={isChecked} />

              <label
                htmlFor={idCheckbox}
                className="block cursor-pointer bg-[url('assets/images/icon/i-checkbox__inactive.svg')] bg-[length:20px_auto] bg-[top_left] bg-no-repeat pl-7 pt-1 text-[16px] leading-[19px] peer-checked:bg-[url('assets/images/icon/i-checkbox__active.svg')]"
              >
                <span className={`${isColorSubscription ? 'font-semibold text-ma-pink-300' : isMulti ? '' : 'font-semibold text-ma-pink-300'} `} dangerouslySetInnerHTML={{ __html: option.label }} />
              </label>
            </div>
          )
        })
      : null
  }, [value, question, isMulti])

  return <div className="space-y-3">{options}</div>
}
