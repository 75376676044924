import { useState, useMemo, useCallback } from 'react'
import { Session10Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion10Questions } from '../../../../../lib/data/session-10'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHRQuestions = [
  {
    ...sesstion10Questions[9],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">No Progress</span>
        <span className="w-[40%] text-center">Neutral</span>
        <span className="w-[30%] text-right">Major Progress</span>
      </div>
    ),
  },
  {
    ...sesstion10Questions[10],
    after: (
      <div className="flex w-full justify-between text-[12px] font-bold leading-none">
        <span className="w-[30%] text-left">Unlikely</span>
        <span className="w-[40%] text-center">Neutral</span>
        <span className="w-[30%] text-right">Very Likely</span>
      </div>
    ),
  },
  sesstion10Questions[11],
]

type Props = {
  isLoading?: boolean
  showModal: () => void
  backScreen: () => void
  nextScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenFeedback = ({ showModal, backScreen, nextScreen, onUpdate, isLoading, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHRQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
    nextScreen()
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons isLoading={isLoading} styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [isLoading, backScreen, checkSubmit, showModal])

  const screenHeartReflection = useMemo(() => {
    return (
      <Session10Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6">
            <h4 className="text-[18px] font-bold leading-[23px]">Share Your Feedback!</h4>
            {ssHRQuestions.map((item) => {
              return (
                <FormField
                  after={item.after || undefined}
                  key={`ss10_${item.name}`}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session10Wrapper>
    )
  }, [formik, buttons, isError])

  return <>{screenHeartReflection}</>
}
