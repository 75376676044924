import React, { useCallback, useMemo, useState, useEffect, lazy } from 'react'
import { IconLoading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { getRegisterInfo } from '../../lib/request/get-register-info'
import { Screen1 } from './Screen1'
import { Screen2 } from './Screen2'
import { Screen4 } from './Screen4'

const Screen3 = lazy(() => import('./Screen3').then((module) => ({ default: module.Screen3 })))

const Loading = () => {
  return (
    <div className="mt-32 flex justify-center">
      <IconLoading size={LoadingSize.Normal} />
    </div>
  )
}

type Props = {}

export const RegisterContent = ({}: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [screen, setScreen] = useState(-1)

  const getRegister = async () => {
    const res = await getRegisterInfo()

    React.startTransition(() => {
      if (res.data.status === 'success') {
        setScreen(res.data.screen)
      } else {
        setScreen(0)
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getRegister()
  }, [])

  const nextScreen = useCallback((screen: any) => {
    React.startTransition(() => {
      setScreen(screen + 1)
    })
  }, [])

  const lastScreen = useCallback(() => {
    React.startTransition(() => {
      setScreen(3)
    })
  }, [])

  const registerContent = useMemo(() => {
    if (isLoading) {
      return <Loading />
    } else {
      switch (screen) {
        case 0:
          return <Screen1 nextScreen={() => nextScreen(screen)} />
        case 1:
          return <Screen2 nextScreen={() => nextScreen(screen)} lastScreen={() => lastScreen()} />
        case 2:
          return (
            <React.Suspense fallback={<Loading />}>
              <Screen3 />
            </React.Suspense>
          )
        default:
          return <Screen4 />
      }
    }
  }, [screen, isLoading])

  return <>{registerContent}</>
}
