import { useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

const postCard = `
Hey everyone, Dr. Suzanne, again. You've made it through another session. Since we're talking about movement, let's jump up and celebrate a bit. 
That feels good, doesn't it? You know, when my son was young, and I was balancing the many duties of parenting and work, 
I'd get creative at night about squeezing more movement in. Sometimes honestly, I'd use my child as a weight. 
One thing that he loved so much that we would do all the time, is I would turn the music on and get him to dance with me. 
We had our own little dance parties. Nowadays, I still love to dance. I take a class online that combines dance steps with weights, and I love that I'm still getting to dance. 
This is my "me" time. I want you to make a shift with me. That all starts with how you think. We've been learning about the amazing benefits of exercise and movement, but it starts with the simple act of thinking about this all differently. 
Starting right now, think about how you can move in joyful, active, and fun ways. Remember, when we were young, we were just happy and skipping around. We were jumping, moving, running, and dancing and just doing what we wanted to do for the fun of it. 
That's how we were born to be. So what can you do right now? All I'm saying is move girl! Just move throughout the day, and add an activity to get your joy flowing. Find something you like, and schedule it. This is the protected time for you. Make this the one time and the day that you own. 
This is your feel-good time. Don't let anything get in the way. And you know what? You don't have to start big. Instead, start with what's easy, and what brings you joy. 
Exercise doesn't mean you have to be miserable. It means you are doing something that you love and look forward to while seeing its amazing benefits with each passing week. 
If you wait, by week six, you won't let anything get in your way or stop you. Your body will start craving it. When you are going to start? You know, Adesso, now, now, now. So what are you waiting for? Ready, set go
`

export const ScreenHeartToHeart = ({ buttons }: Props) => {
  const screenHeartToHeart = useMemo(() => {
    return (
      <Session02Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>Let’s dive deeper into how movement can be a game changer in our lives, body, and heart.</p>

          <audio controls className="w-full">
            <source src="https://api.myadesso.health/uploads/journeys/episode-02.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div className="h-full max-h-[calc(100vh-490px)] w-full flex-1 overflow-y-scroll">
            <p className=" text-[18px] leading-[22px]" dangerouslySetInnerHTML={{ __html: postCard }} />
          </div>
        </div>
      </Session02Wrapper>
    )
  }, [buttons])

  return <>{screenHeartToHeart}</>
}
