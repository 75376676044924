export const DATA_MY_TUTORIALS = [
  {
    id: 1,
    title: 'Dashboard',
    numberOfMinutes: 3,
    link: '/my-tutorials/dashboard',
  },
  {
    id: 2,
    title: 'Daily Tracking',
    numberOfMinutes: 2,
    link: '/my-tutorials/goal-and-daily-tracking',
  },
  {
    id: 3,
    title: 'Learning Journeys',
    numberOfMinutes: 3,
    link: '/my-tutorials/learning-journey',
  },
  {
    id: 4,
    title: 'Heart Chart',
    numberOfMinutes: 3,
    link: '/my-tutorials/heart-chart',
  },
  {
    id: 5,
    title: 'My Health',
    numberOfMinutes: 2,
    link: '/my-tutorials/my-health',
  },
]
