import { useCallback, useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, TitlePage, SubmitButtonCommon, ButtonStyles, ErrorMessage } from '../Common'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSearchParams } from 'react-router-dom'
import { postChangePassword } from '../../lib/request/post-change-password'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { useBoolean } from '../../hooks/use-boolean'
import { CLOSE_EYE_ICON, OPEN_EYE_ICON } from '../../constants'

type Props = {}

export const ForgotPasswordActionContent = ({}: Props) => {
  const navigate = useNavigate()
  const { setItemLS } = useLocalStorage()
  const [isCheckError, setIsCheckError] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const passwordEye = useBoolean()
  const cpasswordEye = useBoolean()

  useEffect(() => {
    const token = searchParams.get('token')

    if (!!!token) {
      navigate('/')
    }
  }, [])

  const defaultData = {
    password: '',
    confirm_password: '',
    token: searchParams.get('token') ?? '',
  }

  const formik = useFormik({
    initialValues: defaultData,
    validationSchema: Yup.object({
      password: Yup.string().required('Field is required'),
      confirm_password: Yup.string()
        .required('Field is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      token: Yup.string(),
    }),
    onSubmit: async (values, { setErrors }) => {
      const res = await postChangePassword(values)

      if (res.status) {
        setItemLS('redirect-from', 'change-password')
        navigate('/forgot-password-success')
      } else {
        setErrors({ ...res.errorMessage })
      }
    },
  })

  const ForgotPasswordContent = useMemo(() => {
    const iconEyePassword = passwordEye.value ? `${OPEN_EYE_ICON}` : `${CLOSE_EYE_ICON}`
    const iconEyeCPassword = cpasswordEye.value ? `${OPEN_EYE_ICON}` : `${CLOSE_EYE_ICON}`

    return (
      <Wrapper className="relative min-h-[calc(100vh-74px)] py-10">
        <TitlePage titleColor="text-color-text-primary" subTitleColor="text-color-overlay" title="Change your password" subTitle="Please enter the new password" />

        <form className="w-full text-black" onSubmit={formik.handleSubmit}>
          <div className="mt-12">
            <div className="relative mb-2">
              <input
                className="w-full border border-slate-400 px-4 py-2"
                id="password"
                type={passwordEye.value ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <div className={iconEyePassword} onClick={passwordEye.onToggle} />
            </div>
            {isCheckError && formik.errors.password && <ErrorMessage text={formik.errors.password} />}
          </div>

          <div className="mt-2">
            <div className="relative mb-2">
              <input
                className="w-full border border-slate-400 px-4 py-2"
                id="confirm-password"
                type={cpasswordEye.value ? 'text' : 'password'}
                name="confirm_password"
                placeholder="Confirm Password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
              />
              <div className={iconEyeCPassword} onClick={cpasswordEye.onToggle} />
            </div>
            {isCheckError && formik.errors.confirm_password && <ErrorMessage text={formik.errors.confirm_password} />}
          </div>

          <SubmitButtonCommon
            isLoading={false}
            disabled={formik.values.password.length === 0}
            width="w-full"
            height="h-[53px]"
            text="submit"
            style={ButtonStyles.Dark}
            className="mt-12"
            onClick={() => {
              setIsCheckError(true)
              formik.handleSubmit
            }}
          />
        </form>
        <div className="mt-20 text-center">
          <a className="font-bold text-[#ED2580] underline hover:text-[#ED2580]" href="/login">
            Back to login
          </a>
        </div>
      </Wrapper>
    )
  }, [formik])

  return <>{ForgotPasswordContent}</>
}
