import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
}

export async function getHeartScoreResult(): Promise<Response> {
  const { data, status } = await axios.get('/heart-score-result')

  if (status !== 200) {
    console.info('getHeartScoreQuestions', data)
    throw new AdessoError(`Request getHeartScoreQuestions error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
