import axios from 'axios'
import { AdessoError } from '../error'
import { HeartJournalMininum } from '../../models/heart-journal'

type GetHeartJournalResponse = {
  data?: HeartJournalMininum[]
  message?: string
  success?: boolean
}

export async function getMyHeartJournals(): Promise<GetHeartJournalResponse> {
  const { data, status } = await axios.get('/goals/memo') || {}

  if (status !== 200) {
    throw new AdessoError(`Request getMyHeartJournals error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: GetHeartJournalResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: true,
      }
    : {
        message: data.message,
        success: false,
      }

  return dataReturn
}

export async function getCalendarHistory(startDate: string, endDate: string): Promise<GetHeartJournalResponse> {
  const params = {
    start_date: startDate,
    end_date: endDate,
  }
  const { data, status } = await axios.get('/heart-journals/calendar', {
    params,
  } as any) || {}

  if (status !== 200) {
    throw new AdessoError(`Request getMyHeartJournals error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: GetHeartJournalResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: true,
      }
    : {
        message: data.message,
        success: false,
      }

  return dataReturn
}

export async function getHJHistoryAnswer(date: string, goalId: number): Promise<GetHeartJournalResponse> {
  const params = {
    date: date,
    goal_id: goalId,
  }
  const { data, status } = await axios.get('/heart-journals/history', {
    params,
  } as any) || {}

  if (status !== 200) {
    throw new AdessoError(`Request getMyHeartJournals error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: GetHeartJournalResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: true,
      }
    : {
        message: data.message,
        success: false,
      }

  return dataReturn
}
