import { useMemo, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonCommon, ButtonStyles, BeatPopup } from '../../../Common'
import { SessionWrapper } from './SessionWrapper'
import { postFinishSessions } from '../../../../lib/request'
import { useGlobalModalContext, MODAL_TYPES } from '../../../../hooks/modals/global-modal'
import { trackAmplitude } from '../../../../lib/utils/helper'

type Props = {
  isNextLevel?: boolean
  journeyId?: any
  showModal?: any
  textContent?: any
  nextScreen?: () => void
}

export const LayoutBeatsReviced = ({ isNextLevel, journeyId, showModal, textContent, nextScreen }: Props) => {
  const { slug } = useParams()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showPopup5, setShowPopup5] = useState<boolean>(false)
  const [showPopup10, setShowPopup10] = useState<boolean>(false)
  const { showGlobalModal } = useGlobalModalContext()
  const [levelUp, setLevelUp] = useState(null)

  const navigate = useNavigate()

  const postFinish = async () => {
    const res = await postFinishSessions({ journal_id: journeyId })

    if (res.data?.status == 'success') {
      showGlobalModal(MODAL_TYPES.ADD_BEAT, res.data)
      trackAmplitude(`sessions_completed`, { value: journeyId })
    }
  }

  const showSpecialAddPoint = () => {
    setShowPopup(false)

    // todo show more for special step
    switch (journeyId) {
      case 5:
        setShowPopup5(true)
        break
      case 10:
        setShowPopup10(true)
        break
      default:
        if (levelUp !== null) {
          showGlobalModal(MODAL_TYPES.LEVEL_UP, levelUp)
        }
    }
  }

  const showLevelUp = () => {
    setShowPopup5(false)
    setShowPopup10(false)

    if (levelUp !== null) {
      showGlobalModal(MODAL_TYPES.LEVEL_UP, levelUp)
    }
  }

  useEffect(() => {
    postFinish()
  }, [])

  const boxButton = useMemo(() => {
    const gotoNextSession = () => {
      if (journeyId == 10 && nextScreen) {
        // window.location.href = '/my-adesso-journey'
        nextScreen()
      } else {
        const nextSession = ('00' + (journeyId + 1)).slice(-2)

        window.location.href = '/my-adesso-journey/session-' + nextSession
      }
    }

    return (
      <div className="flex flex-col justify-center space-y-4 pt-11">
        {journeyId !== 10 && (
          <ButtonCommon style={ButtonStyles.Light} width="w-[250px]" height="h-[50px]" onClick={() => navigate(`/my-adesso-journey/go-deeper/${slug}`)}>
            <span className="text-ma-pink">GO DEEPER LIBRARY</span>
          </ButtonCommon>
        )}

        <ButtonCommon style={ButtonStyles.Light} width="w-[250px]" height="h-[50px]" onClick={gotoNextSession}>
          <span className="text-ma-pink">{journeyId != 10 ? 'NEXT SESSION' : 'CONTINUE THE JOURNEY'}</span>
        </ButtonCommon>
        {journeyId !== 10 && (
          <ButtonCommon style={ButtonStyles.Light} width="w-[250px]" height="h-[50px]" onClick={() => navigate('/dashboard')}>
            <span className="text-ma-pink">
              RETURN TO
              <br />
              DASHBOARD
            </span>
          </ButtonCommon>
        )}

        {showModal && (
          <ButtonCommon style={ButtonStyles.Dark} width="w-[250px]" height="h-[50px]" onClick={showModal}>
            <div className="h-full w-full bg-[url('assets/images/icon/i-menu.svg')] bg-[length:26px_auto] bg-center bg-no-repeat" />
          </ButtonCommon>
        )}
      </div>
    )
  }, [])

  const screenBeatsReviced = useMemo(() => {
    return (
      <>
        <SessionWrapper
          bgExpand={true}
          hiddenHeader={true}
          className="mt-8"
          style={{ backgroundImage: isNextLevel ? `url(/backgrounds/bg-maj__reviced_screen-02.webp)` : `url(/backgrounds/bg-maj__reviced_screen-01.webp)` }}
        >
          {textContent}
          {boxButton}
        </SessionWrapper>
        {showPopup && (
          <BeatPopup className="bg-[#FFD7E8] shadow-[4px_4px_16px_rgb(0,0,0)]">
            <div className="absolute top-4 right-4 h-6 w-6 bg-[url('/images/white_close.webp')] bg-cover bg-center bg-no-repeat" onClick={showSpecialAddPoint}></div>
            <div className="text-large flex  items-center font-bold text-[#F26E57]">
              <div className="mr-3 inline-block h-14 w-20 bg-[url('assets/images/icon/i-heart.svg')] bg-center bg-no-repeat text-center text-[20px] font-medium leading-none text-white">
                <p className="relative top-1/2 -translate-y-1/2">+10</p>
              </div>
              BEATS
            </div>
            <h1 className="mt-4 text-center text-[#F26C59]">Congratulations on finishing this session! Complete more sessions, log actions, and explore Go Deepers to earn more beats. </h1>
            <div className="absolute -bottom-12 left-1/2 h-14 w-12 -translate-x-1/2 border-x-[24px] border-t-[32px] border-x-[transparent] border-t-[#FFD7E8]"></div>
          </BeatPopup>
        )}
        {showPopup5 && (
          <BeatPopup className="bg-[#FFD7E8] shadow-[4px_4px_16px_rgb(0,0,0)]">
            <div className="absolute top-4 right-4 h-6 w-6 bg-[url('/images/white_close.webp')] bg-cover bg-center bg-no-repeat" onClick={showLevelUp}></div>
            <div className="text-large flex  items-center font-bold text-[#EF2E7D]">
              <div className="mr-3 inline-block h-14 w-20 bg-[url('assets/images/icon/i-heart.svg')] bg-center bg-no-repeat text-center text-[20px] font-medium leading-none text-white">
                <p className="relative top-1/2 -translate-y-1/2">+25</p>
              </div>
              BEATS
            </div>
            <h1 className="mt-4 text-center text-[#F26C59]">
              Congratulations! You’ve finished the first half of the Adesso journey. With this foundation for heart health in place, you are ready to learn more about heart disease prevention.
            </h1>
            <div className="absolute -bottom-12 left-1/2 h-14 w-12 -translate-x-1/2 border-x-[24px] border-t-[32px] border-x-[transparent] border-t-[#FFD7E8]"></div>
          </BeatPopup>
        )}
        {showPopup10 && (
          <BeatPopup className="bg-[#FFD7E8] shadow-[4px_4px_16px_rgb(0,0,0)]">
            <div className="absolute top-4 right-4 h-6 w-6 bg-[url('/images/white_close.webp')] bg-cover bg-center bg-no-repeat" onClick={showLevelUp}></div>
            <div className="text-large flex  items-center font-bold text-[#974A9A]">
              <div className="mr-3 inline-block h-14 w-20 bg-[url('assets/images/icon/i-heart.svg')] bg-center bg-no-repeat text-center text-[20px] font-medium leading-none text-white">
                <p className="relative top-1/2 -translate-y-1/2">+10</p>
              </div>
              BEATS
            </div>
            <h1 className="mt-4 text-center text-[#F26C59]">Congratulations on finishing your Adesso journey!</h1>
            <div className="absolute -bottom-12 left-1/2 h-14 w-12 -translate-x-1/2 border-x-[24px] border-t-[32px] border-x-[transparent] border-t-[#FFD7E8]"></div>
          </BeatPopup>
        )}
      </>
    )
  }, [isNextLevel, textContent, showPopup, showPopup5, showPopup10])

  return <>{screenBeatsReviced}</>
}
