import { useState, useCallback, useMemo } from 'react'
import { Session07Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion07Questions } from '../../../../../lib/data/session-07'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion07Questions[4], sesstion07Questions[5], sesstion07Questions[6], sesstion07Questions[7]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment01 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const after1 = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">Hate it!</span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">No Problem.</span>
    </div>
  )

  const after2 = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">Could be better</span>
      <span className="w-[33%] text-center">Neutral</span>
      <span className="w-[33%] text-right">Great</span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session07Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            <h4>You and your primary care provider.</h4>
            <p>The first step toward a truly preventative approach to health means getting a wellness check. Let's take a moment to reflect on the relationship you and your medical person have right now. Is it close? distant? Non-existant?!! Don't despair, but let's get real for a moment.</p>
          </div>

          <div className="space-y-10">
            {ssHAQuestions.map((item, index) => {
              const after = index === 1 ? after1 : after2
              return (
                <FormField
                  key={`ss05_${item.name}`}
                  after={after}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session07Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
