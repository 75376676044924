import { useEffect, useMemo, useState } from 'react'
import { useLocales } from '../../../locales'

import { getHeartChartHistory, getHeartChartResult } from '../../../lib/request'

import WrapperMyHealth from '../MyHealthWrapper'
import { addUnitToAnswers } from './Results'
import { RiskLegend } from './RiskLegend'
import { IconLoading } from '../../Common'
import { LoadingSize } from '../../../models/loading'

type Props = {
  prevScreen: () => void
}

const ListHeartScore = ({ prevScreen }: Props) => {
  const { t } = useLocales()

  const [historyResult, setHistoryResult] = useState<any[]>([])
  const [expanded, setExpanded] = useState<boolean[]>()
  const [answer, setAnswer] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [loadingDate, setLoadingDate] = useState<string | null>(null)

  useEffect(() => {
    const getHistory = async () => {
      setIsLoading(true)
      const historyTestResult = await getHeartChartHistory()
      setHistoryResult(historyTestResult.data)
      setExpanded(new Array(historyTestResult.data.length).fill(false))
      setIsLoading(false)
    }
    getHistory()
  }, [])

  const handleChange = async (index: number, date: string) => {
    const updateState = (prev: boolean[] | undefined): boolean[] => {
      if (!prev) return []
      const clar = prev.map((item, i) => (i === index ? item : false))
      clar[index] = !clar[index]
      return clar
    }

    setExpanded((prev) => updateState(prev))
    if (!answer[date]) {
      setLoadingDate(date)
      const result = await getHeartChartResult({ date: date })
      setAnswer((prev: any) => ({
        ...prev,
        [date]: addUnitToAnswers(result.data.answers),
      }))
      setLoadingDate(null)
    }
  }

  const listHeartScore = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Small} />
        </div>
      )
    }
    return (
      <div className="space-y-4 pb-2">
        {historyResult?.map((item, index) => {
          let dateObj = new Date(item.date)
          let formattedDate = dateObj.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          return (
            expanded && (
              <>
                <div className="flex flex-col gap-4" onClick={() => handleChange(index, item.date)}>
                  <div className="flex w-full cursor-pointer flex-row items-center justify-between rounded-[10px] border-[1px] border-solid border-[#D4D4D4] p-2 shadow-lg shadow-[#D4D4D4]">
                    <div className="font-['Proxima Nova'] text-[15px] font-[600] leading-[21px]">{formattedDate}</div>
                    <div className={`flex h-[30px] w-[30px] ${expanded[index] ? 'pt-3' : 'p-1'}`}>
                      <div className={`h-full w-full  ${expanded[index] ? "bg-[url('assets/images/icon/i-arrow_down.svg')]" : "bg-[url('assets/images/icon/i-arrow.svg')]"} bg-no-repeat`} />
                    </div>
                  </div>
                </div>
                {expanded[index] && (
                  <>
                    {loadingDate === item.date ? (
                      <div className="flex justify-center">
                        <IconLoading size={LoadingSize.Small} />
                      </div>
                    ) : (
                      <>
                        <div>
                          {answer[item.date]?.map((detail: any, index: number) => {
                            return (
                              <div key={`resultDetail-${index}`} className="flex items-center border-t border-color-gray py-3">
                                <div className="flex-1 grow text-[12px] font-semibold leading-[22px] text-color-overlay">{detail.title}</div>
                                <div className={`text-color-${detail.result.toLowerCase()}-risk w-20 grow text-right text-[12px] font-semibold leading-[15px]`}>
                                  {detail.answer || ''}
                                  <span className="ml-1"></span>
                                  {detail.answer ? detail.unit : ''}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <RiskLegend />
                      </>
                    )}
                  </>
                )}
              </>
            )
          )
        })}
      </div>
    )
  }, [expanded, answer, isLoading, loadingDate])

  return (
    <WrapperMyHealth title="labTestResults" prevScreen={prevScreen}>
      <div className="m-2 flex flex-col justify-center gap-8">{listHeartScore}</div>
    </WrapperMyHealth>
  )
}

export default ListHeartScore
