import axios from 'axios'

import {AdessoError} from '../../lib/error'
import {LoginOptions} from '../../models/login'
import {AdessoResult} from "../../models";

type GetMeResult = {
  success: boolean,
  data?: any,
  message?: string
}

export async function getMe(): Promise<GetMeResult> {
  const {data, status} = await axios.get('/me')

  if (status !== 200) {
    console.info('get me', data)
    throw new AdessoError(`Update introduction error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
