import { useMemo } from 'react'
import { Session10Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'
import { ButtonCommon, ButtonStyles } from '../../../../Common'

type Props = {
  isLoading?: boolean
  nextScreen?: any
  next2Screen?: any
}

export const ScreenVideo = ({ isLoading, next2Screen, nextScreen }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session10Wrapper>
        <div className="space-y-6">
          <h4 className="text-[18px] font-bold leading-[23px]">The Journey Continues...</h4>
          <p>Hear a final message from Dr. Suzanne.</p>
          <VimeoEmbed embedId="808487287" />
          <p className="text-center">
            The journey doesn’t end here.
            <br /> <br />
            Keep visiting your dashboard to log your actions, discover new content and make progress towards your goals. Remember that this is a lifelong journey with ups and downs and Adesso is
            always here to support you.
          </p>
        </div>
        <ButtonCommon className="mt-16" style={ButtonStyles.Dark} text="SHARE FEEDBACK" width="w-full" height="h-[50px]" onClick={nextScreen} />
        <ButtonCommon className="mt-4 mb-8" style={ButtonStyles.Dark} text="COMPLETE THIS EXPERIENCE" width="w-full" height="h-[50px]" onClick={next2Screen} />
      </Session10Wrapper>
    )
  }, [isLoading, next2Screen, nextScreen])

  return <>{screenBigIdeasVideo}</>
}
