import axios from 'axios'
import { AdessoError } from '../error'

type GetGoalTrackingDetailResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getGoalTrackingDetail(goalTrackingId: number): Promise<GetGoalTrackingDetailResponse> {
  const { data, status } = await axios.get(`/goal-tracking/${goalTrackingId}/detail`)

  if (status !== 200) {
    console.info('getGoalTrackingDetail', data)
    throw new AdessoError(`Request getGoalTrackingDetail error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetGoalTrackingDetailResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
