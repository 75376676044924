import { Session02Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenHeartAssessment04 = ({ buttons }: Props) => {
  return (
    <Session02Wrapper buttons={buttons}>
      <div className="space-y-6">
        <p>
          <b>Great work!</b> No matter the degree, movement has been a part of our lives up to now, all we have to do is start incorporating it!
          <br />
          <br />
          When?
          <br />
          <br />
          You guessed it:
          <span className="font-bold italic"> Adesso: Now!</span>
          <br />
          <br />
          So if we’re sitting or laying down let’s hop up and dance a little jig. Or stretch a big, long, wonderful stretch with a BIGGGGG deeep breath!
          <br />
          <br />
          Feels good, right?
          <br />
          <br />
          Next, let’s talk about the benefits of incorporating movement into our daily lives.
        </p>
      </div>
    </Session02Wrapper>
  )
}
