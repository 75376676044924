import {useMemo, useState} from 'react'
import {Goal} from "../../models/goal";
import {GoalElement} from "./Goal";
import {IconLoading} from "../Common";
import { useLocales } from '../../locales';

export enum ActionType {
  Add,
  Remove,
}

type Props = {
  handleButtonClick: Function,
  goal: Goal
  actionType: ActionType
}

export const HeartJournalMyGoalsItem = ({handleButtonClick, goal, actionType}: Props) => {
  const [isProgressing, setIsProgressing] = useState(false)
  const {t} = useLocales()

  function handleButtonClicked() {
    setIsProgressing(true)
    handleButtonClick(goal)
    setTimeout(() => {
      setIsProgressing(false)
    }, 60000)
  }

  const actionButton = useMemo(() => {
    const isAddAction = actionType === ActionType.Add
    const icon = isAddAction ? `bg-[url('assets/images/icon/i-add.svg')]` : `bg-[url('assets/images/icon/i-remove.svg')]`
    const textButton = isAddAction ? t('common.add') : t('common.remove')
    const colorButton = isAddAction ? 'text-color-text-primary' : 'text-color-text-secondary'

    if (isProgressing) {
      return (
        <div
          className={`${isAddAction ? 'ml-4 text-color-text-primary' : 'ml-[27.5px]'} pl-9 h-[29px] w-24 flex items-center justify-end`}>
          <IconLoading customStyle={{width: '29px', height: '29px'}}></IconLoading>
        </div>
      )
    }
    return (
      <div onClick={handleButtonClicked}
           className={`ml-4 flex h-[29px] w-24 cursor-pointer items-center justify-end bg-[length:auto_29px] bg-[center_right_1px] bg-no-repeat pr-9 ${icon}`}>
                <span
                  className={`text-[14px] font-semibold leading-[17px] tracking-[0.15px] ${colorButton}`}>{textButton}</span>
      </div>
    )
  }, [actionType, isProgressing])

  return (
    <div className="flex items-center justify-between" data-id={goal.id}>
      <GoalElement goal={goal} clickable={actionType == ActionType.Remove} isProgressing={isProgressing}/>
      {actionButton}
    </div>
  )
}
