import { useState, useCallback, useMemo } from 'react'
import { ScreenHeartAssessment01 } from './HeartAssessment01'
import { SessionButtons } from '../../layout'
import { Session09Wrapper } from '../Wrapper'

import { CoreConceptsStops } from '../../../../../models'

import { dataHA, sesstion09Questions } from '../../../../../lib/data/session-09'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers: any
  isButtonBack: boolean
}

export const ScreenHeartAssessment = ({ showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? 5 : step.sub_step)
  // const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataHA[0])
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataHA[isButtonBack ? 5 : step.sub_step])

  const numScreens = dataHA.length

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 1, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
        setContentScreen(dataHA[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < numScreens - 1) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
        setContentScreen(dataHA[stepCurrent + 1] || '')
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: number) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
    },
    [onUpdate, nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenHeartAssessment = useMemo(() => {
    switch (screen) {
      case 0:
        return <ScreenHeartAssessment01 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />

      default:
        return (
          <Session09Wrapper buttons={buttons}>
            <div className="session-style">
              {contentScreen.title && <h4>{contentScreen.title}</h4>}

              {contentScreen.img && <img src={`/images/my-adresso-journey/session/09/${contentScreen.img}`} alt="" />}

              <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
            </div>
          </Session09Wrapper>
        )
    }
  }, [screen, buttons, contentScreen, handleBackScreen, handleNextScreen, showModal, handleUpdate])

  return <>{screenHeartAssessment}</>
}
