import { ChangeEvent, useMemo, useState } from 'react'
import { FieldTypes } from '../../../models'
import { RadioField } from '../../Common/form/Radio'
import { useLocales } from '../../../locales'
import { goalTrackingConfig } from '../../../models/goalTrackingConfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { MyAdessoGTCStep } from '../../../constants/introduction'

type Props = {
  handleAddRemoveGoal: Function
  title?: string
  question?: string
  switches?: boolean
  questions?: any
  backgroundColor?: string
  goalTracking?: goalTrackingConfig
  value?: string
  fieldType: FieldTypes
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  jumpTo: any
  refs?: React.Ref<any>[]
  isAdded?: boolean
  isIntroduction?: boolean
}

export const GoalTrackingConfiguration = ({ handleAddRemoveGoal, backgroundColor, jumpTo, refs = [], questions, value, fieldType, onChange, isAdded, goalTracking, isIntroduction }: Props) => {
  const { t } = useLocales()
  const navigate = useNavigate()
  const location = useLocation()

  const navigateTo = (title: string) => {
    return `/my-health/${title}-rx/?previousUrl=${location.pathname}&tab-gtc-from-dt=${questions?.id}`
  }
  const getTitle = (title = 'commit', key?: string) => {
    return t(`goalConfig.${title?.toLowerCase()}.${key}`)
  }
  const isDefaultGoal = goalTracking?.title === 'COMMIT'

  const toggle = useMemo(() => {
    return (
      <>
        {(!isDefaultGoal || isIntroduction) && (
          <label className="inline-flex cursor-pointer items-center" ref={refs[MyAdessoGTCStep.ToggleCommit]}>
            <input key={questions.id} type="checkbox" value="" className="peer sr-only" checked={isAdded} onChange={() => handleAddRemoveGoal(isAdded)} />
            <div
              ref={jumpTo}
              className="peer relative h-[31px] w-[51px] rounded-[16px] bg-[#E9E9EA] after:absolute after:left-0.5 after:top-0.5 after:h-[27px] after:w-[27px] after:rounded-[16px] after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#FF3399] peer-checked:after:-left-[5px] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
            ></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
          </label>
        )}
      </>
    )
  }, [questions, isAdded])

  return (
    <div className="space-y-2 border-y-[1px] py-3">
      <div>
        <div className="flex justify-between">
          <h4 style={{ color: backgroundColor }} className="font-bold uppercase">
            {questions?.name}
          </h4>
          {toggle}
        </div>
        <p>{getTitle(goalTracking?.title, 'title1')}</p>
      </div>
      <div>
        <h6 className="uppercase" style={{ color: backgroundColor }}>
          {getTitle(goalTracking?.title, 'title2')}
        </h6>
        <p>{getTitle(goalTracking?.title, 'title3')}</p>
      </div>
      <div ref={refs[MyAdessoGTCStep.Journal]}>
        <h6 className="uppercase" style={{ color: backgroundColor }}>
          {t('mainMenuAndSection.journaling')}
        </h6>
      </div>
      <div>
        {questions?.notification && <h6 className="uppercase">{`${questions?.notification}: `}</h6>}
        <div>{questions && <RadioField question={questions} value={typeof value === 'string' ? value : undefined} fieldType={fieldType} onChange={onChange} />}</div>
      </div>
      {questions?.title && (
        <div className="flex justify-end">
          <a
            href=""
            className="text-[14px] leading-none text-color-primary underline decoration-color-primary"
            onClick={(e) => {
              e.preventDefault()
              navigate(navigateTo(questions?.title))
            }}
          >
            {questions?.link}
          </a>
        </div>
      )}
    </div>
  )
}
