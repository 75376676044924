import axios from 'axios'

import { AdessoError } from '../error'
import { SubscriptionOptions } from '../../models/subscription'

type Response = {
  data?: {
    id: number
    content: string
  }
  message?: string
}

export async function postSubscription(params: SubscriptionOptions): Promise<Response> {
  const { data, status } = await axios.post('/subscription', params)

  console.info('Data =>', data)
  console.info('Status =>', status)

  if (status !== 200) {
    console.info('postSubscription', data)
    throw new AdessoError(`Request postSubscription error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
