import { useState, useCallback, useMemo } from 'react'

import { Session09Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { CoreConceptsStops } from '../../../../../models'

type Props = {
  dataList: CoreConceptsStops[]
  showModal: () => void
  backScreen: () => void
}

export const ScreenCoreConceptsMoreItem = ({ showModal, backScreen, dataList }: Props) => {
  const [screen, setScreen] = useState<number>(0)
  const [contentScreen, setContentScreen] = useState<CoreConceptsStops>(dataList[0])

  const handleBackScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent === 0) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        setScreen(stepCurrent - 1)
        setContentScreen(dataList[stepCurrent - 1] || '')
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent < dataList.length - 1) {
        window.scrollTo(0, 0)
        setScreen(stepCurrent + 1)
        setContentScreen(dataList[stepCurrent + 1] || '')
      } else backScreen()
    },
    [backScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConceptsMoreItem = useMemo(() => {
    return (
      <Session09Wrapper buttons={buttons}>
        <div className="session-style">
          {contentScreen.title && <h4>{contentScreen.title}</h4>}

          <img src={`/images/my-adresso-journey/session/09/${contentScreen.img}`} alt="" />

          <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
        </div>
      </Session09Wrapper>
    )
  }, [screen, buttons, contentScreen])

  return <>{screenCoreConceptsMoreItem}</>
}
