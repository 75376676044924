import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DashboardIntroductionScreen, ScreenEnd } from '../../components/Introduction/Screens/'
import { useLocalStorage } from '../../hooks/use-local-storage'
import { useAuth } from '../../hooks/use-auth'
import { useMAJPage } from '../../hooks/pages/maj'
import MyActionServices from '../../services/MyActionServices'
import { dataEndOfOnboarding } from '../../constants/introduction'

enum IntroductionScreen {
  Dashboard,
  EndOfOnboarding,
}

export const Introduction = () => {
  const { getItemLS, setItemLS } = useLocalStorage()
  const navigate = useNavigate()
  const auth = useAuth()
  const [doneIntroduction, setDoneIntroduction] = useState(false)
  const [screen, setScreen] = useState<IntroductionScreen>(IntroductionScreen.Dashboard)

  const userLocalStorage: any = getItemLS('user')
  const [user, setUser] = useState(userLocalStorage)
  const doneIntroductionLocalStore = getItemLS('doneIntroduction')

  const { dataSessions, getDataSessions } = useMAJPage()

  useEffect(() => {}, [doneIntroductionLocalStore])

  useEffect(() => {
    if (dataSessions?.isLoading === undefined) {
      getDataSessions()
    }
  }, [dataSessions?.isLoading])

  useEffect(() => {
    auth.getMeResult().then((res) => {
      if (res.success && res.data) {
        let user = res.data
        setUser(user)
        if (user.introduced) {
          setItemLS('doneIntroduction', 'yes')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (user && user.introduced) {
      setDoneIntroduction(true)
    }
  }, [user])

  useEffect(() => {
    if (doneIntroduction && screen !== IntroductionScreen.EndOfOnboarding) {
      navigate('/dashboard')
    }
  }, [doneIntroduction])

  const handleClickNextScreen = useCallback((stepCurrent: IntroductionScreen, shouldLogout = false) => {
    if (stepCurrent + 1 === IntroductionScreen.EndOfOnboarding) {
      setScreen(stepCurrent + 1)
      setItemLS('doneIntroduction', 'yes')
      auth.setIntroduced().then((res) => {
        if (res) {
          setUser(res.data)
        }
      })
    } else {
      const key = 'introduction_' + stepCurrent
      MyActionServices.addMyAction(key).then((response) => {
        // do something here
      })

      setScreen(stepCurrent + 1)
    }
  }, [])

  switch (screen) {
    case IntroductionScreen.Dashboard:
      return <DashboardIntroductionScreen nextScreen={() => handleClickNextScreen(screen)} />
    case IntroductionScreen.EndOfOnboarding:
      return <ScreenEnd nextScreen={() => handleClickNextScreen(screen)} text={dataEndOfOnboarding.text} linkPages={dataEndOfOnboarding.pages} />

    default:
      return <></>
  }
}
