import { useMemo } from 'react'
import { Session04Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session04Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            In this session we’re going to focus on how your emotional heart state impacts your physical heart state.
            <br />
            Play the video below to learn about some of these big ideas:
            <br />
            <br />
            Click the video below to begin.
          </p>

          <VimeoEmbed embedId="808489202" />
        </div>
      </Session04Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
