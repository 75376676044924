import { Link } from 'react-router-dom'

export enum ArrowType {
  Left = 'left',
  Right = 'right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  Center = 'center',
  BottomCenter = 'bottom-center',
  BottomCenters = 'bottom-centers',
}

type Props = {
  text: string
  title?: string
  download?: {
    name?: string
    link?: string
  }
  children?: React.ReactNode
  className?: string
  plusPoint?: number
  arrow?: ArrowType
  arrowClass?: string
}

export const BoxText = ({ text, children, className, arrowClass, plusPoint, arrow, title, download }: Props) => {
  let arrowStyle = ''
  let containerStyle = ''
  let arrowStyles: any = []

  switch (arrow) {
    case ArrowType.Left:
      arrowStyle = 'absolute -top-10 h-[57px] w-[49px] bg-[url("assets/images/icon/i-arrow_up.webp")] bg-[length:auto_100%] bg-center bg-no-repeat left-5'
      break
    case ArrowType.Right:
      arrowStyle = 'absolute -top-10 h-[57px] w-[49px] bg-[url("assets/images/icon/i-arrow_up.webp")] bg-[length:auto_100%] bg-center bg-no-repeat right-5'
      break
    case ArrowType.BottomLeft:
      arrowStyle = 'absolute bottom-[-10px] h-[57px] w-[49px] bg-[url("assets/images/icon/i-arrow_down.webp")] bg-[length:auto_100%] bg-center bg-no-repeat left-5'
      containerStyle = 'bottom'
      break
    case ArrowType.BottomRight:
      arrowStyle = 'absolute bottom-[-10px] h-[57px] w-[49px] bg-[url("assets/images/icon/i-arrow_down.webp")] bg-[length:auto_100%] bg-center bg-no-repeat right-20'
      containerStyle = 'bottom'
      break

    case ArrowType.BottomCenter:
      arrowStyle =
        'absolute bottom-[-10px]  w-[49px] h-[57px] bg-[url("assets/images/icon/i-arrow_down.webp")] bg-[length:auto_100%] bg-center bg-no-repeat left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      containerStyle = 'bottom'
      break

    case ArrowType.BottomCenters:
      arrowStyles = [
        'absolute bottom-[-10px] w-[49px] h-[57px] bg-[url("assets/images/icon/i-arrow_down.webp")] bg-[length:auto_100%] bg-center bg-no-repeat right-[calc(50%-145px)]',
        'absolute bottom-[-10px]  w-[49px] h-[57px] bg-[url("assets/images/icon/i-arrow_down.webp")] bg-[length:auto_100%] bg-center bg-no-repeat left-[calc(50%-145px)]',
        'absolute bottom-[-10px]  w-[49px] h-[57px] bg-[url("assets/images/icon/i-arrow_down.webp")] bg-[length:auto_100%] bg-center bg-no-repeat left-1/2 -translate-x-1/2',
      ]
      containerStyle = 'bottomCenters'
      break
    case ArrowType.Center:

    default:
      arrowStyle = 'absolute -top-10 h-[57px] w-[49px] bg-[url("assets/images/icon/i-arrow_up.webp")] bg-[length:auto_100%] bg-center bg-no-repeat left-1/2 -translate-x-1/2'
      break
  }

  const ArrowBottomCenters = () => {
    return arrowStyles.map((style: any, index: any) => <div key={index} className={`${style} ${arrowClass} `} />)
  }

  return (
    <>
      <div className={`relative max-w-[855px] rounded-[15px] bg-white p-5 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] ${className}`}>
        {arrow && containerStyle === '' && <div className={arrowStyle} />}
        <p className="text-[20px] font-bold leading-[21px]">{children || title}</p>
        {plusPoint && <p className="text-color-text-[#FF3399] my-4 text-[24px] font-bold leading-none">+{plusPoint} beats</p>}

        <p className="text-[17px] font-medium leading-[21px]">{children || text}</p>

        <a onClick={() => window.open(`${download?.link}`, '_blank')} className="text-[17px] font-bold leading-[21px] text-[#000] underline">
          {children || download?.name}
        </a>

        {arrow && containerStyle === 'bottom' && <div className={`${arrowStyle} ${arrowClass} `} />}
        {arrow && containerStyle === 'bottomCenters' && ArrowBottomCenters()}
      </div>
    </>
  )
}
