import { useCallback, useMemo, useState } from 'react'
import { ScreenCoreConcepts01 } from './CoreConcepts01'
import { ScreenCoreConcepts02 } from './CoreConcepts02'
import { ScreenCoreConcepts03 } from './CoreConcepts03'
import { ScreenCoreConcepts04 } from './CoreConcepts04'
import { ScreenCoreConcepts05 } from './CoreConcepts05'
import { ScreenCoreConcepts06 } from './CoreConcepts06'
import { ScreenCoreConcepts07 } from './CoreConcepts07'
import { ScreenCoreConcepts08 } from './CoreConcepts08'
import { ScreenCoreConcepts09 } from './CoreConcepts09'
import { ScreenCoreConcepts10 } from './CoreConcepts10'
import { SessionButtons } from '../../layout'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'
import { useMovementRxPage } from '../../../../../hooks/use-movement-rx'
import { useAlert } from '../../../../../context/AlertContext'
import { useMAJPage } from '../../../../../hooks/pages/maj'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  values: object
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  step: Step
  answers?: any
  isButtonBack: boolean
}

enum Screen {
  CoreConcepts01,
  CoreConcepts02,
  CoreConcepts03,
  CoreConcepts04,
  CoreConcepts05,
  CoreConcepts06,
  CoreConcepts07,
  CoreConcepts08,
  CoreConcepts09,
  CoreConcepts10,
}

export const ScreenCoreConcepts = ({ values, showModal, nextScreen, backScreen, onUpdate, step, answers, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.CoreConcepts10 : step.sub_step)
  const { usePostMovementAutoRx } = useMovementRxPage()
  const { postDataSessions } = useMAJPage()
  const { setAlertError } = useAlert()

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const handleMoveAuto = async (params: any) => {
    try {
      await postDataSessions(params, 2)
      await usePostMovementAutoRx()
    } catch (error) {
      setAlertError(`${error}`)
    }
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.CoreConcepts01) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent < Screen.CoreConcepts10) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const handleUpdate = useCallback(
    (params: object, stepCurrent: Screen) => {
      params && onUpdate(params)
      handleNextScreen(stepCurrent)
      if (screen === 6) {
        handleMoveAuto(params)
      }
    },
    [onUpdate, nextScreen, screen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    switch (screen) {
      case 0:
        return <ScreenCoreConcepts01 buttons={buttons} />
      case 1:
        return <ScreenCoreConcepts02 buttons={buttons} />
      case 2:
        return <ScreenCoreConcepts03 buttons={buttons} />
      case 3:
        return <ScreenCoreConcepts04 buttons={buttons} />
      case 4:
        return <ScreenCoreConcepts05 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />
      case 5:
        return <ScreenCoreConcepts06 buttons={buttons} />
      case 6:
        return <ScreenCoreConcepts07 answers={answers} showModal={showModal} backScreen={() => handleBackScreen(screen)} onUpdate={(params: object) => handleUpdate(params, screen)} />
      case 7:
        return <ScreenCoreConcepts08 values={values} buttons={buttons} />
      case 8:
        return <ScreenCoreConcepts09 buttons={buttons} />
      case 9:
        return <ScreenCoreConcepts10 buttons={buttons} />
    }
  }, [values, screen, buttons, showModal, handleBackScreen, handleNextScreen])

  return <>{screenCoreConcepts}</>
}
