import { useMemo, useCallback } from 'react'

import { Question } from '../../../models/question'

type Props = {
  question: Question
  value?: number
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  onChange: Function
}

export const RateField = ({ question, disabled, value, onChange }: Props) => {
  const handleChangeRate = useCallback((e: any) => {
    if (!disabled) {
      onChange(question.name, String(e.target.value / 20))
    }
  }, [])

  const rateContent = useMemo(() => {
    return (
      <div className="relative w-full pb-[18px]">
        <div className="flex w-full cursor-pointer justify-between px-5 text-[16px] font-medium leading-[22px]">
          <span className="w-[12.5%] text-left">1</span>
          <span className="w-1/4 text-center">2</span>
          <span className="w-1/4 text-center">3</span>
          <span className="w-1/4 text-center">4</span>
          <span className="w-[12.5%] text-right">5</span>
        </div>
        <input type="range" name={question.name} disabled={disabled} onChange={(e) => handleChangeRate(e)} defaultValue={(value || 2.5) * 20} />
      </div>
    )
  }, [value, question])

  return <div className="space-y-4">{rateContent}</div>
}
