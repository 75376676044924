import { useState, useCallback, useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion02Questions } from '../../../../../lib/data/session-02'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion02Questions[27], sesstion02Questions[28]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers?: any
}

export const ScreenCoreConcepts05 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session02Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6 text-[18px] leading-[22px]">
            <h4 className="font-bold">Creating a lifestyle of movement.</h4>
            <picture className="block h-auto w-full">
              <source srcSet={`/images/my-adresso-journey/session/02/img_005.webp`} type="image/webp" />
              <img src={`/images/my-adresso-journey/session/02/img_005.jpg`} alt="" />
            </picture>
            <p>
              {`How do you move throughout the day? It’s part of our vitality. It’s what sustains us and keeps us vital, living more like our ancestors and the way our bodies are best designed to work. This is a mindset shift that goes beyond scheduling time at the gym or whatever exercise you like to do and simply creating a daily habit that opts to move whenever possible.`}
              <br />
              <br />
              You can think about “Movement” in two senses:
              <br />
              <br />
              <b>Target Movements</b>
              {` - focused time during which you actively work to reach your target heart rate and sustain it for 30 minutes or more. Besides cardio, these can also be times when you really
            focus on your muscles. From a very basic perspective, we recommend:`}
              <br />
              <br />
              <b>Daily Movements</b> - those things you do throughout your day to keep yourself in a state of activity.
            </p>

            {ssHAQuestions.map((item) => {
              return (
                <FormField key={`${item.name}`} isInputSS question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session02Wrapper>
    )
  }, [formik, buttons, isError])

  return <>{screenHeartAssessment02}</>
}
