import { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Wrapper, TitlePage } from '../Common'
import { useLocalStorage } from '../../hooks/use-local-storage'

type Props = {}

export const ForgotPasswordSentContent = ({}: Props) => {
  const navigate = useNavigate()

  const { getItemLS, deleteItemLS } = useLocalStorage()
  const redirectFrom = String(getItemLS('redirect-from'))

  useEffect(() => {
    const acceptRoute = ['forgot-password', 'change-password']
    if (acceptRoute.includes(redirectFrom)) {
      deleteItemLS('redirect-from')
    } else {
      navigate('/')
    }
  }, [])

  const ForgotPasswordSentContent = useMemo(() => {
    if (redirectFrom == 'forgot-password') {
      return (
        <Wrapper className="relative min-h-[calc(100vh-74px)] py-10">
          <TitlePage
            titleColor="text-color-text-primary"
            subTitleColor="text-color-overlay"
            title="Password reset request received"
            subTitle="If the email address you entered is registered with MyAdesso, we will send an email message with a link to reset your password."
          />
        </Wrapper>
      )
    } else {
      return (
        <Wrapper className="relative min-h-[calc(100vh-74px)] py-10">
          <TitlePage titleColor="text-color-text-primary" subTitleColor="text-color-overlay" title="Your password has been changed" subTitle="Please login by new password!" />
          <div className="mt-20 text-center">
            <a className="font-bold text-[#ED2580] underline hover:text-[#ED2580]" href="/login">
              Back to login
            </a>
          </div>
        </Wrapper>
      )
    }
  }, [])

  return <>{ForgotPasswordSentContent}</>
}
