import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { GoDeeperLibraryContent } from '../../components/GoDeeper/Library'

import { useGoDeeperLibraryPage } from '../../hooks/pages/go-deeper'

export const GoDeeperLibraryPage = () => {
  const navigate = useNavigate()
  const { dataDeepers, getDataDeepers } = useGoDeeperLibraryPage()

  useEffect(() => {
    if (dataDeepers?.data) return
    getDataDeepers()
  }, [dataDeepers?.data])

  const goDeeperLibraryPage = useMemo(() => {
    const isLoading = dataDeepers?.isLoading || false
    const deepers = dataDeepers?.data?.deepers || []

    return <GoDeeperLibraryContent deepers={deepers} isLoading={isLoading} />
  }, [dataDeepers])

  return <>{goDeeperLibraryPage}</>
}
