import { CSSProperties } from 'react'
import { IconLoading } from './IconLoading'
import { LoadingSize } from '../../models/loading'

type Props = {
  size?: LoadingSize
  customStyle?: CSSProperties
  isFull?: boolean
}

export const Loading = ({ size, customStyle, isFull = false }: Props) => {
  return (
    <>
      {isFull ? (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-40 flex items-center justify-center bg-white opacity-60">
          <IconLoading size={size} customStyle={customStyle} />
        </div>
      ) : (
        <div className="flex w-full items-center justify-center">
          <IconLoading size={size} customStyle={customStyle} />
        </div>
      )}
    </>
  )
}
