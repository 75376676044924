import React, { useEffect, useMemo, useState } from 'react'
import { DashboardSection } from '../Common/DashboardSection'
import { NavLink } from 'react-router-dom'
import { useLocales } from '../../locales'
import { useHeartScoreResultPage } from '../../hooks/pages/heart-score-result'
import { ButtonCommon, ButtonStyles, ButtonType } from '../Common/Button'
import { IconLoading } from '../Common'
import { LoadingSize } from '../../models/loading'

export const MyHealth = ({ jumpTo }: any) => {
  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()
  const dataResultHeartScore = resultHeartScore?.data?.results

  useEffect(() => {
    if (resultHeartScore?.data) return
    getResultHeartScore()
  }, [resultHeartScore?.data])

  const { t } = useLocales()
  const [isOpen, setIsOpen] = useState(false)

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  const myHealth = useMemo(() => {
    const isLoading = resultHeartScore?.isLoading || false

    if (isLoading) {
      return (
        <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myHealth')}>
          <div className="flex justify-center">
            <IconLoading size={LoadingSize.Normal} />
          </div>
        </DashboardSection>
      )
    }

    return (
      <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myHealth')}>
        {/* <div className="flex space-x-4">
          <div className="relative flex h-[131px] w-[41%] items-center justify-center">
            <div className="h-full w-full bg-[url('assets/images/icon/i-circle_health.svg')] bg-[length:auto] bg-center bg-no-repeat" />
            <p className="absolute text-[44px] font-bold text-[#F26E57]">{totalScore}</p>
          </div>
  
          <div className="w-[60%] text-start">
            <h4 className="text-[20px] font-normal text-[#F26E57]">Moderate</h4>
            <p className="text-[14px] font-normal">This score is calculated from your Responses to Your Most Recent Adesso Heart Score Questionaire</p>
          </div>
        </div> */}

        <div className="md:grid md:grid-flow-col md:gap-x-4">
          <div className="flex justify-between md:col-span-2">
            <div
              className={`mr-4 flex h-[130px] w-[130px] items-center justify-center rounded-full border-[10px] border-color-${dataResultHeartScore?.totalStatus}-risk  font-['Lato'] text-[44px] leading-none text-color-${dataResultHeartScore?.totalStatus}-risk`}
            >
              {dataResultHeartScore?.totalScore}
            </div>
            <div className="flex w-[calc(100%-146px)] flex-col justify-end">
              <h3 className={`text-[24px] capitalize text-color-${dataResultHeartScore?.totalStatus}-risk`}>{t(`${dataResultHeartScore?.totalStatus} Risk`)}</h3>
              <p className="pt-1 pb-4 text-[14px] font-normal leading-[16px] md:leading-[24px]">{t('extraText.hearChartScoreDes')}</p>

              <div className="flex justify-start">
                <NavLink to="/my-health/heart-score" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
                  {t('extraText.seeYourHeartScoreReport')}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="space-y-4 md:flex md:flex-col md:justify-end lg:space-x-4">
            <div className=""></div>
            <p className="text-[14px] font-normal leading-[16px] md:leading-[24px]">Access your medical data, prescription and blood work.</p>

            <div className="flex justify-end md:justify-start">
              <NavLink to="/my-health" onClick={togglePopup} className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
                {t('extraText.viewMyHealth')}
              </NavLink>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className={`fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-60`}>
            <div className={`relative top-24 mx-auto w-[95%] max-w-[500px] rounded-md p-4`}>
              <div className="relative top-[14px] w-full rounded-[15px] bg-white px-6 py-[18px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
                <p className="text-[17px] font-medium leading-[21px]">{t('extraText.newFeature')}</p>
              </div>
              <div className="relative top-[14px] mt-4 w-full rounded-[15px]">
                <ButtonCommon type={ButtonType.Button} style={ButtonStyles.Introduction} width="w-[167px]" height="h-[51px]" text="Got it!" onClick={togglePopup} />
              </div>
            </div>
          </div>
        )}
      </DashboardSection>
    )
  }, [resultHeartScore, dataResultHeartScore, isOpen])

  return myHealth
}
