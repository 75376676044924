import { useMemo, useCallback, useState, useEffect } from 'react'

import { Quizess } from '../../models/question'

import { IntroductionScreen } from './screen/Introduction'
import { QuizzesScreen } from './screen/Quizzes'
import { FinishedScreen } from './screen/Finished'

import { FormikOptions } from '../../hooks/use-form'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth'
import { ButtonCommon, ButtonStyles } from '../Common'
import { useLocales } from '../../locales'
import { paramsOfSymptoms } from '../../pages/HeartScore/constants'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { trackAmplitude } from '../../lib/utils/helper'

type Props = {
  quizessData: Quizess[]
  isLoading?: boolean
  isSuccess?: boolean
  isError?: boolean
  errorMessage?: string
  onSubmit: (params: object) => Promise<void>
  step: any
}

export const HeartScoreContent = ({ quizessData, isLoading, isSuccess, isError, errorMessage, onSubmit, step }: Props) => {
  const introductionStepIndex = -1

  const { user, getMeResult } = useAuth()
  const { t } = useLocales()

  const [values, setValues] = useState<FormikOptions<any>>({})
  const [totalPage, setTotalPage] = useState<number>(quizessData.length)
  const [currentScreen, setCurrentScreen] = useState<number>(-1)
  const [stepIndex, setStepIndex] = useState<number>(introductionStepIndex)
  const { setItemLS, getItemLS } = useLocalStorage()

  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate('/my-health/heart-score')
  }

  useEffect(() => {
    getMeResult()
  }, [])

  useEffect(() => {
    setItemLS('amplitude_heart_score_survey_questions', `${user?.screen?.info?.page ?? -1}`)
  }, [user])

  useEffect(() => {
    let stepLS = (getItemLS('amplitude_heart_score_survey_questions') ?? -1) as number
    if (stepIndex > stepLS) {
      setItemLS('amplitude_heart_score_survey_questions', `${stepIndex}`)
      trackAmplitude('heart_score_survey_questions', { value: stepIndex })
    }
  }, [stepIndex])

  useEffect(() => {
    if (quizessData) {
      setTotalPage(quizessData.length)
    }
  }, [quizessData])

  useEffect(() => {
    if (step?.screen == 'heart-score' && step?.info?.page != undefined && step?.info?.screen != undefined) {
      setStepIndex(step.info.page)
      setCurrentScreen(step.info.screen)
    }
  }, [step])

  useEffect(() => {
    if (isSuccess) {
      setStepIndex(totalPage)
    }

    if (errorMessage) {
      alert(errorMessage)
    }
  }, [totalPage, errorMessage, isSuccess])

  const handlePrevScreen = useCallback(
    (stepCurrent: number) => {
      if (stepCurrent > introductionStepIndex) {
        window.scrollTo(0, 0)
        setStepIndex(stepCurrent - 1)

        const screens = quizessData[stepCurrent - 1] && quizessData[stepCurrent - 1].screens
        if (screens && screens.length) {
          setCurrentScreen(screens.length - 1)
        }
      } else {
        // first step
      }
    },
    [quizessData]
  )

  const handleNextScreen = useCallback(
    async (stepCurrent: number, paramCurrent: object, data?: any, nextPage?: boolean) => {
      // console.log("🚀 ~ stepCurrent:", stepCurrent)
      if (data?.data) {
        setValues({ ...paramCurrent, ...data.data })
      }

      // store step answer
      if (stepCurrent < totalPage && data?.screen != undefined) {
        stepCurrent === paramsOfSymptoms.currentPageOfSymptoms && data.screen === paramsOfSymptoms.stepPrevOfSymptoms
          ? await onSubmit({ answers: data?.data, step: { page: stepCurrent, screen: data.screen } })
          : onSubmit({ answers: data?.data, step: { page: stepCurrent, screen: data.screen } })
      }

      if (stepCurrent < totalPage && nextPage) {
        if (stepCurrent === totalPage - 1) {
          // retrieve the previously made answer when the user continues to perform the task in the next step
          const extractedAnswers = quizessData.map((obj: any) => obj?.answers)
          const mergedDataBefore = Object.assign({}, ...extractedAnswers)
          // submit when finish all heart score
          onSubmit({ ...mergedDataBefore, ...paramCurrent, ...data })
          if (user?.had_first_heart_score) {
            navigate('/my-health/heart-score')
          }
        } else {
          window.scrollTo(0, 0)
          setStepIndex(stepCurrent + 1)
          setCurrentScreen(-1)
        }
      }
    },
    [totalPage, user]
  )

  const heartScoreStatus = useMemo(() => {
    if (user?.heart_score_status !== 'can_not_create_or_update') return <></>
    return (
      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] flex items-center justify-center">
        <div className="absolute h-full w-full bg-color-overlay bg-opacity-50"></div>
        <div className="relative mx-12 w-[380px]">
          <div className="rounded-[15px] bg-white p-4 text-center text-[17px] font-medium leading-[21px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
            <p>{t('extraText.heartScoreStatus')}</p>
          </div>
          <div className="mt-3">
            <ButtonCommon
              // contentRef={contentRef}
              style={ButtonStyles.Introduction}
              width="w-[167px]"
              height="h-[51px]"
              // text={t('common.buttonConfirm')}
              text={`Got it!`}
              onClick={handleGoBack}
            />
          </div>
        </div>
      </div>
    )
  }, [user])

  const heartScoreContent = useMemo(() => {
    if (stepIndex === totalPage) return <FinishedScreen backScreen={() => handlePrevScreen(stepIndex)} />

    return stepIndex > introductionStepIndex ? (
      <QuizzesScreen
        values={values}
        quizess={quizessData[stepIndex]}
        isLoading={isLoading}
        totalPage={totalPage}
        currentScreen={currentScreen}
        currentPage={stepIndex}
        jumpScreen={(step) => handleNextScreen(step - 2, values, {}, true)}
        nextScreen={(data?: object, nextPage?: boolean) => handleNextScreen(stepIndex, values, data, nextPage)}
        backScreen={() => handlePrevScreen(stepIndex)}
      />
    ) : (
      <IntroductionScreen isLoading={isLoading} nextScreen={() => handleNextScreen(stepIndex, values, {}, true)} />
    )
  }, [currentScreen, quizessData, totalPage, isLoading, values, stepIndex, handlePrevScreen, handleNextScreen])

  return (
    <>
      {heartScoreStatus}
      {heartScoreContent}
    </>
  )
}
