import { GoalTrackConfigSection } from './Section'
import { ActionType, GoalTrackConfigMyGoalsItem } from './MyGoalsItem'
import { useMemo } from 'react'
import { Goal } from '../../models/goal'
import { IconLoading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { useLocales } from '../../locales'

const BackgroundColor = '#989899'
const TextColor = '#ffffff'

type Props = {
  isLoading?: boolean
  handleAddGoal: Function
  goals?: Goal[]
  noTitle?: boolean
  onClick?: (slug: string | any) => void
}
export const GoalTrackConfigAllGoals = ({ handleAddGoal, goals, isLoading, noTitle = false, onClick }: Props) => {
  const { t } = useLocales()
  const allGoalsContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }
    if (!goals || !goals.length) {
      return <div>{t('mainMenuAndSection.noGoal')}</div>
    }
    return goals.map((goal: Goal, index) => {
      goal.backgroundColor = BackgroundColor
      goal.textColor = TextColor
      return (
        <GoalTrackConfigMyGoalsItem
          handleButtonClick={handleAddGoal}
          key={goal.id || index}
          goal={goal}
          actionType={ActionType.Add}
          onClick={() => {
            if (onClick) onClick(goal.slug)
          }}
        />
      )
    })
  }, [goals])
  return (
    <GoalTrackConfigSection className="space-y-2" title={noTitle ? '' : t('mainMenuAndSection.allGoals')}>
      {allGoalsContent}
    </GoalTrackConfigSection>
  )
}
