import { useGlobalModalContext } from './global-modal'
import { BeatPopup } from '../../components/Common'
import { useMemo, useState } from 'react'

export const AddBeat = () => {
  const [step, setStep] = useState(0)
  const [levelUp, setLevelUp] = useState({ point: 0, img: '', title: '', beat: 0 })
  const [isBeat, setIsBeat] = useState(true)
  const { hideGlobalModal, store } = useGlobalModalContext()
  const {
    modalProps: { beats, score, message, level_up },
  } = store

  const showSpecialAddPoint = () => {
    if (level_up.point != 0) {
      setStep(1)
      setLevelUp(beats)
      setIsBeat(false)
    } else {
      hideGlobalModal()
    }
  }

  const handleModalToggle = () => {
    hideGlobalModal()
  }

  const prepareColor = useMemo(() => {
    return (
      <div className="hidden">
        <div className="border-t-[#F2C9C2] bg-[#F2C9C2] text-[#F26C59]"></div>
        <div className="border-t-[#FFD7E8] bg-[#FFD7E8] text-[#EF2E7D]"></div>
        <div className="border-t-[#EFD4F3] bg-[#EFD4F3] text-[#974A9A]"></div>
        <div className="border-t-[#FFD7E8] bg-[#FFD7E8] text-[#EB2781]"></div>
      </div>
    )
  }, [])

  const beatContent = useMemo(() => {
    return isBeat && score ? (
      <BeatPopup className={`bg-[${message.bg_color}] shadow-[4px_4px_16px_rgb(0,0,0)]`}>
        <div className="absolute top-4  right-4 h-6 w-6 bg-[url('/images/white_close.png')] bg-cover bg-center bg-no-repeat" onClick={showSpecialAddPoint}></div>
        <div className={`flex items-center  text-[28px] font-semibold text-[${message.text_color}]`}>
          <div className="mr-3 inline-block h-[4.5rem] w-20 bg-[url('assets/images/icon/i-heart-add-beat.webp')] bg-center bg-no-repeat text-center text-[20px] font-medium leading-none text-white">
            <p className="relative top-1/2 -translate-y-1/2 text-[25px] font-semibold leading-4 text-white">+{score}</p>
          </div>
          BEATS
        </div>
        <div className="flex w-full justify-center">
          <h1 className={`mt-4 text-center text-[${message.text_color}] w-[60%] text-[17px] font-semibold leading-5`}>{message.message}</h1>
        </div>
        <div className={`absolute -bottom-12 left-1/2 h-14 w-12 -translate-x-1/2 border-x-[24px] border-t-[32px] border-x-[transparent] border-t-[${message.bg_color}]`}></div>
      </BeatPopup>
    ) : (
      <></>
    )
  }, [step])

  const levelContent = useMemo(() => {
    return level_up.point != 0 ? (
      <div className="fixed top-0 left-0 right-0 bottom-0 z-[51] flex justify-center bg-[rgba(0,0,0,0.8)] md:py-4">
        <div className="h-full w-[375px] bg-cover bg-[center_top] bg-no-repeat md:rounded-md" style={{ backgroundImage: 'url(/backgrounds/bg_up_level.png)' }}>
          <div className="space-y-6 pt-8 text-center text-white">
            <h1 className="w-full space-y-6 font-butler text-[35px] leading-[39px]">
              Congratulations!
              <br />
              You did it!
            </h1>
            <p className="text-[24px] font-bold">You've reached</p>
            <p className="text-[40px] font-bold">{beats} BEATS</p>
            <img className="inline h-56 w-56" src={level_up.img} alt="heart score" />
            <p className="text-[24px] font-bold">You’ve achieved the</p>
            <p className="text-[24px] font-bold">{level_up.title}</p>
            <p className="text-[20px]">To unlock your next level, work to complete your actions and keep advancing through your heart journey.</p>
            <span onClick={handleModalToggle} className=" inline-block rounded-[30px] bg-white px-10 py-3 text-[#EB2781]">
              OK
            </span>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  }, [levelUp])

  const addBeatScr = useMemo(() => {
    return isBeat ? <>{beatContent}</> : <>{levelContent}</>
  }, [step, beatContent, levelContent, isBeat])

  return <>{addBeatScr}</>
}
