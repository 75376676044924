import { useState, useCallback, useMemo } from 'react'
import { Session06Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion06Questions } from '../../../../../lib/data/session-06'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion06Questions[2], sesstion06Questions[3], sesstion06Questions[4], sesstion06Questions[5]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment01 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const after = (
    <div className="flex w-full justify-between text-[12px] font-bold leading-none">
      <span className="w-[33%] text-left">Very Little</span>
      <span className="w-[33%] text-center">Some</span>
      <span className="w-[33%] text-right">A lot</span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session06Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            <h4>How much do you know about your heart?</h4>
            <h4>First, let’s find out!</h4>
          </div>

          <div className="space-y-10">
            {ssHAQuestions.map((item, index) => {
              return (
                <FormField
                  key={`ss05_${item.name}`}
                  after={after}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session06Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
