import { DashboardSection } from '../components/Common/DashboardSection'
export enum DashboardStep {
  StartTour,
  MyNow,
  MyHeart,
  Congratulations,
  MyHeartActions,
  MyDailyTrack,
  GoalTrackingConfig,
  MyNextSession,
  MyReminder,
  MyHealth,
  GoDeeper,
  Completed,
}

export enum MyAdessoJourneyStep {
  WelcomeTour,
  Session1,
  IntroductionButton,
  Session2,
  Session3,
  IntroductionForm,
  GoalTrackingConfig,
}

export enum MyAdessoMyHealthStep {
  WelcomeTour,
  HeartScore,
  LabTestResult,
  SeePastTestResult,
  HealthRecommendations,
  RxRecommendationPage,
  CustomizeYourPlan,
}

export enum MyAdessoGTCStep {
  WelcomeTour,
  TabCommit,
  ToggleCommit,
  Journal,
  BloodPressure,
  TrackYourGoals,
}

export enum MyAdessoDailyTrackingStep {
  WelcomeTour,
  AdjustYourGoals,
  SeeYourLifestyleRx,
  GoToHeartJournal,
  Submit,
}

export enum MyAdessoHeartChartStep {
  WelcomeTour,
  Chart,
  MyProgressPage,
  // YourHeart,
  // Alerts,
  HeartDimensionsTracking,
}

export const dataEndOfOnboarding = {
  pages: [
    { title: 'introduction.endOfOnBoarding.pages.dailyTracking', link: '/daily-tracking' },
    { title: 'introduction.endOfOnBoarding.pages.learningJourneys', link: '/my-adesso-journey' },
    { title: 'introduction.endOfOnBoarding.pages.heartChart', link: '/heart-chart' },
    { title: 'introduction.endOfOnBoarding.pages.myHealth', link: '/my-health' },
  ],
  text: 'introduction.endOfOnBoarding.text',
}
export const dataEndOfSessionTutorial = {
  pages: [
    { title: 'introduction.finish.pages.dashboard', link: '/dashboard' },
    { title: 'introduction.finish.pages.dailyTracking', link: '/daily-tracking' },
    { title: 'introduction.finish.pages.myHealth', link: '/my-health' },
    { title: 'introduction.finish.pages.heartChart', link: '/heart-chart' },
  ],
  text: 'introduction.finish.text',
}

export const dataEndOfMyHealthTutorial = {
  pages: [
    { title: 'introduction.finish.pages.dashboard', link: '/dashboard' },
    { title: 'introduction.finish.pages.myHealth', link: '/my-health' },
    { title: 'introduction.finish.pages.learningJourneys', link: '/my-adesso-journey' },
    { title: 'introduction.finish.pages.heartChart', link: '/heart-chart' },
  ],
  text: 'introduction.myAdessoMyHealth.finish.text',
}

export const dataEndOfDailyTrackingTutorial = {
  pages: [
    { title: 'introduction.finish.pages.dashboard', link: '/dashboard' },
    { title: 'introduction.finish.pages.myHealth', link: '/my-health' },
    { title: 'introduction.finish.pages.learningJourneys', link: '/my-adesso-journey' },
    { title: 'introduction.finish.pages.heartChart', link: '/heart-chart' },
  ],
  text: 'introduction.myAdessoDailyTracking.finish.text',
}

export const dataEndOfHeartChartTutorial = {
  pages: [
    { title: 'introduction.finish.pages.dashboard', link: '/dashboard' },
    { title: 'introduction.finish.pages.myHealth', link: '/my-health' },
    { title: 'introduction.finish.pages.learningJourneys', link: '/my-adesso-journey' },
    { title: 'introduction.finish.pages.dailyTracking', link: '/daily-tracking' },
  ],
  text: 'introduction.myAdessoHeartChart.finish.text',
}
