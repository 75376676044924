import { Deeper, DeeperArticle, GoDeeperTopics, GoDeeperTypes } from '../../models/deepers'

function mapGoDeeperTopic(topic: string): GoDeeperTopics {
  switch (topic) {
    case 'COMMIT TO MY HEART':
      return GoDeeperTopics.COMMIT_TO_MY_HEART
    case 'RELATE FOR MY HEART':
      return GoDeeperTopics.RELATE_FOR_MY_HEART
    case 'MOVE MY HEART':
      return GoDeeperTopics.MOVE_MY_HEART
    case 'KNOW MY NUMBERS':
      return GoDeeperTopics.KNOW_MY_NUMBERS
    case 'NOURISH MY HEART':
      return GoDeeperTopics.NOURISH_MY_HEART
    case 'ADVOCATE FOR MY HEART':
      return GoDeeperTopics.ADVOCATE_FOR_MY_HEART
    case 'REFRESH MY HEART':
      return GoDeeperTopics.REFESH_MY_HEART
    case 'MANAGE MY HORMONES':
      return GoDeeperTopics.MANAGE_MY_HORMONES
    default:
      return GoDeeperTopics.COMMIT_TO_MY_HEART
  }
}

function mapGoDeeperType(type: string): GoDeeperTypes {
  switch (type) {
    case 'video':
      return GoDeeperTypes.VIDEO
    default:
      return GoDeeperTypes.BLOG
  }
}

export function mapDataDeepers(data: any[]): Deeper[] {
  if (data.length > 0) {
    let dataReturn: Deeper[] = []

    data.map((deeper) => {
      const slug = deeper.slug || deeper.id
      const type = mapGoDeeperType(deeper.type || 'blog')
      const topic = mapGoDeeperTopic(deeper.topic.toUpperCase())
      const thumbnail = deeper.imageUrl || '/images/go-deeper/001.webp'
      const title = deeper.title || 'title'
      const description = deeper.description || 'description'

      dataReturn = [...dataReturn, { slug, type, topic, thumbnail, title, description }]
    })

    if (dataReturn) return dataReturn
  }

  return []
}

export function mapDataDeeperDetail(data: any): DeeperArticle | undefined {
  if (data) {
    const id = data.id || 0
    const slug = data.slug || data.id
    const type = mapGoDeeperType(data.type || 'blog')
    const topic = mapGoDeeperTopic(data.topic.toUpperCase())
    const banner = data.imageUrl || '/images/go-deeper/001.webp'
    const title = data.title || ''
    const content = data.content || data.description || ''

    return { slug, type, topic, banner, title, content, id }
  }

  return undefined
}
