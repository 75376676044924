import { useMemo } from 'react'
import { Session05Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session05Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            <b>Welcome!</b>
            <br />
            In this session we’re going to talk about how relationships contribute to the physical health of your heart.
            <br />
            Play the video below to learn about some of these big ideas:
            <br />
            <br />
            Click the video below to begin.
          </p>

          <VimeoEmbed embedId="808488854" />
        </div>
      </Session05Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
