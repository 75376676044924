import { useCallback, useEffect, useMemo, useState } from 'react'

import { Navigate } from 'react-router-dom'

import { VimeoEmbed } from '../../components/Common/VimeoEmbed'
import { ButtonCommon, ButtonStyles } from '../../components/Common/Button'
import { postUpdateUserScreen } from '../../lib/request/post-update-user-screen'

export const Welcome = () => {
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    postUpdateUserScreen({ screen: 'welcome' })
    const onboarded = localStorage.getItem('onboarded')
    if (!onboarded || onboarded !== 'yes') {
      localStorage.removeItem('onboarded')
    }
  }, [])

  const handleGoToOnboard = useCallback(() => {
    setRedirect(true)
  }, [])

  const welcomeContent = useMemo(() => {
    if (redirect) {
      return <Navigate to="/onboard" replace={false} />
    } else {
      return (
        <div className="flex h-full w-full justify-center text-white">
          <div className="fixed top-20 left-0 right-0 bottom-0 z-10 bg-gradient-to-b from-[#D42128] to-[#F26E57]"></div>
          <div className="z-20 flex h-full w-full max-w-[686px] flex-col items-center">
            <div className="h-full w-full flex-1 px-4">
              <p className="mt-20 text-[28px]">Welcome to MyAdesso!</p>
              <p className="text-md mt-7 mb-4 font-semibold uppercase">Click the video below to begin</p>
              <VimeoEmbed embedId="806959257" />
            </div>
            <div className="mt-32 flex justify-center pb-10">
              <ButtonCommon style={ButtonStyles.Session} width="w-[109px]" height="h-[50px]" text="Next" onClick={() => handleGoToOnboard()} />
            </div>
          </div>
        </div>
      )
    }
  }, [redirect])

  return <>{welcomeContent}</>
}
