import { mapDataModalProgress } from '../maps/session'
import { mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion07Questions: Question[] = [
  mapQuestionNotRequired('ss07__005', QuestionTypes.TextArea, 'What difficulties have you had navigating the medical system as a woman?', 'Describe what you want to change.'),
  mapQuestionNotRequired('ss07__006', QuestionTypes.TextArea, 'What steps are you taking to ensure you prevent a serious heart emergency?', 'Describe what you want to change.'),
  mapQuestionNotRequired(
    'ss07__007',
    QuestionTypes.TextArea,
    'After learning more about emergency symptoms, is there any action that you want to take to address your heart health or speak with a healthcare provider about?',
    'Describe what you want to change.'
  ),
  mapQuestionNotRequired(
    'ss07__008',
    QuestionTypes.TextArea,
    'Are you happy with your healthcare provider or do you think that you might be able to find a better fit?',
    'Describe what you want to change.'
  ),
  mapQuestionNotRequired('ss07__001', QuestionTypes.Radio, 'When was your last check-up/physical?', undefined, ['A few months ago', 'A year ago', 'A few years ago']),
  mapQuestionNotRequired('ss07__002', QuestionTypes.Rate, 'How comfortable do you feel visiting your health provider’s office for routine check-ups or to address a health concern?'),
  mapQuestionNotRequired('ss07__003', QuestionTypes.Rate, 'Rate yourself on how well you communicate your symptoms with your health provider.'),
  mapQuestionNotRequired('ss07__004', QuestionTypes.Rate, 'Rate yourself on how well you advocate for your needs with your health provider.'),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  return { id, img, title, description, content }
}

const contentCoreConcepts01 = `
<p>In the last several sessions we discussed very important areas of your heart health: moving, nourishing, refreshing, and relating. These are the foundations of our wellbeing.</p>
</p>

<p>Because every aspect of our lives connects right to the center of our lives: our loving, working, beating heart.</p>

<p>Everything we talked about can lead to the development of plaque or prevent the development of plaque. Eighty percent of the time we can prevent the most common form of hear disease, atherosclerosis, which is plaque in the arteries that causes heart disease.</p>

<p>And what people often don’t know is that plaque in the arteries is very, very different in women than it is in men—and it’s harder to detect. We’ll get into this more later, but this often leads to women not knowing about their potential disease until it’s a real threat.</p>

<p>Simply by shifting our lifestyle choices, we can take control and prevent heart disease in our lives.</p>

<p>Now, let’s explore the heart and how it works so that this invisible thing isn't scary to you anymore.</p>`

const contentCoreConcepts03 = `
<p>First, let’s talk about how the heart works. The heart's job is to get oxygen to the body through the blood in the arteries. It is a muscle that pumps blood throughout the body, and to the lungs to get oxygen. . It is controlled by the autonomic nervous system, and you don't even have to think about it.</p>
<p>The valves control the blood moving in the right directions through the heart.</p>
<p>Un-oxygenated blood goes from the right side of the heart to the lungs and then gets sent back to the left side, where oxygenated blood gets pumped out of the heart through the arteries to the entire body.</p>
<h4>You can think of the heart in terms of these four key functions:</h4>
<ul>
  <li>Arteries - Carry oxygen-filled blood to all the cells of the body.</li>
  <li>Muscle - Pumps the blood to, through, and from the heart.</li>
  <li>Electrical system - Controls the heart rhythm.</li>
  <li>Valves - Regulate the flow of blood through the chambers of the heart.</li>
</ul>
<p>Let’s take a moment to explore these functions and what happens when they aren’t working correctly.</p>
`

const contentCoreConcepts04 = `
<ul class="space-y-6">
  <li>Arteries carry blood out from the heart to other areas of the body.</li>
  <li>Too much cholesterol, high sugars, elevated blood pressure, smoking, lack of sleep, poor diet, and a sedentary lifestyle can negatively damage the lining of the arteries (the endothelium).</li>
  <li>Coronary artery disease develops when the arteries become stiff. Once they become stiff, tears in the lining of the artery can occur from high blood pressure, elevated sugars or high cholesterol, and all the other lifestyle issues, like stress, poor diet, lack of sleep and no exercise.</li>
  <li>Plaque is a buildup of cholesterol, inflammatory cells and proteins that form inside those microtears in the lining of the artery.</li>
  <li>A heart attack happens when the plaque ruptures and blocks the flow of blood to the heart. If you experience chest pain, shortness of breath, jaw pain, back pain, nausea and vomiting, fatigue, and even flu-like symptoms, talk to your health care provider immediately. These could be symptoms that occur when the heart is not getting enough oxygen.</li>
</ul>
`

const contentCoreConcepts05 = `
<ul class="space-y-6">
  <li>The heart muscle pumps blood to and through,your heart to the other parts of the body.</li>
  <li>When the heart is weak or beats irregularly, this can cause a range of problems. The heart muscle can also become inflamed.</li>
  <li>With high blood pressure and lack of exercise, the heart muscle can also become stiff and have difficulty filling..If you experience chest pain, chest pressure, shortness of breath, nausea, vomiting, radiating pain from chest to back, swelling of your ankles, problems laying flat, difficulty with exertion or exercise, talk to your health care provider.</li>
</ul>
`

const contentCoreConcepts06 = `
<p>Just by doing this journey, you are far less likely to have a heart-related illness. But we always want to be prepared, right? So don’t be afraid, be empowered.</p>
<p>It’s important to familiarize yourself with the symptoms of the most urgent heart episodes. Explore the scenarios below so that you have a better sense of when you might need to get yourself straight to the ER or be there to help those you love.</p>
<h4>Heart attack</h4>
<p>Do you know the symptoms of a heart attack?</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/07/img_008.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/07/img_008.png" alt="">
</picture>
<p><b>If you find yourself with any combination of these symptoms, stop what you are doing and call 9-1-1.</b></p>

<h4>Stroke</h4>
<p>Do you know the symptoms of a stroke?</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/07/img_009.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/07/img_009.png" alt="">
</picture>
<p><b>If you find yourself with any combination of these symptoms, stop what you are doing and call 9-1-1.</b></p>
`

const coreConceptsMore = `<h4>coreConceptsMore</h4>`

export const dataCoreConceptsMores: CoreConceptsStops[] = [mapCoreConcepts(9, 'img_003.webp', 'Work', '', coreConceptsMore)]

export const dataCoreConcepts = [
  mapCoreConcepts(1, undefined, 'Your checkup is in a few days. What do you think you need to do to be prepared for your visit?', '', ''),
  mapCoreConcepts(2, 'CC4.webp', 'Make sure your provider has access to any diagnosis or medical records from another doctor:', '', ''),
  mapCoreConcepts(3, 'CC5.webp', 'Write down a list of questions you’d like to ask:', '', ''),
  mapCoreConcepts(4, 'CC6.webp', 'Make sure your provider has access to your medical file. Read the Go Deeper: “10 Things Your Provider Should Know About You” at the end of the session.', '', ''),
  mapCoreConcepts(5, 'CC7.webp', 'Determine if you have a family history of heart disease, including heart attack, stroke, aortic aneurysm, irregular heartbeat, or sudden cardiac death.', '', ''),
  mapCoreConcepts(6, 'CC8.webp', 'All of the above', '', ''),
]

export const dataCC01 = [
  { id: 0, text: 'Make sure your provider has access to any diagnosis, medications, and test results.' },
  { id: 1, text: 'Write down a list of questions you’d like to ask.' },
  { id: 2, text: 'Make sure your provider has access to your medical file.' },
  { id: 3, text: 'Determine if you have a family history of heart disease, including heart attack, stroke, aortic aneurysm, irregular heartbeat, or sudden cardiac death.' },
]

export const dataCoreConcepts2 = [
  mapCoreConcepts(1, 'CC9.webp', '', '', ''),
  mapCoreConcepts(2, 'CC10.webp', 'Provide access to your medical records, allowing them to view it on their own.', '', ''),
  mapCoreConcepts(3, 'CC11.webp', 'Show them your medical records while you explain how your doctor should interpret them.', '', ''),
]

export const dataCC02 = [
  { id: 0, text: 'Provide access to your medical records, allowing them to view it on their own.', result: true },
  { id: 1, text: 'Show them your medical records while you explain how your doctor should interpret them based on your internet research.', result: false },
]

export const dataCoreConcepts3 = [
  mapCoreConcepts(1, 'CC12.webp', 'Your appointment begins.', '', ''),
  mapCoreConcepts(2, 'CC13.webp', 'All of your symptoms – including a long list of both large and small symptoms', '', ''),
  mapCoreConcepts(3, 'CC14.webp', 'Your most important symptoms, including a timeline of how the symptoms have progressed', '', ''),
  mapCoreConcepts(4, 'CC15.webp', 'The diagnosis that you believe you have, from your Internet research of your symptoms', '', ''),
]

export const dataCC03 = [
  { id: 0, text: 'All of your symptoms – including a long list of both large and small symptoms', result: false },
  { id: 1, text: 'Your most important symptoms, including a timeline of how the symptoms have progressed', result: true },
  { id: 2, text: 'The diagnosis that you believe you have, from your internet research of your symptoms', result: false },
]

export const dataCoreConcepts4 = [
  mapCoreConcepts(1, 'CC16.webp', 'The diagnosis that you believe you have, from your Internet research of your symptoms', '', ''),
  mapCoreConcepts(2, 'CC17.webp', 'The whole truth – even if they’re facts you’re not proud of, such as unhealthy diets, smoking, drinking, and/or not exercising.', '', ''),
  mapCoreConcepts(
    3,
    'CC18.webp',
    'Leave out parts of the truth that you don’t feel they need to know – for example, you’re pretty sure your drinking habit has nothing to do with what’s wrong with you, so why tell?',
    '',
    ''
  ),
]

export const dataCC04 = [
  { id: 0, text: 'The whole truth – even if they’re facts you’re not proud of, such as unhealthy diets, smoking, drinking, and/or not exercising', result: true },
  {
    id: 1,
    text: 'Leave out parts of the truth that you don’t feel they need to know – for example, you’re pretty sure your drinking habit has nothing to do with what’s wrong with you, so why tell?',
    result: false,
  },
]

export const dataCoreConcepts5 = [
  mapCoreConcepts(1, 'CC19.webp', '', '', ''),
  mapCoreConcepts(2, 'CC20.webp', 'Tell your provider you would need to understand what’s wrong and if there are other tests you could take.', '', ''),
  mapCoreConcepts(3, 'CC21.webp', 'Tell your provider you would need to understand what’s wrong and if there are other tests you could take.', '', ''),
  mapCoreConcepts(4, 'CC22.webp', 'Tell your provider you would need to understand what’s wrong and if there are other tests you could take.', '', ''),
]

export const dataCC05 = [
  { id: 0, text: 'Tell your provider you would need to understand what’s wrong and if there are other tests you could take.', result: false },
  { id: 1, text: 'Tell your provider they’re wrong and threaten a lawsuit.', result: false },
  { id: 2, text: 'Get a second opinion or find a new provider.', result: true },
]

export const dataCoreConcepts6 = [
  mapCoreConcepts(1, 'CC23.webp', '', '', ''),
  mapCoreConcepts(2, 'CC24.webp', 'Push them for a diagnosis – surely they know it, but they’re just not telling you', '', ''),
  mapCoreConcepts(3, 'CC25.webp', 'Tell them the diagnosis you’d found from the Internet, surely it’s that one?', '', ''),
  mapCoreConcepts(4, 'CC26.webp', 'Name-drop other doctors who were able to provide you with a diagnosis, so why can’t they?', '', ''),
  mapCoreConcepts(5, 'CC27.webp', 'Ask them the questions you’d prepared, such as "When you see symptoms like mine, what general advice would you give me if they persist?', '', ''),
  mapCoreConcepts(6, 'CC28.webp', 'Look for a new provider', '', ''),
]

export const dataCC06 = [
  { id: 0, text: 'Push them for a diagnosis – surely they know it, but they’re just not telling you', result: false },
  { id: 1, text: 'Tell them the diagnosis you’d found from the Internet, surely it’s that one?', result: false },
  { id: 2, text: 'Name-drop other doctors who were able to provide you with a diagnosis, so why can’t they?', result: false },
  { id: 3, text: 'Ask them the questions you’d prepared, such as “what will happen to people who have symptoms like me?”', result: true },
  { id: 4, text: 'Look for a new doctor', result: false },
]

export const dataCoreConcepts7 = [mapCoreConcepts(6, undefined, 'When things go wrong', '', contentCoreConcepts06)]
