import { mapDataModalProgress } from '../maps/session'
import { mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion05Questions: Question[] = [
  mapQuestionNotRequired(
    'ss05__013',
    QuestionTypes.TextArea,
    'Take a moment to reflect on your heart journey and listen to your heart. What was the first thought or image that popped into your mind?',
    'Enter your reflection.'
  ),
  mapQuestionNotRequired('ss05__014', QuestionTypes.TextArea, 'Write down three things that you like about yourself:', 'Enter your reflection.'),
  mapQuestionNotRequired('ss05__015', QuestionTypes.TextArea, 'Write down three things you want to do to bring greater pleasure in your life.', 'Enter your reflection.'),
  mapQuestionNotRequired('ss05__016', QuestionTypes.TextArea, 'Describe what gives you hope:', 'Enter your reflection.'),
  mapQuestionNotRequired('ss05__017', QuestionTypes.TextArea, 'In two sentences or less, describe your purpose in life as it stands right now.', 'Enter your reflection.'),
  mapQuestionNotRequired('ss05__001', QuestionTypes.Rate, 'To what degree do you experience stress at work?'),
  mapQuestionNotRequired('ss05__002', QuestionTypes.Rate, '“My work brings me a sense of pride, purpose and connection.”'),
  mapQuestionNotRequired('ss05__003', QuestionTypes.Rate, 'If you are in one, how satisfied are you with the health of your romantic relationship(s), partnership or marriage?'),
  mapQuestionNotRequired('ss05__b001', QuestionTypes.CheckboxMulti, 'How fulfilled are you with your sex life?', undefined, [
    'I’m lacking sex',
    'It’s ok',
    'I’m lacking sex with a connection',
    'I’m very happy with my sex life',
  ]),
  mapQuestionNotRequired('ss05__b002', QuestionTypes.Rate, 'Do you feel satisfied with the intimacy in your life?'),
  mapQuestionNotRequired('ss05__b003', QuestionTypes.Rate, 'To what degree are you happy with the current state of your relationships with your family and friends?'),
  mapQuestionNotRequired('ss05__b004', QuestionTypes.Rate, 'To what degree do you feel satisfied with your relationships with your community.'),
  mapQuestionNotRequired('ss05__b005', QuestionTypes.Rate, 'To what degree do you agree with this sentence <i>"I have lasting, supportive and nurturing relationships?"</i>'),
  mapQuestionNotRequired('ss05__b006', QuestionTypes.Rate, 'Laughter is the best medicine. To what degree do you agree with this statement:'),
  mapQuestionNotRequired('ss05__b007', QuestionTypes.TextArea, 'Which activities bring you the most joy and pleasure?', 'enter your response'),
  mapQuestionNotRequired('ss05__b008', QuestionTypes.Rate, 'To what degree do you agree with this statement <i>“I have a strong sense of purpose that brings direction to my life."</i>'),
  mapQuestionNotRequired('ss05__b009', QuestionTypes.TextArea, 'Describe how you define your purpose below.', 'enter your response'),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  return { id, img, title, description, content }
}

const contentCoreConcepts01 = `
<p>One of the strongest forms of pleasure in our lives is intimacy and specifically sexual intimacy.
From an emotional perspective, the bonding you experience can lower the heart effects of stress, anxiety and depression. From a physical perspective, the benefits are many. Sex is exercise. It strengthens your heart, lowers your blood pressure, reduces stress and improves sleep.</p>
`

const contentCoreConcepts02 = `
<p>Besides romantic relationships, all relationships can be helpful to your body. When you have someone to talk to about a stressful experience, it can reduce the effects of the fight/flight/freeze/fawn process in your sympathetic nervous system. When someone listens and speaks an encouraging word, you automatically begin to soothe and deescalate the physical effects of stress upon your body.<br /> <br />
If we broaden our view of our relationships this collectively defines itself as our sense of community. For some, your community is your place of worship or your neighborhood. For others, it's the place you get your hair done. Your community is the group of people surrounding you who act as a support system to you.</p>
`

const contentCoreConcepts03 = `
<p>In a similar way to what we just described with relationships, finding activities that reduce your blood pressure and also reduce your heart risk.<br/> <br/>
In one study in Japan, researchers found that those who had a hobby had a reduced rate of cardiovascular disease compared to those that did not. <br/> <br/>
Additionally, laughter has been shown to have a positive correlation with the function of your arteries.
</p>
`

const contentCoreConcepts04 = `
<p>Consider all of these dynamics and areas of people and pleasure. When we do this we start to realize that our reasons for getting up in the morning are very tied to our health and well being. We mentioned in the last session that a sense of purpose has multiple benefits to your health. But what is purpose? Purpose is your reason for being. It’s what you feel you contribute to the world. Purpose provides a sense of meaning when life is mundane or even challenging. It can be your anchor, a sense that you’re working towards a larger societal or environmental goal or it can be the contribution you provide to very specific relationships and people.</p>
`

const contentCoreConcepts06 = `
<div>
  <p>
    The effects of work on your heart can vary depending on the work you do. Sedentary jobs have increased 83 percent since 1950. Research shows that if you spend more than 10 hours sitting, your cardiovascular risk really goes up.
    <br/><br/>In addition to the kind of work you do, the stress of your work environment is another important factor. There is growing evidence that job strain, an imbalance between the rewards you receive from work and the efforts you put out, as well as larger trends of organizational injustice contribute to cardiovascular disease.
    <br/><br/>Sources of stress at work can widely vary. They can range from poor communication between you and a colleague to toxic work culture to injustices in company policy.
  </p>
</div>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>If you have a job where you are sitting most of the day, try working for 20 minutes sitting and then spend 8 minutes standing.</li>
    <li>Focus on one strained relationship at work. Make a list of quick actions you can do to ease that situation.</li>
    <li>Invite a new colleague at work to lunch just to get to know each other a bit more and create a positive connection.</li>
    <li>Find balance in your responsibilities at work by scheduling end times and unplugged times just as you do work meetings.</li>
    <li>Rate your job satisfaction and enjoyment on a scale of 1-10. Journal about what might improve that score and make a commitment to enact that change.</li>
  </ul>
</div>
`

const contentCoreConcepts07 = `
<p>
  Being in a healthy relationship has been shown to provide health benefits, including a longer life. Those in happy marriages or life partnerships generally have lower morbidity rates. In a review of 30 studies involving more than 2 million people, the data showed that people who weren’t married were at a 42% higher risk of developing cardiovascular disease and a 16% higher risk of developing coronary artery disease compared to married people. The study (which did not analyze those in life partnerships) also showed heart risks for those who experienced the stress of divorce.
  <br/>A partner can encourage you towards better habits than the ones you might fall prey to when left on your own. A partner may see early warning signs and encourage you to go see a doctor. They might even be there to save you when you’re in a medical emergency.
</p>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>If you’re in a relationship, invite your person to an impromptu “date” or coffee where you express gratitude (even if it’s a bit tough to do so!)</li>
    <li>If you’re not in a relationship, list the qualities of the person you envision and take a moment to welcome that future person to start to arrive in your life.</li>
    <li>Make a list of three simple doable things you’d like your partner to do to deepen your relationship. Share it and ask them to tell you three you can do as well.</li>
  </ul>
</div>
`

const contentCoreConcepts08 = `
<p>Partnership is tied to intimacy, the feeling of being safe and having someone that helps us alleviate stress.</p>
<p>We can’t talk about intimacy without mentioning sex. Don’t get embarrassed! We’re human and we all have sexual needs.</p>
<p>In 2012 the American Heart Association released its first scientific stance that “sex is important to quality of life.” They also said that “impaired sex is often associated with anxiety, depression, and other psychosocial factors.”</p>
<p>When you have sex, oxytocin is released, making you feel close to your partner and safe. It also releases anti-inflammatories, hormonal painkillers, and immunoglobins that help boost your immune system. Studies suggest that women who report having satisfying sex lives are less likely to have a heart attack.</p>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>Take a moment to rate your sex life now on a scale of 1-10. Be honest with yourself. Then journal about what you’d like it to become and what thoughts may help you get there.</li>
    <li>Schedule a time to talk to your partner about your sexual routine and express your needs.</li>
    <li>Consider what “intimacy” means to you. How can you have an intimate moment like that soon. Make some plans!</li>
  </ul>
</div>
`

const contentCoreConcepts09 = `
<p>Besides our romantic partners, family and friends are the typical places that we may access safety and trust. Research is showing that those who have safe and trusting relationships generally have lower blood pressure and live longer lives than those who do not.</p>
<p>Our family and friends can provide support and they also can present more complexities! Even with those who show you love and support, you may still have stressful communication habits. It is important to focus on communication in a relationship that fosters support and love. Communication is a critical way to express your needs and to find a clear mutual path together that supports each of you to reach a positive relationship.</p>
<p>Other relationships can be draining, unsupportive or even toxic. It is important to identify people that take your energy rather than fuel you. Consider changing that relationship to decrease its strain on you. In some cases that might be as simple as experimenting with a new type of communication to better navigate your interactions. With others you may need to take more concerted efforts such as setting clear boundaries or even limiting your interactions with the individual.</p>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>
      <p>Make a list of your relationships in two columns:</p>
      <ul>
        <li>Bring you stress</li>
        <li>Bring you joy and ease</li>
      </ul>
    </li>
    <li>Connect with an important person who brings you joy that you haven’t seen and make a plan to catch up. Tell them they matter to you.</li>
    <li>Create an exit plan from the relationships that aren’t changing and bring you stress. Determine how you might unravel those relationships and create more space from them.</li>
  </ul>
</div>
`

const contentCoreConcepts10 = `
<p>A three-year Swedish study of more than 13,600 men and women found that having few or no close friends increased the risk of having a first-time heart attack by about 50 percent. A two-year study of more than 500 women with suspected coronary artery disease showed similar results. Women who reported the lowest levels of social support were twice as likely to die during the study. The women who enjoyed close support were not only more likely to be alive after two years, they also had lower rates of high blood pressure and diabetes and were less likely to have excessive abdominal fat.</p>
<p>Close relationships benefit our mind and bodies, so imagine what a network of relationships can do for us. It’s rare that one single relationship can fulfill all of our needs. Having a community of relationships presents many opportunities to provide for others and for them to provide for us. They provide for a network of support in the most extreme of life situations. In the same way that the heart relies on a network, so do we.</p>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>Define “community” for yourself. What groups, clubs, organizations etc are you a part of. Do they intersect? Make a little graph to visualize it.</li>
    <li>Determine which forms of community bring you joy and comfort and alleviate stress.</li>
    <li>Show up for community. Actively join or show interest when someone invites you.</li>
  </ul>
</div>
`

const contentCoreConcepts11 = `
<p>Hobbies, artistic practices, and creativity can all be powerful tools to help us connect with others and with ourselves. They provide a break from work and from stressful situations and a parallel space that allows us to feel free.</p>
<p>In one study, researchers at Drexel University were looking at the effect of creativity on cortisol. Cortisol is a stress hormone released when we activate our fight, flight, freeze, fawn reactions. It causes inflammation and decreases the immune system. It has also been implicated in weight gain. When participants made art (collaging, modeling clay, drawing) they saw that salivary cortisol levels were reduced significantly in 75% of the participants after 45 minutes of art making.</p>
<p>Music can reduce systolic, diastolic blood pressure, and heart rate. Music, writing and artistic improvisation can also increase cognitive flexibility, helping reduce unhelpful stress hormones.</p>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>Write down a list of activities that you already know you enjoy but haven’t been as engaged with lately.</li>
    <li>Write down a list of activities that you are interested in but haven’t ever done.</li>
    <li>Pick 1-2 activities and schedule a time to engage with them in the next two weeks. Look up courses in your area or online or reach out to friends to see if they know of anything or want to try something with you.</li>
  </ul>
</div>
`

const contentCoreConcepts12 = `
<p>A recent analysis by Mount Sinai Medical Center found that a high sense of purpose is associated with a 23 percent reduction in death from all causes and a 19 percent reduced risk of heart attack, stroke, or the need for coronary artery bypass surgery (CABG) or a cardiac stenting procedure.</p>
<p>Developing and refining your sense of purpose could protect your heart health and potentially save your life.</p>
<p>Purpose is your reason for being, and if you don’t see any purpose in living, you can imagine the toll that will take on your heart.</p>
<p>The thing about purpose is that it’s always evolving and changing as we move through various phases in our lives. At one stage your purpose may have been connected to the desire for experiences in your youth or a multitude of friendships or to your profession. Later your purpose might shift to center on your partnership or children. In midlife and later, it can be easier to lose sight of our purpose and with that we become less active, less engaged, more dissatisfied by life. And our heart and our body feels that.</p>
<p>So if you haven’t redefined your purpose, now is the time to do that. Explore some parts of yourself that are waiting to be discovered. Seek out new friendships, experiences, and endeavors that provide a sense of connection. Root yourself in a deep sense of commitment to what you bring about in the world through your everyday existence. The time is now!</p>
<div>
  <h4>What you can do:</h4>
  <ul>
    <li>Schedule a time in the next week to reflect on your purpose at this moment in your life.</li>
    <li>Write down your purpose as a simple statement. Remind yourself daily or weekly. Adapt your purpose as life evolves.</li>
    <li>Write down a list of things you can do to further your purpose. People you need to connect with, experiences you want to have, things you want to learn.</li>
  </ul>
</div>
`

export const dataCoreConcepts = [
  mapCoreConcepts(1, undefined, 'Intimacy and sex.', '', contentCoreConcepts01),
  mapCoreConcepts(2, undefined, 'Friends and family.', '', contentCoreConcepts02),
  mapCoreConcepts(3, undefined, 'Hobbies and creativity.', '', contentCoreConcepts03),
  mapCoreConcepts(4, undefined, 'Purpose.', '', contentCoreConcepts04),
  mapCoreConcepts(5, 'ss_05.mp4', undefined, '', ''),
]

export const dataCoreConceptsMores: CoreConceptsStops[] = [
  mapCoreConcepts(6, 'img_003.webp', 'Work.', '', contentCoreConcepts06),
  mapCoreConcepts(7, 'img_004.webp', 'Partnerships.', '', contentCoreConcepts07),
  mapCoreConcepts(8, 'img_005.webp', 'Sex and intimacy.', '', contentCoreConcepts08),
  mapCoreConcepts(9, 'img_006.webp', 'Friends and family.', '', contentCoreConcepts09),
  mapCoreConcepts(10, 'img_007.webp', 'Community.', '', contentCoreConcepts10),
  mapCoreConcepts(11, 'img_008.webp', 'Hobbies and creativity.', '', contentCoreConcepts11),
  mapCoreConcepts(12, 'img_009.webp', 'Purpose.', '', contentCoreConcepts12),
]
