import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GoDeeperSlugContent } from '../../components/GoDeeper/Slug'

import { useGoDeeperDetailPage } from '../../hooks/pages/go-deeper'

export const GoDeeperDetailPage = () => {
  const { getDataResult, getDataDeeper } = useGoDeeperDetailPage()

  const navigate = useNavigate()
  const { slug } = useParams()

  useEffect(() => {
    if (slug) getDataDeeper(slug)
  }, [])

  const goDeeperDetailPage = useMemo(() => {
    const isLoading = getDataResult?.isLoading || false
    const deeper = getDataResult?.data?.deeper || undefined
    return <GoDeeperSlugContent isLoading={isLoading} deeper={deeper} />
  }, [getDataResult])

  return <>{goDeeperDetailPage}</>
}
