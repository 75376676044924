import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MyAdessoGTCStep } from '../../../../constants/introduction'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { useLocales } from '../../../../locales'
import { ButtonCommon, ButtonStyles } from '../../../Common'
import { GoalTrackingConfigurationContent } from '../../../GoalTrackingConfiguration'
import { ArrowType, BoxText } from '../../../Introduction/BoxText'

interface Props {
  nextScreen: () => void
  closeScreen: () => void
}

const MyAdessoGTCIntroductionScreen = ({ nextScreen, closeScreen }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const { t } = useLocales()
  const [step, setStep] = useState<MyAdessoGTCStep>(MyAdessoGTCStep.WelcomeTour)
  const boxRef = useRef<HTMLDivElement>(null)
  const refs = Object.values(MyAdessoGTCStep).reduce((acc: any, step, index) => {
    if (!isNaN(Number(step))) {
      acc[step] = useRef<HTMLElement>(null)
    }

    return acc
  }, {})

  const [currentRef, setCurrentRef] = useState<any>(refs[MyAdessoGTCStep.WelcomeTour])

  const positionStep = {
    WelcomeTour: 'top',
    TabCommit: '',
    ToggleCommit: '',
    Journal: '',
    BloodPressure: '',
    TrackYourGoals: '',
  }
  const { setShowFeedback } = useHeaderContext()

  const handleNextStep = useCallback((stepCurrent: MyAdessoGTCStep) => {
    setStep(stepCurrent + 1)
  }, [])

  useEffect(() => {
    setShowFeedback(false)
  }, [])

  useEffect(() => {
    if (step === MyAdessoGTCStep.WelcomeTour) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        boxRef.current.style.top = '0px'
      }
      return
    }

    if (!currentRef?.current) {
      // Center vertical popup
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        const boxTop = Math.max(0, window.innerHeight / 2 - (boxRef.current.children[0]?.clientHeight + 51) / 2) - 80
        boxRef.current.style.top = boxTop + 'px'
      }
      return
    }

    currentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    const scrollHeight = currentRef.current.scrollHeight
    const scrollY = window.scrollY
    const { bottom } = currentRef?.current.getBoundingClientRect()

    if (boxRef?.current) {
      switch (step) {
        case MyAdessoGTCStep.TabCommit:
        case MyAdessoGTCStep.ToggleCommit:
        case MyAdessoGTCStep.Journal:
        case MyAdessoGTCStep.BloodPressure:
        case MyAdessoGTCStep.TrackYourGoals:
          boxRef.current.style.top = Math.round(bottom + scrollY - scrollHeight / 2.5 - 40) + 'px'
          break

        default:
          boxRef.current.style.top = Math.round(bottom + scrollY - 80) + 'px'
          break
      }
    }
  }, [step])

  const handleButtonNext = () => {
    handleNextStep(step)
    const stepCurrent = step
    const nextStep = stepCurrent + 1

    if (stepCurrent === MyAdessoGTCStep.TrackYourGoals) {
      nextScreen()
    }

    setCurrentRef(refs[nextStep])
  }

  const contentMyAdessoGTCStep = useMemo(() => {
    switch (step) {
      case MyAdessoGTCStep.WelcomeTour:
        return <BoxText className={`${positionStep.WelcomeTour}`} title={t('introduction.myAdessoGTC.title.welcomeTour')} text={t('introduction.myAdessoGTC.text.welcomeTour')} />
      case MyAdessoGTCStep.TabCommit:
        return <BoxText arrow={ArrowType.Center} arrowClass="" className={`${positionStep.TabCommit}`} text={t('introduction.myAdessoGTC.text.tabCommit')} />
      case MyAdessoGTCStep.ToggleCommit:
        return <BoxText arrow={ArrowType.Right} className={`${positionStep.ToggleCommit}`} text={t('introduction.myAdessoGTC.text.toggleCommit')} />
      case MyAdessoGTCStep.Journal:
        return <BoxText arrow={ArrowType.Left} className={`${positionStep.Journal}`} text={t('introduction.myAdessoGTC.text.journal')} />
      case MyAdessoGTCStep.BloodPressure:
        return <BoxText arrow={ArrowType.Right} className={`${positionStep.BloodPressure}`} text={t('introduction.myAdessoGTC.text.bloodPressure')} />
      case MyAdessoGTCStep.TrackYourGoals:
        return <BoxText arrow={ArrowType.Right} className={`${positionStep.TrackYourGoals}`} text={t('introduction.myAdessoGTC.text.trackYourGoals')} />

      default:
        return <></>
    }
  }, [step])

  const buttonNext = useMemo(() => {
    const marginBottom = step === MyAdessoGTCStep.WelcomeTour ? 'scroll-mb-0' : 'scroll-mb-80'
    return (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        scrollMarginBottom={marginBottom}
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        onClick={() => handleButtonNext()}
      />
    )
  }, [step, handleNextStep])

  const buttonClose = useMemo(() => {
    return (
      <button onClick={closeScreen} className="fixed right-5 top-7 z-50 flex cursor-pointer  items-center justify-center bg-transparent text-white">
        <p className="pr-2 font-['Proxima_Nova'] text-[14px] font-[600] leading-[17px] tracking-[0.15px]">CLOSE DEMO</p>
        <div className=" h-[29px] w-[30px] bg-[url('assets/images/icon/i-button-close.svg')] bg-no-repeat" />
      </button>
    )
  }, [step])

  const introMemo = useMemo(() => {
    switch (step) {
      case MyAdessoGTCStep.WelcomeTour:
      case MyAdessoGTCStep.TabCommit:
      case MyAdessoGTCStep.ToggleCommit:
      case MyAdessoGTCStep.Journal:
      case MyAdessoGTCStep.BloodPressure:
      case MyAdessoGTCStep.TrackYourGoals:
        return <GoalTrackingConfigurationContent tabIntroduction={step === MyAdessoGTCStep.WelcomeTour ? 0 : undefined} refs={refs} isIntroduction />
    }
  }, [step, currentRef])

  return (
    <div className="relative h-full w-full pb-40">
      {introMemo}

      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] bg-color-overlay bg-opacity-50"></div>
      {buttonClose}
      <div ref={boxRef} className="absolute left-0 top-[-74px] z-50 flex h-[calc(100%+74px)] w-full flex-col items-center space-y-4 px-4 font-['Proxima_Nova']">
        {contentMyAdessoGTCStep}
        {buttonNext}
      </div>
    </div>
  )
}

export default MyAdessoGTCIntroductionScreen
