import { useMemo } from 'react'

import { ForgotPasswordSentContent } from '../components/ForgotPassword/ForgotPasswordSent'

export const ForgotPasswordSentPage = () => {
  const pageContent = useMemo(() => {
    return <ForgotPasswordSentContent />
  }, [])

  return <>{pageContent}</>
}
