import { useMemo, ChangeEvent } from 'react'
import { Question, FieldTypes } from '../../../models'
import { useGoalTrackingConfigPage } from '../../../hooks/pages/goal-tracking-config'

type Props = {
  id?: string
  value?: string
  question: Question
  fieldType: FieldTypes
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onUpdate: (data: any) => void
}

export const RadioFieldGoalTracking = ({ question, id, value, fieldType, onChange, onUpdate }: Props) => {
  const { goalSettingIdResult, updateGoalSettingId } = useGoalTrackingConfigPage()
  const isDarkType: boolean = fieldType === FieldTypes.Dark

  const handleOption = async (id: any, option: any) => {
    const data = await updateGoalSettingId(id, {
      is_enable: true,
      type: option,
    })
    onUpdate(data)
  }

  const radioList = useMemo(() => {
    return question.options
      ? question.options.map((option, index) => {
          const idRadio = `${question.name}-${index}`
          const background = isDarkType ? `bg-[url('assets/images/icon/i-radio__uncheck.svg')]` : `bg-[url('assets/images/icon/i-radio__uncheck__white.svg')]`
          return (
            <div key={idRadio}>
              <input
                id={idRadio}
                type="radio"
                name={question.name}
                className="peer hidden"
                value={option.label}
                onChange={() => handleOption(question?.id, option?.value)}
                checked={option?.value === (goalSettingIdResult ? goalSettingIdResult?.data?.goalSettingId?.type : question?.user_settings[0]?.type)}
              />

              <label
                htmlFor={idRadio}
                className={`flex cursor-pointer bg-[length:21px_auto] bg-[top_1px_left] bg-no-repeat pl-7 pt-[1px] text-ma-md peer-checked:bg-[url('assets/images/icon/i-radio__checked.svg')] ${background}`}
              >
                {option.label}
              </label>
            </div>
          )
        })
      : null
  }, [goalSettingIdResult, isDarkType, value, question])

  return <div className="space-y-3">{radioList}</div>
}
