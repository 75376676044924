import { IntroductionQuizess, OptionValue, Question, QuestionOption, QuestionTypes, Quizess, ScreenQuizess } from '../../models/question'
import { mapQuestion } from './question'

const introduction01: IntroductionQuizess = {
  title: 'Tell Us About Yourself:',
  description: `Who are you? These factors help us understand a bit about your life. It is also important for us to understand your genetics and ethnicity. <br /><br /> Your ethnic background matters. Black women have the highest prevalence of heart disease, with 50% of Black women having some form of heart diseases by age 20. South Asian women, including women from Indian and Filipino descent, also have an increased risk of heart disease. We need to know to understand who you are a bit better!`,
}

const introduction02: IntroductionQuizess = {
  title: 'Lifestyle Choices:',
  description: `Heart health is based on many risk factors. 80% of the time heart disease is preventable based on your lifestyle choices. How you eat, how you move, how you sleep and how you manage stress are some of the critical factors of a healthy lifestyle.<br /><br />These are the factors we can control.<br /><br />Let’s see where you are today, before we start on this wellness journey together.`,
}

const introduction03: IntroductionQuizess = {
  title: 'Your Feelings And Mental Health:',
  description: `Stress is just a part of our lives, but how we respond to it matters. Chronic stress or intense stress over time can affect our hearts and our health. Depression is twice as common in women, and those with depression are at an 2 to 3 times greater risk than women who are not depressed. The most important thing is knowing how you feel and understanding how to manage it.`,
}

const introduction04: IntroductionQuizess = {
  title: 'The Women Stuff:',
  description: `Did you know that if you had complications with pregnancy that you are at greater risk of heart disease? These “adverse outcomes of pregnancy” can increase your risk of heart disease later in life.
  <br /><br /> And of course, we need to talk about menopause. Going into menopause at an earlier age and/or having significant symptoms impacts your overall heart health.
  <br /><br /> Tell us where you are at in your hormonal journey.`,
}

const introduction05: IntroductionQuizess = {
  title: 'Your Family History:',
  description: `Remember, 80% of the time heart disease is preventable, but we have to start early, especially when you have a family history of heart disease.
  <br><br>Let’s figure out how your family’s heart history may affect your own.`,
}

const introduction06: IntroductionQuizess = {
  title: 'Past Medical History:',
  description: `If you have had any issues with your heart in the past, or any risk factors of heart disease, then you are at a greater risk.
  <br /><br />Let’s learn more about your past medical history as it pertains to your heart.`,
}

const introduction07: IntroductionQuizess = {
  title: 'Know Your Numbers:',
  description: `Your objective test results tell us a great deal about your risk of heart disease. If you do not know your blood pressure or lab values, this will affect your score results and they will be less accurate. Once you get your test results, this information will make your Adesso Heart Score more accurate. So, come back once you get your results!
Do you know your lab numbers?`,
}

const introduction08: IntroductionQuizess = {
  title: 'The Bottom Of Your Heart:',
  description: `This final section is the most important as we assess past and present problems with your heart.
  <br /><br />Please answer honestly all that apply to you.`,
}

const optionYesNo = ['YES', 'NO']

const quizzes01Screen01: ScreenQuizess = {
  title: 'Let’s start with the basics:',
  questions: [
    mapQuestion('hs_001', QuestionTypes.Radio, 'What is your current age?', undefined, ['Less than 49 years old', '50-65 years old', 'Greater than 65 years old']),
    mapQuestion('hs_002', QuestionTypes.Radio, 'What is your marital status:', undefined, ['Single', 'Partnered', 'Divorced', 'Widowed']),
    mapQuestion('hs_003', QuestionTypes.Radio, 'Caretaker:', undefined, ['Parent', 'Children', 'Both', 'No']),
    mapQuestion('hs_004', QuestionTypes.Radio, 'Gender at birth:', undefined, ['Female', 'Male']),
    mapQuestion('hs_005', QuestionTypes.Radio, 'What is your ethnicity:', undefined, ['White', 'Black', 'Hispanic', 'Native American', 'Asian', 'South Asia', 'Pacific Islander']),
  ],
}

const quizzes02Screen01: ScreenQuizess = {
  title: 'Smoking and Drinking',
  questions: [
    mapQuestion('hs_006', QuestionTypes.Radio, 'Have you ever been a smoker:', undefined, optionYesNo),
    mapQuestion('hs_007', QuestionTypes.Radio, 'Have you smoked in the past 5 years?', undefined, optionYesNo, false, {
      parentName: 'hs_006',
      value: optionYesNo[0],
    }),
    mapQuestion('hs_008', QuestionTypes.Radio, 'Are you taking oral contraceptives?', undefined, optionYesNo, false, {
      parentName: 'hs_006',
      value: optionYesNo[0],
    }),
    mapQuestion('hs_009', QuestionTypes.Radio, 'Do you use VAPE products?', undefined, optionYesNo),
    mapQuestion('hs_011', QuestionTypes.Radio, 'How often do you drink?', undefined, ['Less than 14 drinks a week', 'More than 14 drinks a week', 'More than 5 drinks in one sitting']),
  ],
}

const quizzes02Screen02: ScreenQuizess = {
  title: 'Weight',
  questions: [mapQuestion('hs_012', QuestionTypes.MBI), mapQuestion('hs_013', QuestionTypes.Radio, 'What is your pants size?', undefined, ['< 35 inches', '>= 35 inches'])],
}

const quizzes02Screen03: ScreenQuizess = {
  title: 'Exercise',
  questions: [
    mapQuestion('hs_014', QuestionTypes.Radio, 'What is your weekly exercise routine?', undefined, [
      'Fairly sedentary. No formal exercise.',
      'At least 75mins/week of vigorous physical activity, or 150mins/week of moderately intense physical activity/week',
      '75mins/week vigorous physical activity or less than 150mins/week moderately intense physical activity/week, with intentional exercise',
      'No formal exercise, but leading a very active life',
    ]),
  ],
}

const quizzes02Screen04: ScreenQuizess = {
  title: 'Diet',
  questions: [
    mapQuestion('hs_015', QuestionTypes.CheckboxMulti, 'What is your daily diet?', undefined, [
      'Mediterranean/Vegetarian',
      'Typical American',
      'High-fat diet',
      'Mostly healthy with some “cheat days”',
      'Fast food, high salt, or processed food',
      'Beverage of choice is soda',
    ]),
  ],
}

const quizzes02Screen05: ScreenQuizess = {
  title: 'Sleep',
  questions: [mapQuestion('hs_016', QuestionTypes.Radio, 'On average how many hours of sleep do you get each night?', undefined, ['Less than 7 hrs/night', '7-9 hrs/night', 'More than 9 hrs/night '])],
}

const quizzes03Screen01: ScreenQuizess = {
  title: 'Stress, Anxiety, Depression',
  questions: [
    mapQuestion('hs_017', QuestionTypes.Radio, 'How would you rate your overall depression?', undefined, [
      'Little to none.',
      'I have good and bad days.',
      'It is something I have to pay attention to and address.',
      'I am being treated for clinical depression.',
    ]),
    mapQuestion('hs_018', QuestionTypes.Radio, 'How would you rate your overall anxiety?', undefined, [
      'Little to none.',
      'I have good and bad days.',
      'I have a lot of anxiety, but I have tools to manage it.',
      'I find my anxiety debilitating.',
    ]),
    mapQuestion('hs_019', QuestionTypes.Radio, 'Do you have a history of PTSD?', undefined, optionYesNo),
    mapQuestion('hs_020', QuestionTypes.Radio, 'How would you rate your overall stress?', undefined, [
      'In the last month I have had very little stress.',
      'Some days are more stressful than others.',
      'I deal with stress regularly, but I have the tools to manage it.',
      'The stress is overwhelming and relentless.',
    ]),
  ],
}

const quizzes03Screen02: ScreenQuizess = {
  title: 'Food Insecurity',
  questions: [
    mapQuestion('hs_021', QuestionTypes.Radio, 'Do you experience food insecurity?', undefined, [
      'I don’t have any issues.',
      'I have to keep a tight budget to feed myself and my family.',
      'From time to time, we must miss a meal or eat unhealthy foods.',
      'I can not afford a balanced diet for myself or my family.',
    ]),
  ],
}

const quizzes03Screen03: ScreenQuizess = {
  title: 'Job Stress',
  questions: [
    mapQuestion('hs_022', QuestionTypes.Radio, 'What is your job like?', undefined, [
      'I love my job.',
      'I like what I do, but do not feel appreciated or valued.',
      'I am working hard, including after hours, with little time to myself or little reward.',
      'Work is all-consuming and I dread having to be there.',
      'I am unemployed.',
      'I am retired.',
    ]),
  ],
}

const quizzes03Screen04: ScreenQuizess = {
  title: 'Financial Stress',
  questions: [
    mapQuestion('hs_023', QuestionTypes.Radio, 'Do you experience financial stress?', undefined, [
      'I have no financial stress.',
      'I have to watch my money but am doing fine.',
      'I have a tight budget but there is a lot I can’t afford.',
      'I am behind with my finances.',
    ]),
  ],
}

const quizzes03Screen05: ScreenQuizess = {
  title: 'Joy/Purpose',
  questions: [
    mapQuestion('hs_023', QuestionTypes.Radio, 'Which situation most describes you?', undefined, [
      'I am living with purpose and passion.',
      'I have times of joy in life and am trying to have purpose.',
      'I feel like joy is hard to find.',
      'I feel that most of what I do is trivial and unimportant.',
    ]),
  ],
}

const quizzes04Screen01: ScreenQuizess = {
  title: 'Joy/Purpose',
  questions: [
    mapQuestion('hs_024', QuestionTypes.CheckboxMulti, 'In your OB/GYN history, check those that you have had:', undefined, [
      'Premature birth (<35 weeks)',
      'Polycystic ovarian syndrome',
      'Gestational Diabetes',
      'Elevated sugars during pregnancy',
      'Preeclampsia',
      'High blood pressure during pregnancy',
      'Placenta Previa',
      'Miscarriage',
    ]),
    mapQuestion('hs_025', QuestionTypes.CheckboxMulti, undefined, undefined, ['Greater than 2 miscarriages'], false, {
      parentName: 'hs_024',
      value: 'Miscarriage',
    }),
  ],
}

const quizzes04Screen02: ScreenQuizess = {
  title: 'Menopause',
  questions: [
    mapQuestion('hs_026', QuestionTypes.Radio, 'Are you in menopause?', undefined, ['YES', 'Perimenopause', 'NO']),
    mapQuestion(
      'hs_027',
      QuestionTypes.CheckboxMulti,
      undefined,
      undefined,
      ['More than 10 years post-menopause.', 'Began menopause before the age of 45.', 'On Hormone Replacement Therapy for more than 10 years.', 'Experiencing consistent hot flashes.'],
      false,
      { parentName: 'hs_026', value: 'YES' }
    ),
  ],
}

const optionOlds = ['less than 55 years old', 'older than 55 years old']
const optionGrandparents = ['Paternal Grandmother', 'Paternal Grandfather', 'Maternal Grandmother', 'Maternal Grandfather']

const quizzes05Screen01: ScreenQuizess = {
  title: 'Family History of Heart Disease',
  questions: [
    mapQuestion('hs_028', QuestionTypes.CheckboxMulti, 'Please check those in your family who had a history of a heart attack.', undefined, ['Siblings'], false),
    mapQuestion('hs_029', QuestionTypes.CheckboxMulti, undefined, undefined, ['Father'], false),
    mapQuestion('hs_030', QuestionTypes.Radio, undefined, undefined, optionOlds, false, {
      parentName: 'hs_029',
      value: 'Father',
    }),
    mapQuestion('hs_031', QuestionTypes.CheckboxMulti, undefined, undefined, ['Mother'], false),
    mapQuestion('hs_032', QuestionTypes.Radio, undefined, undefined, optionOlds, false, {
      parentName: 'hs_031',
      value: 'Mother',
    }),
    mapQuestion('hs_033', QuestionTypes.CheckboxMulti, undefined, undefined, ['Grandparents'], false),
    mapQuestion('hs_034', QuestionTypes.CheckboxMulti, undefined, undefined, optionGrandparents, false, {
      parentName: 'hs_033',
      value: 'Grandparents',
    }),
    mapQuestion('hs_035', QuestionTypes.CheckboxMulti, 'Do you have a family history of any of the following (check all the apply):', undefined, [
      'Sudden Cardiac Death',
      'Aortic Aneurysm',
      'Atrial Fibrillation',
      'Genetic Hypercholesterolemia or Lp(a)',
    ]),
  ],
}

const optionQuizzes06 = ['treated', 'untreated']
const quizzes06Screen01: ScreenQuizess = {
  title: 'Past Heart History',
  questions: [
    mapQuestion(
      'hs_036',
      QuestionTypes.CheckboxMulti,
      'Check all of those that pertain to you:',
      undefined,
      ['COVID-19', 'Sleep apnea', 'Kidney disease', 'Autoimmune disease', 'On steroids/prednisone', 'Aspirin Treatment'],
      false
    ),

    mapQuestion('hs_037', QuestionTypes.Checkbox, undefined, undefined, ['Diabetes'], false),
    mapQuestion('hs_038', QuestionTypes.Radio, undefined, undefined, ['treated', 'insulin', 'untreated'], false, {
      parentName: 'hs_037',
      value: 'Diabetes',
    }),

    mapQuestion('hs_039', QuestionTypes.Checkbox, undefined, undefined, ['Elevated blood sugars/Metabolic Syndrome'], false),
    mapQuestion('hs_040', QuestionTypes.Radio, undefined, undefined, optionQuizzes06, false, {
      parentName: 'hs_039',
      value: 'Elevated blood sugars/Metabolic Syndrome',
    }),

    mapQuestion('hs_041', QuestionTypes.Checkbox, undefined, undefined, ['High LDL or high cholesterol'], false),
    mapQuestion('hs_042', QuestionTypes.Radio, undefined, undefined, optionQuizzes06, false, {
      parentName: 'hs_041',
      value: 'High LDL or high cholesterol',
    }),

    mapQuestion('hs_043', QuestionTypes.Checkbox, undefined, undefined, ['High triglycerides'], false),
    mapQuestion('hs_044', QuestionTypes.Radio, undefined, undefined, optionQuizzes06, false, {
      parentName: 'hs_043',
      value: 'High triglycerides',
    }),

    mapQuestion('hs_045', QuestionTypes.Checkbox, undefined, undefined, ['High blood pressure'], false),
    mapQuestion('hs_046', QuestionTypes.Radio, undefined, undefined, optionQuizzes06, false, {
      parentName: 'hs_045',
      value: 'High blood pressure',
    }),
  ],
}

const quizzes07Screen01: ScreenQuizess = {
  title: 'Enter your lab numbers. If you don’t know the exact number select the one you think is closest. If you don’t know one, you can leave it blank.',
  questions: [
    mapQuestion('hs_046', QuestionTypes.RadioDropdown, 'Blood Pressure', undefined, ['<120/80', '>130/80 - 139/89', '>140/90 - 159/99', '>160/100'], false),
    mapQuestion('hs_047', QuestionTypes.RadioDropdown, 'Cholesterol', undefined, ['<120/80', '>130/80 - 139/89', '>140/90 - 159/99', '>160/100'], false),
    mapQuestion('hs_048', QuestionTypes.RadioDropdown, 'LDL', undefined, ['<120/80', '>130/80 - 139/89', '>140/90 - 159/99', '>160/100'], false),
    mapQuestion('hs_049', QuestionTypes.RadioDropdown, 'HDL', undefined, ['<120/80', '>130/80 - 139/89', '>140/90 - 159/99', '>160/100'], false),
    mapQuestion('hs_050', QuestionTypes.RadioDropdown, 'Triglycerides', undefined, ['<150', '151–199', '200–499', '>=500'], false),
    mapQuestion('hs_051', QuestionTypes.RadioDropdown, 'Lp(a)', undefined, ['<30', '31–50', '>50', '>70mg/dL'], false),
  ],
}

const quizzes07Screen02: ScreenQuizess = {
  title: 'Enter your test numbers. If you don’t know them, skip to the next question.',
  questions: [
    mapQuestion('hs_052', QuestionTypes.RadioDropdown, 'ApoE', undefined, ['E2/3', 'E3/3', 'E3/4', 'E4/4'], false),
    mapQuestion('hs_053', QuestionTypes.RadioDropdown, 'Hemoglobin A1C', undefined, ['<5.7', '5.7-6.4', '>6.5'], false),
    mapQuestion('hs_054', QuestionTypes.RadioDropdown, 'Calcium Score', undefined, ['0-10', '11-100', '101-400', '>400'], false),
    mapQuestion('hs_055', QuestionTypes.RadioDropdown, 'Carotid artery dopplers', undefined, ['Normal', 'Carotid intima-medial thickening (CIMT), < 25%', '25-49%', '50-69% ', '>70%'], false),
    mapQuestion('hs_056', QuestionTypes.RadioDropdown, 'Elevated Calcium Score', undefined, ['Normal', 'Carotid intima-medial thickening (CIMT), < 25%', '25-49%', '50-69% ', '>70%'], false),
  ],
}

const quizzes08Screen01: ScreenQuizess = {
  title: 'Past Heart History:',
  subTitle:
    'If you have any heart history, it is important for us to know, so we can best guide you in your care. For some, you may have made significant changes after these events and lowered your risk. For others, they may require extra attention now. It is important to be aware of past disease, which heightens the risk of future problems.',
  questions: [
    mapQuestion(
      'hs_057',
      QuestionTypes.CheckboxMulti,
      'Please let us know if you have had any of these issues:',
      undefined,
      ['Heart Attack', 'Stroke/TIA', 'Stent/PTCA', 'Coronary Artery Bypass Grafting (CABG)', 'Peripheral Vascular Disease', 'Aortic Aneurysm', 'Atrial Fibrillation ', 'Pacemaker'],
      false
    ),
  ],
}

const quizzes08Screen02: ScreenQuizess = {
  title: 'Heart Symptoms:',
  subTitle:
    'It matters most if you have any symptoms when you are just going about your life and getting through your days. Sometimes we don’t even pay attention to how we feel. Let’s just take a moment to reflect on that. Think about how you have been feeling and if your body might be trying to tell you something. Sometimes we just need to listen!',
  questions: [
    mapQuestion(
      'hs_057',
      QuestionTypes.CheckboxMulti,
      'Check all the apply:',
      undefined,
      ['Chest discomfort', 'Palpitations or pounding heart', 'Inability to have sexual relations', 'Nausea or vomiting with exertion', 'Lightheadedness'],
      false
    ),
  ],
}

const quizzes08Screen03: ScreenQuizess = {
  title: 'Heart Symptoms:',
  questions: [
    mapQuestion(
      'hs_058',
      QuestionTypes.CheckboxMulti,
      'The following symptoms are strongly associated with heart disease. Do you feel any of these symptoms (check all the apply):',
      undefined,
      [
        'Chest pain on exertion',
        'Pain radiating to shoulder, neck, jaw or back',
        'Shortness of breath, not associated with exertion',
        'Unable to walk a block without stopping',
        'Unable to walk 2 flights of stairs/uphill',
        'Inability to do moderate recreational activity due to fatigue',
      ],
      false
    ),
  ],
}

const quizzes08Screen04: ScreenQuizess = {
  title: 'Heart Symptoms:',
  subTitle:
    'You are experiencing symptoms that could be related to heart disease. Too often symptoms go ignored leading to greater risk of cardiovascular disease. It is generally advisable when symptoms are present to schedule an appointment with your primary care provider for further testing.',
  questions: [],
}

const quizzes01: ScreenQuizess[] = [quizzes01Screen01]
const quizzes02: ScreenQuizess[] = [quizzes02Screen01, quizzes02Screen02, quizzes02Screen03, quizzes02Screen04, quizzes02Screen05]
const quizzes03: ScreenQuizess[] = [quizzes03Screen01, quizzes03Screen02, quizzes03Screen03, quizzes03Screen04, quizzes03Screen05]
const quizzes04: ScreenQuizess[] = [quizzes04Screen01, quizzes04Screen02]
const quizzes05: ScreenQuizess[] = [quizzes05Screen01]
const quizzes06: ScreenQuizess[] = [quizzes06Screen01]
const quizzes07: ScreenQuizess[] = [quizzes07Screen01, quizzes07Screen02]
const quizzes08: ScreenQuizess[] = [quizzes08Screen01, quizzes08Screen02, quizzes08Screen03, quizzes08Screen04]

export const dummyQuizess: Quizess[] = [
  { introduction: introduction01, screens: quizzes01 },
  { introduction: introduction02, screens: quizzes02 },
  { introduction: introduction03, screens: quizzes03 },
  { introduction: introduction04, screens: quizzes04 },
  { introduction: introduction05, screens: quizzes05 },
  { introduction: introduction06, screens: quizzes06 },
  { introduction: introduction07, screens: quizzes07 },
  { introduction: introduction08, screens: quizzes08 },
]

export function mapAPIOptions(options: any[]): QuestionOption<OptionValue>[] | undefined {
  return (
    options &&
    options.map((option, index) => {
      return { value: index, label: option.content || '', is_correct: option.is_correct, score: option.score }
    })
  )
}

export function mapAPIQuestionTypes(type: string): QuestionTypes | undefined {
  switch (type) {
    case 'radio-dropdown':
      return QuestionTypes.RadioDropdown
    case 'radio':
      return QuestionTypes.Radio
    case 'checkbox-multi':
      return QuestionTypes.CheckboxMulti
    case 'checkbox':
      return QuestionTypes.Checkbox
    case 'Caculate MBI':
      return QuestionTypes.MBI
    case 'range':
      return QuestionTypes.Rate
    case 'input':
      return QuestionTypes.Input
    case 'hidden':
      return QuestionTypes.InputHidden
    case 'table':
      return QuestionTypes.TableQuestion
    case 'input-hidden':
      return QuestionTypes.InputHidden
    case 'label':
      return QuestionTypes.Label
    case 'fill_blank':
      return QuestionTypes.FillBlank
    default:
      return undefined
  }
}

const arrOptionsType = [QuestionTypes.RadioDropdown, QuestionTypes.CheckboxMulti, QuestionTypes.Radio]

function mapHSQuestion(q: any, index: number): Question | undefined {
  if (q.name && q.type) {
    const type = mapAPIQuestionTypes(q.type)

    if (type) {
      let options: QuestionOption<OptionValue>[] | undefined = undefined
      if (arrOptionsType.includes(type) && q.options) {
        options = mapAPIOptions(q.options)
      }

      let parent: { value: string; parentName: string } | undefined = undefined
      if (q.parent && q.parent.value && q.parent.parentName) {
        parent = { value: q.parent.value, parentName: q.parent.parentName }
      }

      const hidden = (!!parent && q.hidden === 1) || false
      const isRequired = (!hidden && q.isRequired === 1) || false

      return { id: index, name: q.name, type, question: q.question || undefined, isRequired, hidden, parent, options, content_type: q.content_type }
    }
  }

  return undefined
}

function mapHSIntroduction(introduction: any): IntroductionQuizess | undefined {
  return introduction
    ? {
        id: introduction.id ? +`${introduction.id}` : undefined,
        title: introduction.title || '',
        description: introduction.description || '',
        background: introduction.background || undefined,
      }
    : undefined
}

function mapHSScreens(screens: any): ScreenQuizess[] | undefined {
  return screens
    ? screens.map((screen: any, index: number) => {
        const questions = screen.questions.map((question: any) => {
          const questionReturn = mapHSQuestion(question, index)
          if (questionReturn) return questionReturn
        })

        if (questions) {
          return {
            isRequired: screen.isRequired || false,
            title: screen.title || undefined,
            subTitle: screen.subTitle || undefined,
            more: screen.more || undefined,
            questions,
            screen_type: screen.screen_type || undefined,
            content_data: screen.content_data || undefined,
          }
        }
      })
    : undefined
}

export function mapDataHeartScoreQuestions(data: any[]): Quizess[] {
  if (data.length > 0) {
    let dataReturn: Quizess[] = []

    data.forEach((quizzes) => {
      const introduction = mapHSIntroduction(quizzes.introduction || undefined)
      const screens = mapHSScreens(quizzes.screens || undefined)
      const answers = quizzes.answers || undefined

      if (introduction && screens) {
        dataReturn = [...dataReturn, { introduction, screens, answers }]
      }
    })

    if (dataReturn) return dataReturn
  }

  return []
}
