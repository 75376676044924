import { useEffect, useMemo, useState } from 'react'
import { useAlert } from '../../../context/AlertContext'
import { useContextSupplementRx } from '../../../context/supplementRxContext'
import { useBoolean } from '../../../hooks/use-boolean'
import { useSupplementRxPage } from '../../../hooks/use-supplement-rx'
import { Loading } from '../../Common'
import { ResponsiveWrapper } from '../../Common/ResponsiveWrapper'
import { ButtonMyHealth } from '../Button'
import WrapperMyHealth from '../MyHealthWrapper'
import AddSupplement from './AddSupplement'
import SetAlarm from './SetAlarm'
import TrackSupplement from './TrackSupplement'

type Props = {
  prevScreen?: () => void
  nextScreen?: () => void
}

const SupplementRx = ({ nextScreen, prevScreen }: Props) => {
  const { supplementRxResult, supplementRxResultById, useGetSupplementRx, useGetByIdSupplementRx, usePutSupplementRx } = useSupplementRxPage()

  const { addDataSupplementRx, addDataSupplement } = useContextSupplementRx()

  const dataSupplementId = supplementRxResultById?.data?.supplements

  const listSupplement = supplementRxResult?.data?.supplements

  const [screen, setScreen] = useState(-1)

  const [dataSupplement, setDataSupplement] = useState<any>([])

  const [idSupplement, setIdSupplement] = useState<number>()

  const isLoadingClickEdit = useBoolean()

  const { setAlertError } = useAlert()

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = () => {
    setScreen((prev) => prev + 1)
  }

  const handleSupplementById = async (id: number) => {
    setIdSupplement(id)
    try {
      isLoadingClickEdit.onTrue()
      await useGetByIdSupplementRx(id)
    } catch (error) {
      isLoadingClickEdit.onFalse()
      setAlertError(`${error}`)
      return { message: 'Error' }
    } finally {
      isLoadingClickEdit.onFalse()
      handleNextScreen()
    }
  }

  const handleApprovedByPhysician = (e: any, item: any, index: number) => {
    try {
      // e.preventDefault()
      setDataSupplement((prev: any) => {
        const clar = [...prev]
        clar[index] = { ...item, approved_by_physician: !item.approved_by_physician }
        return clar
      })

      delete item.alarm_id
      usePutSupplementRx(item.id, { ...item, approved_by_physician: !item.approved_by_physician })
    } catch (error) {
      setAlertError(`${error}`)
    }
  }

  useEffect(() => {
    setDataSupplement(listSupplement)
  }, [supplementRxResult?.data, screen])

  // useEffect(() => {
  //   if (supplementRxResult?.data) return
  //   useGetSupplementRx()
  // }, [supplementRxResult?.data])

  useEffect(() => {
    if (screen === -1) {
      useGetSupplementRx()
    }
  }, [screen])

  useEffect(() => {
    addDataSupplement(dataSupplementId)
  }, [supplementRxResultById?.data?.supplements, dataSupplementId])

  const myRecommentdations = useMemo(() => {
    return (
      <div className="flex flex-col gap-2">
        <h5 className="font-proxima-nova text-[15px] font-semibold uppercase leading-5">MY RECOMMENDATIONS</h5>
        {dataSupplement?.length > 0 ? (
          <div className="flex flex-col gap-4">
            {dataSupplement.map((item: any, index: any) => {
              return (
                <div key={index} className="w-full space-y-4 rounded-[20px] border-solid border-[#D4D4D4] bg-[#F9F7F7] p-4 shadow-lg shadow-gray-400">
                  <div className="flex flex-row justify-between gap-2 whitespace-normal">
                    <h4 className="max-w-[15%] truncate break-all text-[18px] font-semibold md:max-w-[50%]">{item?.prescription_name}</h4>
                    <div className="flex flex-row justify-between gap-4 break-all">
                      <h6 className={`${item?.approved_by_physician ? '' : 'text-[#F23581]'}`}>Approved By Physician</h6>
                      <div>
                        <label className="inline-flex cursor-pointer items-center">
                          <input
                            id={item?.id}
                            name={item?.approved_by_physician}
                            type="checkbox"
                            value=""
                            className="peer sr-only"
                            checked={item?.approved_by_physician}
                            onChange={(e) => handleApprovedByPhysician(e, item, index)}
                          />
                          <div
                            className={`${
                              item?.approved_by_physician
                                ? 'after:border-color-checkbox after:bg-color-checkbox peer-focus:ring-pink-300'
                                : 'after:border-[#FFF] after:bg-[#FFF] after:shadow-lg peer-focus:ring-gray-300'
                            } after:start-[0px] peer relative h-7 w-14 rounded-full bg-gray-200 after:absolute after:top-[-2px] after:h-8 after:w-8 after:rounded-full after:transition-all after:content-[''] peer-checked:bg-[#FBB3D7] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800`}
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="whitespace-normal">
                    <p className="truncate break-all text-[#666666]">{item?.how_to_take}</p>
                  </div>
                  <div className="flex w-[60px] cursor-pointer flex-row items-center gap-2 text-[#EE2B7E]" onClick={() => handleSupplementById(item?.id)}>
                    <div className='h-[20px] w-[20px] bg-[url("assets/images/icon/i-edit.svg")] bg-no-repeat'></div>
                    <h6>Edit</h6>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div>
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <p className="font-proxima-nova text-[14px] font-medium">Do you have other supplements you're now taking? Feel free to add them all here so you can track them all</p>
            </div>
          </div>
        )}
      </div>
    )
  }, [supplementRxResult, supplementRxResult?.data, dataSupplement, screen, idSupplement])

  const button = useMemo(() => {
    return (
      <>
        <ButtonMyHealth
          nameButton="ADD YOUR SUPPLEMENTs"
          bgButton="bg-color-bg-primary"
          nextScreen={() => {
            // setIdSupplement(undefined)
            addDataSupplement({ prescription_name: '', how_to_take: '', single_dose: '', times_per_day: '', approved_by_physician: false })
            handleNextScreen()
          }}
        />
        <ButtonMyHealth nameButton="Track Supplement Usage" bgButton="bg-color-violet" nextScreen={() => setScreen(3)} />
      </>
    )
  }, [screen, idSupplement])

  const screenSupplementRx = useMemo(() => {
    switch (screen) {
      case 0:
        return supplementRxResult?.isLoading ? <Loading /> : <AddSupplement prevScreen={handlePrevScreen} nextScreen={handleNextScreen} />
      case 1:
        return <SetAlarm prevScreen={handlePrevScreen} />
      case 3:
        return <TrackSupplement />
      default:
        return (
          <WrapperMyHealth title="supplementsRx" prevScreen={prevScreen} button={button}>
            {myRecommentdations}
          </WrapperMyHealth>
        )
    }
  }, [screen, supplementRxResult?.data, dataSupplement, supplementRxResultById?.data, dataSupplementId, idSupplement])

  return <ResponsiveWrapper>{supplementRxResult?.isLoading ? <Loading /> : isLoadingClickEdit.value ? <Loading /> : screenSupplementRx}</ResponsiveWrapper>
}

export default SupplementRx
