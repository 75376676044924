import axios from 'axios'
import { AdessoError } from '../error'

type GetDataLifeStyleResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getOptionsOfLifeStyleRx(): Promise<GetDataLifeStyleResponse> {
  const { data, status } = await axios.get(`/lifestyles/options`)

  if (status !== 200) {
    throw new AdessoError(`Request getOptionsOfLifeStyleRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDataLifeStyleResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function getLifeStyleRx(): Promise<GetDataLifeStyleResponse> {
  const { data, status } = await axios.get(`/lifestyles`)

  if (status !== 200) {
    throw new AdessoError(`Request get LifeStyle error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDataLifeStyleResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
