import { useCallback, useMemo } from 'react'
import { ProgressItem } from './ProgressItem'
import { ProgressScreenType } from '../../../../models/session'

type Props = {
  progress: ProgressScreenType[]
  isShowModal: boolean
  closeModal: () => void
  goToScreen: (id: number) => void
}

export const ModalProgress = ({ progress, isShowModal, goToScreen, closeModal }: Props) => {
  const handleToScreen = useCallback(
    (id: number) => {
      closeModal()
      goToScreen(id)
    },
    [goToScreen, closeModal]
  )

  const progressList = useMemo(() => {
    return progress.map((item) => {
      return <ProgressItem key={`process-item-${item.id}`} progressItem={item} toScreen={() => handleToScreen(item.id)} />
    })
  }, [progress, handleToScreen])

  const modalProgress = useMemo(() => {
    return (
      <div className={`fixed top-0 right-0 z-50 h-full overflow-hidden transition-all ${isShowModal ? 'w-full' : 'w-0'}`}>
        <div className="relative h-full w-full">
          <div className="absolute z-[51] h-full w-full" onClick={closeModal} />

          <div className="absolute top-1/2 right-1/2 z-[52] h-auto -translate-y-1/2 translate-x-[calc(375px/2)] bg-white py-9 shadow-lg">
            <div className="h-full max-h-[calc(100vh-180px)] w-[338px] overflow-y-auto">
              <div className="relative h-full w-full px-4">
                <div className="absolute left-[37px] top-[21px] z-[1] w-[1px] bg-ma-gray-300" style={{ height: `${(progress.length - 1) * 129}px` }} />
                <div className="relative">{progressList}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }, [isShowModal])

  return <>{modalProgress}</>
}
