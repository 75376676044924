import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Question, QuestionTypes } from '../models/question'

export type FormikOptions<T> = {
  [key: string]: T
}

export function useForm() {
  function getInitialValues(dataFields: Question[], valuesField: FormikOptions<any>) {
    const arrayInitialValues = Array.from(
      new Map(
        dataFields?.map((question: any) => [
          question.name,
          question.name === 'hs_012'
            ? {
                ...question.value,
                feet: question.feet || '',
                bmi: question.feet || '',
                inches: question.feet || '',
                pounds: question.feet || '',
              }
            : question.value || '',
        ])
      ).entries()
    )

    const initialValues = arrayInitialValues?.reduce((obj: FormikOptions<string>, [key, value]) => {
      obj[key] = valuesField[key] || value
      return obj
    }, {})

    return initialValues
  }
  const checkHaveValueParent = (parentQuestion?: string, currentValues?: any, parentPathName?: any, questions?: Question[]) => {
    const parentQuestionName = parentQuestion || ''
    let parentValues = currentValues?.[parentQuestionName]

    if (parentValues?.includes(parentPathName)) {
      if (parentQuestion === 'hs_006') {
        return true
      }
      const parent = questions?.find((q) => q.name === parentQuestion)
      const option = parent?.options?.find((opt) => opt.label === parentPathName)
      return !((option?.score || 0) > 0)
    }

    return false
  }

  function getValidationSchema(dataFields: Question[], messageErrors?: string, currentValues?: any) {
    const arrayValidationSchema = Array.from(
      new Map(
        dataFields
          ?.filter((question) => {
            return question.isRequired || (question.parent && checkHaveValueParent(question.parent.parentName, currentValues, question.parent.value, dataFields))
          })
          .map((question) => [question.name, question])
      ).entries()
    )
    const generationObj = (question: any) => {
      if (question.type === QuestionTypes.CheckboxMulti) {
        return Yup.array()
          .min(1, messageErrors || 'Field is required')
          .of(Yup.string().required())
          .required(messageErrors || 'Field is required')
      } else if (question.type === 'Caculate MBI') {
        return Yup.object().shape({
          pounds: Yup.string().required('Please provide your weight in pounds'),
          feet: Yup.string().required('Please provide your height in feet'),
          inches: Yup.string().required('Please provide your height in inches'),
        })
      } else {
        return Yup.string().required(messageErrors || 'Field is required')
      }
    }

    const validationSchema = arrayValidationSchema
      ? Yup.object(
          arrayValidationSchema.reduce((obj: FormikOptions<any>, [key, question]) => {
            obj[key] = generationObj(question)
            return obj
          }, {})
        )
      : undefined
    return validationSchema
  }

  function initUseFormik(dataFields: Question[], callback: () => void, valuesField: FormikOptions<any> = [], messageErrors?: string, currentValues?: any) {
    return useFormik({
      enableReinitialize: true,
      initialValues: getInitialValues(dataFields, valuesField),
      validationSchema: getValidationSchema(dataFields, messageErrors, currentValues),
      onSubmit: (values) => {
        callback()
      },
    })
  }

  return { initUseFormik }
}
