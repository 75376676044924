import { useMemo, useCallback, useState, useEffect } from 'react'

import { useForm } from '../../hooks/use-form'

import { Wrapper, ErrorMessage, SubmitButtonCommon, ButtonStyles, FormField } from '../Common'
import { SubscriptionOptions } from '../../models/'
import { subscriptionQuestions } from '../../lib/maps/subscription-questions'

type Props = {
  isLoading?: boolean
  errorMessage?: string
  onSubscription: (params: SubscriptionOptions) => void
}

export const SubscriptionContent = ({ isLoading, errorMessage, onSubscription }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  useEffect(() => {
    if (errorMessage && errorMessage !== '') {
      setIsError(true)
      setErrorMsg(errorMessage)
    }
  }, [errorMessage])

  const formik = initUseFormik(subscriptionQuestions, () => {
    const { text_frequency, email_frequency } = formik.values

    setErrorMsg('')
    // onSubscription({ text_frequency, email_frequency })
    onSubscription({ text_frequency: text_frequency[0] ? 'texts' : '', email_frequency: email_frequency[0] ? 'emails' : '' })
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const formSubscription = useMemo(() => {
    return (
      <form className="flex flex-1 flex-col space-y-9 pb-4" onSubmit={formik.handleSubmit}>
        <div className="flex-1 space-y-7">
          {subscriptionQuestions.map((item, index) => {
            return (
              <div className="!uppercase !text-color-bg-primary">
                <FormField key={index} question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              </div>
            )
          })}

          {isError && errorMsg && <ErrorMessage text={errorMsg} />}
        </div>

        <div className="flex justify-center">
          <SubmitButtonCommon isLoading={isLoading} width="w-[174px]" height="h-[53px]" text="Confirm" style={ButtonStyles.Dark} onClick={() => checkSubmit()} />
        </div>
      </form>
    )
  }, [isLoading, formik, isError, errorMsg])

  return (
    <Wrapper className="space-y-[21px] text-ma-md">
      <h1 className="text-ma-lg font-bold">Patient Data and Confidentiality</h1>

      <p>
        MyAdesso is a journey to a healthy heart and life. The journey requires setting intentions towards your habits and goals. We recommend setting reminders to help you check in on how you are
        doing.
        <br />
        <br />
        Please select your preferred notification settings to help encourage your growth.
      </p>

      {formSubscription}
    </Wrapper>
  )
}
