import { useMemo } from 'react'
import { Session03Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

const postCard = `
  Hi, it's Dr. Suzanne. We've been learning about a topic that everyone is obsessed with, and no one understands... diets. Can we shift that from now on? This isn't about the latest fad of the week. This is about providing your body with the best fuel to keep you feeling vital and fabulous. And that my friends, is more about nourishment. Let me tell you, I love to eat. I love to go out to dinner with friends. If I go more than a few hours without eating, I get lightheaded. I know how food makes me feel. It's not just about the taste. It's about how it fuels my days. I know that eating well makes me happy and energized. How do you feel when you eat? This is the time to turn eating into the best choice you can do for yourself, three, four, even five times a day. You're empowered and we've given you so many great ideas to choose from. So many of us have thought about food as a guilty pleasure, a way to reward ourselves. Food makes us feel a certain way, and we often think about it a lot. I'm not telling you not to love to eat. I'm telling you that we need to think about it differently. Starting Adesso (now), we eat in a way that is best for our health. We eat to nourish our lives and figure out the best diet for us. Make a plan and a decision to do it the right way. Make a schedule and prepare to reach success. On the next screen, you can click to make this a goal. You'll use this program to be your food journal, every day. You'll check in with the one thing that matters. What did you eat, and how did it make you feel? It is that easy. Start here and let's make this shift. Your heart will love you for it. You can do this. It is easier than you think.
`

export const ScreenHeartToHeart = ({ buttons }: Props) => {
  const screenHeartToHeart = useMemo(() => {
    return (
      <Session03Wrapper buttons={buttons}>
        <div className="session-style">
          <h4>Let’s reflect on the idea of “nourishment.”</h4>

          <audio controls className="w-full">
            <source src="https://api.myadesso.health/uploads/journeys/episode-03.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div className="h-full max-h-[calc(100vh-490px)] w-full flex-1 overflow-y-scroll">
            <p className=" text-[18px] leading-[22px]" dangerouslySetInnerHTML={{ __html: postCard }} />
          </div>
        </div>
      </Session03Wrapper>
    )
  }, [buttons])

  return <>{screenHeartToHeart}</>
}
