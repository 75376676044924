import { useCallback } from 'react'
import { AdessoError } from '../lib/error'
import { getNourishRx, getOptionsOfNourishRx } from '../lib/request/get-nourish'
import { postSubmitNourish } from '../lib/request/post-submit-nourish'
import { PramsNourish } from '../models'

export function useNourish() {
  const getNourish = useCallback(async () => {
    try {
      return await getNourishRx()
    } catch (error) {
      throw new AdessoError('Get Nourish Failed')
    }
  }, [])

  const getNourishOptions = useCallback(async () => {
    try {
      return await getOptionsOfNourishRx()
    } catch (error) {
      throw new AdessoError('Get Nourish Options Failed')
    }
  }, [])

  const submitNourish = useCallback(async (prams: PramsNourish) => {
    try {
      return await postSubmitNourish(prams)
    } catch (error) {
      throw new AdessoError('submit nourish Failed')
    }
  }, [])

  return { getNourish, getNourishOptions, submitNourish }
}
