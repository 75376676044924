import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { WrapperCommon, ButtonCommon, ButtonStyles } from '../Common'
import { useHeartJournal } from '../../hooks/use-heart-journal'
import { useAuth } from '../../hooks/use-auth'

const FirstGoalSlug = 'commit-to-my-heart'
export const IntroductionScreen = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const heartJournal = useHeartJournal()
  const handleNextPage = useCallback(() => {
    heartJournal.addGoal(4)
    auth.updateUserLocal({ screen: [] })
    navigate('/explanation')
  }, [])

  return (
    <WrapperCommon className="min-h-[calc(100vh-74px)] bg-transparent py-5 text-white">
      <div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-white pt-20">
        <div className="h-full bg-[url('/backgrounds/bg-onboard.webp')] bg-cover bg-center bg-no-repeat"></div>
      </div>
      <div className="relative z-20 flex h-full w-full flex-col justify-between">
        <div className="flex-1 space-y-7 text-center text-ma-lg font-semibold">
          <p>
            Congratulations! The time is now and you’re doing it. Welcome to this journey of living a heart-healthy life!
            <br />
            <br />
            Throughout this journey you will set personalized goals. You can choose and prioritize the goals that are most helpful to you along the way. When you enroll in these goals, they will
            appear on your dashboard and you can click into them to take daily actions.
            <br />
            <br />
            Throughout the journey you will choose the goals that are best for you. But for now, we can all agree on this first—and most important—goal. Let's add this goal now!
          </p>

          <p className="pt-6 text-center text-[22px] font-bold leading-[28px]">
            Add Goal:
            <br />
            “Commit to a heart healthy life”
          </p>
        </div>

        <div className="flex justify-center pt-11">
          <ButtonCommon style={ButtonStyles.Light} width="w-[147px]" height="h-[53px]" text="YES" onClick={handleNextPage} />
        </div>
      </div>
    </WrapperCommon>
  )
}
