import React, { createContext, useState, useContext } from 'react'

interface IChildren {
  children: React.ReactNode
}

interface ISupplementContext {
  addDataSupplementRx: any
  editDataSupplementRx: any
  addDataSupplement: (addDataSupplementRx: any) => void
  editDataSupplement: (editDataSupplementRx: any) => void
  removeDataSupplement: (addDataSupplementRx: any) => void
}

const SupplementContext = createContext<ISupplementContext>({
  addDataSupplementRx: {},
  editDataSupplementRx: {},
  addDataSupplement: () => undefined,
  editDataSupplement: () => undefined,
  removeDataSupplement: () => undefined,
})

const defaultAddSupplementData = {
  prescription_name: '',
  how_to_take: '',
  single_dose: '',
  times_per_day: '',
  approved_by_physician: false,
}

const SupplementRxProvider = ({ children }: IChildren) => {
  const [addDataSupplementRx, setAddDataSupplementRx] = useState<any>(defaultAddSupplementData)
  const [editDataSupplementRx, setEditDataSupplementRx] = useState<any>(defaultAddSupplementData)

  const addDataSupplement = (data: any) => {
    setAddDataSupplementRx(data)
    // setAddDataSupplementRx([...addDataSupplementRx, data])
  }

  const editDataSupplement = (data: any) => {
    setEditDataSupplementRx(data)
    // setEditDataSupplementRx([...addDataSupplementRx, data])
  }

  // const removeDataSupplement = (data: any) => {
  //   setAddDataSupplementRx(addDataSupplementRx.filter((item: any) => item !== data))
  // }

  const removeDataSupplement = () => {
    setAddDataSupplementRx(defaultAddSupplementData)
  }

  return (
    <SupplementContext.Provider
      value={{
        addDataSupplementRx,
        editDataSupplementRx,
        addDataSupplement,
        editDataSupplement,
        removeDataSupplement,
      }}
    >
      {children}
    </SupplementContext.Provider>
  )
}
const useContextSupplementRx = () => {
  return useContext(SupplementContext)
}

export { SupplementRxProvider, useContextSupplementRx }
