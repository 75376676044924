type Props = {
  title?: string
  subTitle?: string
  className?: string
  children?: React.ReactNode
  titleColor?: string
  subTitleColor?: string
}

export const TitlePage = ({ title, subTitle, className = '', children, titleColor = '', subTitleColor = '' }: Props) => {
  return (
    <div className={`w-full ${className} ${title && subTitle ? 'space-y-6' : ''}`}>
      {title && <div className={`text-ma-xl font-semibold ${titleColor}`}>{title}</div>}
      {subTitle && <div className={`text-ma-md font-bold ${subTitleColor}`}>{subTitle}</div>}
      {children}
    </div>
  )
}
