import { useState, useMemo, useCallback, useRef, MutableRefObject, useEffect } from 'react'
import { DashboardContent } from '../../Dashboard'
import { ButtonCommon, ButtonStyles } from '../../Common/Button'
import { BoxText, ArrowType } from '../BoxText'
import { useLocales } from '../../../locales'
import flagsmith from 'flagsmith'
import { GoalTrackingConfigurationContent } from '../../GoalTrackingConfiguration'
import { useMAJPage } from '../../../hooks/pages/maj'
import { DashboardStep } from '../../../constants/introduction'

enum ScreenIntroduction {
  MyNow,
  DailyTrack,
}
type Props = {
  nextScreen: any
}

export const DashboardIntroductionScreen = ({ nextScreen }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const featureEnabled = flagsmith.hasFeature('ACP')
  const { t } = useLocales()
  const [step, setStep] = useState<DashboardStep>(DashboardStep.StartTour)
  const [screenIntroduction, setScreenIntroduction] = useState<ScreenIntroduction>(ScreenIntroduction.MyNow)
  const myNowLocalStorage = localStorage.getItem('myNow') || ''
  const boxRef = useRef<HTMLDivElement>(null)
  const { dataNextSession } = useMAJPage()

  const refs = Object.values(DashboardStep).reduce((acc: any, step, index) => {
    if (!isNaN(Number(step))) {
      acc[step] = useRef<HTMLElement>(null)
    }

    return acc
  }, {})

  const [currentRef, setCurrentRef] = useState<any>(refs[DashboardStep.MyNow])

  const handleNextStep = useCallback((stepCurrent: DashboardStep) => {
    const isMyHeartNotEnabled = stepCurrent + 1 === DashboardStep.MyHeart && !featureEnabled
    const isMyReminderNotEnabled = stepCurrent + 1 === DashboardStep.MyReminder && !featureEnabled

    const nextStep = isMyHeartNotEnabled || isMyReminderNotEnabled ? stepCurrent + 2 : stepCurrent + 1
    setStep(nextStep)

    const nextScreenIntro = stepCurrent === DashboardStep.MyDailyTrack ? ScreenIntroduction.DailyTrack : ScreenIntroduction.MyNow
    setScreenIntroduction(nextScreenIntro)
    if (nextStep === DashboardStep.MyDailyTrack) {
      setCurrentRef(refs[DashboardStep.MyHeartActions])
    } else {
      setCurrentRef(refs[nextStep])
    }
  }, [])

  const positionStep = {
    MyNow: 'top',
    MyHeart: '',
    Congratulations: '',
    MyHeartActions: '',
    MyDailyTrack: '',
    GoalTrackingConfig: '',
    MyNextSession: '',
    MyReminder: '',
    MyHealth: '',
    GoDeeper: '',
    Completed: '',
  }

  useEffect(() => {
    // 80 header height
    // 47 Arrow height -10 (bottom)
    // 51 Next Button Height
    if (step === DashboardStep.StartTour) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        boxRef.current.style.top = '0'
      }
      return
    }
    if (step === DashboardStep.Completed) {
      if (boxRef?.current?.style) {
        boxRef.current.style.bottom = '58px'
        boxRef.current.style.top = 'unset'
        boxRef.current.style.height = 'unset'
        window.scrollTo(0, document.body.scrollHeight)
      }
      return
    }

    if (!currentRef?.current) {
      // Center vertical popup
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        const boxTop = Math.max(0, window.innerHeight / 2 - (boxRef.current.children[0]?.clientHeight + 51) / 2) - 80
        boxRef.current.style.top = boxTop + 'px'
      }
      return
    }

    currentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    const scrollHeight = currentRef.current.scrollHeight
    const scrollY = window.scrollY
    const { bottom } = currentRef?.current.getBoundingClientRect()

    if (boxRef?.current) {
      switch (step) {
        case DashboardStep.MyNow:
          boxRef.current.style.top = Math.round(bottom - 80) + 'px'
          break
        case DashboardStep.MyReminder:
        case DashboardStep.GoDeeper:
          boxRef.current.style.top = Math.round(bottom + scrollY - scrollHeight / 2.5 - 80) + 'px'
          break
        case DashboardStep.GoalTrackingConfig:
          boxRef.current.style.top = Math.round(bottom + scrollY + 47 - 80) + 'px'
          break
        default:
          boxRef.current.style.top = Math.round(bottom + scrollY - 80) + 'px'
          break
      }
    }
  }, [step])

  const contentDashboardStep = useMemo(() => {
    switch (step) {
      case DashboardStep.StartTour:
        return (
          <div className="relative">
            <BoxText className={`${positionStep.MyNow}`} title={t('introduction.dashboard.title.AdessoTour')} text={t('introduction.dashboard.text.AdessoTour')} />
          </div>
        )
      case DashboardStep.MyNow:
        return (
          <div className="relative">
            <BoxText arrow={ArrowType.Right} className={`${positionStep.MyNow}`} title={t('introduction.dashboard.title.MyNow')} text={t('introduction.dashboard.text.MyNow')} />
          </div>
        )

      case DashboardStep.MyHeart:
        return <BoxText arrow={ArrowType.Left} className={`${positionStep.MyHeart}`} title={t('introduction.dashboard.title.MyHeart')} text={t('introduction.dashboard.text.MyHeart')} />
      case DashboardStep.Congratulations:
        return <BoxText className={`${positionStep.Congratulations}`} title={t('introduction.dashboard.title.Congratulations')} text={t('introduction.dashboard.text.Congratulations')} plusPoint={5} />
      case DashboardStep.MyHeartActions:
        return (
          <BoxText
            arrow={ArrowType.Left}
            className={`${positionStep.MyHeartActions}`}
            title={t('introduction.dashboard.title.MyHeartActions')}
            text={t('introduction.dashboard.text.MyHeartActions')}
          />
        )
      case DashboardStep.MyDailyTrack:
        return (
          <BoxText arrow={ArrowType.Right} className={`${positionStep.MyDailyTrack}`} title={t('introduction.dashboard.title.MyDailyTrack')} text={t('introduction.dashboard.text.MyDailyTrack')} />
        )
      case DashboardStep.GoalTrackingConfig:
        return (
          <BoxText
            arrow={ArrowType.Right}
            className={`${positionStep.GoalTrackingConfig} !max-w-[670px]`}
            title={t('introduction.dashboard.title.GoalTrackingConfig')}
            text={t('introduction.dashboard.text.GoalTrackingConfig')}
          />
        )
      case DashboardStep.MyNextSession:
        return (
          <BoxText arrow={ArrowType.Left} className={`${positionStep.MyNextSession}`} title={t('introduction.dashboard.title.MyNextSession')} text={t('introduction.dashboard.text.MyNextSession')} />
        )
      case DashboardStep.MyReminder:
        return <BoxText arrow={ArrowType.Left} className={`${positionStep.MyReminder}`} title={t('introduction.dashboard.title.MyReminder')} text={t('introduction.dashboard.text.MyReminder')} />
      case DashboardStep.MyHealth:
        return <BoxText arrow={ArrowType.Left} className={`${positionStep.MyHealth}`} title={t('introduction.dashboard.title.MyHealth')} text={t('introduction.dashboard.text.MyHealth')} />
      case DashboardStep.GoDeeper:
        return <BoxText arrow={ArrowType.Left} className={`${positionStep.GoDeeper}`} title={t('introduction.dashboard.title.GoDeeper')} text={t('introduction.dashboard.text.GoDeeper')} />
      case DashboardStep.Completed:
        return <BoxText arrow={ArrowType.BottomRight} arrowClass="!right-1/4 !top-[95%]" title={t('introduction.dashboard.title.Completed')} text={t('introduction.dashboard.text.Completed')} />

      default:
        return <></>
    }
  }, [step])

  const buttonNext = useMemo(() => {
    const marginBottom = step === DashboardStep.MyDailyTrack || step === DashboardStep.MyHeartActions ? 'scroll-mb-[0px]' : undefined

    return step === DashboardStep.Completed ? (
      <ButtonCommon
        contentRef={contentRef}
        scrollMarginTop="scroll-mt-0"
        scrollMarginBottom="scroll-mt-0"
        style={ButtonStyles.Introduction}
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        className={`${positionStep.Completed}`}
        onClick={nextScreen}
      />
    ) : (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        width="w-[167px]"
        height="h-[51px]"
        scrollMarginBottom={marginBottom}
        text={t('introduction.gotItNext')}
        onClick={() => {
          handleNextStep(step)
        }}
      />
    )
  }, [step, handleNextStep, nextScreen])

  const introMemo = useMemo(() => {
    return (
      <div>
        <div className={step !== DashboardStep.GoalTrackingConfig ? 'block' : 'hidden'}>
          <DashboardContent refs={refs} myNow={myNowLocalStorage} nextSession={dataNextSession} score={50} isIntroduction />
        </div>
        <div className={step === DashboardStep.GoalTrackingConfig ? 'block' : 'hidden'}>
          <GoalTrackingConfigurationContent jumpTo={refs[DashboardStep.GoalTrackingConfig]} isIntroduction />
        </div>
      </div>
    )
  }, [step, currentRef, screenIntroduction])

  return (
    <div className="relative h-full w-full">
      {introMemo}
      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] bg-color-overlay bg-opacity-50"></div>
      <div ref={boxRef} className="absolute left-0 top-[-74px] z-50 flex  h-full w-full scroll-mb-[74px] flex-col items-center space-y-4  px-4 font-['Proxima_Nova']">
        {step === DashboardStep.Completed ? (
          <>
            {buttonNext}
            {contentDashboardStep}
          </>
        ) : (
          <>
            {contentDashboardStep}
            {buttonNext}
          </>
        )}
      </div>
    </div>
  )
}
