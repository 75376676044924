import { useMemo } from 'react'

type Props = {
  children?: React.ReactNode
  isRight?: Boolean
}

export const Bubble = ({ children, isRight = false }: Props) => {
  return (
    <div className={`relative flex w-full px-4 pb-5 text-[18px] leading-[22px] ${isRight ? 'justify-end ' : ''}`}>
      <div className={`rounded-ma px-4 py-3 ${isRight ? 'bg-ma-violet-300 text-right text-ma-violet-700' : 'bg-ma-orange-300 text-ma-orange-700'}`}>{children}</div>
      <span className={`absolute bottom-0 h-3 w-3 rounded-full bg-white ${isRight ? 'right-0 bg-ma-violet-300' : 'left-0 bg-ma-orange-300'}`} />
      <span className={`absolute bottom-3 h-6 w-6 rounded-full bg-white ${isRight ? 'right-2 bg-ma-violet-300' : 'left-2 bg-ma-orange-300'}`} />
    </div>
  )
}
