import { Goal } from '../models/goal'

export const IntroSelectedTabData = {
  id: 4,
  goal_id: 4,
  title: 'COMMIT',
  description: 'What changes do you want to make in your life?',
  sub_title: null,
  sub_description: null,
  text_color: '#ffffff',
  background_color: '#ff3399',
  goal_settings: [
    {
      id: 11,
      identifier: 'blood_pressure',
      goal_tracking_id: 4,
      type: 'both',
      title: 'Blood pressure',
      description: 'I want to monitor my blood pressure.',
      priority: 1,
      user_settings: [
        {
          id: 52,
          goal_setting_id: 11,
          is_enable: false,
          type: 'none',
        },
      ],
    },
    {
      id: 12,
      identifier: '',
      goal_tracking_id: 4,
      type: 'manually',
      title: 'Medication Tracking',
      description: 'I want to track my heart medications.',
      priority: 2,
      user_settings: [
        {
          id: 55,
          goal_setting_id: 12,
          is_enable: false,
          type: 'none',
        },
      ],
    },
    {
      id: 13,
      identifier: '',
      goal_tracking_id: 4,
      type: 'manually',
      title: 'Smoking',
      description: 'I want to reduce my smoking habit.',
      priority: 3,
      user_settings: [
        {
          id: 56,
          goal_setting_id: 13,
          is_enable: false,
          type: 'none',
        },
      ],
    },
    {
      id: 18,
      identifier: '',
      goal_tracking_id: 4,
      type: 'manually',
      title: 'Alcohol',
      description: 'I want to improve my drinking habits.',
      priority: 4,
      user_settings: [
        {
          id: 57,
          goal_setting_id: 18,
          is_enable: false,
          type: 'none',
        },
      ],
    },
    {
      id: 19,
      identifier: '',
      goal_tracking_id: 4,
      type: 'manually',
      title: 'Self-Check-ins',
      description: 'I want to check in with my health regularly.',
      priority: 5,
      user_settings: [
        {
          id: 48,
          goal_setting_id: 19,
          is_enable: false,
          type: 'none',
        },
      ],
    },
  ],
}

export const IntroductionTabGTC: Goal[] = [
  {
    id: 4,
    name: 'Commit To My Heart',
    slug: 'commit-to-my-heart',
    order: 1,
    textColor: '#ffffff',
    backgroundColor: '#ff3399',
    added_at: null,
    description: 'Take a moment to reflect on living from the heart.',
    completed: false,
    showProgress: true,
    title: 'COMMIT',
    goal_tracking_id: 4,
  },
  {
    id: 2,
    name: 'Move My Heart',
    slug: 'move-my-heart',
    order: 2,
    textColor: '#ffffff',
    backgroundColor: '#ff567a',
    added_at: null,
    description: 'Log Your Movements',
    completed: false,
    showProgress: true,
    title: 'MOVE',
    goal_tracking_id: 2,
  },
  {
    id: 1,
    name: 'Nourish My Heart',
    slug: 'nourish-my-heart',
    order: 3,
    textColor: '#ffffff',
    backgroundColor: '#f26e57',
    added_at: null,
    description: 'Track your new nourishment habits.',
    completed: false,
    showProgress: true,
    title: 'NOURISH',
    goal_tracking_id: 3,
  },
  {
    id: 3,
    name: 'Refresh My Heart',
    description: 'Track and journal your emotions and mental health practices.',
    order: 4,
    created_at: '2022-12-28T03:55:01.000000Z',
    updated_at: '2024-04-16T21:47:44.000000Z',
    status: 1,
    deleted_at: null,
    textColor: '#ffffff',
    backgroundColor: '#b7005e',
    slug: 'refresh-my-heart',
    title: 'REFRESH',
    goal_tracking_id: 7,
  },
  {
    id: 5,
    name: 'Relate For My Heart',
    description: 'Track and journal about your relationships.',
    order: 5,
    created_at: '2022-12-28T03:55:01.000000Z',
    updated_at: '2024-02-29T09:02:47.000000Z',
    status: 1,
    deleted_at: null,
    textColor: '#ffffff',
    backgroundColor: '#974a9a',
    slug: 'relate-for-my-heart',
    title: 'RELATE',
    goal_tracking_id: 6,
  },
]

export const IntroductionMyGoals: Goal[] = [
  {
    id: 4,
    name: 'Commit To My Heart',
    slug: 'commit-to-my-heart',
    order: 1,
    textColor: '#ffffff',
    backgroundColor: '#ff3399',
    added_at: null,
    description: 'Take a moment to reflect on living from the heart.',
    completed: false,
    showProgress: true,
    title: 'COMMIT',
    goal_tracking_id: 4,
  },
  {
    id: 2,
    name: 'Move My Heart',
    slug: 'move-my-heart',
    order: 2,
    textColor: '#ffffff',
    backgroundColor: '#ff567a',
    added_at: null,
    description: 'Log Your Movements',
    completed: false,
    showProgress: true,
    title: 'MOVE',
    goal_tracking_id: 2,
  },
  {
    id: 1,
    name: 'Nourish My Heart',
    slug: 'nourish-my-heart',
    order: 3,
    textColor: '#ffffff',
    backgroundColor: '#f26e57',
    added_at: null,
    description: 'Track your new nourishment habits.',
    completed: false,
    showProgress: true,
    title: 'NOURISH',
    goal_tracking_id: 3,
  },
]

export const IntroductionRemainingGoals: Goal[] = [
  {
    id: 3,
    name: 'Refresh My Heart',
    description: 'Track and journal your emotions and mental health practices.',
    order: 4,
    created_at: '2022-12-28T03:55:01.000000Z',
    updated_at: '2024-04-16T21:47:44.000000Z',
    status: 1,
    deleted_at: null,
    textColor: '#ffffff',
    backgroundColor: '#b7005e',
    slug: 'refresh-my-heart',
    title: 'REFRESH',
    goal_tracking_id: 7,
  },
  {
    id: 5,
    name: 'Relate For My Heart',
    description: 'Track and journal about your relationships.',
    order: 5,
    created_at: '2022-12-28T03:55:01.000000Z',
    updated_at: '2024-02-29T09:02:47.000000Z',
    status: 1,
    deleted_at: null,
    textColor: '#ffffff',
    backgroundColor: '#974a9a',
    slug: 'relate-for-my-heart',
    title: 'RELATE',
    goal_tracking_id: 6,
  },
]
