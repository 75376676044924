import axios from 'axios'
import { AdessoError } from "../error";
import { HeartJournal } from "../../models/heart-journal";


type GetHeartJournalResponse = {
    success: boolean
    data?: HeartJournal
    message?: string
}

export async function getHeartJournal ( date: string ): Promise<GetHeartJournalResponse> {
    const { data, status } = await axios.get( `/goals/memo-detail/${date}` )
    if ( data.success && data.data && data.data.goals ) {
        data.data.goals = data.data.goals.map( ( goal: any ) => {
            goal.backgroundColor = goal.background_color
            goal.textColor = goal.text_color
            return goal;
        } )
    }
    if ( status !== 200 ) {
        console.info( 'getGoal', data )
        throw new AdessoError( `Request getGoal error - ${status} ${JSON.stringify( data )}` )
    }
    return data.success ? {
        data: data.data,
        message: data.message,
        success: data.success
    } : {
        message: data.message,
        success: false
    }
}
