type Props = {
  title?: string
  adJust?: React.ReactNode
  className?: string
  children?: React.ReactNode
}

export const DailyTrackingSection = ({ title, adJust, className, children }: Props) => {
  return (
    <section className={className}>
      <div className="flex flex-wrap justify-between gap-[10px]">
        {title && <h3 className="font-['Butler'] text-[24px] leading-[1.41667]">{title}</h3>}
        {adJust}
      </div>
      {children}
    </section>
  )
}
