import axios from 'axios'

import {AdessoError} from '../../lib/error'


type GetTokenResponse = {
  token?: string
  user?: {
    id: number
    name?: string
    email: string
    phone_number: string
  }
  errorMessage?: string
}

export async function getToken(code: string): Promise<GetTokenResponse> {
  const {data, status} = await axios.get('/oauth/token/' + code)

  if (status !== 200) {
    console.info('getToken', data)
    throw new AdessoError(`Request get token error - ${status} ${JSON.stringify(data)}`)
  }

  let dataReturn: GetTokenResponse
  if (data.success === true) {
    dataReturn = {
      token: data.data.token,
      user: data.data.user,
    }
  } else {
    dataReturn = {
      errorMessage: data.message,
    }
  }

  return dataReturn
}
