import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
    data?: any
    message?: string
}

export async function getDeeperSlug ( slug: string ): Promise<Response> {
    const { data, status } = await axios.get( `/deeper/${slug}` )

    if ( status !== 200 ) {
        console.info( 'getDeepers', data )
        throw new AdessoError( `Request getDeepers error - ${status} ${JSON.stringify( data )}` )
    }

    const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

    return dataReturn
}
