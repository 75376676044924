import { useCallback, useState } from 'react'

// ----------------------------------------------------------------------

type BooleanHook = {
  value: boolean
  onTrue: () => void
  onFalse: () => void
  onToggle: () => void
  setValue: React.Dispatch<React.SetStateAction<boolean>>
}

// ----------------------------------------------------------------------

const stringToBoolean = (str: any) => {
  if (typeof str === 'string') {
    return str.toLowerCase() === 'true'
  } else {
    return !!str
  }
}

export function useBoolean(defaultValue?: boolean): BooleanHook {
  const [value, setValue] = useState<boolean>(stringToBoolean(defaultValue))

  const onTrue = useCallback(() => {
    setValue(true)
  }, [])

  const onFalse = useCallback(() => {
    setValue(false)
  }, [])

  const onToggle = useCallback(() => {
    setValue((prev) => !prev)
  }, [])

  return {
    value,
    onTrue,
    onFalse,
    onToggle,
    setValue,
  }
}
