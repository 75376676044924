import { FooterItem } from './FooterItem'
import { useLocales } from '../../locales'

export const CommonFooter = () => {
  const { t } = useLocales()
  return (
    <div className="fixed bottom-0 left-0 right-0 z-20  bg-gradient-to-l from-ma-red to-ma-red-300">
      <div className=" mx-auto flex h-[58px] w-full min-w-[375px] max-w-[895px] items-center justify-center space-x-[1px]">
        <FooterItem name={t('mainMenuAndSection.home')} link="dashboard">
          <div className="h-full w-full bg-[url('assets/images/icon/i-home.svg')] bg-[length:auto_21px] bg-center bg-no-repeat" />
        </FooterItem>

        <FooterItem name={t('mainMenuAndSection.learning')} link="my-adesso-journey">
          <div className="h-full w-full bg-[url('assets/images/icon/i-learning.svg')] bg-[length:auto_18px] bg-center bg-no-repeat" />
        </FooterItem>

        <FooterItem name={t('mainMenuAndSection.myHealth')} link="my-health">
          <div className="h-full w-full bg-[url('assets/images/icon/i-my-health.svg')] bg-[length:auto_23px] bg-center bg-no-repeat" />
        </FooterItem>

        <FooterItem name={t('mainMenuAndSection.heartChart')} link="heart-chart">
          <div className="h-full w-full bg-[url('assets/images/icon/i-heart_chart.svg')] bg-[length:auto_18px] bg-center bg-no-repeat" />
        </FooterItem>

        <FooterItem name={t('mainMenuAndSection.settings')} link="setting">
          <div className="h-full w-full bg-[url('assets/images/icon/i-settings.svg')] bg-[length:auto_20px] bg-center bg-no-repeat" />
        </FooterItem>
      </div>
    </div>
  )
}
