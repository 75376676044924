export const DATA_MY_HEALTH = [
  {
    id: 0,
    icon: 'i-heart_score',
    label: 'HEART SCORE',
    bgColor: 'color-text-primary',
    link: '/my-health/heart-score',
  },
  {
    id: 1,
    icon: 'i-lab_test_results',
    label: 'LAB TEST RESULTS',
    bgColor: 'color-text-primary',
    link: '/my-health/lab-test-results',
  },
  {
    id: 2,
    icon: 'i-medications_rx',
    label: 'MEDICATIONS RX',
    bgColor: 'color-violet',
    link: '/my-health/medications-rx',
  },
  {
    id: 3,
    icon: 'i-movement_rx',
    label: 'MOVE RX',
    bgColor: 'color-violet',
    link: '/my-health/move-rx',
  },
  {
    id: 4,
    icon: 'i-nutrition_rx',
    label: 'NOURISH RX',
    bgColor: 'color-violet',
    link: '/my-health/nourish-rx',
  },
  {
    id: 5,
    icon: 'i-supplements',
    label: 'SUPPLEMENTS RX',
    bgColor: 'color-violet',
    link: '/my-health/supplements-rx',
  },
  {
    id: 6,
    icon: 'i-lifestyle_rx',
    label: 'LIFESTYLE Rx',
    bgColor: 'color-violet',
    link: '/my-health/lifestyle-rx',
  },
]
