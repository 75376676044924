import { convertStartDay, GroupData } from '../../models/heartChart'

export function mapDataChartPie(data: any[]): GroupData[] {
  const todayWeekday = new Date().getDay()

  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6] // Sunday as 0, then Monday (1) to Saturday (6)

  const groupData = data.map((item) => {
    const days = daysOfWeek.map((dayNumber) => {
      const dayIndex = dayNumber % 7
      let value
      const convertedDayIndex = String(convertStartDay[dayIndex])

      if (item.dates === null) {
        value = null
      } else {
        if (dayIndex <= todayWeekday) {
          value = item.dates.includes(convertedDayIndex) ? 1 : 0
        } else {
          value = null
        }
      }

      return { day: dayNumber, value }
    })
    return {
      label: item.title.toUpperCase(),
      color: item.color,
      days,
    }
  })

  return groupData
}
