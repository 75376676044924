import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
}

export async function getHeartScoreQuestions(): Promise<Response> {
  const { data, status } = await axios.get('/heart-score-questions')

  if (status !== 200) {
    console.info('getHeartScoreQuestions', data)
    throw new AdessoError(`Request getHeartScoreQuestions error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getCheckHeartSym(): Promise<Response> {
  const { data, status } = await axios.get('heart-scores/check-heart-symptoms')

  if (status !== 200) {
    console.info('getCheckHeartSymptoms', data)
    throw new AdessoError(`Request getCheckHeartSymptoms error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHighRiskFactors(): Promise<Response> {
  const { data, status } = await axios.get('heart-scores/high-risk-factors')

  if (status !== 200) {
    console.info('getHighRiskFactor', data)
    throw new AdessoError(`Request getHighRiskFactor error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
