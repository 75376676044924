import axios from 'axios'
import { AdessoError } from '../error'

type GetDataNourishResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getOptionsOfNourishRx(): Promise<GetDataNourishResponse> {
  const { data, status } = await axios.get(`/nourishs/options`)

  if (status !== 200) {
    throw new AdessoError(`Request getOptionsOfNourishRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDataNourishResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function getNourishRx(): Promise<GetDataNourishResponse> {
  const { data, status } = await axios.get(`/nourishs`)

  if (status !== 200) {
    throw new AdessoError(`Request get nourish error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: GetDataNourishResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
