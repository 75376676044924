import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEn from './langs/en.json'
import translationEs from './langs/es.json'
import { defaultLang } from './config-lang'
import { useLocalStorage } from '../hooks/use-local-storage'

const { getItemLS } = useLocalStorage()

const lng = (getItemLS('i18nextLng') as string) || defaultLang.value

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      es: { translations: translationEs },
      en: { translations: translationEn },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
