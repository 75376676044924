import { useCallback, useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, Container, TitlePage, FormInput, SubmitButtonCommon, ButtonCommon, ButtonStyles, ErrorMessage } from '../Common'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postForgotPassword } from '../../lib/request/post-forgot-password'
import { useLocalStorage } from '../../hooks/use-local-storage'

type Props = {}

const defaultData = {
  email: '',
}

export const ForgotPasswordContent = ({}: Props) => {
  const navigate = useNavigate()
  const { setItemLS } = useLocalStorage()
  const [isCheckError, setIsCheckError] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: defaultData,
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Field is required'),
    }),
    onSubmit: async (values, { setErrors }) => {
      const res = await postForgotPassword(values)

      if (res.status) {
        setItemLS('redirect-from', 'forgot-password')
        navigate('/forgot-password-success')
      } else {
        setErrors({ ...res.errorMessage })
      }
    },
  })

  const ForgotPasswordContent = useMemo(() => {
    return (
      <Wrapper className="relative min-h-[calc(100vh-74px)] py-10">
        <TitlePage
          titleColor="text-color-text-primary"
          subTitleColor="text-color-overlay"
          title="Forgot your password"
          subTitle="Please enter the email address you’d like your password reset information sent to"
        />

        <form className="w-full text-black" onSubmit={formik.handleSubmit}>
          <div className="mt-12">
            <input className="mb-2 w-full border border-slate-400 px-4 py-2" id="email" type="email" name="email" placeholder="Email *" value={formik.values.email} onChange={formik.handleChange} />
            {isCheckError && formik.errors.email && <ErrorMessage text={formik.errors.email} />}
          </div>

          <SubmitButtonCommon
            isLoading={false}
            disabled={formik.values.email.length === 0}
            width="w-full"
            height="h-[53px]"
            text="request reset link"
            style={ButtonStyles.Dark}
            className="mt-12"
            onClick={() => {
              setIsCheckError(true)
              formik.handleSubmit
            }}
          />
        </form>
        <div className="mt-20 text-center">
          <a className="font-bold text-[#ED2580] underline hover:text-[#ED2580]" href="/login">
            Back to login
          </a>
        </div>
      </Wrapper>
    )
  }, [formik])

  return <>{ForgotPasswordContent}</>
}
