import { useMemo, useCallback, useState, useEffect } from 'react'
import { QuizzesLayout } from './layout'
import { Wrapper } from '../Common'
import { useNavigate } from 'react-router-dom'

type Props = {
  quizessData: any
  isLoading?: boolean
  isSuccess?: boolean
  errorMessage?: string
  onSubmit: (params: object) => void
  answerValue?: any
}

export const HeartChartTestingContent = ({ quizessData, isLoading, isSuccess, errorMessage, onSubmit, answerValue }: Props) => {
  const [values, setValues] = useState({ ...answerValue })
  const [stepIndex, setStepIndex] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      // btodo goto result page
      navigate('/heart-chart/result')
    }

    if (errorMessage) {
      alert(errorMessage)
    }
  }, [errorMessage, isSuccess])

  const handlePrevScreen = useCallback((currentStep: number, oldData: object, data?: object) => {
    if (data) {
      setValues({ ...oldData, ...data })
    }
    window.scrollTo(0, 0)
    setStepIndex(currentStep - 1)
  }, [])

  const handleNextScreen = useCallback((currentStep: number, oldData: object, data?: object, notMove?: boolean) => {
    if (data) {
      setValues({ ...oldData, ...data })
    }

    if (notMove) {
      onSubmit({ ...oldData, ...data })
    } else {
      window.scrollTo(0, 0)
      setStepIndex(currentStep + 1)
    }
  }, [])

  const screenDisplay = useMemo(
    () => (
      <QuizzesLayout
        values={values}
        currentStep={stepIndex}
        quizess={quizessData[stepIndex]}
        isLoading={isLoading}
        backScreen={(data?: object) => handlePrevScreen(stepIndex, values, data)}
        nextScreen={(data?: object, notMove?: boolean) => handleNextScreen(stepIndex, values, data, notMove)}
      />
    ),
    [quizessData, isLoading, values, stepIndex, handlePrevScreen, handleNextScreen]
  )

  const heartChartTestingScreen = useMemo(() => {
    return <Wrapper className={`text-ma-md text-black`}>{screenDisplay}</Wrapper>
  }, [screenDisplay])

  return <>{heartChartTestingScreen}</>
}
