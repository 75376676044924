import axios from 'axios'

class MyActionServices {
  getCompletedActions() {
    return axios.get(import.meta.env.VITE_APP_HOST_URL + '/sanctum/csrf-cookie').then(function (response) {
      return axios
        .get('/my-actions/complete')
        .then((response) => {
          return response.data
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            return error.response.data
          } else if (error.request || error.message) {
            return error.message
          } else {
            //
          }
        })
    })
  }

  getInCompletedActions() {
    return axios.get(import.meta.env.VITE_APP_HOST_URL + '/sanctum/csrf-cookie').then(function (response) {
      return axios
        .get('/my-actions/not-complete')
        .then((response) => {
          return response.data
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            return error.response.data
          } else if (error.request || error.message) {
            return error.message
          } else {
            //
          }
        })
    })
  }

  addMyAction(key: string) {
    return axios
      .post('/my-actions/complete-by-key', {
        key: key,
      })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          return error.response.data
        } else if (error.request || error.message) {
          return error.message
        } else {
          //
        }
      })
  }
}

export default new MyActionServices()
