import { useMemo } from 'react'
import { Session01Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

const postCard = `
  Hi everyone, it's Dr. Suzanne again. I've been doing this my whole career. And no matter where I go, I hear, is this going to happen to me? Heart disease? Me? Well, not if I can help it, because yes, this matters for all of you: low risk, high risk. It's never too early or never too late. We looked at all the contributing factors of heart disease, and you have your score now. But let's start thinking about it in another way. Everything you do for heart health is about how you want to live your life. Listen to me, heart disease may not interest you, but heart health certainly does. For every one of you, this is a perfect moment for us to pause and reflect. We'll do this throughout your Adesso experience. I want you to connect to your heart. Find a time to listen and engage when it works for you. This is all for you, and how you can best use this journey to help you. You've clicked your first goal. Now use the tools to start your electronic heart book. Come back here daily, a minute or two a day, to get in touch with your heart health. We've given you everything you need right in the palm of your hand. I'm going to help you, give you advice, and guide you; but only you can choose to embrace a lifestyle and perspective that will ultimately lead to a healthier heart. You're in the driver's seat here. We can't wait to see where you go on this journey. So buckle up, and let's get started.

  <br/><br/>You've clicked your first goal! This is amazing! Now use these tools to start your own heart book. Come back here every day, for a minute or two, to get in touch with your heart health. We have given you everything you need right in the palm of your hand. And guess what? I am going to help you. I'm going to give you advice. I'm going to guide you, but I'm going to tell you something: only you can choose to embrace a lifestyle and perspective that will ultimately lead to a healthier heart. You know, you're in the driver's seat here, and we can't wait to see where you go on this journey. So buckle up and let's get started.
`

export const ScreenHeartToHeart = ({ buttons }: Props) => {
  const screenHeartToHeart = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>Let’s dive deeper into the idea of “listening to your heart.” Dr. Suzanne wants to share some thoughts. Push play below and feel free to read along.</p>

          <audio controls className="w-full">
            <source src="https://api.myadesso.health/uploads/journeys/episode-01.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>

          <div className="h-full max-h-[calc(100vh-490px)] w-full flex-1 overflow-y-scroll">
            <p className=" text-[18px] leading-[22px]" dangerouslySetInnerHTML={{ __html: postCard }} />
          </div>
        </div>
      </Session01Wrapper>
    )
  }, [buttons])

  return <>{screenHeartToHeart}</>
}
