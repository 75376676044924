import { useCallback, useMemo, useState } from 'react'
import { ScreenCoreConcepts01 } from './CoreConcepts01'
import { ScreenCoreConcepts02 } from './CoreConcepts02'
import { ScreenCoreConcepts03 } from './CoreConcepts03'
import { ScreenCoreConcepts04 } from './CoreConcepts04'
import { ScreenCoreConcepts05 } from './CoreConcepts05'
import { ScreenCoreConcepts06 } from './CoreConcepts06'
import { ScreenCoreConcepts07 } from './CoreConcepts07'

import { putJourneysCurrentStep } from '../../../../../lib/request/put-journeys-current-step'
import { Session07Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
  step: Step
  isButtonBack: boolean
}

enum Screen {
  CoreConcepts00,
  CoreConcepts01,
  CoreConcepts02,
  CoreConcepts03,
  CoreConcepts04,
  CoreConcepts05,
  CoreConcepts06,
  CoreConcepts07,
}

export const ScreenCoreConcepts = ({ showModal, nextScreen, backScreen, step, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState<Screen>(isButtonBack ? Screen.CoreConcepts07 : step.sub_step)

  const saveScreen = (screen: any) => {
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: 2, step: screen })
  }

  const handleBackScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent === Screen.CoreConcepts00) {
        backScreen()
      } else {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent - 1)
      }
    },
    [backScreen]
  )

  const handleNextScreen = useCallback(
    (stepCurrent: Screen) => {
      if (stepCurrent !== Screen.CoreConcepts07) {
        window.scrollTo(0, 0)
        saveScreen(stepCurrent + 1)
      } else nextScreen()
    },
    [nextScreen]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={() => handleBackScreen(screen)} nextScreen={() => handleNextScreen(screen)} />
  }, [screen, handleBackScreen, handleNextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    switch (screen) {
      case 0:
        const srcImg = `/images/my-adresso-journey/session/07/img_002.webp`
        return (
          <Session07Wrapper buttons={buttons}>
            <div className="session-style">
              <img src={srcImg} alt="" />
            </div>
          </Session07Wrapper>
        )

      case 1:
        return <ScreenCoreConcepts01 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
      case 2:
        return <ScreenCoreConcepts02 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
      case 3:
        return <ScreenCoreConcepts03 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
      case 4:
        return <ScreenCoreConcepts04 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
      case 5:
        return <ScreenCoreConcepts05 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
      case 6:
        return <ScreenCoreConcepts06 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
      case 7:
        return <ScreenCoreConcepts07 showModal={showModal} nextScreen={() => handleNextScreen(screen)} backScreen={() => handleBackScreen(screen)} />
    }
  }, [screen, showModal, handleBackScreen, handleNextScreen])

  return <>{screenCoreConcepts}</>
}
