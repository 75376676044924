import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DATA_MY_HEALTH } from '../../constants'
import { useHeartScoreResultPage } from '../../hooks/pages/heart-score-result'
import { useLocales } from '../../locales'
import { Loading } from '../Common'
import { DashboardSection } from '../Common/DashboardSection'
import { ResponsiveWrapper } from '../Common/ResponsiveWrapper'
import { MyAdessoMyHealthStep } from '../../constants/introduction'

type Props = {
  refs?: React.Ref<any>[]
  isIntroduction?: boolean
}

const REF: any = {
  0: MyAdessoMyHealthStep.HeartScore,
  1: MyAdessoMyHealthStep.LabTestResult,
  2: MyAdessoMyHealthStep.HealthRecommendations,
}

const MyHealthContent = ({ refs = [], isIntroduction = false }: Props) => {
  const { t } = useLocales()
  const [screen, setScreen] = useState(-1)
  const navigate = useNavigate()

  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()
  const dataResultHeartScore = resultHeartScore?.data?.results

  const handlePrevScreen = () => {
    setScreen(-1)
  }

  useEffect(() => {
    if (resultHeartScore?.data || isIntroduction) return
    getResultHeartScore()
  }, [resultHeartScore?.data])

  const myRxRecommendations = useMemo(() => {
    return (
      <>
        {resultHeartScore?.isLoading ? (
          <Loading />
        ) : (
          <div className="flex justify-between md:col-span-2">
            <div
              className={`mr-4 flex h-[130px] w-[130px] items-center justify-center rounded-full border-[10px] border-color-${
                isIntroduction ? 'medium' : dataResultHeartScore?.totalStatus
              }-risk  font-['Lato'] text-[44px] leading-none text-color-${isIntroduction ? 'medium' : dataResultHeartScore?.totalStatus}-risk`}
            >
              {isIntroduction ? '75' : dataResultHeartScore?.totalScore}
            </div>
            <div className="flex w-[calc(100%-146px)] flex-col justify-center">
              <h3 className={`text-[24px] capitalize text-color-${isIntroduction ? 'medium' : dataResultHeartScore?.totalStatus}-risk`}>
                {t(`${isIntroduction ? 'medium' : dataResultHeartScore?.totalStatus} Risk`)}
              </h3>
            </div>
          </div>
        )}

        <div className="flex flex-col gap-4">
          {DATA_MY_HEALTH.map((item, index) => {
            return (
              <div key={`${item.id}-${index}`} ref={refs[REF[index]] ?? null}>
                {item.id === 2 && (
                  <div>
                    <h3 className="text-[15px] font-semibold uppercase">MY RX Recommendations</h3>
                    <p className="text-[16px] font-medium">We encourage you to set an intentional plan for your heart health. Create your own recommendations here:</p>
                  </div>
                )}
                <div onClick={() => navigate(`${item?.link}`)} className={`flex h-[62px] w-[100%] cursor-pointer flex-row items-center gap-4 rounded-[20px] bg-${item.bgColor} p-3 text-[#FFF]`}>
                  <div className={`${item.id === 2 || item.id === 3 ? 'h-[40px]' : 'h-[29px]'} w-[33px] bg-${item.icon} bg-no-repeat`} />
                  <div className="uppercase">{item.label}</div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }, [resultHeartScore, dataResultHeartScore, screen, resultHeartScore?.isLoading])

  return (
    <ResponsiveWrapper>
      <DashboardSection className="space-y-4 pb-2" title={t('mainMenuAndSection.myHealth')}>
        {myRxRecommendations}
      </DashboardSection>
    </ResponsiveWrapper>
  )
}

export default MyHealthContent
