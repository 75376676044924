import { useState, useCallback, useMemo, useEffect } from 'react'
import { Session10Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { FormField, ErrorMessage } from '../../../../Common'

import { useForm } from '../../../../../hooks/use-form'
import { Question, CoreConceptsStops } from '../../../../../models'

type Props = {
  contentScreen: CoreConceptsStops
  questions: Question[]
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers?: any
}

export const ScreenCoreConceptsQuestion01 = ({ contentScreen, questions, showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    setIsError(false)
  }, [contentScreen])

  const initForm = questions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenCoreConceptsQuestion = useMemo(() => {
    return (
      <Session10Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1">
            {contentScreen.title && <h4>{contentScreen.title}</h4>}

            {contentScreen.img && <img src={contentScreen.img} alt="" />}

            <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />

            {formik.values['ss10__mynow'] && (
              <>
                <p>Here it is again:</p>
                <h4>{formik.values['ss10__mynow']}</h4>
                <p>Does this Now still ring true for you or is it the Now you want to establish for the next phase in your journey? If not, then please adjust it below.</p>
              </>
            )}

            {questions.map((item) => {
              return (
                <FormField
                  after={item.after || undefined}
                  key={`ss10_${item.name}`}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session10Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenCoreConceptsQuestion}</>
}
