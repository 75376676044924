import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'

import { BarData } from '../../models/heartChart'
import { getHeartChartBar } from '../../lib/request/get-heartChart'
import { mapDataChartBar } from '../../lib/maps/heart-chart-bar'

export function useHeartChartBarPage() {
  const [dataBarDrinkingResult, setDataBarDrinkingResult] = useState<AdessoResult<BarData[]>>()
  const [dataBarSleepResult, setDataBarSleepResult] = useState<AdessoResult<BarData[]>>()
  const [dataBarSmokingResult, setDataBarSmokingResult] = useState<AdessoResult<BarData[]>>()
  const [dataBarStepsResult, setDataBarStepsResult] = useState<AdessoResult<BarData[]>>()

  const auth = useAuth()

  const getDataHeartChartBarDrinking = useCallback(async (type?: string, typeDate?: string) => {
    setDataBarDrinkingResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartBar('drinking', type)
        if (res.data) {
          const dataChartBar = mapDataChartBar(res.data, typeDate)
          setDataBarDrinkingResult({
            isLoading: false,
            data: dataChartBar,
            error: undefined,
          })
        } else {
          setDataBarDrinkingResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataBarDrinkingResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart drinking Error'),
      })
    }
  }, [])

  const getDataHeartChartBarSmoking = useCallback(async (type?: string, typeDate?: string) => {
    setDataBarSmokingResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartBar('smoking', type)
        if (res.data) {
          const dataChartBar = mapDataChartBar(res.data, typeDate)
          setDataBarSmokingResult({
            isLoading: false,
            data: dataChartBar,
            error: undefined,
          })
        } else {
          setDataBarSmokingResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataBarSmokingResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart smoking Error'),
      })
    }
  }, [])

  const getDataHeartChartBarSteps = useCallback(async (type?: string, typeDate?: string) => {
    setDataBarStepsResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartBar('steps', type)
        if (res.data) {
          const dataChartBar = mapDataChartBar(res.data, typeDate)
          setDataBarStepsResult({
            isLoading: false,
            data: dataChartBar,
            error: undefined,
          })
        } else {
          setDataBarStepsResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataBarStepsResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart steps Error'),
      })
    }
  }, [])

  const getDataHeartChartBarSleep = useCallback(async (type?: string, typeDate?: string) => {
    setDataBarSleepResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartBar('sleep', type)
        if (res.data) {
          const dataChartBar = mapDataChartBar(res.data, typeDate)
          setDataBarSleepResult({
            isLoading: false,
            data: dataChartBar,
            error: undefined,
          })
        } else {
          setDataBarSleepResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataBarSleepResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart sleep Error'),
      })
    }
  }, [])

  return {
    dataBarDrinkingResult,
    getDataHeartChartBarDrinking,
    dataBarSmokingResult,
    getDataHeartChartBarSmoking,
    dataBarStepsResult,
    getDataHeartChartBarSteps,
    dataBarSleepResult,
    getDataHeartChartBarSleep,
  }
}
