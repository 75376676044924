import { ChangeEvent } from 'react'

type Props = {
  id?: string
  type: string
  name: string
  value?: string
  placeholder?: string
  className?: string
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const FormInput = ({ id, type, name, value, placeholder, className, onChange }: Props) => {
  const isTextarea = type === 'textarea'
  return isTextarea ? (
    <textarea
      className={`border-none ${className ? className : 'input h-input w-full rounded-none bg-white'}`}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    ></textarea>
  ) : (
    <input
      className={`border-none ${className ? className : 'input h-input w-full rounded-none bg-white'}`}
      id={id}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}
