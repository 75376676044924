import { useMemo } from 'react'
import { Session01Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            Let’s kick off this experience by diving into your first “Big Idea.”
            <br />
            <br />
            In this big idea we're talking about what it really means to live from the heart. We'll learn that heart health is so much more than what you may have heard. It is the center of your
            wellbeing and key to a healthy life.
            <br />
            <br />
            Play the video:
          </p>

          <VimeoEmbed embedId="806959784" />
        </div>
      </Session01Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
