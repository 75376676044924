import axios from 'axios'
import { AdessoError } from "../error";

type AddGoalResponse = {
    success: boolean
    data: any
    message: string
}

export async function postAddCoupon ( coupon: any ): Promise<AddGoalResponse> {
    const { data, status } = await axios.post( "/coupon/add", { ...coupon } )

    if ( status !== 200 ) {
        throw new AdessoError( `Request coupon error - ${status} ${JSON.stringify( data )}` )
    }

    return data;
}
