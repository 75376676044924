import { HeartScoreResult, ResultStatus, DetailResultData } from '../../models/heartscore-result'

const contents = [
  `This puts you in the <b class='text-color-low-risk'>Low-risk</b> category. We encourage you to embrace your health and well-being by starting the MyAdesso Learning Journey. Stay vital and fabulous by empowering yourself with how to continue living the most heart-healthy life you can! Congratulations. See you there!`,
  `This puts you in the <b class='text-color-medium-risk'>Moderate-risk</b> category. We are so happy to have you become part of the MyAdesso Learning Journey to help you empower yourself with information to address the issues that you can change. 
  <br/>We can help you reach your goals and ultimate wellness.  Come join us on this journey.
  <br/>
  <br/>We suggest you join our clinical program to have testing to create your own personalized diet, exercise, and stress management program. This will be created specifically for YOU.
  <br/>
  <br/><b>Call your doctor's office/human resources department/employee benefits to get enrolled. Can't wait for you to get started.</b>`,
  `
    This puts you in the <b class='text-color-high-risk'>High-risk</b> category. Don\'t worry...we want to make sure that you and your heart are okay!
    <br/>
    <br/><b>Call your doctor's office/human resources department/employee benefits to find a cardiologist on your plan.</b>
    <br/>
    <br/>Once you are evaluated, you can join our clinical program to have testing done to create your own personalized diet, exercise, and stress management program. This will be created specifically for YOU, as soon and we have the ok from the cardiologist.
    <br/>
    <br/>Bring your assessment with you so your doctor can see our concerns.
    <br/>In the meantime, you have become part of the MyAdesso Learning Journey to help you empower yourself with information to address the issues that you can change.
  `,
]

function mapResultDetail(data: any[]): DetailResultData[] {
  return data.map((item, index) => {
    return {
      category: item.title || '',
      score: +item.score || 0,
      scoreRaw: +item.raw_score || 0,
      status: mapResultStatus(item.result || ''),
    }
  })
}

function mapResultContent(type: ResultStatus): string {
  switch (type) {
    case ResultStatus.HIGH:
      return contents[2]
    case ResultStatus.MEDIUM:
      return contents[1]
    default:
      return contents[0]
  }
}

function mapResultStatus(type: string): ResultStatus {
  switch (type) {
    case 'Max':
      return ResultStatus.HIGH
    case 'High':
      return ResultStatus.HIGH
    case 'Moderate':
      return ResultStatus.MEDIUM
    case 'Medium':
      return ResultStatus.MEDIUM
    case 'N/A':
      return ResultStatus['N/A']
    default:
      return ResultStatus.LOW
  }
}

export function mapDataHeartScoreResult(data: any[]): HeartScoreResult | undefined {
  if (data.length > 0) {
    const details = mapResultDetail(data)
    const totalScore = details[0].score
    const totalRawScore = details[0].scoreRaw
    const totalStatus = details[0].status
    const content = mapResultContent(totalStatus)

    return { content, totalScore, totalRawScore, totalStatus, details: details.filter((item) => item.category !== 'Total') }
  }

  return undefined
}
