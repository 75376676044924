import { useCallback, useState } from 'react'
import { useHeartJournal } from '../use-heart-journal'
import { Goal } from '../../models/goal'
import { AdessoResult } from '../../models'
import { useAuth } from '../use-auth'
import { HeartJournal, HeartJournalMininum } from '../../models/heart-journal'
import { mapTodayGoals } from '../../lib/maps/goal'
import { getCalendarHistory, getHJHistoryAnswer } from '../../lib/request/get-my-heart-journals'

type GetMyGoalsResponse = {
  goals?: Goal[]
}

type GetRemainingGoalsResponse = {
  goals?: Goal[]
}

type HeartJournalResponse = {
  heartJournal?: HeartJournal
}

type MyHeartJournalResponse = {
  myHeartJournals?: HeartJournalMininum[]
}

export function useHeartJournalPage() {
  const [myGoalsResult, setMyGoals] = useState<AdessoResult<GetMyGoalsResponse>>()
  const [remainingGoalsResult, setRemainingGoals] = useState<AdessoResult<GetRemainingGoalsResponse>>()
  const [heartJournalResult, setHeartJournalResult] = useState<AdessoResult<HeartJournalResponse>>()
  const [myHeartJournalsResult, setMyHeartJournalsResult] = useState<AdessoResult<MyHeartJournalResponse>>()

  const auth = useAuth()
  const heartJournal = useHeartJournal()

  const getHeartJournal = useCallback(async (date: string) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setHeartJournalResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await heartJournal.heartJournal(date)
        if (res.success) {
          setHeartJournalResult({
            data: { heartJournal: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setHeartJournalResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setHeartJournalResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Heart Journal error'),
      })
    }
  }, [])

  const getMyHeartJournals = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMyHeartJournalsResult({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await heartJournal.myHeartJournals()
        if (res.success) {
          setMyHeartJournalsResult({
            data: { myHeartJournals: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setMyHeartJournalsResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setMyHeartJournalsResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get My Heart Journals error'),
      })
    }
  }, [])

  const getMyGoals = useCallback(async (showProgress: boolean) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setMyGoals({
          isLoading: true,
          data: undefined,
          error: undefined,
        })
        const res = await heartJournal.myGoals(showProgress)
        if (res.success) {
          setMyGoals({
            data: { goals: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setMyGoals({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setMyGoals({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get My Goals error'),
      })
    }
  }, [])

  const getRemainingGoals = useCallback(async () => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        setRemainingGoals({
          data: undefined,
          isLoading: true,
          error: undefined,
        })
        const res = await heartJournal.remainingGoals()
        if (res.success) {
          setRemainingGoals({
            data: { goals: res.data },
            isLoading: false,
            error: undefined,
          })
        } else {
          setRemainingGoals({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (e) {
      setRemainingGoals({
        isLoading: false,
        data: undefined,
        error: auth.throwError(undefined, 'Get Remaining Goals error'),
      })
    }
  }, [])

  const addGoal = useCallback(async (id?: number, slug?: string) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await heartJournal.addGoal(id, slug)
        if (res.success && res.data) {
          const todayGoals = mapTodayGoals(res.data)
          setMyGoals({
            data: { goals: todayGoals.myGoals },
            isLoading: false,
            error: undefined,
          })

          setRemainingGoals({
            data: { goals: todayGoals.remainingGoals },
            isLoading: false,
            error: undefined,
          })
        }
      }
    } catch (e) {}
  }, [])

  const removeGoal = useCallback(async (id: number) => {
    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await heartJournal.removeGoal(id)
        if (res.success && res.data) {
          const todayGoals = mapTodayGoals(res.data)
          setMyGoals({
            data: { goals: todayGoals.myGoals },
            isLoading: false,
            error: undefined,
          })

          setRemainingGoals({
            data: { goals: todayGoals.remainingGoals },
            isLoading: false,
            error: undefined,
          })
        }
      }
    } catch (e) {}
  }, [])

  const getCalendarHistories = useCallback(async (startDate: string, endDate: string) => {
    try {
      return getCalendarHistory(startDate, endDate)
    } catch (error) {
      return null
    }
  }, [])

  const getHJHistory = useCallback(async (date: string, goalId: number) => {
    try {
      return getHJHistoryAnswer(date, goalId)
    } catch (error) {
      return null
    }
  }, [])

  

  return {
    heartJournalResult,
    getHeartJournal,
    myHeartJournalsResult,
    getMyHeartJournals,
    myGoalsResult,
    remainingGoalsResult,
    getMyGoals,
    getRemainingGoals,
    addGoal,
    removeGoal,
    getHJHistory,
    getCalendarHistories,
  }
}
