import { Question, QuestionTypes, Parent, OptionValue } from '../../models/question'

function mapOptions(options: string[]) {
  return (
    options &&
    options.map((option, index) => {
      return { value: index, label: option }
    })
  )
}

export function mapQuestion(name: string, type: QuestionTypes, question?: string, placeholder?: string, options?: string[], isRequired: boolean = true, parent?: Parent<OptionValue>): Question {
  return { name, type, question, placeholder, options: options ? mapOptions(options) : undefined, isRequired, parent }
}

export function mapQuestionNotRequired(
  name: string,
  type: QuestionTypes,
  question?: string,
  placeholder?: string,
  options?: string[],
  isRequired: boolean = false,
  parent?: Parent<OptionValue>
): Question {
  return { name, type, question, placeholder, options: options ? mapOptions(options) : undefined, isRequired, parent }
}
