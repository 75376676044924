import {useNavigate, useParams} from "react-router-dom";
import {HeartJournalArchiveContent} from "../../components/HeartJournalArchive";
import {useEffect, useMemo} from "react";
import {useHeartJournalPage} from "../../hooks/pages/heart-journal";

export const HeartJournalArchive = () => {
  const {heartJournalResult, getHeartJournal} = useHeartJournalPage()

  const navigate = useNavigate()
  const {date} = useParams()

  if (date === undefined) {
    navigate('/heart-journal')
  }

  useEffect(() => {
    if (date) getHeartJournal(date)
  }, [])


  const heartJournalDetailPage = useMemo(() => {
    const isLoading = heartJournalResult?.isLoading || false
    const heartJournal = heartJournalResult?.data?.heartJournal || undefined
    return <HeartJournalArchiveContent date={date ?? ''} isLoading={isLoading} heartJournal={heartJournal}/>
  }, [heartJournalResult])

  return <>{heartJournalDetailPage}</>
}
