import React, { createContext, useState, useContext } from 'react'

interface IChildren {
  children: React.ReactNode
}

interface IMovementContext {
  addDataMovementRx: any
  editDataMovementRx: any
  addDataMovement: (addDataMovementRx: any) => void
  editDataMovement: (editDataMovementRx: any) => void
  removeDataMovement: (addDataMovementRx: any) => void
}

const MovementContext = createContext<IMovementContext>({
  addDataMovementRx: {},
  editDataMovementRx: {},
  addDataMovement: () => undefined,
  editDataMovement: () => undefined,
  removeDataMovement: () => undefined,
})

const defaultAddMovementData = {
  target_heart_rate: '',
  times_per_week: '',
  minutes: '',
  activity: '',
  approved_by_physician: false,
}

const MovementRxProvider = ({ children }: IChildren) => {
  const [addDataMovementRx, setAddDataMovementRx] = useState<any>(defaultAddMovementData)
  const [editDataMovementRx, setEditDataMovementRx] = useState<any>(defaultAddMovementData)

  const addDataMovement = (data: any) => {
    setAddDataMovementRx(data)
    // setAddDataMovementRx([...addDataMovementRx, data])
  }

  const editDataMovement = (data: any) => {
    setEditDataMovementRx(data)
    // setEditDataMovementRx([...addDataMovementRx, data])
  }

  // const removeDataMovement = (data: any) => {
  //   setAddDataMovementRx(addDataMovementRx.filter((item: any) => item !== data))
  // }

  const removeDataMovement = () => {
    setAddDataMovementRx(defaultAddMovementData)
  }

  return (
    <MovementContext.Provider
      value={{
        addDataMovementRx,
        editDataMovementRx,
        addDataMovement,
        editDataMovement,
        removeDataMovement,
      }}
    >
      {children}
    </MovementContext.Provider>
  )
}
const useContextMovementRx = () => {
  return useContext(MovementContext)
}

export { MovementRxProvider, useContextMovementRx }
