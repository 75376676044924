import React, { useRef, useEffect, useState, memo } from 'react'
import * as d3 from 'd3'
import { useLocales } from '../../../locales'
import { HeartChartLine } from '../../../models/heart-chart-line'
import { eachDayOfInterval, endOfMonth, startOfMonth, subMonths, format } from 'date-fns'
import { convertTypeButton } from '../../../lib/maps/heart-chart-line'
import { IconLoading } from '../../Common'

type PropsLineChart = {
  series: HeartChartLine[]
  width: number
  height: number
  selectedButton?: number
  isLoading?: boolean
}

type Props = {
  data: HeartChartLine[]
  colorButton: string
  title: string
  width: number
  height: number
  setSelectedButtonIndex: (index: number) => void
  selectedButtonIndex: number
  isLoading?: boolean
}

type ScaleOptionKeys = 'week' | 'month' | 'sixMonths'

interface CommentatorsChartProps {
  data: HeartChartLine[]
}
const LineChart: React.FC<PropsLineChart> = memo(({ series, width, height, selectedButton, isLoading }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)

  const margin = { top: 20, right: 30, bottom: 40, left: 50 }
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  const scaleOptions: Record<ScaleOptionKeys, string[]> = {
    week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    month: eachDayOfInterval({
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    }).map((day) => format(day, 'd')),
    sixMonths: Array.from({ length: 6 }, (_, i) => format(subMonths(new Date(), i), 'MMM yyyy')).reverse(),
  }

  const selectedScaleOption: ScaleOptionKeys = convertTypeButton[selectedButton || 0] as ScaleOptionKeys
  const daysOfWeek = scaleOptions[selectedScaleOption] || scaleOptions.week

  useEffect(() => {
    if (!svgRef.current) return

    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove()

    const pointPadding = innerWidth / (daysOfWeek.length * 2)

    const xScale = d3
      .scalePoint()
      .domain(daysOfWeek)
      .range([pointPadding, innerWidth - pointPadding])

    const minY = 0

    let maxY = Math.max(160, ...series.flatMap((ser) => ser.values.filter((v) => v !== null)))
    maxY = Math.ceil(maxY / 10) * 10

    // Generate tick values dynamically
    const tickCount = 16
    const tickStep = Math.ceil((maxY - minY) / tickCount / 10) * 10
    const yAxisTickValues = d3.range(minY, maxY + tickStep, tickStep)

    const yScale = d3.scaleLinear().domain([minY, maxY]).range([innerHeight, 0])
    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`)

    d3.range(10, 161, 10).forEach((value) => {
      g.append('line').attr('x1', 0).attr('x2', innerWidth).attr('y1', yScale(value)).attr('y2', yScale(value)).attr('stroke', '#F0F0F0').attr('stroke-width', 0.25)
    })

    // Draw grid lines for each day
    daysOfWeek.forEach((day, index) => {
      g.append('line')
        .attr('x1', xScale(day) ?? 0)
        .attr('x2', xScale(day) ?? 0)
        .attr('y1', 0)
        .attr('y2', innerHeight)
        .attr('stroke', '#e2e8f0')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')
    })

    series.forEach((ser) => {
      const filteredData = ser.values.map((value, i) => ({ x: daysOfWeek[i], y: value })).filter((d) => d.y !== null)
      const lineGenerator = d3
        .line<{ x: string; y: number }>()
        .x((d) => xScale(d.x) || 0)
        .y((d) => yScale(d.y))

      g.append('path').datum(filteredData).attr('fill', 'none').attr('stroke', ser.color).attr('stroke-width', 1).attr('d', lineGenerator)

      g.selectAll(`.circle-${ser.name}`)
        .data(filteredData)
        .enter()
        .append('circle')
        .attr('class', (_, i) => `circle-${ser.name}-${i}`)
        .attr('cx', (d) => xScale(d.x) || 0)
        .attr('cy', (d) => yScale(d.y))
        .attr('r', 3.5)
        .attr('fill', 'white')
        .attr('stroke', ser.color)
        .attr('stroke-width', 1)
    })

    // Add and style X axis

    const xAxisGroup = g.append('g').attr('transform', `translate(0,${innerHeight})`).call(d3.axisBottom(xScale).tickSize(0))

    xAxisGroup.select('.domain').remove()

    g.append('line').attr('x1', 0).attr('x2', innerWidth).attr('y1', innerHeight).attr('y2', innerHeight).attr('stroke', '#A3A3A3')

    xAxisGroup.selectAll('text').style('fill', '#A3A3A3').style('font-family', 'Proxima Nova').style('font-weight', '500').style('font-size', '7.8px').style('letter-spacing', '0.15px')

    xAxisGroup.select('.domain').style('stroke', '#A3A3A3')

    // Add and style Y axis
    const yAxisGroup = g.append('g').call(d3.axisLeft(yScale).tickValues(yAxisTickValues).tickSize(0))
    yAxisGroup.selectAll('.tick line').style('stroke', 'none')

    yAxisGroup.selectAll('text').style('fill', '#A3A3A3').style('font-family', 'Proxima Nova').style('font-weight', '500').style('font-size', '7.8px').style('letter-spacing', '0.15px')

    yAxisGroup.select('.domain').style('stroke', '#A3A3A3')
  }, [series, width, height, selectedButton, isLoading])

  return (
    <div ref={containerRef} style={{ width: width, height: height, position: 'relative' }} className="flex items-center justify-center">
      {isLoading ? <IconLoading /> : <svg ref={svgRef} width="100%" height="100%" />}
    </div>
  )
})

export const ContentLineChart: React.FC<Props> = memo(({ data, colorButton, setSelectedButtonIndex, title, width, height, selectedButtonIndex, isLoading }) => {
  const { t } = useLocales()
  const containerRef = useRef<HTMLDivElement>(null)

  const [selectedButton, setSelectedButton] = useState(selectedButtonIndex)
  const dataGroup = [t('heartChartMain.groupButton.week'), t('heartChartMain.groupButton.month'), t('heartChartMain.groupButton.sixMonth')]
  const [chartSize, setChartSize] = useState({ width: width, height: height })

  useEffect(() => {
    function handleResize() {
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : width
      const chartWidth = window.innerWidth < 768 ? width : containerWidth
      const chartHeight = window.innerWidth < 768 ? height : height + 100
      setChartSize({ width: chartWidth, height: chartHeight })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [width, height])
  const Commentators: React.FC<CommentatorsChartProps> = ({ data }) => {
    return (
      <section className="mt-10 flex w-full justify-between">
        {data.map((group) => (
          <div key={group.name} className="flex items-center ">
            <span className={`mr-1 h-[12px]  w-[12px] rounded-full `} style={{ backgroundColor: group.color }} />
            <p className="font-['Roboto'] text-[12px] font-[400] ">{group.name}</p>
          </div>
        ))}
      </section>
    )
  }
  const GroupButton = () => {
    const getButtonStyle = (isSelected: boolean) => ({
      backgroundColor: isSelected ? colorButton : '#ffffff',
      color: isSelected ? '#ffffff' : '#000000',
      borderRadius: '9999px',
      padding: '8px 16px',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '21px',
      fontFamily: "'Proxima Nova', sans-serif",
    })

    return (
      <div className="flex justify-around">
        {dataGroup.map((item, index) => {
          const isSelected = selectedButton === index
          return (
            <button
              key={index}
              style={getButtonStyle(isSelected)}
              onClick={() => {
                setSelectedButton(index)
                setSelectedButtonIndex(index)
              }}
            >
              <p className='font-["Proxima Nova"] text-[15px] font-[500] leading-[21px]'>{item}</p>
            </button>
          )
        })}
      </div>
    )
  }
  return (
    <div ref={containerRef} className="mt-3 w-full">
      <p className="font-['Proxima Nova'] justify-start text-[15px] font-[600] leading-[21px]">{title}</p>
      <Commentators data={data} />
      <div className="flex w-full flex-col items-center justify-center">
        <LineChart series={data} width={chartSize.width} height={chartSize.height} selectedButton={selectedButton} isLoading={isLoading} />
      </div>
      <GroupButton />
    </div>
  )
})
