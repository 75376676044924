import { useMemo } from 'react'

import { ButtonCommon, ButtonStyles, SubmitButtonCommon } from '../../Common'
import { useLocales } from '../../../locales'

type Props = {
  style?: ButtonStyles
  isYesNoType?: boolean
  isDisable?: boolean
  isSubmit?: boolean
  isSymptoms?: boolean
  isLoading?: boolean
  showModal?: () => void
  nextScreen?: (screen_type?: boolean) => void
  backScreen?: () => void
  jumpScreen?: () => void
  contactProvider?: () => void
}

export const HeartScoreButtons = ({
  contactProvider,
  style,
  isDisable = false,
  isYesNoType = false,
  isSubmit = false,
  isSymptoms = false,
  isLoading = false,
  showModal,
  backScreen,
  nextScreen,
  jumpScreen,
}: Props) => {
  const { t } = useLocales()

  const heartScoreButtons = useMemo(() => {
    if (isSymptoms) {
      return (
        nextScreen && (
          <div className="flex-col space-y-5 ">
            <ButtonCommon
              style={style}
              width="w-40"
              height="h-[50px]"
              text={t('heartScore.button.yes')}
              onClick={() => {
                nextScreen(true)
              }}
            />
            <ButtonCommon
              style={style}
              width="w-40"
              height="h-[50px]"
              text={t('heartScore.button.no')}
              onClick={() => {
                nextScreen(false)
              }}
            />
            <ButtonCommon
              style={style}
              width="w-40"
              height="h-[50px]"
              text={t('heartScore.button.doNot')}
              onClick={() => {
                nextScreen(false)
              }}
            />
          </div>
        )
      )
    }

    if (isYesNoType) {
      return (
        <div className={`flex ${showModal ? 'justify-between' : 'justify-center space-x-2'}`}>
          {backScreen && (
            <div>
              <ButtonCommon style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.back')} onClick={backScreen} />
            </div>
          )}
          {jumpScreen && (
            <div>
              <ButtonCommon style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.skip')} onClick={jumpScreen} />
            </div>
          )}
          {nextScreen && (
            <div>
              {isSubmit ? (
                <SubmitButtonCommon style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.next')} isLoading={isLoading} onClick={nextScreen} />
              ) : (
                <ButtonCommon style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.next')} onClick={nextScreen} />
              )}
            </div>
          )}
        </div>
      )
    }
    return (
      <div className={`flex ${showModal ? 'justify-between' : 'justify-center space-x-2'}`}>
        {contactProvider && (
          <div
            onClick={contactProvider}
            className="flex w-[347px] max-w-[347px] cursor-pointer items-center justify-center self-center whitespace-nowrap rounded-[50px] bg-[#FF3399] p-4 px-10 uppercase text-[#FFF]"
          >
            <h3 className="font-proxima-nova text-[15px] font-medium">{t('heartScore.button.contact')}</h3>
          </div>
        )}
        {backScreen && (
          <div>
            <ButtonCommon style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.back')} onClick={backScreen} />
          </div>
        )}
        {showModal && (
          <div>
            <ButtonCommon style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.show')} onClick={showModal} />
          </div>
        )}
        {nextScreen && (
          <div>
            {isSubmit ? (
              <SubmitButtonCommon disabled={isDisable} style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.next')} isLoading={isLoading} onClick={nextScreen} />
            ) : (
              <ButtonCommon disabled={isDisable} style={style} width="w-28" height="h-[50px]" text={t('heartScore.button.next')} onClick={nextScreen} />
            )}
          </div>
        )}
      </div>
    )
  }, [style, isSubmit, isLoading, isDisable, showModal, backScreen, nextScreen])

  return <>{heartScoreButtons}</>
}
