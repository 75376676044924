import { Goal, GoalFromApiResponse, TodayGoals } from '../../models/goal'
import { OptionValue, Question, QuestionOption, QuestionTypes } from '../../models'
import { mapAPIOptions, mapAPIQuestionTypes } from './heart-score-questions'

const arrOptionsType = [QuestionTypes.RadioDropdown, QuestionTypes.CheckboxMulti, QuestionTypes.Radio]

export function mapGoal(goal: GoalFromApiResponse, showProgress: boolean = false): Goal {
  return {
    id: goal.id,
    name: goal.name,
    slug: goal.slug,
    description: goal.description,
    order: goal.order,
    textColor: goal.text_color,
    backgroundColor: goal.background_color,
    completed: goal.completed,
    addedAt: goal.added_at,
    showProgress: showProgress,
    title: goal.title,
    goalTrackingId: goal.goal_tracking_id,
  }
}

function mapGoalQuestion(question: any): Question | undefined {
  if (question.name && question.type) {
    const type = mapAPIQuestionTypes(question.type)
    if (type) {
      let options: QuestionOption<OptionValue>[] | undefined = undefined

      if (arrOptionsType.includes(type) && question.options) {
        options = mapAPIOptions(question.options)
      }

      let parent: { value: string; parentName: string } | undefined = undefined
      if (question.parent && question.parent.value && question.parent.parentName) {
        parent = { value: question.parent.value, parentName: question.parent.parentName }
      }

      return {
        id: question.id,
        name: question.name,
        type,
        hideLabel: question.hide_label,
        inputType: question.input_type,
        question: question.question || undefined,
        isRequired: question.is_required,
        parent,
        options,
        value: question.answer || (type === QuestionTypes.Rate ? 3 : undefined),
        placeholder: question.placeholder || '',
        fields: question.fields || undefined,
        tableOptions: question.table_options || undefined,
        order: question.order,
        is_enable: question.is_enable,
        real_type: question.type,
      }
    }
  }

  return undefined
}

export function mapGoalQuestions(data: Question[]): Question[] {
  if (data.length > 0) {
    let dataReturn: Question[] = []
    data.map((question) => {
      const questionReturn = mapGoalQuestion(question)
      if (questionReturn) {
        dataReturn = [...dataReturn, questionReturn]
      }
    })
    if (dataReturn) return dataReturn
  }

  return []
}

export function mapTodayGoals(goals: GoalFromApiResponse[]): TodayGoals {
  let myGoals: Goal[] = []
  let remainingGoals: Goal[] = []
  goals.map(function (goal, index) {
    let mappedGoal = mapGoal(goal)
    if (goal.added_at) {
      myGoals = [...myGoals, mappedGoal]
    } else {
      remainingGoals = [...remainingGoals, mappedGoal]
    }
  })
  if (myGoals.length) {
    myGoals.sort((firstGoal: Goal, secondGoal: Goal) => {
      if (firstGoal.addedAt && secondGoal.addedAt) {
        return firstGoal.addedAt < secondGoal.addedAt ? 1 : firstGoal.addedAt > secondGoal.addedAt ? -1 : 0
      } else {
        return firstGoal.order > secondGoal.order ? 1 : firstGoal.order < secondGoal.order ? -1 : 0
      }
    })
  }
  if (remainingGoals.length) {
    myGoals.sort((firstGoal: Goal, secondGoal: Goal) => {
      return firstGoal.order > secondGoal.order ? 1 : firstGoal.order < secondGoal.order ? -1 : 0
    })
  }
  return {
    myGoals,
    remainingGoals,
  }
}
