import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import { AuthMiddleware, LoginMiddleware } from './components/Route/RouteMiddleware'

import { configAxiosDefault, configTokenAPI } from './lib/open-api'
import { useLocalStorage } from './hooks/use-local-storage'
import * as Sentry from '@sentry/react'
import App from './App'
import './index.css'

import { LoginPage } from './pages/Login'
import { LogoutPage } from './pages/Logout'
import { RegisterPage } from './pages/Register'
import { ForgotPasswordPage } from './pages/ForgotPassword'
import { ForgotPasswordActionPage } from './pages/ForgotPasswordAction'
import { ForgotPasswordSentPage } from './pages/ForgotPasswordSent'

import { PrivacyPolicy } from './components/PrivacyPolicy'

import { SubscriptionPage } from './pages/Subscription'
import { HeartScorePage } from './pages/HeartScore'
import { HeartScoreResultPage } from './pages/HeartScore/Result'

import { GoDeeperLibraryPage } from './pages/GoDeeper'
import { GoDeeperDetailPage } from './pages/GoDeeper/Detail'

import { Landing } from './pages/Dashboard/Landing'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { Setting } from './pages/Setting/Setting'
import { Welcome } from './pages/Dashboard/Welcome'
import { Explanation } from './pages/Dashboard/Explanation'
import { Introduction } from './pages/Dashboard/Introduction'
import { HeartJournalPage } from './pages/HeartJournal'
import { HeartJournalAction } from './pages/HeartJournal/HeartJournalAction'
import { GoDeeperFinish } from './pages/Deeper/GoDeeperFinish'
import { HeartChart, HeartChartV2 } from './pages/HeartChart/HeartChart'
import { HeartChartResult } from './pages/HeartChart/HeartChartResult'
import { HeartChartTesting } from './pages/HeartChart/HeartChartTesting'
import { GoalTrackingConfigurationPage } from './pages/GoalTrackingConfiguration'

import { DailyTrackingPage } from './pages/DailyTracking'

import { LayoutWrapper } from './components/Layouts/'
import { FullLayout } from './components/Layouts/FullLayout'
import { NoFooter } from './components/Layouts/NoFooter'

import { Onboard } from './pages/Onboard'
import { MyAdessoJourney } from './pages/MyAdessoJourney'
import { MAJSession } from './pages/MyAdessoJourney/Session'
import { MAJourneyReviced } from './pages/MyAdessoJourney/reviced'
import { MAJourneyGoDeeper } from './pages/MyAdessoJourney/go-deeper'
import { HeartJournalArchive } from './pages/HeartJournalArchive'
import { OAuthPage } from './pages/OAuth'

import { GlobalModal } from './hooks/modals/global-modal'
import { SessionLayoutWrapper } from './components/Layouts/SessionLayout'
import { GodeeperLayoutWrapper } from './components/Layouts/GodeeperLayout'

//flagsmith
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'

//i18
import './locales/i18n'
import { HeaderProvider } from './hooks/modals/global-set-header'
import { LearningJourneyTutorial } from './pages/MyTutorials/LearningJourneyTutorial'
import { BeatsProgress } from './pages/HeartChart/BeatsProgress'
import { AlertProvider } from './context/AlertContext'

//my-health
import { HeartScoreMyHealthPage, LabTestResultsPage, LifeStyleRxPage, MedicationsRxPage, MovementRxPage, MyHealthPage, NourishRxPage, SupplementsRxPage } from './pages/MyHealth'

//my-tutorials
import MyTutorials from './pages/MyTutorials'
import MyHealthTutorial from './pages/MyTutorials/MyHealthTutorial'
import GTCAndDTTutorial from './pages/MyTutorials/GoalAndDailyTrackingTutorial'
import HeartChartTutorial from './pages/MyTutorials/HeartChartTutorial'

Sentry.init({
  dsn: 'https://03aead6ca01142d5bc6ba6f9c7d04199@o4505279090327552.ingest.sentry.io/4505283202908160',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/stage\.myadesso\.health/, /^https:\/\/myadesso\.health/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <FlagsmithProvider
    options={{
      environmentID: import.meta.env.VITE_FLAGSMITH_CLIENT_KEY,
    }}
    flagsmith={flagsmith}
  >
    <AlertProvider>
      <GlobalModal>
        <HeaderProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<App />}>
                <Route path="/" element={<Landing />} />

                <Route path="/" element={<LayoutWrapper noFooter />}>
                  <Route path="welcome" element={<Welcome />} />
                </Route>

                <Route path="/" element={<LayoutWrapper />}>
                  <Route path="archived-heart-chart" element={<AuthMiddleware component={<HeartChart />} />} />
                  <Route path="heart-chart" element={<AuthMiddleware component={<HeartChartV2 />} />} />
                  <Route path="heart-chart/beats-progress" element={<AuthMiddleware component={<BeatsProgress />} />} />
                  <Route path="dashboard" element={<AuthMiddleware component={<Dashboard />} />} />
                  <Route path="introduction" element={<AuthMiddleware component={<Introduction />} />} />
                  <Route path="go-deeper" element={<AuthMiddleware component={<GoDeeperLibraryPage />} />} />
                  <Route path="go-deeper/finish" element={<AuthMiddleware component={<GoDeeperFinish />} />} />
                  <Route path="my-adesso-journey" element={<AuthMiddleware component={<MyAdessoJourney />} />} />
                  <Route path="heart-chart/testing" element={<AuthMiddleware component={<HeartChartTesting />} />} />
                  <Route path="goal-tracking-configuration" element={<AuthMiddleware component={<GoalTrackingConfigurationPage />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper />}>
                  <Route path="my-tutorials" element={<AuthMiddleware component={<MyTutorials />} />} />
                  <Route path="my-tutorials/learning-journey" element={<AuthMiddleware component={<LearningJourneyTutorial />} />} />
                  <Route path="my-tutorials/my-health" element={<AuthMiddleware component={<MyHealthTutorial />} />} />
                  <Route path="my-tutorials/goal-and-daily-tracking" element={<AuthMiddleware component={<GTCAndDTTutorial />} />} />
                  <Route path="my-tutorials/heart-chart" element={<AuthMiddleware component={<HeartChartTutorial />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper />}>
                  <Route path="my-health" element={<AuthMiddleware component={<MyHealthPage />} />} />
                  <Route path="my-health/heart-score" element={<AuthMiddleware component={<HeartScoreMyHealthPage />} />} />
                  <Route path="my-health/lab-test-results" element={<AuthMiddleware component={<LabTestResultsPage />} />} />
                  <Route path="my-health/medications-rx" element={<AuthMiddleware component={<MedicationsRxPage />} />} />
                  <Route path="my-health/move-rx" element={<AuthMiddleware component={<MovementRxPage />} />} />
                  <Route path="my-health/nourish-rx" element={<AuthMiddleware component={<NourishRxPage />} />} />
                  <Route path="my-health/supplements-rx" element={<AuthMiddleware component={<SupplementsRxPage />} />} />
                  <Route path="my-health/lifestyle-rx" element={<AuthMiddleware component={<LifeStyleRxPage />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper hasbgcolor />}>
                  <Route path="heart-journal" element={<AuthMiddleware component={<HeartJournalPage />} />} />
                  <Route path="heart-journal/actions/:slug" element={<AuthMiddleware component={<HeartJournalAction />} />} />
                  <Route path="heart-journal/archive/:date" element={<AuthMiddleware component={<HeartJournalArchive />} />} />
                  <Route path="heart-chart/result" element={<AuthMiddleware component={<HeartChartResult />} />} />
                  <Route path="setting" element={<AuthMiddleware component={<Setting />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper hasbgcolor />}>
                  <Route path="daily-tracking" element={<AuthMiddleware component={<DailyTrackingPage />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper headerAbsolute hasbgcolor />}>
                  <Route path="my-adesso-journey/go-deeper" element={<AuthMiddleware component={<MAJourneyGoDeeper />} />} />
                  <Route path="my-adesso-journey/go-deeper/:slug" element={<AuthMiddleware component={<MAJourneyGoDeeper />} />} />
                </Route>

                <Route path="/" element={<GodeeperLayoutWrapper headerAbsolute />}>
                  <Route path="go-deeper/:slug" element={<AuthMiddleware component={<GoDeeperDetailPage />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper noHeader />}>
                  <Route path="my-adesso-journey/reviced" element={<AuthMiddleware component={<MAJourneyReviced />} />} />
                  <Route path="my-adesso-journey/reviced-next-level" element={<AuthMiddleware component={<MAJourneyReviced />} />} />
                </Route>

                <Route path="/" element={<SessionLayoutWrapper />}>
                  <Route path="my-adesso-journey/:slug" element={<AuthMiddleware component={<MAJSession />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper noHeader hasbgcolor />}>
                  <Route path="explanation" element={<AuthMiddleware component={<Explanation />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper noFooter />}>
                  <Route path="login" element={<LoginMiddleware component={<LoginPage />} />} />
                  <Route path="auth/:token" element={<OAuthPage />} />
                  <Route path="logout" element={<AuthMiddleware component={<LogoutPage />} />} />
                  <Route path="register" element={<RegisterPage />} />

                  <Route path="subscription" element={<SubscriptionPage />} />
                </Route>

                <Route path="/" element={<LayoutWrapper noFooter hasbgcolor />}>
                  <Route path="forgot-password" element={<ForgotPasswordPage />} />
                  <Route path="forgot-password-success" element={<ForgotPasswordSentPage />} />
                  <Route path="change-password" element={<ForgotPasswordActionPage />} />
                  <Route path="onboard" element={<Onboard />} />
                  <Route path="heart-score" element={<AuthMiddleware component={<HeartScorePage />} />} />
                  <Route path="heart-score/result" element={<AuthMiddleware component={<HeartScoreResultPage />} />} />
                </Route>

                <Route path="/" element={<LayoutWrapper noFooter />}>
                  <Route path="legal/privacy" element={<PrivacyPolicy />} />
                </Route>
                <Route path="/" element={<LayoutWrapper noFooter />}>
                  <Route path="legal/privacy" element={<PrivacyPolicy />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </HeaderProvider>
      </GlobalModal>
    </AlertProvider>
  </FlagsmithProvider>
)
