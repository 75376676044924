import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HeartAction01 } from './HeartAction01'
import { ScreenHeartGoalTracking } from './HeartGoalTracking'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  slogan: string
  takeActions?: string[]
  goHeartAction: () => void
  sessionId: number
  takeActionHint?: string
  buttons?: React.ReactNode
  goalTrackingConfig?: any
  isButtonBack?: boolean
}

enum Screen {
  CoreConcepts01,
  CoreConcepts02,
}

export const LayoutHeartAction = ({ goalTrackingConfig, slogan, takeActions, goHeartAction, sessionId, takeActionHint, buttons, isButtonBack = false }: Props) => {
  const [screen, setScreen] = useState(isButtonBack ? Screen.CoreConcepts02 : 0)
  const [isLoading, setIsloading] = useState<boolean>(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tabGtcFromDT = Number(searchParams.get('tab-gtc-from-dt') as string)

  const postAddGoalAction = async () => {
    setIsloading(true)
    // const data = await postAddGoal(sessionId)
    setIsloading(false)

    // if (data.message == 'existed') {
    // setScreen(1)
    // } else {
    // goHeartAction()
    setScreen(1)
    // }
  }

  const handleNextScreen = useCallback((stepCurrent: Screen) => {
    if (stepCurrent == 1) {
      goHeartAction()
    } else {
      postAddGoalAction()
    }
  }, [])

  useEffect(() => {
    if (tabGtcFromDT) {
      setScreen(1)
    } else {
      setScreen(0)
    }
  }, [])

  const LayoutHeartAction = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <HeartAction01
            isLoading={isLoading}
            slogan={slogan}
            takeActionHint={takeActionHint}
            takeActions={takeActions}
            goHeartAction={() => handleNextScreen(screen)}
            skipHeartAction={() => handleNextScreen(1)}
          />
        )
      case 1:
        return <ScreenHeartGoalTracking buttons={buttons} sessionId={sessionId} goalTrackingConfig={goalTrackingConfig} />
      // return <HeartAction02 slogan={slogan} goHeartAction={() => handleNextScreen(screen)} />
    }
  }, [handleNextScreen, screen, isLoading, goalTrackingConfig])

  return <>{LayoutHeartAction}</>
}
