import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Results from './Results'
import CreateMyNourish from './CreateMyNourish'
import { FormValues, PramsNourish } from '../../../models/my-heart'
import { useNourishPage } from '../../../hooks/pages/nourish'
import { useGoDeeperLibraryPage } from '../../../hooks/pages/go-deeper'
import { ResponsiveWrapper } from '../../Common/ResponsiveWrapper'

type Props = {
  prevScreen?: () => void
  // nextScreen?: () => void
}

type PropsOption = {
  id: number
  recommendation_type: number
  title: string
}

const NutritionRx = ({ prevScreen }: Props) => {
  const { postDataResult, submitNourish, getNourishRx, getOptionsOfNourishRx, dataOptionsNourish, stateCreate, editEnabled } = useNourishPage()
  const { dataDeepers, getDataDeepers } = useGoDeeperLibraryPage()
  const [screen, setScreen] = useState(0)
  const [edit, setEdit] = useState(false)

  const listOptions = dataOptionsNourish?.data
  const dataResult = postDataResult?.data || {}
  const deepers = dataDeepers?.data?.deepers || []

  useEffect(() => {
    const fetchNourishContent = async () => {
      try {
        await getOptionsOfNourishRx().catch()
        await getDataDeepers().catch()
      } catch (error) {}
    }
    fetchNourishContent()
  }, [])

  useEffect(() => {
    const fetchNourishResult = async () => {
      try {
        await getNourishRx().catch()
      } catch (error) {}
    }
    fetchNourishResult()
  }, [])

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = (edit?: boolean) => {
    console.log(edit)

    if (edit) {
      setEdit(true)
    } else {
      setEdit(false)
    }
    setScreen((prev) => prev + 1)
  }

  const handleSubmit = useCallback(
    (params: FormValues, toggle?: boolean): void => {
      const optionIds = dataOptionsNourish?.data?.reduce<number[]>((acc, item: PropsOption) => {
        if (params[item.title] === true) {
          acc.push(item.id)
        }
        return acc
      }, [])
      const statusApprovedByPhysician: boolean = params['approvedByPhysician'] || false

      const newParams: PramsNourish = {
        option_id: optionIds as number[],
        approved_by_physician: statusApprovedByPhysician,
      }
      if (toggle) {
        submitNourish(newParams, params, toggle)
      } else {
        submitNourish(newParams, params)
        handlePrevScreen()
      }
    },
    [dataOptionsNourish]
  )

  const NutritionRx = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <Results
            stateCreate={stateCreate}
            deepers={deepers}
            postDataResult={dataResult}
            prevScreen={prevScreen}
            nextScreen={handleNextScreen}
            isLoading={postDataResult?.isLoading === true && editEnabled}
            isLoadingToggle={!editEnabled}
            isLoadingDeepers={dataDeepers?.isLoading === true}
            submit={handleSubmit}
          />
        )
      case 1:
        return (
          <CreateMyNourish
            edit={edit}
            postDataResult={dataResult}
            prevScreen={handlePrevScreen}
            submit={handleSubmit}
            dataOptions={listOptions}
            isLoading={dataOptionsNourish?.isLoading === true || postDataResult?.isLoading}
          />
        )
    }
  }, [screen, postDataResult, dataOptionsNourish, dataDeepers, stateCreate, editEnabled])

  return <ResponsiveWrapper>{NutritionRx}</ResponsiveWrapper>
}

export default NutritionRx
