import { useMemo } from 'react'
import { Session09Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session09Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            In this session, we’re going to discuss the impact of hormones on the health of your heart. We’ll help you understand the impact of hormones on your heart health and some strategies to
            navigate fluctuations in hormones. And, we will talk about adverse outcomes of pregnancy and what to do after delivery to stay healthy.
            <br /> <br />
            Watch the video below to begin.
          </p>

          <VimeoEmbed embedId="808487424" />
        </div>
      </Session09Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
