type Props = {
  title?: string
  className?: string
  children?: React.ReactNode
  jumpTo?: React.Ref<any>
}

export const DashboardSection = ({ title, className, jumpTo, children }: Props) => {
  return (
    <section className={className} ref={jumpTo}>
      {title && <h3 className="font-['Butler'] text-[24px] leading-[1.41667]">{title}</h3>}
      {children}
    </section>
  )
}
