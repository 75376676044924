import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'

import { HeartChartLine } from '../../models/heart-chart-line'
import { mapDataChartLineBloodPressure, mapDataChartLineWeight } from '../../lib/maps/heart-chart-line'
import { getHeartChartLineBloodPressure, getHeartChartLineWeight } from '../../lib/request/get-heartChart'

export function useHeartChartLinePage() {
  const [dataBloodPressureResult, setDataBloodPressureResult] = useState<AdessoResult<HeartChartLine[]>>()
  const [dataWeightResult, setDataWeightResult] = useState<AdessoResult<HeartChartLine[]>>()

  const auth = useAuth()

  const getDataHeartChartBloodPressure = useCallback(async (type?: string) => {
    setDataBloodPressureResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartLineBloodPressure(type)

        if (res.data) {
          const dataChartBloodPressure = mapDataChartLineBloodPressure(res.data, type)
          setDataBloodPressureResult({
            isLoading: false,
            data: dataChartBloodPressure,
            error: undefined,
          })
        } else {
          setDataBloodPressureResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataBloodPressureResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart blood pressure Error'),
      })
    }
  }, [])

  const getDataHeartChartWeight = useCallback(async (type?: string) => {
    setDataWeightResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartLineWeight(type)

        if (res.data) {
          const dataChartWeight = mapDataChartLineWeight(res.data, type)
          setDataWeightResult({
            isLoading: false,
            data: dataChartWeight,
            error: undefined,
          })
        } else {
          setDataWeightResult({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataWeightResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart weight Error'),
      })
    }
  }, [])

  return { dataBloodPressureResult, getDataHeartChartBloodPressure, dataWeightResult, getDataHeartChartWeight }
}
