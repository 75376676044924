import { useState, useEffect, useMemo, useCallback } from 'react'
import { ModalProgress, SessionButtons, LayoutHeartAction, LayoutBeatsReviced } from '../layout'
import { ScreenBigIdeasVideo, ScreenHeartAssessment, ScreenCoreConcepts, ScreenHeartToHeart, ScreenHeartReflection } from './screen'

import { SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../../../models'
import { dummyProgressDataSS01 } from '../../../../lib/data/session-01'

import { putJourneysCurrentStep } from '../../../../lib/request/put-journeys-current-step'
import { ScreenHeartGoalTracking } from '../layout/HeartGoalTracking'
import { useBoolean } from '../../../../hooks/use-boolean'
import { useReplacePage } from '../replacePage'

type Step = {
  session_id: any
  step: any
  sub_step: any
}

type Props = {
  isLoading?: boolean
  isSuccess?: boolean
  onSubmit: (params: object) => void
  backTop: () => void
  step: Step
  answers: any
}

export const Session08 = ({ isLoading, isSuccess, onSubmit, backTop, step, answers }: Props) => {
  const [values, setValues] = useState<object>({})
  const [screen, setScreen] = useState(step.step)

  const [isShowProgressModal, setIsShowProgressModal] = useState<boolean>(false)
  const [progressData, setProgressData] = useState<ProgressScreenType[]>(dummyProgressDataSS01)

  const isButtonBack = useBoolean()

  const replacePage = useReplacePage()

  const saveScreen = (screen: any) => {
    if (screen !== 5) {
      replacePage()
    }
    setScreen(screen)
    putJourneysCurrentStep({ journey_id: step.session_id, article_id: screen, step: 0 })
  }

  useEffect(() => {
    const newProgress = dummyProgressDataSS01.map((item, index) => {
      if (screen >= index) item.status = ProgressScreenStatus.Done
      return item
    })

    setProgressData(newProgress)
  }, [screen])

  useEffect(() => {
    if (isSuccess) saveScreen(SessionScreen.HeartAction)
  }, [isSuccess])

  const handleGoToScreen = useCallback((screenID: SessionScreen) => {
    isButtonBack.onFalse()
    saveScreen(screenID)
  }, [])

  const handleToggleProgressModal = useCallback((isShow: boolean) => {
    setIsShowProgressModal(isShow)
  }, [])

  const handleBackScreen = useCallback((stepCurrent: SessionScreen) => {
    if (stepCurrent === SessionScreen.BigIdeasVideo) {
      backTop()
    } else {
      isButtonBack.onTrue()
      window.scrollTo(0, 0)
      saveScreen(stepCurrent - 1)
    }
  }, [])

  const handleNextScreen = useCallback((stepCurrent: SessionScreen) => {
    if (stepCurrent < 6) {
      isButtonBack.onFalse()
      console.info('handleNextScreen', stepCurrent)
      window.scrollTo(0, 0)
      saveScreen(stepCurrent + 1)
    }
  }, [])

  const handleUpdateParams = useCallback((oldValues: object, params: object) => {
    setValues({ ...oldValues, ...params })
    onSubmit({ ...oldValues, ...params, goNext: false })
  }, [])

  const handleSubmit = useCallback((oldValues: object, params: object) => {
    setValues({ ...oldValues, ...params })
    onSubmit({ ...oldValues, ...params, goNext: true })
  }, [])

  const buttons = useMemo(() => {
    return (
      <SessionButtons
        styleButton={'dark'}
        isLoading={isLoading}
        showModal={() => handleToggleProgressModal(true)}
        backScreen={() => handleBackScreen(screen)}
        nextScreen={() => handleNextScreen(screen)}
      />
    )
  }, [isLoading, screen, handleBackScreen, handleNextScreen, handleToggleProgressModal])

  const congratulationContent = useMemo(() => {
    return (
      <div className="space-y-6 text-center text-white">
        <h1 className="w-full font-butler text-[35px] leading-[39px]">You did it! You’ve completed this step in your heart journey</h1>

        <p className="w-full text-[20px] font-semibold leading-[24px]">
          You’ve received 10 heart beats.
          <br />
          <br />
          To unlock your next level, work to complete your actions and keep advancing through your heart journey.
        </p>
      </div>
    )
  }, [])

  const session01 = useMemo(() => {
    switch (screen) {
      case 0:
        return <ScreenBigIdeasVideo isLoading={isLoading} buttons={buttons} />
      case 1:
        return (
          <ScreenHeartAssessment
            isButtonBack={isButtonBack.value}
            answers={answers}
            step={step}
            showModal={() => handleToggleProgressModal(true)}
            backScreen={() => handleBackScreen(screen)}
            nextScreen={() => handleNextScreen(screen)}
            onUpdate={(params: object) => handleUpdateParams(values, params)}
          />
        )
      case 2:
        return (
          <ScreenCoreConcepts
            isButtonBack={isButtonBack.value}
            answers={answers}
            step={step}
            showModal={() => handleToggleProgressModal(true)}
            backScreen={() => handleBackScreen(screen)}
            nextScreen={() => handleNextScreen(screen)}
            onUpdate={(params: object) => handleUpdateParams(values, params)}
          />
        )
      case 3:
        return <ScreenHeartToHeart buttons={buttons} />
      case 4:
        return (
          <ScreenHeartReflection
            answers={answers}
            isLoading={isLoading}
            showModal={() => handleToggleProgressModal(true)}
            backScreen={() => handleBackScreen(screen)}
            onSubmit={(params: object) => handleSubmit(values, params)}
          />
        )
      case 5:
        return <ScreenHeartGoalTracking buttons={buttons} sessionId={8} />
      // const takeActions = ['Track your weight, resting heart rate, and heart rate during exercise.', 'Journal about your efforts to improve your numbers.', 'Rate your ability to exercise']
      // return <LayoutHeartAction takeActions={takeActions} sessionId={8} slogan="“Better understand and track your numbers.”" goHeartAction={() => handleNextScreen(screen)} />
      default:
        return <LayoutBeatsReviced textContent={congratulationContent} isNextLevel showModal={() => handleToggleProgressModal(true)} journeyId={8} />
    }
  }, [isButtonBack.value, isLoading, values, buttons, screen, handleBackScreen, handleNextScreen, handleUpdateParams, handleSubmit, handleToggleProgressModal])

  const modalProgress = useMemo(() => {
    return <ModalProgress progress={progressData} isShowModal={isShowProgressModal} closeModal={() => handleToggleProgressModal(false)} goToScreen={handleGoToScreen} />
  }, [progressData, isShowProgressModal, handleToggleProgressModal, handleGoToScreen])

  return (
    <>
      {session01}
      {modalProgress}
    </>
  )
}
