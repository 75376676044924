import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../../hooks/use-local-storage'

export function configAxiosDefault() {
  const { deleteItemLS } = useLocalStorage()
  const location = useLocation()
  const navigate = useNavigate()
  const axiosService = axios

  axiosService.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response.status === 401) {
        deleteItemLS('token')
        deleteItemLS('user')
        deleteItemLS('amplitudeUser')
        deleteItemLS('amplitudeEvent')
        deleteItemLS('amplitude_heart_score_survey_questions')
        navigate('/login')
      } else if (err.response.status === 400 && err.response.data?.error_code === 10001 && location.pathname !== '/register') {
        navigate('/register')
      } else {
        return Promise.reject(err)
      }
    }
  )

  const axiosDefault = axiosService.defaults
  axiosDefault.baseURL = import.meta.env.VITE_APP_API_URL
  axiosDefault.withCredentials = true
  axiosDefault.headers.common['Content-Type'] = 'application/json'

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  axiosDefault.headers.common['Time-Zone'] = timeZone
  return axiosDefault
}

export function configTokenAPI(token: string) {
  return token ? (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`) : ''
}
