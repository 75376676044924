import { Session01Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenHeartAssessment02 = ({ buttons }: Props) => {
  return (
    <Session01Wrapper buttons={buttons}>
      <video className="block h-auto w-full" controls autoPlay muted>
        <source src="https://api.myadesso.health/uploads/animation/ss_01.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Session01Wrapper>
  )
}
