import { useState, useCallback, useMemo } from 'react'
import { Session03Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion03Questions } from '../../../../../lib/data/session-03'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'
import { CoreConceptsStops } from '../../../../../models'

const ssHAQuestions = [sesstion03Questions[16]]

type Props = {
  content: CoreConceptsStops
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers?: any
}

export const ScreenCoreConceptsStop01 = ({ content, showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const screenCoreConceptsStop01 = useMemo(() => {
    return (
      <Session03Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="session-style flex-1 space-y-6">
            <h4>{content.title}</h4>

            <picture className="">
              <source srcSet={`/images/my-adresso-journey/session/03/${content.imgWebp}`} type="image/webp" />
              <img src={`/images/my-adresso-journey/session/03/${content.img}`} alt="" />
            </picture>

            <div className="space-y-6" dangerouslySetInnerHTML={{ __html: content.content }} />

            <div className="space-y-10">
              {ssHAQuestions.map((item) => {
                return (
                  <FormField
                    isInputSS
                    key={`ss03_${item.name}`}
                    question={item}
                    value={formik.values[item.name]}
                    onChange={formik.handleChange}
                    onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                  >
                    {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                  </FormField>
                )
              })}
            </div>
          </div>

          {buttons}
        </form>
      </Session03Wrapper>
    )
  }, [content, formik, buttons, isError, handleChangeValue])

  return <>{screenCoreConceptsStop01}</>
}
