import {useMemo, ChangeEvent} from 'react'

import {Question, FieldTypes, QuestionTypes} from '../../../models'

type Props = {
  value?: any
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  fieldType: FieldTypes
  question: Question
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxField = ({value, disabled, question, onChange}: Props) => {
  const checkbox = useMemo(() => {
    return (
      <div>
        <input id={question.name} disabled={disabled} type="checkbox" name={question.name}
               className="peer hidden"
               value={value} onChange={onChange} checked={value == true}/>
        <label
          htmlFor={question.name}
          className="mx-0 block text-ma-md text-ma-pink-300 font-semibold cursor-pointer bg-[url('assets/images/icon/i-checkbox__uncheck.svg')] bg-[length:24px_auto] bg-[top_left] bg-no-repeat pl-7 pt-1 text-[16px] leading-[19px] peer-checked:bg-[url('assets/images/icon/i-checkbox__checked.svg')]"
        >
          <span>{question.question}</span>
        </label>
      </div>
    )
  }, [value, question])

  return <div className="space-y-3">{checkbox}</div>
}
