import { useMemo, useCallback, useState, useEffect } from 'react'
import { FormikOptions } from '../../../hooks/use-form'
import { Wrapper, ButtonStyles, Container, Loading } from '../../Common'
import { HeartScoreButtons, IntroductionLayout, QuizzesLayout } from '../layout'

import { Quizess } from '../../../models/question'
import { IntroPopup } from '../../Common/IntroPopup'
import { useLocales } from '../../../locales'
import { useAuth } from '../../../hooks/use-auth'
import { useHeartScorePage } from '../../../hooks/pages/heart-score'
import { LoadingSize } from '../../../models/loading'
import { getHighRiskFactors } from '../../../lib/request/get-heartscore-questions'
import { paramsOfSymptoms } from '../../../pages/HeartScore/constants'
import { trackAmplitude } from '../../../lib/utils/helper'

export enum ScreenTypes {
  Yes = 'Yes',
  No = 'No',
}

type screen = {
  title: string
  subTitle: string
  screen_type: ScreenTypes
  content_data: {
    footer: string
    header: string
    content: string
  }
}
type Props = {
  values: FormikOptions<any>
  isLoading?: boolean
  quizess: Quizess
  totalPage: number
  currentScreen: number
  currentPage: number
  backScreen: () => void
  nextScreen: (data?: object, nextPage?: boolean) => Promise<void>
  jumpScreen: (index: number) => void
}

export const QuizzesScreen = ({ values, quizess, currentPage, currentScreen, totalPage, isLoading, backScreen, nextScreen, jumpScreen }: Props) => {
  const { t } = useLocales()
  const auth = useAuth()
  const { getDataCheckHeartSymptoms, getCheckHeartSymptoms } = useHeartScorePage()

  const [parentScreenHaveType, setParentScreenHaveType] = useState(0)
  const { introduction, screens, answers } = quizess
  const introductionScreenIndex = -1

  const [screenIndex, setScreenIndex] = useState<number>(currentScreen)
  const [isNoHeartSymptomsScreen, setIsNoHeartSymptomsScreen] = useState<boolean>(false)
  const [isLoadingHeartSym, setLoadingHeartSym] = useState(false)
  const [indexScreenHighRisk, setIndexScreenHighRisk] = useState()
  const [dataScreenHighRisk, setDataScreenHighRisk] = useState()
  const KNOW_YOUR_NUMBER_SCREEN = 6

  useEffect(() => {
    setScreenIndex(currentScreen)
  }, [currentScreen])

  useEffect(() => {
    // Attach the beforeunload event listener
    const handleBeforeUnload = (event: any) => {
      event.preventDefault()
      auth.getMeResult()
      return
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (paramsOfSymptoms.currentPageOfSymptoms === 7 && screenIndex === paramsOfSymptoms.currentStepOfSymptoms) {
      const indexSymptoms = findScreenSymptoms(null, getDataCheckHeartSymptoms?.data ? ScreenTypes.Yes : ScreenTypes.No)
      setScreenIndex(indexSymptoms)
      setParentScreenHaveType(paramsOfSymptoms.stepPrevOfSymptoms)
    }
  }, [getDataCheckHeartSymptoms])

  useEffect(() => {
    if (!indexScreenHighRisk) {
      return
    }

    const fetchHighRiskFactor = async () => {
      setLoadingHeartSym(true)
      const dataHighRisk = await getHighRiskFactors()
      setLoadingHeartSym(false)
      setDataScreenHighRisk(dataHighRisk.data)
      return dataHighRisk
    }

    fetchHighRiskFactor()
  }, [indexScreenHighRisk])

  useEffect(() => {
    if (dataScreenHighRisk && indexScreenHighRisk) {
      screens[indexScreenHighRisk].content_data.contentHighRisk = dataScreenHighRisk
    }
  }, [dataScreenHighRisk])

  const handlePrevPage = useCallback(
    (screenCurrent: number) => {
      if (screenCurrent > introductionScreenIndex) {
        window.scrollTo(0, 0)

        setScreenIndex(screenCurrent - 1)

        if (currentPage === 7 && screenCurrent > 3) {
          setScreenIndex(parentScreenHaveType)
        }
      } else {
        backScreen()
      }
    },
    [screens, backScreen, parentScreenHaveType]
  )

  const findScreenSymptoms = (titleOfHighRisk: string | null, screenType: string) => {
    return screens.findIndex((screen: screen) => screen.screen_type === screenType && (titleOfHighRisk ? screen.title.toLowerCase().replace(/:$/, '') === titleOfHighRisk : true))
  }

  const handleSpecialNextScreen = async (screenCurrent: number, data?: object) => {
    setLoadingHeartSym(true)

    await nextScreen({ data, screen: screenCurrent })

    await getCheckHeartSymptoms()

    setLoadingHeartSym(false)
  }

  const handleNextScreen = useCallback(
    async (screenCurrent: number, data?: object, screen_type?: boolean) => {
      if (screenCurrent < screens.length - 1) {
        window.scrollTo(0, 0)
        setScreenIndex(screenCurrent + 1)

        if (currentPage === KNOW_YOUR_NUMBER_SCREEN) {
          window.scrollTo(0, 0)
          setScreenIndex(screenCurrent + 1)
        }

        if (currentPage === 7) {
          let screenType: string | null = null

          const generationType = (typeScreen?: boolean) => {
            return typeScreen ? ScreenTypes.Yes : ScreenTypes.No
          }
          if (screenCurrent > 3) {
            //On the symptoms page, if "Next" is clicked without selecting screen_type "Yes" or "No", it should submit to the completion page.
            if (screen_type !== undefined) {
              screenType = generationType(screen_type)
            } else {
              nextScreen({ data, screen: screenCurrent }, true)
              setScreenIndex(screenCurrent)
            }
          }

          if (screenType !== null) {
            const titleOfHighRisk = screenCurrent > 3 ? 'high risk factors' : null
            const nextScreenIndex = findScreenSymptoms(titleOfHighRisk, screenType)
            setIndexScreenHighRisk(nextScreenIndex)
            setScreenIndex(nextScreenIndex)
            setParentScreenHaveType(screenCurrent)
          }
        }

        if (data) {
          if (currentPage === 7 && screenCurrent === 3) {
            handleSpecialNextScreen(screenCurrent, data)
          } else {
            nextScreen({ data, screen: screenCurrent })
          }
        }
      } else {
        nextScreen({ data, screen: screenCurrent }, true)
        if (currentPage < totalPage - 1) setScreenIndex(introductionScreenIndex)
      }
    },
    [screens, nextScreen]
  )
  const buttons = useMemo(() => {
    const style = screenIndex > introductionScreenIndex ? ButtonStyles.Dark : ButtonStyles.Light
    return (
      <HeartScoreButtons
        isYesNoType={currentPage === KNOW_YOUR_NUMBER_SCREEN}
        isLoading={isLoading}
        style={style}
        backScreen={() => handlePrevPage(screenIndex)}
        nextScreen={() => {
          if (currentPage === KNOW_YOUR_NUMBER_SCREEN) {
            trackAmplitude('medical_data', { event_properties: { skip: false } })
          }
          handleNextScreen(screenIndex)
        }}
        jumpScreen={() => {
          if (currentPage === KNOW_YOUR_NUMBER_SCREEN) {
            trackAmplitude('medical_data', { event_properties: { skip: true } })
          }
          jumpScreen(8)
        }}
      />
    )
  }, [currentPage, isLoading, screenIndex, handlePrevPage, handleNextScreen, nextScreen, jumpScreen])

  const introductionScreen = useMemo(() => {
    return (
      <IntroductionLayout className="z-20" title={introduction.title} currentPage={currentPage} totalPage={totalPage} buttons={buttons}>
        <span dangerouslySetInnerHTML={{ __html: introduction.description }} />
      </IntroductionLayout>
    )
  }, [currentPage, totalPage, buttons])

  const noHeartSymptomsScreen = useMemo(() => {
    return (
      <Container bottom={buttons}>
        <div className="space-y-[21px]">
          <h1 className="text-ma-lg font-bold">No Heart Symptoms:</h1>

          <div>GREAT! You're doing well and not exhibiting any serious threat at this point. Continue on the journey to lower your overall risk and to ensure symptoms don't arise.</div>
        </div>
      </Container>
    )
  }, [buttons])

  const screenDisplay = useMemo(() => {
    const fieldValues = { ...answers, ...values }

    if (isLoadingHeartSym) {
      return (
        <div className="flex justify-center">
          <Loading size={LoadingSize.Small} isFull={true} />
        </div>
      )
    }

    return screenIndex > introductionScreenIndex ? (
      <QuizzesLayout
        values={fieldValues}
        currentPage={currentPage}
        currentScreen={screenIndex}
        quizess={screens[screenIndex]}
        isLoading={isLoading}
        backScreen={() => handlePrevPage(screenIndex)}
        nextScreen={(data?: object, screen_type?: boolean) => handleNextScreen(screenIndex, data, screen_type)}
      />
    ) : (
      <>{introductionScreen}</>
    )
  }, [isLoadingHeartSym, values, currentPage, isNoHeartSymptomsScreen, screenIndex, introductionScreen, noHeartSymptomsScreen, isLoading, handlePrevPage, handleNextScreen])

  const quizzesScreen = useMemo(() => {
    return (
      <Wrapper className={`text-ma-md ${screenIndex > introductionScreenIndex ? 'text-black' : 'text-white'}`}>
        {screenIndex == introductionScreenIndex && (
          <div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-white pt-20">
            <div className={`h-full bg-cover bg-center bg-no-repeat bg-heartscore-${currentPage + 1}`}></div>
          </div>
        )}
        {currentScreen >= 0 && <IntroPopup show={true} message={t('extraText.inactiveIntroPopup')} />}
        {screenDisplay}
      </Wrapper>
    )
  }, [currentPage, screenIndex, screenDisplay])

  return <>{quizzesScreen}</>
}
