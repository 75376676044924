import { Container } from '../../Common'
import { ProgressBar } from './ProgressBar'

type Props = {
  title?: string
  totalPage?: number
  currentPage?: number
  children?: React.ReactNode
  buttons?: React.ReactNode
  className?: string
}

export const IntroductionLayout = ({ title = '', totalPage = 0, currentPage = 0, children, buttons, className = '' }: Props) => {
  return (
    <Container className={className} bottom={buttons}>
      {totalPage !== 0 ? <ProgressBar currentPage={currentPage} totalPage={totalPage} /> : undefined}

      <div className="space-y-[21px]">
        {title !== '' ? <h1 className="text-ma-lg font-bold">{title}</h1> : undefined}

        <div>{children}</div>
      </div>
    </Container>
  )
}
