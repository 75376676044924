import { useState, useEffect } from 'react'
import { DashboardSection } from '../Common/DashboardSection'
import { getMyHeart } from '../../lib/request'
import { useLocales } from '../../locales'
import { TDataMyHeart, dataMyHeart } from '../../constants'

export const MyHeart = ({ jumpTo }: any) => {
  const [img, setImg] = useState('')
  const [description, setDescription] = useState('')
  const [bgColor, setBgColor] = useState('#FF3399')
  const [nextLevel, setNextLevel] = useState('')
  const [leftPoint, setLeftPoint] = useState(0)
  const { t } = useLocales()

  const getInfo = async () => {
    const data = await getMyHeart()

    if (data.success) {
      setImg(data.data.img)
      setDescription(data.data.des)
      setBgColor(data.data.bgColor)
      setNextLevel(data.data.nextLevel)
      setLeftPoint(data.data.leftPoint)
    }
  }

  const myHeart = dataMyHeart.map((item: TDataMyHeart, index: number) => {
    return (
      <div key={index} className="flex w-[calc(31%)] flex-col justify-between rounded-[15px] p-2 pb-0 " style={{ backgroundColor: bgColor }}>
        <div className=" order-1 flex flex-row justify-between">
          <div className={`h-[16px] w-[18px] bg-${item.statisticsIcon} bg-[length:auto] bg-no-repeat`} />
          <p className="break-word text-right font-proxima-nova text-[40px] font-semibold leading-9 text-white">{item?.statistics}</p>
        </div>

        <div className="order-2 flex justify-end">
          <p
            className="break-word text-right font-proxima-nova text-[23px] text-white"
            style={{
              fontFamily: 'Proxima Nova',
              fontWeight: '600',
              fontSize: index === 0 ? '40px' : '10px',
            }}
          >
            {item?.unit}
          </p>
        </div>

        <div className="order-3 mt-3 flex w-[calc(100%)] flex-row items-center space-x-1  pr-2">
          <p className="flex-row whitespace-nowrap text-[9px] font-medium uppercase text-white">{item?.nameStatistics}</p>
          <div className={`h-[9px] w-[8px] bg-${item.progressIcon} bg-[length:auto] bg-no-repeat`} />
        </div>
      </div>
    )
  })

  useEffect(function () {
    getInfo()
  }, [])

  return (
    <DashboardSection jumpTo={jumpTo} className="space-y-4" title={t('mainMenuAndSection.myHeart')}>
      <div className="flex justify-between space-x-3">{myHeart}</div>
    </DashboardSection>
  )
}
