import axios from 'axios'
import { AdessoError } from "../error";

export type GetUserSettingResponse = {
    success: boolean
    data: any
    message: string
}

export async function getUserSetting (): Promise<GetUserSettingResponse> {
    const { data, status } = await axios.get( '/users/setting' )

    if ( status !== 200 ) {
        throw new AdessoError( `Request user setting error - ${status} ${JSON.stringify( data )}` )
    }

    return data
}