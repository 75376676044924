import { useMemo } from 'react'
import { SessionWrapper } from '../layout'

type Props = {
  title?: string
  isLoading?: boolean
  hiddenHeader?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
  buttons?: React.ReactNode
}

export const Session09Wrapper = ({ hiddenHeader, isLoading, title, children, style, buttons }: Props) => {
  const sessionWrapper = useMemo(() => {
    return (
      <SessionWrapper
        buttons={buttons}
        isLoading={isLoading}
        hiddenHeader={hiddenHeader}
        title={title || `Balance: <br/>Hormones And Your Heart`}
        className={hiddenHeader ? '' : 'bg-[length:100%_155px]'}
        style={style || { backgroundImage: `url(/images/my-adresso-journey/session/09/bg.webp)` }}
        pcPannel="url(/images/my-adresso-journey/session/ss9_pc.webp)"
      >
        {children}
      </SessionWrapper>
    )
  }, [isLoading, hiddenHeader, title, children, style, buttons])

  return <>{sessionWrapper}</>
}
