import { useCallback, useEffect, useMemo, useState } from 'react'
import { Goal } from '../../../models/goal'
import { LoadingSize } from '../../../models/loading'
import { IconLoading } from '../../Common'
import { FormQuestions } from './FormQuestions'
import { goalTrackingConfig } from '../../../models/goalTrackingConfig'

type Props = {
  goalTracking: goalTrackingConfig
  goal: Goal
  date?: any
  history?: any
  refs?: React.Ref<any>[]
  setStateSubmit?: any
}

export const FormQuestionsContent = ({ goal, goalTracking, date, history, refs = [], setStateSubmit }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>()

  const handleAfterSubmitForm = useCallback(() => {
    setStateSubmit(true)
    setIsSubmitted(true)
  }, [])

  useEffect(() => {
    setIsSubmitted(goal?.completed)
  }, [goal])

  const formGoalQuestions = useMemo(() => {
    const questionData = goalTracking?.questions?.filter((f) => f.is_enable !== 0).sort((a: any, b: any) => a.order - b.order)

    if (!goal || !questionData?.length) {
      return (
        <div className="flex justify-center py-40">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    } else {
      return (
        <FormQuestions
          refs={refs}
          id={goalTracking.id}
          date={date}
          goalMemoId={goal?.goal_memo_id}
          backgroundColor={goal?.backgroundColor}
          className="pb-9"
          editable={!history?.length}
          completed={goal?.completed}
          questions={questionData}
          key={goal?.id}
          hideAfterSubmit={false}
          afterSubmit={handleAfterSubmitForm}
          answer={history}
          trackerAtionStatus={goalTracking.heart_journal_action}
          slug={goal.slug}
        />
      )
    }
  }, [goal, date, history, goalTracking])

  return <div>{formGoalQuestions}</div>
}
