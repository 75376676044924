import { useMemo } from 'react'

import { RegisterContent } from '../components/Register/'
import { Navigate } from 'react-router-dom'
import { useLocalStorage } from '../hooks/use-local-storage'
import { IUser } from '../models/user'

export const RegisterPage = () => {
  const { getItemLS } = useLocalStorage()
  const user = getItemLS('user') as IUser

  const registerContent = useMemo(() => {
    return user?.had_first_heart_score ? <Navigate to="/dashboard" replace /> : <RegisterContent />
  }, [])

  return <>{registerContent}</>
}
