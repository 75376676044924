import { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonCommon, ButtonStyles, IconLoading } from '../Common'
import { GoalDetail } from '../../models/goal'
import { NavLink, useNavigate } from 'react-router-dom'
import { HeartJournalSection } from '../HeartJournal/Section'
import { GoalQuestionsForm } from './GoalQuestionsForm'
import { LoadingSize } from '../../models/loading'
import { mapGoalQuestions } from '../../lib/maps/goal'
import { HeartJournalAction } from '../HeartJournal/Action'
import { useLocales } from '../../locales'

type Props = {
  goal?: GoalDetail
}

export const HeartJournalActionsContent = ({ goal }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>()
  const { t } = useLocales()
  const navigate = useNavigate()

  const handleAfterSubmitForm = useCallback(() => {
    setIsSubmitted(true)
  }, [])

  useEffect(() => {
    setIsSubmitted(goal?.completed)
  }, [goal])

  const formGoalQuestions = useMemo(() => {
    if (!goal) {
      return (
        <div className="flex justify-center py-40">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    } else {
      let mappedQuestions = mapGoalQuestions(goal.questions)

      return (
        <GoalQuestionsForm
          id={goal.id}
          goalMemoId={goal.goal_memo_id}
          backgroundColor={goal.backgroundColor}
          textColor={goal.textColor}
          className="pb-9"
          editable={!goal.completed}
          completed={goal.completed}
          questions={mappedQuestions}
          key={goal.id}
          hideAfterSubmit={false}
          afterSubmit={handleAfterSubmitForm}
        />
      )
    }
  }, [goal])

  const logMore = useMemo(() => {
    return (
      <div className="pb-10">
        <ButtonCommon style={ButtonStyles.Dark} width="w-[259px]" height="h-[50px]" text="LOG MORE ACTIONS" isRoudedDefault={true} onClick={() => navigate('/heart-journal')} />
      </div>
    )
  }, [goal])

  const goDeeper = useMemo(() => {
    return (
      <div className="mt-12 px-2 pb-10">
        <h4 className="relative text-ma-md font-bold text-ma-pink-300">Go Deeper (earn extra point)</h4>
        <div className="mt-6 grid grid-cols-1 gap-y-3">
          {goal?.articles &&
            goal.articles.map((article: any) => {
              return (
                <NavLink key={article.id} to={`/go-deeper/${article.slug}`} className="link text-base text-black">
                  {article.title}
                </NavLink>
              )
            })}
        </div>
      </div>
    )
  }, [goal])

  return (
    <HeartJournalSection className="mx-auto w-full max-w-[552px] px-5" title={`${goal?.name || t('mainMenuAndSection.heartJournal')} Actions`}>
      <HeartJournalAction description={goal?.description}>
        {formGoalQuestions}
        {goal && isSubmitted && logMore}
        {goal && goal.articles.length > 0 && isSubmitted && goDeeper}
      </HeartJournalAction>
    </HeartJournalSection>
  )
}
