import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
  success?: boolean
}

export async function getHeartScoreResult(params?: any): Promise<Response> {
  const { data, status } = await axios.get(`/heart-score-result/?date=${params?.date}`)

  if (status !== 200) {
    console.info('getHeartScoreQuestions', data)
    throw new AdessoError(`Request getHeartScoreQuestions error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartScoreHistory(params?: any): Promise<Response> {
  const { data, status } = await axios.get(`/heart-score-history/?start_date=${params?.start_date}&end_date=${params?.end_date}`)

  if (status !== 200) {
    console.info('getHeartScoreHistory', data)
    throw new AdessoError(`Request getHeartScoreHistory error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
