import axios from 'axios'
import { AdessoError } from '../error'

type TMedicationRxResponse = {
  data?: any
  message?: string
  success?: boolean
}

export async function getMedicationRx(): Promise<TMedicationRxResponse> {
  const { data, status } = await axios.get(`/medications`)

  if (status !== 200) {
    console.info('getMedicationRx', data)
    throw new AdessoError(`Request getMedicationRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMedicationRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function getMedicationRxById(id: number): Promise<TMedicationRxResponse> {
  const { data, status } = await axios.get(`/medications/${id}`)

  if (status !== 200) {
    console.info('getMedicationRxById', data)
    throw new AdessoError(`Request getMedicationRxById error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMedicationRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}

export async function deleteMedicationRx(id: number): Promise<TMedicationRxResponse> {
  const { data, status } = await axios.delete(`/medications/${id}`)
  if (status !== 200) {
    console.info('deleteMedicationRx', data)
    throw new AdessoError(`Request medication rx error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}

export async function putMedicationRx(id: number, dataUpdate: any): Promise<TMedicationRxResponse> {
  const { data, status } = await axios.put(`/medications/${id}`, dataUpdate)
  if (status !== 200) {
    console.info('putMedicationRx', data)
    throw new AdessoError(`Request update medication Rx error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}

export async function postMedicationRx(dataCreate: any): Promise<TMedicationRxResponse> {
  const { data, status } = await axios.post(`/medications`, dataCreate)

  if (status !== 200) {
    console.info('postMedicationRx', data)
    throw new AdessoError(`Request postMedicationRx error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: TMedicationRxResponse = data.success
    ? {
        data: data.data,
        message: data.message,
        success: data.success,
      }
    : {
        message: data.message,
        success: data.success,
      }

  return dataReturn
}
