export type HeartChartPie = {
  title: string
  goal_id: number
  color: string
  dates: string[] | null
}

export type DayData = {
  day: number
  value: number | null
}

export type GroupData = {
  label: string
  color: string
  days: DayData[]
}

export type BarData = {
  day: string
  value: number
}

export const convertStartDay: { [key: string]: number } = {
  '0': 7,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
}
export type stressTracking = {
  data: number[]
}
