import { ButtonStyles, WrapperCommon, ButtonCommon } from '../../../Common'
import { useLocales } from '../../../../locales'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Page {
  title: string
  link: string
}
type Props = {
  text: string
  linkPages?: Page[]
}
type MyHealthPagesProps = {
  pages: Page[]
}

export const EndMyHealth = ({ text, linkPages }: Props) => {
  const { t } = useLocales()
  const navigate = useNavigate()
  const { setShowHeader, setShowFeedback } = useHeaderContext()

  useEffect(() => {
    setShowHeader(false)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 100)
  }, [])

  const ReviewRecommendations = () => {
    return (
      <div className=" mx-2 w-full font-[700] leading-5 tracking-[0.15px]">
        <p className="my-6 text-[20px] ">{t('introduction.myAdessoMyHealth.finish.reviewRecommendations.title')}</p>
        <p className="mb-6 text-[16px]">{t('introduction.myAdessoMyHealth.finish.reviewRecommendations.text')}</p>
      </div>
    )
  }

  const MyHealthPage: React.FC<MyHealthPagesProps> = ({ pages }) => {
    return (
      <div className="my-6 w-full px-2 text-[18px] font-[500] leading-5 tracking-[0.15px]">
        <p className="my-6 text-[20px] font-[700] leading-5 tracking-[0.15px]">{t('introduction.myAdessoMyHealth.finish.pageSections.title')}</p>
        <p className="mb-6">{t('introduction.myAdessoMyHealth.finish.pageSections.line1')}</p>
        <p className="mb-6">{t('introduction.myAdessoMyHealth.finish.pageSections.line2')}</p>
        <ul className="mb-6 flex flex-col gap-6">
          {pages.map((page, index) => (
            <li
              key={index}
              className={`w-max cursor-pointer underline`}
              onClick={() => {
                navigate(page.link)
                setShowHeader(true)
                setShowFeedback(true)
              }}
            >
              {t(page.title)}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <WrapperCommon className="mt-14 min-h-[calc(100vh-74px)] bg-transparent text-white">
      <div className="fixed bottom-0 left-0 right-0 top-0 z-10 bg-white ">
        <div className="h-full bg-[url('assets/images/bg-end-of-onboarding-session.webp')] bg-cover bg-center bg-no-repeat"></div>
      </div>
      <div className="relative z-20 flex h-full w-full flex-col justify-between font-['Proxima_Nova']">
        <div className="w-full text-center ">
          <p className="mb-2 text-[35px] font-[400] leading-10">{t('introduction.myAdessoMyHealth.finish.title')}</p>
          <p className="text-[22px] font-[500] leading-7">{t(text) || ''}</p>
        </div>

        <ReviewRecommendations />

        <ButtonCommon
          className="uppercase"
          scrollMarginTop="scroll-mt-0"
          scrollMarginBottom="scroll-mt-0"
          style={ButtonStyles.EndOfOnboarding}
          width="w-[320px]"
          height="h-[51px]"
          text={t('introduction.myAdessoMyHealth.finish.reviewRecommendations.textButton')}
          onClick={() => {
            setShowHeader(true)
            setShowFeedback(true)
            navigate('/my-health')
          }}
        />

        <MyHealthPage pages={linkPages || []} />

        <ButtonCommon
          className="uppercase"
          scrollMarginTop="scroll-mt-0"
          scrollMarginBottom="scroll-mt-0"
          style={ButtonStyles.EndOfOnboarding}
          width="w-[237px]"
          height="h-[51px]"
          text={t('introduction.myAdessoMyHealth.finish.visitMyTutorial.textButton')}
          onClick={() => {
            setShowHeader(true)
            navigate('/my-tutorials')
          }}
        />
      </div>
    </WrapperCommon>
  )
}
