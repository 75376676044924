import React, { createContext, useState, useContext } from 'react'

interface IChildren {
  children: React.ReactNode
}

interface IMedicationContext {
  addDataMedicationRx: any
  editDataMedicationRx: any
  addDataMedication: (addDataMedicationRx: any) => void
  editDataMedication: (editDataMedicationRx: any) => void
  removeDataMedication: (addDataMedicationRx: any) => void
}

const MedicationContext = createContext<IMedicationContext>({
  addDataMedicationRx: {},
  editDataMedicationRx: {},
  addDataMedication: () => undefined,
  editDataMedication: () => undefined,
  removeDataMedication: () => undefined,
})

const defaultAddMedicationData = {
  prescription_name: '',
  how_to_take: '',
  single_dose: '',
  times_per_day: '',
  approved_by_physician: false,
}

const MedicationRxProvider = ({ children }: IChildren) => {
  const [addDataMedicationRx, setAddDataMedicationRx] = useState<any>(defaultAddMedicationData)
  const [editDataMedicationRx, setEditDataMedicationRx] = useState<any>(defaultAddMedicationData)

  const addDataMedication = (data: any) => {
    setAddDataMedicationRx(data)
    // setAddDataMedicationRx([...addDataMedicationRx, data])
  }

  const editDataMedication = (data: any) => {
    setEditDataMedicationRx(data)
    // setEditDataMedicationRx([...addDataMedicationRx, data])
  }

  // const removeDataMedication = (data: any) => {
  //   setAddDataMedicationRx(addDataMedicationRx.filter((item: any) => item !== data))
  // }

  const removeDataMedication = () => {
    setAddDataMedicationRx(defaultAddMedicationData)
  }

  return (
    <MedicationContext.Provider
      value={{
        addDataMedicationRx,
        editDataMedicationRx,
        addDataMedication,
        editDataMedication,
        removeDataMedication,
      }}
    >
      {children}
    </MedicationContext.Provider>
  )
}
const useContextMedicationRx = () => {
  return useContext(MedicationContext)
}

export { MedicationRxProvider, useContextMedicationRx }
