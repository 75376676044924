import { mapDataModalProgress, mapDataHeartRickFactors } from '../maps/session'
import { mapQuestion, mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 3),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 5),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

export const sesstion07Questions: Question[] = [
  mapQuestion('ss07__005', QuestionTypes.TextArea, 'What difficulties have you had navigating the medical system as a woman?', 'Describe what you want to change.'),
  mapQuestion('ss07__006', QuestionTypes.TextArea, 'What steps are you taking to ensure you prevent a serious heart emergency?', 'Describe what you want to change.'),
  mapQuestion(
    'ss07__007',
    QuestionTypes.TextArea,
    'After learning more about emergency symptoms, is there any action that you want to take to address your heart health or speak with a healthcare provider about?',
    'Describe what you want to change.'
  ),
  mapQuestion('ss07__008', QuestionTypes.TextArea, 'Are you happy with your healthcare provider or do you think that you might be able to find a better fit?', 'Describe what you want to change.'),
  mapQuestion('ss07__001', QuestionTypes.Radio, 'When was your last check-up/physical?', undefined, ['A few months ago', 'A year ago', 'A few years ago']),
  mapQuestion('ss07__002', QuestionTypes.Rate, 'How comfortable do you feel visiting your health provider’s office for routine check-ups or to address a health concern?'),
  mapQuestion('ss07__003', QuestionTypes.Rate, 'Rate yourself on how well you communicate your symptoms with your health provider.'),
  mapQuestion('ss07__004', QuestionTypes.Rate, 'Rate yourself on how well you advocate for your needs with your health provider.'),
]

export const sesstion09Questions: Question[] = [
  mapQuestionNotRequired('ss09__003', QuestionTypes.TextArea, 'How do you experience your hormones affecting you?', 'Describe what you want to change.'),
  mapQuestionNotRequired('ss09__001', QuestionTypes.CheckboxMulti, 'What best describes you? Check all that apply.', undefined, [
    'On birth control',
    'Pregnant',
    'Post-Pregnancy',
    'Perimenopause',
    'Menopause',
    'Postmenopause',
    'On Hormone Replacement Therapy (HRT)',
  ]),
  mapQuestionNotRequired('ss09__002', QuestionTypes.CheckboxMulti, 'Select any of these symptoms you are or have recently experienced:  ', undefined, [
    'Worsening or extreme PMS and mood swings',
    'Irregular menstrual cycles',
    'Loss of sex drive',
    'Hair loss',
    'Interrupted sleep',
    'Hot flashes',
    'Breast pain or tenderness',
    'Issues with fluid retention',
    'Nausea',
    'Leg cramps',
    'Headaches and migraines',
  ]),
]

function mapCoreConcepts(id: number, img: string | undefined, title: string | undefined, description: string = '', content: string = ''): CoreConceptsStops {
  return { id, img, title, description, content }
}
const ha01 = `
  <h4>Your hormones, your life</h4>
  <div>
    <p>Hormones isn’t exactly a sexy word. But ladies, let’s get real here: Few things are more important throughout our entire life! If you think about it, we are focused on our hormones from the moment we start menstruating.<p>
    <ul>
      <li>As teenagers, we are obsessed with when our periods are coming. Who wants to look bloated in a bathing suit with friends?</li>
      <li>In our 20s and 30s, we plot our cycles for just the right time of ovulation if we want to get pregnant.</li>
      <li>We hit our 40s, we start counting our periods as they become more irregular, holding onto the last shred of our estrogen</li>
      <li>In our 50s, when we hit menopause, our heart risk shoots up, which you’ll want to learn more about in this session.</li>
    </ul>
  </div>
`

const ha02 = `
  <h4>The wonder of Estrogen</h4>
  <p>Women are fundamentally different from men because of these hormones, specifically estrogen, which increases during the beginning of menses and decreases as we go through menopause.</p>
  <p>Estrogen is very protective to the lining of the arteries, keeping them pliable and flexible. As the estrogen decreases during perimenopause, the arteries naturally become stiffer, leading to endothelial dysfunction and microvascular disease.</p>
  <p>Then, as we reach menopause and estrogen continues to decrease, there is often the development of high blood pressure, a decrease in the protective HDL and an increase in the LDL. This is the perfect setup for plaque formation, and is the reason why most women tend to develop heart disease 10 years after men do. We are protected by estrogen!</p>
  <p>It is critically important to understand this hormonal ebb and flow throughout each stage of your life.</p>
`

const ha03 = `
  <h4>Menstruation and pregnancy</h4>
  <p>On a monthly basis, your body prepares for pregnancy, and if it doesn’t happen then the uterus sheds its lining, which is the normal process of menstruation. Your hormones are already at work in your body. But then comes the time that menstruation does not occur. Pregnancy! Your hormones shift into high gear.</p>
  <div>
    <p>During pregnancy:<p>
    <ul>
      <li>Your body produces more estrogen to support the formation of the placenta.</li>
      <li>Your body produces more progesterone by the ovaries and by the placenta. Progesterone stimulates the thickening of the uterine lining in anticipation of implantation of a fertilized egg.</li>
      <li>The fluctuations support the growing fetus by increasing nutritional support through an increase in blood flow.</li>
      <li>If there is endothelial dysfunction or microvascular disease, the demand increases for the arteries to dilate to support both the baby and the mother. Pregnancy has often been called the first “stress test.”</li>
    </ul>
  </div>
  <p>As pregancy continues, the demand for nutrients increases, also increasing blood flow. If there is endothelial dysfunction or microvascular disease, the arteries can't dilate to support both the baby and the mother and issues can happen like high blood pressure or preeclampsia. Pregnancy has often been called the first “stress test.”</p>
`

const ha04 = `
  <h4>The critical moment after pregnancy</h4>
  <p>After delivery is the moment in time to improve the health of your arteries by making all the changes needed to achieve heart health. This is the time when movement, nourishment, refreshing, and relating become the four pillars of your daily, heart healthy life. And think about the motivation for this change: that beautiful baby in your arms.</p>
  <p>Women who have had issues during pregnancy — like gestational diabetes, hypertension and preeclampsia — are at risk for developing diabetes, hypertension and eventually heart disease. These issues may show up within 5-10 years after pregnancy. Becoming heart healthy will protect you (and your baby) for the next pregnancies. In the U.S., cardiovascular disease is the leading cause of maternal death and is responsible for 33% of pregnancy-related deaths.<sup>1</sup> Postpartum is an important time to get healthy.<p>
  <ul class="notes">
    <li>(J AmColl Cardiology. 2020 Nov, 76(18) 2114-2116.)</li>
  <ul>
`

const ha05 = `
  <h4>Hormones and menopause</h4>
  <p>As we lose estrogen, our risk of heart disease increases. I always say, train for menopause like you are training for a marathon. Eating right, exercising, sleep and stress management during perimenopause can help to counteract the loss of estrogen and help to prevent heart disease. The truth is, the earlier you begin, the more protected you will be. By the time you reach menopause, your fitness level and healthy eating will support all of those changes that happen.</p>
  <p>Going into menopause before the age of 40 is called premature menopause and between 40 and 45 years old is early menopause. When we go through menopause early, and lose the estrogen protection earlier in life, our risk of heart disease goes up. It is important to discuss preventive cardiovascular measures with your doctor if this is the case.</p>
  <p>Seventy percent of women in menopause will have cardiovascular disease. It's never too early — or too late — to start your heart-care journey.</p>
`

export const dataHA = [
  mapCoreConcepts(1, undefined, undefined, ''),
  mapCoreConcepts(2, 'img_001.webp', undefined, '', ha01),
  mapCoreConcepts(3, 'img_002.webp', undefined, '', ha02),
  mapCoreConcepts(4, 'img_003.webp', undefined, '', ha03),
  mapCoreConcepts(5, 'img_004.webp', undefined, '', ha04),
  mapCoreConcepts(6, 'img_005.webp', undefined, '', ha05),
]

const coreConceptsMore101 = `
  <p>During PMS you feel bloating, breast tenderness, headaches, irritability and mood swings.</p>
  <img src="/images/my-adresso-journey/session/09/img_012.png" alt="" />
  <p>Your hormones really kick in seven days before you menstruate. In women with PMS, serotonin—the neurotransmitter that regulates moods, sleep and appetite—drops as estrogen drops. Serotonin can be the lowest in the two weeks before the period when estrogen is the lowest. This happens most often in women with PMS. You become much more irritable as a result</p>
  <img src="/images/my-adresso-journey/session/09/img_013.png" alt="" />
  <p>With the fluctuations in hormones, there may be heart symptoms as well. Sometimes a rapid, skipping or fluttering heartbeat can happen around the time of your period. And you may feel other symptoms like a headache, low energy levels and stomach issues.</p>
`
const coreConceptsMore102 = `
  <h4>What to do:</h4>
  <p>The best and quickest way to balance hormones and their effect on your mood, heart and life is to practice many of the things we’ve already discussed:</p>
  <ul>
    <li>Avoid alcohol</li>
    <li>Avoid stimulants</li>
    <li>Eat plenty of vegetables, fruits, and high-fiber foods</li>
    <li>Sleep more</li>
    <li>Manage your stress</li>
    <li>Exercise</li>
  </ul>
  <p>Now that we better understand and appreciate what our hormones are doing throughout our life, we can understand why we might feel the way that we do.</p>
  <p>PMS is a regular occurance, so be gentle with yourself when these times come around. Avoid making major decisions and volatile conversations as these will only increase your stress and anxiety levels.</p>
  <p>Your heart is the essence of who you are and you have every right and reason to protect it. So when you’re not feeling yourself, give yourself a break, take a time out, give yourself some extra nurturing. You know that soon enough you will feel better. If PMS interrupts your life and your health, talk to your doctor to see if oral contraceptives or even antidepressants might be options for you.</p>
`

const coreConceptsMore103 = `
  <h4>The more you know: PCOS</h4>
  <p>There’s also a condition called Polycystic ovary syndrome (PCOS) in which there is a hormonal imbalance that can cause irregular periods, ovarian cysts and infertility. The hormones produce more insulin causing the ovaries to generate more androgen, a male hormone that can increase hair growth, cause hair loss, acne, and increase testosterone levels. Many women with PCOS have elevated blood pressures, increase in belly fat, elevated sugars and high triglyceride levels (called the Metabolic Syndrome) and thus, have greater risk of heart disease. 10% of infertile women have this condition, so make sure to talk to your doctor if you are experiencing infertility. I’ve had patients who have had PCOS and infertility. They made major changes to their diet, started exercising and got pregnant as a result. So there’s still hope!</p>
`

const coreConceptsMore201 = `
  <p>Am I in this stage? If you’re pregnant, hopefully you know it!</p>
  <p>As we read earlier, pregnancy kicks your hormones into high gear. It is also a time where heart health is more important than ever. You’ll want to be aware of what’s happening to you and what you can do.</p>
  <img src="/images/my-adresso-journey/session/09/img_013.png" alt="" />
  <p>Shortness of breath, chest pain, nausea, palpitations, dizziness, fatigue may be normal in pregnancy. Pay attention to the changes in your health and these symptoms as your pregnancy progresses. If these symptoms progress and are affecting your daily life, talk to your obstetrician to see if you might need to see a cardiologist. You will experience a heart rate increase and there could also be a decrease in blood pressure. Paying attention to how you feel is critical.</p>
  <p>During pregnancy, your heart and your whole body is working to its maximum.</p>
`

const coreConceptsMore202 = `
  <p>Keep eating healthy. You’re eating the nutrients you and your baby need. You don’t have to eat more, just eat healthy!</p>
  <p>Get moving. Staying active is essential. You don’t have to start going to the gym if you didn’t go before, but continue to walk and do activities that you used to do. Talk to your doctor about the intensity of your activity and what is the right level of movement for you.</p>
  <p>Advocate for yourself. If you’ve had a previous pregnancy and experienced any of the adverse outcomes listed next, speak up! Advocate for yourself with your OB/GYN to ensure you are getting the right tests throughout this pregnancy for your heart health and the health of your baby.</p>
  <p>Adverse outcomes of pregnancy</p>
  <p>Remember, we said, pregnancy is a stress test? This is actually the first time the arteries are challenged to see if they are healthy.</p>
  <div>
    <p class="text-ma-pink"><b>Adverse outcomes of pregnancy include:</b></p>
    <ul>
      <li>hypertension</li>
      <li>gestational diabetes</li>
      <li>miscarriages</li>
      <li>premature birth</li>
      <li>preeclampsia</li> 
    </ul>
  </div>
  <p>These are all signs that the lining of the arteries are struggling, like essentially a “failed” stress test.</p>
  <p>If your blood pressure goes up (hypertension or preeclampsia), it can be an indication of endothelial dysfunction and microvascular disease.</p>
  <p>You must know what to do to care for yourself both during the pregnancy, after the pregnancy and well before the next pregnancy, should that occur.</p>
  <p>It’s important to advocate for yourself as you navigate more extreme symptoms. If your issues persist and get worse, it’s really important to go to the doctor.</p>
`

const coreConceptsMore301 = `
<p>Perimenopause is the name for the years leading up to menopause. It usually occurs between 40 and 60.</p>
<p>Essentially,your ovaries, which are usually pumping out estrogen, begin to get tired.</p>
<p>With this decrease in estrogen, you may develop symptoms like hot flashes, difficulty sleeping, and irritability.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/09/img_020.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/09/img_020.png" alt="">
</picture>
<p>The complex interconnection between the drop of estrogen and the hypothalamus—the area in the brain that controls body temperature—is thought to be the reason for hot flashes to develop. Just a warning…the average time that women can experience hot flashes during the perimenopausal and menopausal time is seven years. But, hang in there…</p>
`
const coreConceptsMore302 = `
<p>Your doctor might be able to help you with common treatments for perimenopause like birth control pills.</p>
<p>Train for menopause like you train for a marathon. It’s a long haul and not a sprint.</p>
<p>This means really shifting your balance of the foods you eat, your movement and exercise routines, reducing stress, and getting healthy sleep so that you can endure this process and reduce the most extreme symptoms as best you can.</p>
<p>Exercise has been shown to be the most effective tool to decrease hot flashes, so if you have not begun a regular exercise routine, now is definitely the time.</p>
<p>Some studies have shown that anti-depressants at low doses may also be beneficial in decreasing hot flashes. Talk to your doctor!</p>
`

const coreConceptsMore401 = `
<p>Once you experience menopause, your estrogen levels really plummet. The biggest difference is that you stop ovulating. You are officially in menopause after you stop menstruating for one year.</p>
<p>Everyone is different. You can’t exactly predict the symptoms or how long it will last.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/09/img_021.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/09/img_021.png" alt="">
</picture>
<p>But it is important to know that hot flashes are associated with endothelial dysfunction. You should definitely be paying attention to your heart numbers.</p>
<p>Those who report going into menopause at age 46 or younger have an increased risk of strokes or heart disease. So if you do go through menopause early, keep tabs on your heart numbers so that you can address any issues . This is the time when blood pressure might start increasing, LDL cholesterol may go up and HDL cholesterol decline. Get those numbers checked and start focusing on your heart health, because this is the critical time for heart disease prevention.</p>
`
const coreConceptsMore402 = `
<h4>What you can do:</h4>
<ul>
<li>Keep moving. Balance your autonomic nervous system with exercise.Focus on moderate intensity exercise for a total of 150 minutes per week, or 30 minutes 5 days a week.</li>
<li>Nourish yourself. Balance your foundation of nourishment. Eating a heart-healthy diet, low in fats and sugars, is more essential than ever.</li>
<li>Explore HRT. Hormone Replacement Therapy (HRT) may be considered, especially within the first ten years after menopause when it also may be protective against cardiovascular disease and dementia. There are many types of HRT from pills to patches to intravaginal suppositories. The types that you don’t have to swallow are safer for you. Other considerations are bio-identical hormones in creams and suppositories. It is best to talk to your doctor to find out what type is best for you.</li>
</ul>
`

const coreConceptsMore501 = `
<p>After you hit menopause, your estrogen continues falling until it’s gone. This affects skin, vaginal dryness, increase in UTIs, thinning hair.. You may experience hot flashes, mood swings, and difficulty sleeping, which eventually will resolve.</p>
<p>During post-menopause, your endothelium gets stiff. Your HDL cholesterol goes down. Your LDL goes up. This is the natural progression of the body as the estrogen decreases. It is considered part of the normal aging process, but we know now that we can do our best to counteract this through lifestyle changes</p>
<picture>
  <source srcset="/images/my-adresso-journey/session/09/img_021.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/09/img_021.png" alt="">
</picture>
<p>Your body changes. A lot of times your weight goes up. Be careful if you walk by a mirror too quickly… you might start seeing your mother!</p>
<p>As your weight increases, cholesterol goes up and the blood pressure can increase, you are now in the perfect time of life for the development of cardiovascular disease. It is truly time to pay attention, so let’s do this….</p>
`
const coreConceptsMore502 = `
<h4>What you can do:</h4>
<ul>
<li>Exercise! It keeps your heart healthy, lowers cholesterol, and even reduces hot flashes. Exercise will be your best friend. Get into a routine of regular exercise. I promise you, it will help just about more than anything!</li>
<li>Focus on your nourishment. A heart healthy diet will help with stabilizing your cholesterol and your blood sugars. Eating vegetables and muti-grains and omega 3’s are anti-inflammatory and can help with symptoms of menopause.</li>
</ul>
<p>Aging is never fun, but getting through it with grace is possible.</p>
<p>After menopause, oxytocin goes down. This is your caretaker hormone. Here’s how I look at it- this is the first time in your life your hormones are not driving you to take care of everyone else and to put yourself last. Think about it ladies, this is a guilt-free time when you get to take care of yourself! So who cares if you have a little more wrinkles, or a slight bit more belly fat and extra cellulite. You get to be you, guilt-free. Enjoy it!</p>
`

export const dataCoreConceptsMores01: CoreConceptsStops[] = [
  mapCoreConcepts(7, 'img_007.webp', undefined, '', coreConceptsMore101),
  mapCoreConcepts(8, 'img_007.webp', undefined, '', coreConceptsMore102),
  mapCoreConcepts(9, 'img_007.webp', undefined, '', coreConceptsMore103),
]

export const dataCoreConceptsMores02: CoreConceptsStops[] = [
  mapCoreConcepts(10, 'img_008.webp', 'Pregnancy and Heart Disease', '', coreConceptsMore201),
  mapCoreConcepts(11, 'img_008.webp', 'What to do:', '', coreConceptsMore202),
]

export const dataCoreConceptsMores03: CoreConceptsStops[] = [
  mapCoreConcepts(12, 'img_009.webp', 'Perimenopause', '', coreConceptsMore301),
  mapCoreConcepts(13, 'img_009.webp', 'What to do:', '', coreConceptsMore302),
]

export const dataCoreConceptsMores04: CoreConceptsStops[] = [
  mapCoreConcepts(14, 'img_010.webp', 'Menopause', '', coreConceptsMore401),
  mapCoreConcepts(15, 'img_010.webp', 'Menopause', '', coreConceptsMore402),
]

export const dataCoreConceptsMores05: CoreConceptsStops[] = [
  mapCoreConcepts(16, 'img_011.webp', 'Post Menopause', '', coreConceptsMore501),
  mapCoreConcepts(17, 'img_011.webp', 'Post Menopause', '', coreConceptsMore502),
]

export type CoreConceptsMores09 = {
  id: number
  img: string
  data: CoreConceptsStops[]
}

export const dataCoreConceptsMores: CoreConceptsMores09[] = [
  { id: 18, img: 'img_007.webp', data: dataCoreConceptsMores01 },
  { id: 19, img: 'img_008.webp', data: dataCoreConceptsMores02 },
  { id: 20, img: 'img_009.webp', data: dataCoreConceptsMores03 },
  { id: 21, img: 'img_010.webp', data: dataCoreConceptsMores04 },
  { id: 22, img: 'img_011.webp', data: dataCoreConceptsMores05 },
]
