import React from 'react'
import SupplementRx from '../../components/MyHealth/SupplementsRx'
import { SupplementRxProvider } from '../../context/supplementRxContext'
import { useHandlePreviousUrl } from '.'

type Props = {}

const SupplementsRxPage = (props: Props) => {
  const handlePreviousUrl = useHandlePreviousUrl()

  return (
    <SupplementRxProvider>
      <SupplementRx prevScreen={handlePreviousUrl} />
    </SupplementRxProvider>
  )
}

export default SupplementsRxPage
