import { GoalTrackConfigSection } from './Section'
import { ActionType, GoalTrackConfigMyGoalsItem } from './MyGoalsItem'
import { Goal } from '../../models/goal'
import React, { useMemo } from 'react'
import { IconLoading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { useLocales } from '../../locales'

type Props = {
  handleRemoveGoal: Function
  goals?: Goal[]
  isLoading?: boolean
  onClick?: (slug: string | any) => void
}
export const GoalTrackConfigMyGoals = ({ handleRemoveGoal, goals, isLoading, onClick }: Props) => {
  const { t } = useLocales()

  const myGoalsContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading size={LoadingSize.Medium} />
        </div>
      )
    }
    if (!goals || !goals.length) {
      return <div>{t('mainMenuAndSection.noGoal')}</div>
    }
    return goals.map((goal: Goal) => {
      return (
        <GoalTrackConfigMyGoalsItem
          handleButtonClick={handleRemoveGoal}
          key={goal.id}
          goal={goal}
          actionType={ActionType.Remove}
          onClick={() => {
            if (onClick) onClick(goal.slug)
          }}
        />
      )
    })
  }, [goals])
  return (
    <GoalTrackConfigSection className="space-y-2" title={t('mainMenuAndSection.myGoals')}>
      {myGoalsContent}
    </GoalTrackConfigSection>
  )
}
