import { rateGoal } from '../../models/heart-chart-rateGoal'

type Dates = {
  date: string
  rate: string
}

export function mapDataChartRateGoal(data: any[]): rateGoal[] {
  const initialData: number[] = Array(7).fill(0)

  const mappedData: rateGoal[] = data.map((item) => {
    let data = [...initialData]

    if (item.dates && item.dates[0] !== null) {
      item.dates.forEach((dateInfo: Dates) => {
        const dateIndex = parseInt(dateInfo.date) - 1
        const rate = Math.round(parseFloat(dateInfo.rate))
        data[dateIndex] = rate
      })
    }
    return {
      label: item.title.toLowerCase(),
      data: data,
      color: item.color,
      subscribed: item.subscribed,
    }
  })

  return mappedData
}
