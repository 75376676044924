import { useState, useCallback, useMemo } from 'react'
import { Session03Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion03Questions } from '../../../../../lib/data/session-03'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [
  sesstion03Questions[8],
  sesstion03Questions[9],
  sesstion03Questions[10],
  sesstion03Questions[11],
  sesstion03Questions[12],
  sesstion03Questions[13],
  sesstion03Questions[14],
  sesstion03Questions[15],
]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment03 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    console.info(formik.values)
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const after = (
    <div className="flex w-full justify-between">
      <span className="w-[50%] text-left">
        Not
        <br />
        mindful
      </span>
      <span className="w-[50%] text-right">
        Very
        <br />
        mindful
      </span>
    </div>
  )

  const screenHeartAssessment03 = useMemo(() => {
    return (
      <Session03Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6">
            <h4 className="text-[18px] font-bold leading-[23px]">
            Mindful eating is healthy eating.
            </h4>
            <p>
              {`Let’s review some of the food and diet questions that we discussed when you took your Adesso heart score and assess other areas related to nourishment, too. Take a moment and record your answers to these questions again.`}
            </p>

            {ssHAQuestions.map((item) => {
              return (
                <FormField key={`ss03_${item.name}`} question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session03Wrapper>
    )
  }, [formik, buttons, isError])

  return <>{screenHeartAssessment03}</>
}
