import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocales } from '../../../../locales'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { ButtonCommon, ButtonStyles } from '../../../Common'
import MyHealthContent from '../../../MyHealth'
import LabTestResults from '../../../MyHealth/LabTestResults'
import MovementRx from '../../../MyHealth/Movement'
import { ArrowType, BoxText } from '../../../Introduction/BoxText'
import { MyAdessoMyHealthStep } from '../../../../constants/introduction'

interface Props {
  nextScreen: () => void
  closeScreen: () => void
}

const MyAdessoMyHealthIntroductionScreen = ({ nextScreen, closeScreen }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const { t } = useLocales()
  const [step, setStep] = useState<MyAdessoMyHealthStep>(MyAdessoMyHealthStep.WelcomeTour)
  const boxRef = useRef<HTMLDivElement>(null)
  const refs = Object.values(MyAdessoMyHealthStep).reduce((acc: any, step, index) => {
    if (!isNaN(Number(step))) {
      acc[step] = useRef<HTMLElement>(null)
    }

    return acc
  }, {})

  const [currentRef, setCurrentRef] = useState<any>(refs[MyAdessoMyHealthStep.WelcomeTour])

  const positionStep = {
    WelcomeTour: 'top',
    HeartScore: '',
    LabTestResult: '',
    SeePastTestResult: '',
    HealthRecommendations: '',
    RxRecommendationPage: '',
    CustomizeYourPlan: '',
  }
  const { setShowFeedback } = useHeaderContext()

  const handleNextStep = useCallback((stepCurrent: MyAdessoMyHealthStep) => {
    setStep(stepCurrent + 1)
  }, [])

  if (step === MyAdessoMyHealthStep.SeePastTestResult) {
    window.scrollTo({
      behavior: 'smooth',
      top: document.body.scrollHeight,
    })
  }

  useEffect(() => {
    setShowFeedback(false)
  }, [])

  useEffect(() => {
    if (step === MyAdessoMyHealthStep.WelcomeTour) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        boxRef.current.style.top = '100px'
      }
      return
    }

    if (!currentRef?.current) {
      // Center vertical popup
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        const boxTop = Math.max(0, window.innerHeight / 2 - (boxRef.current.children[0]?.clientHeight + 51) / 2) - 80
        boxRef.current.style.top = boxTop + 'px'
      }
      return
    }

    currentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    const scrollHeight = currentRef.current.scrollHeight
    const scrollY = window.scrollY
    const { bottom } = currentRef?.current.getBoundingClientRect()

    if (boxRef?.current) {
      switch (step) {
        case MyAdessoMyHealthStep.HeartScore:
        case MyAdessoMyHealthStep.LabTestResult:
        case MyAdessoMyHealthStep.RxRecommendationPage:
        case MyAdessoMyHealthStep.CustomizeYourPlan:
          boxRef.current.style.top = Math.round(bottom - 80) + 'px'
          break
        case MyAdessoMyHealthStep.SeePastTestResult:
          boxRef.current.style.top = Math.round(bottom + scrollY - scrollHeight / 2.5 - 300) + 'px'
          break

        case MyAdessoMyHealthStep.HealthRecommendations:
          window.scrollTo({
            behavior: 'smooth',
            top: 170,
          })
          boxRef.current.style.top = Math.round(bottom + scrollY - scrollHeight / 2.5 - 380) + 'px'
          break

        default:
          boxRef.current.style.top = Math.round(bottom + scrollY - 80) + 'px'
          break
      }
    }
  }, [step])

  const handleButtonNext = () => {
    handleNextStep(step)
    const stepCurrent = step
    const nextStep = stepCurrent + 1

    if (stepCurrent === MyAdessoMyHealthStep.CustomizeYourPlan) {
      nextScreen()
    }

    setCurrentRef(refs[nextStep])
  }

  const contentMyAdessoMyHealthStep = useMemo(() => {
    switch (step) {
      case MyAdessoMyHealthStep.WelcomeTour:
        return <BoxText className={`${positionStep.WelcomeTour}`} title={t('introduction.myAdessoMyHealth.title.welcomeTour')} text={t('introduction.myAdessoMyHealth.text.welcomeTour')} />
      case MyAdessoMyHealthStep.HeartScore:
        return (
          <BoxText
            arrow={ArrowType.Left}
            className={`${positionStep.HeartScore}`}
            title={t('introduction.myAdessoMyHealth.title.heartScore')}
            text={t('introduction.myAdessoMyHealth.text.heartScore')}
          />
        )
      case MyAdessoMyHealthStep.LabTestResult:
        return (
          <BoxText
            arrow={ArrowType.Left}
            className={`${positionStep.LabTestResult}`}
            title={t('introduction.myAdessoMyHealth.title.labTestResult')}
            text={t('introduction.myAdessoMyHealth.text.labTestResult')}
          />
        )
      case MyAdessoMyHealthStep.SeePastTestResult:
        return <BoxText arrow={ArrowType.BottomLeft} arrowClass="!top-[95%]" className={`${positionStep.SeePastTestResult}`} text={t('introduction.myAdessoMyHealth.text.seePastTestResult')} />
      case MyAdessoMyHealthStep.HealthRecommendations:
        return (
          <BoxText
            arrow={ArrowType.BottomLeft}
            arrowClass="!top-[95%]"
            className={`${positionStep.HealthRecommendations}`}
            title={t('introduction.myAdessoMyHealth.title.healthRecommendations')}
            text={t('introduction.myAdessoMyHealth.text.healthRecommendations')}
          />
        )
      case MyAdessoMyHealthStep.RxRecommendationPage:
        return (
          <BoxText
            className={`${positionStep.RxRecommendationPage}`}
            title={t('introduction.myAdessoMyHealth.title.rxRecommendationPage')}
            text={t('introduction.myAdessoMyHealth.text.rxRecommendationPage')}
          />
        )
      case MyAdessoMyHealthStep.CustomizeYourPlan:
        return (
          <BoxText
            className={`${positionStep.CustomizeYourPlan}`}
            title={t('introduction.myAdessoMyHealth.title.customizeYourPlan')}
            text={t('introduction.myAdessoMyHealth.text.customizeYourPlan')}
          />
        )
      default:
        return <></>
    }
  }, [step])

  const buttonNext = useMemo(() => {
    const marginBottom = step === MyAdessoMyHealthStep.WelcomeTour ? 'scroll-mb-0' : 'scroll-mb-80'
    return (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        scrollMarginBottom={marginBottom}
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        onClick={() => handleButtonNext()}
      />
    )
  }, [step, handleNextStep])

  const buttonClose = useMemo(() => {
    return (
      <button onClick={closeScreen} className="fixed right-5 top-7 z-50 flex cursor-pointer  items-center justify-center bg-transparent text-white">
        <p className="pr-2 font-['Proxima_Nova'] text-[14px] font-[600] leading-[17px] tracking-[0.15px]">CLOSE DEMO</p>
        <div className=" h-[29px] w-[30px] bg-[url('assets/images/icon/i-button-close.svg')] bg-no-repeat" />
      </button>
    )
  }, [step])

  const introMemo = useMemo(() => {
    switch (step) {
      case MyAdessoMyHealthStep.WelcomeTour:
      case MyAdessoMyHealthStep.HeartScore:
      case MyAdessoMyHealthStep.LabTestResult:
      case MyAdessoMyHealthStep.HealthRecommendations:
      case MyAdessoMyHealthStep.CustomizeYourPlan:
      case MyAdessoMyHealthStep.SeePastTestResult:
        return (
          <div>
            <div className={step !== MyAdessoMyHealthStep.SeePastTestResult ? 'block' : 'hidden'}>
              <MyHealthContent refs={refs} isIntroduction />
            </div>
            <div className={step === MyAdessoMyHealthStep.SeePastTestResult ? 'block' : 'hidden'}>
              <LabTestResults refs={refs} isIntroduction />
            </div>
          </div>
        )

      case MyAdessoMyHealthStep.RxRecommendationPage:
        return <MovementRx />
    }
  }, [step, currentRef])

  const buttonNextTop = [MyAdessoMyHealthStep.SeePastTestResult, MyAdessoMyHealthStep.HealthRecommendations]

  return (
    <div className="relative h-full w-full pb-40">
      {introMemo}

      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] bg-color-overlay bg-opacity-50"></div>
      {buttonClose}
      <div ref={boxRef} className="absolute left-0 top-[-74px] z-50 flex h-[calc(100%+74px)] w-full flex-col items-center space-y-4 px-4 font-['Proxima_Nova']">
        {buttonNextTop.includes(step) ? (
          <>
            {buttonNext}
            {contentMyAdessoMyHealthStep}
          </>
        ) : (
          <>
            {contentMyAdessoMyHealthStep}
            {buttonNext}
          </>
        )}
      </div>
    </div>
  )
}

export default MyAdessoMyHealthIntroductionScreen
