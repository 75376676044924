import { useState, useMemo, useCallback, useEffect } from 'react'
import { YourNowScreen } from './YourNowScreen'
import { ConfirmScreen } from './ConfirmScreen'
import { IntroductionScreen } from './IntroductionScreen'

import { MyNow } from '../../models/my-now'

enum OnboardScreen {
  YourNow,
  Confirm,
  Introduction,
}

type Props = {
  isLoading: boolean
  isSaveSuccess: boolean
  saveMyNow: (myNow: string) => void
}

export const OnboardContent = ({ isLoading, isSaveSuccess, saveMyNow }: Props) => {
  const [screen, setScreen] = useState<OnboardScreen>(OnboardScreen.YourNow)
  const [myNow, setMyNow] = useState<string>('')
  useEffect(() => {
    if (isSaveSuccess) {
      setScreen(2)
    }
  }, [isSaveSuccess])

  const handleCreateMyNow = useCallback((data: string, currentScreen: OnboardScreen) => {
    setMyNow(data)
    setScreen(currentScreen + 1)
  }, [])

  const handleEditMyNow = useCallback((currentScreen: OnboardScreen) => {
    setScreen(currentScreen - 1)
  }, [])

  const handleSaveMyNow = useCallback(() => {
    saveMyNow(myNow)
  }, [myNow, saveMyNow])

  const yourNowScreen = useMemo(() => {
    return <YourNowScreen myNow={myNow} createMyNow={(data: string) => handleCreateMyNow(data, screen)} />
  }, [myNow, screen, handleCreateMyNow])

  const confirmScreen = useMemo(() => {
    return <ConfirmScreen isLoading={isLoading} myNow={myNow} editMyNow={() => handleEditMyNow(screen)} saveMyNow={handleSaveMyNow} />
  }, [isLoading, myNow, screen, handleEditMyNow, handleSaveMyNow])

  const onboardContent = useMemo(() => {
    switch (screen) {
      case OnboardScreen.YourNow:
        return yourNowScreen
      case OnboardScreen.Confirm:
        return confirmScreen
      case OnboardScreen.Introduction:
        return <IntroductionScreen />

      default:
        return <></>
    }
  }, [screen, yourNowScreen, confirmScreen])

  return <>{onboardContent}</>
}
