import { useState, useCallback, useMemo } from 'react'
import { Session02Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion02Questions } from '../../../../../lib/data/session-02'
import { useForm } from '../../../../../hooks/use-form'

import { ErrorMessage } from '../../../../Common'
import { InputField } from '../../../../Common/form/Input'

const ssHAQuestions = [
  sesstion02Questions[7],
  sesstion02Questions[8],
  sesstion02Questions[9],
  sesstion02Questions[10],
  sesstion02Questions[11],
  sesstion02Questions[12],
  sesstion02Questions[13],
  sesstion02Questions[14],
]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment02 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session02Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6">
            <h4 className="text-[18px] font-bold leading-[23px]">Select the activities and estimate how often you do them each week.</h4>

            <div className="space-y-2 text-[15px] font-semibold leading-[17px]">
              <div className="flex h-14 w-full divide-x divide-dotted divide-black rounded-[14px] bg-[#F0F0F0] py-1 ">
                <div className="flex h-full flex-1 items-center px-5">Activity</div>
                <div className="flex h-full w-[153px] items-center px-2">Number of times each week</div>
              </div>

              {ssHAQuestions.map((item, index) => {
                let textColor = ''

                switch (index) {
                  case 0:
                  case 5:
                  case 7:
                    textColor = 'text-ma-red-500'
                    break

                  case 1:
                  case 4:
                    textColor = 'text-ma-orange-500'
                    break

                  case 2:
                    textColor = 'text-ma-violet'
                    break

                  case 3:
                  case 6:
                    textColor = 'text-ma-violet-700'
                    break

                  default:
                    break
                }
                return (
                  <div key={index}>
                    <div className={`flex h-14 w-full divide-x divide-dotted divide-black rounded-[14px] bg-[#F0F0F0] py-1 ${textColor}`}>
                      <div className="flex h-full flex-1 items-center px-5" dangerouslySetInnerHTML={{ __html: `${item.question}` }} />
                      <div className="flex h-full w-[153px] items-center px-2">
                        <InputField isDefault question={item} value={formik.values[item.name]} onChange={formik.handleChange} />
                      </div>
                    </div>
                    {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                  </div>
                )
              })}
            </div>
          </div>

          {buttons}
        </form>
      </Session02Wrapper>
    )
  }, [formik, buttons, isError])

  return <>{screenHeartAssessment02}</>
}
