import React from 'react'
import { GoalTrackingConfigurationContent } from '../../GoalTrackingConfiguration'
import { Navigate } from 'react-router-dom'

type Props = {}

const TrackMedication = ({}: Props) => {
  // return <GoalTrackingConfigurationContent tab-gtc-from-rx={4} />
  return <Navigate to={'/goal-tracking-configuration/?tab-gtc-from-rx=4'} replace />
}

export default TrackMedication
