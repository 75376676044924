import { useState, useMemo, useCallback } from 'react'

import { WrapperCommon, ButtonCommon, ButtonStyles, SubmitButtonCommon } from '../Common'

type Props = {
  myNow: string
  isLoading: boolean
  editMyNow: () => void
  saveMyNow: () => void
}

export const ConfirmScreen = ({ isLoading, myNow, editMyNow, saveMyNow }: Props) => {
  const onboardContent = useMemo(() => {
    return (
      <WrapperCommon className="min-h-[calc(100vh-74px)] py-5">
        <h3 className="font-['Butler'] text-[24px]">My Now!</h3>
        <br />
        <p className="mb-5 font-['Proxima_Nova'] text-[20px] font-[600]  leading-[24px]">
          Your Now is your mantra to keep you motivated throughout the MyAdesso journey.
          <br />
          <br />
          Envision yourself as the healthiest you can be. Does Your Now capture that? Does it motivate you? Make sure this is the best statement it can be.
        </p>
        <div className="space-y-7 text-ma-lg font-semibold">
          <div className="flex min-h-[312px] w-full items-center justify-center bg-[url('/backgrounds/bg-onboard__02.webp')] bg-cover bg-center bg-no-repeat px-4 text-center text-3xl text-white">
            {myNow}
          </div>
          <p className="text-center">Do you confirm this is your Now?</p>
        </div>

        <div className="flex justify-center gap-4 pt-8">
          <div>
            <ButtonCommon style={ButtonStyles.Dark} width="w-[167px]" height="h-[50px]" text="EDIT MY NOW" onClick={editMyNow} />
          </div>
          <div>
            <SubmitButtonCommon isLoading={isLoading} style={ButtonStyles.Dark} width="w-[167px]" height="h-[50px]" text="CONFIRM MY NOW" onClick={saveMyNow} />
          </div>
        </div>
      </WrapperCommon>
    )
  }, [isLoading, myNow, editMyNow, saveMyNow])

  return <>{onboardContent}</>
}
