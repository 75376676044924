import { useState, useCallback, useMemo } from 'react'
import { Session03Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { sesstion03Questions } from '../../../../../lib/data/session-03'
import { useForm } from '../../../../../hooks/use-form'

import { FormField, ErrorMessage } from '../../../../Common'

const ssHAQuestions = [sesstion03Questions[2], sesstion03Questions[3], sesstion03Questions[4], sesstion03Questions[5], sesstion03Questions[6], sesstion03Questions[7]]

type Props = {
  showModal: () => void
  backScreen: () => void
  onUpdate: (params: object) => void
  answers: any
}

export const ScreenHeartAssessment02 = ({ showModal, backScreen, onUpdate, answers }: Props) => {
  const { initUseFormik } = useForm()
  const [isError, setIsError] = useState<boolean>(false)

  const initForm = ssHAQuestions.map((v) => {
    return answers && answers[v.name] ? { ...v, value: answers[v.name]['answer'] } : { ...v, value: '' }
  })

  const formik = initUseFormik(initForm, () => {
    onUpdate(formik.values)
  })

  const checkSubmit = useCallback(() => {
    setIsError(true)
  }, [])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={() => checkSubmit()} />
  }, [backScreen, checkSubmit, showModal])

  const after = (
    <div className="flex w-full justify-between">
      <span className="w-[50%] text-left">
        Not
        <br />
        mindful
      </span>
      <span className="w-[50%] text-right">
        Very
        <br />
        mindful
      </span>
    </div>
  )

  const handleChangeValue = useCallback(
    (value: string, name: string) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const screenHeartAssessment02 = useMemo(() => {
    return (
      <Session03Wrapper>
        <form className="flex h-full w-full flex-1 flex-col" onSubmit={formik.handleSubmit}>
          <div className="flex-1 space-y-6">
            <h4 className="text-[18px] font-bold leading-[23px]">The Adesso approach to nourishment.</h4>
            <p>
              {`Take a moment to think about how you nourish yourself. The Adesso approach to nourishment recognizes the power in changing our habits by first being aware of them. We refer to this as being mindful, which means we pause doing routine things like cooking, eating or even digesting and pay attention to our thoughts and how we feel. Mindfulness helps us connect directly to our hearts.`}
            </p>
            <p>So let’s start now and apply this to how we nourish ourselves.</p>
            <p>{`Rank yourself between 1 and 5 on how much you pay attention (i.e., are mindful) to all the steps in nourishing you and your family.`}</p>
          </div>

          <div className="space-y-10">
            {ssHAQuestions.map((item) => {
              return (
                <FormField
                  key={`ss03_${item.name}`}
                  after={after}
                  question={item}
                  value={formik.values[item.name]}
                  onChange={formik.handleChange}
                  onChangeValue={(value: string) => handleChangeValue(value, item.name)}
                >
                  {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
                </FormField>
              )
            })}
          </div>

          {buttons}
        </form>
      </Session03Wrapper>
    )
  }, [formik, buttons, isError, handleChangeValue])

  return <>{screenHeartAssessment02}</>
}
