import { useMemo, useState } from 'react'

import { Session07Wrapper } from '../Wrapper'
import { SessionButtons } from '../../layout'

import { dataCoreConcepts7 } from '../../../../../lib/data/session-07'

type Props = {
  showModal: () => void
  nextScreen: () => void
  backScreen: () => void
}

export const ScreenCoreConcepts07 = ({ showModal, nextScreen, backScreen }: Props) => {
  const [contentScreen, setContentScreen] = useState(dataCoreConcepts7[0])

  const buttons = useMemo(() => {
    return <SessionButtons styleButton={'dark'} showModal={showModal} backScreen={backScreen} nextScreen={nextScreen} />
  }, [nextScreen, showModal])

  const screenCoreConcepts = useMemo(() => {
    const srcImg = contentScreen.img?.includes('.mp4') ? `https://api.myadesso.health/uploads/animation/${contentScreen.img}` : `/images/my-adresso-journey/session/07/${contentScreen.img}`

    return (
      <Session07Wrapper buttons={buttons}>
        <div className="session-style">
          {contentScreen.title && <h4>{contentScreen.title}</h4>}

          {contentScreen.img ? (
            srcImg.includes('.mp4') ? (
              <video className="block h-auto w-full" autoPlay muted controls>
                <source src={srcImg} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={srcImg} alt="" />
            )
          ) : (
            ''
          )}

          <div className="space-y-6" dangerouslySetInnerHTML={{ __html: contentScreen.content }} />
        </div>
      </Session07Wrapper>
    )
  }, [buttons, contentScreen, showModal])

  return <>{screenCoreConcepts}</>
}
