import { useCallback, useState } from 'react'

import { AdessoResult } from '../../models'

import { useAuth } from '../use-auth'

type Result = {
  success: boolean
}

export function useOAuthPage() {
  const [dataResult, setDataResult] = useState<AdessoResult<Result>>()
  const auth = useAuth()

  const getToken = useCallback(async (code: string) => {
    setDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      await auth.checkAuth()
      const success = await auth.getToken(code)
      setDataResult({
        isLoading: false,
        data: { success },
        error: undefined,
      })
    } catch (error) {
      setDataResult({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Login Error'),
      })
    }
  }, [])

  return { dataResult, getToken }
}
