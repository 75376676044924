import { useEffect, useMemo } from 'react'
import { MyAdessoJourneyContent } from '../../components/MyAdessoJourney/'
import { useMAJPage } from '../../hooks/pages/maj'

export const MyAdessoJourney = () => {
  const { dataSessions, getDataSessions } = useMAJPage()

  useEffect(() => {
    if (dataSessions?.isLoading === undefined) {
      getDataSessions()
    }
  }, [dataSessions?.isLoading])

  const majContent = useMemo(() => {
    const sessions = dataSessions?.data?.sessions || undefined
    const isLoading = dataSessions?.isLoading || undefined

    return <MyAdessoJourneyContent sessions={sessions} isLoading={isLoading} />
  }, [dataSessions])

  return <>{majContent}</>
}
