import axios from 'axios'

import { AdessoError } from '../error'
import { LoginOptions } from '../../models/login'
import { AdessoResult } from '../../models'

type getMyHeart = {
  success: boolean
  data?: any
  message?: string
}

export async function getMyHeart(): Promise<getMyHeart> {
  const { data, status } = await axios.get('/my-heart')

  if (status !== 200) {
    console.info('get me', data)
    throw new AdessoError(`Get my heart error - ${status} ${JSON.stringify(data)}`)
  }

  return data
}
