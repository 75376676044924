import { useEffect, useMemo, useState } from 'react'
import { useHeartScoreResultPage } from '../../../hooks/pages/heart-score-result'
import { useLocales } from '../../../locales'
import { ButtonCommon, ButtonStyles, IconLoading } from '../../Common'
import WrapperMyHealth from '../MyHealthWrapper'
import { ButtonMyHealth } from '../Button'
import HeartScoreCustom from '../Form/HeartScoreCustom'
import { IntroPopup } from '../../Common/IntroPopup'
import { useBoolean } from '../../../hooks/use-boolean'
import useHeartScore from '../../../hooks/use-heart-score'
import { format, parse } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/use-auth'

type Props = {
  prevScreen: () => void
  nextScreen: () => void
}

const HeartScoreLatest = ({ prevScreen, nextScreen }: Props) => {
  const { t } = useLocales()
  const navigate = useNavigate()
  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()
  const { dataHSHistory, useGetByDateHSHistory } = useHeartScore()
  const [testDate, setTestDate] = useState<string>('')
  const [countDate, setCountDate] = useState<number>(1)
  const { user, getMeResult } = useAuth()

  const [screen, setScreen] = useState(0)
  const openResetHS = useBoolean()
  const changeBgRYHS = useBoolean()

  useEffect(() => {
    getMeResult()
  }, [])

  useEffect(() => {
    if (resultHeartScore?.isLoading === undefined) {
      getResultHeartScore()
    }
  }, [resultHeartScore])

  useEffect(() => {
    if (dataHSHistory?.isLoading === undefined) {
      useGetByDateHSHistory()
    }
  }, [dataHSHistory?.data?.heartScoreHistory])

  useEffect(() => {
    if (dataHSHistory?.data?.heartScoreHistory?.length > 0) {
      const dateLatest = dataHSHistory?.data?.heartScoreHistory[0]
      if (dateLatest.date) {
        const currentDate = new Date()
        const parsedDate = parse(dateLatest?.date, 'yyyy-MM-dd', new Date())
        const formattedDate = format(parsedDate, 'MMMM dd, yyyy')

        const fixedTime = parsedDate.getTime()
        const currentTime = currentDate.getTime()
        const differenceMs = currentTime - fixedTime
        const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24))

        setTestDate(formattedDate)
        setCountDate(differenceDays)
      }
    }
  }, [dataHSHistory?.data?.heartScoreHistory])

  const tableScore = useMemo(() => {
    const isLoading = resultHeartScore?.isLoading || false
    const data = resultHeartScore?.data?.results || undefined

    if (isLoading) {
      return (
        <div className="flex justify-center">
          <IconLoading />
        </div>
      )
    }

    if (!data) return <>{`Empty Data!`}</>

    const totalScore =
      data.totalScore && data.totalStatus ? (
        <div className={`flex w-full items-center space-x-[6px] font-semibold uppercase text-color-${data.totalStatus}-risk`}>
          <div className={`flex h-16 w-16 items-center justify-center rounded-full border-2 text-[25px] leading-[30px] border-color-${data.totalStatus}-risk`}>{data.totalScore}</div>

          <div className="leading-[19px text-[16px]">{data.totalStatus} RISK</div>
        </div>
      ) : null

    const tableScore = data.details
      ? data.details.map((detail) => {
          return (
            <div key={`resultDetail-${detail.category}`} className="flex items-center justify-between gap-3 border-t border-color-gray py-3">
              <div className="flex-1 font-semibold leading-[22px] text-color-overlay">{detail.category}</div>
              <div className={`font-semibold text-color-${detail.status}-risk whitespace-nowrap text-right uppercase`}>{`${detail.status}  ${detail.status === 'n/a' ? '' : 'Risk'}`}</div>
            </div>
          )
        })
      : null

    return (
      <>
        {totalScore}
        <h6 className="font-proxima-nova text-[15px] font-semibold uppercase leading-5">Test date: {testDate ?? ''}</h6>
        <div>
          {user?.heart_score_status === 'can_create' && (
            <div
              onClick={() => navigate('/heart-score')}
              className={`flex h-[62px] w-[100%] cursor-pointer flex-row items-center gap-4 rounded-[15px] bg-color-bg-primary px-[15px] py-[10px] text-[#FFF]`}
            >
              <div className={`h-[29px] w-[33px] bg-i-heart_score bg-no-repeat`} />
              <div className="font-proxima-nova text-[15px] font-medium uppercase">Take Your NEw Heart Score</div>
            </div>
          )}

          {/* <div
            onClick={openResetHS.onTrue}
            className={`flex h-[62px] w-[100%] cursor-pointer flex-row items-center gap-4 rounded-[15px] ${changeBgRYHS.value ? 'bg-color-bg-primary' : 'bg-color-violet'} px-[15px] py-[10px] text-[#FFF]`}
          >
            <div className={`h-[29px] w-[33px] bg-i-heart_score bg-no-repeat`} />
            <div className="font-proxima-nova text-[15px] font-medium uppercase">RESET YOUR HEART SCORE</div>
          </div>
          {openResetHS.value && (
            <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] flex items-center justify-center">
              <div className="absolute h-full w-full bg-color-overlay bg-opacity-50"></div>
              <div className="relative mx-12 w-[380px]">
                <div className="rounded-[15px] bg-white p-4 text-center text-[17px] font-medium leading-[21px] shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
                  <p>{`MyAdesso prompts you to retake your Heart Score every four months. If you believe you’ve entered your previous answers in error, you can delete your most recent Heart Score entry and repeat the self-assessment. Are you sure you want to delete all of your previous answers and score and start over?`}</p>
                </div>
                <div className="mt-10">
                  <ButtonCommon
                    // contentRef={contentRef}
                    style={ButtonStyles.Introduction}
                    width="w-[167px]"
                    height="h-[51px]"
                    // text={t('common.buttonConfirm')}
                    text={`Reset`}
                    onClick={() => {
                      openResetHS.onFalse()
                      changeBgRYHS.onToggle()
                    }}
                  />
                </div>
                <div className="mt-6">
                  <ButtonCommon style={ButtonStyles.Cancel} width="w-[167px]" height="h-[51px]" text={`Cancel`} onClick={openResetHS.onFalse} />
                </div>
              </div>
            </div>
          )} */}
        </div>
        <p className="font-bold">Here were your scores for each section:</p>
        <div className="border-b border-color-gray">{tableScore}</div>
      </>
    )
  }, [user, resultHeartScore, openResetHS, testDate])

  const heartScoreLatest = useMemo(() => {
    return (
      <div>
        <div className="space-y-5 pb-2">
          {tableScore}

          {/* <div className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-low-risk bg-color-low-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-low-risk">LOW RISK</div>
            </div>

            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-medium-risk bg-color-medium-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-medium-risk">MEDIUM RISK</div>
            </div>

            <div className="flex items-center space-x-[6px]">
              <div className="h-[18px] w-[18px] rounded-full border-color-high-risk bg-color-high-risk" />
              <div className="text-[12px] font-semibold uppercase leading-[15px] text-color-high-risk">HIGH RISK</div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }, [resultHeartScore, openResetHS])

  const button = useMemo(() => {
    return <ButtonMyHealth nameButton="See Past Heart Scores" bgButton="bg-color-violet" nextScreen={nextScreen} />
  }, [])

  return (
    <WrapperMyHealth title="myHeartScore" prevScreen={prevScreen} button={button}>
      <div className="m-2 flex flex-col justify-center gap-8">
        {/* <HeartScoreCustom resultHeartScore={resultHeartScore} /> */}
        {heartScoreLatest}
      </div>
    </WrapperMyHealth>
  )
}

export default HeartScoreLatest
