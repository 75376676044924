import axios from 'axios'
import {AdessoError} from "../error";
import {MyNow} from "../../models";

type GetMyNowResponse = {
  success: boolean
  data: MyNow | undefined
  message: string
}

export async function getMyNow(): Promise<GetMyNowResponse> {
  const {data, status} = await axios.get('/my-now')

  if (status !== 200) {
    console.info('getMyNow', data)
    throw new AdessoError(`Request getMyNow error - ${status} ${JSON.stringify(data)}`)
  }
  return data
}