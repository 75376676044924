import { DashboardWrapper } from '../Common/DashboardWrapper'
import { MyNow } from './MyNow'
import { MyHeart } from './MyHeart'
import { MyBeats } from '../Common/MyBeats'
import { MyHeartActions } from './MyHeartActions'
import { MyNextSession } from './MyNextSession'
import { GoDeeper } from './GoDeeper'

import { Deeper } from '../../models/deepers'
import { Goal } from '../../models/goal'
import { Session } from '../../models/session'
import { MyReminders } from './MyReminders'
import { MyHealth } from './MyHealth'
import { MyTutorials } from './MyTutorials'
import flagsmith from 'flagsmith'
import { DashboardStep } from '../../constants/introduction'

type Props = {
  isLoading?: boolean
  isIntroduction?: boolean
  score: number
  deepers?: Deeper[]
  goals?: Goal[]
  myGoalsIsLoading?: boolean
  myNow: string
  handleUpdateMyNow?: Function
  nextSession?: Session
  nextSessionIsLoading?: boolean
  refs?: React.Ref<any>[]
}

export const DashboardContent = ({ isIntroduction, score, refs = [], deepers, isLoading, goals, myGoalsIsLoading, myNow, handleUpdateMyNow, nextSession, nextSessionIsLoading }: Props) => {
  const featureEnabled = flagsmith.hasFeature('ACP')

  return (
    <DashboardWrapper className={`space-y-4 ${isIntroduction ? 'mb-48' : ''}`}>
      <MyNow jumpTo={refs[DashboardStep.MyNow]} myNowContent={myNow} handleUpdateMyNow={handleUpdateMyNow} isIntroduction={isIntroduction} />
      {featureEnabled && <MyHeart jumpTo={refs[DashboardStep.MyHeart]} />}
      <MyHeartActions jumpTo={refs[DashboardStep.MyHeartActions]} />
      <MyNextSession jumpTo={refs[DashboardStep.MyNextSession]} isLoading={nextSessionIsLoading} nextSession={nextSession} />
      {featureEnabled && <MyReminders jumpTo={refs[DashboardStep.MyReminder]} />}
      <MyHealth jumpTo={refs[DashboardStep.MyHealth]} />
      <GoDeeper jumpTo={refs[DashboardStep.GoDeeper]} deepers={deepers} isLoading={isLoading} isIntroduction={isIntroduction} />
      <MyTutorials />
    </DashboardWrapper>
  )
}
