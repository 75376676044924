import { AdessoError } from '../lib/error'

export function useLocalStorage() {
  const setItemLS = (key: string, value: string | object) => {
    const data = typeof value === 'object' ? JSON.stringify(value) : value
    try {
      localStorage.setItem(key, data)
    } catch (error) {
      throw new AdessoError('Error setItem LocalStorage')
    }
  }

  const getItemLS = <T,>(key: string): string | object[] | string[] | null | T => {
    const data = localStorage.getItem(key)

    if (!data) return null

    return isJSON(data) ? JSON.parse(data) : data
  }

  const deleteItemLS = (key: string) => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      throw new AdessoError(`Error deleteItem LocalStorage : ${key}`)
    }
  }
  const resetLocalStorage = () => {
    try {
      localStorage.clear()
    } catch (error) {
      throw new AdessoError('Error removeAllItems LocalStorage')
    }
  }

  const isJSON = (text: string): boolean => {
    return /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  }

  return { setItemLS, getItemLS, deleteItemLS, resetLocalStorage }
}
