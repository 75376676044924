import { Session04Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenHeartAssessment01 = ({ buttons }: Props) => {
  return (
    <Session04Wrapper buttons={buttons}>
      <div className="session-style">
        <h4>Refresh yourself… now!</h4>
        <p>
          There’s a secret killer, but ladies, we know… it’s not so secret to us! But maybe we don’t realize where it’s hiding… Stress is impacting us right in the middle of our hearts! It’s not about
          what we eat, or how we exercise (though those things help!). It’s deeper… tucked away in our emotions, our feelings, and our general states of mind.
        </p>
        <p>
          When you experience depression, anxiety or stress, your body releases stress hormones epinephrine and norepinephrine. Those hormones increase your heart rate and blood pressure, while the
          release of cortisol increases inflammation. Over time, these effects can lead to heart disease.
        </p>
        <p>Managing stress, anxiety, and depression is just as important as how you move and nourish yourself.</p>
        <p>So let’s refresh ourselves NOW!</p>
      </div>
    </Session04Wrapper>
  )
}
