import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'

import { AdessoResult } from '../../models'

import { HeartChartBeats } from '../../models/heart-chart-beats'
import { getHeartChartBeats } from '../../lib/request/get-heartChart-beats'
import { mapDataChartBeats } from '../../lib/maps/heart-chart-beats'

export function useHeartChartBeatsPage() {
  const [dataChartBeats, setDataChartBeats] = useState<AdessoResult<HeartChartBeats[]>>()

  const auth = useAuth()

  const getDataHeartChartBeats = useCallback(async () => {
    setDataChartBeats({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartChartBeats()

        if (res.data) {
          const dataChartBeats = mapDataChartBeats(res.data)
          setDataChartBeats({
            isLoading: false,
            data: dataChartBeats,
            error: undefined,
          })
        } else {
          setDataChartBeats({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setDataChartBeats({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get chart beats Error'),
      })
    }
  }, [])

  return { dataChartBeats, getDataHeartChartBeats }
}
