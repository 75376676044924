export enum GoDeeperTopics {
  COMMIT_TO_MY_HEART = 'COMMIT TO MY HEART',
  RELATE_FOR_MY_HEART = 'RELATE FOR MY HEART',
  MOVE_MY_HEART = 'MOVE MY HEART',
  KNOW_MY_NUMBERS = 'KNOW MY NUMBERS',
  NOURISH_MY_HEART = 'NOURISH MY HEART',
  ADVOCATE_FOR_MY_HEART = 'Advocate for My Heart',
  REFESH_MY_HEART = 'Refresh My Heart',
  MANAGE_MY_HORMONES = 'MANAGE MY HORMONES',
}

export enum GoDeeperTypes {
  BLOG = 'blog',
  VIDEO = 'video',
}

// const GoDeeperTopics = {
//   COMMIT_TO_MY_HEART: 'COMMIT TO MY HEART',
//   RELATE_FOR_MY_HEART: 'RELATE FOR MY HEART',
//   MOVE_MY_HEART: 'MOVE MY HEART',
//   KNOW_MY_NUMBERS: 'KNOW MY NUMBERS',
//   NOURISH_MY_HEART: 'NOURISH MY HEART',
//   ADVOCATE_FOR_MY_HEART: 'Advocate for My Heart',
//   REFESH_MY_HEART: 'Refresh My Heart',
//   MANAGE_MY_HORMONES: 'MANAGE MY HORMONES',
// } as const

// type GoDeeperTopics = typeof GoDeeperTopics[keyof typeof GoDeeperTopics]

export type Deeper = {
  slug: string
  type: GoDeeperTypes
  topic: GoDeeperTopics
  thumbnail: string
  title: string
  description: string
}

export type DeeperArticle = {
  id: number
  slug: string
  type: GoDeeperTypes
  topic: GoDeeperTopics
  banner: string
  title: string
  content: string
}

export type HearJournalArticle = {
  id: number
  deeper_id: number
  title: string
  content: string
  image: string
  order: number
  hasMedia: number
  mediaType: any
  mediaObject: any
  created_at: string
  updated_at: string
  slug: string
  is_finish?: number | any
  pivot: {
    goal_id: number
    deeper_article_id: number
  }
}
