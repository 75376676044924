type Props = {
  children?: React.ReactNode
  className?: string
}

export const BeatPopup = ({ children, className = '' }: Props) => {
  return (
    <div className={`fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-60`}>
      <div className={`relative top-24 mx-auto w-[95%] max-w-[500px] rounded-[25px] p-4 ${className}`}>{children}</div>
    </div>
  )
}
