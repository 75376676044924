import { add, eachDayOfInterval, endOfMonth, endOfWeek, format, getDay, isAfter, isBefore, isSameDay, isSameMonth, parse, startOfToday, startOfWeek, subMonths, subYears } from 'date-fns'
import { useMemo, useState } from 'react'
import { useBoolean } from '../../hooks/use-boolean'

interface Props {
  [_: string]: any
  styles?: any
  highlight?: Date[]
  selectedDate?: any
  togglePopover?: boolean
}

export const BasicCalendar = ({ highlight, selectedDate, togglePopover }: Props) => {
  const isPopoverOpen = useBoolean()
  const isArrowRight = useBoolean()
  const isArrowLeft = useBoolean(true)
  const today = startOfToday()

  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7']

  const [currMonth, setCurrMonth] = useState(() => format(today, 'MMM-yyyy'))
  const [changeMonth, setChangeMonth] = useState(() => format(today, 'MM-dd-yyyy'))
  const [pickDate, setPickDate] = useState(() => format(today, 'MM-dd-yyyy'))

  let firstDayOfMonth = parse(currMonth, 'MMM-yyyy', new Date())
  const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 })
  const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 })

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  })

  const getPrevMonth = (event: React.MouseEvent) => {
    event.preventDefault()
    // const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 })
    // const dateYearAgo = subYears(today, 1)
    const oneYearOneMonthAgo = subMonths(today, 13)
    const dayCurrentMonth = new Date(firstDayOfPrevMonth)
    const before = isBefore(oneYearOneMonthAgo, dayCurrentMonth)
    if (before) {
      isArrowRight.onTrue()
      setCurrMonth(format(firstDayOfPrevMonth, 'MMM-yyyy'))
      setChangeMonth(format(firstDayOfPrevMonth, 'MM-dd-yyyy'))
    } else {
      isArrowRight.onTrue()
      isArrowLeft.onFalse()
    }
  }

  const getNextMonth = (event: React.MouseEvent) => {
    event.preventDefault()
    // const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 })
    const dayCurrentMonth = new Date(today)
    const dateChoice = new Date(firstDayOfNextMonth)
    const after = isAfter(dayCurrentMonth, dateChoice)
    if (after) {
      isArrowLeft.onTrue()
      setCurrMonth(format(firstDayOfNextMonth, 'MMM-yyyy'))
      setChangeMonth(format(firstDayOfNextMonth, 'MM-dd-yyyy'))
    } else {
      isArrowLeft.onTrue()
      isArrowRight.onFalse()
    }
  }

  const handleClickDate = (day: any) => {
    setChangeMonth(format(day, 'MM-dd-yyyy'))
    selectedDate(format(day, 'MMMM d, yyyy'))
    setPickDate(day)
  }

  const calendarHeader = useMemo(() => {
    const textCurrentMonth = format(new Date(changeMonth), 'MMMM yyyy') //`${monthNames[monthIndex]} ${dateChange}, ${thisYear}`
    return (
      <div className="flex items-center space-x-1">
        <div
          onClick={getPrevMonth}
          className={`h-[24px] w-[24px] rounded-[50%]  bg-[length:auto] bg-no-repeat ${
            isArrowLeft.value ? `bg-[url('assets/images/icon/i-arrow_left.svg')] hover:cursor-pointer hover:bg-slate-200` : ''
          }`}
        />

        <h4 className="text-[13px] font-semibold uppercase leading-[17px] tracking-[0.15px] hover:cursor-pointer" onClick={() => isPopoverOpen.onToggle()}>
          {textCurrentMonth}
        </h4>

        <div
          onClick={getNextMonth}
          className={`h-[24px] w-[24px] rounded-[50%]  bg-[length:auto] bg-no-repeat ${
            isArrowRight.value ? `bg-[url('assets/images/icon/i-arrow_right.svg')] hover:cursor-pointer hover:bg-slate-200` : ''
          } `}
        />
      </div>
    )
  }, [currMonth, changeMonth, isArrowRight.value, isArrowLeft.value])

  const calendarData = useMemo(() => {
    return (
      <div className="relative">
        <div className="absolute z-10 w-full table-auto border-collapse border bg-[white] shadow-lg">
          {!togglePopover && <div className="p-2">{calendarHeader}</div>}
          <div className="">
            <div className="flex">
              {days.map((day, idx) => {
                return (
                  <div
                    key={idx}
                    className="w-[calc(100%/7)] bg-[white] text-center text-[14px] font-semibold leading-7 tracking-[0.15px] first-letter:uppercase first:rounded-[5px_0_0_5px] last:rounded-[0_5px_5px_0]"
                  >
                    {day}
                  </div>
                )
              })}
            </div>
            <div className="grid grid-cols-7 place-items-center py-4">
              {daysInMonth.map((day, idx) => {
                const oneYearAgo = subYears(today, 1)
                const afterToday = !isAfter(day, today)
                const beforeOneYear = !isBefore(day, oneYearAgo)
                const backgroundToday = `bg-[url('assets/images/icon/i-circle.svg')] bg-[length:auto_33px] text-white rounded-full`
                const bgHeartHightLight = `bg-[url('assets/images/icon/i-heart-2.svg')] bg-[length:auto_27px] text-white`
                let bg = isSameMonth(day, today)
                  ? `bg-[length:auto_33px] rounded-full ${afterToday && `hover:bg-[url('assets/images/icon/i-circle.svg')] hover:text-white`}`
                  : afterToday && beforeOneYear
                  ? `hover:bg-[url('assets/images/icon/i-circle.svg')] bg-[length:auto_33px] hover:text-white rounded-full  ${
                      isSameMonth(day, firstDayOfNextMonth) ? 'text-gray-200' : isSameMonth(day, firstDayOfPrevMonth) ? 'text-gray-200' : ''
                    } `
                  : 'text-gray-200'

                // if (isToday(day)) {
                //   bg = backgroundToday
                // }

                if (isSameDay(day, pickDate)) {
                  bg = backgroundToday
                }

                if (highlight?.includes(day)) {
                  bg = bgHeartHightLight
                }

                return (
                  <div key={idx} className={colStartClasses[getDay(day)]}>
                    <p
                      className={`flex h-8 w-8 ${afterToday && beforeOneYear && 'cursor-pointer'}  items-center justify-center
                      bg-center bg-no-repeat text-center text-[14px] font-semibold leading-[17px] ${bg} `}
                      onClick={() => {
                        if (afterToday && beforeOneYear) {
                          handleClickDate(day)
                        }
                      }}
                    >
                      {format(day, 'd')}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }, [currMonth, pickDate, isArrowRight.value, isArrowLeft.value])
  return (
    <>
      {togglePopover && calendarHeader}
      {togglePopover ? isPopoverOpen.value && calendarData : calendarData}
    </>
  )
}
