import { useLocales } from '../../locales'

export const ChangeLanguage = () => {
  const { allLangs, currentLang, onChangeLang } = useLocales()

  const handleChangeLanguage = (selectedLanguage: string) => {
    // Implement the function to change the language
    onChangeLang(selectedLanguage)
  }

  return (
    <div className="flex items-center space-x-2">
      {allLangs.map((lang) => (
        <button
          key={lang.value}
          onClick={() => handleChangeLanguage(lang.value)}
          className={`
          ${lang.value === currentLang.value ? 'outline outline-offset-0 outline-purple-300' : ''} mt-2 h-[24px] w-[32px] bg-${lang.value} bg-cover bg-no-repeat py-2 px-4 hover:bg-opacity-40`}
        ></button>
      ))}
    </div>
  )
}
