type Props = {
  children?: React.ReactNode
  title?: string
  className?: string
  backgroundImage?: string
}

export const SessionWrapper = ({ className, title, children, backgroundImage }: Props) => {
  const style = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined
  return (
    <section className={`relative z-10 flex min-h-[calc(100vh-58px)] w-full flex-col bg-[length:100%_158px] bg-[center_top] bg-no-repeat px-4 pt-[74px] ${className}`} style={style}>
      <div className="h-20 w-full font-butler text-[30px] leading-[35px] text-white md:text-center">{title}</div>

      <div className="flex flex-1 flex-col">{children}</div>
    </section>
  )
}
