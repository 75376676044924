import React, { useRef, useEffect, memo, useState } from 'react'
import * as d3 from 'd3'
import { useLocales } from '../../../locales'
import { BarData } from '../../../models/heartChart'
import { IconLoading } from '../../Common'

interface PropsBarChart {
  data: BarData[]
  width: number
  height: number
  color: string
  type: typeBarChart
  isLoading?: boolean
}
export enum typeBarChart {
  normal,
  special,
}

interface Props {
  data: BarData[]
  width: number
  height: number
  color: string
  title: string
  setSelectedButtonIndexBarChart: (index: number) => void
  type: typeBarChart
  selectedButtonIndex?: number
  isLoading?: boolean
}

const BarChart: React.FC<PropsBarChart> = memo(({ data, width, height, color, type, isLoading }) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const margin = { top: 20, right: 30, bottom: 40, left: 50 }
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  useEffect(() => {
    if (!data.length || !svgRef.current) return

    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove()

    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.day))
      .range([0, innerWidth])
      .paddingInner(0.5)
      .paddingOuter(0.1)

    const maxValue = type === typeBarChart.special ? d3.max(data.map((d) => d.value ?? 0)) : 10
    const yScale = d3
      .scaleLinear()
      .domain([0, maxValue || 10])
      .range([innerHeight, 0])
      .nice()

    const yAxisTickValues = d3.range(0, 11, 2)

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`)

    if (type === typeBarChart.normal) {
      // Draw horizontal grid lines
      yScale.ticks().forEach((value) => {
        g.append('line')
          .attr('x1', 0)
          .attr('x2', innerWidth)
          .attr('y1', yScale(value))
          .attr('y2', yScale(value))
          .attr('stroke', '#F0F0F0')
          .attr('stroke-width', 1)
          .attr('shape-rendering', 'crispEdges')
      })

      xScale.domain().forEach((day) => {
        g.append('line')
          .attr('x1', (xScale(day) ?? 0) + xScale.bandwidth() / 2)
          .attr('x2', (xScale(day) ?? 0) + xScale.bandwidth() / 2)
          .attr('y1', 0)
          .attr('y2', innerHeight)
          .attr('stroke', '#F0F0F0')
          .attr('stroke-width', 1)
      })
    } else {
      const middleY = innerHeight / 2

      g.append('line').attr('x1', 0).attr('x2', innerWidth).attr('y1', middleY).attr('y2', middleY).attr('stroke', '#974A9A').attr('stroke-width', 1).attr('stroke-dasharray', '5,5')
    }

    // Draw bars
    const barWidth = xScale.bandwidth()
    g.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => xScale(d.day) ?? 0 + (xScale.bandwidth() - barWidth) / 2)
      .attr('y', (d) => yScale(d.value) - (type === typeBarChart.special ? 2 : 0))
      .attr('width', barWidth)
      .attr('height', (d) => innerHeight - yScale(d.value))
      .attr('fill', color)

    if (type === typeBarChart.special) {
      g.selectAll('.text')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'label')
        .attr('x', (d) => (xScale(d.day) ?? 0) + xScale.bandwidth() / 2)
        .attr('y', (d) => yScale(d.value) - 15)
        .attr('dy', '.75em')
        .attr('text-anchor', 'middle')
        .text((d) => d.value || '')
        .style('fill', color)
        .style('font-family', 'Proxima Nova')
        .style('font-weight', '500')
        .style('font-size', '7.8px')
    }

    if (type === typeBarChart.normal) {
      // Add and style Y axis
      const yAxisGroup = g.append('g').call(d3.axisLeft(yScale).tickValues(yAxisTickValues).tickSize(0))
      yAxisGroup.selectAll('.tick line').style('stroke', 'none')

      yAxisGroup.selectAll('text').style('fill', '#A3A3A3').style('font-family', 'Proxima Nova').style('font-weight', '500').style('font-size', '7.8px').style('letter-spacing', '0.15px')

      yAxisGroup.select('.domain').style('stroke', '#F0F0F0')
    }
    // Add and style X axis
    const xAxisGroup = g.append('g').attr('transform', `translate(0,${innerHeight})`).call(d3.axisBottom(xScale).tickSize(0))

    type === typeBarChart.normal ? xAxisGroup.select('.domain').remove() : xAxisGroup.select('.domain').style('stroke', '#F0F0F0')

    xAxisGroup.selectAll('text').style('fill', '#A3A3A3').style('font-family', 'Proxima Nova').style('font-weight', '500').style('font-size', '7.8px').style('letter-spacing', '0.15px')
  }, [data, innerWidth, innerHeight])

  return (
    <div ref={containerRef} style={{ width: width, height: height, position: 'relative' }} className="flex items-center justify-center">
      {isLoading ? <IconLoading /> : <svg ref={svgRef} width="100%" height="100%" />}
    </div>
  )
})

export const ContentBarChart: React.FC<Props> = memo(({ data, width, height, color, title, setSelectedButtonIndexBarChart, type, selectedButtonIndex, isLoading }) => {
  const { t } = useLocales()
  const containerRef = useRef<HTMLDivElement>(null)

  const [selectedButton, setSelectedButton] = useState(selectedButtonIndex)
  const dataGroup = [t('heartChartMain.groupButton.week'), t('heartChartMain.groupButton.month'), t('heartChartMain.groupButton.sixMonth')]
  const [chartSize, setChartSize] = useState({ width: width, height: height })

  useEffect(() => {
    function handleResize() {
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : width
      const chartWidth = window.innerWidth < 768 ? width : containerWidth
      const chartHeight = window.innerWidth < 768 ? height : height + 100
      setChartSize({ width: chartWidth, height: chartHeight })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [width, height])
  const GroupButton = () => {
    const getButtonStyle = (isSelected: boolean) => ({
      backgroundColor: isSelected ? color : '#ffffff',
      color: isSelected ? '#ffffff' : '#000000',
      borderRadius: '9999px',
      padding: '8px 16px',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '21px',
      fontFamily: "'Proxima Nova', sans-serif",
    })

    return (
      <div className="flex justify-around">
        {dataGroup.map((item, index) => {
          const isSelected = selectedButton === index
          return (
            <button
              key={index}
              style={getButtonStyle(isSelected)}
              onClick={() => {
                setSelectedButton(index)
                setSelectedButtonIndexBarChart(index)
              }}
            >
              <p className='font-["Proxima Nova"] text-[15px] font-[500] leading-[21px]'>{item}</p>
            </button>
          )
        })}
      </div>
    )
  }
  return (
    <div ref={containerRef} className="mt-3 w-full">
      <p className="font-['Proxima Nova'] justify-start text-[15px] font-[600] leading-[21px]">{title}</p>
      <div className="flex w-full flex-col items-center justify-center">
        <BarChart data={data} width={chartSize.width} height={chartSize.height} color={color} type={type} isLoading={isLoading} />
      </div>
      <GroupButton />
    </div>
  )
})
