import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: any
  message?: string
}

export async function getHeartChartPie(): Promise<Response> {
  const { data, status } = await axios.get('/charts/completion-in-week')

  if (status !== 200) {
    console.info('getHeartChartPie', data)
    throw new AdessoError(`Request getHeartChart Pie error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartStressTracking(): Promise<Response> {
  const { data, status } = await axios.get('/charts/stress-in-week')

  if (status !== 200) {
    console.info('stress-in-week', data)
    throw new AdessoError(`Request stress in week  error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartLineBloodPressure(type?: string): Promise<Response> {
  const { data, status } = await axios.get(`/charts/blood-pressure?${type ? `type=${type}` : ''}`)

  if (status !== 200) {
    throw new AdessoError(`Request get Heart Chart Line Blood Pressure error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartLineWeight(type?: string): Promise<Response> {
  const { data, status } = await axios.get(`/charts/weight?${type ? `type=${type}` : ''}`)

  if (status !== 200) {
    throw new AdessoError(`Request get Heart Chart Line weight error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartRateGoal(): Promise<Response> {
  const { data, status } = await axios.get('/charts/rate-goal-in-week')

  if (status !== 200) {
    throw new AdessoError(`Request getHeartChart rate goal error - ${status} ${JSON.stringify(data)}`)
  }
  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}

export async function getHeartChartBar(barType?: string, type?: string): Promise<Response> {
  const { data, status } = await axios.get(`/charts/${barType}?${type ? `type=${type}` : ''}`)

  if (status !== 200) {
    console.info('getHeartChartPie', data)
    throw new AdessoError(`Request getHeartChart bar error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
