import { useCallback, useMemo, useState } from 'react'
import { SettingWrapper } from './Wrapper'
import { SettingSection } from './Section'
import { InputStyle, ButtonCommon, ButtonStyles, ErrorMessage, FormRadioStyle, ChangeLanguage } from '../Common'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSettingPage } from '../../hooks/pages/setting'
import { useLocales } from '../../locales'
import { useBoolean } from '../../hooks/use-boolean'
import { CLOSE_EYE_ICON, OPEN_EYE_ICON } from '../../constants'

type Props = {
  dataSetting: any
}

export const SettingContent = ({ dataSetting }: Props) => {
  const navigate = useNavigate()
  const { t } = useLocales()
  const useSetting = useSettingPage()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const passwordEye = useBoolean()

  const iconEyePassword = passwordEye.value ? `${OPEN_EYE_ICON}` : `${CLOSE_EYE_ICON}`

  const formikUser = useFormik({
    initialValues: dataSetting?.data?.user,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, `${t('validator.minLengthRequired', { length: 3, field: 'name' })}`)
        .max(50, `${t('validator.maxLengthRequired', { length: 50, field: 'name' })}`)
        .required(`${t('validator.requiredField', { field: 'name' })}`),
      email: Yup.string()
        .email(`${t('validator.invalidField', { field: 'email' })}`)
        .required(`${t('validator.requiredField', { field: 'email' })}`),
      phone_number: Yup.string()
        .min(9, `${t('validator.invalidField', { field: 'phone number' })}`)
        .max(12, `${t('validator.requiredField', { field: 'phone number' })}`),
      company_name: Yup.string()
        .min(3, `${t('validator.minLengthRequired', { length: 3, field: 'company' })}`)
        .max(50, `${t('validator.maxLengthRequired', { length: 50, field: 'company' })}`),
    }),
    onSubmit: (values) => {
      useSetting.postSettingUser(values)
    },
  })

  const formikNoti = useFormik({
    initialValues: dataSetting.data.noti,
    onSubmit: (values) => {
      useSetting.postSettingNoti(values).then(() => {
        alert('setting saved!')
      })
    },
  })

  const handleSubmit = useCallback((callback: () => void) => {
    callback()
  }, [])

  const labels = [
    {
      label: 'Once A Day',
      value: 'Daily',
    },
    {
      label: 'Weekly Digest',
      value: 'Monthly Digest',
    },
  ]

  return (
    <SettingWrapper>
      <SettingSection className="mx-auto max-w-[684px]" title={t('mainMenuAndSection.settings')}>
        <form className="bg-gray-100 px-4 py-6 md:bg-white" onSubmit={formikUser.handleSubmit}>
          <InputStyle value={formikUser.values.name} id="name" label={t('common.name')} name="name" placeholder={t('common.name') + ' ' + t('extraText.goesHere')} onChange={formikUser.handleChange} />
          {formikUser.errors.name && <ErrorMessage text={formikUser.errors.name} />}
          <InputStyle
            value={formikUser.values.email}
            id="email"
            label={t('common.email')}
            name="email"
            placeholder={t('common.email') + ' ' + t('extraText.goesHere')}
            onChange={formikUser.handleChange}
          />
          <InputStyle
            value={formikUser.values.phone_number}
            id="phone_number"
            label={t('auth.phoneNumber')}
            name="phone_number"
            placeholder={t('auth.phoneNumber') + ' ' + t('extraText.goesHere')}
            onChange={formikUser.handleChange}
          />
          <div className="grid grid-cols-2 gap-4">
            <InputStyle
              value={formikUser.values.city}
              id="city"
              label={t('common.city')}
              name="city"
              placeholder={t('common.city') + ' ' + t('extraText.goesHere')}
              onChange={formikUser.handleChange}
            />
            <InputStyle
              value={formikUser.values.country}
              id="country"
              label={t('common.state')}
              name="country"
              placeholder={t('common.state') + ' ' + t('extraText.goesHere')}
              onChange={formikUser.handleChange}
            />
          </div>
          <div className="relative">
            <InputStyle
              id="password"
              type={passwordEye.value ? 'text' : 'password'}
              label={t('common.password')}
              name="password"
              placeholder={t('common.password') + ' ' + t('extraText.goesHere')}
              onChange={formikUser.handleChange}
            />
            <div className={`top-3 ${iconEyePassword}`} onClick={passwordEye.onToggle} />
          </div>
          <InputStyle
            value={formikUser.values.company_name}
            id="company_name"
            label={t('auth.companyName')}
            name="company_name"
            placeholder={t('auth.companyName') + ' ' + t('extraText.goesHere')}
            onChange={formikUser.handleChange}
          />
          <div className="mt-6 flex justify-end">
            <ButtonCommon style={ButtonStyles.Setting} width="w-[112px]" height="h-[50px]" text={t('common.save')} onClick={() => handleSubmit(formikUser.handleSubmit)} />
          </div>
        </form>

        <div className="mt-6 bg-gray-100 px-4 py-6 md:bg-white">
          <p className="curser text-dark-purple mt-5 font-semibold">{t('common.language')}</p>
          <ChangeLanguage />
        </div>
        <form className="mt-6 bg-gray-100 px-4 py-6 md:bg-white" onSubmit={formikNoti.handleSubmit}>
          <p className="text-dark-purple mt-5 font-semibold">{t('common.notifications')}</p>
          <div className="mt-3 flex justify-between py-2">
            <label className="font-semibold text-pink-500">{t('common.email')}</label>
            <label className="relative inline-flex cursor-pointer items-center">
              <input type="checkbox" name="by_email" value="1" checked={formikNoti.values.by_email.includes('1')} className="peer sr-only" onChange={formikNoti.handleChange} />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-pink-500 peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
            </label>
          </div>
          {/* {formikNoti.values.by_email.includes('1') && (
            <div className="mt-4 px-4">
              {labels &&
                labels.map((unit, index) => {
                  return (
                    <FormRadioStyle
                      id={`email-${index}`}
                      checked={formikNoti.values.type_notifi_by_email == unit.value}
                      key={index}
                      name="type_notifi_by_email"
                      label={unit.label}
                      value={unit.value}
                      onChange={formikNoti.handleChange}
                    />
                  )
                })}
            </div>
          )} */}
          <div className="mt-3 flex justify-between py-2">
            <label className="font-semibold text-pink-500">{t('common.text')}</label>
            <label className="relative inline-flex cursor-pointer items-center">
              <input type="checkbox" name="by_phone" value="1" checked={formikNoti.values.by_phone.includes('1')} className="peer sr-only" onChange={formikNoti.handleChange} />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-pink-500 peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
            </label>
          </div>
          {/* {formikNoti.values.by_phone.includes('1') && (
            <div className="px-4">
              {labels &&
                labels.map((unit, index) => {
                  return (
                    <FormRadioStyle
                      id={`phone-${index}`}
                      checked={formikNoti.values.type_notifi_by_phone == unit.value}
                      key={index}
                      name="type_notifi_by_phone"
                      label={unit.label}
                      value={unit.value}
                      onChange={formikNoti.handleChange}
                    />
                  )
                })}
            </div>
          )} */}

          <div className="mt-6 flex justify-end">
            <ButtonCommon style={ButtonStyles.Setting} width="w-[112px]" height="h-[50px]" text={t('common.save')} onClick={() => handleSubmit(formikNoti.handleSubmit)} />
          </div>
        </form>
        <div className="mt-10">
          <ButtonCommon style={ButtonStyles.Dark} width="w-[112px]" height="h-[50px]" text={t('auth.logout')} onClick={() => navigate('/logout')} />
        </div>
      </SettingSection>
    </SettingWrapper>
  )
}
