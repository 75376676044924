import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EndHeartChart } from '../../components/MyTutorials/Screen/HeartChart/EndHeartChart'
import MyAdessoHeartChartIntroductionScreen from '../../components/MyTutorials/Screen/HeartChart/HeartChart'
import { dataEndOfHeartChartTutorial } from '../../constants/introduction'

type Props = {}

enum IntroductionScreen {
  heartChart,
  finish,
}

const HeartChartTutorial = (props: Props) => {
  const [screen, setScreen] = useState<IntroductionScreen>(IntroductionScreen.heartChart)
  const navigate = useNavigate()

  const handleClickNextScreen = useCallback((stepCurrent: IntroductionScreen, shouldLogout = false) => {
    setScreen(stepCurrent + 1)
  }, [])

  const handleClickCloseDemo = useCallback(() => {
    navigate('/my-tutorials')
  }, [])

  const heartChartContent = useMemo(() => {
    return <MyAdessoHeartChartIntroductionScreen nextScreen={() => handleClickNextScreen(screen)} closeScreen={() => handleClickCloseDemo()} />
  }, [screen, handleClickNextScreen])

  switch (screen) {
    case IntroductionScreen.heartChart:
      return heartChartContent
    case IntroductionScreen.finish:
      return <EndHeartChart text={dataEndOfHeartChartTutorial.text} linkPages={dataEndOfHeartChartTutorial.pages} />

    default:
      return <></>
  }
}

export default HeartChartTutorial
