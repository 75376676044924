import { ButtonCommon, ButtonStyles } from '../../../../Common/Button'
import { useNavigate } from 'react-router-dom'

import { useMemo } from 'react'
import { Session01Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenHeartAction = ({ buttons }: Props) => {
  const navigate = useNavigate()
  const screenHeartAction = useMemo(() => {
    return (
      <Session01Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p className="text-[18px] leading-[22px]">
            Remember at the beginning of this experience that you selected a goal?
            <br />
            <br />
            Here it is again:
            <br />
            <br />
            <span className="font-bold uppercase">Commit to a heart-healthy life.</span>
            <br />
            <br />
            Now you understand the Risk Factors that affect your heart. However, the MyAdesso experience isn't just about knowing. That would be living from your head. We’re living from our hearts and
            that requires doing. This means taking action that changes our mindsets, habits, and behaviors.
            <br />
            <br />
            And we will prompt you every day to journal and record your lifestyle choices.
            <br />
            <br />
            In this first goal you can choose to track areas like sleep, weight, and alcohol consumption.
            <br />
          </p>

          <p className="text-[18px] leading-[22px]">Keep the momentum! Log your actions on your dashboard every day and keep moving through the sessions to add more goals and actions.</p>
        </div>
      </Session01Wrapper>
    )
  }, [buttons])

  return <>{screenHeartAction}</>
}
