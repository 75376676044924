const currentStepOfSymptoms = 4
const stepPrevOfSymptoms = currentStepOfSymptoms - 1
const currentPageOfSymptoms = 7

export const paramsOfSymptoms = {
  currentStepOfSymptoms: currentStepOfSymptoms,
  stepPrevOfSymptoms: stepPrevOfSymptoms,
  currentPageOfSymptoms: currentPageOfSymptoms,
}

export const ABOUT_SELF_SCREEN = 0
