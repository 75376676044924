import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { allLangs, defaultLang } from './config-lang'
import { useLocalStorage } from '../hooks/use-local-storage'

const { getItemLS } = useLocalStorage()

export default function useLocales() {
  const { i18n, t } = useTranslation()
  const lng = getItemLS('i18nextLng') as string

  const currentLang = allLangs.find((f) => f.value === lng) || defaultLang

  const onChangeLang = useCallback(
    (newlang: string | undefined) => {
      i18n.changeLanguage(newlang)
    },
    [i18n]
  )

  return {
    allLangs,
    t,
    currentLang,
    onChangeLang,
  }
}
