import { useMemo } from 'react'
import { Session10Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session10Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            <b>Welcome and Congratulations!</b>
            <br />
            You’ve nearly made it through the MyAdesso Learning Journey. This is your final session, but the journey continues.
            <br />
            <br />
            Watch the video below to begin.
          </p>

          <VimeoEmbed embedId="808490846" />
        </div>
      </Session10Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
