import { mapDataModalProgress, mapDataHeartRickFactors } from '../maps/session'
import { mapQuestion, mapQuestionNotRequired } from '../maps/question'

import { Question, QuestionTypes } from '../../models/question'
import { CoreConceptsStops, SessionScreen, ProgressScreenStatus, ProgressScreenType } from '../../models/session'

export const dummyProgressData: ProgressScreenType[] = [
  mapDataModalProgress(SessionScreen.BigIdeasVideo, 'Big Ideas', 5, ProgressScreenStatus.Done),
  mapDataModalProgress(SessionScreen.HeartAssessment, 'Heart Assessment', 2),
  mapDataModalProgress(SessionScreen.CoreConcepts, 'Core Concepts', 5),
  mapDataModalProgress(SessionScreen.HeartToHeart, 'Heart to Heart: Listen to Your Heart', 3),
  mapDataModalProgress(SessionScreen.HeartReflection, 'Heart Reflection', 2),
  mapDataModalProgress(SessionScreen.HeartAction, 'Heart Actions', 2),
]

const option01 = ['6 or more times', '4-5 times', '2-3 times', '1 time', 'Less than 1']
const option02 = [option01[4], option01[3], option01[2], option01[1], option01[0]]
const option03 = ['Heaping amount', 'A lot', 'Some', 'Very little', 'None']

export const sesstion03Questions: Question[] = [
  mapQuestionNotRequired(
    'ss03__016',
    QuestionTypes.TextArea,
    'What is your dietary Achilles heel, the food that most tempts you? How often each week do you indulge?',
    'Describe what you want to change.'
  ),
  mapQuestionNotRequired('ss03__017', QuestionTypes.TextArea, 'What can you swap the next time the craving occurs?', 'Describe what you want to change.'),
  mapQuestionNotRequired('ss03__001', QuestionTypes.Rate, 'Shopping'),
  mapQuestionNotRequired('ss03__002', QuestionTypes.Rate, 'Selecting meals'),
  mapQuestionNotRequired('ss03__003', QuestionTypes.Rate, 'Cooking'),
  mapQuestionNotRequired('ss03__004', QuestionTypes.Rate, 'Snacking'),
  mapQuestionNotRequired('ss03__005', QuestionTypes.Rate, 'Tasting, chewing and savoring your meal'),
  mapQuestionNotRequired('ss03__006', QuestionTypes.Rate, 'Recognizing how your body feels after you’ve eaten'),
  mapQuestionNotRequired('ss03__007', QuestionTypes.Radio, 'How many times a day did you eat fast/fried food/or packaged snacks high in fat/salt/or sugar?', undefined, option02),
  mapQuestionNotRequired('ss03__008', QuestionTypes.Radio, 'How many servings (1 serving = 1/2 cup) of fresh fruit did you eat each day?', undefined, option02),
  mapQuestionNotRequired('ss03__009', QuestionTypes.Radio, 'How many servings of fresh, canned, frozen or dried vegetables did you eat each day?', undefined, option02),
  mapQuestionNotRequired(
    'ss03__010',
    QuestionTypes.Radio,
    'How many regular soda, sweet tea, juice, energy/sports drinks, sweetened-coffee or other sugar sweetened beverages did you drink each day?',
    undefined,
    option02
  ),
  mapQuestionNotRequired('ss03__011', QuestionTypes.Radio, 'How many times a day did you eat regular (not low-fat) snack chips or crackers?', undefined, option02),
  mapQuestionNotRequired(
    'ss03__012',
    QuestionTypes.Radio,
    'How many times a day did you eat sweet foods (not the low-fat kind) or desserts, like chocolate or ice cream, and other sweets?',
    undefined,
    option02
  ),
  mapQuestionNotRequired('ss03__013', QuestionTypes.Radio, 'How much margarine, butter, lard, meat fat did you add to vegetables, potatoes, bread, corn or dried meat?', undefined, option03),
  mapQuestionNotRequired('ss03__014', QuestionTypes.Radio, 'How many times a day did you eat dairy products (milk, unsweetened yogurt, low fat cheese)?', undefined, option02),
  mapQuestionNotRequired('ss03__015', QuestionTypes.Input, 'Where do you feel you need to be making changes?'),
]

function mapCoreConcepts(id: number, img: string = '', imgWebp = '', title: string, description: string = '', content: string = '', status = 0): CoreConceptsStops {
  return { id, img, title, description, content, status, imgWebp }
}

const contentCoreConcepts = [
  `
  <p>We’re taking a mindful approach, so let’s pause. Now that you’ve asked these questions again, does it change how you felt answering the questions? Perhaps you were more aware of things you want to change. We can all improve how we nourish ourselves.</p>
  `,
  `
  <p>Nourishment is often about making swaps, not denying yourself. As we become aware and mindful, we see the value of swapping out foods that don't nourish you or even hurt your heart with nutrient dense foods that can assist the health of your heart. <br/> <br/> Our bodies are all different and different eating habits interact uniquely with our own personal metabolism and genetic factors. Finding the diet that's best for you is part of living with vitality.</p>
  `,
  `
  <p>Let's figure out the best way for you to eat that makes you feel satisfied, energized and keeps all the healthy markers in check. This is why we call this session “Nourishing Your Heart.” The process of nourishing your body involves far more than simply putting food in your mouth and filling your stomach. Nourishing your body involves making decisions that support your unique family history, medical history, habits, environment, and physical needs. The better you know yourself, your testing numbers, and your habits, the better you know how to support your heart health. And the better you can work to nourish yourself through your diet and food habits.</p>
  `,
  `
  <p>We’ve already discussed one of the biggest changes in your approach to eating, which is to view the process of eating for how it nourishes you and supports your body.  Let’s talk about some of the other ways in which you may need to adopt new mindsets and habits to support a more nourishing lifestyle. Click on some of the topics below to explore what can help you as you begin to shift your habits towards nourishing yourself.</p>
  <ul>
    <li>Accountability</li>
    <li>Food fantasies</li>
    <li>Take a look in the mirror</li>
    <li>Habits</li>
    <li>Change one thing </li>
    <li>Establish a principle and live by it</li>
  </ul>
  `,
  `
  <p>Studies have shown that most people don’t really know what they’ve eaten or how much they’ve eaten. They always underestimate their portions. Be accountable of what you eat for one week by recording everything you eat!</p>
  <p>(We can help! If you choose this as your goal, you’ll earn daily beats for journaling here).</p>
  `,
  `
  <p>We all crave food. We all have our emotional food. Eating is tied to every level of our being right down to the fact we make a choice 3-4 times a day to nourish ourselves or harm ourselves.</p>
  <p>Make a list of the unhealthy foods you regularly eat. Circle 1 and only 1! Choose 1 time a week you get to enjoy it. Highlight it. Reward yourself when you get to that time and enjoy it, but only if you didn’t eat the others!</p>
  <p>You can also find a new non-guilty pleasure! Try 5 new healthy foods and taste them. Find a new love and make it a point to reward yourself with this new snack.</p>
  `,
  `
  <p>Get naked and take a look at yourself! Notice the parts you’re proud of. Be honest with yourself about the parts of your body that need attention. Make a promise to your body that you’ll do what’s best for it. This is not about judging yourself. This is about being honest. We are not looking for anything but a healthy you!</p>
  `,
  `
  <p>Most of us don’t really know what trips us up. Sure we understand the big things, like a hamburger and frie for lunch. But it’s the patterns that get us off track. </p>
  <p>Write down everything you can remember eating for the last three days. Does anything surprise you? Don’t feel guilty!</p>
  <p>Notice which foods you gravitate towards - crunchy-salty, soft-sweet, etc. Identify what foods are your Achilles’ heel. This can help you make healthy, active and mindful changes.</p>
  `,
  `
  <p>Drastic change is hard. Start small. Focus on your one worst habit. If you can’t imagine giving up the chips you eat every night, think about what you might be able to substitute the chips with that might be a healthier option - like unsalted nuts or blueberries. </p>
  <p>Make this one change… ADESSO! </p>
  `,
  `
  <p>Focus on making better choices rather than “going on a diet.” Develop the key practices that encourage healthy heart nourishment for you and live by them as principles. Following your principles most of the time, you’ll be moving in the right direction.</p>
  <p>You can learn how to create your own Nourishment Principles in the Go Deeper Library at the end of the session.</p>
  `,
  ``,
  `
  <p>Generally, we recommend the Mediterranean Diet, which can support heart health for most people.</p>
  <p>The Mediterranean Diet is based on the traditional foods that people eat in countries on the Mediterranean Sea like Greece and Italy.</p>
  <p>Research has proven that the Mediterranean Diet keeps people healthy and lowers their risk of many chronic conditions.</p>
  <p>The Mediterranean Diet consists of fruits, vegetables, whole grains, legumes, nuts, seeds, and heart-healthy omega 3 fatty acids.</p>
  <p>Processed foods, added sugar, and refined grains should be restricted.</p>
  <p>This wonderful approach to food is so important you can learn so much more in the Go Deeper Library link at the end of this section.</p>
  `,
  `
  <p>For some people, we diverge a little bit from just the Mediterranean Diet. Depending on your health markers and your family history, we might recommend a low-fat, low-carbohydrate, or low-salt diet.</p>
  <p>Diet can have a huge impact on your health, so let’s think of diet as a tool to manage health issues. In fact, lowering blood pressure might be as simple as lowering salt intake. Eating less than 2,300 mg of salt is a starting point, but if your blood pressure is high, cutting back to 1,500 mg of salt a day might be essential to getting this blood pressure under control.</p>
  <h4>Here’s how to choose the best plan for you:</h4>
  <div>
    <p class="font-bold">We encourage a low-fat diet:</p>
    <ul>
      <li>If you have high cholesterol with an elevated LDL.</li>
      <li>If you have a strong family history of strokes, heart disease, or Alzheimer’s.</li>
      <li>If you know that your ApoE is 3/4 or 4/4</li>
    </ul>
    <p>If these apply to you, you’ll need to watch sugars or high fats, reduce sugar intake and refrain from eating simple carbs.</p>
  </div>
  <div>
    <p class="font-bold">We encourage a low-sugar diet:</p>
    <ul>
      <li>If you have high sugars (diabetes or metabolic syndrome, or an increase in belly fat).</li>
      <li>If you have a strong family history of diabetes or obesity.</li>
      <li>If you know that your ApoE is 2/3.</li>
      <li>If your triglycerides are elevated.</li>
    </ul>
    <p>If these apply to you, you’ll need to watch sugars or high fats, reduce sugar intake and refrain from eating simple carbs.</p>
  </div>
  <div>
    <p class="font-bold">We encourage a low-salt diet:</p>
    <ul>
      <li>If you have high blood pressure.</li>
      <li>If you are salt sensitive.</li>
      <li>If you have a strong family history of high blood pressure and strokes.</li>
    </ul>
    <p>If these apply to you, you’ll need to monitor your salt intake.</p>
  </div>
  <p>You can learn more about each of these food plans in our Go Deeper Library.</p>
  <p>You can also look at the specific results of your tests to narrow down areas of your eating habits to focus on. We will focus on these factors in the “Getting to Know Your Heart” and “Know Your Numbers” sessions, but in short, you should try to get the following information from tests you take to help you understand the factors that influence your diet.</p>
  `,
]

const coreConceptsStop01 = `
<h4>Start your day with protein and complex carbs</h4>
<p>It’s best to start your day with complex carbs + protein. Protein and fiber slows the release of blood sugar and keeps you feeling full. Some people may consider intermittent fasting and skipping breakfast. Talk to your doctor before you try that as an option.</p>

<div>
  <h4>Do’s:</h4>
  <p>Here are some ideas for foods to integrate into your breakfast diet:</p>
  <ul>
    <li>Egg-white omelet with veggies and multi-grain toast</li>
    <li>Multi-grain toast with low-fat cheese and tomato</li>
    <li>Greek yogurt with fruit and granola</li>
  </ul>
</div>

<div>
  <h4>Don’ts:</h4>
  <p>Notice that we’ve left out all of those sugary breakfast cereals we all know and love!</p>
</div>`

const coreConceptsStop02 = `
<h4>Avoid simple carbohydrates and decrease carb intake in the evening</h4>
<p>White or light yellow-colored foods full of simple carbs cause your blood sugar to spike, then crash. Foods like white pasta or white rice can do the same thing. Eating them in moderation or as a small part of the meal, instead of the main course, might be the best option.</p>
<p>You can eat grains/fruits during breakfast or lunch, when you need the energy. But carbs are no longer needed as you wind down at night. Eat as few carb-rich foods as possible after 3pm.</p>

<div>
  <h4>Do’s:</h4>
  <ul>
    <li>Eat complex grains with wheat, amaranth, spelt, bulgar, quinoa.</li>
    <li>Salmon, vegetables, and legumes for dinner.</li>
  </ul>
</div>

<div>
  <h4>Don’ts:</h4>
  <p>Bread, pizza, fruit, sugary pastries, etc for dinner.</p>
</div>

<div class="flex-col-3">
  <div>
    <div class="aspect-video bg-cover bg-no-repeat bg-center bg-[url('/images/my-adresso-journey/session/03/thumbnail__001.webp')]"></div>
    <p>White bread</p>
  </div>
  <div>
    <div class="aspect-video bg-cover bg-no-repeat bg-center bg-[url('/images/my-adresso-journey/session/03/thumbnail__002.webp')]"></div>
    <p>Muffins</p>
  </div>
  <div>
    <div class="aspect-video bg-cover bg-no-repeat bg-center bg-[url('/images/my-adresso-journey/session/03/thumbnail__003.webp')]"></div>
    <p>Cookies</p>
  </div>
  <div>
    <div class="aspect-video bg-cover bg-no-repeat bg-center bg-[url('/images/my-adresso-journey/session/03/thumbnail__004.webp')]"></div>
    <p>Potatoes</p>
  </div>
  <div>
    <div class="aspect-video bg-cover bg-no-repeat bg-center bg-[url('/images/my-adresso-journey/session/03/thumbnail__005.webp')]"></div>
    <p>Corn</p>
  </div>
  <div>
    <div class="aspect-video bg-cover bg-no-repeat bg-center bg-[url('/images/my-adresso-journey/session/03/thumbnail__006.webp')]"></div>
    <p>Bagels</p>
  </div>
</div>`

const coreConceptsStop03 = `
<h4>Never eat more than one sugary item per day</h4>
<p>The more refined sugars you eat, the more insulin your body releases and the greater your risk of obesity – and in turn, cardiovascular disease. A sugary diet may increase your heart risk even if you aren’t obese. It can raise your LDL (the bad cholesterol) and lower your HDL (the good cholesterol) and increase your sugar levels in your bloodstream.</p>

<div>
  <h4>Do’s:</h4>
  <ul>
    <li>1-2 squares of dark chocolate per day.</li>
  </ul>
</div>

<div>
  <h4>Don’ts:</h4>
  <ul>
    <li>Frequently eating ice cream, pastries, cookies, donuts, candies, muffins.</li>
  </ul>
</div>

<picture>
  <source srcset="/images/my-adresso-journey/session/03/img_022.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/03/img_022.jpg" alt="">
</picture>`

const coreConceptsStop04 = `
<h4>No soda!</h4>
<p>Never, ever drink any type of soda, regular or diet. A study released by the American Heart Journal showed that people who drank at least five sugar-sweetened sodas a week were more likely to have early signs of heart disease than people who drank less than one soda weekly.</p>

<div>
  <h4>Do’s:</h4>
  <ul>
    <li>Water!</li>
    <li>Coffee and Tea (if desired, and in moderation).</li>
  </ul>
</div>

<div>
  <h4>Don’ts:</h4>
  <ul>
    <li>Soda</li>
    <li>Juices</li>
    <li>Artificially sweetened beverages</li>
    <li>Energy drinks</li>
  </ul>
</div>

<picture>
  <source srcset="/images/my-adresso-journey/session/03/img_023.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/03/img_023.png" alt="">
</picture>
`

const coreConceptsStop05 = `
<h4>Lower fats and high cholesterol foods!</h4>
<p>Your body needs good cholesterol (HDL) to build healthy cells, but high levels of bad cholesterol (LDL) can increase your risk of heart disease. With high cholesterol, you can develop fatty deposits in your blood vessels. Eventually, these deposits grow, leading to plaque formation and atherosclerosis (blockages in the arteries). For this reason, it’s important to avoid fried foods, fatty meat products, processed meats, and snacks.</p>

<div>
  <h4>Do’s:</h4>
  <ul>
    <li>Vegetables</li>
    <li>Salmon</li>
    <li>Legumes</li>
  </ul>
</div>

<div>
  <h4>Don’ts:</h4>
  <ul>
    <li>High-fat foods: deli meats, steak, chicken, turkey, high-fat soft cheeses.</li>
    <li>High-carb foods: white bread, pizza, sugary fruits like mango and papaya, pastries, cookies</li>
  </ul>
</div>

<picture>
  <source srcset="/images/my-adresso-journey/session/03/img_024.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/03/img_024.jpg" alt="">
</picture>
`

const coreConceptsStop06 = `
<h4>Eat more veggies</h4>
<p>Eat as many veggies as you can! Be “as vegetarian as possible”.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/03/img_025.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/03/img_025.jpg" alt="">
</picture>

<div>
  <h4>Do’s:</h4>
  <ul>
    <li>Broccoli, lettuce, greens, carrots, sweet potatoes, etc.</li>
  </ul>
</div>`

const coreConceptsStop07 = `
<h4>Add omega-3 into your diet</h4>
<p>Omega-3 fatty acids increase HDL while decreasing LDL, inflammation and clotting.</p>

<picture>
  <source srcset="/images/my-adresso-journey/session/03/img_026.webp" type="image/webp">
  <img src="/images/my-adresso-journey/session/03/img_026.jpg" alt="">
</picture>

<div>
  <h4>Do’s:</h4>
  <ul>
    <li>Eat fish 2-3 times per week</li>
    <li>Use olive oil, canola oil or flaxseed oil</li>
    <li>Or take a daily supplement</li>
  </ul>
</div>

<div>
  <h4>Don’ts:</h4>
  <ul>
    <li>Watch out for cooking certain oils on high heat.</li>
  </ul>
</div>`

export const dataCoreConcepts = [
  mapCoreConcepts(1, 'img_001.jpg', 'img_001.webp', 'A fresh look at how we nourish.', '', contentCoreConcepts[0]),
  mapCoreConcepts(2, 'img_002.jpg', 'img_002.webp', 'Personalizing your diet for nourishment.', '', contentCoreConcepts[1]),
  mapCoreConcepts(3, 'img_003.jpg', 'img_003.webp', 'The best plan for you.', '', contentCoreConcepts[2]),
  mapCoreConcepts(4, undefined, undefined, ''),
  mapCoreConcepts(11, 'ss_03.mp4', undefined, 'Oh how I love Italia!', '', contentCoreConcepts[10]),
  mapCoreConcepts(12, 'img_012.jpg', 'img_012.webp', 'The Mediterranean Diet.', '', contentCoreConcepts[11]),
  mapCoreConcepts(14, undefined, undefined, ''),
  mapCoreConcepts(15, 'img_015.jpg', 'img_015.webp', 'The Breakfast Aisle.', 'Start your day with proteins and complex carbs.', coreConceptsStop01),
  mapCoreConcepts(16, 'img_016.jpg', 'img_016.webp', 'The Bread Aisle.', 'Avoid simple carbohydrates and decrease carb intake in the evening.', coreConceptsStop02),
  mapCoreConcepts(17, 'img_017.jpg', 'img_017.webp', 'The Candy Aisle.', 'Never eat more than one sugary item per day.', coreConceptsStop03),
  mapCoreConcepts(18, 'img_018.jpg', 'img_018.webp', 'The Beverage Aisle.', 'No soda!', coreConceptsStop04),
  mapCoreConcepts(19, 'img_019.jpg', 'img_019.webp', 'The Meat and Frozen Section.', 'Lower fats and high cholesterol foods!', coreConceptsStop05),
  mapCoreConcepts(20, 'img_020.jpg', 'img_020.webp', 'The Produce Aisle.', 'Eat more veggies!', coreConceptsStop06),
  mapCoreConcepts(21, 'img_021.jpg', 'img_021.webp', 'The Cooking Section.', 'Add omega-3 into your diet.', coreConceptsStop07),
  mapCoreConcepts(13, 'img_013.jpg', 'img_013.webp', 'Personalize your nourishment plan.', '', contentCoreConcepts[12]),
]

export const dataCoreConceptsStops01: CoreConceptsStops[] = [
  mapCoreConcepts(1, 'img_015.jpg', undefined, 'The Breakfast Aisle.', 'Start your day with proteins and complex carbs.', coreConceptsStop01),
  mapCoreConcepts(2, 'img_016.jpg', undefined, 'The Bread Aisle.', 'Avoid simple carbohydrates and decrease carb intake in the evening.', coreConceptsStop02),
  mapCoreConcepts(3, 'img_017.jpg', undefined, 'The Candy Aisle.', 'Never eat more than one sugary item per day.', coreConceptsStop03),
  mapCoreConcepts(4, 'img_018.jpg', undefined, 'The Beverage Aisle.', 'No soda!', coreConceptsStop04),
  mapCoreConcepts(5, 'img_019.jpg', undefined, 'The Meat and Frozen Section.', 'Lower fats and high cholesterol foods!', coreConceptsStop05),
  mapCoreConcepts(6, 'img_020.jpg', undefined, 'The Produce Aisle.', 'Eat more veggies!', coreConceptsStop06),
  mapCoreConcepts(7, 'img_021.jpg', undefined, 'The Cooking Section.', 'Add omega-3 into your diet.', coreConceptsStop07),
]

export const dataCoreConceptsStops02: CoreConceptsStops[] = [
  // mapCoreConcepts(4, 'img_004.png', 'Making changes toward nourishmen', '', contentCoreConcepts[3]),
  mapCoreConcepts(5, 'img_005.jpg', 'img_005.webp', 'Take responsibility and be accountable.', 'Accountability', contentCoreConcepts[4]),
  mapCoreConcepts(6, 'img_006.jpg', 'img_006.webp', 'Say goodbye to food fantasies.', 'Food fantasies', contentCoreConcepts[5]),
  mapCoreConcepts(7, 'img_007.jpg', 'img_007.webp', 'Take a look in the mirror.', 'Take a look in the mirror', contentCoreConcepts[6]),
  mapCoreConcepts(8, 'img_008.jpg', 'img_008.webp', 'Analyze your habits.', 'Habits', contentCoreConcepts[7]),
  mapCoreConcepts(9, 'img_009.jpg', 'img_009.webp', 'Change one thing.', 'Change one thing', contentCoreConcepts[8]),
  mapCoreConcepts(10, 'img_010.jpg', 'img_010.webp', 'Establish a principle and live by it', 'Establish a principle and <br /> live by it', contentCoreConcepts[9]),
]
