import { useEffect, useMemo, useState } from 'react'
import { HeartJournalWrapper } from '../HeartJournal/Wrapper'
import { HeartChartSection } from './Section'
// import { ActionsItem } from './ActionsItem'
// import { RedeemYourBeatsItem, Beat } from './RedeemYourBeatsItem'
import { MyBeats } from '../Common/MyBeats'
import { useNavigate } from 'react-router-dom'
import { getHeartChartResult } from '../../lib/request'
import { useLocales } from '../../locales'
import { useHeartScoreResultPage } from '../../hooks/pages/heart-score-result'

// const CarouselItems: Beat[] = [
//   {
//     id: 'cook_like_a_chef_class',
//     title: 'Cook like a Chef Class',
//     image: 'images/redeem-your-beats/1.png',
//     point: 140,
//     link: '#',
//   },
//   {
//     id: 'myadesso_favorite_cap',
//     title: 'MyAdesso Favorite Cap',
//     image: 'images/redeem-your-beats/2.png',
//     point: 90,
//     link: '#',
//   },
//   {
//     id: 'myadesso_favorite_juice',
//     title: 'MyAdesso Favorite Juice',
//     image: 'images/redeem-your-beats/3.png',
//     point: 25,
//     link: '#',
//   },
//   {
//     id: 'juicing_for_a_healthy_heart_2',
//     title: 'Juicing for a healthy heart',
//     image: 'images/redeem-your-beats/1.png',
//     point: 111,
//     link: '#',
//   },
// ]

type Props = {
  isIntroduction?: boolean
}
export const HeartChartContent = ({ isIntroduction }: Props) => {
  const [totalHeartChart, setTotalHeartChart] = useState(0)
  const [riskLevel, setRiskLevel] = useState('')
  const navigate = useNavigate()
  const { t } = useLocales()
  const { resultHeartScore, getResultHeartScore } = useHeartScoreResultPage()

  const getResult = async () => {
    const res = await getHeartChartResult()
    setTotalHeartChart(res.data.totalScore)
    setRiskLevel(res?.data?.risk_level)
  }

  useEffect(() => {
    getResult()
  }, [])

  useEffect(() => {
    if (resultHeartScore?.data) return
    getResultHeartScore()
  }, [resultHeartScore?.data])

  const handleTest = () => {
    navigate('/heart-chart/testing')
  }

  const handleResult = () => {
    navigate('/heart-chart/result')
  }

  const totalChart = useMemo(() => {
    const dataResultHeartScore = resultHeartScore?.data?.results

    return (
      <HeartChartSection className="space-y-4 pt-10" title={t('mainMenuAndSection.myHeartScore')}>
        <div className="flex justify-between">
          <div
            className={`border-color-${dataResultHeartScore?.totalStatus}-risk mr-4 flex h-[130px] w-[130px] items-center justify-center rounded-full border-[10px] font-['Lato'] text-[44px] leading-none text-color-${dataResultHeartScore?.totalStatus}-risk`}
          >
            {totalHeartChart}
          </div>
          <div className="w-[calc(100%-146px)]">
            <h3 className={`text-[24px] text-color-${dataResultHeartScore?.totalStatus}-risk`}>{t(`${riskLevel} Risk`)}</h3>
            <p className="pt-1 pb-4 text-[13px] leading-[16px]">{t('extraText.hearChartScoreDes')}</p>
          </div>
        </div>
        <div
          className={`w-full rounded-[15px] p-2`}
          style={{
            backgroundColor: dataResultHeartScore?.totalStatus === 'high' ? '#ED2580' : dataResultHeartScore?.totalStatus === 'low' ? '#974A9A' : '#F26E57',
          }}
          onClick={handleResult}
        >
          <div className="bg-[url('assets/images/icon/icon_check.webp')] bg-[length:auto_49px] bg-[center_left] bg-no-repeat py-2 pl-14 text-[11px] font-semibold uppercase leading-[16px] tracking-[0.15em] text-white">
            {t('extraText.seeYourMedicalResults')}
          </div>
        </div>
      </HeartChartSection>
    )
  }, [resultHeartScore, totalHeartChart, riskLevel])

  return (
    <HeartJournalWrapper className={`space-y-4 ${isIntroduction ? 'pb-52' : ''}`}>
      <h3 className="font-['Butler'] text-[24px] leading-[1.41667]">{t('mainMenuAndSection.heartChart')}</h3>
      <MyBeats showLink={false} />

      {/* <HeartChartSection className="space-y-4 py-[16px]" title="ACTIONS TO COMPLETE">
        <div className="space-y-4">
          <ActionsItem text="Take “Nourishment” Session." point={10} />
          <ActionsItem text="Enter Your Today’s Heart Rate" point={2} />
          <ActionsItem text="Create a menu for your week" point={2} />
        </div>
      </HeartChartSection> */}

      <HeartChartSection className="space-y-4" title={t('mainMenuAndSection.redeemYourBeats')}>
        {/* <div className="carousel-center carousel -mx-4 w-[calc(100%+32px)] space-x-3 px-4">
          {CarouselItems.map((item) => {
            return (
              <RedeemYourBeatsItem key={item.id} dataBeat={item}>
                <img src={item.image} className="h-auto w-full max-w-carousel-item"/>
              </RedeemYourBeatsItem>
            )
          })}
        </div> */}
        <p>
          <b>{t('extraText.heatChartComing')}</b>
          <br />
          <br />
          {t('extraText.heartChartRedeemDescription')}
        </p>
      </HeartChartSection>

      {/* <HeartChartSection className="space-y-4 py-[16px]" title="ACTIONS COMPLETED">
        <div className="space-y-4">
          <ActionsItem text="Heart Score" point={10} isCompleted />
          <ActionsItem text="My Now" point={30} isCompleted />
          <ActionsItem text="Sessions Completed" point={10} isCompleted />
          <ActionsItem text="Sessions Completed" point={10} isCompleted />
          <ActionsItem text="Actions" point={25} isCompleted />
          <ActionsItem text="Go Deeper" point={5} isCompleted />
        </div>
      </HeartChartSection> */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <HeartChartSection className="space-y-4" title={t('mainMenuAndSection.myTestResults')}>
          <div className="w-80 rounded-[15px] bg-[#EB2781] p-2 md:w-full" onClick={handleTest}>
            <div className="bg-[url('assets/images/icon/i-lab.webp')] bg-[length:auto_49px] bg-[center_left] bg-no-repeat py-2 pl-14 text-[15px] font-semibold uppercase leading-[17px] tracking-[0.15em] text-white">
              {t('extraText.updateYourTestingandLabNumbers')}
            </div>
          </div>
        </HeartChartSection>
        {totalChart}
      </div>
    </HeartJournalWrapper>
  )
}
