import React, { createContext, useContext, useState } from 'react'

type HeaderContextType = {
  showHeader: boolean
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>
  showFeedback: boolean
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>
  isHeaderAbsolute: boolean
  setIsHeaderAbsolute: React.Dispatch<React.SetStateAction<boolean>>
}

const initialHeaderState: HeaderContextType = {
  showHeader: true,
  setShowHeader: () => {},
  showFeedback: true,
  setShowFeedback: () => {},
  isHeaderAbsolute: false,
  setIsHeaderAbsolute: () => {},
}

const HeaderContext = createContext<HeaderContextType>(initialHeaderState)

export const useHeaderContext = () => useContext(HeaderContext)

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showHeader, setShowHeader] = useState<boolean>(initialHeaderState.showHeader)
  const [showFeedback, setShowFeedback] = useState<boolean>(initialHeaderState.showHeader)
  const [isHeaderAbsolute, setIsHeaderAbsolute] = useState<boolean>(initialHeaderState.isHeaderAbsolute)

  return <HeaderContext.Provider value={{ isHeaderAbsolute, setIsHeaderAbsolute, showHeader, setShowHeader, setShowFeedback, showFeedback }}>{children}</HeaderContext.Provider>
}
