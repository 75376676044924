export const dataPieIntroduction = [
  {
    label: 'COMMIT',
    color: '#ff3399',
    days: [
      {
        day: 0,
        value: 0,
      },
      {
        day: 1,
        value: 0,
      },
      {
        day: 2,
        value: 0,
      },
      {
        day: 3,
        value: null,
      },
      {
        day: 4,
        value: null,
      },
      {
        day: 5,
        value: null,
      },
      {
        day: 6,
        value: null,
      },
    ],
  },
  {
    label: 'MOVE',
    color: '#ff567a',
    days: [
      {
        day: 0,
        value: 0,
      },
      {
        day: 1,
        value: 0,
      },
      {
        day: 2,
        value: 0,
      },
      {
        day: 3,
        value: null,
      },
      {
        day: 4,
        value: null,
      },
      {
        day: 5,
        value: null,
      },
      {
        day: 6,
        value: null,
      },
    ],
  },
  {
    label: 'NOURISH',
    color: '#f26e57',
    days: [
      {
        day: 0,
        value: 0,
      },
      {
        day: 1,
        value: 0,
      },
      {
        day: 2,
        value: 0,
      },
      {
        day: 3,
        value: null,
      },
      {
        day: 4,
        value: null,
      },
      {
        day: 5,
        value: null,
      },
      {
        day: 6,
        value: null,
      },
    ],
  },
  {
    label: 'REFRESH',
    color: '#b7005e',
    days: [
      {
        day: 0,
        value: 0,
      },
      {
        day: 1,
        value: 0,
      },
      {
        day: 2,
        value: 0,
      },
      {
        day: 3,
        value: null,
      },
      {
        day: 4,
        value: null,
      },
      {
        day: 5,
        value: null,
      },
      {
        day: 6,
        value: null,
      },
    ],
  },
  {
    label: 'RELATE',
    color: '#974a9a',
    days: [
      {
        day: 0,
        value: 0,
      },
      {
        day: 1,
        value: 0,
      },
      {
        day: 2,
        value: 0,
      },
      {
        day: 3,
        value: null,
      },
      {
        day: 4,
        value: null,
      },
      {
        day: 5,
        value: null,
      },
      {
        day: 6,
        value: null,
      },
    ],
  },
]

export const dataRateHeartsIntroduction = [
  {
    label: 'commit',
    data: [0, 0, 0, 0, 0, 0, 0],
    color: '#ff3399',
    subscribed: ['BLOOD PRESSURE', 'SMOKING', 'DRINKING'],
  },
  {
    label: 'move',
    data: [0, 0, 0, 0, 0, 0, 0],
    color: '#ff567a',
    subscribed: ['STEPS'],
  },
  {
    label: 'nourish',
    data: [0, 0, 0, 0, 0, 0, 0],
    color: '#f26e57',
    subscribed: ['WEIGHT'],
  },
  {
    label: 'refresh',
    data: [0, 0, 0, 0, 0, 0, 0],
    color: '#b7005e',
    subscribed: [],
  },
  {
    label: 'relate',
    data: [0, 0, 0, 0, 0, 0, 0],
    color: '#974a9a',
    subscribed: [],
  },
]

export const dataBloodPressureIntroduction: any = [
  {
    name: 'Systolic Pressure',
    values: [null, 111, null, null, null, null, null],
    color: '#FF3399',
  },
  {
    name: 'Diastolic Pressure',
    values: [null, 111, null, null, null, null, null],
    color: '#974A9A',
  },
  {
    name: 'Pulse Pressure',
    values: [null, 111, null, null, null, null, null],
    color: '#F26E57',
  },
]

export const dataBarStepsIntroduction = [
  {
    day: 'Sun',
    value: 100,
  },
  {
    day: 'Mon',
    value: 80,
  },
  {
    day: 'Tue',
    value: 80,
  },
  {
    day: 'Wed',
    value: 50,
  },
  {
    day: 'Thu',
    value: 100,
  },
  {
    day: 'Fri',
    value: 120,
  },
  {
    day: 'Sat',
    value: 150,
  },
]

export const dataBarSmokingIntroduction = [
  {
    day: 'Sun',
    value: 0,
  },
  {
    day: 'Mon',
    value: 0,
  },
  {
    day: 'Tue',
    value: 0,
  },
  {
    day: 'Wed',
    value: 0,
  },
  {
    day: 'Thu',
    value: 0,
  },
  {
    day: 'Fri',
    value: 0,
  },
  {
    day: 'Sat',
    value: 0,
  },
]

export const dataBarDrinkingIntroduction = [
  {
    day: 'Sun',
    value: 0,
  },
  {
    day: 'Mon',
    value: 0,
  },
  {
    day: 'Tue',
    value: 0,
  },
  {
    day: 'Wed',
    value: 0,
  },
  {
    day: 'Thu',
    value: 0,
  },
  {
    day: 'Fri',
    value: 0,
  },
  {
    day: 'Sat',
    value: 0,
  },
]

export const dataChartBeatsIntroduction = [
  {
    label: 'COMMIT',
    beats: 177,
    totalBeats: 500,
    color: '#ff3399',
  },
  {
    label: 'MOVE',
    beats: 9,
    totalBeats: 400,
    color: '#ff567a',
  },
  {
    label: 'NOURISH',
    beats: 11,
    totalBeats: 400,
    color: '#f26e57',
  },
  {
    label: 'RELATE',
    beats: 5,
    totalBeats: 400,
    color: '#974a9a',
  },
  {
    label: 'REFRESH',
    beats: 6,
    totalBeats: 400,
    color: '#b7005e',
  },
]

export const dataMyBeatsIntroduction = {
  img: '/images/icons/i_heart_2.png',
  des: 'Heart Alive 1: 203 beats',
  score: 203,
  bgColor: '#EFD4F3',
  leftPoint: 97,
  nextLevel: 'Heart Alive 2',
}
