import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MyAdessoHeartChartStep } from '../../../../constants/introduction'
import { useHeaderContext } from '../../../../hooks/modals/global-set-header'
import { useLocales } from '../../../../locales'
import { ButtonCommon, ButtonStyles } from '../../../Common'
import HeartChartMain from '../../../HeartChart/HeartChartMain'
import BeastProgressContent from '../../../HeartChart/HeartChartMain/BeastProgressContent'
import { ArrowType, BoxText } from '../../../Introduction/BoxText'

interface Props {
  nextScreen: () => void
  closeScreen: () => void
}

const MyAdessoHeartChartIntroductionScreen = ({ nextScreen, closeScreen }: Props) => {
  const contentRef: MutableRefObject<any> = useRef()
  const { t } = useLocales()
  const [step, setStep] = useState<MyAdessoHeartChartStep>(MyAdessoHeartChartStep.WelcomeTour)
  const boxRef = useRef<HTMLDivElement>(null)
  const refs = Object.values(MyAdessoHeartChartStep).reduce((acc: any, step, index) => {
    if (!isNaN(Number(step))) {
      acc[step] = useRef<HTMLElement>(null)
    }

    return acc
  }, {})

  const [currentRef, setCurrentRef] = useState<any>(refs[MyAdessoHeartChartStep.WelcomeTour])

  const positionStep = {
    WelcomeTour: 'top',
    Chart: '',
    MyProgressPage: '',
    // YourHeart: '',
    // Alerts: '',
    HeartDimensionsTracking: '',
  }
  const { setShowFeedback } = useHeaderContext()

  const handleNextStep = useCallback((stepCurrent: MyAdessoHeartChartStep) => {
    setStep(stepCurrent + 1)
  }, [])

  useEffect(() => {
    setShowFeedback(false)
  }, [])

  useEffect(() => {
    if (step === MyAdessoHeartChartStep.WelcomeTour) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        boxRef.current.style.top = '250px'
      }
      return
    }

    if (!currentRef?.current) {
      // Center vertical popup
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
      if (boxRef?.current?.style) {
        const boxTop = Math.max(0, window.innerHeight / 2 - (boxRef.current.children[0]?.clientHeight + 51) / 2) - 80
        boxRef.current.style.top = boxTop + 'px'
      }
      return
    }

    currentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })

    const scrollHeight = currentRef.current.scrollHeight
    const scrollY = window.scrollY
    const { bottom } = currentRef?.current.getBoundingClientRect()

    if (boxRef?.current) {
      switch (step) {
        case MyAdessoHeartChartStep.Chart:
          window.scrollTo({
            behavior: 'smooth',
            top: 50,
          })
          boxRef.current.style.top = Math.round(bottom - 80) + 'px'
          break
        case MyAdessoHeartChartStep.MyProgressPage:
        // case MyAdessoHeartChartStep.YourHeart:
        //   currentRef.current.style.marginTop = '100px'
        //   boxRef.current.style.top = Math.round(bottom + scrollY + 40) + 'px'
        //   break
        // case MyAdessoHeartChartStep.Alerts:
        //   currentRef.current.style.marginTop = '100px'
        //   boxRef.current.style.top = Math.round(bottom + scrollY) + 'px'
        case MyAdessoHeartChartStep.HeartDimensionsTracking:
          currentRef.current.style.marginTop = '100px'
          boxRef.current.style.top = Math.round(bottom + scrollY + 60) + 'px'
          break

        default:
          boxRef.current.style.top = Math.round(bottom + scrollY - 80) + 'px'
          break
      }
    }
  }, [step])

  const handleButtonNext = () => {
    handleNextStep(step)
    const stepCurrent = step
    const nextStep = stepCurrent + 1

    if (stepCurrent === MyAdessoHeartChartStep.HeartDimensionsTracking) {
      nextScreen()
    }

    setCurrentRef(refs[nextStep])
  }

  const contentMyAdessoMyHealthStep = useMemo(() => {
    switch (step) {
      case MyAdessoHeartChartStep.WelcomeTour:
        return <BoxText className={`${positionStep.WelcomeTour}`} title={t('introduction.myAdessoHeartChart.title.welcomeTour')} text={t('introduction.myAdessoHeartChart.text.welcomeTour')} />
      case MyAdessoHeartChartStep.Chart:
        return <BoxText arrow={ArrowType.Center} className={`${positionStep.Chart}`} text={t('introduction.myAdessoHeartChart.text.chart')} />
      case MyAdessoHeartChartStep.MyProgressPage:
        return (
          <BoxText className={`${positionStep.MyProgressPage}`} title={t('introduction.myAdessoHeartChart.title.myProgressPage')} text={t('introduction.myAdessoHeartChart.text.myProgressPage')} />
        )
      // case MyAdessoHeartChartStep.YourHeart:
      //   return (
      //     <BoxText
      //       arrow={ArrowType.Center}
      //       className={`${positionStep.YourHeart}`}
      //       title={t('introduction.myAdessoHeartChart.title.yourHeart')}
      //       text={t('introduction.myAdessoHeartChart.text.yourHeart')}
      //       download={{ name: `${t('introduction.myAdessoHeartChart.link.yourHeart')}`, link: 'https://apps.apple.com/us/app/myadesso/id6497954262' }}
      //     />
      //   )
      // case MyAdessoHeartChartStep.Alerts:
      //   return (
      //     <BoxText arrow={ArrowType.Center} className={`${positionStep.Alerts}`} title={t('introduction.myAdessoHeartChart.title.alerts')} text={t('introduction.myAdessoHeartChart.text.alerts')} />
      //   )
      case MyAdessoHeartChartStep.HeartDimensionsTracking:
        return (
          <BoxText
            arrow={ArrowType.Center}
            className={`${positionStep.HeartDimensionsTracking}`}
            title={t('introduction.myAdessoHeartChart.title.heartDimensionsTracking')}
            text={t('introduction.myAdessoHeartChart.text.heartDimensionsTracking')}
          />
        )

      default:
        return <></>
    }
  }, [step])

  const buttonNext = useMemo(() => {
    const marginBottom = step === MyAdessoHeartChartStep.WelcomeTour ? 'scroll-mb-0' : 'scroll-mb-80'
    return (
      <ButtonCommon
        contentRef={contentRef}
        style={ButtonStyles.Introduction}
        scrollMarginBottom={marginBottom}
        width="w-[167px]"
        height="h-[51px]"
        text={t('introduction.gotItNext')}
        onClick={() => handleButtonNext()}
      />
    )
  }, [step, handleNextStep])

  const buttonClose = useMemo(() => {
    return (
      <button onClick={closeScreen} className="fixed right-5 top-7 z-50 flex cursor-pointer  items-center justify-center bg-transparent text-white">
        <p className="pr-2 font-['Proxima_Nova'] text-[14px] font-[600] leading-[17px] tracking-[0.15px]">CLOSE DEMO</p>
        <div className=" h-[29px] w-[30px] bg-[url('assets/images/icon/i-button-close.svg')] bg-no-repeat" />
      </button>
    )
  }, [step])

  const introMemo = useMemo(() => {
    switch (step) {
      case MyAdessoHeartChartStep.WelcomeTour:
      case MyAdessoHeartChartStep.Chart:
      case MyAdessoHeartChartStep.MyProgressPage:
      // case MyAdessoHeartChartStep.YourHeart:
      // case MyAdessoHeartChartStep.Alerts:
      case MyAdessoHeartChartStep.HeartDimensionsTracking:
        return (
          <div>
            <div className={step !== MyAdessoHeartChartStep.MyProgressPage ? 'block' : 'hidden'}>
              <HeartChartMain refs={refs} isIntroduction />
            </div>
            <div className={step === MyAdessoHeartChartStep.MyProgressPage ? 'block' : 'hidden'}>
              <BeastProgressContent isIntroduction />
            </div>
          </div>
        )
    }
  }, [step, currentRef])

  return (
    <div className="relative h-full w-full pb-40">
      {introMemo}

      <div className="fixed bottom-0 left-0 right-0 top-0 z-[49] bg-color-overlay bg-opacity-50"></div>
      {buttonClose}
      <div ref={boxRef} className="absolute left-0 top-[-74px] z-50 flex h-[calc(100%+74px)] w-full flex-col items-center space-y-4 px-4 font-['Proxima_Nova']">
        {contentMyAdessoMyHealthStep}
        {buttonNext}
      </div>
    </div>
  )
}

export default MyAdessoHeartChartIntroductionScreen
