import { Session03Wrapper } from '../Wrapper'

type Props = {
  buttons?: React.ReactNode
}

export const ScreenHeartAssessment01 = ({ buttons }: Props) => {
  return (
    <Session03Wrapper buttons={buttons}>
      <div className="space-y-6">
        <h4 className="text-[18px] font-bold leading-[23px]">Nourishment…Now!</h4>
        <p>
          {`If we know one thing about diets by now it’s that they don’t work! Every year an estimated 45 million Americans go on a diet each year, and Americans spend $33 billion each year on weight loss products, according to Boston Medical Center. And yet, ⅔ of Americans are either clinically obese or overweight! We're spending more and more to lose weight, but gaining more and more, while exposing our hearts to more and more risk.`}
        </p>
        <p>Time to stop the lunacy! ADESSO!</p>
        <p>
          {`We’re done talking about diets. Here we’re going to learn how to nourish your heart, life and self with an active, present, daily approach to food. We call this a “mindful” approach. Instead of mindlessly grabbing a bag of potato chips, we want to start thinking about our nourishment as a core foundation for self-care. `}
        </p>
        <p>Because ladies, as you know… heart-care is the best form of self-care. </p>
        <p>So let’s dive in, together. Adesso. We have no time to waste.</p>
      </div>
    </Session03Wrapper>
  )
}
