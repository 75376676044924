import React, { useMemo, useState } from 'react'
import HeartScoreLatest from './HeartScoreLatest'
import ListHeartScore from './ListHeartScore'
import { ResponsiveWrapper } from '../../Common/ResponsiveWrapper'

type Props = {
  prevScreen: () => void
}

const HeartScore = ({ prevScreen }: Props) => {
  const [screen, setScreen] = useState(0)

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = () => {
    setScreen((prev) => prev + 1)
  }

  const heartScore = useMemo(() => {
    switch (screen) {
      case 0:
        return <HeartScoreLatest prevScreen={() => prevScreen()} nextScreen={handleNextScreen} />
      case 1:
        return <ListHeartScore prevScreen={handlePrevScreen} />
    }
  }, [screen, prevScreen])

  return <ResponsiveWrapper>{heartScore}</ResponsiveWrapper>
}

export default HeartScore
