import React, {useMemo, ChangeEvent} from 'react'
import {Question, FieldTypes} from '../../../models'

type Props = {
  value?: any
  disabled: boolean
  backgroundColor?: string
  textColor?: string
  fieldType: FieldTypes
  question: Question
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

export const TableQuestion = ({value, disabled, question, onChange}: Props) => {

  const headerRow = useMemo(() => {
    return (
      <thead>
      <tr className="border-2 border-gray-300">
        {question.fields && question.fields.map((field, index) => {
          return (
            <th className="p-4 border-2 border-gray-300 text-left" key={field.key}>{field.label}</th>
          )
        })
        }
      </tr>
      </thead>
    )
  }, [question, value])

  const bodyRows = useMemo(() => {
      return (
        <tbody>
        <tr>
          {(question.fields) && (question.fields.map((field, index) => {
            return (
              <td key={field.key + '' + index} className="p-0 border border-gray-300 text-left">
                {Array.from(Array(question.tableOptions?.row || 0).keys()).map((item, index, array) => {
                  let key = field.key + '-' + index
                  let thisValue = ''
                  if (typeof value === 'object' && value !== null) {
                    thisValue = value[key]
                  }
                  return (
                    <div key={key} className="border-[0.1px] border-gray-300">
                      <input type={field.type}
                             value={thisValue}
                             name={`${question.name}[${key}]`}
                             disabled={disabled}
                             onChange={onChange}
                             className="input text-ma-md h-[46px] w-full rounded-none border-none border-transparent disabled:bg-transparent placeholder:text-ma-gray-900/50 focus:border-transparent focus:outline-0"
                      />
                    </div>
                  )
                })
                }
              </td>
            )
          }))}
        </tr>
        </tbody>
      )
    },
    [question, value]
  )
  return <div>
    <table className="border border-gray-300 w-full">
      {headerRow}
      {bodyRows}
    </table>
  </div>
}
