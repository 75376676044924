import React from 'react'
import LifeStyleRx from '../../components/MyHealth/LifeStyle'
import { useHandlePreviousUrl } from '.'

type Props = {}

const LifeStyleRxPage = (props: Props) => {
  const handlePreviousUrl = useHandlePreviousUrl()

  return <LifeStyleRx prevScreen={handlePreviousUrl} />
}

export default LifeStyleRxPage
