import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Results from './Results'
import CreateMyLifeStyle from './CreateMyLifeStyle'
import { FormValues, PramsLifeStyle } from '../../../models/my-heart'
import { useLifeStylePage } from '../../../hooks/pages/lifeStyle'
import { useGoDeeperLibraryPage } from '../../../hooks/pages/go-deeper'
import { ResponsiveWrapper } from '../../Common/ResponsiveWrapper'

type Props = {
  prevScreen?: () => void
  // nextScreen?: () => void
}

type PropsOption = {
  id: number
  recommendation_type: number
  title: string
}

const LifeStyleRx = ({ prevScreen }: Props) => {
  const { postDataResult, submitLifeStyle, getLifeStyleRx, getOptionsOfLifeStyleRx, dataOptionsLifeStyle, stateCreate, editEnabled } = useLifeStylePage()
  const { dataDeepers, getDataDeepers } = useGoDeeperLibraryPage()

  const [screen, setScreen] = useState(0)
  const [edit, setEdit] = useState(false)

  const listOptions = dataOptionsLifeStyle?.data
  const dataResult = postDataResult?.data || {}
  const deepers = dataDeepers?.data?.deepers || []

  useEffect(() => {
    const fetchLifeStyleContent = async () => {
      try {
        await getOptionsOfLifeStyleRx().catch()
        await getDataDeepers().catch()
      } catch (error) {}
    }
    fetchLifeStyleContent()
  }, [])

  useEffect(() => {
    const fetchLifeStyleResult = async () => {
      try {
        await getLifeStyleRx().catch()
      } catch (error) {}
    }
    fetchLifeStyleResult()
  }, [])

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = (edit?: boolean) => {
    if (edit) {
      setEdit(true)
    } else {
      setEdit(false)
    }
    setScreen((prev) => prev + 1)
  }

  const handleSubmit = useCallback(
    (params: FormValues, toggle?: boolean): void => {
      const optionIds = dataOptionsLifeStyle?.data?.reduce<number[]>((acc, item: PropsOption) => {
        if (params[item.title] === true) {
          acc.push(item.id)
        }
        return acc
      }, [])
      const statusApprovedByPhysician: boolean = params['approvedByPhysician'] || false

      const newParams: PramsLifeStyle = {
        option_id: optionIds as number[],
        approved_by_physician: statusApprovedByPhysician,
      }

      if (toggle) {
        submitLifeStyle(newParams, params, toggle)
      } else {
        submitLifeStyle(newParams, params)
        handlePrevScreen()
      }
    },
    [dataOptionsLifeStyle]
  )

  const LifeStyleRx = useMemo(() => {
    switch (screen) {
      case 0:
        return (
          <Results
            stateCreate={stateCreate}
            deepers={deepers}
            postDataResult={dataResult}
            prevScreen={prevScreen}
            nextScreen={handleNextScreen}
            isLoadingDeepers={dataDeepers?.isLoading === true}
            isLoading={postDataResult?.isLoading === true && editEnabled}
            isLoadingToggle={!editEnabled}
            submit={handleSubmit}
          />
        )
      case 1:
        return (
          <CreateMyLifeStyle
            edit={edit}
            postDataResult={dataResult}
            prevScreen={handlePrevScreen}
            submit={handleSubmit}
            dataOptions={listOptions}
            isLoading={dataOptionsLifeStyle?.isLoading === true}
          />
        )
    }
  }, [screen, postDataResult, dataOptionsLifeStyle, dataDeepers, stateCreate, editEnabled])

  return <ResponsiveWrapper>{LifeStyleRx}</ResponsiveWrapper>
}

export default LifeStyleRx
