import { useMemo } from 'react'
import { SubmitButtonCommon } from '../../../Common'
import { ButtonStyles } from '../../../Common/Button'
import { SessionWrapper } from './SessionWrapper'
import { useHeartJournalPage } from '../../../../hooks/pages/heart-journal'

type Props = {
  slogan: string
  takeActions?: string[]
  goHeartAction: () => void
  isLoading?: boolean
  skipHeartAction: () => void
  takeActionHint?: string
}

const dummyTakeActions = ['Journal and track your new nourishment habits', 'Rate your nourishment habits']

export const HeartAction01 = ({ slogan, takeActions, goHeartAction, isLoading, skipHeartAction, takeActionHint }: Props) => {
  const layoutHeartAction = useMemo(() => {
    const dataTakeActions = takeActions || dummyTakeActions
    return (
      <SessionWrapper
        isLogoWhite={false}
        hiddenHeader
        bgExpand={true}
        className={'bg-[center_top_72px] text-white'}
        style={{ backgroundImage: `url(/images/my-adresso-journey/session/heart-action__bg.webp)` }}
      >
        <div className="space-y-6 pt-16 pb-10 text-[18px] leading-[20px]">
          <h4 className="text-[20px] font-semibold leading-[24px]">Was this session helpful? If so, add your goal:</h4>
          <h4 className="text-center text-[20px] font-bold uppercase leading-[24px]">ADD GOAL</h4>
          <p className="text-center text-[18px] font-semibold capitalize leading-[18px]">{slogan}</p>

          <div className="flex w-full justify-center">
            <SubmitButtonCommon isLoading={isLoading} style={ButtonStyles.Light} width="w-[203px]" height="h-[50px]" text="YES!" onClick={goHeartAction} />
          </div>

          <h4 className="text-[20px] font-semibold leading-[24px]">Take Action:</h4>
          {takeActionHint ? (
            <p className="text-[18px] font-normal leading-5">{takeActionHint}</p>
          ) : (
            <p className="text-[18px] font-normal leading-5">Once you add the goal above, you will enroll in the following actions and track them on your dashboard every day:</p>
          )}
          <ul className="list-disc space-y-5 pl-8">
            {dataTakeActions.map((text, index) => (
              <li key={index} className="text-[18px] font-normal leading-5">
                {text}
              </li>
            ))}
          </ul>
          <h4 className="text-center text-[18px] font-semibold leading-[24px]">
            This goal isn't right for you? No problem! <br />
            Click to return to your dashboard and continue your heartcare journey.
          </h4>
          <div className="flex w-full justify-center">
            <SubmitButtonCommon isLoading={isLoading} style={ButtonStyles.Light} width="w-[203px]" height="h-[50px]" text="FINISH" onClick={skipHeartAction} />
          </div>
        </div>
      </SessionWrapper>
    )
  }, [slogan, takeActions, goHeartAction, isLoading])

  return <>{layoutHeartAction}</>
}
