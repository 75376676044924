import React, { useEffect, useMemo, useState } from 'react'
import WrapperMyHealth from '../MyHealthWrapper'
import { ButtonMyHealth } from '../Button'
import { RiskLegend } from './RiskLegend'

type Props = {
  prevScreen?: () => void
  nextScreen1?: () => void
  nextScreen2?: () => void
  answers: any[]
  date: any
  refs?: React.Ref<any>[]
}

export function addUnitToAnswers(answers: any[]): any[] {
  return answers.map((item) => {
    let unit: string | undefined = 'mg/dL'
    const name = item.title ?? item.name

    if (name === 'Blood Pressure') {
      unit = undefined
    } else if (name === 'Hemoglobin A1C') {
      unit = '%'
    }
    return { ...item, unit }
  })
}

const Results = ({ prevScreen, nextScreen1, nextScreen2, answers, date, refs = [] }: Props) => {
  const dataAnswers = addUnitToAnswers(answers)

  const buttonAdd = useMemo(() => {
    return <ButtonMyHealth refs={refs} nameButton="ADD NEW TEST RESULTS" bgButton="bg-color-bg-primary " nextScreen={nextScreen1} />
  }, [])

  const buttonSee = useMemo(() => {
    return <ButtonMyHealth refs={refs} nameButton="SEE PAST TEST RESULTS" bgButton="bg-[#974A9A] " nextScreen={nextScreen2} />
  }, [])

  const contentAnswers = useMemo(() => {
    if (dataAnswers?.length > 0) {
      return (
        <div className="w-full">
          <div className="font-[Proxima Nova] text-[15px] font-[600] leading-[21px]">Test Date: {date || ''}</div>
          <div className=" py-3 text-[12px] font-semibold leading-[22px] text-color-overlay">LAB NUMBERS</div>

          {dataAnswers.map((detail: any, index) => {
            return (
              <div key={`resultDetail-${index}`} className="flex items-center border-t border-color-gray py-3">
                <div className="flex-1 grow text-[12px] font-semibold leading-[22px] text-color-overlay">{detail.title ?? detail.name}</div>
                <div className={`text-color-${detail?.result?.toLowerCase() || 'low'}-risk w-20 grow text-right text-[12px] font-semibold leading-[15px]`}>
                  {detail.answer || ''}
                  <span className="ml-1"></span>
                  {detail.answer ? detail.unit : ''}
                </div>
              </div>
            )
          })}
          <RiskLegend />
        </div>
      )
    } else {
      return <h6>You don’t have any test results yet. Please enter them manually by pressing the button below.</h6>
    }
  }, [answers, date])

  return (
    <WrapperMyHealth title="labTestResults" prevScreen={prevScreen} button={buttonAdd}>
      <div className="flex flex-col items-center justify-center gap-4">{contentAnswers}</div>
      {dataAnswers?.length > 0 ? <div className=" flex flex-col items-center justify-center gap-4">{buttonSee}</div> : ''}
    </WrapperMyHealth>
  )
}
export default Results
