import React, { useEffect, useState } from 'react'
import { HeartChartWrapper } from './Wrap'
import { ChartBeastProgress, dataMyBeats } from './ChartBeastProgress'
import { useLocales } from '../../../locales'
import { useHeartChartBeatsPage } from '../../../hooks/pages/heart-chart-beats'
import { IconLoading } from '../../Common'
import { getMyBeat } from '../../../lib/request'
import { dataChartBeatsIntroduction, dataMyBeatsIntroduction } from '../../../constants/dataIntroductionHeartChart'

type Props = {
  isIntroduction?: boolean
}

export default function BeastProgressContent({ isIntroduction }: Props) {
  const { t } = useLocales()
  const { dataChartBeats, getDataHeartChartBeats } = useHeartChartBeatsPage()
  const [loading, setLoading] = useState(false)
  const [dataMyBeats, setDataMyBeats] = useState<dataMyBeats>()

  useEffect(() => {
    if (isIntroduction) return
    getDataHeartChartBeats()
  }, [])

  useEffect(() => {
    if (isIntroduction) return
    const getDataMyBeats = async () => {
      setLoading(true)
      const data = await getMyBeat()
      setDataMyBeats(data?.data)
      setLoading(false)
    }
    getDataMyBeats()
  }, [])
  if (isIntroduction ? false : dataChartBeats?.isLoading || loading) {
    return (
      <div className="flex justify-center">
        <IconLoading />
      </div>
    )
  }

  return (
    <HeartChartWrapper>
      <p className='my-2 font-["Butler"] text-[24px] font-[400] leading-[34px]'>{t('heartChartMain.beatsProgress.title')}</p>
      <ChartBeastProgress size={370} data={isIntroduction ? dataChartBeatsIntroduction : dataChartBeats?.data || []} dataMyBeats={isIntroduction ? dataMyBeatsIntroduction : dataMyBeats} />
    </HeartChartWrapper>
  )
}
