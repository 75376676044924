import React from 'react'
import MyHealthContent from '../../components/MyHealth'

type Props = {}

const MyHealthPage = (props: Props) => {
  return <MyHealthContent />
}

export default MyHealthPage
