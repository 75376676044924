import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useForm } from '../../hooks/use-form'
import { useGlobalModalContext, MODAL_TYPES } from '../../hooks/modals/global-modal'
import { useLocales } from '../../locales'
import { useLocalStorage } from '../../hooks/use-local-storage'

import { Wrapper, IconLoading, FormField, ErrorMessage, SubmitButtonCommon, ButtonStyles, ButtonCommon, BeatPopup } from '../Common'

import { mapQuestion } from '../../lib/maps/question'
import { QuestionTypes } from '../../models/question'
import { IUser } from '../../models/user'
import { DeeperArticle } from '../../models/deepers'
import { postFinishGoDeeper } from '../../lib/request'

type Props = {
  isLoading: boolean
  deeper?: DeeperArticle
}

const dummyFormCommit = [
  mapQuestion('gd_001', QuestionTypes.CheckboxMulti, undefined, undefined, [
    'Start by first tracking your habits. You’ll learn that you may be consuming more than you think.',
    'Trying to reduce down to a drink a night and no more than 2 drinks on a weekend night. Focus on red wine, if possible.',
    'If it’s impossible to keep it under control, abstinence is more healthy than unhealthy drinking.',
  ]),
]

export const GoDeeperSlugContent = ({ isLoading, deeper }: Props) => {
  const navigate = useNavigate()

  const { getItemLS, setItemLS } = useLocalStorage()
  const { t } = useLocales()
  const { showGlobalModal } = useGlobalModalContext()
  const { initUseFormik } = useForm()

  const user = getItemLS('user') as IUser
  const idDeeper = deeper?.id

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const idNotExists = !user?.goal_deeper?.some((item: any) => item.id === idDeeper)

  const finishGoDeeper = async () => {
    setLoadingBtn(true)
    const res = await postFinishGoDeeper({ id: deeper?.id || 0 })
    setLoadingBtn(false)

    if (res.data?.status == 'success') {
      const updatedUser = { ...user, goal_deeper: [...user.goal_deeper, { id: idDeeper }] }
      setItemLS('user', updatedUser)
      showLevelUp(res.data)
    } else {
      navigate('/dashboard')
    }
  }

  const showLevelUp = (levelUp: any) => {
    if (levelUp !== null) {
      showGlobalModal(MODAL_TYPES.ADD_BEAT, levelUp)
    } else {
      navigate('/dashboard')
    }
  }

  const questions = dummyFormCommit
  const formik = initUseFormik(dummyFormCommit, () => {})

  const handleSubmitForm = useCallback(() => {
    setIsError(true)
  }, [])

  const formCommit = useMemo(() => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="flex-1 space-y-4">
          {questions.map((item, index) => {
            if (item.parent) {
              const parentValue = item.parent.value
              const formikParentValue = formik.values[item.parent.parentName]

              if (typeof formikParentValue === 'object') {
                // @ts-ignore
                if (!formikParentValue.includes(parentValue)) return undefined
              } else {
                if (formikParentValue !== parentValue) return undefined
              }
            }

            return (
              <FormField key={`${item.name}-${index}`} question={item} value={formik.values[item.name]} onChange={formik.handleChange}>
                {isError && formik.errors[item.name] && <ErrorMessage text={formik.errors[item.name]} />}
              </FormField>
            )
          })}

          {isError && errorMsg && <ErrorMessage text={errorMsg} />}

          {/* <SubmitButtonCommon style={ButtonStyles.Dark} width="w-[132px]" height="h-[50px]" text="I COMMIT!" onClick={() => handleSubmitForm()} /> */}
        </div>
      </form>
    )
  }, [formik, isError, errorMsg, isLoading, handleSubmitForm])

  useEffect(() => {
    if (deeper) {
      const links = document.querySelectorAll('.adesso-p a')
      links.forEach((link) => {
        const htmlLink = link as HTMLElement
        htmlLink.style.whiteSpace = 'nowrap'
        htmlLink.style.overflow = 'hidden'
        htmlLink.style.textOverflow = 'ellipsis'
        htmlLink.style.display = 'block'
        htmlLink.style.width = '100%'
      })
    }
  }, [deeper])

  const goDeeperSlugContent = useMemo(() => {
    if (!deeper)
      return (
        <div className="no-css-section flex justify-center">
          <IconLoading />
        </div>
      )

    return (
      <div className="no-css-section deeper-content mx-auto w-full max-w-[687px] space-y-5">
        <div className="mt-8 md:mt-10">
          <img className="block h-auto w-full" src={deeper.banner} alt="" />
          <div className="space-y-5" dangerouslySetInnerHTML={{ __html: deeper.content }} />
          {idNotExists && (
            <SubmitButtonCommon
              isLoading={loadingBtn}
              style={ButtonStyles.Dark}
              className="p-2 normal-case"
              width="w-[132px]"
              height="h-[50px]"
              text={t('goDeeperDetail.button')}
              onClick={finishGoDeeper}
            />
          )}
        </div>
      </div>
    )
  }, [deeper, loadingBtn, idNotExists])

  return (
    <Wrapper className="">
      {/* min-h-screen bg-[url('/images/go-deeper/demo_heart.png')] bg-[length:100%_155px] bg-[center_top] bg-no-repeat pt-0 */}
      {/* <div className="absolute top-0 left-0 right-0 z-10 hidden h-[156px] bg-slate-300 bg-cover bg-[center_top] bg-no-repeat md:block  md:bg-[url('/images/go-deeper/godeeper-pc.png')]"></div> */}

      {/* <h2 className="relative z-20 h-[155px] pt-[78px] font-butler text-[24px] leading-[34px] text-white md:text-center">{t('mainMenuAndSection.goDeeper')}</h2> */}

      {goDeeperSlugContent}
    </Wrapper>
  )
}
