import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Question } from '../../../models/question'
import { ErrorMessage } from '../ErrorMessage'

function renderOption(type: 'feet' | 'inches' | 'pounds', feetValue?: string) {
  let options: JSX.Element[] | undefined = undefined

  if (type === 'feet') {
    options = [4, 5, 6].map((item) => (
      <option key={`feet-${item}`} value={item}>
        {`${item}'`}
      </option>
    ))
  }

  if (type === 'inches') {
    options = [...Array(+`${feetValue}` === 6 ? 7 : 12).keys()].map((item) => (
      <option key={`inches-${item}`} value={item}>
        {`${item}"`}
      </option>
    ))
  }

  if (type === 'pounds') {
    options = [...Array(201).keys()].map((item) => (
      <option key={`pounds-${item}`} value={item + 90}>
        {`${item + 90} lbs`}
      </option>
    ))
  }

  return (
    <>
      <option value="">{type}</option>
      {options}
    </>
  )
}

type Props = {
  values?: any
  children?: React.ReactNode
  question: Question
  onChangeValue: (value: object) => void
  errors?: any
  stateSubmit?: boolean
}

type BmiType = {
  feet: string
  inches: string
  pounds: string
  bmi: string
}

const defaultValues = { feet: '', inches: '', pounds: '', bmi: '' }

export const BmiField = ({ values, question, children, onChangeValue, errors, stateSubmit }: Props) => {
  const isErrorsFeet = errors?.feet ?? 0
  const isErrorsInches = errors?.inches ?? 0
  const isErrorsPound = errors?.pounds ?? 0

  const updateValue = (data: BmiType) => {
    let bmiTmp = data.bmi
    if (data.pounds && (data.feet || data.inches)) {
      const height = (+data.feet || 0) * 12 + (+data.inches || 0)
      bmiTmp = `${((703 * +data.pounds) / (+height * +height)).toFixed(2)}`
    }

    onChangeValue({ ...data, bmi: bmiTmp })
  }

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target

      if (!isNaN(+value)) {
        updateValue({ ...values, [name]: value })
      }
    },
    [values]
  )

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (!isNaN(+value)) {
      updateValue({ ...defaultValues, bmi: value })
    }
  }, [])
  const bmiField = useMemo(() => {
    return (
      <div className="space-y-7">
        <div className="space-y-4">
          <h4 className="text-ma-md font-semibold text-ma-pink-300">What is your height?</h4>
          <div className="flex flex-col space-y-2">
            <div className="flex justify-between">
              <div className=" w-[45%]">
                <select
                  name="feet"
                  id="feet"
                  value={values.feet || ''}
                  className="mb-4 h-[46px] w-full appearance-none border-transparent bg-ma-pink-100 px-3 focus:border-transparent focus:outline-0"
                  onChange={handleChangeInput}
                >
                  {renderOption('feet')}
                </select>
                {stateSubmit && !!isErrorsFeet && <ErrorMessage text={errors['feet']} />}
              </div>
              <div className="w-[45%]">
                <select
                  name="inches"
                  id="inches"
                  value={values.inches || ''}
                  className="mb-4 h-[46px] w-full appearance-none border-transparent bg-ma-pink-100 px-3 focus:border-transparent focus:outline-0"
                  onChange={handleChangeInput}
                >
                  {renderOption('inches', values.feet || '')}
                </select>
                {stateSubmit && !!isErrorsInches && <ErrorMessage text={errors['inches']} />}
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <h4 className="text-ma-md font-semibold text-ma-pink-300">What is your weight?</h4>

          <select
            name="pounds"
            id="pounds"
            value={values.pounds || ''}
            className="h-[46px] w-[45%] appearance-none border-transparent bg-ma-pink-100 px-3 focus:border-transparent focus:outline-0"
            onChange={handleChangeInput}
          >
            {renderOption('pounds')}
          </select>
        </div>
        {stateSubmit && !!isErrorsPound && <ErrorMessage text={errors['pounds']} />}

        <div className="space-y-4">
          <h4 className="text-ma-md font-semibold text-ma-pink-300">Your BMI is:</h4>
          <input
            type="text"
            id="bmi"
            name="bmi"
            value={values.bmi || ''}
            className="h-[46px] w-full border-transparent bg-ma-pink-100 px-3 placeholder:text-ma-gray-900/50 focus:border-transparent focus:outline-0"
            onChange={handleChange}
            readOnly={true}
          />
        </div>

        {children}
      </div>
    )
  }, [values, question, children, handleChange, handleChangeInput, errors, stateSubmit])

  return <>{bmiField}</>
}
