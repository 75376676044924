import { useMemo } from 'react'
import { Session07Wrapper } from '../Wrapper'
import { VimeoEmbed } from '../../../../Common/VimeoEmbed'

type Props = {
  isLoading?: boolean
  buttons?: React.ReactNode
}

export const ScreenBigIdeasVideo = ({ isLoading, buttons }: Props) => {
  const screenBigIdeasVideo = useMemo(() => {
    return (
      <Session07Wrapper buttons={buttons}>
        <div className="space-y-6">
          <p>
            <b>Welcome Back! </b>
            <br />
            In this session we’re going to discuss what happens when things don’t go according to plan. We’re going to learn how to navigate the medical system so you get the care you need. We’ll
            discuss what to do in emergency situations and when you get sick.
            <br />
            <br />
            Watch the video below to begin.
          </p>

          <VimeoEmbed embedId="808490655" />
        </div>
      </Session07Wrapper>
    )
  }, [isLoading, buttons])

  return <>{screenBigIdeasVideo}</>
}
