import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { RateField } from './Rate'
import { FieldTypes, Question, QuestionTypes } from '../../../models/'
import { FillBlankField } from './FillBlank'
import { IncreaseOrReduceButtonField } from './IncreaseOrReduceButton'
import { Ticked } from './Ticked'
import { ToggleField } from './Toggle'

type Props = {
  editable: boolean
  backgroundColor?: string
  textColor?: string
  children?: React.ReactNode
  value?: string | string[]
  question: Question
  fieldType?: FieldTypes
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onUpdateHiddenField: Function
  answer?: any
  isVisible?: boolean
}

const ObjectInput: any = {
  'Your Blood Pressure Today': [
    { name: 'SYS', unit: '', value: '' },
    { name: 'DIA', unit: '', value: '' },
    { name: 'PUL', unit: '', value: '' },
  ],
  'Target Heart Rate move duration today': [{ name: '', unit: 'min', value: '' }],
  'How many steps did you take today?': [{ name: '', unit: '', value: '' }],
  'Your Weight': [{ name: '', unit: 'lb', value: '' }],
  'Hours Slept Last Night': [
    { name: '', unit: 'h', value: '' },
    { name: '', unit: 'min', value: '' },
  ],
}

export const DailyTrackingField = ({ isVisible, answer, editable, children, value, backgroundColor, textColor, question, fieldType = FieldTypes.Dark, onChange, onUpdateHiddenField }: Props) => {
  let isHidden: boolean = [QuestionTypes.InputHidden, QuestionTypes.RadioHidden, QuestionTypes.RadioDropdown].includes(question.real_type)
  const isDarkType: boolean = fieldType === FieldTypes.Dark
  const hasParent: boolean = !!question.parent

  const disabledField = !isVisible

  const [isShowField, setIsShowField] = useState<boolean>(!isHidden)

  const handleShowInput = useCallback((statusCurrent: boolean) => {
    if (isHidden && editable) {
      setIsShowField(true)
    }
  }, [])

  const input = useMemo(() => {
    switch (question.real_type) {
      case QuestionTypes.IncreaseOrReduceButton:
        return <IncreaseOrReduceButtonField disabled={disabledField} answer={answer} question={question} value={value} onChange={onUpdateHiddenField} />
      case QuestionTypes.Rate:
        return (
          <RateField
            question={question}
            disabled={disabledField}
            backgroundColor={backgroundColor}
            textColor={textColor}
            value={typeof value === 'string' ? parseFloat(value) : undefined}
            onChange={onUpdateHiddenField}
          />
        )
      case QuestionTypes.Boolean:
        return <ToggleField question={question} valueDefault={value} onChange={onUpdateHiddenField} isArchived={answer?.length} disabled={disabledField} />

      case QuestionTypes.FillBlank:
        return (
          <FillBlankField
            question={question}
            inputType={question.real_type}
            disabled={disabledField}
            backgroundColor={backgroundColor}
            textColor={textColor}
            value={value}
            isArchived={answer?.length}
            onChange={onUpdateHiddenField}
          />
        )

      case QuestionTypes.Ticked:
        return <Ticked question={question} value={value} onChange={onUpdateHiddenField} />

      default:
        break
    }
  }, [fieldType, value, question, isVisible, answer])

  const inputField = useMemo(() => {
    const iconCheckbox = isShowField ? `bg-[url('assets/images/icon/i-checkbox__checked.svg')]` : `bg-[url('assets/images/icon/i-checkbox__uncheck.svg')]`
    const checkboxStyle = isHidden ? `cursor-pointer bg-[top_left_-3px] bg-no-repeat bg-[length:24px_auto] pl-6 pt-[2px] ${iconCheckbox}` : ''

    const iconArrowRotate = isShowField ? 'before:rotate-90' : ''
    const dropdownStyle = isHidden
      ? `cursor-pointer rounded border border-[#D4D4D4] pl-3 leading-[30px] shadow-[0px_8px_15px_rgba(0,0,0,0.2)] before:absolute before:top-0 before:right-0 before:h-[30px] before:w-[30px] before:bg-[url('assets/images/icon/i-arrow.svg')] before:bg-[length:auto_17px] before:bg-center before:bg-no-repeat before:transition-transform ${iconArrowRotate}`
      : ''

    const iconStyle = question.real_type === QuestionTypes.RadioDropdown ? dropdownStyle : checkboxStyle
    const colorStyle = isDarkType ? (question.parent ? 'text-ma-pink-300' : 'text-black') : 'text-white'
    return (
      <div
        className={`${
          question.real_type === QuestionTypes.IncreaseOrReduceButton || question.real_type === QuestionTypes.Boolean || question.real_type === QuestionTypes.Ticked
            ? 'flex justify-between text-[#000]'
            : question.real_type === QuestionTypes.FillBlank &&
              (question?.name === 'Target Heart Rate move duration today' ||
                question?.name === 'Your Weight' ||
                question?.name === 'Hours Slept Last Night' ||
                question?.name === `How many did you take today? steps`)
            ? 'flex justify-between'
            : question?.name === 'Your Blood Pressure Today'
            ? ''
            : ''
        } space-y-2`}
      >
        {question.name && !question.hideLabel && (
          <h4
            className={`relative text-[16px] font-normal ${iconStyle} ${colorStyle}`}
            style={{
              color: textColor ?? 'text-ma-pink-300',
            }}
            onClick={() => handleShowInput(isShowField)}
          >
            {question.name}
          </h4>
        )}

        <div className={`space-y-2`}>
          <div className={`${question.real_type === QuestionTypes.Boolean ? 'flex justify-end' : ''}`}>{isShowField ? input : null}</div>
          <div className="flex justify-end">
            <a href="#edit-my-now" className="text-[14px] leading-none text-color-primary underline decoration-color-primary">
              {/* {t('extraText.editMyNow')} */}
              {question?.link as any}
            </a>
          </div>
        </div>
      </div>
    )
  }, [isDarkType, isHidden, isShowField, question, input, handleShowInput])

  const formField = useMemo(() => {
    const hasTitle = !!question.question
    return (
      <div className={`space-y-4 ${isDarkType ? 'text-black' : 'text-white'} ${hasTitle ? 'pt-3' : ''}`}>
        {inputField}
        {children}
      </div>
    )
  }, [hasParent, inputField, question, children, answer])

  return <div className="border-b-[1px] pb-4">{formField}</div>
}
