import {useMemo} from "react";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

type Props = {
  date?: string
  description?: string
  className?: string
  children?: React.ReactNode
}

export const HeartJournalAction = ({date, description, className, children}: Props) => {
  const formattedDate = useMemo(() => {
    let dateValue = date ? new Date(Date.parse(date)) : new Date()
    let formattedDate = monthNames[dateValue.getMonth()] + " " + dateValue.getDate() + ", " + dateValue.getFullYear()
    return (
      <h5 className="mb-4 uppercase text-[13px] font-bold">
        {formattedDate}
      </h5>
    )
  }, [])
  return (
    <section className={className || '' + " font-['Proxima_Nova']"}>
      {formattedDate}
      {description && (
        <p className="font-bold text-lg">{description}</p>
      )}
      {children}
    </section>
  )
}
