import axios from 'axios'
import { getCsrfCookie } from './get-csrf-cookie'
import { Goal, GoalFromApiResponse } from '../../models/goal'
import { mapGoal } from '../maps/goal'

type MyGoalsResponse = {
  success: boolean
  data: Goal[]
  message: string
}

type GetRemainingGoalsResponse = {
  message: string
  data: GoalFromApiResponse[]
}

export async function getRemainingGoals(): Promise<MyGoalsResponse> {
  let dataReturn: MyGoalsResponse = {
    success: true,
    data: [],
    message: 'Success',
  }

  /* Don't delete this line of comment code  */

  // await getCsrfCookie()
  //   .then(() => {
  //     return axios
  //       .get<GetRemainingGoalsResponse>('/goals/remaining')
  //       .then((response) => {
  //         const mappedData = response.data.data.map((goal) => {
  //           return mapGoal(goal)
  //         })
  //         dataReturn.success = true
  //         dataReturn.data = mappedData
  //       }).catch((error) => {
  //         console.info('/goals/remaining error', error)
  //         dataReturn.success = false
  //       })
  //   })
  //   .catch((error) => {
  //     console.info('getCsrfCookie error', error)
  //     dataReturn.success = false
  //     return dataReturn
  //   })
  // return dataReturn;

  /* Don't delete this line of comment code  */

  await axios
    .get<GetRemainingGoalsResponse>('/goals/remaining')
    .then((response) => {
      const mappedData = response.data.data.map((goal) => {
        return mapGoal(goal)
      })
      dataReturn.success = true
      dataReturn.data = mappedData
    })
    .catch((error) => {
      console.info('/goals/remaining error', error)
      dataReturn.success = false
    })

  return dataReturn
}
